import React, {useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {Card, Button, Table} from "../../../components/Elements/appUtils";
import {checkListGroupedDocumentFxn} from "./actions";
import UploadCheckListDocument from "./drawers/uploadCheckListDocument";
import DocumentCheckList from "./drawers/documentCheckList";

const GroupedDocuments = () => {
    let tableRef = useRef();
    let dispatch = useDispatch();
    let [visible, setVisible] = useState(false)
    let [documentState, setDocumentState] = useState({
        visible: false,
        masterCountry: {},
        category: {},
    })
    const docEvents = {
        showDocumentList: (data) => {
            let {masterCountryId, categoryId} = data;
            setDocumentState({
                visible: true,
                masterCountry: masterCountryId,
                category: categoryId
            })
        },
        hideDocumentList: () => {
            setDocumentState({
                visible: false,
                masterCountry: {},
                category: {}
            })
        }
    }
    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            let data = await dispatch(checkListGroupedDocumentFxn({
                ...params,
                regExFilters: ["countryName", "interview", "documents"],
            }));
            console.log(data)
            resolve(data);
        });
    };

    const columns = [
        {
            title: "Country",
            dataIndex: "masterCountryId",
            key: "masterCountryId",
            width: 150,
            render: (item, record) => {
                let {masterCountryId} = record._id;
                return <div
                    style={{width: 150}}>{masterCountryId && masterCountryId.countryName ? masterCountryId.countryName : ""}</div>;
            },
        },
        {
            title: "Category",
            dataIndex: "categoryId",
            key: "categoryId",
            width: 150,
            render: (item, record) => {
                let {categoryId} = record._id;
                return <div
                    style={{width: 150}}>{categoryId && categoryId.name ? categoryId.name : ""}</div>;
            },
        },
        {
            title: "Documents",
            dataIndex: "count",
            key: "count",
            width: 150,
            render: (item, record) => {
                return item ? <>
                    <a className={'action_btn'}
                       onClick={() => {
                           docEvents.showDocumentList(record._id)
                       }}>{item}</a>
                </> : 0
            }
        },
        {
            title: "Action",
            dataIndex: "action",
            width: 150,
            render: (val, record) => {
                return (
                    <>

                    </>
                );
            },
        },
    ];
    return (
        <>
            <Card
                title={"Document Checklist"}
                rightContent={
                    <Button type={"primary"} sm={true} onClick={() => {
                        setVisible(true)
                    }}>
                        Add Document
                    </Button>
                }
            >
                <Table apiRequest={apiRequest} columns={columns} ref={tableRef}/>
            </Card>
            {visible ? <UploadCheckListDocument
                visible={visible}
                onClose={() => {
                    setVisible(false)
                }}
                onSubmit={() => {
                    setVisible(false)
                    tableRef.current.reload()
                }}
            /> : null}


            {documentState.visible ? <DocumentCheckList
                {...documentState}
                onClose={() => {
                    docEvents.hideDocumentList()
                }}
            /> : null}

        </>
    );
};

export default GroupedDocuments;
