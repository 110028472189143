import notification from "../../../components/Elements/Notification";
import {customAxios as axios, getToken} from "../../../request";
import {addTemplateCategoryUrl, getTemplateCategoryUrl, templateEditUrl, templateCategoryListUrl, getCategoryUrl, postCategoryUrl} from "../apis";
import {hideLoader, showLoader} from "../../../actions/loader";

export const AddTemplateCategory = async (valData) => {

    let {data} = await axios.post(
        addTemplateCategoryUrl(),
        valData,
        getToken()
    );
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    }
    return data;
};


export const templateCategoryList = async (valData) => {
    let config = {
        params: {...valData},
        ...await getToken()
    }
    let {data} = await axios.get(templateCategoryListUrl(), config)
    // console.log(data)
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }

    return data
}

export const getRelatedTemplateSection = (id) => async (dispatch) => {
    dispatch(showLoader())
    let config = {
        ...await getToken()
    }
    let {data} = await axios.get(getTemplateCategoryUrl(id), config)
    dispatch(hideLoader())

    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }

    return data
}


export const updateTemplateSectionFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(templateEditUrl(), valData, getToken());
    dispatch(hideLoader())
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};

export const GetRelatedCategoryFxn = (id) => async (dispatch) => {
    dispatch(showLoader())
    let config = {
        ...await getToken()
    }
    let {data} = await axios.get(getCategoryUrl(id), config)
    dispatch(hideLoader())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }

    return data
  }

  export const postRelatedCategoryFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(postCategoryUrl(), valData, getToken());
    dispatch(hideLoader())
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};
