import {apiUrl} from "../../settings";

export const addPurchasePlanUrl = () => {
    return `${apiUrl}/addPurchasePlan`;
};

export const singlePurchasePlanUrl = () => {
    return `${apiUrl}/getSinglePurchasePlan`;
};

export const purchasePlansListUrl = () => {
    return `${apiUrl}/purchasePlansList`;
};

export const getSinglePurchasePlanByUserUrl = () => {
    return `${apiUrl}/getSinglePurchasePlanByUser`;
};
