import React, {useState, useEffect} from "react";
import {
    Button,
    Card,
    Form,
    Table,
    notification,
} from "../../../components/Elements/appUtils";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import {useDispatch} from "react-redux";
import {EditPlanFxn, getSinglePlanFxn} from "../action";

const EditPlans = (props) => {
    // const [name, setName] = useState("");
    // const [amount, setAmount] = useState("");
    // const [planId, setPlanId] = useState("");
    const [planData, setPlanData] = useState({name: "", amount: ""});
    const [planId, setPlanId] = useState("");
    let dispatch = useDispatch();
    // let dispatch = useDispatch();

    useEffect(() => {
        checkPlanId();
    }, []);

    const checkPlanId = async () => {
        let searchParams = new URLSearchParams(window.location.search);
        let id = searchParams.get("_id");
        if (id) {
            setPlanId(id);
            getSinglePlan(id);
        }
    };

    const getSinglePlan = async (id) => {
        let {data, success} = await dispatch(getSinglePlanFxn(id));
        console.log(data, "ddtdtdtdtt");
        if (success) {
            setPlanData({name: data.name, amount: data.amount});
            setTimeout(() => {
                props.form.setFieldsValue({
                    name: data.name,
                    amount: data.amount,
                });
            }, 300);
        }
    };

    const handleSubmit = (e) => {
        const {form} = props;
        e.preventDefault();

        form.validateFields(async (err, valData) => {
            if (!err) {
                // valData.name = name;
                // valData.amount = amount;
                // valData.planId = planId;

                valData.planId = planId;

                // let x = await dispatch(EditPlanFxn(valData));
                let x = await dispatch(EditPlanFxn(valData));
            } else {
                if (err.name) {
                    notification.warning({
                        message: `Please enter Name`,
                    });
                    return;
                }

                if (err.amount) {
                    notification.warning({
                        message: `Please enter Email`,
                    });
                    return;
                }
            }
        });
    };

    let planInfo = {
        fields: [
            {
                key: "name",
                label: "Name",
                required: true,
                placeholder: "Name",
                type: "text",
            },
            {
                key: "amount",
                label: "Amount",
                placeholder: "Amount",
                type: "number",
            },
        ],
    };

    return (
        <>
            <Card title={"Edit Plans"}>
                <Form onSubmit={handleSubmit}>
                    <div className={"row"}>
                        {planInfo.fields.map((item, key) => {
                            return !item.hidden ? (
                                <div className={"col-md-6 pt-3"} key={key}>
                                    <GetEachFormFields {...props.form} item={item}/>
                                </div>
                            ) : null;
                        })}
                    </div>
                    <div style={{marginTop: "20px"}}>
                        <Button type={"primary"} htmlType="submit">
                            submit
                        </Button>
                    </div>
                </Form>
            </Card>
        </>
    );
};

export default Form.create()(EditPlans);
