import {
    Navigate,
    Route,
    BrowserRouter as Router,
    Routes,
} from "react-router-dom";
import AuthLayout from "../Layout/AuthLayout";
import FrontLayout from "../Layout/FrontLayout";
import MainLayout from "../Layout/MainLayout";

//<editor-fold desc="Masters">
import AddMasterCity from "../containers/Masters/city/AddCity";
import MasterCityList from "../containers/Masters/city/CityList";
import EditMasterCity from "../containers/Masters/city/EditCity";
import AddMasterCountry from "../containers/Masters/country/AddCountry";
import MasterCountryList from "../containers/Masters/country/CountryList";
import EditMasterCountry from "../containers/Masters/country/EditCountry";
import AddMasterCourse from "../containers/Masters/course/AddCourse";
import MasterCourseList from "../containers/Masters/course/CourseList";
import EditMasterCourse from "../containers/Masters/course/EditCourse";
import MasterCourseCategory from "../containers/Masters/courseCategory/CourseCategory";
import MasterCourseCategoryEdit from "../containers/Masters/courseCategory/CourseCategoryEdit";
import AddMasterState from "../containers/Masters/state/AddState";
import EditMasterState from "../containers/Masters/state/EditState";
import MasterStateList from "../containers/Masters/state/StateList";
import AddMasterUniversity from "../containers/Masters/university/AddUniversity";
import EditMasterUniversity from "../containers/Masters/university/EditUniversity";
import MasterUniversityList from "../containers/Masters/university/UniversityList";

import AddTemplate from "../containers/template/view/AddTemplate";
import EditTemplate from "../containers/template/view/EditTemplate";
import TemplateList from "../containers/template/view/TemplateList";

import TemplateCategory from "../containers/templateCategory/drawers/templateCategory";
import TemplateCategoryList from "../containers/templateCategory/views/TemplateCategoryList";
import TemplateView from "../containers/templateView/templateView";

import AddCompany from "../containers/Masters/company/AddCompany";
import CompanyList from "../containers/Masters/company/CompanyList";

//</editor-fold>

//<editor-fold desc="Head branch">

import AddCountry from "../containers/Country/view/AddCountry";
import CountryList from "../containers/Country/view/CountryList";
import AddCourse from "../containers/Course/view/AddCourse";
import CourseList from "../containers/Course/view/CourseList";
import SelfDocument from "../containers/Document/selfDocument";
import AddUniversity from "../containers/University/view/AddUniversity";
import UniversityList from "../containers/University/view/UniversityList";
//</editor-fold>

import AddStudent from "../containers/AddStudent/AddStudent";
import ApplicationProfile from "../containers/ApplicationProfile";
import ApplicationsList from "../containers/Applications/list";
// import AddBranch from "./containers/Branch/addBranch";
import StudentListTest from "../containers/AddStudent_test/views/StudentListTest";
import BranchList from "../containers/Branch/branchList";
import AddCounsellor from "../containers/Counsellor/views/AddCounsellor";
import CounsellorList from "../containers/Counsellor/views/CounsellorList";
import Dashboard from "../containers/Dashboard";
import Home from "../containers/Home";
import AddLead from "../containers/Leads/views/AddLead";
import LeadList from "../containers/Leads/views/LeadList";
import AddPartner from "../containers/Partner/addPartner";
import Profile from "../containers/Profile/views/profile";
import AddReception from "../containers/Reception/addReception";
import ReceptionList from "../containers/Reception/receptionList";
import GenerateSop from "../containers/SOP/generateSop";
import SopList from "../containers/SOP/sopList";
import StudentList from "../containers/Student/views/StudentList";
import AddBranchUser from "../containers/Users/views/addBranchUser";
import EditUser from "../containers/Users/views/editUser";
import LeadExtraFields from "../containers/leadExtraFields";

import AddCourseLevel from ".././containers/CourseLevel/addCourseLevel";
import AddManager from ".././containers/Manager/views/addManager";
import AddPaymentDetails from "../containers/AddPayments/AddPaymentDetails";
import AgencyFees from "../containers/AddPayments/agencyFees";
import Login from "../containers/Auths/login";
import Signup from "../containers/Auths/signup";
import AddBoardStream from "../containers/BoardStream/addBoardStream";
import EditCounsellor from "../containers/Counsellor/views/EditCounsellor";
import GicList from "../containers/GicCommission/gicList";
import AddHr from "../containers/HR/AddHr";
import AddEmployee from "../containers/HR/addEmployee";
import EmployeeList from "../containers/HR/employeeList";
import EditLead from "../containers/Leads/views/editLead";
import RequestedCompanyList from "../containers/Masters/company/requestedCompanyList";
import DocumentCheckList from "../containers/Masters/documentCheckList/groupedDocuments";
import EditPlans from "../containers/Plans/views/EditPlans";
import AddPlans from "../containers/Plans/views/plans";
import SingleLead from "../containers/SingleLead/view";
import StudentProfile from "../containers/StudentProfile/view";
import AddBranch from "../containers/Users/branch/addBranch";
import UserList from "../containers/Users/userGrouped";
import AddEnglishTeacher from "../containers/Users/views/addEnglishTeacher";
import BranchUserList from "../containers/Users/views/branchUserList";
import EditBranchUser from "../containers/Users/views/editBranchUser";
import EditEnglishTeacher from "../containers/Users/views/editEnglishTeacher";

import EnglishTeacherList from "../containers/Users/views/englsihTeacherList";

import {history} from "../reducers/store";

import AddCommission from "../containers/Commission/addCommission";
import CommissionList from "../containers/Commission/commissionList";
import Feedback from "../containers/Feedback/feedback";
import AddGicPage from "../containers/GicCommission/addGicPage";
import GicApprovedList from "../containers/GicCommission/gicApprovedList";
import HrList from "../containers/HR/list";
import ManagerList from "../containers/Manager/views/ManagerList";
import EditManager from "../containers/Manager/views/editManager";
import EditReception from "../containers/Reception/EditReception";
import EditStudent from "../containers/StudentProfile/view/editStudent";
// import TrainingRequestComponent from "../containers/TrainingRequest";
import ForgetPassword from "../containers/Auths/forgetPassword.js";
import ResetPassword from "../containers/Auths/resetPassword/index.js";
import MyProfile from "../containers/Dashboard/components/myProfile";
import FeedBackList from "../containers/Feedback/feedBackLIst";
import EmployeeEdit from "../containers/HR/employeeEdit";
import SingleEmpProfile from "../containers/HR/singleEmpProfile";
import AddIpAddress from "../containers/IpAddress/addIpAddress.js";
import EditCompany from "../containers/Masters/company/editCompany";
import EditMyProfile from "../containers/Profile/editMyProfile";
import WithdrawalList from "../containers/Withdrawals/views/list";
import ChangePassword from "../containers/changepassword/changePassword";
import {menuData} from "./menu";

const RouteListOld = (props) => {
    return (
        <Router history={history}>
            <Routes>
                {/* <Route path={"/"} element={<FrontLayout/>}>
                    <Route index element={<Home/>}/>
                    <Route path="/home" element={<Home/>}/>
                </Route>*/}
                <Route element={<MainLayout {...props} />}>
                    {/*<Route path="/" element={<Dashboard/>}/>*/}

                    <Route path="/dashboard" element={<Dashboard {...props} />}/>

                    {/*master routes start*/}

                    <Route path="/master/add-country" element={<AddMasterCountry/>}/>
                    <Route path="/master/country-edit" element={<EditMasterCountry/>}/>
                    <Route path="/master/country-list" element={<MasterCountryList/>}/>
                    <Route path="/add-university" element={<AddMasterUniversity/>}/>
                    <Route
                        path="/master/university-edit"
                        element={<EditMasterUniversity/>}
                    />
                    <Route
                        path="/master/university-list"
                        element={<MasterUniversityList/>}
                    />

                    <Route path="/master/add-state" element={<AddMasterState/>}/>

                    <Route path="/master/state-edit" element={<EditMasterState/>}/>
                    <Route path="/master/state-list" element={<MasterStateList/>}/>
                    <Route path="/master/add-city" element={<AddMasterCity/>}/>
                    <Route path="/master/city-edit" element={<EditMasterCity/>}/>
                    <Route path="/master/city-list" element={<MasterCityList/>}/>

                    <Route path="/master/add-course" element={<AddMasterCourse/>}/>
                    <Route path="/master/course-edit" element={<EditMasterCourse/>}/>
                    <Route path="/master/course-list" element={<MasterCourseList/>}/>

                    <Route
                        path="/master/course-category"
                        element={<MasterCourseCategory/>}
                    />
                    <Route
                        path="/master/course-category-edit"
                        element={<MasterCourseCategoryEdit/>}
                    />

                    <Route path="/master/add-template" element={<AddTemplate/>}/>
                    <Route path="/master/edit-template" element={<EditTemplate/>}/>
                    <Route path="/master/template-list" element={<TemplateList/>}/>
                    <Route
                        path="/master/template-category"
                        element={<TemplateCategory/>}
                    />
                    <Route path="/template-view" element={<TemplateView/>}/>

                    <Route
                        path="/master/template-category-list"
                        element={<TemplateCategoryList/>}
                    />

                    <Route path="/master/add-company" element={<AddCompany/>}/>
                    <Route path="/master/company-list" element={<CompanyList/>}/>
                    <Route path="/master/edit-company" element={<EditCompany/>}/>
                    <Route
                        path="/master/requested-company-list"
                        element={<RequestedCompanyList/>}
                    />

                    {/*master routes end*/}

                    {/*head branch routes start*/}

                    <Route path="/add-country" element={<AddCountry/>}/>
                    <Route path="/country-list" element={<CountryList/>}/>
                    {/* <Route path="/add-university" element={<AddUniversity />} /> */}
                    <Route path="/university-list" element={<UniversityList/>}/>
                    <Route path="/add-course" element={<AddCourse/>}/>
                    <Route path="/course-list" element={<CourseList/>}/>
                    <Route path="/self-Document" element={<SelfDocument/>}/>
                    <Route path="/add-Commission/:id" element={<AddCommission/>}/>

                    <Route path="/user-list" element={<UserList/>}/>
                    <Route path="/employee-list" element={<EmployeeList/>}/>
                    <Route path="/single-emp-profile" element={<SingleEmpProfile/>}/>
                    <Route path="/edit-single-emp" element={<EmployeeEdit/>}/>
                    <Route path="/add-branch-user" element={<AddBranchUser/>}/>
                    <Route path="/branch-user-list" element={<BranchUserList/>}/>
                    {/* <Route path="/add-branch" element={<AddBranch />} /> */}

                    <Route path="/branch-list" element={<BranchList/>}/>
                    <Route path="/add-reception" element={<AddReception/>}/>
                    <Route path="/edit-Lead" element={<EditLead/>}/>

                    <Route path="/reception-list" element={<ReceptionList/>}/>
                    <Route path="/edit-reception" element={<EditReception/>}/>
                    <Route path="/add-counsellor" element={<AddCounsellor/>}/>
                    <Route path="/edit-counsellor" element={<EditCounsellor/>}/>
                    <Route path="/add-manager" element={<AddManager/>}/>

                    <Route path="/counsellor-list" element={<CounsellorList/>}/>
                    <Route path="/lead-extra-fields" element={<LeadExtraFields/>}/>
                    <Route path="/add-partner" element={<AddPartner/>}/>
                    <Route path="/add-ipAddress" element={<AddIpAddress/>}/>
                    <Route path="/board-and-stream" element={<AddBoardStream/>}/>
                    <Route path="/course-Level" element={<AddCourseLevel/>}/>
                    <Route path="/add-Branch" element={<AddBranch/>}/>
                    <Route path="/englishTeacher-list" element={<EnglishTeacherList/>}/>
                    <Route path="/edit-englishTeacher" element={<EditEnglishTeacher/>}/>
                    <Route path="/edit-manager" element={<EditManager/>}/>
                    <Route path="/manager-list" element={<ManagerList/>}/>
                    <Route path="/my-profile" element={<MyProfile/>}/>
                    <Route path="/edit-my-profile" element={<EditMyProfile/>}/>
                    <Route path="/change-password" element={<ChangePassword/>}/>
                    {/*head branch routes end*/}
                    <Route path="/add-feedback" element={<Feedback/>}/>
                    {/*<Route
                        path="/add-trainingRequest"
                        element={<TrainingRequestComponent/>}
                    />*/}

                    <Route path="/sopList" element={<SopList/>}/>

                    <Route path="/add-lead" element={<AddLead/>}/>
                    <Route path="/lead-list" element={<LeadList {...props} />}/>
                    <Route path="/generateSOP" element={<GenerateSop/>}/>
                    <Route path="/student-list" element={<StudentList/>}/>
                    <Route path="/edit-student" element={<EditStudent/>}/>
                    <Route path="/add-student" element={<AddStudent/>}/>
                    <Route path="/applications-list" element={<ApplicationsList/>}/>
                    <Route
                        path="/student-application-profile"
                        element={<StudentProfile/>}
                    />
                    <Route path="/userEdit" element={<EditUser/>}/>

                    <Route path="/add-hr" element={<AddHr/>}/>
                    <Route path="/add-employee" element={<AddEmployee/>}/>
                    <Route path="/edit-branch-user" element={<EditBranchUser/>}/>
                    <Route path="/student-profile" element={<StudentProfile/>}/>
                    <Route path="/lead-Profile" element={<SingleLead/>}/>
                    <Route path="/add-payment-details" element={<AddPaymentDetails/>}/>
                    <Route path="/commission-list" element={<CommissionList/>}/>
                    <Route path="/feedback-list" element={<FeedBackList/>}/>

                    <Route path="/add-plan" element={<AddPlans/>}/>
                    <Route path="/edit-plan" element={<EditPlans/>}/>
                    <Route path="/student-list-test" element={<StudentListTest/>}/>

                    <Route path="/profile" element={<Profile/>}/>
                    <Route path="/agency-fees-details/:id" element={<AgencyFees/>}/>
                    <Route path="/document-check-list" element={<DocumentCheckList/>}/>
                    <Route path="/add-english-teacher" element={<AddEnglishTeacher/>}/>
                    <Route path="*" element={<Navigate replace to="/dashboard"/>}/>
                    <Route path="/gic-Request-list" element={<GicList/>}/>
                    <Route path="/withdrawal-list" element={<WithdrawalList/>}/>
                    <Route path="/hr-list" element={<HrList/>}/>
                    <Route path="/add-gic-request" element={<AddGicPage/>}/>
                    <Route path="/gic-approved-list" element={<GicApprovedList/>}/>
                </Route>
                <Route path={"/"} element={<AuthLayout/>}>
                    <Route index element={<Login/>}/>
                    <Route path="/reset-password" element={<ResetPassword/>}/>
                    <Route path="/forget-password" element={<ForgetPassword/>}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/signup" element={<Signup/>}/>
                </Route>
            </Routes>
        </Router>
    );
};
const RouteList = (props) => {
    let {currentUser = {}} = props;

    return (
        <Router history={history}>
            <Routes>
                <Route path={"/"} element={<MainLayout {...props} />}>
                    {menuData.map((item) => {
                        let {component: Component} = item;
                        if (!item.subMenu && currentUser) {
                            let routeRow = (
                                <Route
                                    exact
                                    path={item.path}
                                    key={item.key}
                                    element={<Component {...props} />}
                                />
                            );
                            if (item.authority) {
                                return item.authority.includes(currentUser.userType)
                                    ? routeRow
                                    : null;
                            } else {
                                return routeRow;
                            }
                        }
                    })}

                    {menuData.map((item) => {
                        if (
                            item.subMenu &&
                            item.subMenu.length &&
                            currentUser &&
                            item.authority
                        ) {
                            return item.subMenu.map((child, k) => {
                                let {component: Component} = child;
                                let routeRow = (
                                    <Route
                                        exact
                                        path={child.path}
                                        key={child.key}
                                        element={<Component {...props} />}
                                    />
                                );
                                return child.authority && child.authority.length
                                    ? child.authority.includes(currentUser.userType)
                                        ? routeRow
                                        : null
                                    : routeRow;
                            });
                        }
                    })}
                </Route>
                <Route path={"/"} element={<AuthLayout/>}>
                    <Route index element={<Login/>}/>
                    <Route path="/forget-password" element={<ForgetPassword/>}/>
                    <Route path="/reset-password" element={<ResetPassword/>}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/signup" element={<Signup/>}/>
                </Route>
                <Route path="*" element={<Navigate replace to="/dashboard"/>}/>
            </Routes>
        </Router>
    );
};

export default RouteList;
