import React from "react"
import AddPlans from "../components/addPlan";
import {Drawer} from "../../../components/Elements/appUtils";

const AddPlanDrawer = (props) => {
    let {visible, onClose, onSubmit} = props;
    return (
        <Drawer
            width={"50%"}
            visible={visible}
            onClose={onClose}
            titl={'Add Plan'}>
            <AddPlans reloadList={onSubmit}/>
        </Drawer>
    )
}
export default AddPlanDrawer
