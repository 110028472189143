import {apiUrl} from "../../settings";

export const AddGicCommissionUrl = () => {
    return `${apiUrl}/addGicCommission`;
};
export const AddTransactionUrl = () => {
    return `${apiUrl}/addTransaction`;
};
export const AddBankAcountUrl = () => {
    return `${apiUrl}/addBankAcount`;
};
export const UpdateGicCommissionUrl = () => {
    return `${apiUrl}/updateGicStatus`;
};

export const gicCommissionListUrl = () => {
    return `${apiUrl}/gicCommissionList`;
};
export const transactionListUrl = () => {
    return `${apiUrl}/transactionList`;
};
export const BankAcountListUrl = () => {
    return `${apiUrl}/bankAcountList`;
};
export const gicCommissionCountUrl = () => {
    return `${apiUrl}/gicCommissionCount`;
};
export const gicCommissionListMonthWiseUrl = () => {
    return `${apiUrl}/gicCommissionListMonthWise`;
};
export const sendWithdrawalOtpUrl = () => {
    return `${apiUrl}/sendWithdrawalOtp`;
};

export const addWithdrawalUrl = () => {
    return `${apiUrl}/addWithdrawal`;
};

export const updateWithdrawalUrl = () => {
    return `${apiUrl}/updateWithdrawal`;
};
export const UpdateGicDocumentsUrl = () => {
    return `${apiUrl}/updateGicDocuments`;
};
