import React, {useRef, useState} from 'react'
import {Button, Card, Form, notification, Table} from "../../../components/Elements/appUtils";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import {AddCourseCategoryFxn, courseCategoryList} from './action';
import {useDispatch} from 'react-redux';

const CourseCategory = (props) => {
    const {getFieldDecorator, setFieldsvalue} = props.form;
    let [logo, setLogo] = useState("")
    let [formState, setState] = useState({
        categoryName: "",
        logo: {}
    });
    let dispatch = useDispatch()
    let tableRef = useRef()

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setLogo(file)
        /*  const name = e.target.name;
          const updatedFormData = {...formState};

          updatedFormData[name] = {
              path: e.target.value,
              fileName: file.name,
          };

          setState(updatedFormData);*/
    };

    const handleSubmit = (e) => {
        const {form} = props;
        e.preventDefault();
        form.validateFields(async (err, valData) => {
            if (!err) {
                let fd = new FormData();
                fd.append("obj", JSON.stringify(valData));
                if (logo && logo.name) {
                    fd.append("logo", logo);
                }
                let x = await dispatch(AddCourseCategoryFxn(fd));
                if (x && x.success) {
                    notification.success({message: x.message})
                  
                    tableRef.current.reload()
                    props.form.setFieldsvalue({
                        categoryName: ""
                    })
                    setLogo("")
                } else {
                    notification.error({message: x.message})
                }

              
            }
        });
    };


    let inputTypes = {
        fields: [
            {
                key: "categoryName",
                label: "Category Name",
                required: true,
                placeholder: "Category Name"

            },
            {
                key: "logo",
                label: " Logo",
                placeholder: "logo",
                type: "file",
                onChange: handleFileChange,

            },
        ]
    };

    const columns = [
        {
            title: 'Category',
            dataIndex: 'categoryName',
            key: 'categoryName',
            searchTextName: 'categoryName',
            filterRegex: true,
        },
        {
            title: 'Logo',
            dataIndex: 'logo',
            key: 'logo',
            render: (logo, record) => {
                return (
                    logo && logo.path ? <img alt={logo.path} src={logo.path} height={60}/> : ""
                )
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            width: 130,
            render: (val, record) => {
                return (
                    <a className={'btn btn-primary btn-sm'}
                       href={`/master/course-category-edit?_id=${record._id}`}>Edit</a>

                )
            }
        },
    ];

    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            let {data} = await courseCategoryList({
                ...params,
                regExFilters: ['country', 'logo']
            })
            resolve(data)
        })
    }

    return (
        <>

            <Card title={"Add Course Category"}>
                <Form onSubmit={handleSubmit}>
                    <div className={"row"}>
                        {inputTypes.fields.map((item, key) => {
                            return (
                                <div className={"col-md-6 pt-3"} key={key}>
                                    <GetEachFormFields
                                        {...props.form}
                                        item={item}
                                    />
                                </div>
                            );
                        })}
                    </div>

                    <div style={{marginTop: "20px"}}>
                        <Button type={"primary"} htmlType="submit">
                            submit
                        </Button>
                    </div>
                </Form>
            </Card>
            <Card title={" Course Category List"} className={"marginTop:50px"}>
                <Table columns={columns} apiRequest={apiRequest} ref={tableRef} marginTop="8"/>
            </Card>
        </>
    )
}

export default Form.create()(CourseCategory);
