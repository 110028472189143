import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {
    Card,
    Form,
    Table,
    Tooltip,
    displayDate,
} from "../../../components/Elements/appUtils";
import {imgPath} from "../../../components/_utils/_utils";
import {studentListFxn} from "../../SOP/action";
import ApplicationList from "../components/applicationList";

let {TableCard} = Card;

let initFilter = {
    name: "",
    email: "",
    mobile: "",
};
const StudentList = (props) => {
    const navigate = useNavigate();
    let tableRef = useRef();
    let [total, setTotal] = useState(0);
    let [filterState, setFilterState] = useState(initFilter);
    let [appState, setAppState] = useState({
        visible: false,
        studentId: "",
        studentName: "",
    });

    const [eduState, setEduState] = useState({
        visible: false,
        educations: {},
    });

    let [studentList, setStudentList] = useState([]);

    useEffect(() => {
    }, []);

    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            if (filterState.name) {
                params.name = filterState.name;
            }
            if (filterState.email) {
                params.email = filterState.email;
            }
            if (filterState.mobile) {
                params.mobile = filterState.mobile;
            }

            let data = await studentListFxn({
                ...params,
                regExFilters: ["name", "email", "mobile"],
            });
            setTotal(data.total);
            resolve(data);
        });
    };

    const events = {
        reloadTable: () => {
            tableRef.current.reload();
        },
        showApplication: (record) => {
            setAppState({
                visible: true,
                studentId: record._id,
                studentName: record.name,
            });
        },
        hideApplications: () => {
            events.reloadTable();
            setAppState({
                visible: false,
                studentId: "",
                studentName: "",
            });
        },
        showEducation: (educations) => {
            setEduState({
                visible: true,
                educations,
            });
        },
        hideEducations: () => {
            setEduState({
                visible: false,
                educations: {},
            });
        },
        updateFilter: (data) => {
            setFilterState((prevState) => {
                return {
                    ...prevState,
                    ...data,
                };
            });
        },
        filterFxn: () => {
            tableRef.current.reload();
        },
        clearFilter: () => {
            setFilterState((prevState) => {
                return {
                    ...prevState,
                    ...initFilter,
                };
            });
            setTimeout(() => {
                tableRef.current.reload();
            }, 500);
        },
    };

    let columns = [
        {
            title: "Sr. No",
            key: "index",
            dataIndex: "index",
            render: (item, record, index) => {
                return index + 1;
            },
        },
        {
            title: "Name",
            key: "name",
            dataIndex: "name",
        },
        {
            title: "DOB",
            key: "dateOfBirth",
            dataIndex: "dateOfBirth",
            render: (item) => {
                return item ? displayDate(item) : "";
            },
        },
        {
            title: "State",
            key: "stateName",
            dataIndex: "stateName",
        },
        {
            title: "City",
            key: "cityName",
            dataIndex: "cityName",
        },
        {
            title: "Mobile",
            dataIndex: "mobile",
            key: "mobile",
            render: (item, record) => {
              
              const countryCode = record.countryCode.substring(3)
              return <>{`${countryCode}-${item}`}</>;
            },
          },
        {
            title: "Applications",
            key: "count",
            dataIndex: "count",
            render: (item, record) => {
                return (
                    <a
                        className={"btn btn-link p-0 mt-1"}
                        onClick={() => events.showApplication(record)}
                    >
                        {item} Applications
                    </a>
                );
            },
        },
        {
            title: "Added On",
            key: "createdAt",
            dataIndex: "createdAt",
            render: (item) => {
                return item ? displayDate(item) : "";
            },
        },
        {
            title: "Assigned To",
            dataIndex: "assignedUserId",
            key: "assignedUserId",
            render: (item, record) => {
                return <>{item && item.name ? item.name : ""}</>;
            },
        },
        {
            title: "Action",
            key: "_id",
            dataIndex: "_id",
            render: (item, record) => {
                return (
                    <>
                        <button
                            className={"btn"}
                            onClick={() => {
                                navigate(`/student-profile?_id=${record._id}`);
                            }}
                        >
                            <img src="../app/img/icons/view.svg"/>
                        </button>

                        <button
                            className={"btn"}
                            onClick={() => {
                                navigate(`/edit-student?_id=${record._id}`);
                            }}
                        >
                            <img src="../app/img/icons/edit.svg"/>
                        </button>
                    </>
                );
            },
        },
    ];
    return (
        <>
            <TableCard
                title={`Total ${total} Students`}
                extraBtn={[
                    {
                        name: "Add Student",
                        action: () => {
                            navigate("/add-student");
                        },
                    },
                ]}
            >
                <div className="filter px-4 mb-4">
                    <form>
                        <div className="form-group search-box">
                            <img src={imgPath.search} alt=""/>{" "}
                            <input
                                type="text"
                                placeholder="Name"
                                className="form-control"
                                value={filterState.name}
                                onChange={({target}) => {
                                    events.updateFilter({
                                        name: target.value,
                                    });
                                }}
                            />
                        </div>
                        {/* <div className="form-group search-box">
                            <img src={imgPath.search} alt=""/>{" "}
                            <input
                                type="text"
                                placeholder="Email"
                                className="form-control"
                                value={filterState.email}
                                onChange={({target}) => {
                                    events.updateFilter({
                                        email: target.value,
                                    });
                                }}
                            />
                        </div> */}
                        <div className="form-group search-box">
                            <img src={imgPath.search} alt=""/>{" "}
                            <input
                                type="text"
                                placeholder="Mobile No"
                                className="form-control"
                                value={filterState.mobile}
                                onChange={({target}) => {
                                    events.updateFilter({
                                        mobile: target.value,
                                    });
                                }}
                            />
                        </div>

                        <a className="btn blue-btn" onClick={events.filterFxn}>
                            Search
                        </a>
                        <a className="btn flat-btn" onClick={events.clearFilter}>
                            Clear
                        </a>
                    </form>
                </div>

                <Table columns={columns} apiRequest={apiRequest} ref={tableRef}/>
            </TableCard>

            {appState.visible ? (
                <ApplicationList {...appState} onClose={events.hideApplications}/>
            ) : (
                ""
            )}
        </>
    );
};

export default Form.create()(StudentList);
