import { hideLoader, showLoader } from "../../../actions/loader";
import notification from "../../../components/Elements/Notification";
import { customAxios as axios, getToken } from "../../../request";
import {
    activityListUrl,
    addApplicationUrl,
    addNoteUrl,
    applicationNoteUrl,
    noteListUrl,
    payTutionFeesUrl,
} from "../apis";

export const addNoteFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  let { data } = await axios.post(addNoteUrl(), valData, getToken());
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  } else {
    notification.success({
      message: data.message || "Success",
    });
  }
  return data;
};
export const noteListFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  let config = {
    params: { ...valData },
    ...(await getToken()),
  };
  let { data } = await axios.get(noteListUrl(), config);
  dispatch(hideLoader());
  return data;
};

export const applicationNoteFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  let { data } = await axios.post(applicationNoteUrl(), valData, getToken());
  dispatch(hideLoader());
  return data;
};

export const addAppliactionFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  let { data } = await axios.post(addApplicationUrl(), valData, getToken());
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  } else {
    notification.success({
      message: data.message || "Success",
    });
  }
  return data;
};
export const activityListFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let config = {
        params: {...valData},
        ...(await getToken()),
    };
    let {data} = await axios.get(activityListUrl(), config);
    dispatch(hideLoader());
    return data;
};

export const payTutionFeesFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(payTutionFeesUrl(), valData, getToken());
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};
