import React, {useEffect, useState} from "react"
import {Button, Card, Drawer, Form, notification} from "../../../components/Elements/appUtils";
import {Countries, Flags} from "../../../components/_utils/appUtils";
import {genderOption, leadSourceOption, maritalStatusOption} from "../../../components/_utils/_utils";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import _ from "lodash";
import {LoadState} from "../../../components/_utils/countryUtil";
import {loadCountryList} from "../../Masters/state/action";
import {updateLeadFxn, getRelatedCounsellorFxn, getSingleLeadFxn} from "../actions/index";
import {useDispatch} from "react-redux";
import moment from "moment";

const EditStudentTest = (props) => {
    let dispatch = useDispatch()
    const {getFieldDecorator} = props.form;
    const [countryList, setCountryList] = useState([]);
    const [counselorList, setCounselorList] = useState([])
    const [stateArray, setStateArray] = useState([]);
    let [formState, setState] = useState({});
    let {visible, onClose, leadId, onSubmit} = props;
    let inputTypes = {
        fields: [
            {
                key: "firstName",
                label: "First Name",
                required: true,
                placeholder: "First Name",
                type: "text",
                span: 'col-md-4'
            },
            {
                key: "lastName",
                label: "Last Name",
                required: true,
                placeholder: "Last Name",
                type: "text",
                span: 'col-md-4'
            },
            {
                key: "email",
                label: "Email",
                required: true,
                placeholder: "Email",
                type: "text",
                span: 'col-md-4'
            },
            {
                key: "countryCode",
                label: "Country Code",
                required: true,
                placeholder: "Country Code",
                type: 'select',
                options: Flags,
                showSearch: true,
                keyAccessor: (x) => x.countryCode,
                valueAccessor: (x) => `${x.name} (${x.dialCode})`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        countryCode: x,
                    });
                },
                span: 'col-md-3'
            },
            {
                key: "mobileNo",
                label: "Mobile No",
                required: true,
                placeholder: "Mobile No ",
                type: "text",
                span: 'col-md-3'
            },
            {
                key: "dateOfBirth",
                label: "Date Of Birth ",
                required: true,
                placeholder: "Date Birth ",
                type: "date",
                span: 'col-md-4',
                keyAccessor: (x) => x.option,
                valueAccessor: (x) => `${x.option}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        dateOfBirth: x,
                    });
                },
            },
            {
                key: "gender",
                label: "Gender",
                required: true,
                placeholder: "Gender ",
                type: "select",
                keyAccessor: (x) => x.option,
                valueAccessor: (x) => `${x.option}`,
                options: genderOption,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        gender: x,
                    });
                },
                span: 'col-md-4'
            },
            {
                key: "maritalStatus",
                label: "Marital Status",
                required: true,
                placeholder: "Marital Status",
                type: "select",
                keyAccessor: (x) => x.option,
                valueAccessor: (x) => `${x.option}`,
                options: maritalStatusOption,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        maritalStatus: x,
                    });
                },
                span: 'col-md-4'
            },
        ]
    }
    let AddressInfo = {
        fields: [
            {
                key: "countryName",
                label: "Country",
                required: true,
                showSearch: true,
                placeholder: "Country Name",
                type: 'select',
                options: Countries,
                keyAccessor: (x) => x.name,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        countryName: x,
                        stateName: "",
                        cityName: "",
                    });
                    events.chooseCountry(x);
                },
                span: 'col-md-4'
            },
            {
                key: "stateName",
                label: "State",
                required: true,
                showSearch: true,
                placeholder: "State Name",
                type: 'select',
                options: stateArray,
                keyAccessor: (x) => x.name,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        stateName: x,
                        cityName: ""
                    });
                    events.chooseState(x)
                },
                span: 'col-md-4',
            },
            {
                key: "cityName",
                label: "City",
                required: true,
                placeholder: "City Name",
                type: "text",
                span: 'col-md-4'
            },
            {
                key: "pinCode",
                label: "Pin Code",
                required: true,
                placeholder: "Pin Code",
                type: "text",
                span: 'col-md-4'
            },
            {
                key: "address",
                label: "Address",
                required: true,
                placeholder: "Address",
                type: "text",
                span: 'col-md-4'
            },
        ]
    }

    useEffect(() => {
        getSingleLead()
    }, [leadId])

    useEffect(() => {
        handleLead()
    }, [])

    const events = {
        chooseCountry: (name) => {
            let countryName = _.find(Countries, (item) => {
                return item.name == name
            })
            if (countryName) {
                setState({
                    ...formState,
                    countryName: countryName.name ? countryName.name : ''
                })
                setStateArray(countryName.id ? LoadState(countryName.id) : [])
            }
        },
        chooseState: (name) => {
            let stateName = _.find(stateArray, (item) => {
                return item.name == name
            })
            if (stateName) {
                setState({
                    ...formState,
                    stateName: stateName.name ? stateName.name : ''
                })
            }
        }
    }

    const getSingleLead = async (id) => {
        let {data} = await dispatch(getSingleLeadFxn(leadId));
        setTimeout(() => {
            props.form.setFieldsValue({
                firstName: data.firstName,
                lastName: data.lastName,
                gender: data.gender,
                mobileNo: data.mobileNo,
                countryCode: data.countryCode,
                address: data.address,
                email: data.email,
                maritalStatus: data.maritalStatus,
                countryName: data.countryName,
                stateName: data.stateName,
                cityName: data.cityName,
                pinCode: data.pinCode,
                dateOfBirth: data.dateOfBirth ? moment(data.dateOfBirth) : ""
            })
            if (data.countryName) {
                events.chooseCountry(data.countryName);
            }
        }, 500)
    }


    const handleSubmit = (e) => {
        const {form} = props;
        e.preventDefault();
        form.validateFields(async (err, valData) => {
            if (!err) {
                valData.leadId = leadId;
                let x = await dispatch(updateLeadFxn(valData));
                if (x && x.success) {
                    onSubmit()
                }
            } else {
                notification.warning({
                    message: 'Fill All Required Fields'
                })
            }
        });
    }

    const handleLead = async () => {
        let x = await loadCountryList();
        setCountryList(x.data)
    }


    return (
        <Drawer visible={visible} onClose={onClose} title={'Edit Lead'} width={'80%'}>
            <Form onSubmit={handleSubmit}>
                <Card>
                    <div className={"row"}>
                        {inputTypes.fields.map((item, key) => {
                            return (
                                <div className={`pt-3 ${item.span ? item.span : "col-md-4"}`} key={key}>
                                    <GetEachFormFields
                                        {...props.form}
                                        item={item}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </Card>
                <Card title={"Address Information"}>
                    <div className={"row"}>
                        {AddressInfo.fields.map((item, key) => {
                            return (
                                <div className={"col-md-4 pt-3"} key={key}>
                                    <GetEachFormFields
                                        {...props.form}
                                        item={item}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    <div className={'mt-4'}>
                        <Button type={"primary"} htmlType="submit">
                            submit
                        </Button>
                    </div>
                </Card>
            </Form>
        </Drawer>
    )
}
export default Form.create()(EditStudentTest)
