import React from "react";
import {
    Modal,
    Card
} from "../../components/Elements/appUtils";
import AddGicComponent from "./components/addGicComponent";

const AddGicDrawer = (props) => {
    let {visible, onClose} = props;

    return (
        <Modal
            visible={visible}
            onClose={onClose}
            width={"50%"}
            title={'Add Gic Request'}
            placement="right"
        >
            <Card shadow={false} padding={false}>
                <AddGicComponent {...props}/>
            </Card>
        </Modal>
    );
};
export default AddGicDrawer;
