import moment from "moment";
import { default as React, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import StyleEditor from "react-style-editor";
import {
  Button,
  Card,
  Form,
  InputBox,
  notification,
} from "../../../components/Elements/appUtils";
import { GetEachFormFields } from "../../../components/_utils/formUtils";
import { getRelatedUniversityListFxn } from "../../Course/action";
import { loadCountryList } from "../../Masters/state/action";
import { getSingleTemplatetFxn } from "../../templateView/actions";
import {courseCategoryList } from "../../Masters/courseCategory/action";
import {
  AddTemplate,
  getRelatedCourseListFxn,
  updateTemplateFxn,
} from "../actions";

const StyleComponent = (props) => {
  let { title, onChange, value } = props;
  return (
    <div className={"mt-3"}>
      <label style={{marginBottom: 5, marginLeft: 10}}>{title}</label>
      <StyleEditor
          style={{backgroundColor: "white", minHeight: 200}}
          value={value}
          onChange={(e) => {
            onChange(e);
          }}
      />
    </div>
  );
};

const EditTemplate = (props) => {
  let dispatch = useDispatch();
  const { getFieldDecorator } = props.form;
  const [countryList, setCountryList] = useState([]);
  const [universityList, setUniversityList] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [templateId, setTemplateId] = useState("");
  let [editorKey, setEditorKey] = useState(moment());
  let [headerStyle, setHeaderStyle] = useState("");
  let [bodyStyle, setBodyStyle] = useState("");
  let [borderStyle, setBorderStyle] = useState("");
  let [footerStyle, setFooterStyle] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [originalDocument, setOriginalDocument] = useState({});
  const [oldOriginalDocument, setOldOriginalDocument] = useState({});

  const chooseOriginalTemplate = (e) => {
    const file = e.target.files[0];
    setOriginalDocument(file);
  };
  const handleSubmit = (e) => {
    const { form } = props;
    e.preventDefault();

    form.validateFields(async (err, valData) => {
      if (!err) {
        let fd = new FormData();
        valData.templateId = templateId;
        valData.headerStyle = headerStyle;
        valData.borderStyle = borderStyle;
        valData.bodyStyle = bodyStyle;
        valData.footerStyle = footerStyle;
        fd.append("obj", JSON.stringify(valData));

        if (originalDocument && originalDocument.name) {
          fd.append("originalDocument", originalDocument);
        }
        let x = await dispatch(updateTemplateFxn(fd));
      } else {
        notification.warning({
          message: "Fill All Required Fields",
        });
      }
    });
  };

  useEffect(() => {
    loadCountryFxn();
    getSingleTemplate();
    handleCourseCategory();
  }, []);

  const handleCourseCategory = async () => {
    let x = await courseCategoryList();
    setCategoryList(x.data.data);
  };

  const getSingleTemplate = async () => {
    let searchParams = new URLSearchParams(window.location.search);
    let templateId = searchParams.get("_id");
    if (templateId) {
      let { data } = await getSingleTemplatetFxn(templateId);
      if (!data.error) {
        setTemplateId(templateId);
      if(data.countryId && data.countryId._id ){
        getRelatedUniversity(data.countryId._id);
      }
      if(data.universityId && data.universityId._id){
        getRelatedCourse(data.universityId._id);
      }

        setTimeout(() => {
          props.form.setFieldsValue({
              countryId: data.countryId && data.countryId._id ? data.countryId._id : null,
              universityId: data.universityId && data.universityId._id ? data.universityId._id : null,
              courseId: data.courseId && data.courseId._id ? data.courseId._id : null,
              categoryId: data.categoryId,
              templateName: data.templateName,
              headerDesign: data.headerDesign,
              footerDesign: data.footerDesign,
              fontsLink: data.fontsLink,
              // categoryId: data. categoryId,
          });
          setHeaderStyle(data.headerStyle);
          setBorderStyle(data.borderStyle);
          setBodyStyle(data.bodyStyle);
          setFooterStyle(data.footerStyle);

          if (data.originalDocument && data.originalDocument.path) {
            setOldOriginalDocument(data.originalDocument);
          }
          setEditorKey(moment());
        }, 500);
      }
    }
  };

  const loadCountryFxn = async () => {
    let x = await loadCountryList();
      setCountryList(x);
  };

  const getRelatedUniversity = async (id) => {
    if (id) {
      let { data } = await getRelatedUniversityListFxn(id);
      setUniversityList(data);
    }
  };
  const getRelatedCourse = async (id) => {
    if (id) {
      let { data } = await getRelatedCourseListFxn(id);
      setCourseList(data.data);
    }
  };

  let inputTypes = {
    fields: [
      {
        key: "countryId",
        label: "Country",
        required: true,
        placeholder: "Country Name",
        type: "select",
        options: countryList,
        keyAccessor: (x) => x._id,
        valueAccessor: (x) => `${x.countryName}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            countryId: x,
          });
          getRelatedUniversity(x);
        },
      },

      {
        key: "universityId",
        label: "University",
        required: true,
        placeholder: "University Name",
        type: "select",
        options: universityList,
        keyAccessor: (x) => x._id,
        valueAccessor: (x) => `${x.universityName}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            universityId: x,
          });
          getRelatedCourse(x);
        },
      },

      {
        key: "courseId",
        label: "Course",
        required: true,
        placeholder: "Course",
        type: "select",
        options: courseList,
        keyAccessor: (x) => x._id,
        valueAccessor: (x) => `${x.courseName}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            courseId: x,
          });
        },
      },

      {
        key: "templateName",
        label: "Template Name ",
        required: true,
        placeholder: "Template Name",
        type: "text",
      },

      {
        key: "headerDesign",
        label: "Header Design",
        editorKey: editorKey,
        type: "ckeditor",
        onChange: (x) => {
          props.form.setFieldsValue({
            headerDesign: x,
          });
        },
      },
      {
        key: "footerDesign",
        editorKey: editorKey,
        label: "Footer Design",
        type: "ckeditor",
        onChange: (x) => {
          props.form.setFieldsValue({
            footerDesign: x,
          });
        },
      },

        {
            key: "originalDocument",
            label: "Original Document",
            placeholder: "Original Document",
            type: "file",
            fileName: originalDocument,
            urlPath:
                oldOriginalDocument && oldOriginalDocument.path
                    ? oldOriginalDocument.path
                    : "",
            onChange: chooseOriginalTemplate,
        },
      {
        key: "categoryId",
        label: "Category",
        // required: true,
        placeholder: "Category",
        type: "select",
        options: categoryList,
        keyAccessor: (x) => x._id,
        valueAccessor: (x) => `${x.categoryName}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            categoryId: x,
          });
        },

      },
      {
        key: "fontsLink",
        // required: true,
        label: "Fonts Link",
        placeholder: "Fonts Link",
        type: "textarea",
        span: "col-md-12",
      },
    ],
  };
  return (
    <>
      <Card title={"Edit Template"}>
        <Form onSubmit={handleSubmit}>
          <div className={"row"}>
            {inputTypes.fields.map((item, key) => {
              return (
                <div
                  className={`pt-3 ${item.span ? item.span : "col-md-6"}`}
                  key={key}
                >
                  <GetEachFormFields
                    {...props.form}
                    item={item}
                  />
                </div>
              );
            })}
          </div>

          <div className={"row"}>
            <div className={"col-md-12"}>
              <StyleComponent
                title={"Header Style"}
                value={headerStyle}
                onChange={(e) => {
                  setHeaderStyle(e);
                }}
              />
            </div>
            <div className={"col-md-12"}>
              <StyleComponent
                title={"Border Style"}
                value={borderStyle}
                onChange={(e) => {
                  setBorderStyle(e);
                }}
              />
            </div>
            <div className={"col-md-12"}>
              <StyleComponent
                title={"Body Style"}
                value={bodyStyle}
                onChange={(e) => {
                  setBodyStyle(e);
                }}
              />
            </div>
            <div className={"col-md-12"}>
              <StyleComponent
                title={"Footer Style"}
                value={footerStyle}
                onChange={(e) => {
                  setFooterStyle(e);
                }}
              />
            </div>
          </div>

          <div style={{ marginTop: "20px" }}>
            <Button type={"primary"} htmlType="submit">
              submit
            </Button>
          </div>
        </Form>
      </Card>
    </>
  );
};

export default Form.create()(EditTemplate);
