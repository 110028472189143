import axios from "axios";
import notification from "./components/Elements/Notification";
import { apiUrl } from "./settings";
export const API_URL = apiUrl;

export let authAxios = axios.create({
  baseURL: apiUrl,
});

export const getToken = () => {
  return {
    headers: {
        "Access-Control-Allow-Origin": "*",
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH',
        'Access-Control-Allow-Credentials': true,
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'X-Requested-With': 'XMLHttpRequest',
        'crossdomain': true,
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0'

    },
  };
};

export let customAxios = axios.create({});

customAxios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    handleErr(error);
    return Promise.reject(error);
  }
);

export const handleErr = (err) => {
  try {
    if (err.response.status === 401) {
      localStorage.clear();
      window.location.reload();
      console.log("this should be here ");
    }
  } catch (e) {}
};

class Request {
  constructor() {}

  error = (err) => {
    try {
      if (err.response.status === 401) {
        localStorage.clear();
      }
    } catch (e) {}
  };

  login(data) {
    console.log(data, "datatataatatt");
    return new Promise((next, error) => {
      authAxios
        .post("/login", data)
        .then((d) => {
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }

  forgotPassword(data) {
    return new Promise((next, error) => {
      authAxios
        .post("/forget/password", data)
        .then((d) => {
          let { data } = d;
          // console.log('d', data)
          if (!data.error) {
            notification.success({
              message: data.message,
            });
          }
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }

  ResetPassword(data) {
    return new Promise((next, error) => {
      authAxios
        .put("/reset-password", data)
        .then((d) => {
          let { data } = d;
          // console.log('d', data)
          if (!data.error) {
            notification.success({
              message: data.message,
            });
          }
          next(d.data);
        })
        .catch((err) => {
          next({ error: true, err });
          this.error(err);
        });
    });
  }
}

export default new Request();
