import React, {useRef} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Card, HyperLink, Table} from "../../../components/Elements/appUtils";
import {userListFxn} from "../../Counsellor/action";

let {TableCard} = Card;

const EnglishTeacherList = () => {
    const navigate = useNavigate();

    let dispatch = useDispatch();
    let tableRef = useRef();
    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            let data = await dispatch(
                userListFxn({
                    ...params,
                    userType: "englishTeacher",
                    regExFilters: ["name"],
                })
            );
            resolve(data);
        });
    };

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            //   searchTextName: "name",
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            //   searchTextName: "email",
        },
        {
            title: "Mobile No",
            dataIndex: "mobile",
            key: "mobile",
            //   searchTextName: "mobile",
        },

        {
            title: "Address",
            dataIndex: "address",
            key: "address",
            //   searchTextName: "address",
        },
        {
            title: "Action",
            dataIndex: "action",
            width: 190,
            render: (val, record) => {
                return (
                    <>
                        <button
                            className={"btn"}
                            onClick={() => {
                                navigate(`/edit-englishTeacher?_id=${record._id}`);
                            }}
                        >
                            <img src={"../app/img/icons/edit.svg"}/>
                        </button>
                    </>
                );
            },
        },
    ];

    return (
        <>
            {/* <Card title={"Counsellor List"} rightContent={(
                <HyperLink className={'btn btn-primary btn-sm'} link={'/add-counsellor'}>Add Counsellor</HyperLink>
            )}>

            </Card> */}
            <TableCard
                title={"Englsih Teacher List"}
                extraBtn={[
                    {
                        name: "Add EnglishTeacher",
                        action: () => {
                            navigate("/add-english-teacher");
                        },
                    },
                ]}
            >
                <Table apiRequest={apiRequest} columns={columns} ref={tableRef}/>
            </TableCard>
        </>
    );
};

export default EnglishTeacherList;
