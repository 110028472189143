import { hideLoader, showLoader } from "../../actions/loader";
import notification from "../../components/Elements/Notification";
import { customAxios as axios, getToken } from "../../request";
import {
    CountryListUrl,
    activateCountryUrl,
    addCountryStatusUrl,
    addCountryUrl,
    addStatusToCountryUrl,
    getCountryUrl,
    listCountryStatusUrl,
    loadPreviousCountryListUrl,
    moveStatusToCountryUrl,
    singleCountryStatusListUrl,
    singleCountryStatusUrl,
    updateCountryUrl,
    updateStatusInCountryUrl,
    updateStatusToggleInCountryUrl,
} from "./api";

export const AddCountryFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  let { data } = await axios.post(addCountryUrl(), valData, getToken());
  dispatch(hideLoader());
  if (data.error) {
      notification.error({
          message: data.message || "Error",
      });
  } else {
      notification.success({
          message: data.message || "Success",
      });
  }
    return data.data;
};
export const ActivateCountryFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(activateCountryUrl(), valData, getToken());
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
    });
  }
  return data;
};
export const CountryListFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());

  let config = {
    params: valData,
    ...(await getToken()),
  };

  let { data } = await axios.get(CountryListUrl(), config);

  dispatch(hideLoader());

  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  }

  return data;
};

export const getRelatedCountryFxn = (id) => async (dispatch) => {
  dispatch(showLoader());
  let config = {
    ...(await getToken()),
  };
  let { data } = await axios.get(getCountryUrl(id), config);
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  }

  return data;
};

export const updateCountryFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  let { data } = await axios.post(updateCountryUrl(), valData, getToken());
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  } else {
    notification.success({
      message: data.message || "Success",
    });
  }
  return data;
};
export const addCountryStatusFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  let { data } = await axios.post(addCountryStatusUrl(), valData, getToken());
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  } else {
    notification.success({
      message: data.message || "Success",
    });
  }
  return data;
};

export const singleCountryStatusFxn = async (valData) => {
  let { data } = await axios.post(
    singleCountryStatusUrl(),
    valData,
    getToken()
  );
  return data;
};

export const loadPreviousCountryList = async (params = {}) => {
  params.results = 100;
  let config = {
    params,
    ...(await getToken()),
  };
  let { data } = await axios.get(loadPreviousCountryListUrl(), config);
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  }
  return data.data;
};

export const listCountryStatusFxn = async (params = {}) => {
  params.results = 100;
  let config = {
    params,
    ...(await getToken()),
  };
  let { data } = await axios.get(listCountryStatusUrl(), config);
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  }
  return data.data;
};
export const singleCountryStatusListFxn = async (valData) => {
    let config = {
        params: {...valData},
        ...(await getToken()),
    };
    let {data} = await axios.get(singleCountryStatusListUrl(), config);
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    }
    return data;
};

export const addStatusToCountryFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(addStatusToCountryUrl(), valData, getToken());
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};
export const updateStatusInCountryFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(
        updateStatusInCountryUrl(),
        valData,
        getToken()
    );
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};
export const updateStatusToggleInCountryFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(
        updateStatusInCountryUrl(),
        valData,
        getToken()
    );
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};
export const moveStatusToCountryFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(
        moveStatusToCountryUrl(),
        valData,
        getToken()
    );
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};

export const countryStatusUpdateToggleInCountryFxn =
    (valData) => async (dispatch) => {
        dispatch(showLoader());
        let {data} = await axios.post(
            updateStatusToggleInCountryUrl(),
            valData,
            getToken()
        );
        dispatch(hideLoader());
        if (data.error) {
            notification.error({
                message: data.message || "Error",
            });
        } else {
            notification.success({
                message: data.message || "Success",
            });
        }
        return data;
    };
