import React, {useRef, useEffect, useState} from "react";
import {
    Button,
    Card,
    Form,
    Table,
    Popconfirm,
    notification, TableButton,
} from "../../../components/Elements/appUtils";
import currencyList from "../../../../src/assets/jsonFiles/currency_list.json";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import {useDispatch} from "react-redux";
import {AddPlanFxn, PlansListFxn, DeletePlanFxn} from "../action";
import _ from "lodash";
import {useNavigate} from "react-router-dom";
import AddPlans from "../components/addPlan";

let {TableCard} = Card
const Plans = (props) => {
    const navigate = useNavigate();
    let dispatch = useDispatch();
    return (
        <>
            <AddPlans reloadList={() => {

            }}/>
        </>
    );
};

export default Form.create()(Plans);

