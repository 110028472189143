import {hideLoader, showLoader} from "../../actions/loader";
import notification from "../../components/Elements/Notification";
import {customAxios as axios, getToken} from "../../request";
import {
    FeedbackListUrl,
    ResolveFeedbackUrl,
    addFeedbackUrl,
    deleteFeedbackUrl,
} from "./api";

export const AddFeedbackFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());

    let {data} = await axios.post(addFeedbackUrl(), valData, getToken());
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};
export const DeleteFeedbackFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());

    let {data} = await axios.post(deleteFeedbackUrl(), valData, getToken());
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};

export const FeedbackListFxn = (filter) => async (dispatch) => {
    dispatch(showLoader());
    let config = {
        params: filter,
        ...(await getToken()),
    };
    let {data} = await axios.get(FeedbackListUrl(), config);
    dispatch(hideLoader());
    return data;
};
export const ResolveFeedbackFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());

    let {data} = await axios.post(ResolveFeedbackUrl(), valData, getToken());
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};
