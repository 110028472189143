import {hideLoader, showLoader} from "../../actions/loader";
import notification from "../../components/Elements/Notification";
import {customAxios as axios, getToken} from "../../request";
import {IpAddressListUrl, addIpAddressUrl, deleteIpAddressUrl} from "./api";

// export const representingUniversityList = async (valData) => {
//   let config = {
//     params: { ...valData },
//     ...(await getToken()),
//   };
//   let { data } = await axios.get(representingUniversityListUrl(), config);
//   if (data.error) {
//     notification.error({
//       message: data.message || "Error",
//     });
//   }

//   return data;
// };

export const AddIpAddressFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());

  let {data} = await axios.post(addIpAddressUrl(), valData, getToken());
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  } else {
    notification.success({
      message: data.message || "Success",
    });
  }
  return data;
};

export const IpAddressListFxn = (filter) => async (dispatch) => {
  dispatch(showLoader());
  let config = {
    params: filter,
    ...(await getToken()),
  };
  let {data} = await axios.get(IpAddressListUrl(), config);
  dispatch(hideLoader());
  return data;
};

export const deleteIpAddressFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  let {data} = await axios.post(deleteIpAddressUrl(), valData, getToken());
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  } else {
    notification.success({
      message: data.message || "Success",
    });
  }
  return data;
};
