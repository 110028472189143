import {apiUrl} from "../../settings";

export const addStudentUrlTest = () => {
    return `${apiUrl}/addLead`;
};

export const counsellorUrl = () => {
    return `${apiUrl}/user`;
};

export const LeadListUrl = () => {
    return `${apiUrl}/leadList`;
};

export const addFollowUrl = () => {
    return `${apiUrl}/addFollowUp`;
};
export const addFollowListUrl = () => {
    return `${apiUrl}/followUpList`;
};

export const addCloseUrl = () => {
    return `${apiUrl}/closeLead`;
};

export const addDeleteUrl = () => {
    return `${apiUrl}/deleteLead`;
};

export const getRelatedCounsellorUrl = () => {
    return `${apiUrl}/getRelatedCounsellors`;
};

export const addTransferToAnotherUrl = () => {
    return `${apiUrl}/transferToAnotherCounsellor`;
};

export const getLeadUrl = (leadId) => {
    return apiUrl + '/getSingleLead/' + leadId
};

export const updateLeadUrl = () => {
    return apiUrl + '/updateLead'
};

export const singleFollowUpUrl = (id) => {
    return apiUrl + `/singleFollowUp/${id}`
};

export const updateFollowupUrl = () => {
    return apiUrl + `/updateFollowup`
};

export const getLeadFieldListUrl = () => {
    return apiUrl + `/leadFieldsList`
};

export const getRelatedUniversityUrl = (countryId) => {
    return apiUrl + "/getRelatedUniversity/" + countryId;
};
export const getCampusListUrl = () => {
    return `${apiUrl}/campusList`;
};
export const getCourseCampusListUrl = () => {
    return `${apiUrl}/courseCampusList`;
};

export const getIntakeListUrl = () => {
    return `${apiUrl}/intakeList`;
};
export const boardStreamListUrl = () => {
    return `${apiUrl}/boardStreamList`;
};

