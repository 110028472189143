import { apiUrl } from "../../settings";

export const representingUniversityListUrl = () => {
    return `${apiUrl}/representingUniversityList`;
};

export const addPartnerUrl = () => {
    return `${apiUrl}/addPartner`;
};
export const partnerListUrl = () => {
    return `${apiUrl}/partnerList`;
};

export const deletePartnerUrl = () => {
    return `${apiUrl}/deletePartner`;
};
