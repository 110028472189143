import {hideLoader, showLoader} from "../../../../actions/loader";
import notification from "../../../../components/Elements/Notification";
import {customAxios as axios, getToken} from "../../../../request";
import {
    CityListUrl,
    addRelatedCityUrl,
    getCityUrl,
    getRelatedStatesUrl,
    postCityUrl,
} from "../api";

export const getRelatedStateListFxn = async (id) => {
    let config = {
    ...(await getToken()),
  };
  let { data } = await axios.get(getRelatedStatesUrl(id), config);

  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  }
  return data;
};

export const AddCityFxn =(valData)=> async (dispatch) => {
    dispatch(showLoader())

    let {data} = await axios.post(addRelatedCityUrl(), valData, getToken());
   dispatch(hideLoader())
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    }
    else{
        notification.success({
          message: data.message || "Success",
        });
      }
    return data;
};

export const cityCategoryList = (valData) =>async (dispatch) => {
    dispatch(showLoader())
    let config = {
        params: {...valData},
        ...await getToken()
    }
    let {data} = await axios.get(CityListUrl(), config)
    dispatch(hideLoader())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }

  return data;
};

export const GetRelatedCityFxn = (id) => async (dispatch) => {
    dispatch(showLoader())
    let config = {
        ...(await getToken()),
    };
    let {data} = await axios.get(getCityUrl(id), config);
    dispatch(hideLoader())
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
  }

  return data;
};

export const postRelatedCityFxn =(valData) => async (dispatch) => {
    dispatch(showLoader())
  let { data } = await axios.post(postCityUrl(), valData, getToken());
    dispatch(hideLoader())
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  } else {
    notification.success({
      message: data.message,
    });
  }
  return data;
};
