import React, { useRef, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";
import {
  Card,
  HyperLink,
  Popconfirm,
  Table,
  Modal,
  InputBox,
  notification,
  Form,
  Button
} from "../../../components/Elements/appUtils";
import { userListFxn } from "../../Counsellor/action";
import { qualificationOptions } from "../../../components/_utils/_utils";
import { boardStreamListFxn } from "../../HR/actions";
import { postUpdateUserEducationFxn } from "./action";
import { Flags } from "../../../components/_utils/appUtils";
import WorkExperienceComp from "../../AddStudent/Components/WorkExperienceComp";
const eduStateInit = {
  qualification: "",
  percentage: "",
  board: "",
  university: "",
  stream: "",
  passingYear: "",
  courseName: "",
  schoolName: "",
};

const workStateInit = {
  companyName: "",
  position: "",
  time: "",
  
};
let boardFields = ["XII", "X"];
const EducationComponent = (props) => {
  let { data, index, eduEvents, education, deleteItem } = props;
  const [streamsList, setStreamList] = useState([]);
  const [eduState, setEduState] = useState(eduStateInit);
  const [boardList, setBoardList] = useState([]);

  const loadBoardList = async (params = {}) => {
    params.type = "Board";

    let x = await boardStreamListFxn(params);
    if (x.data) {
      setBoardList(x.data);
    }
  };

  const loadStreamList = async (params = {}) => {
    params.type = "Stream";
    let x = await boardStreamListFxn(params);
    if (x.data) {
      setStreamList(x.data);
    }
  };

  useEffect(() => {
    loadBoardList();
    loadStreamList();
  }, []);


  return (
    <>
      <div className="row new-border">
        <div className="col-md-11">
          <div className={"row"} key={index}>
            <div className={"col-md-2"}>
              <InputBox title={"Qualification"}>
                <select
                  className={"form-control"}
                  value={data.qualification}
                  onChange={({ target }) => {
                    eduEvents.updateState(
                      { qualification: target.value },
                      index
                    );
                  }}
                >
                  <option value={""}>Choose</option>
                  {qualificationOptions.map((item) => {
                    return (
                      <option key={item.option} value={item.option}>
                        {item.option}
                      </option>
                    );
                  })}
                </select>
              </InputBox>
            </div>

            {data.qualification == "Bachelor Degree" ||
            data.qualification == "Post Graduation" ||
            data.qualification == "Diploma" ? (
              <div className={"col-md-2"} name="universityName">
                <InputBox title={"University"}>
                  <input
                    placeholder=" Enter University"
                    className={"form-control"}
                    onChange={({ target }) => {
                      eduEvents.updateState(
                        { university: target.value },
                        index
                      );
                    }}
                    value={data.university}
                  />
                </InputBox>
              </div>
            ) : null}

            {data.qualification == "Bachelor Degree" ||
            data.qualification == "Post Graduation" ||
            data.qualification == "Diploma" ? (
              <div className={"col-md-2"} name="courseName">
                <InputBox title={"Course"}>
                  <input
                    placeholder="Please enter your Course"
                    className={"form-control"}
                    onChange={({ target }) => {
                      eduEvents.updateState(
                        { courseName: target.value },
                        index
                      );
                    }}
                    value={data.courseName}
                  />
                </InputBox>
              </div>
            ) : null}

            {data.qualification && boardFields.includes(data.qualification) ? (
              <div className={"col-md-2"} name="board">
                <InputBox title={"Board"}>
                  <select
                    className={"form-control"}
                    placeholder="Board"
                    onChange={({ target }) => {
                      eduEvents.updateState({ board: target.value }, index);
                    }}
                    value={data.board}
                  >
                    <option value={""}>Choose</option>

                    {boardList.map((item) => {
                      return (
                        <option key={item.name} value={item.name}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                </InputBox>
              </div>
            ) : null}

            {data.qualification == "X" || data.qualification == "XII" ? (
              <div className={"col-md-2"} name="schoolName">
                <InputBox title={"School Name"}>
                  <input
                    className={"form-control"}
                    placeholder="School Name"
                    onChange={({ target }) => {
                      eduEvents.updateState(
                        { schoolName: target.value },
                        index
                      );
                    }}
                    value={data.schoolName}
                  />
                </InputBox>
              </div>
            ) : null}

            <div className={"col-md-2"} name="passingYear">
              <InputBox title={"Passing Year"}>
                <input
                  className={"form-control"}
                  placeholder="Passing Year"
                  onChange={({ target }) => {
                    eduEvents.updateState({ passingYear: target.value }, index);
                  }}
                  value={data.passingYear}
                />
              </InputBox>
            </div>

            <div className={"col-md-2"} name="Percentage">
              <InputBox title={"Percentage"}>
                <input
                  placeholder="Please enter your percentage"
                  className={"form-control"}
                  onChange={({ target }) => {
                    eduEvents.updateState({ percentage: target.value }, index);
                  }}
                  value={data.percentage}
                />
              </InputBox>
            </div>

            {data.qualification == "XII" ? (
              <div className={"col-md-2 "} name="math">
                <InputBox title={"Stream"}>
                  <select
                    className={"form-control"}
                    placeholder="Stream"
                    onChange={({ target }) => {
                      eduEvents.updateState({ stream: target.value }, index);
                    }}
                    value={data.stream}
                  >
                    <option value={""}>Choose</option>

                    {streamsList.map((item) => {
                      return (
                        <option key={item.name} value={item.name}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                </InputBox>
              </div>
            ) : null}
          </div>
        </div>
        <div className="col-md-1 div-icon">
          {index !== 0 ? (
            <i
              class="fa fa-trash new-icon"
              aria-hidden="true"
              onClick={() => deleteItem(index)}
            ></i>
          ) : null}
        </div>
      </div>
    </>
  );
};

const UserListComponent = (props) => {
  let dispatch = useDispatch();
  const { getFieldDecorator, getFieldValue, setFieldsValue } = props.form;
  const [openUserModalVisible, setOpenUserModalVisible
  ] = useState(false);
  const [openUserData, setOpenUserData] = useState(null);
  const [education, setEducation] = useState([eduStateInit]);
  const [eduState, setEduState] = useState(eduStateInit);
  const [experience, setExperience] = useState([workStateInit]);
  const events = {
    addMore: () => {
      setExperience([...experience, workStateInit]);
    },
    deleteItem: (itemId) => {
      const updatedItems = experience.filter((item, key) => key !== itemId);
      setExperience(updatedItems);
    },
    updateState: (data, index) => {
      let cloneStatus = _.clone(experience);
      cloneStatus[index] = { ...cloneStatus[index], ...data };
      setExperience(cloneStatus);
    },
    setCountryCode: (country) => {
      let countryCode = _.find(Flags, (item) => {
        return item.name == country;
      });
      if (countryCode) {
        setFieldsValue({ countryCode: countryCode.countryCode });
      }
    },
  };

  let tableRef = useRef();
  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      let data = await dispatch(
        userListFxn({  
          ...params,
          userType: "branchUser",
          regExFilters: ["name"],
        })
      );
      resolve(data);
      console.log(data);
    });
  };
  let user = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))
  : null;
useEffect(() => {
  // events.setCountryCode(user.countryName);
  setFieldsValue({ countryCode: user.countryCode });
}, []);

   const AllData = () => {
    setEducation([...education, eduStateInit]);
  };
  const deleteItem = (itemId) => {
    const updatedItems = education.filter((item, key) => key !== itemId);
    setEducation(updatedItems);
  };

  const eduEvents = {
    updateState: (data, index) => {
      let cloneEdu = _.clone(education);
      cloneEdu[index] = { ...cloneEdu[index], ...data };
      setEducation(cloneEdu);
    },
    addEdu: () => {
      if (education) {
        if (education.percentage < 1) {
          notification.warning({
            message: "Enter  greater then 0 Percentage",
          });
          return;
        }
        if (education.qualification == "XII") {
          if (education.math < 1) {
            notification.warning({
              message: "Enter greater then 0 Math score",
            });
            return;
          }
          if (education.english < 1) {
            notification.warning({
              message: "Enter greater then 0 English score",
            });
            return;
          }
        }
      }
      setEducation((preState) => {
        return [...preState, eduState];
      });
      eduEvents.updateState(eduStateInit);
    },
  };

  const handleOpenUser = (userData) => {
    console.log(userData, "userData")
    setOpenUserData(userData);
    setOpenUserModalVisible(true);
  };

  const handleModalCancel = () => {
    setOpenUserModalVisible(false);
    setOpenUserData(null);
  };

  const handleSubmit = (e) => {
    const { form } = props;
   
    e.preventDefault();
    form.validateFields(async (err, valData) => {
      if (!err) {
        valData.id = openUserData;
        valData = { ...valData, education };
        valData.workExperience = experience;
          console.log(valData, "valData")
    
        let x = await dispatch(postUpdateUserEducationFxn(valData));

        console.log(x, "ddddd");

        if (x && x.success) {
          form.resetFields();
          
          setEducation([eduStateInit]);
          setOpenUserModalVisible(false);
          setExperience([workStateInit])
        }
    
      } else {
       
       
        notification.warning({
          message: `Please Enter All Required Fields`,
        });
        return;
      }
    });
  };
  const columns = [
    {
      title: "Serial no",
      name: "serial",
      key: "serial",
      render: (text, record, index) => {
        return <div>{index + 1}</div>;
      },
      width: 100,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      searchTextName: "name",
      filterRegex: true,
      width: 250,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      searchTextName: "email",
      filterRegex: true,
      width: 250,
    },
    {
      title: "Mobile No",
      dataIndex: "mobile",
      key: "mobile",
      searchTextName: "mobile",
      filterRegex: true,
    },
    // {
    //     title: 'Country',
    //     dataIndex: 'countryId',
    //     key: 'countryId',
    //     render :(item,record)=>{
    //         //  console.log(item.countryName)
    //         return (
    //             <>
    //             {item&&item.countryName?item.countryName:null}
    //             </>)
    //      }
    // },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      searchTextName: "address",
      filterRegex: true,
    },
    {
      title: "Country Code",
      dataIndex: "countryCode",
      key: "countryCode",
      searchTextName: "countryCode",
      filterRegex: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 150,
      render: (val, record) => {
        return (
          <HyperLink
              type={"primary"}
              sm={true}
              link={`/edit-branch-user?_id=${record._id}`}
          >
            Edit
          </HyperLink>
          
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 150,
      render: (val, record) => {
      
        return (
          <span>
            <button className="btn btn-primary btn-sm" onClick={() => handleOpenUser(record._id)}>Edit Profile</button>
          </span>
        );
      },
    },

    
  ];

  


  return (
    <>
      <Card
        title={"Branch User List"}
        rightContent={
          <HyperLink
            className={"btn btn-primary btn-sm"}
            link={"/add-branch-user"}
          >
            Add User
          </HyperLink>
        }
      >
        <Table apiRequest={apiRequest} columns={columns} ref={tableRef} />
      </Card>
      <Form>
      <Modal
        style={{width: '960px'}}
        title="Edit Profile Details"
        visible={openUserModalVisible}
        onClose={handleModalCancel}
       
        footer={null}
      >
        <Card title={"Education"}>
          {education.map((data, index) => {
            return (
              <EducationComponent
                data={data}
                key={index}
                index={index}
                education={education}
                eduEvents={eduEvents}
                deleteItem={deleteItem}
              />
            );
          })}

          <a
            className={"btn btn-link "}
            style={{ float: "right" }}
            onClick={AllData}
          >
            Add Multiple Educations
          </a>
        </Card>
        <Card title={"Work Experience"}>
          {experience.map((data, index) => {
            return (
              <WorkExperienceComp
                data={data}
                key={index}
                index={index}
                experience={experience}
                updateState={events.updateState}
                deleteItem={events.deleteItem}
                handleFileChange={events.handleFileChange}
              />
            );
          })}

          <a
            className={"btn btn-link "}
            style={{ float: "right" }}
            onClick={events.addMore}
          >
            Add More Work Experiences
          </a>
        </Card>
        <button onClick={handleSubmit} className="btn btn-primary btn-sm">
                            submit
                        </button>
      
         
      </Modal>
      </Form>
   
    </>
  );
};

export default Form.create()(UserListComponent);

