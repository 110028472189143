import {apiUrl} from "../../settings";

export const getSingleApplicationUrl = () => {
    return apiUrl + "/getSingleApplication";
};

export const applicationListUrl = () => {
    return `${apiUrl}/applicationList`;
};
export const addNoteUrl = () => {
    return `${apiUrl}/addNote`;
};

export const addCommentUrl = () => {
    return `${apiUrl}/addComment`;
};
