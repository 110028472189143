import { apiUrl } from "../../settings";

export const addStudentUrl = () => {
  return `${apiUrl}/addStudent`;
};
export const getRelatedUniversityUrl = (countryId) => {
  return apiUrl + "/getRelatedUniversity/" + countryId;
};
export const getCampusListUrl = () => {
    return `${apiUrl}/campusList`;
};
export const getCourseCampusListUrl = () => {
    return `${apiUrl}/courseCampusList`;
};

export const getIntakeListUrl = () => {
  return `${apiUrl}/intakeList`;
};
export const boardStreamListUrl = () => {
  return `${apiUrl}/boardStreamList`;
};
