import React, {useEffect, useState} from 'react'
import {Button, Card, Drawer, Form} from '../../../components/Elements/appUtils'
import {GetEachFormFields} from '../../../components/_utils/formUtils';
import {GetRelatedCategoryFxn, postRelatedCategoryFxn} from '../actions';
import {useDispatch} from 'react-redux';

const TemplateCategoryEdit = (props) => {
    let {visible, hideEdit, categoryEditId} = props;
    const [categoryId, setCategoryId] = useState("");
    const {getFieldDecorator} = props.form;
    const dispatch = useDispatch();

    console.log(categoryEditId);

    const handleSubmit = (e) => {
        const {form} = props;
        e.preventDefault();
        form.validateFields(async (err, valData) => {
            console.log(err, valData);
            if (!err) {
                valData.templateCategoryId = categoryEditId
                let x = await dispatch(postRelatedCategoryFxn(valData));
                console.log(x);
            }
        });
    }

    // const getCampusData = async () => {
    //     let searchParams = new URLSearchParams(window.location.search)
    //      let id = searchParams.get('_id')
    //      if (id) {
    //         setCategoryId(id)

    //        }
    //     }

    const getRelatedCategory = async () => {
        let {data} = await dispatch(GetRelatedCategoryFxn(categoryEditId));
        setTimeout(() => {
            props.form.setFieldsValue({
                categoryName: data.categoryName
            })
        }, 1000)
    }

    useEffect(() => {
        getRelatedCategory()
    }, [])

    let inputTypes = {
        fields: [
            {
                key: "categoryName",
                label: "Category Name",
                required: true,
                placeholder: "Category Name",
                type: "text",
                onChange: (x) => {
                    props.form.setFieldsValue({
                        categoryName: x,
                    });
                },
                span: 8,
            },

        ],
    };

    return (
        <>
            <Drawer placement="right" onClose={hideEdit} visible={visible} width="65%" title={"Template Category Edit"}>
                <Form onSubmit={handleSubmit}>
                    <div className={"row"}>
                        {inputTypes.fields.map((item, key) => {
                            return (
                                <div className={"col-md-12 pt-3"} key={key}>
                                    <GetEachFormFields
                                        {...props.form}
                                        item={item}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    <Button type={"primary"} htmlType="submit" className={"mt-4"}>
                        submit
                    </Button>
                </Form>
            </Drawer>
        </>
    )
}

export default Form.create()(TemplateCategoryEdit);
