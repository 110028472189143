import React, {useEffect} from "react"
import {Card} from "../../components/Elements/appUtils";
import {apiUrl} from "../../settings";

const IntroductoryVideos = () => {
    useEffect(() => {
        setTimeout(() => {
            if (document.getElementById('introductoryVideo')) {
                document.getElementById('introductoryVideo').play()
            }
        }, 1000)
    }, [])
    return (
        <>
            <Card title={'Introductory Video'}>
                <video
                    width="100%"
                    height="600"
                    controls
                    id={'introductoryVideo'}
                    muted={true}
                    loop={true}>
                    <source src={`${apiUrl}/introductoryvideo.mp4`} type="video/mp4"/>
                </video>
            </Card>

        </>
    )
}
export default IntroductoryVideos
