import _, {add} from "lodash";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import S from "string";
import {
    Button,
    Card,
    Form,
    Modal,
    Popconfirm,
    displayDate,
    documentArray,
    notification,
} from "../../components/Elements/appUtils";
import {imgPath, leaveOption} from "../../components/_utils/_utils";
import {GetEachFormFields} from "../../components/_utils/formUtils";
import {
    GetRelatedUserFxn,
    SingleUserLeave,
    addLeave,
    deleteEmpDocumentFxn,
    getSingleEmpDocumentsFxn,
    getSingleEmpFxn,
    postUpdateLeave,
} from "./actions";
import UploadSingleDoc from "./uploadDocs";
import UploadNewDoc from "./uploadNewDoc";

const SingleEmpProfile = (props) => {
    const {getFieldDecorator, getFieldValue, setFieldsValue} = props.form;
    console.log(props.form, "proprrrrrrrrrrrrrrr");
    const [singleData, setSingleData] = useState();
    const [userId, setUserId] = useState();
    const [leaveData, setLeaveData] = useState([]);
    const [documents, setDocuments] = useState([]);
    let [singleDocState, setSingleDocState] = useState({
        visible: false,
        studentId: "",
        item: "",
        documentType: "",
        type: "",
    });
    let [newsingleDocState, setNewSingleDocState] = useState({
        visible: false,
        studentId: "",
        item: "",
    });

    const events = {
        showNewSingleDoc: (studentId) => {
            setNewSingleDocState({
                visible: true,
                studentId,
            });
        },
        hideNewSingleDoc: () => {
            setNewSingleDocState({
                visible: false,
                studentId: "",
            });
            singleStudentData();
        },
        // visibleFollowUp: () => {
        //     setOpenFollowUp(true);
        // },
        // hideFollowUp: () => {
        //     setOpenFollowUp(false);
        // },
        showSingleDoc: (documentType, type, studentId) => {
            setSingleDocState({
                visible: true,
                studentId,
                documentType,
                type,
            });
        },
        hideSingleDoc: () => {
            setSingleDocState({
                visible: false,
                studentId: "",
                item: "",
                documentType: "",
                type: "",
            });
            singleStudentData();
        },
        deleteSingleDocument: async (id) => {
            let data = await dispatch(
                deleteEmpDocumentFxn({documentId: id, userId})
            );

            console.log(data, "dataaaaa");

            if (data && data.success) {
                setDocuments((prevDocuments) =>
                    prevDocuments.map((category) => ({
                        ...category,
                        documentTypes: category.documentTypes.map((docType) => ({
                            ...docType,
                            documents: docType.documents.filter((doc) => doc._id !== id),
                        })),
                    }))
                );
            }
        },
    };

  let dispatch = useDispatch();

  useEffect(() => {
    singleStudentData();
  }, []);

  const navigate = useNavigate();

  let openLink = (link) => {
    navigate(link);
  };
  const singleStudentData = async () => {
    let searchParams = new URLSearchParams(window.location.search);
    let id = searchParams.get("_id");
    if (id) {
      setUserId(id);
      apiRequest(id);
      apiRequest2(id);
      loadDocuments(id);
    }
  };

  let apiRequest = async (id) => {
    let { data } = await dispatch(getSingleEmpFxn(id));
    setSingleData(data);
  };

  let inputTypes = {
    fields: [
      {
        key: "leave",
        label: "Leave",
        showStar: true,
        placeholder: "Select Leave",
        type: "select",
        keyAccessor: (x) => x.option,
        valueAccessor: (x) => `${x.option}`,
        options: leaveOption,
        onChange: (x) => {
          props.form.setFieldsValue({
            leave: x,
          });
        },
      },

      {
        key: "reason",
        label: "Reason",
        showStar: true,
        placeholder: "Reason",
        type: "text",
      },
      {
        key: "from_date",
        label: "From Date",
        placeholder: "From Date",
        type: "date",
        onChange: (x) => {
          props.form.setFieldsValue({
            from_date: x,
          });
        },
      },
      {
        key: "to_date",
        label: "To Date",

        placeholder: "To Date",
        type: "date",
        onChange: (x) => {
          props.form.setFieldsValue({
            to_date: x,
          });
        },
      },
    ],
  };

  let apiRequest2 = async (id) => {
    let { data } = await dispatch(SingleUserLeave(id));
    setLeaveData(data);
  };

  const [open, setOpen] = useState(false);

  const showModal = (e, id) => {
    e.preventDefault();
    setOpen(true);
    setUserId(id);
  };
  const onClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    const { form } = props;
    e.preventDefault();
    form.validateFields(async (err, valData) => {
      if (!err) {
        if (!valData.leave) {
          notification.warning({
            message: `Please select leave`,
          });
          return;
        }
        if (!valData.reason) {
          notification.warning({
            message: `Please enter reason`,
          });
          return;
        }

        if (!valData.from_date) {
          notification.warning({
            message: `Please select From Date`,
          });
          return;
        }

        if (!valData.to_date) {
          notification.warning({
            message: `Please enter to Date`,
          });
          return;
        }
        valData.userId = userId;
        let x = await dispatch(addLeave(valData));

        if (x && x.success) {
          apiRequest2(userId);
          setOpen(false);
          form.resetFields();
          props.form.setFieldsValue({
            leave: "",
            reason: "",
            from_date: "",
            to_date: "",
          });
        }
      } else {
        notification.warning({
          message: `Please Enter All Required Fields`,
        });
        return;
      }
    });
  };

  const getMatchingDocuments = (docType, docCategory) => {
    const matchingDocuments = [];

    for (const category of documents) {
      if (category.documentCategory === docCategory) {
        for (const docTypeObj of category.documentTypes) {
          if (docTypeObj.documentType === docType) {
            matchingDocuments.push(...docTypeObj.documents);
          }
        }
      }
    }

      return matchingDocuments;
  };
    const handleSubmitLeaveApproved = async (_id) => {
        let resp = await dispatch(postUpdateLeave({_id, status: "Approved"}));

        if (resp) {
            singleStudentData();
        }
    };

    let loadDocuments = async (id) => {
        let {data, success} = await dispatch(getSingleEmpDocumentsFxn(id));

        if (success && data) {
            const otherCategoryIndex = _.findIndex(documentArray, {name: "Other"});

            const otherCategory = _.find(data, {documentCategory: "Other"});
            if (otherCategory) {
                _.each(otherCategory.documentTypes, (docTypeObj) => {
                    const documentTypeToAdd = docTypeObj.documentType;
                    if (
                        !documentArray[otherCategoryIndex].documents.includes(
                            documentTypeToAdd
                        )
                    ) {
                        documentArray[otherCategoryIndex].documents.push(documentTypeToAdd);
                    }
                });
            }
        }
        setDocuments(data);
    };
  return (
    <>
      <div className="container-fluid pt-4 px-4">
        <div className="profile-box student-single-profile card-box border-grey mb-4 p-4">
          <div className="pic">
            <div className="upload-img">
              <span>
                <img src="../app/img/user.svg" alt="" className="w-100 h-100"/>
              </span>
            </div>
              <div className="content-box">
                  <h4>{singleData && singleData.name ? singleData.name : null} </h4>
                  <p>
                      {singleData && singleData.dateOfBirth
                          ? displayDate(singleData.dateOfBirth)
                          : null}
                  </p>
                  {singleData && singleData.status ? (
                      <>
                          {singleData.status === "Pending" ? (
                              <span className="pending">Pending</span>
                          ) : singleData.status === "Done" ? (
                              <span className="active">Done</span>
                          ) : singleData.status === "Closed" ? (
                              <span className="inactive">Closed</span>
                          ) : null}
                      </>
                  ) : null}
              </div>
          </div>
          <div className="detail">
              <ul>
                  <li>
                      <span>Name:</span>{" "}
                      {singleData && singleData.name ? singleData.name : null}{" "}
                  </li>
                  <li>
                      <span>City:</span>{" "}
                      {singleData && singleData.cityName ? singleData.cityName : null}
                  </li>
                  <li>
                      <span>Mobile:</span>
                      {singleData && singleData.mobile
                          ? singleData.countryCode.substring(3) +
                          " " +
                          singleData.mobile
                          : null}
                  </li>
                  <li>
                      <span>Pincode:</span>
                      {singleData && singleData.pincode ? singleData.pincode : null}
                  </li>
                  <li>
                      <span>Email:</span>
                      {singleData && singleData.email ? singleData.email : null}
                  </li>
                  <li>
                      <span>Address:</span>
                      {singleData && singleData.address ? singleData.address : null}
                  </li>
                  <li>
                      <span>Country:</span>
                {singleData && singleData.countryName
                  ? singleData.countryName
                  : null}{" "}
              </li>
              <li>
                <span>State:</span>{" "}
                {singleData && singleData.stateName
                  ? singleData.stateName
                  : null}
              </li>
            </ul>
          </div>
        </div>

        <div className="information-box card-box border-grey p-4 mb-4">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="Profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#Profile"
                type="button"
                role="tab"
                aria-controls="Profile"
                aria-selected="true"
              >
                Profile
              </button>
            </li>

            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="Education-tab"
                data-bs-toggle="tab"
                data-bs-target="#Education"
                type="button"
                role="tab"
                aria-controls="Education"
                aria-selected="false"
              >
                Education
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="Documents-tab"
                data-bs-toggle="tab"
                data-bs-target="#Documents"
                type="button"
                role="tab"
                aria-controls="Documents"
                aria-selected="false"
              >
                Documents
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="Experience-tab"
                data-bs-toggle="tab"
                data-bs-target="#Experience"
                type="button"
                role="tab"
                aria-controls="Experience"
                aria-selected="false"
              >
                Experience
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="Leave_Request-tab"
                data-bs-toggle="tab"
                data-bs-target="#Leave_Request"
                type="button"
                role="tab"
                aria-controls="Leave_Request"
                aria-selected="false"
              >
                Leave Request
              </button>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="Profile"
              role="tabpanel"
              aria-labelledby="Profile-tab"
            >
              {" "}
              <div className="detail-box">
                <ul>
                  <li>
                    <span>Name:</span>
                    {singleData && singleData.name ? singleData.name : null}
                  </li>
                  <li>
                    <span>Date:</span>{" "}
                    {singleData && singleData.dateOfBirth
                      ? displayDate(singleData.dateOfBirth)
                      : null}{" "}
                  </li>

                  <li>
                    <span>Gender:</span>{" "}
                    {singleData && singleData.gender ? singleData.gender : null}
                  </li>
                  <li>
                    <span>Marital Status:</span>{" "}
                    {singleData && singleData.maritalStatus
                      ? singleData.maritalStatus
                      : null}
                  </li>
                  <li>
                    <span>Address:</span>
                    {singleData && singleData.address
                      ? singleData.address
                      : null}{" "}
                  </li>
                  <li>
                    <span>Country:</span>
                    {singleData && singleData.countryName
                      ? singleData.countryName
                      : null}
                  </li>
                  <li>
                    <span>State:</span>
                    {singleData && singleData.stateName
                      ? singleData.stateName
                      : null}
                  </li>
                  <li>
                    <span>City:</span>
                    {singleData && singleData.cityName
                      ? singleData.cityName
                      : null}
                  </li>
                </ul>
              </div>
            </div>

            <div
              className="tab-pane fade"
              id="Education"
              role="tabpanel"
              aria-labelledby="Education-tab"
            >
              <div className="detail-box">
                <h3>Education</h3>

                {singleData &&
                  singleData?.education.map((item, index) => {
                    return (
                      <ul key={index}>
                        {Object.entries(item).map(
                          ([key, value]) =>
                            key !== "_id" &&
                            value && (
                                  <li key={key}>
                                      <span> {S(key).humanize().s}:</span> {value}
                                  </li>
                              )
                        )}
                      </ul>
                    );
                  })}
              </div>
            </div>
              <div
                  className="tab-pane fade"
                  id="Documents"
                  role="tabpanel"
                  aria-labelledby="Documents-tab"
              >
                  <a
                      href="#"
                      style={{
                          marginLeft: "82%",
                          color: "#3E6AB4",
                          textDecoration: "underline",
                      }}
                      onClick={(e) => {
                          e.preventDefault();
                          events.showNewSingleDoc(singleData._id);
                      }}
                  >
                      Add Other-Document
                  </a>
                  {documentArray.map((eachDoc) => {
                      return (
                          <>
                              <div className="detail-box">
                                  <div className="flex box">
                                      <h3>{eachDoc.name}</h3>
                                  </div>
                                  <div className={"upload_form"}>
                                      <div className="col-lg-12 col-12 mb-4">
                                          <div className="form-group">
                                              <div>
                                                  {eachDoc.documents && eachDoc.documents.length
                                                      ? eachDoc.documents.map((docType, index) => {
                                                          const matchingDocuments =
                                                              getMatchingDocuments(
                                                                  docType,
                                                                  eachDoc.name
                                                              );
                                                          return (
                                                              <div className="card-box border-grey p-3 mb-4">
                                        <div
                                          className="flex-box"
                                          style={{
                                            flexWrap: "nowrap",
                                            margin: 0,
                                          }}
                                        >
                                          <label className="form-control">
                                            {docType}{" "}
                                            {matchingDocuments.map(
                                              (document) => (
                                                  <span>
                                                  {document.name.length > 6
                                                      ? document.name.substring(
                                                          0,
                                                          6
                                                      ) +
                                                      "[...]" +
                                                      document.name.slice(-4)
                                                      : document.name}
                                                      <button
                                                          type="button"
                                                          className="p-0"
                                                      >
                                                    <Popconfirm
                                                        title="Are you sure, you want to delete this Document?"
                                                        onConfirm={() => {
                                                            events.deleteSingleDocument(
                                                                document._id
                                                            );
                                                        }}
                                                        okText="Yes"
                                                        cancelText="No"
                                                    >
                                                      <span>
                                                        <i
                                                            className="fa fa-times"
                                                            // onClick={() =>
                                                            //   events.deleteSingleDocument(
                                                            //     document._id
                                                            //   )
                                                            // }
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                        ></i>
                                                      </span>
                                                    </Popconfirm>
                                                  </button>
                                                </span>
                                              )
                                            )}
                                          </label>
                                          <div className="upload">
                                            <a
                                              className="btn blue-btn"
                                              onClick={(e) => {
                                                events.showSingleDoc(
                                                  docType,
                                                  eachDoc.name,
                                                  userId._id
                                                );
                                              }}
                                            >
                                              <img
                                                src={imgPath.upload}
                                                alt=""
                                              />
                                              Upload
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>

            <div
              className="tab-pane fade"
              id="Experience"
              role="tabpanel"
              aria-labelledby="Experience-Tab"
            >
              <div className="detail-box">
                <h3>Experience</h3>

                {singleData &&
                  singleData?.workExperience.map((item) => {
                    return (
                      <>
                        <ul>
                          <li>
                            <span>Company Name:</span>
                            {item.companyName}
                          </li>
                          <li>
                            <span>Position:</span> {item.position}
                          </li>
                          <li>
                            <span>Time:</span> {item.time}
                          </li>
                        </ul>
                      </>
                    );
                  })}
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="Leave_Request"
              role="tabpanel"
              aria-labelledby="Leave_Request-Tab"
            >
              <div class="detail-box">
                <div class="d-flex align-items-center justify-content-between mb-4 gap-3 flex-wrap">
                  <h3 class="mb-0">Leave Request</h3>
                  <button
                    class="btn blue-btn"
                    onClick={(e) => showModal(e, singleData._id)}
                  >
                    Apply Leave
                  </button>
                </div>

                <div class="leave-type mb-4">
                  <div class="row gy-4">
                    <div class="col-xl-3 col-lg-4 col-sm-6 col-12">
                      <div class="card-box border-grey p-3">
                        <h4 data-letters="CL">Casual Leaves</h4>
                        <p class="m-0">12 Leaves</p>
                      </div>
                    </div>
                    <div class="col-xl-3 col-lg-4 col-sm-6 col-12">
                      <div class="card-box border-grey p-3">
                        <h4 data-letters="EL">Earned Leaves</h4>
                        <p class="m-0">12 Leaves</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="table-responsive">
                  <table class="table text-start align-middle table-hover mb-0 data-table ">
                    <thead>
                      <tr class="text-dark">
                        <th scope="col">S.No.</th>
                        <th scope="col">Leave</th>
                        <th scope="col">From Date</th>
                        <th scope="col">To Date</th>
                        <th scope="col">Reason</th>
                        <th scope="col">Status</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {leaveData.map((val, key) => {
                        return (
                            <>
                                <tr>
                                    <td>{key + 1}</td>
                                    <td>{val.leave}</td>
                                    <td>{displayDate(val.from_date)}</td>
                                    <td>{displayDate(val.to_date)}</td>
                                    <td>{val.reason}</td>
                                    <td
                                        style={{
                                            color:
                                                val.status === "pending"
                                                    ? "#FFD700"
                                                    : val.status === "Approved"
                                                        ? "green"
                                                        : "inherit",
                                        }}
                                    >
                                        {val.status.charAt(0).toUpperCase() +
                                        val.status.slice(1)}
                                    </td>

                                    <td>
                                        {val.status == "pending" ? (
                                            <>
                                                <Popconfirm
                                                    title={
                                                        "Are your sure, you want to approve leave?"
                                                    }
                                                    okText="Yes"
                                                    cancelText="No"
                                                    onConfirm={() => {
                                                        handleSubmitLeaveApproved(val._id);
                                                    }}
                                                >
                                                    <img
                                                        src={imgPath.check}
                                                        className="btn-action"
                                                        style={{width: "25px"}}
                                                    />
                                                </Popconfirm>
                                  </>
                                ) : null}
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* <div
              class="modal fade"
              id="applyLeave"
              tabindex="-1"
              aria-labelledby="applyLeaveLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header p-4 pb-0">
                    <h1 class="modal-title fs-5" id="applyLeaveLabel">
                      Apply Leave
                    </h1>
                  
                  </div>
                  <div class="modal-body p-4 pb-0">
                  
                    <Form onSubmit={handleSubmit}>
                      {inputTypes.fields.map((item, key) => {
                        return (
                          <div
                            className={`pb-3 ${
                              item.span == 6 ? "col-md-6" : "col-md-12"
                            }`}
                            key={key}
                          >
                            <GetEachFormFields {...props.form} item={item} />
                          </div>
                        );
                      })}
                      <div class="modal-footer">
                        <Button
                          type={"primary"}
                          htmlType="submit"
                          className={" btn flat-btn"}
                        >
                          submit
                        </Button>
                        <button
                          type="button"
                          class="btn flat-btn"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div> */}
            <Modal
              visible={open}
              onClose={onClose}
              open={open}
              width={"30%"}
              title={"Edit Commission"}
            >
              <div>
                <Form onSubmit={handleSubmit}>
                  <div className={"row"}>
                    {inputTypes.fields.map((item, key) => {
                      return (
                        <div
                          className={`pb-3 ${
                            item.span == 6 ? "col-md-6" : "col-md-12"
                          }`}
                          key={key}
                        >
                          <GetEachFormFields {...props.form} item={item} />
                        </div>
                      );
                    })}
                  </div>

                  <div className={"mt-4"}>
                    <Button type={"primary"} htmlType="submit">
                      submit
                    </Button>
                  </div>
                </Form>
              </div>
            </Modal>
          </div>
        </div>
      </div>
      {newsingleDocState.visible ? (
        <UploadNewDoc
          uploadType={"Employee"}
          {...newsingleDocState}
          onClose={events.hideNewSingleDoc}
          userId={userId}
          onSubmit={() => {
            events.hideNewSingleDoc();
          }}
        />
      ) : null}
      {singleDocState.visible ? (
        <UploadSingleDoc
          {...singleDocState}
          onClose={events.hideSingleDoc}
          userId={userId}
          onSubmit={() => {
            events.hideSingleDoc();
          }}
        />
      ) : null}
    </>
  );
};
export default Form.create()(SingleEmpProfile);
