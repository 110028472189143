import {apiUrl} from "../../../settings";

export const addTemplateCategoryUrl = () => {
  return `${apiUrl}/addTemplateCategory`;
};

export const templateCategoryListUrl = () => {
    return `${apiUrl}/templateCategoryList`;
};

export const templateEditUrl = () => {
  return `${apiUrl}/updateTemplateSection`;
};

export const getTemplateCategoryUrl = (categoryId) => {
  return apiUrl + '/getRelatedTemplateSection/' + categoryId
};

export const getCategoryUrl = (categoryId) => {
  return apiUrl + '/getSingleTemplateCategory/' + categoryId
};

export const postCategoryUrl = () => {
  return `${apiUrl}/updateTemplateCategory`;
};
