import { hideLoader, showLoader } from "../../../actions/loader";
import notification from "../../../components/Elements/Notification";
import { customAxios as axios, getToken } from "../../../request";
import {
    ApplicationStatusUrl,
    CountryAllstatusUrl,
    countryStatusUrl,
    getSingleUserUrl,
    getUserCountryStatusWiseNewUrl,
    getUserCountryStatusWiseUrl,
    postUpdateUserUrl,
    getSingleEmployeeUrl,
    getEmployeeUrl,
    addBranchUrl,
    getUserUrl
} from "../views/api";

export const CountryStatusFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let config = {
        params: valData,
        ...(await getToken()),
    };
    let {data} = await axios.get(countryStatusUrl(), config);
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    }

    return data.data;
};
export const CountryAllstatusFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let config = {
        params: valData,
        ...(await getToken()),
    };
    let {data} = await axios.get(CountryAllstatusUrl(), config);
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    }

    return data.data;
};

export const ApplicationStatusFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let config = {
        params: valData,
        ...(await getToken()),
    };
    console.log('jjjjjjjjjjjjjjjj')
    let {data} = await axios.get(ApplicationStatusUrl(), config);
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    }

    return data;
};

export const getUserCountryStatusWiseFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(
        getUserCountryStatusWiseUrl(),
        valData,
        getToken()
    );
    dispatch(hideLoader());
    return data;
};

export const GetRelatedUserFxn = (id) => async (dispatch) => {
  dispatch(showLoader());
  let config = {
    ...(await getToken()),
  };
  let { data } = await axios.get(getSingleUserUrl(id), config);
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  }

  return data;
};



export const updateUserFxn = (valData) => async (dispatch) => {
  let { id } = valData;
  dispatch(showLoader());
  let { data } = await axios.put(postUpdateUserUrl(id), valData, getToken());
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  } else {
    notification.success({
      message: data.message,
    });
  }
  return data;
};

export const getUserCountryStatusWiseNewFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  let { data } = await axios.post(
    getUserCountryStatusWiseNewUrl(),
    valData,
    getToken()
  );
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    }

    return data;
};

export const GetEmployeeListFxn = (id) => async (dispatch) => {
    dispatch(showLoader());
    let config = {
        ...(await getToken()),
    };
    let {data} = await axios.get(getSingleEmployeeUrl(id), config);
    console.log(data);
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    }


    return data;
};
export const UserListFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let config = {
        params: {...valData},
        ...(await getToken()),
    };
    let {data} = await axios.get(getEmployeeUrl(), config);
    dispatch(hideLoader());

    return data;
};
export const EmployeeListFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let config = {
        params: {...valData},
        ...(await getToken()),
    };
    let {data} = await axios.get(getUserUrl(), config);
    dispatch(hideLoader());

    return data;
};

export const AddBranchFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(addBranchUrl(), valData, getToken());
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};
