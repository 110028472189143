import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  DatePicker,
  Drawer,
  Form,
  displayDate,
  notification,
} from "../../../components/Elements/appUtils";
import {
  followUpOptions,
  reminderMeOptions,
} from "../../../components/_utils/_utils";
import { GetEachFormFields } from "../../../components/_utils/formUtils";
import { AddFollowUpFxn, FollowUpList } from "../action";

const AddFollowup = (props) => {
  const [followList, setFollowList] = useState([]);
  const [date, setDate] = useState("");
  let [total, setTotal] = useState(0);

  let { visible, onClose, onSubmit, lead } = props;
  const { getFieldDecorator } = props.form;
  let dispatch = useDispatch();
  const handleSubmit = (e) => {
    const { form } = props;
    e.preventDefault();
    form.validateFields(async (err, valData) => {
      if (!err) {
        valData.leadId = lead._id;
        if (!date) {
          notification.warning({ message: "Please choose date." });
          return;
        }
        valData.date = date;
        let x = await dispatch(AddFollowUpFxn(valData));
        if (!x.error) {
          props.form.setFieldsValue({
            type: "",
            subject: "",
            date: "",
            time: "",
            reminderTime: "",
            notes: "",
          });
          setDate("");
          onSubmit();
        }
      } else {
        notification.warning({
          message: "Fill All Required Fields",
        });
      }
    });
  };

  const apiRequest = async () => {
    let obj = {
      leadId: lead._id,
      sortField: "_id",
      sortOrder: "descend",
    };
    let data = await dispatch(FollowUpList(obj));
    setTotal(data.total);
    setFollowList(data.data);
  };
  useEffect(() => {
    apiRequest();
  }, []);
  let inputTypes = {
    fields: [
      // {
      //     key: "type",
      //     label: "FollowUp Types",
      //     required: true,
      //     placeholder: "FollowUp Types",
      //     type: "select",
      //     keyAccessor: (x) => x.option,
      //     valueAccessor: (x) => `${x.option}`,
      //     options: followUpOptions,
      //     onChange: (x) => {
      //         props.form.setFieldsValue({
      //             type: x,
      //         });
      //     },
      // },

      // {
      //     key: "subject",
      //     label: "FollowUp Subject",
      //     required: true,
      //     placeholder: "FollowUp Subject",
      //     type: "text",

      // },
      // {
      //     key: "date",
      //     label: "Next FollowUp Date",
      //     required: true,
      //     placeholder: "Next FollowUp Date",
      //     type: "date",
      //     onChange: (x) => {
      //         console.log(x)
      //         props.form.setFieldsValue({
      //             date: x,
      //         });
      //     }

      // },
      // {
      //     key: "time",
      //     label: "Start Time",
      //     required: true,
      //     placeholder: "Select Time",
      //     type: "time",
      //     onChange: (x) => {
      //         props.form.setFieldsValue({
      //             time: x,
      //         });
      //     }
      // },
      // {
      //     key: "reminder",
      //     label: "Reminder In",
      //     required: true,
      //     placeholder: "Reminder In",
      //     type: "select",
      //     keyAccessor: (x) => x.option,
      //     valueAccessor: (x) => `${x.option}`,
      //     options: reminderMeOptions,
      //     onChange: (x) => {
      //         props.form.setFieldsValue({
      //             reminder: x,
      //         });

      //     },
      //     span: 6
      // },
      // {
      //     key: "reminderTime",
      //     label: " Time",
      //     required: true,
      //     placeholder: "Time",
      //     type: "number",
      //     span: 6
      // },

      {
        key: "notes",
        label: " FollowUp Notes",
        // required: true,
        placeholder: "FollowUp Notes",
        type: "textarea",
      },

      /* {
                             key: "date",
                             label: "Next FollowUp Date",
                             required: true,
                             placeholder: "Next FollowUp Date",
                             type: "date",
                             onChange: (x) => {
                                 console.log(x)
                                 props.form.setFieldsValue({
                                     date: x,
                                 });
                             },


                         },*/
    ],
  };
  return (
    <>
      <Drawer
        placement="right"
        onClose={onClose}
        visible={visible}
        width="45%"
        rightContent={`Total Follow ups: ${total}`}
        className={"follow_ups"}
        title={"Add Follow Up"}
      >
        <Form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-lg-12 col-12">
              <div className="add-follow">
                <h5>Add Followup</h5>
                <span>
                  <i className="fa fa-calendar-alt"></i> Counseling Date:{" "}
                  {lead && lead.createdAt ? displayDate(lead.createdAt) : null}
                </span>
              </div>
              {inputTypes.fields.map((item, key) => {
                return (
                  <div
                    className={`pb-3 ${
                      item.span == 6 ? "col-md-6" : "col-md-12"
                    }`}
                    key={key}
                  >
                    <GetEachFormFields {...props.form} item={item} />
                  </div>
                );
              })}
            </div>
            <div className="col-lg-12 col-12">
              <div className="row">
                <div className="col-lg-4 col-12">
                  <div className="form-group">
                    <label className="form-control">Next Followup</label>
                  </div>
                </div>
                <div className="col-lg-8 col-12">
                  <div className="form-group">
                    <DatePicker
                      className="form-control"
                      placeholder="DD/MM/YYYY"
                      value={date}
                      onChange={(value) => {
                        setDate(value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="or">
            <button type={"primary"} htmlType="submit" className="btn blue-btn">
              Add FollowsUp
            </button>
            <span>OR</span>
            <button type="button" className="btn red-btn" onClick={onClose}>
              Close Lead
            </button>
          </div>
        </Form>

        <div className={'followup_list'}>
          <div className="added-on">
            <ul>
              {followList && followList.length
                  ? followList.map((item, index) => {
                    return (
                        <>
                          <li className="active">
                            <div className="head">
                              <h5 className="m-0">Added on</h5>
                              <span>
                            <i className="fa fa-calendar-alt"></i>{" "}
                                {item && item.createdAt
                                    ? displayDate(item.createdAt)
                                    : null}
                          </span>
                            </div>
                            <p className="m-0">{item.notes}</p>
                            <span className="date">
                          <i className="fa fa-calendar-alt"></i> Next Followup:{" "}
                              {displayDate(item.date)}
                        </span>
                          </li>
                        </>
                    );
                  })
                  : null}
            </ul>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default Form.create()(AddFollowup);
