import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Button, Form, notification } from "../../../components/Elements/appUtils";
import { useDispatch } from "react-redux";
import { forgetPasswordFxn } from "../actions";
import Layout from "../layout";

const ForgetPassword = () => {
    let dispatch = useDispatch();
    const navigate = useNavigate();
    const [email, setEmail] = useState("");

    const handleInputChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = async(e) => {
        e.preventDefault();
        if (!email) {
            notification.warning({
                message: "Please enter your email.",
            });
            return;
        }
        localStorage.setItem('email',email)
        let x =  await dispatch(forgetPasswordFxn({
            email: email,
        }));

        if (x && x.success) {
            notification.success({
                message: "Password reset email sent successfully.",
            });
        } else {
            notification.error({
                message: "Failed to send password reset email.",
            }); 
        }
    };

    const goToLogin = () => {
        navigate('/login');
    }

   
    return (
        <Layout formName={'forget-password'}>
            <div className="content-box">
                <img src="../app/img/logo.svg" className="logo" alt="logo here"/>

                <div className="head">
                    <h4>Forgot Password</h4>
                    <p>Enter your email to receive a password reset link.</p>
                </div>

                <div className="field">
                    <Form onSubmit={handleSubmit}>
                        <div className="form-floating mb-4">
                            <input
                                type="email"
                                className="form-control"
                                value={email}
                                onChange={handleInputChange}
                                id="floatingInput"
                                placeholder="Enter your email address"
                            />
                            <label htmlFor="floatingInput">Email address</label>
                        </div>
                        <Button className="btn black-btn mt-3" htmlType="submit">Submit</Button>
                        <div className="forgot">Remembered your password? <a onClick={goToLogin}>Login</a></div>
                    </Form>
                </div>
            </div>
        </Layout>
    );
};

export default ForgetPassword;
