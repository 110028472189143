import {hideLoader, showLoader} from "../../actions/loader";
import notification from "../../components/Elements/Notification";
import {customAxios as axios, getToken} from "../../request";
import {
    addDocumentsLeadUrl,
    deleteSingleLeadDocumentUrl,
    getSingleLeadDocumentsUrl,
    singleLeadUrl,
    deleteLeadDocumentUrl
} from "./api";

export const singleLeadFxn = (id) => async (dispatch) => {
    console.log(id, "dddddddddddddd");
    dispatch(showLoader());
    let config = {
        ...(await getToken()),
    };
    let {data} = await axios.get(singleLeadUrl(id), config);
    console.log(data);
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    }

    return data;
};
export const addDocumentToLeadFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(addDocumentsLeadUrl(), valData, getToken());
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};

export const getSingleLeadDocumentsFxn = (id) => async (dispatch) => {
    dispatch(showLoader());
    let config = {
        ...(await getToken()),
    };
    let {data} = await axios.get(getSingleLeadDocumentsUrl(id), config);

    dispatch(hideLoader());
    if (data.error) {
        console.log("errrrrrrrr");
        // notification.error({
        //     message: data.message || "Error",
        // });
    }

    return data;
};


export const deleteLeadDocumentFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());

    let {data} = await axios.post(
        deleteLeadDocumentUrl(),
        valData,
        getToken()
    );
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }

    return data;
};

