import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Card, Form } from "../../../components/Elements/appUtils";
import { GetEachFormFields } from "../../../components/_utils/formUtils";
import { loadCountryList } from "../state/action";
import {
  GetRelatedCityFxn,
  getRelatedStateListFxn,
  postRelatedCityFxn,
} from "./action";

const EditCity = (props) => {
  const { getFieldDecorator } = props.form;
  // const [city, setCity] = useState([]);
  const [cityId, setCityId] = useState("");
  console.log(cityId);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  let dispatch = useDispatch();

  useEffect(() => {
    loadCountryFxn();
    getCampusData();
  }, []);

  const loadCountryFxn = async () => {
    let x = await loadCountryList();
    console.log(x);
    setCountryList(x);
  };

  const getRelatedStates = async (id) => {
    if (id) {
      let { data } = await getRelatedStateListFxn(id);
      setStateList(data);
    }
  };

  const getCampusData = async () => {
    let searchParams = new URLSearchParams(window.location.search);
    let id = searchParams.get("_id");
    if (id) {
      setCityId(id);
      getRelatedCity(id);
    }
  };

  const getRelatedCity = async (id) => {
    let { data } = await dispatch(GetRelatedCityFxn(id));
    console.log(data);
    getRelatedStates(data.country._id);
    setTimeout(() => {
      props.form.setFieldsValue({
        country: data.country._id,
        state: data.state._id,
        cityName: data.cityName,
      });
    }, 500);
  };

  const handleSubmit = (e) => {
    const { form } = props;
    e.preventDefault();
    form.validateFields(async (err, valData) => {
      console.log(err, valData);
      if (!err) {
        valData.cityId = cityId;
        let x = dispatch(postRelatedCityFxn(valData));
        console.log(x, "resssss");
      }
    });
  };

  let inputTypes = {
    fields: [
      {
        key: "country",
        label: "Country",
        required: true,
        placeholder: "Country Name",
        type: "select",
        options: countryList,
        keyAccessor: (x) => x._id,
        valueAccessor: (x) => `${x.countryName}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            country: x,
            state: undefined,
          });
          getRelatedStates(x);
        },
        span: 8,
      },

      {
        key: "state",
        label: "State",
        required: true,
        placeholder: "State Name",
        type: "select",
        options: stateList,
        keyAccessor: (x) => x._id,
        valueAccessor: (x) => `${x.stateName}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            state: x,
          });
        },
        span: 8,
      },

      {
        key: "cityName",
        label: "City",
        required: true,
        placeholder: "City Name",
        type: "text",
      },
    ],
  };
  return (
    <>
      <Card title={"Edit City"}>
        <Form onSubmit={handleSubmit}>
          <div className={"row"}>
            {inputTypes.fields.map((item, key) => {
              return (
                <div className={"col-md-6 pt-3"} key={key}>
                  <GetEachFormFields {...props.form} item={item} />
                </div>
              );
            })}
          </div>

          <div style={{ marginTop: "20px" }}>
            <Button type={"primary"} htmlType="submit">
              Update
            </Button>
          </div>
        </Form>
      </Card>
    </>
  );
};
export default Form.create()(EditCity);
