import React from "react"
import AddGicComponent from "./components/addGicComponent";
import {Card} from "../../components/Elements/appUtils";

const AddGicPage = () => {
    return (
        <>
            <Card title={'Add GIC Request'}>
                <AddGicComponent
                    onSubmit={() => {

                    }}/>
            </Card>
        </>
    )
}
export default AddGicPage
