import React, {useEffect, useState} from "react"
import {getSinglePurchasePlanByUserFxn} from "./actions";
import {useDispatch} from "react-redux";
import {Card, displayDate, TableButton, Tooltip} from "../../components/Elements/appUtils";
import {taxObj} from "../../components/_utils/_utils";
import PayModal from "./Components/payModal";

let {TableCard} = Card
const SinglePurchase = (props) => {
    let {data, events, currentUser} = props;
    let {leadId, plans} = data;
    return (
        <>
            <Card title={`Service Purchase on (${displayDate(data.createdAt)})`} padding={10}
                  customStyle={{marginBottom: 50}}>
                <div className="detail-box">
                    <ul>
                        {leadId && leadId._id ?
                            <>
                                <li>
                                    Name :
                                </li>
                                <li>
                                    {leadId.name}
                                </li>
                                <li>
                                    Email :
                                </li>
                                <li>
                                    {leadId.email}
                                </li>
                                <li>Country :</li>

                                <li>{leadId.countryName}</li>

                            </>
                            : null}
                        {plans && plans.length ? plans.map((item, index) => {
                            return (
                                <>
                                    <li>
                                        <span>Service {index + 1} : </span>
                                        {item.name}
                                    </li>
                                    <li>
                                        {currentUser.currency} {item.amount}
                                    </li>
                                </>
                            )
                        }) : null}

                        <li>Discount :</li>
                        <li>{currentUser.currency} {data.discountAmount ? data.discountAmount : 0}</li>
                        <li>Service Tax :</li>
                        <li>{currentUser.currency} {data.serviceTaxAmount ? `${data.serviceTaxAmount} (${taxObj.serviceTax}%)` : 0}</li>

                        <li>GST :</li>
                        <li>{data.gstAmount ? `${currentUser.currency} ${data.gstAmount} (${taxObj.gst}%)` : 0}</li>
                        <li>Grand Total :</li>
                        <li>{currentUser.currency} {data.netAmount}</li>

                        <li>Paid Amount :</li>
                        <li>{currentUser.currency} {data.paidAmount}</li>
                        <li>Rest Amount :</li>
                        <li>{currentUser.currency} {data.balance}</li>
                    </ul>
                    {data.installments && data.installments.length ?
                        <TableCard>
                            <table className="table text-start align-middle table-hover mb-0 data-table ">
                                <tr>
                                    <th>Installment</th>
                                    <th>Amount</th>
                                    <th>Due Date</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                                {data.installments.map((item, index) => {
                                    return (
                                        <tr key={item._id}>
                                            <td>Installment {index + 1}</td>
                                            <td>{currentUser.currency} {item.amount}</td>
                                            <td>{item.dueDate ? displayDate(item.dueDate) : null}</td>
                                            <td>
                                                {item.status == 'Pending' ?
                                                    <label
                                                        className={'label label-sm label-info'}>Pending</label> : null}
                                                {item.status == 'Paid' ?
                                                    <label
                                                        className={'label label-sm label-success'}>Paid</label> : null}
                                            </td>
                                            <td>
                                                <TableButton
                                                    type={'check'}
                                                    onClick={() => {
                                                        events.openPayModal(item)
                                                    }}/>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </table>
                        </TableCard> : null}
                </div>
            </Card>
        </>
    )
}

const AgencyFees = (props) => {
    let {currentUser} = props;
    let dispatch = useDispatch()
    let [dataList, setDataList] = useState([])
    let [installmentState, setInstallmentState] = useState({
        visible: false, data: {}
    })
    useEffect(() => {
        loadFeeData()
    }, [])
    let loadFeeData = async () => {
        let path = window.location.pathname;
        let id = path.split('/').pop()
        if (id) {
            let {data} = await dispatch(getSinglePurchasePlanByUserFxn({id}))
            setDataList(data)
        }
    }
    const instEvents = {
        openPayModal: (data) => {
            /* setInstallmentState({
                 data: data,
                 visible: true
             })*/
        },
        hidePayModal: () => {
            setInstallmentState({
                data: {},
                visible: false
            })
        },
        submitPayModal: () => {
            instEvents.hidePayModal()
            loadFeeData()
        }
    }
    return (
        <>
            <div title={'Purchased Services'}>
                {dataList && dataList.length ? dataList.map((data, index) => {
                    return (
                        <>
                            <SinglePurchase data={data} key={data._id} events={instEvents} currentUser={currentUser}/>
                        </>
                    )
                }) : null}

                {installmentState.visible ?
                    <PayModal {...installmentState} onClose={instEvents.hidePayModal}
                              onSubmit={instEvents.submitPayModal}/>
                    : null}
            </div>
        </>
    )
}
export default AgencyFees
