import _ from "lodash";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import currencyList from "../../../../src/assets/jsonFiles/currency_list.json";
import {
    Button,
    Card,
    Form,
    Popconfirm,
    Table,
    TableButton,
    notification,
} from "../../../components/Elements/appUtils";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import {AddPlanFxn, DeletePlanFxn, PlansListFxn} from "../action";

let {TableCard} = Card;
const AddPlans = (props) => {
    let {reloadList} = props;
    const navigate = useNavigate();
    let dispatch = useDispatch();
    let tableRef = useRef();
    const [currencySymbol, setCurrencySymbol] = useState(null);

    const [names, setNames] = useState([]);
    const [amounts, setAmounts] = useState([]);

    useEffect(() => {
        const userString = localStorage.getItem("user");
        const user = JSON.parse(userString);
        const currency = user.currency;
        Symbol(currency);
    }, []);

    const Symbol = (name) => {
        let symbol = _.find(currencyList, (item) => {
            return item.name === name;
        });

        if (symbol) {
            setCurrencySymbol(symbol.value);
        }
    };

    const handleDelete = (id) => {
        const {form} = props;
        form.validateFields(async (err, valData) => {
            if (!err) {
                valData.planId = id;
                let x = await dispatch(DeletePlanFxn(valData));
            }
        });
    };

    useEffect(() => {
        apiRequest();
    }, []);

    const handleSubmit = (e) => {
        const {form} = props;
        e.preventDefault();
        form.validateFields(async (err, valData) => {
            if (!err) {
                if (!valData.name) {
                    notification.warning({message: "Please enter plan."});
                    return;
                }
                if (!valData.amount) {
                    notification.warning({message: "Please enter amount."});
                    return;
                }
                let x = await dispatch(AddPlanFxn(valData));
                if (x && x.success) {
                    tableRef.current.reload();
                } else {
                    notification.error({message: x.message});
                }
                props.form.setFieldsValue({
                    name: "",
                    amount: "",
                });
                reloadList();
            }
        });
    };

    let planInfo = {
        fields: [
            {
                key: "name",
                label: "Name",
                required: false,
                placeholder: "Name",
                type: "text",
            },
            {
                key: "amount",
                label: "Amount",
                placeholder: "Amount",
                type: "number",
            },
        ],
    };

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            // searchTextName: "name"
        },
        {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
            // searchTextName: "amount",
            render: (text, record) => (
                <div>
                    {currencySymbol} {record.amount}
                </div>
            ),
        },
        {
            title: "Actions",
            dataIndex: "action",
            key: "action",
            render: (text, item) => {
                return (
                    <>
                        <TableButton
                            type={"edit"}
                            onClick={() => {
                                navigate(`/edit-plan?_id=${item._id}`);
                            }}
                        />
                        {/* <Popconfirm
                            title="Are you sure to delete this Plan?"
                            onConfirm={() => {
                                handleDelete(item._id);
                            }}
                            okText="Yes"
                            cancelText="No">
                            <TableButton type={'close'}/>
                        </Popconfirm> */}
                    </>
                );
            },
        },
    ];

    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            let {data} = await dispatch(
                PlansListFxn({
                    ...params,
                    regExFilters: ["name", "amount", "currency"],
                })
            );
            resolve(data);
        });
    };

    return (
        <>
            <Card title={"Add Plans"} shadow={false} bordered={false}>
                <Form onSubmit={handleSubmit}>
                    <div className={"row"}>
                        {planInfo.fields.map((item, key) => {
                            return !item.hidden ? (
                                <div className={"col-md-6 pt-3"} key={key}>
                                    <GetEachFormFields {...props.form} item={item}/>
                                </div>
                            ) : null;
                        })}
                    </div>
                    <div style={{marginTop: "20px"}}>
                        <Button type={"primary"} htmlType="submit">
                            submit
                        </Button>
                    </div>
                </Form>
            </Card>
            <TableCard title={"Plans List"} shadow={false}>
                <Table columns={columns} apiRequest={apiRequest} ref={tableRef}/>
            </TableCard>
        </>
    );
};

export default Form.create()(AddPlans);
