import React from "react"
import ProgramListComponent from "./components/programListComponent";
import {countryIds} from "../../components/Elements/appUtils";

const UkCourseStatus = () => {
    return (
        <>
            <ProgramListComponent countryId={countryIds.uk} countryName={'Canada'}/>

        </>
    )
}
export default UkCourseStatus
