import { apiUrl } from "../../../settings";

export const addNoteUrl = () => {
  return `${apiUrl}/addNote`;
};
export const noteListUrl = () => {
  return `${apiUrl}/noteList`;
};
export const applicationNoteUrl = () => {
  return `${apiUrl}/api/getApplicationNotes`;
};
export const addApplicationUrl = () => {
  return `${apiUrl}/addApplication`;
};

export const activityListUrl = () => {
  return `${apiUrl}/activityList`;
};
export const payTutionFeesUrl = () => {
    return `${apiUrl}/payTutionFees`;
};
