import React from "react";
import { InputBox } from "../../../components/Elements/appUtils";

const ContactDetails = (props) => {
  let { data, index, updateState, deleteItem } = props;
  return (
    <>
      <div className={"row new-border"} name="status" key={index}>
        <div className={"col-md-6"}>
          <InputBox
            title={`${index + 1}. Contract Person`}
            className={"rowFlex"}
          >
            <input
              required={true}
              placeholder="Please enter Name"
              className={"form-control"}
              onChange={({ target }) => {
                updateState({ contactPerson: target.value}, index);
              }}
              value={data.contactPerson}
            />
          </InputBox>
        </div>
        
        <div className={"col-md-6"}>
          <InputBox title={`Designation`} className={"rowFlex"}>
            <input
              required={true}
              placeholder="Please enter Designation"
              className={"form-control"}
              onChange={({ target }) => {
                updateState({ designation: target.value }, index);
              }}
              value={data.designation}
            />
            {index !== 0 ? (
              <a>
                <i
                  className="fa fa-trash new-icon"
                  aria-hidden="true"
                  onClick={() => deleteItem(index)}
                ></i>
              </a>
            ) : null}
          </InputBox>
        </div>
      </div>
    </>
  );
};

export default ContactDetails;
