import {apiUrl} from "../../settings";

export const addFeedbackUrl = () => {
    return `${apiUrl}/feedback`;
};
export const deleteFeedbackUrl = () => {
    return `${apiUrl}/deleteFeedback`;
};
export const FeedbackListUrl = () => {
    return `${apiUrl}/feedback`;
};
export const ResolveFeedbackUrl = () => {
    return `${apiUrl}/resolveFeedback`;
};
