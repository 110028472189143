import React, {useEffect, useState} from "react";
import {englishTestLeadCountFxn} from "../actions";
import {Cell, Legend, Pie, PieChart, Tooltip} from 'recharts'
import _ from "lodash"

let filterList = [
    "last month",
    "last quarter",
    "last year",
    "all",
]
const $ = window.$;

const EnglishTestStudent = () => {
    let [studentData, setStudentData] = useState([])
    let [state, setState] = useState({
        dateFilter: "all"
    })
    let _updateState = (data) => {
        setState((prevState) => {
            return {
                ...prevState,
                ...data
            }
        })
    }
    useEffect(() => {
        loadData(state.dateFilter); // Load data initially with the default date filter
      }, [state.dateFilter]);
      
    let loadData = async (dateFilter) => {
        let newData = []
        let {data} = await englishTestLeadCountFxn({}, dateFilter);
        _.each(data, (item) => {
            newData.push({
                name: item.name,
                value: item.count,
            })
        })
        setStudentData(newData)
    }
    const countryColors = [
        "#4FB4E7",
        "#831231",
        "#F67C22",
        "#1F4677",
        "#7DD4D7"
    ]
    const getWidth = () => {
        if ($('#englishPieChart')) {
            return $('#englishPieChart').width()
        }
    }

    const RADIAN = Math.PI / 180
    const renderLabelContent = props => {
        const {
            value,
            percent,
            cx,
            cy,
            x,
            y,
            midAngle,
            name,
            innerRadius,
            outerRadius
        } = props

        return (
            <g transform={`translate(${x}, ${y})`}>
                <text
                    x={90}
                    y={40}
                    textAnchor="middle"
                    dominantBaseline="central"
                    style={{fontSize: 14}}>
                    {`${name}: ${value}`}
                    {`(Per: ${(percent * 100).toFixed(2)}%)`}
                </text>
            </g>
        )
    }

    return (
        <>
            <div className="col-sm-12 col-xl-5" id={'englishPieChart'}>
                <div className="card-box text-center p-4 border-dark">
                    <div className="d-flex align-items-center justify-content-between mb-4 gap-3 flex-wrap">
                        <h6 className="mb-0">PTE/IELTS Students</h6>
                        {/*  <div className="select-box">
                            <select name="filter" id="" className="form-control bg-white">
                                <option value="1">Reports</option>
                                <option value="2">Reports</option>
                                <option value="3">Reports</option>
                            </select>
                        </div>*/}
                    </div>

                    <ul key={state.dateFilter}>
                        {filterList.map((item) => {
                            return (
                                <li key={item}>
                                    <input type="radio" name="commission-radio" value={item}
                                           onChange={({target}) => {
                                               _updateState({dateFilter: target.value})
                                           }}
                                           checked={state.dateFilter == item}/>
                                    <label>{item}</label>
                                </li>
                            )
                        })}
                    </ul>

                    <div key={studentData}>
                        <PieChart width={getWidth()} height={380}>
                            <Pie
                                data={studentData && studentData.length ? studentData : []}
                                labelLine={true}
                                label={renderLabelContent}
                                fill="#8884d8"
                                cx="50%"
                                cy="50%"
                                isAnimationActive={false}
                                dataKey="value">
                                {studentData.map((entry, index) => (
                                    <Cell
                                        key={`cell-${index}`}
                                        fill={countryColors[index % countryColors.length]}
                                    />
                                ))}
                            </Pie>
                            <Legend wrapperStyle={{position: 'relative'}} display={false}/>
                            <Tooltip trigger="click"/>
                        </PieChart>
                    </div>
                </div>
            </div>

        </>
    )
}
export default EnglishTestStudent
