import _ from "lodash";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {
    Button,
    Card,
    DatePicker,
    Form,
    InputBox,
    notification,
} from "../../../components/Elements/appUtils";
import {
    EnglishExamTypes,
    genderOption,
    leadSourceOption,
    maritalStatusOption,
} from "../../../components/_utils/_utils";
import {Countries, Flags} from "../../../components/_utils/appUtils";
import {LoadState} from "../../../components/_utils/countryUtil";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import WorkExperienceComp from "../../AddStudent/Components/WorkExperienceComp";
import EducationComponent from "../../AddStudent/Components/educationComponent";
import {courseCategoryList} from "../../Masters/courseCategory/action";
import {loadCountryList} from "../../Masters/state/action";
import {companyCountryList} from "../../University/action";
import {
    AddLeadFxn,
    getLeadFieldListFxn,
    getRelatedCounsellorFxn,
    getSingleLeadFxn,
    updateLeadFxn,
} from "../action";
// import { singleStudentFxn,updateStudentFxn } from "../action"
const {InnerCard} = Card;
const initialState = {
    name: "",
    passportNumber: "",
    email: "",
    address: "",
    companyContactNo: "",
    companyAddress: "",
    companyEmail: "",
    companyLogo: {},
    country: undefined,
    gstRegistration: true,
    gstInNo: "",
    pincode: "",
    countryCode: "",
    cityName: undefined,
    countryName: undefined,
    stateName: undefined,
    postalCode: "",
    agentId: "",
    certificate: {},
    profilePhoto: {},
    referBy: "",
};

const LeadInformation = (props) => {
    let {data, extraFields, updateExtraState} = props;
    let [optionsList, setOptionsList] = useState([]);

    useEffect(() => {
        setOptionsListFxn();
    }, []);

    let setOptionsListFxn = () => {
        if (data.option) {
            let optionList = data.option.split(",");
            setOptionsList(optionList);
        }
    };
    return (
        <>
            {data.inputType == "select" ? (
                <div className={"col-md-4"} key={data?._id}>
                    <InputBox title={data.name}>
                        <select
                            className={"form-control"}
                            value={extraFields[data.name]}
                            onChange={(e) => {
                                updateExtraState({[data.name]: e.target.value});
                            }}
                        >
                            <option value={""}>Choose</option>
                            {optionsList && optionsList.length
                                ? optionsList.map((item) => {
                                    return (
                                        <option key={item} value={item}>
                                            {item}
                                        </option>
                                    );
                                })
                                : null}
                        </select>
                    </InputBox>
                </div>
            ) : null}

            {data.inputType == "text" ? (
                <div className={"col-md-4"} key={data?._id}>
                    <form className="p-2">
                        <div className="position-relative">
                            <label
                                style={{
                                    display: "flex",
                                    marginLeft: "5px",
                                    fontWeight: "600",
                                    fontFamily: "Poppins",
                                    marginBottom: "5px",
                                }}
                            >
                                {data.name}
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder={data.name}
                                value={extraFields[data.name] ? extraFields[data.name] : null}
                                onChange={(e) => {
                                    updateExtraState({[data.name]: e.target.value});
                                }}
                            />
                        </div>
                    </form>
                </div>
            ) : null}

            {data.inputType == "date" ? (
                <div className={"col-md-4 mt-2"} key={data?._id}>
                    <label
                        style={{
                            display: "flex",
                            marginLeft: "5px",
                            fontWeight: "600",
                            fontFamily: "Poppins",
                            marginBottom: "5px",
                        }}
                    >
                        {data.name}
                    </label>
                    <DatePicker
                        value={extraFields[data.name] ? extraFields[data.name] : null}
                        onChange={(e) => {
                            updateExtraState({[data.name]: e});
                        }}
                    />
                </div>
            ) : null}
        </>
    );
};
const eduStateInit = {
    qualification: "",
    math: "",
    english: "",
    percentage: "",
    board: "",
    university: "",
    stream: "",
    passingYear: "",
    courseName: "",
    schoolName: "",
};

const workStateInit = {
    companyName: "",
    position: "",
    time: "",
    // experienceCertificate: "",
};
const EditLead = (props) => {
    const {getFieldDecorator, getFieldValue, setFieldsValue} = props.form;
    const [countryList, setCountryList] = useState([]);
    const [counselorList, setCounselorList] = useState([]);
    const [stateArray, setStateArray] = useState([]);
    const [leadFieldsList, setLeadFieldsList] = useState([]);
    const [categoriesList, setCategoryList] = useState([]);
    const [leadId, setLeadId] = useState("");
    const [eduState, setEduState] = useState(eduStateInit);
    const [studentId, setStudentId] = useState("");
    let [formState, setState] = useState(initialState);
    let [extraFields, setExtraFields] = useState({});
    let [interestedFields, setInterestedFields] = useState([]);
    const [education, setEducation] = useState([eduStateInit]);
    const [experience, setExperience] = useState([workStateInit]);
    const dispatch = useDispatch();

    useEffect(() => {
        handleLead();
        handleCourseCategory();

        checkLeadId();
    }, []);
    const checkLeadId = async () => {
        let searchParams = new URLSearchParams(window.location.search);
        let id = searchParams.get("_id");
        if (id) {
            getSingleStudent(id);
            setStudentId(id)
        }
    };

    const handleCounselor = async (countryId) => {

        if (countryId) {
            let x = await getRelatedCounsellorFxn({
                countryId,
                userType: "counsellor",
            });
            setCounselorList(x);
        }
    };
    const getSingleStudent = async (id) => {
        let {data, success} = await dispatch(getSingleLeadFxn(id));
        console.log(data, "single lead data-------")
        if (success) {
            setTimeout(() => {
                props.form.setFieldsValue({

                    name: data.name,
                    gender: data.gender,
                    mobileNo: data.mobileNo,
                    address: data.address,
                    email: data.email,
                    passportNumber: data.passportNumber,
                    leadSource: data.leadSource,

                    assignTo:
                        data.assignTo && data.assignTo.name ? data.assignTo.name : null,

                    interestedCountry:
                        data.interestedCountry && data.interestedCountry.countryName
                            ? data.interestedCountry.countryName
                            : null,
                    countryCode: data.countryCode,
                    maritalStatus: data.maritalStatus,
                    countryName: data.countryName,
                    stateName: data.stateName,
                    cityName: data.cityName,
                    pincode: data.pincode,
                    message: data.message,
                    dateOfBirth: data.dateOfBirth ? moment(data.dateOfBirth) : "",
                    // englishRequirement: data.englishRequirement,
                    "englishRequirement.examType": data.englishRequirement && data.englishRequirement.examType ? data.englishRequirement.examType : "",
                });
                setEducation(data && data.education && data.education.length ? data.education : [eduStateInit]);
                setExtraFields(data.extraFields)
                setInterestedFields(data.interestedFields);

                setExperience(
                    data && data.workExperience && data.workExperience.length ? data.workExperience : [workStateInit]
                );


                // setAssignto(data.assignTo);
                setLeadId(data._id);
                // handleCounselor(data.interestedCountry?._id);
            }, 300);
            setTimeout(() => {
                let obj = {
                    assignTo: data.assignTo && data.assignTo.name ? data.assignTo?.name : null,
                }
                let englishRequirement = {}
                if (data.englishRequirement && data.englishRequirement.examinationDate) {
                    englishRequirement.examinationDate = moment(data.englishRequirement.examinationDate);
                    englishRequirement.overall = data.englishRequirement.overall;
                    englishRequirement.listening = data.englishRequirement.listening;
                    englishRequirement.reading = data.englishRequirement.reading;
                    englishRequirement.writing = data.englishRequirement.writing;
                    englishRequirement.speaking = data.englishRequirement.speaking;
                }
                props.form.setFieldsValue({...obj, englishRequirement});
            }, 1000);
        }
    };
    let user = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : null;
    useEffect(() => {
        setFieldsValue({countryCode: user.countryCode});
    }, []);

    const handleCourseCategory = async () => {
        let x = await courseCategoryList();
        setCategoryList(x.data.data);
    };

    const events = {
        addMore: () => {
            setExperience([...experience, workStateInit]);
        },
        deleteItem: (itemId) => {
            const updatedItems = experience.filter((item, key) => key !== itemId);
            setExperience(updatedItems);
        },
        updateState: (data, index) => {
            let cloneStatus = _.clone(experience);
            cloneStatus[index] = {...cloneStatus[index], ...data};
            setExperience(cloneStatus);
        },
        chooseCountry: (name) => {
            let countryName = _.find(Countries, (item) => {
                return item.name == name;
            });
            if (countryName) {
                setState({
                    ...formState,
                    countryName: countryName.name ? countryName.name : "",
                });
                setStateArray(countryName.id ? LoadState(countryName.id) : []);
            }
        },
        chooseState: (name) => {
            let stateName = _.find(stateArray, (item) => {
                return item.name == name;
            });
            if (stateName) {
                setState({
                    ...formState,
                    stateName: stateName.name ? stateName.name : "",
                });
            }
        },
        updateExtraState: (data) => {
            setExtraFields((prevState) => {
                return {
                    ...prevState,
                    ...data,
                };
            });
        },
        chooseCourseCategory: (category) => {
            let cloneD = _.clone(interestedFields);
            let findV = _.find(cloneD, (item) => {
                return item == category;
            });
            if (findV) {
                cloneD = _.reject(cloneD, (item) => {
                    return item == category;
                });
                setInterestedFields(cloneD);
            } else {
                cloneD.push(category);
                setInterestedFields(cloneD);
            }
        },
    };
    const handleLead = async () => {
        let x = await companyCountryList();
        setCountryList(x.data);
    };


    const ExamConditions =
        !getFieldValue("englishRequirement.examType") ||
        (getFieldValue("englishRequirement.examType") &&
            getFieldValue("englishRequirement.examType") == "I don't have this");

    const Exam12ThConditions =
        !getFieldValue("englishRequirement.examType") ||
        (getFieldValue("englishRequirement.examType") &&
            getFieldValue("englishRequirement.examType") !== "I don't have this");

    const AllData = () => {
        setEducation([...education, eduStateInit]);
    };
    const deleteItem = (itemId) => {
        const updatedItems = education.filter((item, key) => key !== itemId);
        setEducation(updatedItems);
    };


    const eduEvents = {
        updateState: (data, index) => {
            let cloneEdu = _.clone(education);
            cloneEdu[index] = {...cloneEdu[index], ...data};
            setEducation(cloneEdu);
        },
        addEdu: () => {
            if (education) {
                if (education.percentage < 1) {
                    notification.warning({
                        message: "Enter greater then 0 Percentage",
                    });
                    return;
                }
                if (education.qualification == "XII") {
                    if (education.math < 1) {
                        notification.warning({
                            message: "Enter greater then 0 Math score",
                        });
                        return;
                    }
                    if (education.english < 1) {
                        notification.warning({
                            message: "Enter greater then 0 English score",
                        });
                        return;
                    }
                }
            }
            setEducation((preState) => {
                return [...preState, eduState];
            });
            eduEvents.updateState(eduStateInit);
        },
    };
    const setAssignto = (assignToId) => {
        let assignTo = _.find(counselorList, (item) => {
            return item._id == assignToId._id;
        });
        if (assignTo) {
            setFieldsValue({assignTo: assignTo._id});
        }
    };

    const chooseStatus = (value, itemId, index) => {
        let cloneState = _.clone(categoriesList);
        let findRow = _.find(cloneState, (item) => {
            return item._id == itemId;
        });
        if (findRow) {
            findRow.item._id = itemId;
        }
        setCategoryList(cloneState);
    };
    const handleSubmit = (e) => {
        const {form} = props;
        e.preventDefault();

        form.validateFields(async (err, valData) => {
            if (!err) {
                valData.extraFields = extraFields;
                valData.interestedFields = interestedFields;

                valData = {...valData, education};
                valData.workExperience = experience;
                valData.leadId = leadId
                let x = await dispatch(updateLeadFxn(valData));
                if (x && x.success) {

                }
            } else {
                if (err.name) {
                    notification.warning({
                        message: `Please enter Name`,
                    });
                    return;
                }

                /* if (err.email) {
                     notification.warning({
                         message: `Please enter Email`,
                     });
                     return;
                 }*/

                if (err.countryName) {
                    notification.warning({
                        message: `Please select Country`,
                    });
                    return;
                }

                if (err.countryCode) {
                    notification.warning({
                        message: `Please select Country Code`,
                    });
                    return;
                }

                if (err.mobileNo) {
                    notification.warning({
                        message: `Please enter Mobile No`,
                    });
                    return;
                }

                if (err.dateOfBirth) {
                    notification.warning({
                        message: `Please enter Date of Birth`,
                    });
                    return;
                }

                if (err.gender) {
                    notification.warning({
                        message: `Please select Gender`,
                    });
                    return;
                }

                if (err.maritalStatus) {
                    notification.warning({
                        message: `Please select Marital Status`,
                    });
                    return;
                }
                if (err.countryName) {
                    notification.warning({
                        message: `Please select Country`,
                    });
                    return;
                }

                if (err.stateName) {
                    notification.warning({
                        message: `Please enter State`,
                    });
                    return;
                }

                if (err.cityName) {
                    notification.warning({
                        message: `Please enter City`,
                    });
                    return;
                }


                if (err.address) {
                    notification.warning({
                        message: `Please enter Address`,
                    });
                    return;
                }


                notification.warning({
                    message: "Fill All Required Fields",
                });
            }
        });
    };

    let inputTypes = {
        fields: [
            {
                key: "name",
                label: "Name",
                showStar: true,
                placeholder: "Name",
                type: "text",
                span: "col-md-4",
            },
            {
                key: "countryName",
                label: "Country",
                showStar: true,
                placeholder: "Country",
                type: "select",
                options: Countries,
                showSearch: true,
                keyAccessor: (x) => x.name,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        countryName: x,
                    });
                },
                span: "col-md-4",
            },
            {
                key: "email",
                label: "Email",
                // showStar: true,
                placeholder: "Email",
                type: "text",
                span: "col-md-4",
            },


            {
                key: "countryCode",
                label: "Country Code",
                showStar: true,
                placeholder: "Country Code",
                type: "select",
                options: Flags,
                showSearch: true,
                keyAccessor: (x) => x.countryCode,
                valueAccessor: (x) => `${x.name} (${x.dialCode})`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        countryCode: x,
                    });
                },
                span: "col-md-4",
            },


            {
                key: "mobileNo",
                label: "Mobile No",
                showStar: true,
                placeholder: "Mobile No ",
                type: "text",
                span: "col-md-4",
            },
            {
                key: "dateOfBirth",
                label: "Date Of Birth ",
                showStar: true,
                placeholder: "Date Birth ",
                type: "date",
                span: "col-md-4",
                keyAccessor: (x) => x.option,
                valueAccessor: (x) => `${x.option}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        dateOfBirth: x,
                    });
                },
            },
            {
                key: "gender",
                label: "Gender",
                showStar: true,
                placeholder: "Gender ",
                type: "select",
                keyAccessor: (x) => x.option,
                valueAccessor: (x) => `${x.option}`,
                options: genderOption,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        gender: x,
                    });
                },
                span: "col-md-4",
            },
            {
                key: "maritalStatus",
                label: "Marital Status",
                showStar: true,
                placeholder: "Marital Status",
                type: "select",
                keyAccessor: (x) => x.option,
                valueAccessor: (x) => `${x.option}`,
                options: maritalStatusOption,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        maritalStatus: x,
                    });
                },
                span: "col-md-4",
            },
            {
                key: "address",
                label: "Address",
                showStar: true,
                placeholder: "Address",
                type: "text",
                span: "col-md-4",
            },
        ],
    };
    let AddressInfo = {
        fields: [
            {
                key: "countryName",
                label: "Country",
                showStar: true,
                showSearch: true,
                placeholder: "Country Name",
                type: "select",
                options: Countries,
                keyAccessor: (x) => x.name,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        countryName: x,
                        stateName: "",
                        cityName: "",
                    });
                    events.chooseCountry(x);
                },
                span: "col-md-4",
            },
            {
                key: "stateName",
                label: "State",
                showStar: true,
                showSearch: true,
                placeholder: "State Name",
                type: "text",
            },
            {
                key: "cityName",
                label: "City",
                showStar: true,
                placeholder: "City Name",
                type: "text",
                span: "col-md-4",
            },
            {
                key: "pincode",
                label: "Pin Code",
                required: false,
                placeholder: "Pin Code",
                type: "text",
                span: "col-md-4",
            },

        ],
    };

    const testScore = {
        fields: [
            {
                key: "englishRequirement.examType",
                label: "English Exam Type",
                required: false,
                placeholder: "English Exam Type",
                type: "select",
                span: 6,
                keyAccessor: (x) => x.option,
                valueAccessor: (x) => `${x.option}`,
                options: EnglishExamTypes,
                onChange: (x) => {
                  let obj = {
                    examType: x,
                    overall: "",
                    listening: "",
                    reading: "",
                    writing: "",
                    speaking: "",
                    english12ThMarks: "",
                  };
                  props.form.setFieldsValue({
                    englishRequirement: obj,
                  });
                },
              },
            {
                key: "englishRequirement.english12ThMarks",
                label: "English 12th Marks",
                type: "number",
                placeholder: "English 12th Marks",
                span: 6,
                hidden: Exam12ThConditions,
                required: !Exam12ThConditions,
            },
            {
                key: "englishRequirement.examinationDate",
                label: "Examination Date",
                type: "date",
                span: 6,
                placeholder: "Examination Date",
                hidden: ExamConditions,
                required: !ExamConditions,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        "englishRequirement.examinationDate": x,
                    });
                },
            },
            {
                key: "englishRequirement.overall",
                label: "Overall",
                type: "number",
                span: 6,
                placeholder: "Overall",
                hidden: ExamConditions,
                required: !ExamConditions,
            },
            {
                key: "englishRequirement.listening",
                label: "Listening",
                type: "number",
                span: 3,
                placeholder: "Listening",
                hidden: ExamConditions,
                required: !ExamConditions,
            },
            {
                key: "englishRequirement.reading",
                label: "Reading",
                type: "number",
                span: 3,
                placeholder: "Reading",
                hidden: ExamConditions,
                required: !ExamConditions,
            },
            {
                key: "englishRequirement.writing",
                label: "Writing",
                type: "number",
                span: 3,
                placeholder: "Writing",
                hidden: ExamConditions,
                required: !ExamConditions,
            },
            {
                key: "englishRequirement.speaking",
                label: "Speaking",
                type: "number",
                span: 3,
                placeholder: "Speaking",
                hidden: ExamConditions,
                required: !ExamConditions,
            },
        ],
    };
    // let LeadInfo = {
    //             fields: [
    //                 {
    //                     key: "interestedCountry",
    //                     label: "Interested Country",
    //                     required: true,
    //                     placeholder: "Interested Country",
    //                     type: "select",
    //                     // disabled: true,
    //                     options: countryList,
    //                     keyAccessor: (x) => x.countryName,
    //                     valueAccessor: (x) => `${x.countryName}`,
    //                     onChange: (x) => {
    //                         props.form.setFieldsValue({
    //                             interestedCountry: x,
    //                             leadSource: undefined,
    //                             assignTo: undefined,
    //                         });
    //                         handleCounselor(x);
    //                     },
    //                     span: "col-md-4",
    //                 },

    //                 {
    //                     key: "leadSource",
    //                     label: "Lead Source",
    //                     required: true,
    //                     placeholder: "Lead Source",
    //                     type: "select",
    //                     keyAccessor: (x) => x.option,
    //                     valueAccessor: (x) => `${x.option}`,
    //                     options: leadSourceOption,
    //                     onChange: (x) => {
    //                         props.form.setFieldsValue({
    //                             leadSource: x,
    //                         });
    //                     },
    //                     span: "col-md-4",
    //                 },
    //                 {
    //                     key: "assignTo",
    //                     label: "Assign To",
    //                     // required: true,


    //                     placeholder: "Assign To",
    //                     type: "select",
    //                     options: counselorList,
    //                     keyAccessor: (x) => x.name,
    //                     valueAccessor: (x) => `${x.name}`,
    //                     onChange: (x) => {
    //                         props.form.setFieldsValue({
    //                             assignTo: x,
    //                         });
    //                     },
    //                     span: "col-md-4",
    //                 },

    //                 {
    //                     key: "message",
    //                     label: "Message",
    //                     placeholder: "Message ",
    //                     type: "textarea",
    //                     span: "col-md-12",
    //                 },
    //             ],
    //         };
    return (
        <>
            {/* BasicInfo */}

            <Card title={"Basic Information"}>
                <Form>
                    <div className={"row"}>
                        {inputTypes.fields.map((item, key) => {
                            return (
                                <div
                                    className={`pt-3 ${item.span ? item.span : "col-md-4"}`}
                                    key={key}
                                >
                                    <GetEachFormFields {...props.form} item={item}/>
                                </div>
                            );
                        })}
                    </div>
                </Form>
            </Card>
            {/* AddressInfo */}
            <Card title={"Address Information"}>
                <Form>
                    <div className={"row"}>
                        {AddressInfo.fields.map((item, key) => {
                            return (
                                <div className={"col-md-4 pt-3"} key={key}>
                                    <GetEachFormFields {...props.form} item={item}/>
                                </div>
                            );
                        })}
                    </div>
                </Form>
            </Card>


            <Card
                title={"Education"}
                bordered
                extraBtn={[{
                    name: "Add Multiple Education",
                    action: AllData,
                }]}
            >
                {education.map((data, index) => {
                    return (
                        <InnerCard key={index}>
                            <EducationComponent
                                data={data}
                                index={index}
                                education={education}
                                eduEvents={eduEvents}
                                deleteItem={deleteItem}
                            />
                        </InnerCard>
                    );
                })}
            </Card>

            <Card
                title={"Work Experience"}
                extraBtn={[{
                    name: "Add Work Experience",
                    action: events.addMore,
                }]}
            >
                {experience.map((data, index) => {
                    return (
                        <InnerCard key={index}>
                            <WorkExperienceComp
                                data={data}
                                index={index}
                                experience={experience}
                                updateState={events.updateState}
                                deleteItem={events.deleteItem}
                                handleFileChange={events.handleFileChange}
                            />
                        </InnerCard>
                    );
                })}
            </Card>
            <Card title={"Test Score"}>
                <div className={"row"}>
                {testScore.fields.map((item, key) => {
                    return !item.hidden ? (
                        <div
                            className={`pt-3 ${item.span == 3 ? "col-md-3" : "col-md-4"}`}
                            key={key}
                        >
                          <GetEachFormFields {...props.form} item={item}/>
                        </div>
                    ) : null;
                  })}
                </div>
                <Form onSubmit={handleSubmit}>

                    <div style={{marginTop: "20px"}}>
                        <Button type={"primary"} htmlType="submit">
                            submit
                        </Button>
                    </div>
                </Form>
            </Card>


        </>
    );
};

export default Form.create()(EditLead);

