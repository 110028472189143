import { apiUrl } from "../../../settings";

export const addTemplateUrl = () => {
  return `${apiUrl}/addTemplate`;
};
export const getRelatedCourseUrl = (universityId) => {
  return apiUrl + "/getRelatedMasterCourse/" + universityId;
};

export const templateListUrl = () => {
  return apiUrl + "/getTemplateList";
};
export const updateTemplateUrl = () => {
  return apiUrl + "/updateTemplate";
};

export const updateTemplateActiveStatusUrl = () => {
  return apiUrl + "/updateTemplateAvailability";
};

export const getCompanyCourseListUrl = (universityId) => {
  return apiUrl + "/getRelatedCourse/" + universityId;
};
export const getRelatedCourseForSopUrl = (universityId) => {
  return apiUrl + "/getRelatedCourseForSop/" + universityId;
};
