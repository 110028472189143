import React, { useEffect, useState } from "react";
import { commissionCountryWiseFxn } from "../actions";

let filterList = ["last month", "last quarter", "last year", "all"];
let initState = {
  status: "Approved",
  date: "",
};
const Commission = () => {
  let [paymentState, setPaymentState] = useState(initState);
  let [objData, setObjData] = useState([]);
  let [state, setState] = useState({
    dateFilter: "all",
  });

  let _updateState = (data) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...data,
      };
    });
  };

  let events = {
    updateFilter: (data) => {
      setPaymentState((prevState) => {
        return {
          ...prevState,
          ...data,
        };
      });
    },
    loadCommission: async () => {
      let { data } = await commissionCountryWiseFxn(paymentState.status, state.dateFilter);
      setObjData(data);
    },
  };

  useEffect(() => {
    events.loadCommission();
  }, [paymentState, state.dateFilter]);

  return (
    <>
      <div className="col-sm-12 col-xl-5">
        <div className="card-box text-center p-4 border-dark">
          <div className="d-flex align-items-center justify-content-between mb-4 gap-3 flex-wrap">
            <h6 className="mb-0">Commission</h6>
            <div className="select-box">
              <select
                name="filter"
                className="form-control bg-white"
                value={paymentState.status}
                onChange={({ target }) => {
                  events.updateFilter({ status: target.value });
                }}
              >
                <option value="Approved">Received</option>
                <option value="Pending">Pending</option>
              </select>
            </div>
          </div>

          <ul key={state.dateFilter}>
            {filterList.map((item) => {
              return (
                <li key={item}>
                  <input
                    type="radio"
                    name="commission-radio"
                    value={item}
                    onChange={({ target }) => {
                      _updateState({ dateFilter: target.value });
                    }}
                    checked={state.dateFilter === item}
                  />
                  <label>{item}</label>
                </li>
              );
            })}
          </ul>

          <div className="country-box1">
            <ul>
              {objData && objData.length
                ? objData.map((item) => {
                    let { record } = item;
                    return (
                      <li key={item._id}>
                        <div className="box">
                          <span>
                            <img src={item.flag && item.flag.path ? item.flag.path : ""} alt="" />
                          </span>
                          <h4>
                            {paymentState.status === "Approved"
                              ? record["Approved"]
                                ? record["Approved"]
                                : 0
                              : record["Pending"]
                              ? record["Pending"]
                              : 0}
                          </h4>
                          <p>{item.countryName}</p>
                        </div>
                      </li>
                    );
                  })
                : null}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
export default Commission;
