import {apiUrl} from "../../settings";

export const addTrainingRequestUrl = () => {
    return apiUrl + "/addTrainingRequest";
};

export const trainingRequestListUrl = () => {
    return apiUrl + "/trainingRequestList";
};

export const updateTrainingRequestUrl = () => {
    return apiUrl + "/updateTrainingRequest";
};
