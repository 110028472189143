import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Card,
  Form,
  MonthList,
  notification,
} from "../../../components/Elements/appUtils";
import { GetEachFormFields } from "../../../components/_utils/formUtils";
import {
  getCampusListFxn,
  getRelatedRepresentativeUniversityListFxn,
} from "../../AddStudent/actions";
import { courseCategoryList } from "../../Masters/courseCategory/action";
import { companyCountryList } from "../../University/action";
import {
  AddRepresentingCourseFxn,
  GetRelatedCourseFxn,
  previousCourseListFxn,
} from "../action";

const CourseData = (props) => {
  const { getFieldDecorator } = props.form;
  const [countryList, setCountryList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [courseList, setCourseList] = useState([]);

  const [campusList, setCampusList] = useState([]);

  const [universityList, setUniversityList] = useState([]);

  let dispatch = useDispatch();
  useEffect(() => {
    handleState();
  }, []);
  const previousCourseList = async (id) => {
    if (id) {
      let { data } = await previousCourseListFxn({ universityCountry: id });
      setCourseList(data.data);
    }
  };

  const handleState = async () => {
      let data = await companyCountryList();
      console.log(data, "dddddddddddddddd");
      setCountryList(data);
  };

  const getReleatedCampus = async (id) => {
    if (id) {
      let { data } = await getCampusListFxn({ universityId: id });
      setCampusList(data);
    }
  };
  const getRelatedUniversities = async (id) => {
    if (id) {
        let {data} = await getRelatedRepresentativeUniversityListFxn(id);
        console.log(data.data, "dddddddddddd");
        setUniversityList(data.data);
    }
  };

  useEffect(() => {
    handleCourseCategory();
  }, []);

  const handleCourseCategory = async () => {
    let x = await courseCategoryList();
    setCategoryList(x.data.data);
  };

  const handleSubmit = (e) => {
    const { form } = props;
    e.preventDefault();
    form.validateFields(async (err, valData) => {
      if (!err) {
        let findUni = _.find(universityList, (item) => {
          return item._id == valData.universityId;
        });
        valData.universityState = findUni.universityState;
        valData.universityCity = findUni.universityCity;
        let x = await dispatch(AddRepresentingCourseFxn(valData));
        if (!x.error) {
          props.form.setFieldsValue({
            universityCountry: [],
            universityId: [],
            courseName: "",
            courseDuration: "",
            courseLevel: [],
            tuitionFee: "",
            intakes: [],
            categoryId: "",
            campus: undefined,
          });
        }
      } else {
        notification.warning({
          message: "Please Enter all Required Fields",
        });
        return;
      }
    });
  };
  const getCourseInformation = async (id) => {
    if (id === 1) {
      props.form.setFieldsValue({
        universityCountry: "",
        universityId: "",
        courseName: "",
        courseDuration: "",
        courseLevel: "",
        tuitionFee: "",
        categoryId: "",
      });
    }
    if (id && id.length > 1) {
      let { data } = await dispatch(GetRelatedCourseFxn(id));
      props.form.setFieldsValue({
        courseName: data.courseName,
        courseDuration: data.courseDuration,
      });
      // setCountryData(data);
    }
  };

  let user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;
  let inputTypes = {
    fields: [
      {
        key: "universityCountry",
        label: "University Country",
        required: true,
        placeholder: "University Country",
        type: "select",
        options: countryList,
        keyAccessor: (x) => x._id,
        valueAccessor: (x) => `${x.masterCountryId.countryName}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            universityCountry: x,
            universityId: undefined,
            campus: undefined,
          });
          getRelatedUniversities(x);
        },
        span: 8,
      },
      {
        key: "universityId",
        label: "University Name",
        required: true,
        placeholder: "University  Name",
        type: "select",
        options: universityList,
        keyAccessor: (x) => x._id,
        valueAccessor: (x) =>
          `${
            x.masterUniversityId &&
            x.masterUniversityId.universityName &&
            x.masterUniversityId.universityName
              ? x.masterUniversityId.universityName
              : x.universityName
          }`,
        onChange: (x) => {
          props.form.setFieldsValue({
            universityId: x,
            campus: undefined,
          });
          getReleatedCampus(x);
        },
        span: 8,
      },
      // {
      //   key: "courseId",
      //   label: "Course",
      //   required: true,
      //   placeholder: "Course Name",
      //   type: "select",
      //   options: courseList,
      //   hidden: user.userType == "admin",
      //   keyAccessor: (x) => x._id,
      //   valueAccessor: (x) => `${x.courseName}`,
      //   onChange: (x) => {
      //     props.form.setFieldsValue({
      //       courseId: x,
      //     });
      //     getCourseInformation(x);
      //   },
      // },
      {
        key: "campus",
        label: "Campus",
        required: true,
        placeholder: "Campus",
        type: "select",
        multiple: true,
        options: campusList,
        keyAccessor: (x) => x._id,
        valueAccessor: (x) => `${x.campus}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            campus: x,
          });
        },
      },

      {
        key: "courseName",
        label: " Course Name",
        required: true,
        placeholder: "Course",
        type: "text",
      },
      {
        key: "courseDuration",
        label: "Duration",
        required: true,
        placeholder: "Duration",
        type: "text",
      },
      {
        key: "courseLevel",
        label: "Level",
        required: true,
        placeholder: "Course -Level",
        type: "select",
        options: [
          { name: "1-Year Post-Secondary Certificate" },
          { name: "2-Year Undergraduate Diploma" },
          { name: "3-Year Undergraduate Advanced Diploma" },
          { name: "3-Year Bachelor's Degree" },
          { name: "4-Year Bachelor's Degree" },
          { name: "5-Year Bachelor's Degree" },
          { name: "Postgraduate Certificate / Master's Degree" },
          { name: "Doctoral Degree (Phd, M.D., ...)" },
        ],
        keyAccessor: (x) => x.name,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            courseLevel: x,
          });
        },
        span: 8,
      },
      {
        key: "intakes",
        label: "Select Months for Intake",
        required: true,
        placeholder: "Select Months for Intake",
        type: "select",
        multiple: true,
        options: MonthList,
        keyAccessor: (x) => x.name,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            intakes: x,
          });
        },
        span: 8,
      },
      {
        key: "tuitionFee",
        label: "Tuition Fee",
        required: true,
        placeholder: "Fee",
        type: "number",
      },
      {
        key: "categoryId",
        label: "Category",
        required: true,
        placeholder: "Category",
        type: "select",
        options: categoryList,
        keyAccessor: (x) => x._id,
        valueAccessor: (x) => `${x.categoryName}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            categoryId: x,
          });
        },
        span: 8,
      },
    ],
  };
  return (
    <>
      <Card title={"Add Course"}>
        <Form onSubmit={handleSubmit}>
          <div className={"row"}>
            {inputTypes.fields.map((item, key) => {
              return !item.hidden ? (
                <div className={"col-md-6 pt-3"} key={key}>
                  <GetEachFormFields {...props.form} item={item} />
                </div>
              ) : null;
            })}
          </div>
          <div style={{ marginTop: "20px" }}>
            <Button type={"primary"} htmlType="submit">
              submit
            </Button>
          </div>
        </Form>
      </Card>
    </>
  );
};

export default Form.create()(CourseData);
