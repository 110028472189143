import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {
    Button,
    Card,
    DefaultTablePagination,
    Form,
    Table,
    notification,
} from "../../components/Elements/appUtils";
import {GetEachFormFields} from "../../components/_utils/formUtils";
import {AddCourseLevelFxn, courseLevelListFxn} from "./action";

const boardTypeList = [
    {text: "Board", value: "Board"},
    {text: "Stream", value: "Stream"},
];
const AddCourseLevel = (props) => {
    const {getFieldDecorator, getFieldValue} = props.form;
    let dispatch = useDispatch();
    let tableRef = useRef();

    const handleSubmit = (e) => {
        const {form} = props;
        e.preventDefault();
        form.validateFields(async (err, valData) => {
            if (!err) {
                // if (
                //   getFieldValue("type") === "Board" &&
                //   getFieldValue("shortName") == undefined
                // ) {
                //   notification.warning({
                //     message: "Please Enter Short Name",
                //   });
                //   return;
                // }
                // if (getFieldValue("type") === "Stream") {
                //   valData.shortName = valData.name;
                // }
                let x = await dispatch(AddCourseLevelFxn(valData));
                if (!x.error) {
                    tableRef.current.reload();
                    props.form.setFieldsValue({
                        type: "",
                        name: "",
                        shortName: "",
                    });
                }
            }
        });
    };
    let inputTypes = {
        fields: [
            //   {
            //     key: "type",
            //     label: "Type",
            //     required: true,
            //     placeholder: "Type Name",
            //     type: "select",
            //     options: [{ name: "Board" }, { name: "Stream" }],
            //     keyAccessor: (x) => x.name,
            //     valueAccessor: (x) => `${x.name}`,
            //     onChange: (x) => {
            //       props.form.setFieldsValue({
            //         type: x,
            //       });
            //     },
            //     span: 8,
            //   },
            {
                key: "name",
                label: "Name",
                required: true,
                placeholder: "Name ",
                type: "text",
            },
            //   {
            //     key: "shortName",
            //     label: " Short Name",
            //     // required: true,
            //     hidden: !(props.form.getFieldValue("type") == "Board"),

            //     placeholder: "Short Name ",
            //     type: "text",
            //   },
        ],
    };

    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            let obj = {
                sortField: "_id",
                sortOrder: "descend",
                ...params,
            };
            let data = await dispatch(
                courseLevelListFxn({
                    ...obj,
                    regExFilters: ["type", "name", "shortName"],
                })
            );
            resolve(data);
        });
    };
    const columns = [
        {
            title: "Sr. No.",
            dataIndex: "index",
            key: "index",
            render: (val, record, index) => {
                return index + 1;
            },
        },
        // {
        //   title: "Type",
        //   dataIndex: "type",
        //   key: "type",
        //   filters: boardTypeList,
        // },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            searchTextName: "name",
            filterRegex: true,
        },
        // {
        //   title: "Short Name",
        //   dataIndex: "shortName",
        //   key: "shortName",
        // },
        {
            title: "Action",
            dataIndex: "action",
            render: (val, record) => {
                return <></>;
            },
        },
    ];

    return (
        <>
            <Card title={"Add Course Level"}>
                <Form onSubmit={handleSubmit}>
                    <div className={"row"}>
                        {inputTypes.fields.map((item, key) => {
                            return (
                                <div className={"col-md-4 pt-3"} key={key}>
                                    <GetEachFormFields {...props.form} item={item}/>
                                </div>
                            );
                        })}
                    </div>

                    <Button type={"primary"} htmlType="submit" className={"mt-4"}>
                        submit
                    </Button>
                </Form>
            </Card>
            <Card title={"CourseLevel List"}>
                <Table
                    apiRequest={apiRequest}
                    columns={columns}
                    pagination={DefaultTablePagination()}
                    ref={tableRef}
                />
            </Card>
        </>
    );
};

export default Form.create()(AddCourseLevel);
