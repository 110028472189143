import {customAxios as axios, getToken} from "../../../../request";
import {showLoader, hideLoader} from "../../../../actions/loader";
import {
    addCheckListCategoryUrl,
    updateCheckListCategoryUrl,
    singleCheckListCategoryUrl,
    checkListCategoryListUrl,

    addCheckListDocumentUrl,
    updateCheckListDocumentUrl,
    singleCheckListDocumentUrl,
    checkListDocumentListUrl, checkListGroupedDocumentUrl,
} from "../apis";
import notification from "../../../../components/Elements/Notification";

export const addCheckListCategoryFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(addCheckListCategoryUrl(), valData, getToken());
    dispatch(hideLoader())
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
}
export const updateCheckListCategoryFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(updateCheckListCategoryUrl(), valData, getToken());
    dispatch(hideLoader())
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
}
export const singleCheckListCategoryFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.get(singleCheckListCategoryUrl(), valData, getToken());
    dispatch(hideLoader())
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
}
export const checkListCategoryListFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let config = {
        params: {...valData},
        ...await getToken()
    }
    let {data} = await axios.get(checkListCategoryListUrl(), config);
    dispatch(hideLoader())
    return data;
}

export const addCheckListDocumentFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(addCheckListDocumentUrl(), valData, getToken());
    dispatch(hideLoader())
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
}
export const updateCheckListDocumentFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(updateCheckListDocumentUrl(), valData, getToken());
    dispatch(hideLoader())
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
}
export const singleCheckListDocumentFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let config = {
        params: {...valData},
        ...await getToken()
    }
    let {data} = await axios.get(singleCheckListDocumentUrl(), config);
    dispatch(hideLoader())
    return data;
}
export const checkListDocumentListFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let config = {
        params: {...valData},
        ...await getToken()
    }
    let {data} = await axios.get(checkListDocumentListUrl(), config);
    dispatch(hideLoader())
    return data;
}
export const checkListGroupedDocumentFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let config = {
        params: {...valData},
        ...await getToken()
    }
    let {data} = await axios.get(checkListGroupedDocumentUrl(), config);
    dispatch(hideLoader())
    return data;
}
