import _ from "lodash";
import moment from "moment";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import Card from "../../components/Elements/Card";
import {
    DefaultTablePagination,
    Drawer,
    Table,
    longDisplayDate,
} from "../../components/Elements/appUtils";
import {transactionListFxn} from "./action";
import WithDrawCommission from "./withDrawCommission";

let {TableCard} = Card
let agentCommissionInit = {
    visible: false,
    expenseId: {},
};
const TransactionListGic = (props) => {
    let tableRef = useRef();
    let dispatch = useDispatch();
    let {visible, onClose, currentBalance, user} = props;
    let [visibleWithdrawal, setVisibleWithdrawal] = useState(false);
    let [transactionData, setTransactionData] = useState([]);
    const [singleExpenseState, setSingleExpenseState] = useState(agentCommissionInit);
    const [refreshKey, setRefreshKey] = useState(moment());


    const columns = [
        {
            title: "Date",
            dataIndex: "date",
            key: "date",
            render: (text, record) => {
                return <>{longDisplayDate(text)}</>;
            },
        },

        {
            title: "Transaction/Reference",
            dataIndex: "transactionReference",
            key: "transactionReference",
            render: (text, record) => {
                return (
                    <>
                        {record.transactionType === "Credit" ?
                            <>
                                {record.requestedById ? <>
                                    Name : {record.requestedById.name},
                                    phone
                                    No: {record.requestedById && record.requestedById.mobile ? record.requestedById.mobile : ""}
                                </> : null}
                            </>
                            : null}
                        {record.transactionType === "Debit" && record.bankId ?
                            <>
                                {record.bankId ? <>
                                    BankName: ${record.bankId.bankName}, Account No: ${record.bankId.accountNo}
                                </> : null}
                            </>
                            : null}


                    </>
                )
            },
        },
        {
            title: "Debit",
            dataIndex: "debit",
            key: "debit",
            render: (text, record) => {
                if (record.transactionType === "Debit") {
                    return `Rs. ${record.amount}`;
                } else {
                    return null;
                }
            },
        },
        {
            title: "Credit",
            dataIndex: "credit",
            key: "credit",
            render: (text, record) => {
                if (record.transactionType === "Credit") {
                    return `Rs. ${record.amount}`;
                } else {
                    return null;
                }
            },
        },
    ];

    const events = {
        showWithdrawal: () => {
            setVisibleWithdrawal(true);
        },
        hideWithdrawal: () => {
            setVisibleWithdrawal(false);
            setRefreshKey(moment());
        },
        showSingleExpense: (record) => {
            setSingleExpenseState({
                visible: true,
                expenseId: record,
            });
        },
        hideSingleExpense: () => {
            setSingleExpenseState({
                visible: false,
                expenseId: "",
            });
        },
        reload: () => {
        },
        apiRequest: (params) => {
            return new Promise(async (resolve) => {
                let obj = {
                    sortField: "_id",
                    sortOrder: "descend",
                    ...params,
                };
                let data = await dispatch(
                    transactionListFxn({
                        ...obj,
                        regExFilters: ["type", "name", "shortName"],
                    })
                );
                resolve(data);
            });
        },
    };

    return (
        <>
            <Drawer
                rightContent={(
                    <button
                        type="button"
                        className="btn blue-btn"
                        onClick={() => {
                            events.showWithdrawal()
                        }}>Withdraw Commission
                    </button>
                )}
                title={"Transaction list"}
                onClose={() => {
                    props.onSubmit();
                    props.onClose();
                }}
                visible={visible}
                width={"75%"}
            >
                <TableCard key={refreshKey}>
                    <Table
                        apiRequest={events.apiRequest}
                        columns={columns}
                        size="large"
                        pagination={DefaultTablePagination()}
                        ref={tableRef}
                    />
                </TableCard>
            </Drawer>
            {visibleWithdrawal ? (
                <WithDrawCommission
                    user={user}
                    visible={visibleWithdrawal}
                    onClose={events.hideWithdrawal}
                    onSubmit={() => {
                        events.hideWithdrawal();
                        // loadExpenseTransactionsFxn();
                        props.onSubmit();
                    }}
                    currentBalance={currentBalance}
                />
            ) : null}
        </>
    );
};
export default TransactionListGic;
