import notification from "../../../../components/Elements/Notification";
import {customAxios as axios, getToken} from "../../../../request";
import {
    StateListUrl,
    addAllCountry,
    addStateUrl,
    getStateUrl,
    postStateUrl,
} from "../api";
import {hideLoader, showLoader} from "../../../../actions/loader";

export const loadCountryList = async (params = {}) => {
    params.results = 100
    let config = {
        params,
        ...(await getToken()),
    };
    let {data} = await axios.get(addAllCountry(), config);
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
  }
    return data.data
}

export const AddStateFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(addStateUrl(), valData, getToken());
    dispatch(hideLoader())
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};

export const stateCategoryList = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let config = {
        params: {...valData},
        ...await getToken()
    }
    let {data} = await axios.get(StateListUrl(), config)
    dispatch(hideLoader())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data
}

export const GetRelatedStateFxn = (id) => async (dispatch) => {
    dispatch(showLoader())
    let config = {
        ...(await getToken()),
    };
    let {data} = await axios.get(getStateUrl(id), config);
    dispatch(hideLoader())
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    }

    return data;
};

export const postRelatedStateFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(postStateUrl(), valData, getToken());
    dispatch(hideLoader())
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};
