import {default as React, useEffect, useState, useRef} from "react";
import {
  Button,
  Card,
  Drawer,
  Form,
  notification,
} from "../../../components/Elements/appUtils";
import { GetEachFormFields } from "../../../components/_utils/formUtils";
import { AddTemplateCategory } from "../actions";

const TemplateCategory = (props) => {
  const { visible, onClose, getCategoryList,countryId } = props;
  const { getFieldDecorator } = props.form;
  const [template, setTemplate] = useState(null);
  console.log(countryId,'templateeeeeeeeeeeeeeeee')
  let inputTypes = {
    fields: [
      {
        key: "categoryName",
        label: "Category Name",
        required: true,
        placeholder: "Category Name",
        type: "text",
      },
    ],
  };
  const handleSubmit = (e) => {
    const { form } = props;
    e.preventDefault();
    form.validateFields(async (err, valData) => {
      console.log(valData,'xxxxxxxxxxxxxx');
      if (!err) {
        valData.countryId = countryId._id
        // template && template.countryId && template.countryId._id
        //     ? template.countryId._id
        //     : null;
        let x = await AddTemplateCategory(valData);
      
        console.log(x, 'xxxxxxxxxxxxxxxxxxxxxx')
        if (!x.error) {
          notification.success({
            message: x.message,
          });
          // onClose();
          getCategoryList();
          setTemplate(x.countryId);
          props.form.setFieldsValue({
            categoryName: "",
          });
        }
      }
    });
  };
  return (
      <Drawer placement="right" onClose={onClose} visible={visible} width={"40%"} title={"Add Template Category"}>
        <Card>
          <Form onSubmit={handleSubmit}>
            <div className={"row"}>
              {inputTypes.fields.map((item, key) => {
                return (
                    <div className={"col-md-12 pt-3"} key={key}>
                      <GetEachFormFields
                          {...props.form}
                          item={item}
                      />
                    </div>
              );
            })}
          </div>
          <div style={{ marginTop: "20px" }}>
            <Button type={"primary"} htmlType="submit">
              submit
            </Button>
          </div>
        </Form>
      </Card>
    </Drawer>
  );
};

export default Form.create()(TemplateCategory);
