import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {
    Button,
    Card,
    Form,
    notification,
} from "../../../components/Elements/appUtils";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import {AddStateFxn, loadCountryList} from "./action";

// const initialState = {
//     country:"",
//     state:""
// }
const AddState = (props) => {
    const {getFieldDecorator} = props.form;
    const [countryList, setCountryList] = useState([]);
    let dispatch = useDispatch();
    useEffect(() => {
        handleState();
    }, []);

    const handleState = async () => {
        let x = await loadCountryList();
        setCountryList(x);
    };

    const handleSubmit = (e) => {
        const {form} = props;
        e.preventDefault();
        form.validateFields(async (err, valData) => {
            if (!err) {
                let x = await dispatch(AddStateFxn(valData));
                props.form.setFieldsValue({
                    country: "",
                    stateName: "",
                });
            }
        });
    };
    let inputTypes = {
        fields: [
            {
                key: "country",
                label: "Country",
                required: true,
                placeholder: "Country Name",
                type: "select",
                options: countryList,
                keyAccessor: (x) => x._id,
        valueAccessor: (x) => `${x.countryName}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            country: x,
          });
        },
                span: 8,
            },
            {
                key: "stateName",
                label: "State",
                required: true,
                placeholder: "State Name",
                type: "text",
            },
        ],
    };
  return (
    <>
        <Card title={"Add State"}>
            <Form onSubmit={handleSubmit}>
                <div className={"row"}>
                    {inputTypes.fields.map((item, key) => {
                        return (
                            <div className={"col-md-6 pt-3"} key={key}>
                                <GetEachFormFields {...props.form} item={item}/>
                            </div>
                        );
                    })}
                </div>

                <Button type={"primary"} htmlType="submit" className={"mt-4"}>
                    submit
                </Button>
            </Form>
        </Card>
    </>
  );
};

export default Form.create()(AddState);
