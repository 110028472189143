import React, {useRef} from "react";
import {useDispatch} from "react-redux";
import {Card, HyperLink, Table, TableButton} from "../../../components/Elements/appUtils";
import {CountryListFxn} from "../../Country/action";
import {companyCountryList} from "../../University/action";
import {useNavigate} from "react-router-dom";

let {TableCard} = Card
const CountryList = () => {
    let tableRef = useRef();
    let dispatch = useDispatch();
    const navigate = useNavigate();

    let user = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : null;
    const apiRequest = (params) => {
        // params = {
        //   results: 100,
        //   count: 100,
        // };
        return new Promise(async (resolve) => {
            if (user.userType == "admin") {
                let data = await dispatch(
                    CountryListFxn({
                        ...params,
                        regExFilters: ["countryName", "interview", "documents"],
                    })
                );
                console.log(data)
                resolve(data);
            } else {
                let data = await companyCountryList({
                    ...params,
                    regExFilters: ["countryName", "interview", "documents"],
                });
                resolve(data);
            }
        });
    };

    const columns = [
        {
            title: "Country",
            dataIndex: "countryName",
            key: "countryName",
            searchTextName: "countryName",
            filterRegex: true,
            width: 150,
            render: (item) => {
                return <div style={{width: 150}}>{item}</div>;
            },
        },
        {
            title: "Interview",
            dataIndex: "interview",
            key: "interview",
            searchTextName: "interview",
            filterRegex: true,
            width: 150,
            render: (item) => {
                return <div style={{width: 150}}>{item}</div>;
            },
        },
        {
            title: "Currency",
            dataIndex: "currency",
            key: "currency",
            width: 150,
            render: (item) => {
                return <div style={{width: 150}}>{item}</div>;
            },
        },
        {
            title: "Docs",
            dataIndex: "documents",
            key: "documents",
            searchTextName: "documents",
            filterRegex: true,
            width: 150,
            render: (item) => {
                return <div style={{width: 150}}>{item}</div>;
            },
        },
        {
            title: "Flag",
            dataIndex: "flag",
            key: "flag",
            width: 150,

            render: (flag, record) => {
                return flag && flag.path ? (
                    <img alt={flag.path} src={flag.path} height={24}/>
                ) : (
                    ""
                );
            },
        },
        {
            title: "Action",
            dataIndex: "action",
            width: 150,
            render: (val, record) => {
                return (
                    <>
                        <TableButton type={'edit'} path={`/master/country-edit?_id=${record._id}`}/>
                        <TableButton type={'view'} onClick={() => {
                        }}/>


                        {/*<a
                            style={{marginLeft: 4}}
                            className={"btn btn-primary btn-sm"}
                            // onClick={() => events.showEditDrawer(item.sectionId)}
                        >
                            Total Status-({record.statusCount ? record.statusCount : 0})
                        </a>*/}
                    </>
                );
            },
        },
    ];
    return (
        <>
            <TableCard
                title={"Country List"}
                extraBtn={[
                    {
                        name: "Add Country",
                        action: () => {
                            navigate(`/master/add-country`)
                        }
                    }
                ]}
            >
                <Table apiRequest={apiRequest} columns={columns} ref={tableRef}/>
            </TableCard>
        </>
    );
};

export default CountryList;
