import { hideLoader, showLoader } from "../../../actions/loader";
import notification from "../../../components/Elements/Notification";
import { customAxios as axios, getToken } from "../../../request";
import {
  addStudentUrl,
  boardStreamListUrl,
  geCampusListUrl,
  getCampusListUrl,
  getCourseCampusListUrl,
  getIntakeListUrl,
  getRelatedUniversityUrl,
} from "../api";

export const addStudentFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  let { data } = await axios.post(addStudentUrl(), valData, getToken());
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  } else {
    notification.success({
      message: data.message || "Success",
    });
  }
  return data;
};
export const getRelatedRepresentativeUniversityListFxn = async (id) => {
  let config = {
    ...(await getToken()),
  };
  let { data } = await axios.get(getRelatedUniversityUrl(id), config);
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  }
  return data;
};

export const getCampusListFxn = async (valData) => {
  let config = {
      params: {...valData},
      ...(await getToken()),
  };
    let {data} = await axios.get(getCampusListUrl(), config);
  if (data.error) {
      notification.error({
          message: data.message || "Error",
      });
  }
  return data;
};
export const getCourseCampusListFxn = async (valData) => {
    let config = {
        params: {...valData},
        ...(await getToken()),
    };
    let {data} = await axios.get(getCourseCampusListUrl(), config);
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  }
  return data;
};
export const getIntakeListFxn = async (valData) => {
    let config = {
        params: {...valData},
        ...(await getToken()),
    };
    let {data} = await axios.get(getIntakeListUrl(), config);
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  }
  return data;
};
export const boardStreamListFxn = async (valData) => {
  let config = {
    params: { ...valData },
    ...(await getToken()),
  };
  let { data } = await axios.get(boardStreamListUrl(), config);
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  }
  return data;
};
