import React, {useEffect, useState} from "react";
import {packageListFxn} from "../../containers/Auths/actions";

const SubscriptionPackage = (props) => {
    let {choosePlan, activePlan} = props;
    let [packageList, setPackageList] = useState([]);
    // let [activePlan, setActivePlan] = useState(null)
    const loadPackageList = async () => {
        let {data} = await packageListFxn();
        setPackageList(data);
        // setActivePlan(data)
    };
    useEffect(() => {
        loadPackageList();
    }, []);

    return (
        <>
            <div
                className="modal fade pricing-modal"
                id="staticBackdrop"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-xl">
                    <div className="modal-content">
                        <div className="modal-body">
                            {/* Close button with custom styling */}
                            <button
                                type="button"
                                className="custom-close-btn"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                            <span aria-hidden="true">X</span>
                            </button>

                            <div className="pricing">
                                <div className="head-3">
                                    <span>pricing plan</span>
                                    <h4 className="m-0">Choose the one that suits you</h4>
                                </div>
                                <div className="row gy-4">
                                    {packageList && packageList.length
                                        ? packageList.map((item, index) => {
                                            return (
                                                <div className="col-lg-4 col-12" key={item.name}>
                                                <div className={`box ${index === 1 ? "premium" : ""} ${activePlan === item ? "active" : ""}`}>

                                                        <h3>
                                                            ${item.price}
                                                            <sub>/{item.periodName}</sub>
                                                        </h3>
                                                        <div className="inner-head">
                                                            <h5 className="m-0">{item.name}</h5>
                                                            <p>{item.title}</p>
                                                        </div>
                                                        <ul>
                                                            {item.details && item.details.length
                                                                ? item.details.map((eachItem) => {
                                                                    return (
                                                                        <li key={eachItem}>
                                                                            <img
                                                                                src="../app/img/check-back.png"
                                                                                alt=""
                                                                            />{" "}
                                                                            {eachItem}
                                                                        </li>
                                                                    );
                                                                })
                                                                : null}
                                                        </ul>
                                                        <button
                                                           className={`btn ${activePlan === item ? "active common-btn" : "common-btn"}`}
                                                            type="button"
                                                            onClick={() => {
                                                                choosePlan(item);
                                                            }}
                                                            data-bs-dismiss="modal"
                                                        >
                                                            get started{" "}
                                                            <img src="../app/img/angle.png" alt=""/>
                                                        </button>
                                                    </div>
                                                </div>
                                            );
                                        })
                                        : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default SubscriptionPackage;
