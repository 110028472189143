import {hideLoader, showLoader} from "../../../actions/loader";
import {notification} from "../../../components/Elements/appUtils";
import Request, {customAxios as axios, getToken} from "../../../request.js";
import {
    applicationCountCountryWiseUrl,
    commissionCountryWiseUrl,
    englishTestLeadCountUrl,
    englishTestRevenueUrl,
    leadCountByTypeUrl,
    studentCountryWiseUrl,
    getDataByBranchIdUrl
} from "../apis";

export const applicationCountCountryWiseFxn = async (valData) => {
    let {data} = await axios.post(
        applicationCountCountryWiseUrl(),
        valData,
        getToken()
    );
    return data;
};

export const englishTestLeadCountFxn = async (valData, dateFilter) => {
    let {data} = await axios.post(
        `${englishTestLeadCountUrl()}?dateFilter=${dateFilter}`,
        valData,
        getToken()
    );
    return data;
};

export const leadCountByTypeFxn = async (valData, dateFilter, branchId) => {
    console.log(dateFilter, branchId, "params---")
    let {data} = await axios.post(
        `${leadCountByTypeUrl()}?dateFilter=${dateFilter}&branchId=${branchId}`, valData, getToken());
    return data;
}

export const getDataByBranchId = async (valData, branchId) => {
    console.log(branchId, "params---")
    let {data} = await axios.post(`${getDataByBranchIdUrl()}?branchId=${branchId}`,
        valData,
        getToken()
    );
    return data;
};

export const studentCountryWiseFxn = async (valData, dateFilter) => {
    console.log(dateFilter, "params-report")
    let {data} = await axios.post(`${studentCountryWiseUrl()}?dateFilter=${dateFilter}`, valData, getToken());
    return data;
};

export const commissionCountryWiseFxn = async (valData, dateFilter) => {
    let {data} = await axios.post(
        `${commissionCountryWiseUrl()}?dateFilter=${dateFilter}`,
        valData,
        getToken()
    );
    return data;
};
export const englishTestRevenueFxn = async (valData, dateFilter) => {
    // console.log(dateFilter)
    let {data} = await axios.post(
        `${englishTestRevenueUrl()}?dateFilter=${dateFilter}`,
        valData,
        getToken()
    );
    return data;
};
