import React, {useRef, useState} from "react";
import {Card, Popconfirm, Table} from "../../../components/Elements/appUtils";
import {approvedTrialManualFxn, companyListFxn} from "./action";
import {useDispatch} from "react-redux";

let {TableCard} = Card;
const CompanyList = () => {
    let dispatch = useDispatch()
    let [total, setTotal] = useState(0)
    let tableRef = useRef();
    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            let data = await companyListFxn({
                ...params,
                companyRegistered: false,
                regExFilters: ["name"],
            });
            setTotal(data.total)
            resolve(data);
        });
    };

    const approveCompany = async (companyId) => {
        let resp = await dispatch(approvedTrialManualFxn({companyId}));
        if (resp && resp.success) {
            tableRef.current.reload()
        }
    }


    const columns = [
        {
            title: "Name",
            dataIndex: "contactPerson",
            key: "contactPerson",
            searchTextName: "name",
            filterRegex: true,
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            searchTextName: "email",
            filterRegex: true,
        },
        {
            title: "Company Name",
            dataIndex: "companyName",
            key: "companyName",
            render: (item, record) => {
                return item;
            },
        },
        {
            title: "Country",
            dataIndex: "countryName",
            key: "countryName",
            render: (item, record) => {
                return item;
            },
        },
        {
            title: "State",
            dataIndex: "stateName",
            key: "stateName",
            render: (item, record) => {
                return item;
            },
        },
        {
            title: "Contact No",
            dataIndex: "mobile",
            key: "mobile",
            render: (item, record) => {
                let {countryCode} = record;
                return `${countryCode} ${item}`;
            },
        },
        {
            title: "Action",
            dataIndex: "action",
            render: (val, record) => {
                let {companyRegistered} = record;
                return <>
                    <Popconfirm
                        title="Are you sure, You want to Register company?"
                        onConfirm={() => {
                            approveCompany(record._id)
                        }}
                        okText="Yes"
                        cancelText="No">
                        <button className="btn" type="button">
                            <img src="../app/img/icons/check.svg" alt=""/>
                        </button>

                    </Popconfirm>
                </>
            },
        },
    ];

    return (
        <>
            <TableCard
                title={`Requested for Trail Company List (Total : ${total})`}>
                <Table apiRequest={apiRequest} columns={columns} ref={tableRef}/>
            </TableCard>
        </>
    );
};

export default CompanyList;
