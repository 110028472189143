import parse from "html-react-parser";
import {default as React, useEffect, useState, useRef} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {
    Button,
    Card,
    Drawer,
    Form,
    notification,
    HyperLink, Popconfirm, Modal,
} from "../../components/Elements/appUtils";
import {GetEachFormFields} from "../../components/_utils/formUtils";
import {AddTemplateCategory} from "../templateCategory/actions";
import TemplateCategory from "../templateCategory/drawers/templateCategory";
import {
    AddTemplateSectionFxn,
    getSectionListFxn,
    getSingleTemplatetFxn,
    getTemplatetCategoryListFxn,
    RemoveSectionFxn,
} from "./actions";
import {apiUrl} from "../../settings";
import TemplateSectionEdit from "../templateCategory/drawers/templateSectionEdit";
import {imgPath} from "../../components/_utils/_utils";

let initState = {
    categorySection: {},
    visible: false,
};
let {confirm} = Modal

const TemplateView = (props) => {
    const navigate = useNavigate();

    let {countryId} = props;
    let location = useLocation();
    const url = window.location.href;
    const searchParams = new URLSearchParams(url.split("?")[1]);
    const templateId = searchParams.get("id");

    const {data} = location.state || {};
    const {getFieldDecorator} = props.form;
    const [template, setTemplate] = useState(null);

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isDrawerOpenCategory, setIsDrawerOpenCategory] = useState(false);
    const [categoryList, setCategoryList] = useState([]);
    const [sectionList, setSectionList] = useState([]);
    let [sectionData, setSectionData] = useState(initState);

    const dispatch = useDispatch();
    let tableRef = useRef();
    useEffect(() => {
        getSingleTemplate(templateId);
        getCategoryList();
        getSectionList();
    }, [templateId]);

    const getCategoryList = async () => {
        let params = {
            results: 100,
            countryId: countryId,
        }
        let x = await getTemplatetCategoryListFxn((params));
        console.log(x.data, 'llllllllllllllllllllllll')
        setCategoryList(x.data);
    };

    const events = {
        showEditDrawer: (record) => {
            setSectionData({
                categorySection: record,
                visible: true,
            });
        },
        hideEditDrawer: () => {
            setSectionData({
                categorySection: {},
                visible: false,
            });
            getSingleTemplate(templateId);
        },
    };

    const getSectionList = async () => {
        let x = await getSectionListFxn();

        if (!x.error) {
            setSectionList(x.data);
        }
    };

    const handleDrawerOpenCategory = () => {
        setIsDrawerOpenCategory(true);
    };

    const handleDrawerCloseCategory = () => {
        setIsDrawerOpenCategory(false);
    };

    const handleDrawerOpen = () => {
        setIsDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setIsDrawerOpen(false);
    };
    const getSingleTemplate = async (templateId) => {
        if (templateId) {
            let {data} = await getSingleTemplatetFxn(templateId);
            // console.log(data,'dataaaaaaaaaaaaaaaaa')
            if (!data.error) {
                setTemplate(data);
            }
        }
    };
    const handleSubmit = (e) => {
        const {form} = props;
        e.preventDefault();
        form.validateFields(async (err, valData) => {
            if (!err) {
                valData.universityId =
                    template && template.universityId && template.universityId._id
                        ? template.universityId._id
                        : null;
                valData.countryId =
                    template && template.countryId && template.countryId._id
                        ? template.countryId._id
                        : null;
                valData.courseId =
                    template && template.courseId && template.courseId._id
                        ? template.courseId._id
                        : null;
                valData.templateId = template && template._id ? template._id : null;
                let x = await AddTemplateSectionFxn(valData);
                console.log(x, "xxxxxxxxxxfor resolve");

                if (!x.error) {
                    notification.success({
                        message: x.message,
                    });
                    getSingleTemplate(templateId);
                    handleDrawerClose();
                    props.form.setFieldsValue({
                        categoryId: "",
                        title: "",
                        content: "",
                    });
                }
            }
        });
    };

    let inputTypes = {
        fields: [
            {
                key: "categoryId",
                label: "Category Name",
                span: 6,
                required: true,
                placeholder: "Category Name",
                type: "select",
                options: categoryList,
                keyAccessor: (x) => x._id,
                valueAccessor: (x) => `${x.categoryName}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        categoryId: x,
                    });
                },
            },
            {
                key: "title",
                label: "Section Title",
                // required: true,
                placeholder: "Section Title",
                type: "text",
            },

            {
                key: "content",
                label: "Section Content",
                type: "ckeditor",
                required: true,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        content: x,
                    });
                },
            },
            // {
            //     key: "content",
            //     label: "Section Content",
            //     type: "styleEditor",
            //     required: true,
            //     onChange: (x) => {
            //         props.form.setFieldsValue({
            //             content: x,
            //         });
            //     },
            // },
        ],
    };

    // 2nd drawer
    let inputTypesCategory = {
        fields: [
            {
                key: "categoryName",
                label: "Category Name",
                required: true,
                placeholder: "Category Name",
                type: "text",
            },
        ],
    };
    const handleSubmitCategory = (e) => {
        const {form} = props;
        e.preventDefault();
        form.validateFields(async (err, valData) => {
            console.log(err);
            if (!err) {
                let x = await AddTemplateCategory(valData);
                if (!x.error) {
                    notification.success({
                        message: x.message,
                    });
                    form.resetFields();
                    getCategoryList();
                    handleDrawerCloseCategory();
                }
            }
        });
    };
    const deleteSection = async (sectionId) => {
        let resp = await dispatch(RemoveSectionFxn({sectionId, templateId}));
        if (resp) {
            tableRef.current.reload()
        }
    }
    return (
        <>
            <Card>
                <div className="justify-content-center">
                    <div className="detail-box">
                        <div className="flex-box mb-4">
                            <h3 className="m-0">Template : {template && template.templateName
                                ? template.templateName
                                : null}</h3>
                            <div className="ms-auto">
                                <button className="btn p-0" onClick={() => {
                                    navigate(`/master/edit-template?_id=${templateId}`)
                                }}><img src={imgPath.edit} alt="" width="25"/></button>
                                <button className="btn p-0" onClick={() => {
                                    window.open(`${apiUrl}/template/${templateId}`, '_blank')
                                }}><img src={imgPath.view} alt="" width="25"/></button>
                            </div>
                        </div>
                        <ul>
                            <li><span>Country:</span>
                                {template &&
                                template.countryId &&
                                template.countryId.countryName
                                    ? template.countryId.countryName
                                    : null}
                            </li>
                            <li><span>University:</span>
                                {template &&
                                template.universityId &&
                                template.universityId.universityName
                                    ? template.universityId.universityName
                                    : null}
                            </li>
                            <li><span>Course:</span> {template &&
                            template.courseId &&
                            template.courseId.courseName
                                ? template.courseId.courseName
                                : null}</li>
                            <li></li>
                            <li><span>Header Design:</span> {template && template.headerDesign
                                ? parse(template.headerDesign)
                                : null}</li>
                            <li><span>Footer Design:</span> {template && template.footerDesign
                                ? parse(template.footerDesign)
                                : null}</li>
                        </ul>
                    </div>

                </div>
            </Card>
            <button className="ms-auto main-btn mb-4" onClick={handleDrawerOpen}>Add Section
            </button>


            {isDrawerOpenCategory ? (
                <TemplateCategory
                    visible={isDrawerOpenCategory}
                    onClose={handleDrawerCloseCategory}
                    countryId={template.countryId}
                    getCategoryList={() => {
                        getCategoryList();
                    }}
                />
            ) : null}
            <br/>
            {template && template.sections && template.sections.length
                ? template.sections.map((item, i) => (
                    <Card
                        key={i}
                        title={`${item && item.sectionId && item.sectionId.title} (${item.categoryId.categoryName})`}
                        extraBtn={[
                            {
                                type: "icon",
                                btnType: "edit",
                                action: () => {
                                    events.showEditDrawer(item.sectionId)
                                }
                            },
                            {
                                type: "icon",
                                btnType: "close",
                                action: () => {
                                    confirm({
                                        title: `Are you sure, you want to delete this section?`,
                                        onSubmit: () => {
                                            deleteSection(item.sectionId)
                                        },
                                        onCancel() {
                                            console.log('Cancel');
                                        },
                                    });

                                }
                            }
                        ]}


                    >

                        <p className={'text-dark'}>
                            {item.sectionId && item.sectionId.content
                                ? parse(item.sectionId.content)
                                : null}
                        </p>

                    </Card>

                ))
                : null}
            {sectionData.visible ? (
                <TemplateSectionEdit
                    visible={sectionData.visible}
                    onClose={events.hideEditDrawer}
                    sectionData={sectionData.categorySection}
                />
            ) : null}
            <Drawer
                placement="right"
                onClose={handleDrawerClose}
                visible={isDrawerOpen}
                width={"70%"}
                title={"Add Template-Section"}
            >
                <Form onSubmit={handleSubmit}>
                    <div className={"row"}>
                        {inputTypes.fields.map((item, key) => {
                            return (
                                <div className={"col-md-12 pt-3"} key={key}>
                                    <GetEachFormFields
                                        {...props.form}
                                        item={item}
                                    />
                                    {key === 0 && (
                                        <button
                                            type="button"
                                            onClick={handleDrawerOpenCategory}
                                            className="btn btn-info"
                                            size="small"
                                            style={{marginTop: "6px", padding: "4px 8px"}}
                                        >
                                            Add Category
                                        </button>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                    <Button type={"primary"} htmlType="submit" className={"mt-4"}>
                        submit
                    </Button>
                </Form>
            </Drawer>
        </>
    );
};

export default Form.create()(TemplateView);
