import { apiUrl } from "../../settings";

export const getRelatedCityUrl = () => {
  return apiUrl + "/getRelatedMasterCities";
};

export const addUniversityDataUrl = () => {
  return `${apiUrl}/addMasterUniversity`;
};

export const UniversityListUrl = () => {
  return `${apiUrl}/masterUniversities`;
};

export const postUniversityUrl = () => {
  return `${apiUrl}/updateMasterUniversity`;
};

export const getUniversityUrl = (universityId) => {
  return apiUrl + "/getSingleMasterUniversity/" + universityId;
};

export const updateUniversityActiveStatusUrl = () => {
  return apiUrl + "/updateUniversityAvailability";
};

export const addRepresentingUniversityUrl = () => {
  return `${apiUrl}/addUniversity`;
};
export const representingUniversityListUrl = () => {
  return `${apiUrl}/universityList`;
};
export const companyCountryListUrl = () => {
    return `${apiUrl}/countryStatusList`;
};
export const partnerListUrl = () => {
    return `${apiUrl}/partnerList`;
};
export const updateCampusUrl = () => {
    return `${apiUrl}/updateCampus`;
};
export const addCampusUrl = () => {
    return `${apiUrl}/addCampus`;
};
