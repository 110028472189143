import S from "string";

export const PureString = (value) => {
    return S(value).humanize().s;
};
export const milliSecToMin = (value) => {
    const oneMinInMilliSec = 60000;
    return parseFloat((value / oneMinInMilliSec).toFixed(2));
};

export const RoundOf = (value) => {
    return parseFloat(value.toFixed(2));
};

export const genderOption = [{option: "Male"}, {option: "Female"}];
export const statusOption = [
    {option: "Pending"},
    {option: "Closed"},
    {option: "Deleted"},
];
export const maritalStatusOption = [
    {option: "Married"},
    {option: "Unmarried"},
    {option: "Divorced"},
];
export const leaveOption = [
    {option: "casual"},
    {option: "sick"},

];
export const leadSourceOption = [
    {option: "Walk-in"},
    {option: "Advertisements"},
    {option: "Email"},
    {option: "Linkedin"},
    {option: "Email"},
    {option: "Google AdWord"},
    {option: "Telephonic"},
    {option: "Website"},
];
export const followUpOptions = [
    {option: "Call"},
    {option: "Meeting"},
    {option: "SMS"},
    {option: "Email"},
    {option: "Zoom"},
];
export const reminderMeOptions = [
    {option: "Minutes"},
    {option: "Hours"},
    {option: "Days"},
];

export const qualificationOptions = [
    {option: "X"},
    {option: "XII"},
    {option: "Bachelor Degree"},
    {option: "Post Graduation"},
    {option: "Diploma"},
];

export const inputFieldsOptions = [
    {option: "text", label: "Text"},
    {option: "select", label: "Drop Down"},
    {option: "date", label: "Date"},
];
export const streamsList = [
    {option: "Commerce"},
    {option: "Non Medical"},
    {option: "Medical"},
    {option: "Arts"},
];
export const EnglishExamTypes = [
    {option: "IELTS"},
    {option: "PTE"},
    {option: "TOEFL"},
    {option: "Duolingo English Test"},
    {option: "I don't have this"},
];
export const boardList = [
    {option: "CBSE - Central Board of Secondary Education", value: "CBSE"},
    {option: "ISC - Indian School Certificate", value: "ISC"},
    {option: "ICSE - Indian School Certificate Examinations", value: "ICSE"},
    {option: "NIOS – National Institute of Open Schooling", value: "NIOS"},
    {
        option:
            "UP Board - Board of High School and Intermediate Education Uttar Pradesh",
        value: "UP Board",
    },
    {
        option: "JKBOSE - Jammu and Kashmir State Board of School Education",
        value: "JKBOSE",
    },
    {option: "RBSE - Board of Secondary Education Rajasthan", value: "RBSE"},
    {
        option: "HPBOSE - Himachal Pradesh Board of School Education",
        value: "HPBOSE",
    },
    {
        option: "MPBSE - Madhya Pradesh Board of Secondary Education",
        value: "MPBSE",
    },
    {
        option: "CGBSE - Chhattisgarh Board of Secondary Education",
        value: "CGBSE",
    },
    {option: "PSEB – Punjab School Education Board", value: "PSEB"},
    {
        option: "BSEH - Haryana Board of School Education (HBSE)",
        value: "BSEH",
    },
    {option: "BSEB - Bihar School Examination Board", value: "BSEB"},
    {
        option: "GSEB - Gujarat Secondary and Higher Secondary Education Board",
        value: "GSEB",
    },
    {
        option:
            "MSBSHSE – Maharashtra State Board Of Secondary and Higher Secondary Education",
        value: "MSBSHSE",
    },
    {
        option: "BIEAP - Andhra Pradesh Board of Intermediate Education",
        value: "BIEAP",
    },
    {
        option: "BSEAP - Andhra Pradesh Board of Secondary Education",
        value: "BSEAP",
    },
    {
        option: "WBBSE - West Bengal Board of Secondary Education",
        value: "WBBSE",
    },
    {
        option: "WBCHSE - West Bengal Council of Higher Secondary Education",
        value: "WBCHSE",
    },
];
export const UserTitle = {
    admin: "Administrator",
    agent: "Partner",
};

export const followUpIcon = {
    SMS: "../assets/images/sms.png",
    Zoom: "../assets/images/zoom.png",
    Call: "../assets/images/phone-call.png",
    Email: "../assets/images/gmail.png",
    Meeting: "../assets/images/meet.png",
};
export const getAvatar = (name) => {
    return name ? name.charAt(0) : null;
};

export const months = {
    list: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ],
    jsonList: {
        1: "January",
        2: "February",
        3: "March",
        4: "April",
        5: "May",
        6: "June",
        7: "July",
        8: "August",
        9: "September",
        10: "October",
        11: "November",
        12: "December",
    },
};

export const dateFormats = [
    "DD/MM/YYYY",
    "MM/DD/YYYY",
    "DD.MM.YYYY",
    "DD-MM-YYYY",
    "MM-DD-YYYY",
    "YYYY-MM-DD",
    "DD-MMM-YYYY",
];
export const timeFormats = [
    {name: "12 Hour", value: "12hour"},
    {name: "24 Hour", value: "24hour"},
];
export const assigntype = [
    {name: "auto", value: "auto"},
    {name: "manual", value: "manual"},
];
export const InstallmentOptions = [
    {option: "1"},
    {option: "2"},
    {option: "3"},
    {option: "4"},
];
export const taxObj = {
    serviceTax: 15,
    gst: 18,
};
export const conditionOptions = [
    {name: "Yes", value: true, display: "Enable"},
    {name: "No", value: false, display: "Disable"},
];
export const defaultStatusList = [
    {status: "New Application", statusType: "Offer Process", index: 0},
    {status: "Offer Applied", statusType: "Offer Process", index: 1},
    {status: "Offer Received", statusType: "Offer Process", index: 2},
    {status: "Fees Payment", statusType: "Offer Process", index: 3},
    {status: "Visa Approved", statusType: "Visa Process", index: 4},
    {status: "Visa Reject", statusType: "Visa Process", index: 5},
    {status: "Case Closed", statusType: "Visa Process", index: 6},
];
export const paymentOptions = [
    {label: "Credit Card", value: "Credit Card"},
    {label: "Cheque", value: "Cheque"},
    {label: "Bhim", value: "BHIM UPI"},
    {label: "PayTm", value: "PayTm"},
    {label: "NEFT", value: "NEFT"},
    {label: "IMPS", value: "IMPS"},
    {label: "RTGS", value: "RTGS"},
];
export const EnglishExamOptions = [
    {name: "IELTS"},
    {name: "PTE"},
    {name: "TOEFL"},
    {name: "Duolingo English Test"}
];

export const imgPath = {
    "edit": "../app/img/icons/edit.svg",
    "view": "../app/img/icons/view.svg",
    "transfer": "../app/img/icons/transfer.svg",
    "menu": "../app/img/icons/menu.svg",
    "close": "../app/img/icons/close.svg",
    "wallet": "../app/img/icons/wallet.svg",
    "check": "../app/img/icons/check.svg",
    "student": "../app/img/icons/student.svg",
    "search": "../app/img/icons/search.svg",
    "upload": "../app/img/icons/upload.svg",
}
export let statusColor = {
    "New Application": "application-submitted",
    "Offer Applied": "offer-applied",
    "Offer Received": "yellow-btn",
    "Fees Payment": "blue-btn",
    "Visa Approved": "visa-approved",
    "Visa Reject": "red-btn",
    "Case Closed": "black-btn"
}

export let gicQuestionList = [
    "What is the name of your first pet?",
    "What is the name of your school?",
    "What is your favorite color?",
    "What was the make your first car?",
    "Which is your favorite sport?"
]
