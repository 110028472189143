import {hideLoader, showLoader} from "../../actions/loader";
import notification from "../../components/Elements/Notification";
import {customAxios as axios, getToken} from "../../request";
import {
    deleteSingleLeadDocumentUrl,
    deleteStudentDocumentUrl,
} from "../SingleLead/api";

import {
    addDocumentsStudentUrl,
    getSingleStudentDocumentsUrl,
    singleStudentUrl,
    updateApplicationUrl,
    updateStudentUrl,
} from "./api";

export const singleStudentFxn = (id) => async (dispatch) => {
  dispatch(showLoader());
  let config = {
    ...(await getToken()),
  };
  let { data } = await axios.get(singleStudentUrl(id), config);
  console.log(data);
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  }

  return data;
};

export const updateStudentFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
    let {data} = await axios.post(updateStudentUrl(), valData, getToken());
  dispatch(hideLoader());
  if (data.error) {
      notification.error({
          message: data.message || "Error",
      });
  } else {
      notification.success({
          message: data.message || "Success",
      });
  }
  return data;
};

export const updateApplicationFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  let { data } = await axios.post(updateApplicationUrl(), valData, getToken());
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  } else {
    notification.success({
      message: data.message || "Success",
    });
  }
  return data;
};
export const addDocumentToStudentFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  let { data } = await axios.post(
    addDocumentsStudentUrl(),
    valData,
    getToken()
  );
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  } else {
    notification.success({
      message: data.message || "Success",
    });
  }
  return data;
};

export const getSingleStudentDocumentsFxn = (id) => async (dispatch) => {
    dispatch(showLoader());
    let config = {
        ...(await getToken()),
    };
    let {data} = await axios.get(getSingleStudentDocumentsUrl(id), config);

    dispatch(hideLoader());
    // if (data.error) {
    //     notification.error({
    //         message: data.message || "Error",
    //     });
    // }

    return data;
};

export const deleteStudentDocumentFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());

    let {data} = await axios.post(
        deleteStudentDocumentUrl(),
        valData,
        getToken()
    );
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }

    return data;
};
