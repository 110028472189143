import flagJson from "../../assets/jsonFiles/countryCodeFlag.json";
import currencyJson from "../../assets/jsonFiles/currency_list.json";
import cityJson from "../../assets/jsonFiles/indiaCities.json";
import countryJson from "../../assets/jsonFiles/newcountries.json";
import stateJson from "../../assets/jsonFiles/states.json";
import timezonesJson from "../../assets/jsonFiles/timezones_list.json";

export const Countries = [...countryJson];
export const States = [...stateJson];
export const Cities = [...cityJson];
export const Flags = [...flagJson];
export const TimeZones = [...timezonesJson];
export const CurrencyJson = [...currencyJson];
