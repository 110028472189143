import _ from "lodash";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {
    Button,
    Card,
    Form,
    MonthList,
    notification,
} from "../../../components/Elements/appUtils";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import {getCampusListFxn} from "../../AddStudent/actions";
import {
    GetRelatedCourseFxn,
    getRelatedUniversityListFxn,
    postRelatedCourseFxn,
} from "../../Course/action";
import { courseCategoryList } from "../courseCategory/action";
import { loadCountryList } from "../state/action";

const EditCourse = (props) => {
    const {getFieldDecorator} = props.form;
    const [countryList, setCountryList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [courseId, setCourseId] = useState("");
    const [universityList, setUniversityList] = useState([]);
    const [campusList, setCampusList] = useState([]);

    let dispatch = useDispatch();

    useEffect(() => {
        handleState();
        getCampusData();
    }, []);

    const handleState = async () => {
        let x = await loadCountryList();
        setCountryList(x);
    };

    const getRelatedUniversities = async (id) => {
        if (id) {
            console.log(id, "jgjdfjgf");
            let {data} = await getRelatedUniversityListFxn(id);
            setUniversityList(data);
        }
    };
    const getReleatedCampus = async (id) => {
        if (id) {
            let {data} = await getCampusListFxn({universityId: id});
            setCampusList(data);
        }
    };

    useEffect(() => {
        handleCourseCategory();
    }, []);

    const handleCourseCategory = async () => {
        let {data} = await courseCategoryList();
        setCategoryList(data.data);
    };

    const getCampusData = async () => {
        let searchParams = new URLSearchParams(window.location.search);
        let id = searchParams.get("_id");

        if (id) {
            setCourseId(id);
            getRelatedCourse(id);
        }
    };

    const setCampusField = () => {
    };
    const getRelatedCourse = async (id) => {
        let {data} = await dispatch(GetRelatedCourseFxn(id));
        if (data && data.universityCountry && data.universityCountry._id) {
            getReleatedCampus(data.universityId._id);

            getRelatedUniversities(data.universityCountry._id);
        }

        setTimeout(() => {
            props.form.setFieldsValue({
                tuitionFee: data.tuitionFee,
                courseName: data.courseName,
                courseDuration: data.courseDuration,
                courseLevel: data.courseLevel,
                universityId:
                    data && data.universityId && data.universityId._id
                        ? data.universityId._id
                        : null,
                categoryId: data && data.categoryId && data.categoryId._id ? data.categoryId._id : null,
                universityCountry: data.universityCountry._id,
                // campus: data.campus,
                intakes: data.intakes,
            });
            if (data && data.campus && data.campus.length) {
                let campusArray = [];
                _.each(data.campus, (item) => {
                    campusArray.push(item._id);
                });
                props.form.setFieldsValue({
                    campus: campusArray,
                });
            }
        }, 500);
    };
    const handleSubmit = (e) => {
        const { form } = props;
        e.preventDefault();
        form.validateFields(async (err, valData) => {
            console.log(err, valData);
            if (!err) {
                valData.courseId = courseId;

                let x = await dispatch(postRelatedCourseFxn(valData));
            } else {
                if (err.universityCountry) {
                    notification.warning({
                        message: "Please enter University Country",
                    });
                    return;
                }

                if (err.universityId) {
                    notification.warning({
                        message: "Please enter University Name",
                    });
                    return;
                }

                /*if (err.campus) {
                    notification.warning({
                        message: "Please select Campus",
                    });
                    return;
                }*/

                if (err.courseName) {
                    notification.warning({
                        message: "Please enter Course Name",
                    });
                    return;
                }

                if (err.courseDuration) {
                    notification.warning({
                        message: "Please enter Course Duration",
                    });
                    return;
                }

                if (err.courseLevel) {
                    notification.warning({
                        message: "Please select Course Level",
                    });
                    return;
                }

                if (err.intakes) {
                    notification.warning({
                        message: "Please select Intakes",
                    });
                    return;
                }

                if (err.tuitionFee) {
                    notification.warning({
                        message: "Please enter Tuition Fee",
                    });
                    return;
                }

                if (err.categoryId) {
                    notification.warning({
                        message: "Please select Category Id",
                    });
                    return;
                }
            }
        });
    };
    let inputTypes = {
        fields: [
            {
                key: "universityCountry",
                label: "University Country",
                required: true,
                showStar: true,
                placeholder: "University Country",
                type: "select",
                options: countryList,
                keyAccessor: (x) => x._id,
                valueAccessor: (x) => `${x.countryName}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        universityCountry: x,
                    });
                    getRelatedUniversities(x);
                },
                span: 8,
            },
            {
                key: "universityId",
                label: "University Name",
                required: true,
                showStar: true,
                placeholder: "University  Name",
                type: "select",
                options: universityList,
                keyAccessor: (x) => x._id,
                valueAccessor: (x) => `${x.universityName}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        universityId: x,
                    });
                },
                span: 8,
            },
            {
                key: "campus",
                label: "Campus",
                required: false,
                placeholder: "Campus",
                type: "select",
                multiple: true,
                showStar: true,
                options: campusList,
                keyAccessor: (x) => x._id,
                valueAccessor: (x) => `${x.campus}`,
                onChange: (x) => {
                    setCampusField(x);
                    props.form.setFieldsValue({
                        campus: x,
                    });
                },
            },
            {
                key: "courseName",
                label: " Course Name",
                required: true,
                showStar: true,
                placeholder: "Course",
                type: "text",
            },
            {
                key: "courseDuration",
                label: "Duration",
                required: true,
                showStar: true,
                placeholder: "Duration",
                type: "text",
            },
            {
                key: "courseLevel",
                label: "Level",
                required: true,
                showStar: true,
                placeholder: "Course -Level",
                type: "select",
                options: [
                    {name: "1-Year Post-Secondary Certificate"},
                    {name: "2-Year Undergraduate Diploma"},
                    {name: "3-Year Undergraduate Advanced Diploma"},
                    {name: "3-Year Bachelor's Degree"},
                    {name: "4-Year Bachelor's Degree"},
                    {name: "5-Year Bachelor's Degree"},
                    {name: "Postgraduate Certificate / Master's Degree"},
                    {name: "Doctoral Degree (Phd, M.D., ...)"},
                ],
                keyAccessor: (x) => x.name,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        courseLevel: x,
                    });
                },
                span: 8,
            },
            {
                key: "intakes",
                label: "Select Months for Intake",
                required: false,
                showStar: true,
                placeholder: "Select Months for Intake",
                type: "select",
                multiple: true,
                options: MonthList,
                keyAccessor: (x) => x.name,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        intakes: x,
                    });
                },
                span: 8,
            },
            {
                key: "tuitionFee",
                label: "Tuition Fee",
                required: true,
                showStar: true,
                placeholder: "Fee",
                type: "text",
            },
            {
                key: "categoryId",
                label: "Category Id",
                required: true,
                showStar: true,
                placeholder: "Category Id",
                type: "select",
                options: categoryList,
                keyAccessor: (x) => x._id,
                valueAccessor: (x) => `${x.categoryName}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        categoryId: x,
                    });
                },
                span: 8,
            },
        ],
    };
    return (
        <>
            <>
                <Card title={"Edit Course"}>
                    <Form onSubmit={handleSubmit}>
                        <div className={"row"}>
                            {inputTypes.fields.map((item, key) => {
                                return (
                                    <div className={"col-md-6 pt-3"} key={key}>
                                        <GetEachFormFields {...props.form} item={item} />
                                    </div>
                                );
                            })}
                        </div>

                        <div style={{ marginTop: "20px" }}>
                            <Button type={"primary"} htmlType="submit">
                                Update
                            </Button>
                        </div>
                    </Form>
                </Card>
            </>
        </>
    );
};

export default Form.create()(EditCourse);
