import _ from "lodash";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {
    Button,
    Card,
    Form,
    InputBox,
    notification,
} from "../../../components/Elements/appUtils";
import {CurrencyJson} from "../../../components/_utils/appUtils";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import {
    getRelatedCountryFxn,
    singleCountryStatusFxn,
    updateCountryFxn,
} from "../../Country/action";
import StatusComponent from "../../Country/components/statusComponent";

const statusInIt = {status: ""};

const EditCountry = (props) => {
    const {getFieldDecorator, setFieldsValue} = props.form;
    const [country, setCountry] = useState([]);
    const [countryId, setCountryId] = useState("");
    const [oldFlag, setOldFlag] = useState({});
    const [status, setStatus] = useState([statusInIt]);
    const [flag, setFlag] = useState({});
    let dispatch = useDispatch();

    const events = {
        addMore: () => {
            setStatus([...status, statusInIt]);
        },
        deleteItem: (itemId) => {
            const updatedItems = status.filter((item, key) => key !== itemId);
            setStatus(updatedItems);
        },
        updateState: (data, index) => {
            let cloneStatus = _.clone(status);
            cloneStatus[index] = {...cloneStatus[index], ...data};
            setStatus(cloneStatus);
        },
        loadCountryStatus: async (countryId) => {
            let {data, success} = await singleCountryStatusFxn({countryId});
            if (success) {
                if (data.statusList && data.statusList.length) {
                    setStatus(data.statusList);
                }
            }
        },
    };
    const getCampusData = async () => {
        let searchParams = new URLSearchParams(window.location.search);
        let id = searchParams.get("_id");
        if (id) {
            setCountryId(id);
            getRelatedCountry(id);
            events.loadCountryStatus(id);
        }
    };

    const getCurrencySymbol = (name) => {
        let symbol = _.find(CurrencyJson, (item) => {
            return item.name == name;
        });
        if (symbol) {
            setFieldsValue({currencySymbol: symbol.value});
        }
    };

    const getRelatedCountry = async (id) => {
        let {data} = await dispatch(getRelatedCountryFxn(id));
        setCountry(data);
        props.form.setFieldsValue({
            countryName: data.countryName,
            documents: data.documents,
            interview: data.interview,
            currency: data.currency,
            currencySymbol: data.currencySymbol,
            // flag: data.flag.path
        });
        if (data.flag && data.flag.path) {
            setOldFlag(data.flag.path);
        }
    };

    useEffect(() => {
        getCampusData();
    }, []);
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFlag(file);
    };
    const handleSubmit = (e) => {
        const {form} = props;
        e.preventDefault();
        form.validateFields(async (err, valData) => {
            if (!err) {
                valData.countryId = countryId;
               /* valData.statusList = status;

                valData.statusList = _.reject(valData.statusList, (item) => {
                    return !item.status;
                });*/
                let fd = new FormData();
                fd.append("obj", JSON.stringify(valData));
                if (flag && flag.name) {
                    fd.append("flag", flag);
                }
                let x = await dispatch(updateCountryFxn(fd));
            } else {
                notification.warning({
                    message: "Fill All Required Fields",
                });
            }
        });
    };

    let inputTypes = {
        fields: [
            {
                key: "countryName",
                label: "Country",
                required: true,
                placeholder: "Country Name",
                type: "text",
            },
            {
                key: "interview",
                label: "Interview",
                required: true,
                placeholder: "Interview",
                type: "select",
                options: [{name: "Yes"}, {name: "No"}, {name: "Maybe"}],
                keyAccessor: (x) => x.name,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        interview: x,
                    });
                },
                span: 8,
            },
            {
                key: "currency",
                label: "Currency",
                span: "col-md-3",
                type: "select",
                required: true,
                options: CurrencyJson,
                showSearch: true,
                keyAccessor: (x) => x.name,
                valueAccessor: (x) => x.name,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        currency: x,
                    });
                    getCurrencySymbol(x);
                },
            },
            {
                key: "currencySymbol",
                label: "Currency Symbol",
                required: true,
                disabled: true,
                placeholder: "Currency Symbol",
                type: "text",
            },
            {
                key: "documents",
                label: "documents",
                required: true,
                placeholder: "documents",
                type: "text",
            },
            {
                key: "flag",
                label: "Flag",
                type: "file",
                onChange: handleFileChange,
                urlPath: oldFlag,
            },
        ],
    };
    return (
        <>
            {" "}
            <Form onSubmit={handleSubmit}>
                <Card title={"Edit Country"}>
                    <div className={"row"}>
                        {inputTypes.fields.map((item, key) => {
                            return (
                                <div className={"col-md-6 pt-3"} key={key}>
                                    <GetEachFormFields {...props.form} item={item}/>
                                </div>
                            );
                        })}
                    </div>
                    <div className={"mt20"}>
                        <Button type={"primary"} htmlType="submit">
                            Update
                        </Button>
                    </div>
                </Card>
                {/* <Card title={"Country Status"}> */}
                    {/* <div className={"row"}>
                        {status && status.length
                            ? status.map((data, index) => {
                                return (
                                    <StatusComponent
                                        data={data}
                                        key={index}
                                        index={index}
                                        status={status}
                                        updateState={events.updateState}
                                        deleteItem={events.deleteItem}
                                    />
                                );
                            })
                            : null}
                    </div> */}
                    {/* <a className={"btn btn-link floatRight"} onClick={events.addMore}>
                        Add More Status
                    </a>*/}

                {/* </Card> */}

            </Form>
        </>
    );
};

export default Form.create()(EditCountry);
