import moment from "moment";

import React, {useEffect, useState} from "react";

import {useDispatch} from "react-redux";

import {
    Card,
    Drawer,
    DropDown,
    Modal,
    Tooltip,
    displayDate,
    displayTime,
    getStatusColor,
} from "../../components/Elements/appUtils";

import {activityListFxn, applicationNoteFxn} from "../Student/actions";

const SingleNoteOld = (props) => {
    let {data, index, events} = props;

    let {intake, universityId, countryId, campusId, message, assignTo} = data;

    return (
        <>
            {/* <Card key={data._id}> */}
            <div className="bar" key={data._id}>
                <div className="custom-div">
                    <div style={{marginLeft: "20px"}}>
                        <div className="row application_list">
                            <div className="col-md-12">
                                <div className="date-display">{displayDate(data._id)}</div>
                            </div>
                        </div>
                        {data && data.activities && data.activities.length
                            ? data.activities.map((item) => {
                                return (
                                    <div className="row application_list ">
                                        <div className="col-md-12">
                                            <div className="flex1">
                                                <div
                                                    className="basic-info"
                                                    style={{padding: "13px"}}
                                                >
                                                    {!item.oldStatus ? (
                                                        <>
                                                            {" "}
                                                            <span>
                                  {getStatusColor(item.newStatus)} Student Added
                                  On{" "}
                                                                <strong>{displayTime(item.createdAt)}</strong>
                                </span>
                                                            <p></p>
                                                            {getStatusColor(item.newStatus)}
                                                            <span>
                                  {item.title} On{" "}
                                                                <strong>{displayTime(item.createdAt)}</strong>
                                </span>
                                                        </>
                                                    ) : null}
                                                    <span>
                              {item &&
                              item.oldStatus &&
                              item.addByUserId &&
                              item.addByUserId.name ? (
                                  <>
                                      {" "}
                                      {getStatusColor(item.newStatus)}{" "}
                                      <span
                                          dangerouslySetInnerHTML={{
                                              __html: ` ${
                                                  item.addByUserId.name
                                              } Updated the status from <strong>${
                                                  item.oldStatus
                                              }</strong> to <strong>${
                                                  item.newStatus
                                              }</strong> and Assigned to ${
                                                  item.assignTo && item.assignTo.name
                                                      ? `<strong>${
                                                          item.assignTo.name
                                                      }</strong> On <strong>${displayTime(
                                                          item.createdAt
                                                      )}</strong>`
                                                      : "<strong>N/A</strong>"
                                              }`,
                                          }}
                                      />
                                  </>
                              ) : null}
                            </span>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className="col-md-4">
        <div className="lead_label">Assign To</div>
            <div className="basic-info">
     <div className="lead_info">

                  <div className="single_box">

                    <i className=" fa fa-user"> </i>

                    {assignTo && assignTo.name ? assignTo.name : null}

                  </div>

                </div>

                <div className="lead_info">

                  <div className="single_box">

                    <i className="fa fa-calendar"> </i>



                  </div>

                </div>

              </div>

            </div> */}
                                    </div>
                                );
                            })
                            : null}
                    </div>
                </div>
            </div>
            {/* </Card> */}
        </>
    );
};

const SingleNote = (props) => {
    let {data, index, events} = props;

    let {intake, universityId, countryId, campusId, message, assignTo} = data;

    return (
        <>
            {/* <Card key={data._id}> */}
            <div className="bar" key={data._id}>
                <div className="custom-div">
                    <div style={{marginLeft: "20px"}}>
                        <div className="row application_list">
                            <div className="col-md-12">
                                <div className="date-display">{displayDate(data._id)}</div>
                            </div>
                        </div>
                        {data && data.activities && data.activities.length
                            ? data.activities.map((item) => {
                                return (
                                    <div className="row application_list ">
                                        <div className="col-md-12">
                                            <div className="flex1">
                                                <div
                                                    className="basic-info"
                                                    style={{padding: "13px"}}
                                                >
                                                    {!item.oldStatus ? (
                                                        <>
                                                            {" "}
                                                            <span>
                                  {getStatusColor(item.newStatus)} Student Added
                                  On{" "}
                                                                <strong>{displayTime(item.createdAt)}</strong>
                                </span>
                                                            <p></p>
                                                            {getStatusColor(item.newStatus)}
                                                            <span>
                                  {item.title} On{" "}
                                                                <strong>{displayTime(item.createdAt)}</strong>
                                </span>
                                                        </>
                                                    ) : null}
                                                    <span>
                              {item &&
                              item.oldStatus &&
                              item.addByUserId &&
                              item.addByUserId.name ? (
                                  <>
                                      {" "}
                                      {getStatusColor(item.newStatus)}{" "}
                                      <span
                                          dangerouslySetInnerHTML={{
                                              __html: ` ${
                                                  item.addByUserId.name
                                              } Updated the status from <strong>${
                                                  item.oldStatus
                                              }</strong> to <strong>${
                                                  item.newStatus
                                              }</strong> and Assigned to ${
                                                  item.assignTo && item.assignTo.name
                                                      ? `<strong>${
                                                          item.assignTo.name
                                                      }</strong> On <strong>${displayTime(
                                                          item.createdAt
                                                      )}</strong>`
                                                      : "<strong>N/A</strong>"
                                              }`,
                                          }}
                                      />
                                  </>
                              ) : null}
                            </span>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className="col-md-4">
        <div className="lead_label">Assign To</div>
            <div className="basic-info">
     <div className="lead_info">

                  <div className="single_box">

                    <i className=" fa fa-user"> </i>

                    {assignTo && assignTo.name ? assignTo.name : null}

                  </div>

                </div>

                <div className="lead_info">

                  <div className="single_box">

                    <i className="fa fa-calendar"> </i>



                  </div>

                </div>

              </div>

            </div> */}
                                    </div>
                                );
                            })
                            : null}
                    </div>
                </div>
            </div>
            {/* </Card> */}
        </>
    );
};

const ApplicationActivityList = (props) => {
    let dispatch = useDispatch();
    let {visible, studentId, applicationId, onClose, onSubmit} = props;
    let [noteList, setNoteList] = useState([]);

    useEffect(() => {
        loadActivityList();
    }, [applicationId._id]);

    let loadActivityList = async () => {
        let params = {
            applicationId: applicationId._id,
        };
        let {data} = await dispatch(activityListFxn(params));
        setNoteList(data);
    };

    return (
        <>
            <Modal
                title={"Activity List"}
                visible={visible}
                onClose={onClose}
                width={"60%"}
            >
                <div>
                    {noteList && noteList.length
                        ? noteList.map((item, index) => {
                            return <SingleNote data={item} key={item._id} index={index}/>;
                        })
                        : null}
                </div>
            </Modal>
        </>
    );
};

export default ApplicationActivityList;
