import React, {useEffect, useRef, useState} from "react";
import {Card, Drawer, Table, TableButton} from "../../../components/Elements/appUtils";
import {getRelatedTemplateSection, templateCategoryList} from "../actions";
import TemplateSectionEdit from "./templateSectionEdit";
import {useDispatch} from "react-redux";

let {TableCard} = Card
let initState = {
    categorySection: {},
    visible: false,

}
const TemplateSectionsList = (props) => {
    let dispatch = useDispatch()
    let {visible, onClose, categoryId} = props;
    let [sectionList, setSectionList] = useState([]);
    let [sectionData, setSectionData] = useState(initState);
    let tableRef = useRef();
    const events = {
        showEditDrawer: (record) => {
            setSectionData({
                categorySection: record,
                visible: true
            })
        },
        hideEditDrawer: () => {
            setSectionData({
                categorySection: {},
                visible: false
            })
        }
    }

    useEffect(() => {
        apiRequest();
    }, [categoryId]);

    const apiRequest = async () => {
        let {data} = await dispatch(getRelatedTemplateSection(categoryId));
        setSectionList(data);
    };

    const columns = [
        {
            title: "Title",
            dataIndex: "title",
            key: "title",
        },
        {
            title: "Country Name",
            dataIndex: "countryId",
            key: "countryId",
            width: 200,
            render: (item) => {
                return item && item.countryName ? item.countryName : "";
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            width: 100,
            render: (val, record) => {
                return (
                    <>
                        <TableButton onClick={() => events.showEditDrawer(record)} type={'edit'}/>

                    </>
                )
            }
        },
    ];

    return (
        <>
            <Drawer placement="right" onClose={onClose} visible={visible} width="65%" title={"Template Category List"}>
                <TableCard key={sectionList}>
                    <Table dataSource={sectionList} columns={columns} ref={tableRef}/>
                </TableCard>
                {sectionData.visible ? <TemplateSectionEdit
                    visible={sectionData.visible}
                    onClose={events.hideEditDrawer}
                    sectionData={sectionData.categorySection}
                /> : null}
            </Drawer>
        </>
    );
};

export default TemplateSectionsList;
