import {apiUrl} from "../../settings";

export const singleLeadUrl = (LeadId) => {
    return apiUrl + "/getSingleLead/" + LeadId;
};
export const addDocumentsLeadUrl = () => {
    return apiUrl + "/addDocumentsToLead";
};
export const getSingleLeadDocumentsUrl = (leadId) => {
    return apiUrl + "/getSingleLeadDocuments/" + leadId;
};
export const deleteStudentDocumentUrl = () => {
    return apiUrl + "/deleteStudentDocument";
};
export const deleteLeadDocumentUrl = () => {
    return apiUrl + "/deleteLeadDocument"
}