import _ from "lodash";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {
  Button,
  Card,
  Form,
  InputBox,
  Table,
  notification,
} from "../../components/Elements/appUtils";
import {
  EnglishExamTypes,
  genderOption,
  maritalStatusOption,
  qualificationOptions,
} from "../../components/_utils/_utils";
import {Countries, Flags} from "../../components/_utils/appUtils";
import {GetEachFormFields} from "../../components/_utils/formUtils";
import {apiUrl} from "../../settings";
import WorkExperienceComp from "../AddStudent/Components/WorkExperienceComp";
import {
  getCourseCampusListFxn,
  getIntakeListFxn,
} from "../AddStudent/actions";
import {getRelatedUniversityListFxn} from "../Course/action";
import {getSingleLeadFxn} from "../Leads/action";
import {companyCountryList, partnerListFxn} from "../University/action";
import {UserListFxn, getUserCountryStatusWiseFxn} from "../Users/action";
import {
  getCompanyCourseListFxn,
  getRelatedCourseListFxn,
} from "../template/actions";
import {addEmployeeFxn, boardStreamListFxn} from "./actions";
import employeeList from "./employeeList";

const {InnerCard} = Card;
const initialState = {
  countryId: "",
  universityId: "",
  courseId: "",
  name: "",
  dateOfBirth: "",
  gender: "",
  maritalStatus: "",
  address: "",
  mobile: "",
  email: "",
  education: {},
  board: "",
  passingYear: "",
  designation: "",
  pincode: "",
  joiningDate: "",
  percentage: "",
  math: "",
  english: "",
  examType: "",
  englishRequirement: {},
};

const eduStateInit = {
  qualification: "",
  math: "",
  english: "",
  percentage: "",
  board: "",
  university: "",
  stream: "",
  passingYear: "",
  courseName: "",
  schoolName: "",
};

const workStateInit = {
  companyName: "",
  position: "",
  time: "",
  // experienceCertificate: "",
};
let boardFields = ["XII", "X"];
const EducationComponent = (props) => {
  let {data, index, eduEvents, education, deleteItem} = props;
  let {qualification} = data;
  const [streamsList, setStreamList] = useState([]);
  const [boardList, setBoardList] = useState([]);
  const loadBoardList = async (params = {}) => {
    params.type = "Board";

    let x = await boardStreamListFxn(params);
    if (x.data) {
      setBoardList(x.data);
    }
  };

  const loadStreamList = async (params = {}) => {
    params.type = "Stream";
    let x = await boardStreamListFxn(params);
    if (x.data) {
      setStreamList(x.data);
    }
  };

  useEffect(() => {
    loadBoardList();
    loadStreamList();
  }, []);
  return (
      <>
        <div className={index > 0 ? "repeat-sec" : ""}>
          <div className="row">
            <div className="col-lg-4 col-12">
              <InputBox type={"select"}>
                <select
                    className={"form-select form-control"}
                    value={data.qualification}
                    onChange={({target}) => {
                      eduEvents.updateState({qualification: target.value}, index);
                    }}
                >
                  <option value={""}>Choose Qualification</option>
                  {qualificationOptions.map((item) => {
                    return (
                        <option key={item.option} value={item.option}>
                          {item.option}
                        </option>
                    );
                  })}
                </select>
              </InputBox>
            </div>
            {qualification == "Bachelor Degree" ||
            qualification == "Post Graduation" ||
            qualification == "Diploma" ? (
                <>
                  <div className="col-lg-4 col-12">
                    <InputBox title={"University"}>
                      <input
                          placeholder=" Enter University"
                          className={"form-control"}
                          onChange={({target}) => {
                            eduEvents.updateState(
                                {university: target.value},
                                index
                            );
                          }}
                          value={data.university}
                      />
                    </InputBox>
                  </div>
                  <div className="col-lg-4 col-12">
                    <InputBox title={"Course"}>
                      <input
                          placeholder="Please enter your Course"
                          className={"form-control"}
                          onChange={({target}) => {
                            eduEvents.updateState(
                                {courseName: target.value},
                                index
                            );
                          }}
                          value={data.courseName}
                      />
                    </InputBox>
                  </div>
                </>
            ) : null}

            {data.qualification && boardFields.includes(data.qualification) ? (
                <>
                  <div className="col-lg-4 col-12">
                    {/*title={"Board"}*/}
                    <InputBox type={"select"}>
                      <select
                          className={"form-control"}
                          placeholder="Board"
                          onChange={({target}) => {
                            eduEvents.updateState({board: target.value}, index);
                          }}
                          value={data.board}
                      >
                        <option value={""}>Choose Board</option>

                        {boardList.map((item) => {
                          return (
                              <option key={item.name} value={item.name}>
                                {item.name}
                              </option>
                          );
                        })}
                      </select>
                    </InputBox>
                  </div>
                </>
            ) : null}

            {data.qualification == "X" || data.qualification == "XII" ? (
                <div className="col-lg-4 col-12">
                  <InputBox title={"School Name"}>
                    <input
                        className={"form-control"}
                        placeholder="School Name"
                        onChange={({target}) => {
                          eduEvents.updateState({schoolName: target.value}, index);
                        }}
                        value={data.schoolName}
                    />
                  </InputBox>
                </div>
            ) : null}

            <div className="col-lg-4 col-12">
              <InputBox title={"Passing Year"}>
                <input
                    className={"form-control"}
                    placeholder="Passing Year"
                    onChange={({target}) => {
                      eduEvents.updateState({passingYear: target.value}, index);
                    }}
                    value={data.passingYear}
                />
              </InputBox>
            </div>

            <div className={"col-lg-4 col-12"}>
              <InputBox title={"Percentage"}>
                <input
                    placeholder="Please enter your percentage"
                    className={"form-control"}
                    onChange={({target}) => {
                      eduEvents.updateState({percentage: target.value}, index);
                    }}
                    value={data.percentage}
                />
              </InputBox>
            </div>

            {data.qualification == "XII" ? (
                <div className={"col-lg-4 col-12"}>
                  {/*title={"Stream"}*/}
                  <InputBox type={"select"}>
                    <select
                        className={"form-control"}
                        placeholder="Stream"
                        onChange={({target}) => {
                          eduEvents.updateState({stream: target.value}, index);
                        }}
                        value={data.stream}
                    >
                      <option value={""}>Choose Stream</option>

                      {streamsList.map((item) => {
                        return (
                            <option key={item.name} value={item.name}>
                              {item.name}
                            </option>
                        );
                      })}
                    </select>
                  </InputBox>
                </div>
            ) : null}
          </div>

          {index !== 0 ? (
              <button className="btn delete" onClick={() => deleteItem(index)}>
                <i className="fa fa-times-circle"></i>
              </button>
          ) : null}
        </div>
      </>
  );
};

const AddNewEmployee = (props) => {
  let dispatch = useDispatch();
  const {getFieldDecorator, getFieldValue, setFieldsValue} = props.form;
  const [education, setEducation] = useState([eduStateInit]);
  const [experience, setExperience] = useState([workStateInit]);
  const [eduState, setEduState] = useState(eduStateInit);
  const [countryList, setCountryList] = useState([]);
  const [intakesList, setIntakesList] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [campusList, setCampusList] = useState([]);
  const [universityList, setUniversityList] = useState([]);
  const [intake, setIntake] = useState({});
  const [userList, setUserList] = useState([]);
  let [leadId, setLeadId] = useState(undefined);
  let [leadsId, setLeadsId] = useState(undefined);
  let [partnerList, setPartnerList] = useState([]);
  let [partnerListOption, setPartnerListOption] = useState(true);

  const [EmployeeList, setEmployeeList] = useState([]);

  console.log(EmployeeList, "EmployeeList");
  const events = {
    addMore: () => {
      setExperience([...experience, workStateInit]);
    },
    deleteItem: (itemId) => {
      const updatedItems = experience.filter((item, key) => key !== itemId);
      setExperience(updatedItems);
    },
    updateState: (data, index) => {
      let cloneStatus = _.clone(experience);
      cloneStatus[index] = {...cloneStatus[index], ...data};
      setExperience(cloneStatus);
    },
    setCountryCode: (country) => {
      let countryCode = _.find(Flags, (item) => {
        return item.name == country;
      });
      if (countryCode) {
        setFieldsValue({countryCode: countryCode.countryCode});
      }
    },
  };

  const selectIntakes = (_id) => {
    let intake = _.find(intakesList, (item) => {
      return item._id === _id;
    });
    if (intake) {
      let {month, year} = intake;
      setIntake({month, year});
    }
  };

  let user = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null;
  useEffect(() => {
    // events.setCountryCode(user.countryName);
    setFieldsValue({countryCode: user.countryCode});
  }, []);

  const AllData = () => {
    setEducation([...education, eduStateInit]);
  };
  const deleteItem = (itemId) => {
    const updatedItems = education.filter((item, key) => key !== itemId);
    setEducation(updatedItems);
  };

  const eduEvents = {
    updateState: (data, index) => {
      let cloneEdu = _.clone(education);
      cloneEdu[index] = {...cloneEdu[index], ...data};
      setEducation(cloneEdu);
    },
    addEdu: () => {
      if (education) {
        if (education.percentage < 1) {
          notification.warning({
            message: "Enter greater then 0 Percentage",
          });
          return;
        }
        if (education.qualification == "XII") {
          if (education.math < 1) {
            notification.warning({
              message: "Enter greater then 0 Math score",
            });
            return;
          }
          if (education.english < 1) {
            notification.warning({
              message: "Enter greater then 0 English score",
            });
            return;
          }
        }
      }
      setEducation((preState) => {
        return [...preState, eduState];
      });
      eduEvents.updateState(eduStateInit);
    },
  };

  console.log(userList, "userList");

  const handleSubmit = (e) => {
    const {form} = props;
    e.preventDefault();
    form.validateFields(async (err, valData) => {
      if (!err) {
        if (!valData.name) {
          notification.warning({
            message: `Please enter Name`,
          });
          return;
        }

        if (!valData.gender) {
          notification.warning({
            message: `Please select Gender`,
          });
          return;
        }

        if (!valData.address) {
          notification.warning({
            message: `Please enter Address`,
          });
          return;
        }

        if (!valData.countryName) {
          notification.warning({
            message: `Please select Country`,
          });
          return;
        }

        if (!valData.stateName) {
          notification.warning({
            message: `Please Choose State`,
          });
          return;
        }

        if (!valData.cityName) {
          notification.warning({
            message: `Please enter City`,
          });
          return;
        }
        if (!valData.joiningDate) {
          notification.warning({
            message: `Please enter Joining Date`,
          });
          return;
        }

        if (!valData.countryCode) {
          notification.warning({
            message: `Please enter Country Code`,
          });
          return;
        }
        if (!valData.mobile) {
          notification.warning({
            message: `Please enter Mobile Number`,
          });
          return;
        }

        if (!valData.designation) {
          notification.warning({
            message: `Please enter Designation`,
          });
          return;
        }
        if (!valData.assignUser) {
          notification.warning({
            message: `Please select Assign User`,
          });
          return;
        }

        valData = {...valData, education};
        valData.workExperience = experience;

        valData.userType = "Employee";

        let x = await dispatch(addEmployeeFxn(valData));
        console.log(x, "xxxxx");
        if (x && x.success) {
          form.resetFields();
          props.form.setFieldsValue({
            name: "",
            address: "",
            stateName: "",
            countryName: "",
            cityName: "",
            designation: "",
            pincode: "",
            joiningDate: "",
            email: "",
            mobile: "",
            dateOfBirth: "",
          });
          setEducation([eduStateInit]);
          setExperience([workStateInit]);
        }

        // }
      } else {
        notification.warning({
          message: `Please Enter All Required Fields`,
        });
        return;
      }
    });
  };

  useEffect(() => {
    loadPartnerListFxn();
    handleState();
  }, []);

  const handleState = async () => {
    return new Promise(async (resolve) => {
      let data = await dispatch(
          UserListFxn({
            //   userType: "branchUser",
            regExFilters: ["name"],
          })
      );
      resolve(data.data);
      setEmployeeList(data.data);
      console.log(data);
    });
  };

  const loadPartnerListFxn = async () => {
    let x = await partnerListFxn();
    setPartnerList(x.data);
  };

  let studentInfo = {
    fields: [
      {
        key: "name",
        label: "Name",
        required: false,
        showStar: true,
        placeholder: "Name",
        type: "text",
      },
      {
        key: "dateOfBirth",
        label: "Date of Birth",
        required: false,

        placeholder: "Date of Birth",
        type: "date",
        onChange: (x) => {
          props.form.setFieldsValue({
            dateOfBirth: x,
          });
        },
      },

      {
        key: "gender",
        label: "Gender",
        required: false,
        showStar: true,
        placeholder: "Gender",
        type: "select",
        keyAccessor: (x) => x.option,
        valueAccessor: (x) => `${x.option}`,
        options: genderOption,
        onChange: (x) => {
          props.form.setFieldsValue({
            gender: x,
          });
        },
      },
      {
        key: "maritalStatus",
        label: "Marital Status",
        required: false,
        showStar: true,
        placeholder: "Marital Status",
        type: "select",
        keyAccessor: (x) => x.option,
        valueAccessor: (x) => `${x.option}`,
        options: maritalStatusOption,
        onChange: (x) => {
          props.form.setFieldsValue({
            maritalStatus: x,
          });
        },
      },
      {
        key: "address",
        label: "Address",
        required: false,
        showStar: true,
        placeholder: "Address",
        type: "text",
      },
      {
        key: "countryName",
        label: "Country",
        required: false,
        showStar: true,
        placeholder: "Country Name",
        type: "select",
        showSearch: true,
        options: Countries,
        keyAccessor: (x) => x.name,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            countryName: x,
          });
        },
      },
      {
        key: "stateName",
        label: "State",
        required: false,
        showStar: true,
        placeholder: "Enter state",
        type: "text",
      },
      {
        key: "cityName",
        label: "City",
        required: false,
        showStar: true,
        placeholder: "Enter City",
        type: "text",
      },
      {
        key: "pincode",
        label: "Pincode",
        required: false,
        placeholder: "Pincode",
        type: "text",
      },
      {
        key: "countryCode",
        label: "Country Code",
        required: false,
        showStar: true,
        placeholder: "Country Code",
        type: "select",
        options: Flags,
        showSearch: true,
        keyAccessor: (x) => x.countryCode,
        valueAccessor: (x) => `${x.name} (${x.dialCode})`,
        onChange: (x) => {
          props.form.setFieldsValue({
            countryCode: x,
          });
        },
      },

      {
        key: "mobile",
        label: "Mobile Number",
        required: false,
        showStar: true,
        placeholder: "Mobile Number",
        type: "text",
      },
      {
        key: "email",
        label: "Email",
        required: false,
        placeholder: "Email",
        type: "text",
      },
      {
        key: "designation",
        label: "Designation",
        required: false,
        showStar: true,
        placeholder: "Designation",
        type: "text",
      },

      {
        key: "joiningDate",
        label: "Joining Date",
        required: false,
        showStar: true,
        placeholder: "Joining Date",
        type: "date",
        onChange: (x) => {
          props.form.setFieldsValue({
            joiningDate: x,
          });
        },
      },
    ],
    userField: [
      {
        key: "assignUser",
        label: "Assign Users",
        placeholder: "Please Choose Users",
        type: "select",
        required: true,
        options: EmployeeList,
        multiple: true,
        keyAccessor: (x) => x._id,
        valueAccessor: (x) => `${x.name} - ${x.userType} `,
        onChange: (x) => {
          props.form.setFieldsValue({
            assignUser: x,
          });
        },
      },
    ],
  };

  return (
      <>
        <Form onSubmit={handleSubmit}>
          <Card title={"Employee Basic Information"}>
            <div className={"row"}>
              {studentInfo.fields.map((item, key) => {
                return (
                    <div
                        className={`pt-3 ${item.span ? item.span : "col-md-4"}`}
                        key={key}
                    >
                      <GetEachFormFields {...props.form} item={item}/>
                    </div>
                );
              })}
              {studentInfo.userField.map((item, key) => {
                return (
                    <div className={`pt-3 col-md-4`} key={key}>
                      <GetEachFormFields {...props.form} item={item}/>
                    </div>
                );
              })}
            </div>
          </Card>

          <Card
              title={"Education"}
              bordered
              extraBtn={[
                {
                  name: "Add Multiple Education",
                  action: AllData,
                },
              ]}
          >
            {education.map((data, index) => {
              return (
                  <InnerCard key={index}>
                    <EducationComponent
                        data={data}
                        index={index}
                        education={education}
                        eduEvents={eduEvents}
                        deleteItem={deleteItem}
                    />
                  </InnerCard>
              );
            })}
          </Card>

          <Card
              title={"Work Experience"}
              extraBtn={[
                {
                  name: "Add Work Experience",
                  action: events.addMore,
                },
              ]}
          >
            {experience.map((data, index) => {
              return (
                  <InnerCard key={index}>
                    <WorkExperienceComp
                        data={data}
                        index={index}
                        experience={experience}
                        updateState={events.updateState}
                        deleteItem={events.deleteItem}
                        handleFileChange={events.handleFileChange}
                    />
                  </InnerCard>
              );
            })}
            <Button
                type={"primary"}
                htmlType="submit"
                className={"mt-4 btn main-btn"}
            >
              submit
            </Button>
          </Card>
        </Form>
      </>
  );
};

export default Form.create()(AddNewEmployee);
