import React, {Suspense, useEffect} from "react";
import {Outlet, Navigate} from "react-router-dom";
import TopNavComponent from "./topMenu";
import SideNavComponent from "./sideMenu";
import MyProfile from "../../containers/Dashboard/components/myProfile";
// import FooterComponent from "./footer";
const FooterComponent = React.lazy(() => import('./footer'))

const isLoggedIn = localStorage.getItem("token") ? true : false;

const InnerLayout = (props) => {
    return isLoggedIn ? (
        <>
            <div className="dashboard position-relative d-flex flex-column p-0">
                {/*<div id="spinner"
                     className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
                    <div className="spinner-border text-primary" style={{width: "3rem", height: "3rem"}} role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>*/}
                <SideNavComponent currentUser={props.currentUser}/>
                <div className="content">
                    <TopNavComponent currentUser={props.currentUser}/>
                    <div className="container-fluid pt-4 px-4">
                        <Outlet/>
                    </div>
                    <Suspense fallback={<></>}>
                        <FooterComponent/>
                    </Suspense>
                </div>

                <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top"><i
                    className="bi bi-arrow-up"></i></a>

            </div>
           
        </>

    ) : (
        <Navigate replace to="/login"/>
    );
};
export default InnerLayout;
