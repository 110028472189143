import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {
    Button,
    Card,
    DefaultTablePagination,
    Drawer,
    Form,
    Table,
    notification,
} from "../../components/Elements/appUtils";
import {GetEachFormFields} from "../../components/_utils/formUtils";
import {AddBankAcountFxn, BankAcountListFxn} from "./action";

let {TableCard} = Card
const boardTypeList = [
    {text: "Board", value: "Board"},
    {text: "Stream", value: "Stream"},
];
const AddBankAcount = (props) => {
    const {getFieldDecorator, getFieldValue} = props.form;
    const {onClose, visible} = props;

    let dispatch = useDispatch();
    let tableRef = useRef();

    const handleSubmit = (e) => {
        const {form} = props;
        e.preventDefault();
        form.validateFields(async (err, valData) => {
            if (!err) {
                let x = await dispatch(AddBankAcountFxn(valData));
                if (!x.error) {
                    tableRef.current.reload();
                    props.form.setFieldsValue({
                        bankName: "",
                        accountNo: "",
                        ifscCode: "",
                        address: "",
                    });
                }
            } else {
                if (!valData.bankName) {
                    notification.warning({message: "Please add Bank Name."});
                    return;
                }
                if (!valData.accountNo) {
                    notification.warning({message: "Please add Account No."});
                    return;
                }
                if (!valData.ifscCode) {
                    notification.warning({message: "Please add Ifsc Code."});
                    return;
                }
                if (!valData.address) {
                    notification.warning({message: "Please add Address."});
                    return;
                }
            }
        });
    };
    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            let obj = {
                sortField: "_id",
                sortOrder: "descend",
                ...params,
            };
            let data = await dispatch(
                BankAcountListFxn({
                    ...obj,
                    regExFilters: ["type", "name", "shortName"],
                })
            );
            resolve(data);
        });
    };
    // Define inputTypes for the form
    const inputTypes = {
        fields: [
            {
                key: "bankName",
                label: "Bank Name",
                required: true,
                placeholder: "Bank Name",
                type: "text",
            },
            {
                key: "accountNo",
                label: "Account Number",
                required: true,
                placeholder: "Account Number",
                type: "text",
            },
            {
                key: "ifscCode",
                label: "IFSC Code",
                required: true,
                placeholder: "IFSC Code",
                type: "text",
            },
            {
                key: "address",
                label: "Address",
                required: true,
                placeholder: "Address",
                type: "text",
            },
        ],
    };

    // Define columns for the table
    const columns = [
        {
            title: "Bank Name",
            dataIndex: "bankName",
            key: "bankName",
        },
        {
            title: "Acount Number",
            dataIndex: "accountNo",
            key: "accountNo",
        },
        {
            title: "IFSC Code",
            dataIndex: "ifscCode",
            key: "ifscCode",
        },
        {
            title: "Address",
            dataIndex: "address",
            key: "address",
        },
        {
            title: "Action",
            dataIndex: "action",
            render: (val, record) => {
                return <></>;
            },
        },
    ];

    return (
        <Drawer
            visible={visible}
            title={"Add Bank Account"}
            onClose={onClose}
            width={"50%"}>
            <>
                <Form onSubmit={handleSubmit}>
                    <div className={"row"}>
                        {inputTypes.fields.map((item, key) => {
                            return (
                                <div className={"col-md-6 pt-3"} key={key}>
                                    <GetEachFormFields {...props.form} item={item}/>
                                </div>
                            );
                        })}
                    </div>

                    <Button type={"primary"} htmlType="submit" className={"mt-4"}>
                        submit
                    </Button>
                </Form>
            </>
            <div className={'mt-4'}>

                <TableCard title={"Bank Account List"}>
                    <Table
                        apiRequest={apiRequest}
                        columns={columns}
                        pagination={DefaultTablePagination()}
                        ref={tableRef}
                    />
                </TableCard>
            </div>

        </Drawer>
    );
};

export default Form.create()(AddBankAcount);
