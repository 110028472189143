import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {
    Button,
    Card,
    Drawer,
    Form,
    HyperLink,
    Popconfirm,
    Tooltip,
    displayDate,
} from "../../../components/Elements/appUtils";
import {getAvatar, statusOption} from "../../../components/_utils/_utils";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import {branchListFxn} from "../../Counsellor/action";
import {AddCloseFxn, AddDeleteFxn, LeadListFxn} from "../actions/index";
import AddFollowup from "../drawers/AddFollowup";
import EditFollowup from "../drawers/EditFollowup";
import TransferDrawer from "../drawers/TransferDrawer";
import CloseLead from "../drawers/closeLead";
import EditStudentTest from "../drawers/EditStudentTest";
import FollowUpList from "../drawers/followUpList";

const SingleLead = (props) => {
    let {item, events} = props;

    // console.log("item", item)

    let [showFollowUp, setShowFollowUp] = useState(false);
    return (
        <>
            <div className={"lead_card"} key={item._id}>
                <div className={"row"}>
                    <div className={"col-md-5"}>
                        <div className={"flex1"}>
                            <div className={"avatar_lead"}>{getAvatar(item.name)}</div>
                            <div className="basic-info">
                                <div className={"name"}>
                                    <Tooltip title={"Edit Lead"}>
                                        <a onClick={() => events.showEditLead(item._id)}>
                                            {" "}
                                            {item.name}
                                            {/* <span style={{ fontSize: 'smaller' }}>({item.englishRequirement.examType})</span> */}

                                        </a>
                                    </Tooltip>
                                </div>
                                <div className={"lead_info"}>
                                    <div className={"single_box"}>
                                        <i className=" fa fa-phone-alt"> </i>
                                        {item.mobileNo}
                                    </div>
                                    <div className={"single_box"}>
                                        <i className="fa fa-birthday-cake"> </i>
                                        {displayDate(item.dateOfBirth)}
                                    </div>
                                </div>
                                {item && item.email ? (
                                    <div className={"lead_info"}>
                                        <div className={"single_box"}>
                                            <i className=" fa fa-envelope"> </i>
                                            {item.email}
                                        </div>
                                        {/*
                                    <div className={'single_box'}>
                                        <i className=' fa fa-user'> </i>
                                        {item.gender}
                                    </div>*/}
                                    </div>
                                ) : null}
                                <div className={"lead_info"}>
                                    <div className={"single_box"}>
                                        <i className=" fa fa-map-marker"> </i>
                                        {item.address}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"col-md-3"}>
                        <div className={"lead_label"}>Added On</div>
                        <div className="basic-info">
                            {/* <div className={"lead_info"}>
                <div className={"single_box"}>
                  <i className=" fa fa-user"> </i>
                  {item.assignTo && item.assignTo.name
                    ? item.assignTo.name
                    : ""}
                </div>
              </div> */}
                            <div className={"lead_info"}>
                                <div className={"single_box"}>
                                    <i className="fa fa-calendar"> </i>
                                    {displayDate(item.createdAt)}
                                </div>
                            </div>
                            {item.interestedCountry && item.interestedCountry.countryName ? (
                                <div className={"lead_info"}>
                                    <div className={"single_box"}>
                                        {item.interestedCountry.flag &&
                                        item.interestedCountry.flag.path ? (
                                            <img
                                                src={item.interestedCountry.flag.path}
                                                style={{height: 15}}
                                            />
                                        ) : null}
                                        &nbsp;
                                        {item.interestedCountry.countryName}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    {/* <div className={"col-md-2"}>
            <div className={"lead_label"}>Follow Up</div>
            {item.latestFollowUpId && item.latestFollowUpId.subject ? (
              <div>
                <Tooltip title={item.latestFollowUpId.notes}>
                  <a
                    onClick={() =>
                      events.showEditFollowUp(item.latestFollowUpId._id)
                    }
                  >
                    {item.latestFollowUpId.subject}
                  </a>
                </Tooltip>

              </div>
            ) : null}

            <div className={"rowFlex mt-2"}>
              <a
                className={"btn btn-link btn-xs mr-1"}
                onClick={() => events.showFollowUp(item._id)}
              >
                <i className="fa fa-plus"></i> Add
              </a>
              <a
                className={"btn btn-link btn-xs"}
                onClick={() => events.showFollowUpList(item._id)}
              >
                <i className="fa fa-eye"></i> View
              </a>
            </div>
          </div> */}
                    <div className={"col-md-2 align_center"}>
                        <div className={"lead_label"}>Actions</div>
                        <div className={"btn_group"}>
                            {item.status == "Pending" ? (
                                <>
                                    <a onClick={() => events.showTransfer(item._id)}>
                                        <i className="fa fa-exchange-alt"></i>
                                    </a>

                                    <Popconfirm
                                        title="Are you sure to delete this lead?"
                                        onConfirm={() => {
                                            events.handleDelete(item._id);
                                        }}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <a>
                                            <i className="fa fa-trash"></i>
                                        </a>
                                    </Popconfirm>

                                    <a onClick={() => events.showClose(item._id)}>
                                        <i className="fa fa-times"> </i>
                                    </a>
                                    <a href={`/add-payment-details?_id=${item._id}`}>
                                        <i className="fa fa-eye"></i>
                                    </a>
                                    <a href={`/edit-student-test?_id=${item._id}`}>
                                        <i className="fa fa-edit"></i>
                                    </a>
                                </>
                            ) : null}

                            {item.status == "Closed" ? (
                                <>
                                    <label className={"label label-success label-sm"}>
                                        Closed
                                    </label>
                                </>
                            ) : null}
                            {item.status == "Deleted" ? (
                                <>
                                    <label className={"label label-danger label-sm"}>
                                        Deleted
                                    </label>
                                </>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const StudentListTest = (props) => {
    let dispatch = useDispatch();
    const {getFieldDecorator} = props.form;
    const [open, setOpen] = useState(false);
    const [closeLeadState, setCloseLeadState] = useState({
        visible: false,
        leadId: "",
    });
    const [transferClose, setTransferClose] = useState(false);
    const [statusFilter, setStatusFilter] = useState("");
    const [branchFilter, setBranchFilter] = useState("");
    const [visibleFollowUps, setVisibleFollowUps] = useState(false);
    const [leadData, setLeadData] = useState([]);
    const [assignId, setAssignId] = useState("");
    const [leadId, setLeadId] = useState("");
    const [branchList, setBranchList] = useState([]);

    let [editState, setEditState] = useState({
        leadId: "",
        visible: false,
    });
    let [editFollowState, setEditFollowState] = useState({
        followUpId: "",
        visible: false,
    });

    let user = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : null;
    const events = {
        showFollowUp: (id) => {
            setOpen(true);
            setLeadId(id);
        },
        hideFollowUp: () => {
            setOpen(false);
            setLeadId("");
            apiRequest();
        },
        showTransfer: (id) => {
            setTransferClose(true);
            setAssignId(id);
            setLeadId(id);
        },
        onTransferClose: () => {
            setTransferClose(false);
        },
        handleDelete: (id) => {
            const {form} = props;
            form.validateFields(async (err, valData) => {
                if (!err) {
                    valData.leadId = id;
                    let x = await dispatch(AddDeleteFxn(valData));
                }
            });
        },
        showClose: (id) => {
            setCloseLeadState({
                leadId: id,
                visible: true,
            });
        },
        hideClose: () => {
            setCloseLeadState({
                leadId: "",
                visible: false,
            });
            apiRequest();
        },
        showFollowUpList: (id) => {
            setVisibleFollowUps(true);
            setLeadId(id);
        },
        hideFollowUpList: (id) => {
            setVisibleFollowUps(false);
            setLeadId(id);
        },
        showEditLead: (id) => {
            setEditState({
                leadId: id,
                visible: true,
            });
        },
        hideEditLead: () => {
            setEditState({
                leadId: "",
                visible: false,
            });
        },
        hideEditLeadAndUpdate: () => {
            setEditState({
                leadId: "",
                visible: false,
            });
            apiRequest();
        },
        showEditFollowUp: (id) => {
            setEditFollowState({
                followUpId: id,
                visible: true,
            });
        },
        hideEditFollowUp: () => {
            setEditFollowState({
                followUpId: "",
                visible: false,
            });
        },
    };

    const onTransferClose = () => {
        setTransferClose(false);
    };

    const apiRequest = async () => {
        let params = {
            results: 100,
            count: 100,
        };
        if (branchFilter) {
            params.addedBy = branchFilter;
        }
        if (statusFilter) {
            params.status = statusFilter;
        } else {
            params.status = "Pending";
        }
        let data = await dispatch(LeadListFxn({...params}));
        setLeadData(data.data);
    };
    useEffect(() => {
        loadBranches();
    }, []);
    useEffect(() => {
        apiRequest();
    }, [statusFilter, branchFilter]);

    const loadBranches = async () => {
        let data = await dispatch(
            branchListFxn({
                userType: ["branch", "headBranch"],
            })
        );
        setBranchList(data.data);
    };
    let filterTypes = {
        fields: [
            {
                key: "status",
                label: "Status",
                required: true,
                placeholder: "status",
                type: "select",
                keyAccessor: (x) => x.option,
                valueAccessor: (x) => `${x.option}`,
                options: statusOption,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        status: x,
                    });

                    setStatusFilter(x);
                },
                span: "col-md-4",
            },
            {
                key: "branch",
                label: "Branch",
                required: true,
                placeholder: "Branch",
                type: "select",
                options: branchList,
                hidden: !(user.userType == "headBranch"),
                keyAccessor: (x) => x._id,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        branch: x,
                    });
                    setBranchFilter(x);
                },
            },
        ],
    };

    return (
        <>
            <Card
                title={"Student Test List"}
                rightContent={
                    <HyperLink type={"primary"} sm={true} link={"/add-student-test"}>
                        Add Student Test
                    </HyperLink>
                }
            >
                <Form onSubmit="">
                    <div className={"row"}>
                        {filterTypes.fields.map((item, key) => {
                            return (
                                <div className="col-md-2" key={key}>
                                    <GetEachFormFields {...props.form} item={item}/>
                                </div>
                            );
                        })}
                    </div>
                </Form>
                <div className="mt-4"></div>

                <div>
                    {leadData && leadData.length
                        ? leadData.map((item) => {
                            return (
                                <SingleLead item={item} events={events} key={item._id}/>
                            );
                        })
                        : null}
                </div>
            </Card>

            {open && leadId ? (
                <AddFollowup
                    onClose={events.hideFollowUp}
                    onSubmit={() => {
                        events.hideFollowUp();
                        apiRequest();
                    }}
                    visible={open}
                    leadId={leadId}
                />
            ) : null}
            {transferClose ? (
                <TransferDrawer
                    onClose={onTransferClose}
                    visible={transferClose}
                    userId={assignId}
                    leadId={leadId}
                />
            ) : null}
            {leadId && visibleFollowUps ? (
                <FollowUpList
                    onClose={events.hideFollowUpList}
                    visible={visibleFollowUps}
                    leadId={leadId}
                />
            ) : (
                ""
            )}

            {closeLeadState.visible ? (
                <CloseLead
                    onClose={events.hideClose}
                    visible={closeLeadState.visible}
                    leadId={closeLeadState.leadId}
                />
            ) : null}

            {editState.visible ? (
                <EditStudentTest
                    onClose={events.hideEditLead}
                    onSubmit={events.hideEditLeadAndUpdate}
                    visible={editState.visible}
                    leadId={editState.leadId}
                />
            ) : (
                ""
            )}

            {editFollowState.visible ? (
                <EditFollowup
                    onClose={events.hideEditFollowUp}
                    onSubmit={() => {
                        events.hideEditFollowUp();
                        apiRequest();
                    }}
                    visible={editFollowState.visible}
                    followUpId={editFollowState.followUpId}
                />
            ) : null}
        </>
    );
};

export default Form.create()(StudentListTest);
