import React from 'react'
import {useRef} from 'react'
import {Table, Card, HyperLink, TableButton, DefaultTablePagination} from '../../../components/Elements/appUtils';
import {cityCategoryList} from './action';
import {useDispatch} from 'react-redux';

const {TableCard} = Card
const CityList = () => {
    let tableRef = useRef()
    let dispatch = useDispatch()

    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            let data = await dispatch(cityCategoryList({
                ...params,
                regExFilters: ['country', 'stateName', 'cityName']
            }))
            resolve(data)
        })
    }

    const columns = [
        {
            title: "Sr. No.",
            dataIndex: "index",
            key: "index",
            render: (val, record, index) => {
                return index + 1;
            },
        },
        {
            title: 'Country',
            dataIndex: 'country',
            key: 'country',
            render: (item, record) => {
                return item.countryName
            }
        },
        {
            title: 'State',
            dataIndex: 'state',
            key: 'state',
            render: (item, record) => {
                return item.stateName
            }
        },
        {
            title: 'City',
            dataIndex: 'cityName',
            key: 'cityName',
            searchTextName: 'cityName',
            filterRegex: true,
        },
        {
            title: 'Action',
            dataIndex: 'action',
            render: (val, record) => {
                return (
                    <>
                        <TableButton type={'edit'} path={`/master/city-edit?_id=${record._id}`}/>
                    </>
                )
            }
        },
    ];

    return (
        <>
            <TableCard title={"City List"} rightContent={(
                <HyperLink type={'primary'} sm={true} link={'/master/add-city'}>Add City</HyperLink>
            )}>
                <Table apiRequest={apiRequest}
                       pagination={DefaultTablePagination()}

                       columns={columns} ref={tableRef}/>
            </TableCard>
        </>
    )
}

export default CityList
