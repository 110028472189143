import React from "react";
import { InputBox } from "../../../components/Elements/appUtils";

const StatusComponent = (props) => {
  let { data, index, updateState, deleteItem } = props;
  return (
    <>
      <div className={"col-md-6"} name="status" key={index}>
        <div className={"new-border"}>
          <InputBox title={`${index + 1}. Status`} className={"rowFlex"}>
            <input
                required={true}
                placeholder="Please enter Status"
                className={"form-control"}
                disabled={true}
                onChange={({target}) => {
                    updateState({status: target.value, index: index + 1}, index);
                }}
                value={data.status}
            />
          {/*  {index !== 0 ? (
              <a>
                <i
                  className="fa fa-trash new-icon"
                  aria-hidden="true"
                  onClick={() => deleteItem(index + 1)}
                ></i>
              </a>
            ) : null}*/}
          </InputBox>
        </div>
      </div>
    </>
  );
};

export default StatusComponent;
