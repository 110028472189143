import {hideLoader, showLoader} from "../../../../actions/loader";
import notification from "../../../../components/Elements/Notification";
import Request, {
    customAxios as axios,
    getToken,
} from "../../../../request.js";
import {
    addAgentUrl,
    agentListUrl,
    approvedTrialManualUrl,
    companyByIdUrl,
    companyListUrl,
    deleteCompanyUrl,
    getSingleCompanyUrl,
    sendOptToDeleteCompanyUrl,
    updateCompanyUrl,
} from "../api";

export const AddAgentFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(addAgentUrl(), valData, getToken());
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};

export const agentListFxn = async (valData) => {
    let config = {
        params: {...valData},
        ...(await getToken()),
    };
    let {data} = await axios.get(agentListUrl(), config);
    return data;
};

export const companyListFxn = async (valData) => {
    let config = {
        params: {...valData},
        ...(await getToken()),
    };
    let {data} = await axios.get(companyListUrl(), config);
    return data;
};

export const approvedTrialManualFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(
        approvedTrialManualUrl(),
        valData,
        getToken()
    );
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};

export const GetRelatedCompanyFxn = (id) => async (dispatch) => {
    dispatch(showLoader());
    let config = {
        ...(await getToken()),
    };
    let {data} = await axios.get(getSingleCompanyUrl(id), config);
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    }

    return data;
};

export const updateCompanyFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {companyId} = valData;
    let {data} = await axios.post(updateCompanyUrl(companyId), valData, getToken());
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};

export const companyByIdFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(companyByIdUrl(), valData, getToken());
    dispatch(hideLoader());
    return data;
};
export const sendOptToDeleteCompanyFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(
        sendOptToDeleteCompanyUrl(),
        valData,
        getToken()
    );
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};
export const deleteCompanyFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(deleteCompanyUrl(), valData, getToken());
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};
