import {hideLoader, showLoader} from "../../actions/loader";
import notification from "../../components/Elements/Notification";
import {customAxios as axios, getToken} from "../../request";
import {
    addTrainingRequestUrl,
    trainingRequestListUrl,
    updateTrainingRequestUrl,
} from "./api";

export const addTrainingRequest = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(addTrainingRequestUrl(), valData, getToken());
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};
export const updateTrainingRequestFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(
        updateTrainingRequestUrl(),
        valData,
        getToken()
    );
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};

export const trainingRequestListFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let config = {
        params: {...valData},
        ...(await getToken()),
    };
    let {data} = await axios.get(trainingRequestListUrl(), config);
    dispatch(hideLoader());
    return data.data;
};
