import { apiUrl } from "../../settings";

export const addUserUrl = () => {
  return `${apiUrl}/user`;
};

export const userListUrl = () => {
  return `${apiUrl}/user`;
};


export const branchListUrl = () => {
  return `${apiUrl}/user`;
};
