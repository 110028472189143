import React from "react";
import { InputBox } from "../../../components/Elements/appUtils";

const WorkExperienceComp = (props) => {
  let { data, index, updateState, deleteItem, handleFileChange } = props;
  return (
    <>
      <div className={index > 0 ? "repeat-sec" : ""}>
        <div className="row">
          <div className="col-lg-4 col-12">
            <InputBox title={"Company Name"}>
              <input
                  required={true}
                  placeholder="Enter Company"
                  className={"form-control"}
                  onChange={({target}) => {
                    updateState({companyName: target.value}, index);
                  }}
                  value={data.companyName}
              />
            </InputBox>
          </div>
          <div className="col-lg-4 col-12">
            <InputBox title={"Position"}>
              <input
                  required={true}
                  placeholder="Enter position"
                  className={"form-control"}
                  onChange={({target}) => {
                    updateState({position: target.value}, index);
                  }}
                  value={data.position}
              />
            </InputBox>
          </div>
          <div className="col-lg-4 col-12">
            <InputBox title={"Time Period"}>
              <input
                  required={true}
                  placeholder="Enter time"
                  className={"form-control"}
                  onChange={({target}) => {
                    updateState({time: target.value}, index);
                  }}
                  value={data.time}
              />
            </InputBox>
          </div>
        </div>

        {index !== 0 ? (
            <a className="btn delete" onClick={() => deleteItem(index)}><i
                className="fa fa-times-circle"></i>
            </a>
        ) : null}
      </div>

    </>
  );
};

export default WorkExperienceComp;
