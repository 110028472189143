import _ from "lodash";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {
    Card,
    Drawer,
    Tooltip,
    displayDate,
    longDisplayDate,
} from "../../components/Elements/appUtils";
import {getAvatar} from "../../components/_utils/_utils";
import {applicationNoteFxn} from "../Student/actions";
import {ApplicationStatusFxn, CountryStatusFxn} from "../Users/action";
import AddComment from "./addComment";

const SingleStatus = (props) => {
    let {data, index, events} = props;
    const [noteId, setNoteId] = useState("");
    const [commentOpen, setCommentOpen] = useState(false);
    const showComment = (id) => {
        setNoteId(id);
        setCommentOpen(true);
    };

    const onCloseComment = () => {
        setCommentOpen(false);
    };

    return (
        <>
            <div className="box" key={data._id}>
                <div className="head d-flex align-items-center">
                    <h5>
                        {moment()
                            .month(data._id.month - 1)
                            .format("MMMM")}
                        , {data._id.year}
                    </h5>
                    {/*  {index == 0 ?
                        <button className="btn" type="button" >+ create
                            note</button> : null}*/}
                </div>

                {data && data.items && data.items.length
                    ? data.items.map((item, key) => {
                        return (
                            <div className="inner card inner-card-with-border" key={key}>
                                <img
                                    src="../images/icons/editing.png"
                                    alt=""
                                    className="edit"
                                />
                                <div className={"noteTitle"}>
                                    <h5>note</h5>
                                    <div style={{textAlign: "right"}}>
                      <span className={"date"}>
                        {displayDate(item.createdAt)}
                          <br/>
                      </span>
                                    </div>
                                </div>
                                <p>
                                    {/* {item.assignedUserId && item.assignedUserId.name ? (
                      <strong>{item.assignedUserId.name} : </strong>
                    ) : null} */}
                                    <div className={"display-linebreak"}>{item.note}</div>
                                </p>
                                <div className={"paddingHz"}>
                                    {item.attachment && item.attachment.url ? (
                                        <Tooltip title={item.attachment.name}>
                                            <a
                                                download={item.attachment.name}
                                                href={item.attachment.url}
                                            >
                                                <label>{item.attachment.name}</label>
                                            </a>
                                        </Tooltip>
                                    ) : null}
                                </div>
                                <div className={"paddingHz"}></div>
                                <span>
                    <img src="../images/icons/user.png" alt=""/>{" "}
                                    {item.createdByUser.name} left a note.
                  </span>

                                <div>
                                    {item.comments && item.comments.length
                                        ? item.comments.map((eacCom, key1) => {
                                            return (
                                                <React.Fragment key={key}>
                                                    <div className={"commentBox"}>
                                                        <div className={"row"}>
                                                            <div className={"col-md-2"}>
                                                                <div className={"avatar"}>
                                                                    <i className={"fa fa-user"}></i>
                                                                </div>
                                                            </div>
                                                            <div className={"col-md-10"}>
                                                                <div className={"noteTitle"}>
                                                                    <div>
                                                                        {eacCom.addedByUserId &&
                                                                        eacCom.addedByUserId.name
                                                                            ? eacCom.addedByUserId.name
                                                                            : null}
                                                                    </div>
                                                                    <span className={"date"}>
                                        <i className={"fa fa-calender"}></i>
                                                                        {displayDate(eacCom.time)}
                                      </span>
                                                                </div>
                                                                <div className={"commentText"}>
                                                                    {eacCom.text}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            );
                                        })
                                        : null}
                                </div>
                                <br/>
                                <button onClick={() => showComment(item._id)} type="button">
                                    + add comment
                                </button>
                            </div>
                        );
                    })
                    : null}
            </div>
            <AddComment
                visible={commentOpen}
                onClose={onCloseComment}
                noteId={noteId}
            />
        </>
    );
};
const StatusList = (props) => {
    let dispatch = useDispatch();
    let {visible, studentId, applicationId, onClose, onSubmit} = props;
    let {countryId} = applicationId;
    let [statusList, setStatusList] = useState([]);
    let [oldStatusList, setOldStatusList] = useState([]);
    useEffect(() => {
        LoadStatusList();
    }, [applicationId._id]);

    useEffect(() => {

        loadApplicationStatus();

    }, [oldStatusList]);
    let loadApplicationStatus = async () => {
        let {data, success} = await dispatch(
            ApplicationStatusFxn({select: "statusList", _id: applicationId._id})
        );
        console.log(data, "dddddddddddddddddd");

        if (success) {
            let statusList = _.clone(oldStatusList);
            if (data.statusList) {
                _.each(statusList, (countryStatus) => {
                    let findStatus = _.find(data.statusList, (applicationStatus) => {
                        return countryStatus.status == applicationStatus.status;
                    });
                    if (findStatus) {
                        countryStatus.date = findStatus.date;
                        countryStatus.assignTo = findStatus.assignTo;
                    }
                });
                console.log(statusList, "sssssssssssss");
                setStatusList(statusList);
            }
        }
    };
  let LoadStatusList = async () => {
    let data = await dispatch(
        CountryStatusFxn({
            select: "statusList",
            _id: countryId._id,
                // statuss: "active",
            })
        );
        if (data[0].statusList) {
            setOldStatusList(data && data[0].statusList ? data[0].statusList : []);
        }
    };
    return (
        <Drawer visible={visible} onClose={onClose} width={"40%"}>
            <h2>Application Status</h2>
            {statusList && statusList.length
                ? statusList.map((item, key) => {
                    return (
                        <>
                            <div className="form-group mb-2 status_row">
                                <div className="radio-check-box">
                                    <div
                                        className={`form-control ${applicationId.status == item.status ? "active" : ""}`}>
                                        <div className={'flex-box'}>
                                            <label>
                                                {item.status}
                                            </label>
                                            {item && item.date ? (
                                                <span className={'date'}>{displayDate(item.date)}</span>
                                            ) : null}
                                        </div>
                                        <div className={'flex-box'}>
                                            <label>
                                                {item && item.assignTo && item.assignTo.name
                                                    ? <small>({item.assignTo.name})</small>
                                                    : null}
                                            </label>
                                            {item && item.date ? (
                                                <img src="../app/img/check-green.svg" alt=""/>) : null}
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </>
                    );
                })
                : null}{" "}
        </Drawer>
    );
};
export default StatusList;
