import React, {useEffect, useState} from "react";
import {
    Bar,
    BarChart,
    CartesianGrid,
    Legend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import {englishTestRevenueFxn, studentCountryWiseFxn} from "../actions";

const $ = window.$;
let filterList = ["today", "last 7 days", "last 30 days", "all"];
const EnglishTestRevenue = () => {
    let [studentData, setStudentData] = useState([]);

    let [state, setState] = useState({
        dateFilter: "all",
    });
    let _updateState = (data) => {
        setState((prevState) => {
            return {
                ...prevState,
                ...data,
            };
        });
        console.log(state, "ffffffffffff");
    };

    useEffect(() => {
        loadData();
    }, []);
    let loadData = async (dateFilter) => {
        let {data} = await englishTestRevenueFxn(
            {},
            dateFilter ? dateFilter : state.dateFilter
        );
        console.log(data);
        setStudentData(data);
    };
    const CustomTooltip = ({active, payload, label}) => {
        if (active) {
            const finalPayload = payload && payload.length ? payload[0].payload : {};
            // console.log(finalPayload, "dfianalaaaaaaaaaaaaaaa");
            const interestedEnglishTest = finalPayload.interestedEnglishTest || "N/A";
            const total = finalPayload.total || 0;
            const visa = finalPayload.visa || 0;

            return (
                <div className="custom-tooltip">
                    <p className="label">
                        <strong>{interestedEnglishTest}</strong>
                    </p>
                    <p className="count">{`Total Amount : ${total}`}</p>
                    {/* <p className="count">{`Paid : ${visa}`}</p> */}
                </div>
            );
        }

        return null;
    };

    const getWidth = () => {
        if ($("#barChart")) {
            setTimeout(() => {
                return $("#barChart").width();
            }, 500);
        }
    };
    const getChartData = () => {
        const chartData = studentData.map((item) => ({
            interestedEnglishTest: item._id,
            total: item.installments.reduce((acc, installment) => {
                return installment.status === "Paid" ? acc + installment.amount : acc;
            }, 0),
            visa: item.installments.filter(
                (installment) => installment.status === "Paid"
            ).length,
        }));

        return chartData;
    };
    return (
        <>
            {/* <div className="col-sm-12 col-xl-7">
                <div className="card-box text-center p-4 border-dark">
                    <div className="d-flex align-items-center justify-content-between mb-4 gap-3 flex-wrap">
                        <h6 className="mb-0">English Test Revenue</h6>
                        <ul className="position-relative">
                            <li><input type="radio" checked name="radio"/><label>today</label></li>
                            <li><input type="radio" name="radio"/><label>last 7 days</label></li>
                            <li><input type="radio" checked name="radio"/><label>last 30 days</label></li>
                            <li><input type="radio" name="radio" data-bs-toggle="collapse" href="#collapseExample"
                                       role="button" aria-expanded="false" aria-controls="collapseExample"/><label>date
                                wise</label></li>
                            <div className="collapse calendarDiv" id="collapseExample">
                                calendar
                            </div>
                        </ul>
                    </div>
                    <canvas id="TotalSOP"></canvas>
                </div>
            </div>*/}

            <div className="col-sm-12 col-xl-7">
                <div className="card-box text-center p-4  border-dark">
                    <div className="d-flex align-items-center justify-content-between mb-4 gap-3 flex-wrap">
                        <h6 className="mb-0">English Test Revenue</h6>
                        <ul key={state.dateFilter}>
                            {filterList.map((item) => {
                                return (
                                    <li key={item}>
                                        <input
                                            type="radio"
                                            onChange={({target}) => {
                                                _updateState({dateFilter: target.value});
                                                loadData(item);
                                            }}
                                            checked={state.dateFilter == item}
                                            name="radio"
                                            value={item}
                                        />
                                        <label>{item}</label>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    <div className={"barChart"}>
                        {studentData && studentData.length > 0 ? (
                            <ResponsiveContainer width={getWidth()} height={350}>
                                <BarChart width={500} height={300} data={getChartData()}>
                                    <CartesianGrid strokeDasharray="1 1"/>
                                    <XAxis dataKey="interestedEnglishTest"/>
                                    <YAxis/>
                                    <Tooltip content={<CustomTooltip/>}/>
                                    <Legend layout="horizontal"/>

                                    <Bar dataKey="total" fill="#2B72F0" barSize={5}/>
                                    <Bar dataKey="visa" fill="#9EBF65" barSize={5}/>
                                </BarChart>
                            </ResponsiveContainer>
                        ) : (
                            <div>No revenue data available</div>
                        )}{" "}
                    </div>

                    {/* <div className={"barChart"}>
            {studentData && studentData.length > 0 ? (
              <ResponsiveContainer width={getWidth()} height={350}>
                <BarChart width={500} height={300} data={getChartData()}>
                  <CartesianGrid strokeDasharray="1 1" />
                  <XAxis dataKey="_id" />
                  <YAxis />
                  <Tooltip content={<CustomTooltip />} />
                  <Legend layout="horizontal" />

                  <Bar dataKey="total" fill="#2B72F0" barSize={5} />
                  <Bar dataKey="visa" fill="#9EBF65" barSize={5} />
                </BarChart>
              </ResponsiveContainer>
            ) : (
              <h3>No revenue data available</h3>
            )}
          </div> */}
                </div>
            </div>
        </>
    );
};
export default EnglishTestRevenue;
