import { apiUrl } from "../../settings";

export const userUrl = () => {
  return `${apiUrl}/user`;
};
export const addEmployeeUrl = () => {
  return `${apiUrl}/addEmployee`;
};
export const boardStreamListUrl = () => {
  return `${apiUrl}/boardStreamList`;
};
export const getSingleEmployeeUrl = (id) => {
  return apiUrl + "/employeeprofile/" + id;
};
export const getSingleUrl = (id) => {
  return apiUrl + '/getSingle/' + id
};
export const updateSingleUrl = () => {
  return apiUrl + '/updateEmp'
};
export const addLeaveUrl = () => {
  return `${apiUrl}/applyLeave`;
};
export const AllLeaveUrl = (userId) => {
  return apiUrl + "/AllLeaves/" + userId;
};
export const postUpdateLeaveUrl = () => {
  return `${apiUrl}/editLeave`;
};

export const addDocumentsEmpUrl = () => {
  return apiUrl + "/addDocumentsToEmp";
};
export const getSingleEmpDocumentsUrl = (userId) => {
  return apiUrl + "/getSingleEmpDocuments/" + userId;
};
export const deleteSingleEmpDocumentUrl = (id) => {
  return apiUrl + "/deleteSingleEmpDocument/" + id;
}
export const deleteEmpDocumentUrl = () => {
  return apiUrl + "/deleteEmpDocument"
}
