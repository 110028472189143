import { hideLoader, showLoader } from "../../actions/loader";
import notification from "../../components/Elements/Notification";
import { customAxios as axios, getToken } from "../../request";
import {
  GenerateSOP,
  applicationListUrl,
  reGenerateSopUrl,
  sopListUrl,
  studentListUrl,
  templateSOPUrl, reGenerateSopDocUrl,
} from "./api";

export const GenerateSOPFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  let { data } = await axios.post(GenerateSOP(), valData, getToken());
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  } else {
    notification.success({
      message: data.message || "Success",
    });
  }
  return data;
};

export const templateSopFxn = async (valData) => {
  // dispatch(showLoader())
  let { data } = await axios.post(templateSOPUrl(), valData, getToken());
  // dispatch(hideLoader())
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  } else {
    notification.success({
      message: data.message || "Success",
    });
  }
  return data;
};
export const studentListFxn = async (valData) => {
  let config = {
    params: { ...valData },
    ...(await getToken()),
  };
  let { data } = await axios.get(studentListUrl(), config);
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  }
  return data.data;
};
export const sopListFxn = async (valData) => {
  let config = {
    params: { ...valData },
    ...(await getToken()),
  };
  let { data } = await axios.get(sopListUrl(), config);
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  }
  return data.data;
};

export const applicationListFxn = async (valData) => {
  let config = {
    params: { ...valData },
    ...(await getToken()),
  };
  let { data } = await axios.get(applicationListUrl(), config);
  return data;
};

export const reGenerateSopFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  let { data } = await axios.post(reGenerateSopUrl(), valData, getToken());
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  } else {
    notification.success({
      message: data.message || "Success",
    });
  }
  return data;
};
export const reGenerateSopDocFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  let {data} = await axios.post(reGenerateSopDocUrl(), valData, getToken());
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  } else {
    notification.success({
      message: data.message || "Success",
    });
  }
  return data;
};
