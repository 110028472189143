import {hideLoader, showLoader} from "../../actions/loader";
import notification from "../../components/Elements/Notification";
import {customAxios as axios, getToken} from "../../request";
import {
    AddBankAcountUrl,
    AddGicCommissionUrl,
    AddTransactionUrl,
    BankAcountListUrl,
    UpdateGicCommissionUrl,
    UpdateGicDocumentsUrl,
    addWithdrawalUrl,
    gicCommissionCountUrl,
    gicCommissionListMonthWiseUrl,
    gicCommissionListUrl,
    sendWithdrawalOtpUrl,
    transactionListUrl,
    updateWithdrawalUrl,
} from "./api";

export const gicCommissionListFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let config = {
        params: {...valData},
        ...(await getToken()),
    };
    let {data} = await axios.get(gicCommissionListUrl(), config);
    dispatch(hideLoader());
    return data;
};

export const AddBankAcountFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(AddBankAcountUrl(), valData, getToken());
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};
export const BankAcountListFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let config = {
        params: {...valData},
        ...(await getToken()),
    };
    let {data} = await axios.get(BankAcountListUrl(), config);
    dispatch(hideLoader());
    return data;
};

export const AddGicCommissionFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(AddGicCommissionUrl(), valData, getToken());
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};
export const AddTransactionFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(AddTransactionUrl(), valData, getToken());
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};
export const UpdateGicCommissionFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(
        UpdateGicCommissionUrl(),
        valData,
        getToken()
    );
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};
export const transactionListFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let config = {
        params: {...valData},
        ...(await getToken()),
    };
    let {data} = await axios.get(transactionListUrl(), config);
    dispatch(hideLoader());
    return data;
};
export const gicCommissionCountFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let config = {
        params: {...valData},
        ...(await getToken()),
    };
    let {data} = await axios.get(gicCommissionCountUrl(), config);
    dispatch(hideLoader());
    return data;
};
export const gicCommissionListMonthWiseFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let config = {
        params: {...valData},
        ...(await getToken()),
    };
    let {data} = await axios.get(gicCommissionListMonthWiseUrl(), config);
    dispatch(hideLoader());
    return data;
};

export const addWithdrawalFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(addWithdrawalUrl(), valData, getToken());
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};

export const sendWithdrawalOtpFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(sendWithdrawalOtpUrl(), valData, getToken());
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};

export const updateWithdrawalFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(updateWithdrawalUrl(), valData, getToken());
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};
export const UpdateGicDocumentsFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(UpdateGicDocumentsUrl(), valData, getToken());
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};
