import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {
    Button,
    Card,
    Drawer,
    Form,
} from "../../../components/Elements/appUtils";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import {loadCountryList} from "../../Masters/state/action";
import {companyCountryList} from "../../University/action";
import {AddTransferToAnotherFxn, getRelatedCounsellorFxn} from "../actions/index";

const TransferDrawer = (props) => {
    console.log(props, "ffjfijfjj");
    let {visible, onClose, userId, leadId} = props;
    const [countryList, setCountryList] = useState([]);
    const [counsellorList, setCounsellorList] = useState([]);
    const {getFieldDecorator} = props.form;
    let dispatch = useDispatch();

    const handleSubmit = (e) => {
        const {form} = props;
        e.preventDefault();
        form.validateFields(async (err, valData) => {
            if (!err) {
                valData.leadId = leadId;
                let x = await dispatch(AddTransferToAnotherFxn(valData));
                console.log(x);
            }
        });
    };

    const loadCountryFxn = async () => {
        let x = await companyCountryList();
        setCountryList(x.data);
    };

    const getRelatedCounsellor = async (countryId) => {
        let data = await getRelatedCounsellorFxn({countryId, userId});
        setCounsellorList(data);
    };

    useEffect(() => {
        loadCountryFxn();
    }, []);

    let inputTypes = {
        fields: [
            {
                key: "countryId",
                label: "Country",
                required: true,
                placeholder: "Country",
                type: "select",
                options: countryList,
                keyAccessor: (x) => x._id,
                valueAccessor: (x) => `${x.countryName}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        countryId: x,
                        counsellorId: undefined,
                    });
                    getRelatedCounsellor(x);
                },
                span: 8,
            },

            {
                key: "counsellorId",
                label: "Counsellor",
                required: true,
                placeholder: "Counsellor",
                type: "select",
                options: counsellorList,
                keyAccessor: (x) => x._id,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        counsellorId: x,
                    });
                },
                span: 8,
            },
        ],
    };

    return (
        <Drawer
            placement="right"
            onClose={onClose}
            visible={visible}
            width="40%"
            title={"Transfer To Another Counsellor"}
        >
            <Card>
                <Form onSubmit={handleSubmit}>
                    <div className={"row"}>
                        {inputTypes.fields.map((item, key) => {
                            return (
                                <div
                                    className={`pt-3 ${
                                        item.span == 6 ? "col-md-6" : "col-md-12"
                                    }`}
                                    key={key}
                                >
                                    <GetEachFormFields {...props.form} item={item}/>
                                </div>
                            );
                        })}
                    </div>

                    <div style={{marginTop: "20px"}}>
                        <Button type={"primary"} htmlType="submit">
                            submit
                        </Button>
                    </div>
                </Form>
            </Card>
        </Drawer>
    );
};

export default Form.create()(TransferDrawer);
