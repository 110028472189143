import { hideLoader, showLoader } from "../../actions/loader";
import notification from "../../components/Elements/Notification";
import { customAxios as axios, getToken } from "../../request";
import { addLeadFieldsUrl } from "./api";

export const addLeadFieldsFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  let { data } = await axios.post(addLeadFieldsUrl(), valData, getToken());
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  } else {
    notification.success({
      message: data.message,
    });
  }
  return data;
};
