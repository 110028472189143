import React from "react";
import {
    Form,
} from "../../../components/Elements/appUtils";
import EditCompanyProfile from "./companyProfile";
import EditUserProfile from "./userProfile";

const EditMyProfile = (props) => {
    let {currentUser} = props
    return (
        <>
            {(currentUser.userType == "headBranch" || currentUser.userType == "branch") ?
                <EditCompanyProfile {...props}/> : <EditUserProfile {...props}/>}
        </>
    );
};

export default Form.create()(EditMyProfile);
