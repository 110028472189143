import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, notification } from "../../../components/Elements/appUtils";
import { useDispatch } from "react-redux";
import { resetPasswordFxn } from "../actions";
import Layout from "../layout";

const ResetPassword = () => {
    let dispatch = useDispatch();
    const navigate = useNavigate();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [token, setToken] = useState("");

    
      useEffect(() => {
        let searchParams = new URLSearchParams(window.location.search);
        let tokenFromQuery = searchParams.get("token");


        if(tokenFromQuery){
            setToken(tokenFromQuery)
        }else{
            return <div>Error: Token is missing. Please provide a valid reset password link.</div>;

        }
  
    }, []);

   
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === "password") {
            setPassword(value);
        } else if (name === "confirmPassword") {
            setConfirmPassword(value);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const email = localStorage.getItem('email');

        if (password.length < 6) {
            notification.warning({
                message: "Password should be at least 6 characters long.",
            });
            return;
        }
        if (!password || !confirmPassword) {
            notification.warning({
                message: "Please enter both password and confirm password.",
            });
            return;
        }

        if (password !== confirmPassword) {
            notification.error({
                message: "Password and confirm password do not match.",
            });
            return;
        }

        let x = await dispatch(resetPasswordFxn({
            email,
            token,
            newPassword: password,
        }));

        if (x && x.success) {
            localStorage.removeItem('email');
            navigate('/login');
        } else {
            notification.error({
                message: "Failed to reset password.",
            });
        }
    };

    return (
        <Layout formName={'reset-password'}>
            <div className="content-box">
                <img src="../app/img/logo.svg" className="logo" alt="logo here"/>

                <div className="head">
                    <h4>Reset Password</h4>
                    <p>Enter your new password.</p>
                </div>

                <div className="field">
                    <Form onSubmit={handleSubmit}>
                        <div className="form-floating mb-4">
                            <input
                                type="password"
                                className="form-control"
                                name="password"
                                value={password}
                                onChange={handleInputChange}
                                id="floatingPassword"
                                placeholder="Password"
                            />
                            <label htmlFor="floatingPassword">New Password</label>
                        </div>
                        <div className="form-floating mb-5">
                            <input
                                type="password"
                                className="form-control"
                                name="confirmPassword"
                                value={confirmPassword}
                                onChange={handleInputChange}
                                id="floatingConfirmPassword"
                                placeholder="Confirm Password"
                            />
                            <label htmlFor="floatingConfirmPassword">Confirm Password</label>
                        </div>
                        <Button className="btn black-btn mt-3" htmlType="submit">Reset Password</Button>
                    </Form>
                </div>
            </div>
        </Layout>
    );
};

export default ResetPassword;
