import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import ReactSwitch from "react-switch";
import {
    Button,
    Card,
    InputBox,
    Modal,
    notification,
} from "../../../components/Elements/appUtils";
import {
    addStatusToCountryFxn,
    countryStatusUpdateToggleInCountryFxn,
    singleCountryStatusListFxn,
    updateStatusInCountryFxn,
} from "../action";

let {TableCard} = Card;
const StatusList = (props) => {
    let {visible, countryId, onClose} = props;

    const [initialActiveStates, setInitialActiveStates] = useState([]);
    const [checkedStates, setCheckedStates] = useState([]);
    let dispatch = useDispatch();
    useEffect(() => {
        getStatusList(countryId);
    }, [countryId]);

    const getStatusList = async (id) => {
        if (id) {
            try {
                const {data} = await singleCountryStatusListFxn({countryId: id});
                setStatusList(data);

                const initialActive = data.map((item) => item.statusList.active);
                setInitialActiveStates(initialActive);
                setCheckedStates(initialActive);
            } catch (error) {
                console.error("Error fetching status list:", error);
            }
        }
    };
    const [statusList, setStatusList] = useState([]);

    const [edititngStatusId, setEditingStatusId] = useState(null);
    const [editedValue, setEditedValue] = useState("");

    const [showAddCampusInput, setShowAddStatusInput] = useState(false);
    const [newStatusValue, setNewStatusValue] = useState("");

    const handleAddStatus = () => {
        setShowAddStatusInput(true);
    };

    const handleSubmit = async () => {
        let valData = {
            countryId: countryId,
            // countryId: countryId,
            status: newStatusValue,
        };
        let x = await dispatch(addStatusToCountryFxn(valData));
        if (!x.error) {
            getStatusList(countryId);
            setShowAddStatusInput(false);
            setNewStatusValue("");
        }
    };
    const handleEdit = (id, campus) => {
        setEditingStatusId(id);
        setEditedValue(campus);
    };
    const handleCancel = () => {
        setEditingStatusId(null);
        setEditedValue("");
    };

    const handleSave = async (id, index) => {
        if (!editedValue) {
            notification.warning({
                message: "Status Can not be Empty",
            });
            return;
        }
        let valData = {
            campusId: id,
            newStatus: editedValue,
            index: index,
            countryId: countryId,
        };
        let x = await dispatch(updateStatusInCountryFxn(valData));
        if (!x.error) {
            getStatusList(countryId);
            setEditingStatusId(null);
            setEditedValue("");
        }
    };

    const onToggleChange = async (index) => {
        const statusIdToUpdate = statusList[index].statusList._id;
        const newActiveStatus = !checkedStates[index];
        let obj = {
            countryId: countryId,
            statusId: statusIdToUpdate,
            newActiveStatus: newActiveStatus,
        };
        let {success} = await dispatch(
            countryStatusUpdateToggleInCountryFxn(obj)
        );
        if (success) {
            const updatedCheckedStates = [...checkedStates];
            updatedCheckedStates[index] = newActiveStatus;
            setCheckedStates(updatedCheckedStates);
        }
    };

    return (
        <Modal
            visible={visible}
            onClose={onClose}
            width={"40%"}
            title={
                <>
                    <div className="d-flex justify-content-between">Status List</div>
                </>
            }
            placement="right"
        >
            <TableCard padding={false}>
                <table className={"table data-table"}>
                    <thead>
                    <tr>
                        <th>Serial No</th>
                        <th>Status Name</th>
                        {/*<th>Action</th>*/}
                    </tr>
                    </thead>
                    <tbody>
                    {statusList.map((item, index) => {
                        let {statusList} = item;
                        return (
                            <tr key={item._id}>
                                <td>{index + 1}</td>
                                <td style={{width: "60%"}}>
                                    {edititngStatusId === statusList._id ? (
                                        <>
                                            <div className={"row"}>
                                                <div className={"col-md-6"}>
                                                    <InputBox>
                                                        <input
                                                            type="text"
                                                            className={"form-control sm"}
                                                            value={editedValue}
                                                            onChange={(e) => setEditedValue(e.target.value)}
                                                        />
                                                    </InputBox>
                                                </div>
                                                <div className={"col-md-6"}>
                                                    <a
                                                        className={"btn btn-success btn-sm"}
                                                        onClick={() => handleSave(statusList._id, index)}
                                                    >
                                                        Save
                                                    </a>
                                                    <a
                                                        className={"btn btn-danger btn-sm ml10"}
                                                        onClick={handleCancel}
                                                    >
                                                        Cancel
                                                    </a>
                                                </div>
                                            </div>
                                        </>
                                    ) : statusList && statusList.status ? (
                                        statusList.status
                                    ) : null}
                                </td>
                                {/*<td></td>*/}
                                {/* <td>

                    {edititngStatusId !== statusList._id ? (
                      <a
                        className={"btn btn-sm btn-default"}
                        onClick={() =>
                          handleEdit(
                            statusList._id,
                            statusList && statusList.status
                              ? statusList.status
                              : null
                          )
                        }
                      >
                        Edit
                      </a>
                    ) : null}
                   <div style={{float:"right"}}>
                     <ReactSwitch
                         checked={checkedStates[index]}
                         onChange={() => onToggleChange(index)}


                     />
                   </div>
                  </td> */}
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
                {showAddCampusInput && (
                    <>
                        <Card title={"Add Status"}>
                            <div className={"row"}>
                                <div className={"col-md-4"}>
                                    <InputBox title={"Status Name"}>
                                        <input
                                            labelCls={"col-md-3"}
                                            placeholder="Status Name"
                                            className={"form-control"}
                                            value={newStatusValue}
                                            onChange={(e) => setNewStatusValue(e.target.value)}
                                        />
                                    </InputBox>
                                </div>
                                <div className={"col-md-4"}>
                                    <div className={"mt-4-1"}>
                                        <button
                                            className={"btn btn-success"}
                                            onClick={handleSubmit}
                                        >
                                            Save
                                        </button>
                                        <button
                                            className={"btn btn-danger ml10"}
                                            onClick={() => setShowAddStatusInput(false)}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </>
                )}
                {/* {!showAddCampusInput && (
          <div style={{ textAlign: "right" }}>
            <Button className={"btn btn-sm"} onClick={handleAddStatus}>
              Add More Status
            </Button>
          </div>
        )} */}
            </TableCard>
        </Modal>
    );
};

export default StatusList;
