import React, {useRef} from "react";
import {useDispatch} from "react-redux";
import {
    Button,
    Card,
    DefaultTablePagination,
    Form,
    Popconfirm,
    Table,
} from "../../components/Elements/appUtils";
import {imgPath} from "../../components/_utils/_utils";
import {GetEachFormFields} from "../../components/_utils/formUtils";
import {AddPartnerFxn, deletePartnerFxn, partnerListFxn} from "./action";

let {TableCard} = Card;
const AddPartner = (props) => {
    let {showHeader = true} = props;
    let tableRef = useRef();
    let dispatch = useDispatch();
    const finalSubmit = (e) => {
        const {form} = props;
        e.preventDefault();
        e.stopPropagation();

        form.validateFields(async (err, valData) => {
            if (!err) {
                let x = await dispatch(AddPartnerFxn(valData));
                if (!x.error) {
                    tableRef.current.reload();
                    props.form.setFieldsValue({
                        partnerName: "",
                    });
                }
            }
        });
    };
    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            let obj = {
                sortField: "_id",
                sortOrder: "descend",
                ...params,
            };
            let data = await dispatch(
                partnerListFxn({
                    ...obj,
                    regExFilters: ["partnerName"],
                })
            );
            resolve(data);
        });
    };

    let events = {
        deletePartner: async (id) => {
            let resp = await dispatch(deletePartnerFxn({partnerId: id}));
            if (resp && resp.success) {
                tableRef.current.reload();
            }
        },
    };

    const columns = [
        {
            title: "#",
            dataIndex: "index",
            key: "index",
            render: (val, record, index) => {
                return index + 1;
            },
        },

        {
            title: "Name",
            dataIndex: "partnerName",
            key: "partnerName",
            searchTextName: "partnerName",
            filterRegex: true,
        },

        {
            title: "Action",
            dataIndex: "action",
            render: (val, record) => {
                return (
                    <>
                        <Popconfirm
                            title={"Are you sure, you want to delete this partner?"}
                            onConfirm={() => {
                                events.deletePartner(record._id);
                            }}
                        >
                            <button className={"btn"}>
                                <img src={imgPath.close}/>
                            </button>
                        </Popconfirm>
                    </>
                );
            },
        },
    ];

    let inputTypes = {
        fields: [
            {
                key: "partnerName",
                label: "Partner Name",
                required: true,
                placeholder: "Enter Partner Name",
                type: "text",
            },
        ],
    };

    let addPartnerForm = (
        <Form onSubmit={finalSubmit}>
            <div className={"row"}>
                {inputTypes.fields.map((item, key) => {
                    return (
                        <div className={"col-md-6 pt-3"} key={key}>
                            <GetEachFormFields {...props.form} item={item}/>
                        </div>
                    );
                })}
            </div>

            <Button type={"primary"} htmlType="submit" className="btn main-btn mt-4">
                submit
            </Button>
        </Form>
    );

    return (
        <>
            <Card title={showHeader ? "Add Partner" : ""}>{addPartnerForm}</Card>
            <TableCard title={"Partners List"}>
                <Table
                    apiRequest={apiRequest}
                    columns={columns}
                    pagination={DefaultTablePagination()}
                    ref={tableRef}
                />
            </TableCard>
        </>
    );
};

export default Form.create()(AddPartner);
