const initialState = {
    currentUser: {},
    isLoading: false,
    studentTestIds: [],
    activePackages: [],
    loadExamData: false
}

export default (state = initialState, action) => {
    switch (action && action.type) {
        case 'SET_CURRENT_USER':
            return {
                ...state,
                currentUser: action.user
            }
        case 'SET_STUDENT_TEST_IDS':
            return {
                ...state,
                studentTestIds: action.data
            }
        case 'LOGOUT':
            return {
                ...state,
                currentUser: {},
                activePackages: []
            }
        case 'SHOW_LOADER': {
            return {
                ...state,
                isLoading: true
            }
        }
        case 'HIDE_LOADER': {
            return {
                ...state,
                isLoading: false
            }
        }
        case 'PROGRESS_LOADER_SHOW': {
            return {
                ...state,
                isProLoader: true
            }
        }
        case 'PROGRESS_LOADER_HIDE': {
            return {
                ...state,
                isProLoader: false
            }
        }
        case 'PROGRESS_LOADER': {
            return {
                ...state,
                percentage: action.percentage
            }
        }
        case 'CLEAR_OPTION': {
            return {
                ...state,
                clearOption: action.clearOption
            }
        }
        case 'CLEAR_QUESTION_VALUE': {
            return {
                ...state,
                clearValue: action.clearValue
            }
        }
        case 'SET_ACTIVE_PACKAGES':
            return {
                ...state,
                activePackages: action.data
            }
        case 'REFRESH_PURCHASE_EXAMS_START': {
            return {
                ...state,
                loadExamData: true
            }
        }
        case 'REFRESH_PURCHASE_EXAMS_STOP':
            return {
                ...state,
                loadExamData: false
            }
        default:
            return state
    }
}
