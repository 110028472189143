import {apiUrl} from "../../settings";

export const counsellorUrl = () => {
    return `${apiUrl}/user`;
};

export const addLeadUrl = () => {
    return `${apiUrl}/addLead`;
};

export const addLeadCopyUrl = () => {
    return `${apiUrl}/addLeads`;
};

export const LeadListUrl = () => {
    return `${apiUrl}/leadList`;
};

export const addFollowUrl = () => {
    return `${apiUrl}/addFollowUp`;
};
export const addFollowListUrl = () => {
    return `${apiUrl}/followUpList`;
};

export const addCloseUrl = () => {
    return `${apiUrl}/closeLead`;
};

export const addDeleteUrl = () => {
    return `${apiUrl}/deleteLead`;
};

export const getRelatedCounsellorUrl = () => {
    return `${apiUrl}/getRelatedCounsellors`;
};

export const addTransferToAnotherUrl = () => {
    return `${apiUrl}/transferToAnotherCounsellor`;
};

export const getLeadUrl = (leadId) => {
    return apiUrl + "/getSingleLead/" + leadId;
};

export const updateLeadUrl = () => {
    return apiUrl + "/updateLead";
};

export const singleFollowUpUrl = (id) => {
    return apiUrl + `/singleFollowUp/${id}`;
};

export const updateFollowupUrl = () => {
    return apiUrl + `/updateFollowup`;
};

export const getLeadFieldListUrl = () => {
    return apiUrl + `/leadFieldsList`;
};

export const OpenLeadUrl = () => {
    return apiUrl + `/openLead`;
};
