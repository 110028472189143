import {hideLoader, showLoader} from "../../../actions/loader";
import {loginUrl, otpUrl, otpVerifyUrl, otpResendUrl, packageListUrl, registerCompanyUrl, forgetpasswordUrl, resetPasswordUrl} from "../apis";
import {customAxios as axios} from "../../../request";
import {authAxios} from "../../../request";
import {notification} from "../../../components/Elements/appUtils";

export const loginFxn = (data) => async (dispatch) => {
    return new Promise((next, error) => {
        dispatch(showLoader())
        authAxios
            .post("/login", data)
            .then((d) => {
                dispatch(hideLoader())
                next(d.data);
            })
            .catch((err) => {
                dispatch(hideLoader())
                next({error: true, err});
                this.error(err);
            });
    });
}


export const postLoginFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(loginUrl(), valData);
    dispatch(hideLoader())
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message
        });
    }
    return data;
};


export const otpFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(otpUrl(), valData);
    dispatch(hideLoader())
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message
        });
    }
    return data;
};


export const otpResendFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(otpResendUrl(), valData);
    dispatch(hideLoader())
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message
        });
    }
    return data;
};


export const packageListFxn = async () => {
    let {data} = await axios.post(packageListUrl());
    return data
}

export const registerCompanyFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(registerCompanyUrl(), valData);
    dispatch(hideLoader())
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message
        });
    }
    return data
}

export const forgetPasswordFxn = (valData) => async (dispatch) => {
    let {data} = await axios.post(forgetpasswordUrl(), valData);
    dispatch(hideLoader())
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message
        });
    }
    return data
}

export const resetPasswordFxn = (valData) => async (dispatch) => {
    let {data} = await axios.post(resetPasswordUrl(), valData);
    dispatch(hideLoader())
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message
        });
    }
    return data
}