import _ from "lodash";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {
    Button,
    Card,
    Form,
    notification,
} from "../../../components/Elements/appUtils";
import {CurrencyJson} from "../../../components/_utils/appUtils";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import {
    AddCountryFxn,
} from "../../Country/action";
import StatusComponent from "../../Country/components/statusComponent";
import {conditionOptions, defaultStatusList} from "../../../components/_utils/_utils";

const statusInIt = {status: ""};
const AddCountry = (props) => {
    const {setFieldsValue} = props.form;
    let dispatch = useDispatch();
    let [flag, setFlag] = useState({});
    const [status, setStatus] = useState(defaultStatusList);

    useEffect(() => {
        setFieldsValue({autoEnable: false})
    }, [])

    const getCurrencySymbol = (name) => {
        let symbol = _.find(CurrencyJson, (item) => {
            return item.name == name;
        });
        if (symbol) {
            setFieldsValue({currencySymbol: symbol.value});
        }
    };
    const events = {
        addMore: () => {
            setStatus([...status, statusInIt]);
        },
        deleteItem: (itemId) => {
            const updatedItems = status.filter((item, key) => key !== itemId);
            setStatus(updatedItems);
        },
        updateState: (data, index) => {
            let cloneStatus = _.clone(status);
            cloneStatus[index] = {...cloneStatus[index], ...data};
            setStatus(cloneStatus);
        },
    };


    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFlag(file);
    };

    let inputTypes = {
        fields: [

            {
                key: "countryName",
                label: "Country Name",
                allowClear: true,

                required: true,
                placeholder: "Country Name",
                type: "text",
            },
            {
                key: "interview",
                label: "Interview",
                required: true,
                placeholder: "Interview",
                type: "select",
                options: [{name: "Yes"}, {name: "No"}, {name: "Maybe"}],
                keyAccessor: (x) => x.name,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        interview: x,
                    });
                },
            },

            {
                key: "currency",
                label: "Currency",
                span: "col-md-3",
                type: "select",
                required: true,
                options: CurrencyJson,
                showSearch: true,
                keyAccessor: (x) => x.name,
                valueAccessor: (x) => x.name,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        currency: x,
                    });
                    getCurrencySymbol(x);
                },
            },
            {
                key: "currencySymbol",
                label: "Currency Symbol",
                span: "col-md-3",
                required: true,
                disabled: true,
                placeholder: "Currency Symbol",
                type: "text",
            },
            {
                key: "monthlyLivingCost",
                label: "Monthly Living Cost",
                type: "number",
                placeholder: "Enter Monthly Living Cost",
            },

            {
                key: "visaRequirements",
                label: "Visa Requirement",
                placeholder: "Enter Visa Requirement",
                type: "text",
            },
            /* {
               key: "countryBenefits",
               label: "Country Benefits",
               placeholder: "Country Benefits",
               type: "text",
             },*/
            {
                key: "documents",
                label: "Documents",
                required: true,
                placeholder: "Documents",
                type: "text",
            },
            {
                key: "autoEnable",
                label: "Auto Enable",
                span: "col-md-3",
                type: "select",
                required: true,
                options: conditionOptions,
                showSearch: true,
                keyAccessor: (x) => x.value,
                valueAccessor: (x) => x.display,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        autoEnable: x,
                    });
                },
            },
            {
                key: "flag",
                label: "Flag",
                type: "file",
                onChange: handleFileChange,
            },
        ],
    };


    const handleSubmit = async (e) => {
        const {form} = props;
        e.preventDefault();
        form.validateFields(async (err, valData) => {
            if (!err) {
                valData.statusList = status;
                valData.statusList = _.reject(valData.statusList, (item) => {
                    return !item.status;
                });
                let fd = new FormData();
                fd.append("obj", JSON.stringify(valData));
                if (flag && flag.name) {
                    fd.append("flag", flag);
                }

                let x = await dispatch(AddCountryFxn(fd));
                props.form.setFieldsValue({
                    countryName: "",
                    interview: "",
                    currency: "",
                    documents: "",
                    flag: "",
                    autoEnable: false,
                    monthlyLivingCost: "",
                    visaRequirements: ""
                });
                setFlag("");
            } else {
                notification.warning({
                    message: "Fill All Required Fields",
                });
            }
        });
    };

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Card title={"Basic Information"}>
                    <div className={"row"}>
                        {inputTypes.fields.map((item, key) => {
                            return !item.hidden ? (
                                <div
                                    className={`pt-3 ${item.span ? item.span : "col-md-6"}`}
                                    key={key}
                                >
                                    <GetEachFormFields {...props.form} item={item}/>
                                </div>
                            ) : null;
                        })}
                    </div>
                </Card>
                <Card title={"Country Status"}>
                    <div className={"row"}>
                        {status.map((data, index) => {
                            return (
                                <StatusComponent
                                    data={data}
                                    key={index}
                                    index={index}
                                    status={status}
                                    updateState={events.updateState}
                                    deleteItem={events.deleteItem}
                                />
                            );
                        })}
                    </div>
                    {/*  <a className={"btn btn-link floatRight"} onClick={events.addMore}>
                        Add More Status
                    </a>*/}

                    <div style={{marginTop: "20px"}}>
                        <Button type={"primary"} htmlType="submit">
                            submit
                        </Button>
                    </div>
                </Card>

            </Form>
        </>
    );
};

export default Form.create()(AddCountry);
