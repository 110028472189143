import {apiUrl} from "../../../settings";

export const loginUrl = () => {
    return `${apiUrl}/login`;
};
export const otpUrl = () => {
    return `${apiUrl}/send-otp`;
};
export const otpResendUrl = () => {
    return `${apiUrl}/resend-otp`;
};

export const packageListUrl = () => {
    return `${apiUrl}/api/package-list`;
};
export const registerCompanyUrl = () => {
    return `${apiUrl}/api/registerCompany`;
};
export const forgetpasswordUrl = () => {
    return `${apiUrl}/forgot-password`;
}
export const resetPasswordUrl = () => {
    return `${apiUrl}/reset-password`;
}

