import React, {useEffect, useState} from "react";
import {Button, Card, Form} from "../../../components/Elements/appUtils";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import {GetRelatedStateFxn, loadCountryList} from "./action";
import {postRelatedStateFxn} from './action';
import {useDispatch} from "react-redux";

const EditState = (props) => {
    const {getFieldDecorator} = props.form;
    const [countryList, setCountryList] = useState([]);
    const [stateId, setStateId] = useState('')
    let dispatch = useDispatch()


    const loadCountry = async () => {
        let x = await loadCountryList();
        setCountryList(x)
    }

    const getCampusData = async () => {
        let searchParams = new URLSearchParams(window.location.search);
        let id = searchParams.get("_id");

        if (id) {
            setStateId(id);
            getRelatedState(id);
        }
    };

    const getRelatedState = async (id) => {
        let {data} = await dispatch(GetRelatedStateFxn(id));
        setTimeout(() => {
            props.form.setFieldsValue({
                country: data.country._id,
                stateName: data.stateName
            });
        }, 500)
    }
    useEffect(() => {
        loadCountry()
        getCampusData()
    }, [])

    const handleSubmit=(e)=>{
      const {form} = props;
      e.preventDefault();
      form.validateFields(async (err, valData) => {
          console.log(err, valData);
          if (!err) {
              valData.stateId=stateId
              let x = await dispatch(postRelatedStateFxn(valData));
              console.log(x);
          }
      });
  }

  let inputTypes = {
    fields: [
      {
        key: "country",
        label: "Country",
        required: true,
        placeholder: "Country Name",
        type: "select",
        options: countryList,
        keyAccessor: (x) => x._id,
        valueAccessor: (x) => `${x.countryName}`,
        onChange: (x) => {
          console.log(x);
          props.form.setFieldsValue({
            country: x,
          });
        },
        span: 8,
      },

      {
        key: "stateName",
        label: "State",
        required: true,
        placeholder: "State Name",
        type: "text",
      },
    ],
  };
  return (
    <>
      <Card title={"Edit States"}>
        <Form onSubmit={handleSubmit}>
          <div className={"row"}>
            {inputTypes.fields.map((item, key) => {
              return (
                <div className={"col-md-6 pt-3"} key={key}>
                  <GetEachFormFields
                      {...props.form}
                      item={item}
                  />
                </div>
              );
            })}
          </div>

          <div style={{ marginTop: "20px" }}>
            <Button type={"primary"} htmlType="submit">
              Update
            </Button>
          </div>
        </Form>
      </Card>
    </>
  );
};

export default Form.create()(EditState);
