import { apiUrl } from "../../settings";

export const getSingleTemplateUrl = (templateId) => {
  return apiUrl + "/getSingleTemplate/" + templateId;
};

export const getTemplatetCategoryListUrl = () => {
  return apiUrl + "/templateCategoryList";
};
export const addTemplateSectionUrl = () => {
  return apiUrl + "/addTemplateSection";
};
export const getSectionListUrl = () => {
  return apiUrl + "/templateSectionList";
};

export const removeTemplateUrl = () => {
  return apiUrl  + "/removeTemplateSection";
};