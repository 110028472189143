import { apiUrl } from "../../../../settings";

export const countryStatusUrl = () => {
  return `${apiUrl}/countryStatusList`;
};
export const ApplicationStatusUrl = () => {
    return `${apiUrl}/getSingleApplication`;
};
export const getUserCountryStatusWiseUrl = () => {
    return `${apiUrl}/getUserCountryStatusWise`;
};

export const getSingleUserUrl = (id) => {
  return apiUrl + "/user/" + id;
};
;

export const getSingleEmployeeUrl = (id) => {
  return apiUrl + "/employeeprofile/" + id;
};

export const getUserUrl = (id) => {
  return `${apiUrl}/employeeListFilter`;
};
export const getEmployeeUrl = (id) => {
  return `${apiUrl}/employeeList`;
};

export const postUpdateUserUrl = (id) => {
  return apiUrl + "/user/" + id;
};
export const getCompanyUserGroupedUrl = (id) => {
  return apiUrl + "/getCompanyUserGrouped";
};
export const getUserCountryStatusWiseNewUrl = () => {
    return `${apiUrl}/getUserCountryStatusWiseNew`;
};
export const CountryAllstatusUrl = () => {
    return `${apiUrl}/countryStatus`;
};

export const postUpdateUserEducationUrl = (id) => {
  return apiUrl + "/userEducation/" + id;
};

export const ApplyLeaveUrl = () => {
  return `${apiUrl}/applyLeave`;
};

export const AllLeaveUrl = (id) => {
  return apiUrl + "/AllLeaves/" + id;
};

export const checkInUrl = () => {
  return `${apiUrl}/checkIn`;
};
export const checkOutUrl = () => {
  return `${apiUrl}/checkout`;
};

export const addLeaveUrl = () => {
  return `${apiUrl}/applyLeave`;
};

export const addLeaveByUserUrl = () => {
  return `${apiUrl}/applyLeaveByEmp`;
};
export const SingleUserCheckInUrl = (id) => {
  return apiUrl + "/singleUserCheckIn/" + id;
};


export const addBranchUrl = () => {
    return `${apiUrl}/addBranch`;
};
