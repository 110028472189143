import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Card,
  Checkbox,
  Form,
  notification,
} from "../../../components/Elements/appUtils";
import { Flags } from "../../../components/_utils/appUtils";
import { GetEachFormFields } from "../../../components/_utils/formUtils";
import { loadCountryList } from "../../Masters/state/action";
import {
  CountryStatusFxn,
  GetRelatedUserFxn,
  postUpdateUserFxn,
} from "./action";

const SingleCountryComponent = (props) => {
  let { data, events } = props;
  let { countryId, statusList } = data;
  //   console.log(props, "ppepepepepeep");
  return (
    <div className="card" key={data._id}>
      <div className="card-header-image">
        <div className="new-header">
          {data && data.flag && data.flag.path ? (
            <img src={data.flag.path} style={{ height: 25, marginRight: 10 }} />
          ) : null}
          <span className="text">{data.countryName}</span>
        </div>
        <div className="checkboxes-design">
          <div className="form-check form-check-info font-size-16  p-3">
            <label className="form-check-label">
              <Checkbox
                value={data.selected == true}
                checked={data.selected == true}
                onChange={(value) => {
                  events.updateSingleCountry(data._id, true);
                }}
              />
              <span className={"new-label"}>Select All</span>
            </label>
          </div>
          <div className="form-check form-check-info font-size-16  p-3">
            <label className="form-check-label">
              <Checkbox
                value={data.selected == false}
                checked={data.selected == false}
                onChange={(value) => {
                  events.updateSingleCountry(data._id, false);
                }}
              />
              <span className={"new-label"}>Deselect All</span>
            </label>
          </div>
        </div>
      </div>

      {statusList && statusList.length ? (
        <div className={"row country_choose_box"}>
          {statusList.map((item, index) => {
            return (
              <div className={"col-md-3"} key={item._id}>
                <label className="form-check-label">
                  <Checkbox
                    value={item.value}
                    checked={item.value}
                    name={item.status}
                    onChange={(value) => {
                      events.chooseStatus(value, data._id, index);
                    }}
                  />
                  <span>{item.status}</span>
                </label>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

const AddUserEdit = (props) => {
  const { getFieldDecorator } = props.form;
  const [countryStatus, setCountryStatus] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [checkboxValues, setCheckboxValues] = useState({});
  const [userId, setUserId] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    handleState();
    getCampusData();
  }, []);

  const getCampusData = async () => {
    let searchParams = new URLSearchParams(window.location.search);
    let id = searchParams.get("_id");
    if (id) {
      setUserId(id);
      getRelatedUser(id);
    }
  };

  const getRelatedUser = async (id) => {
    let { data } = await dispatch(GetRelatedUserFxn(id));

    // setCountryStatus(data.countryStatusList);
    handleCountryStatus(data.countryStatusList);

    setTimeout(() => {
      props.form.setFieldsValue({
        name: data.name,
        password: data.displayPassword,
        address: data.address,
        email: data.email,
        countryCode: data.countryISOCode,
        mobile: data.mobile,
      });
      setCountryStatus((prevCountryStatus) => {
        console.log(prevCountryStatus, "dstatusListstatusList");
        return [...prevCountryStatus, ...data.countryStatusList];
      });
    }, 500);
  };

  const handleCountryStatus = async (statusList) => {
    let data = await dispatch(CountryStatusFxn());
    console.log(data, "data");
    console.log(countryStatus, "cccccccccccccc");
    // setCountryStatus((prevCountryStatus) => {
    //   return [...prevCountryStatus, ...data];
    // });
    setCountryStatus(data);
  };

  const handleState = async () => {
    let x = await loadCountryList();
    setCountryList(x.data);
  };

  const events = {
    chooseStatus: (value, rowId, index) => {
      let cloneState = _.clone(countryStatus);
      let findRow = _.find(cloneState, (item) => {
        return item._id == rowId;
      });
      if (findRow) {
        findRow.statusList[index].value = value;
      }
      setCountryStatus(cloneState);
    },
    updateSingleCountry: (rowId, value) => {
      let cloneState = _.clone(countryStatus);
      let findRow = _.find(cloneState, (item) => {
        return item._id == rowId;
      });
      if (findRow) {
        findRow.selected = value;
        _.each(findRow.statusList, (item) => {
          item.value = value;
        });
        setCountryStatus(cloneState);
      } else {
        setCountryStatus(cloneState);
      }
    },
    updateAll: (rowId, value) => {
      let cloneState = _.clone(countryStatus);
      _.find(cloneState, (row) => {
        _.each(row.statusList, (item) => {
          item.value = value;
        });
      });
      setCountryStatus(cloneState);
    },
  };

  const handleSubmit = (e) => {
    const { form } = props;
    e.preventDefault();
    form.validateFields(async (err, valData) => {
      console.log(err, valData, "dddddd");
      if (!err) {
        // valData.cityId = cityId;
        valData.id = userId;
        valData.countryStatusList = countryStatus;
        let x = await dispatch(postUpdateUserFxn(valData));
        console.log(x, "rrrrrrrrrrrr");
      }
    });
  };
  let inputTypes = {
    fields: [
      {
        key: "name",
        label: "Name",
        required: true,
        placeholder: "Name",
        type: "text",
      },
      {
        key: "email",
        label: "Email",
        required: true,
        placeholder: "Email",
        type: "text",
      },
      {
        key: "countryCode",
        label: "Country Code",
        required: true,
        placeholder: "Country Code",
        type: "select",
        options: Flags,
        showSearch: true,
        keyAccessor: (x) => x.countryCode,
        valueAccessor: (x) => `${x.name} (${x.dialCode})`,
        onChange: (x) => {
          props.form.setFieldsValue({
            countryCode: x,
          });
        },
        span: "col-md-3",
      },
      {
        key: "mobile",
        label: "Mobile No",
        required: true,
        placeholder: "Mobile No",
        type: "text",
        span: "col-md-3",
      },
      {
        key: "password",
        label: "Password",
        required: true,
        placeholder: "Password",
        type: "text",
      },
      // {
      //     key: "countryId",
      //     label: "Country",
      //     required: true,
      //     placeholder: "Country",

      //     },

      {
        key: "address",
        label: "Address",
        required: true,
        placeholder: "Address",
        type: "text",
      },
    ],
  };
  return (
    <>
      <Card title={"Basic Details"}>
        <Form onSubmit={handleSubmit}>
          <div className={"row"}>
            {inputTypes.fields.map((item, key) => {
              return (
                <div
                  className={`pt-3 ${item.span ? item.span : "col-md-6"}`}
                  key={key}
                >
                  <GetEachFormFields {...props.form} item={item} />
                </div>
              );
            })}
          </div>

          <div style={{ marginTop: "10px" }}>
            <Button type={"primary"} htmlType="submit">
              submit
            </Button>
          </div>
        </Form>
      </Card>

      <Card title={"Countries Status"} className="mt-3">
        {countryStatus &&
          countryStatus.map((item) => {
            return <SingleCountryComponent events={events} data={item} />;
          })}
      </Card>
    </>
  );
};

export default Form.create()(AddUserEdit);
