import {default as React, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {
    Button,
    Card,
    Form,
    notification,
} from "../../../components/Elements/appUtils";
import {courseCategoryList } from "../../Masters/courseCategory/action";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import {getRelatedUniversityListFxn} from "../../Course/action";
import {loadCountryList} from "../../Masters/state/action";
import {AddTemplateFxn, getRelatedCourseListFxn} from "../actions";

const AddTemplate = (props) => {
    const navigate = useNavigate();
    const {getFieldDecorator} = props.form;
    const [categoryList, setCategoryList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [universityList, setUniversityList] = useState([]);
    const [courseList, setCourseList] = useState([]);
    const [countryId, setCountryData] = useState("");
    const [universityId, setUniversityId] = useState("");
    const [originalDocument, setOriginalDocument] = useState({});
    let dispatch = useDispatch();

    const handleTemplateChange = (e) => {
        const file = e.target.files[0];
        setOriginalDocument(file);
    };

    const handleSubmit = (e) => {
        const {form} = props;
        e.preventDefault();
        form.validateFields(async (err, valData) => {
            if (!err) {
                console.log(valData);
                let fd = new FormData();
                fd.append("obj", JSON.stringify(valData));

                if (originalDocument && originalDocument.name) {
                    fd.append("originalDocument", originalDocument);
                }
                let x = await dispatch(AddTemplateFxn(fd));
                console.log(x);
                if (!x.error) {
                    notification.success({
                        message: x.message,
                    });
                    navigate(`/templateView?id=${x.data._id}`, {
                        state: {data: x.data},
                    });
                }
            } else {
                notification.warning({
                    message: "Fill All Required Fields",
                });
            }
        });
    };

    useEffect(() => {
        loadCountryFxn();
        handleCourseCategory();
    }, []);

    const loadCountryFxn = async () => {
        let x = await loadCountryList();
        setCountryList(x);
    };

    const getRelatedUniversity = async (id) => {
        if (id) {
            setCountryData(id);
            let {data} = await getRelatedUniversityListFxn(id);
            setUniversityList(data);
        }
    };
    const getRelatedCourse = async (id) => {
        if (id) {
            setCountryData(id);
            let {data} = await getRelatedCourseListFxn(id);
            setCourseList(data.data);
        }
    };
    const handleCourseCategory = async () => {
        let x = await courseCategoryList();
        setCategoryList(x.data.data);
      };

    let inputTypes = {
        fields: [
            {
                key: "countryId",
                label: "Country",
                required: true,
                placeholder: "Country",
                type: "select",
                options: countryList,
                keyAccessor: (x) => x._id,
                valueAccessor: (x) => `${x.countryName}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        countryId: x,
                    });
                    getRelatedUniversity(x);
                },
            },

            {
                key: "universityId",
                label: "University",
                required: true,
                placeholder: "University",
                type: "select",
                options: universityList,
                keyAccessor: (x) => x._id,
                valueAccessor: (x) => `${x.universityName}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        universityId: x,
                    });
                    getRelatedCourse(x);
                },
            },

            {
                key: "courseId",
                label: "Course",
                required: true,
                placeholder: "Course",
                type: "select",
                options: courseList,
                keyAccessor: (x) => x._id,
                valueAccessor: (x) => `${x.courseName}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        courseId: x,
                    });
                },
            },

            {
                key: "templateName",
                label: "Template Name ",
                required: true,
                placeholder: "Template Name",
                type: "text",
            },

            {
                key: "headerDesign",
                label: "Header Design",
                type: "ckeditor",
                onChange: (x) => {
                    props.form.setFieldsValue({
                        headerDesign: x,
                    });
                },
            },
            {
                key: "footerDesign",

                label: "Footer Design",
                type: "ckeditor",
                onChange: (x) => {
                    props.form.setFieldsValue({
                        footerDesign: x,
                    });
                },
            },

            {
                key: "originalDocument",
                label: "Original Document",
                placeholder: "Original Document",
                type: "file",
                fileName: originalDocument,
                onChange: handleTemplateChange,
            },
            {
                key: "categoryId",
                label: "Category",
                // required: true,
                placeholder: "Category",
                type: "select",
                options: categoryList,
                keyAccessor: (x) => x._id,
                valueAccessor: (x) => `${x.categoryName}`,
                onChange: (x) => {
                  props.form.setFieldsValue({
                    categoryId: x,
                  });
                },

            },
        ],
    };
    return (
        <>
            <Card title={"Add New SOP Template"}>
                <Form onSubmit={handleSubmit}>
                    <div className={"row"}>
                        {inputTypes.fields.map((item, key) => {
                            return (
                                <div className={"col-md-6 pt-3"} key={key}>
                                    <GetEachFormFields
                                        {...props.form}
                                        item={item}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    <div style={{marginTop: "20px"}}>
                        <Button type={"primary"} htmlType="submit">
                            submit
                        </Button>
                    </div>
                </Form>
            </Card>
        </>
    );
};

export default Form.create()(AddTemplate);
