import React, {useEffect, useState, useRef} from "react"
import {useDispatch} from "react-redux";
import {Tooltip, Card, Table, InputBox} from "../../../components/Elements/appUtils";
import _ from "lodash"
import {campusIntakesListFxn} from "../actions";
import moment from "moment";

let {TableCard} = Card
let initState = {
    totalCourses: 0,
    isLoading: true,
    courseList: [],
    currentPage: 1,
    count: 50,
    isMount: false,
    status: "Open"
}

const ProgramListComponent = (props) => {
    let {countryName} = props;
    let dispatch = useDispatch()
    let [state, setState] = useState(initState)
    let tableRef = useRef()

    let [eligDrawer, setEligDrawer] = useState({
        universityId: '',
        universityName: '',
        visible: false
    })
    let [courseState, setCourseState] = useState({
        visible: false,
        selectedCourse: {}
    })

    let events = {
        _updateState: (data) => {
            setState((prevState) => {
                return {
                    ...prevState,
                    ...data,
                    currentPage: 1
                }
            })
        },
        onShowSizeChange: (current, pageSize) => {
            events._updateState({
                currentPage: current
            })
        },
        refreshTable: () => {
            if (tableRef && tableRef.current && tableRef.current.reload) {
                tableRef.current.reload()
            }
        },
        debounceFetchData: () => {
            events._updateState({isLoading: true})
            let dataFetch = _.debounce(() => {
                events.refreshTable()
                console.log('rin')
            }, 1500)
            dataFetch()
        },
        debounceRefresh: () => {
            const debouncedLogHi = _.debounce(() => {
                events.refreshTable()
                console.log('run')
            }, 1000, true)
            debouncedLogHi()

        },
        hideEligDrawer: () => {
            setEligDrawer({
                universityId: '',
                universityName: '',
                visible: false
            })
        },
        hideStudentDrawer: () => {
            setCourseState({
                visible: false,
                selectedCourse: {}
            })
        },
    }


    let apiRequest = (params) => {
        return new Promise(async (resolve) => {
            let {countryId} = props;
            let obj = {
                courseName: state.courseName,
                universityName: state.universityName,
                campusName: state.campusName,
                intake: state.intake,
                status: state.status,
                countryId: countryId,
                // page: state.currentPage
            }
            params = {...params, ...obj}
            let resp1 = await campusIntakesListFxn({
                ...params,
                regExFilters: ['courseName', 'courseDuration', 'courseLevel', 'tuitionFee']
            })
            resolve(resp1)
            setTimeout(() => {
                events._updateState({
                    totalCourses: resp1.total ? resp1.total : 0,
                    currentPage: params.page,
                    isLoading: false
                })
            }, 500)
        })
    }

    useEffect(() => {
        events.debounceFetchData()
    }, [state.courseName, state.universityName, state.campusName, state.intake, state.status])


    let columns = [
        {
            key: '_id',
            title: '#',
            dataIndex: '_id',
            render: (item, record, index) => {
                return (
                    <>
                        {state.count * (state.currentPage - 1) + (index + 1)}
                    </>
                )
            }
        },
        {
            key: 'courseName',
            title: 'Program',
            dataIndex: 'courseName',
        },
        {
            key: 'universityName',
            title: 'University',
            dataIndex: 'universityName',
            render: (item, record) => {
                let {campusIntakes} = record;
                return (
                    <>
                        {item}
                        {campusIntakes && campusIntakes.updateDate ? <div className={'mt5'}>
                            <label className={'label label-default label-xs'} style={{fontSize: 11}}>
                                Last Update : {moment(campusIntakes.updateDate).fromNow()}
                            </label>
                        </div> : null}
                    </>
                )
            }
        },
        {
            key: 'campusIntakes.campusName',
            title: 'Campus',
            dataIndex: 'campusIntakes.campusName',
            render: (item, record) => {
                let {campusIntakes} = record
                return (
                    <div>
                        {campusIntakes.campusName}
                    </div>
                )
            }
        },
        {
            key: 'campusIntakes',
            title: 'Program',
            dataIndex: 'campusIntakes',
            render: (item) => {
                return (
                    <>
                        {item.month ? item.month : ""}
                        {item.year ? `, ${item.year}` : ""}
                    </>
                )
            }
        },
        {
            key: 'campusIntakes.status',
            title: 'status',
            dataIndex: 'campusIntakes.status',
            render: (item, record) => {
                let {campusIntakes} = record
                return (
                    <div style={{textTransform: "uppercase"}}>
                        {campusIntakes.status}
                    </div>
                )
            }
        },
       /* {
            key: 'action',
            title: 'Action',
            dataIndex: 'action',
            render: (item, record) => {
                let {courseUniversity, universityName} = record;
                return (
                    <div style={{width: 150}}>
                        <div>

                        </div>


                    </div>
                )
            }
        },*/
    ]
    return (
        <>
            <TableCard title={`${countryName} course current status`}>
                <div className={'table_program'}>
                    <table className={'table table-bordered'}>
                        <tr>
                            <th>#</th>
                            <th>Program</th>
                            <th>University</th>
                            <th>Campus</th>
                            <th>Intake</th>
                            <th>Status</th>
                            {/*  <th>
                                <div style={{width: 150}}>
                                    Action
                                </div>
                            </th>*/}
                        </tr>
                        <tr className={"bgRow"}>
                            <td></td>
                            <td>
                                <InputBox title={'Search by course'}>
                                    <input value={state.courseName}
                                           placeholder={'Course Name'}
                                           className={'form-control'}

                                           onChange={({target}) => {
                                               events._updateState({courseName: target.value})
                                           }}/>
                                </InputBox>
                            </td>
                            <td>
                                <InputBox extraCls={"single_column"}
                                          title={'Search by university'}>
                                    <input value={state.universityName}
                                           className={'form-control'}
                                           placeholder={'University Name'}
                                           onChange={({target}) => {
                                               events._updateState({universityName: target.value})
                                           }}/>
                                </InputBox>
                            </td>
                            <td>
                                <InputBox extraCls={"single_column"} title={'Search by campus'}>
                                    <input value={state.campusName}
                                           placeholder={'Campus'}
                                           className={'form-control'}
                                           onChange={({target}) => {
                                               events._updateState({campusName: target.value})
                                           }}/>
                                </InputBox>
                            </td>
                            <td>
                                <InputBox extraCls={"single_column"} title={'Search by intake'}>
                                    <input value={state.intake}
                                           placeholder={'Intake'}
                                           className={'form-control'}
                                           onChange={({target}) => {
                                               events._updateState({intake: target.value})
                                           }}/>
                                </InputBox>
                            </td>
                            <td>
                                <InputBox title={'Search by status'} type={'select'}>
                                    <select value={state.status} className={'form-control'}
                                            onChange={({target}) => {
                                                events._updateState({status: target.value})
                                            }}>
                                        <option value={'Open'}>Open</option>
                                        <option value={'Close'}>Close</option>
                                    </select>
                                </InputBox>
                            </td>
                            {/*<td style={{width: 200}}></td>*/}
                        </tr>
                    </table>

                    {state.isLoading ? <div className={'loader'}>
                        <br/>
                        <h3>Loading</h3>
                    </div> : null}

                    <div key={state.totalCourses}>
                        <Table
                            bordered={true}
                            apiRequest={apiRequest}
                            ref={tableRef}
                            columns={columns}

                            pagination={{
                                defaultPageSize: 20,
                                pageSizeOptions: ['20', '50', '100'],
                                position: "bottom"
                            }}/>
                    </div>
                </div>

            </TableCard>
        </>
    )
}
export default ProgramListComponent
