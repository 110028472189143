import React from "react";
import {useDispatch} from "react-redux";
import {
    Button,
    Card,
    Drawer,
    Form,
} from "../../../components/Elements/appUtils";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import {AddCloseFxn} from "../action";

const CloseLead = (props) => {
    let {visible, onClose, leadId} = props;
    const {getFieldDecorator} = props.form;
    let dispatch = useDispatch();
    let inputTypes = {
        fields: [
            {
                key: "reason",
                label: "Notes",
                required: true,
                placeholder: "Notes",
                type: "textarea",
            },
    ],
  };
  const handleClose = (e) => {
      const {form} = props;
      e.preventDefault();
      form.validateFields(async (err, valData) => {
          if (!err) {
              valData.leadId = leadId;
              console.log(valData, valData.leadId, "leadid-----");
              let x = await dispatch(AddCloseFxn(valData));
              onClose();
              props.form.setFieldsValue({
                  reason: "",
              });
          }
      });
  };
    return (
        <>
            <Drawer
                placement="right"
                title={"Close Lead"}
                onClose={onClose}
                visible={visible}
                width="40%"
            >
                <Form onSubmit={handleClose}>
                    <div className={"row"}>
                        {inputTypes.fields.map((item, key) => {
                            return (
                                <div
                                    className={`pt-3 ${
                                        item.span == 6 ? "col-md-6" : "col-md-12"
                                    }`}
                                    key={key}
                                >
                                    <GetEachFormFields {...props.form} item={item}/>
                                </div>
                            );
                        })}
                    </div>

                    <Button
                        type={"primary"}
                        htmlType="submit"
                        className={"btn main-btn mt-4"}
                    >
                        submit
                    </Button>
                </Form>
            </Drawer>
        </>
    );
};
export default Form.create()(CloseLead);
