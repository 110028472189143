import React, {useState} from "react";
import Card from "../../../components/Elements/Card";
import Modal from "../../../components/Elements/Modal";
import ChangePassword from "../../changepassword/changePassword";
import ApplicationStatusWise from "../components/applicationStatusWise";
import Commission from "../components/commission";
import EnglishTestRevenue from "../components/englishTestRevenue";
import EnglishTestStudent from "../components/englishTestStudent";
import GicComponent from "../components/gicComponent";
import LeadSlider from "../components/leadSlider";
import Report from "../components/report";

const CompanyDashboard = (props) => {
    let [branchId, setBranchId] = useState("");
    let [changePassword, setChangePassword] = useState(true);
    let [branchData, setBranchData] = useState(null);
    let callback = (branchId) => {
        setBranchId(branchId);
    };
    let user = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : null;
    const handleBranchChange = async (selectedBranchId) => {
        setBranchId(selectedBranchId);

        // const branchData = await fetchDataForBranch(selectedBranchId);
        setBranchData(branchData);
    };
    // Retrieve branchId from props
    // let branchId = props.branchId;
    return (
        <>
            <section className="countries">
                <LeadSlider callback={handleBranchChange} currentUser={user}/>
                <div>
                    {user.userType == "reception" ? (
                        ""
                    ) : (
                        <>
                            <div className="row g-4">
                                <Report/>
                                <Commission/>
                            </div>

                            <div className="row g-4 mt-2">
                                <EnglishTestRevenue/>
                                <EnglishTestStudent/>
                            </div>
                            <ApplicationStatusWise/>
                            {user && user.firstTimeLogin ? (
                                <Modal
                                    visible={changePassword}
                                    onClose={() => {
                                        setChangePassword(false);
                                    }}
                                    width={"50%"}
                                    placement="right"
                                >
                                    <Card shadow={false} padding={false}>
                                        <ChangePassword
                                            onClose={() => {
                                                setChangePassword(false);
                                            }}
                                        />
                                    </Card>
                                </Modal>
                            ) : (
                                ""
                            )}
                        </>
                    )}

                    <GicComponent {...props} />
                </div>
            </section>
        </>
    );
};
export default CompanyDashboard;
