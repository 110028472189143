import {apiUrl} from "../../../../settings";

export const addCourseCategoryUrl = () => {
    return `${apiUrl}/addCourseCategory`;
};

export const courseCategoryUrl = () => {
    return `${apiUrl}/courseCategories`;
};

export const getCourseCategoryUrl = (categoryId) => {
    return apiUrl + '/getCourseCategory/' + categoryId
};

export const postCourseCategoryUrl = () => {
    return `${apiUrl}/updateCourseCategory`;
};
