import React from "react";
import {
    Card,
} from "../../components/Elements/appUtils";
import GicListComponent from "./components/gicListComponent";

let {TableCard} = Card

const GicList = (props) => {
    return (
        <>
            <TableCard title={"Gic Request List"}>
                <GicListComponent/>
            </TableCard>
        </>
    );
};

export default GicList;
