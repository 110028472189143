import _, {cloneWith} from "lodash";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {
    Button,
    Card,
    Form,
    InputBox,
    notification,
} from "../../../components/Elements/appUtils";
import {EnglishExamTypes} from "../../../components/_utils/_utils";
import {Flags} from "../../../components/_utils/appUtils";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import {loadCountryList} from "../../Masters/state/action";
import {branchListFxn} from "../../Reception/actions";
import {companyCountryList} from "../../University/action";
import {GetRelatedUserFxn} from "../../Users/action";
import {postUpdateUserFxn} from "../../Users/views/action";

const commissionOptions = [
    {option: "Yes", value: true},
    {option: "No", value: false},
];
const EditManager = (props) => {
    const {getFieldDecorator} = props.form;
    const [countryList, setCountryList] = useState([]);
    const [branchList, setBranchList] = useState([]);
    const [countryStatus, setCountryStatus] = useState([]);
    const [userId, setUserId] = useState("");
    let user = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : null;
    const [isSelected, setIsSelected] = useState(false);
  const [isSelectedEnglish, setIsSelectedEnglish] = useState(false);

  const handleClick = () => {
    setIsSelected(!isSelected);
  };
  const handleClickEnglish = () => {
    setIsSelectedEnglish(!isSelectedEnglish);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    handleState();
    loadBranches();
  }, []);
  useEffect(() => {
    let searchParams = new URLSearchParams(window.location.search);
    let id = searchParams.get("_id");
    if (id) {
      setUserId(id);
      getRelatedUser(id);
    }
  }, [branchList]);
  const events = {
    setBranch: (id) => {
      let branchUser = _.find(branchList, (item) => {
        return item._id == id;
      });
      if (branchUser) {
        props.form.setFieldsValue({
          branchId: branchUser._id,
        });
      }
    },
  };
  const getRelatedUser = async (id) => {
      let {data} = await dispatch(GetRelatedUserFxn(id));

    setTimeout(() => {
      props.form.setFieldsValue({
          name: data.name,
          address: data.address,
          email: data.email,
          countryCode: data.countryISOCode,
          mobile: data.mobile,
          enableForCommission: data.enableForCommission,
          enableForIp: data.enableForIp,
      });
    }, 500);
    events.setBranch(data.branchId);
  };

    const boxClassName = isSelected
        ? "col-md-12 selected-box selected"
        : "col-md-12 selected-box";
    const boxClassNameEnglish = isSelectedEnglish
        ? "col-md-12 selected-box selected"
        : "col-md-12 selected-box";

  const handleState = async () => {
    let x = await companyCountryList();
    setCountryList(x.data);
  };
  const loadBranches = async () => {
    let data = await dispatch(
        branchListFxn({
            userType: ["branch", "headBranch"],
        })
    );
    setBranchList(data.data);
  };
  const handleSubmit = (e) => {
      const {form} = props;
    e.preventDefault();
    form.validateFields(async (err, valData) => {
      if (!err) {
        let countryStatusList = [];
        _.each(countryStatus, (item) => {
          if (item) {
              countryStatusList.push({countryId: item});
          }
        });
        delete valData["countryId"];
        valData.countryStatusList = countryStatusList;

        _.each(countryStatus, (item) => {
          if (item) {
              countryStatusList.push({countryId: item});
          }
        });
        valData.countryStatusList = countryStatusList;
        valData.id = userId;

        let x = await dispatch(
            postUpdateUserFxn({...valData, userType: "manager"})
        );
        if (x.success) {
        }
      } else {
        notification.warning({
          message: "Please Fill All Required Fields",
        });
      }
    });
  };

  let inputTypes = {
    fields: [
      {
        key: "name",
        label: " Full Name",
        required: true,
        placeholder: "Name",
        type: "text",
      },
      {
        key: "email",
        label: "Email",
        required: true,
        placeholder: "Email",
        type: "text",
      },

      {
        key: "address",
        label: "Address",
        required: true,
        placeholder: "Address",
        type: "text",
      },
      {
        key: "enableForCommission",
        label: "Enable For Commission",
        options: commissionOptions,
        type: "select",
        required: true,

        keyAccessor: (x) => x.value,
        valueAccessor: (x) => `${x.option}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            enableForCommission: x,
          });
        },
      },
      {
        key: "countryCode",
        label: "Country Code",
        required: true,
        placeholder: "Country Code",
        type: "select",
        options: Flags,
        showSearch: true,
        keyAccessor: (x) => x.countryCode,
        valueAccessor: (x) => `${x.name} (${x.dialCode})`,
        onChange: (x) => {
          props.form.setFieldsValue({
            countryCode: x,
          });
        },
        span: "col-md-3",
      },
      {
        key: "mobile",
        label: "Mobile No",
        required: true,
        placeholder: "Mobile No",
        type: "text",
        span: "col-md-3",
      },

      {
        key: "branchId",
        label: "Branch",
        required: true,
        hidden: !(user.userType == "headBranch"),
        placeholder: "Please Choose Branch",
          type: "select",
          options: branchList,
          keyAccessor: (x) => x._id,
          valueAccessor: (x) => `${x.name}`,
          onChange: (x) => {
              props.form.setFieldsValue({
                  branchId: x,
              });
          },
      },
        {
            key: "enableForIp",
            label: "Enable For Ip Address",
            placeholder: "Enable For Ip Address",
            options: commissionOptions,
            type: "select",
            keyAccessor: (x) => x.value,
            valueAccessor: (x) => `${x.option}`,
            onChange: (x) => {
                props.form.setFieldsValue({
                    enableForIp: x,
                });
            },
        },
    ],
  };
  return (
      <>
          <Form onSubmit={handleSubmit}>
              <Card title={"Edit Manager"}>
                  <div className={"row"}>
                      {inputTypes.fields.map((item, key) => {
                          return (
                              <div
                                  className={`pt-3 ${item.span ? item.span : "col-md-6"}`}
                                  key={key}
                              >
                                  <GetEachFormFields {...props.form} item={item}/>
                              </div>
                          );
                      })}
                  </div>
                  {" "}
                  <div style={{marginTop: "20px"}}>
                      <Button type={"primary"} htmlType="submit">
                          submit
                      </Button>
                  </div>
              </Card>
          </Form>
      </>
  );
};

export default Form.create()(EditManager);
