import React, {useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {
    Card,
    Popconfirm,
    Table,
    displayDate,
} from "../../components/Elements/appUtils";
import {imgPath} from "../../components/_utils/_utils";
import {trainingRequestListFxn, updateTrainingRequestFxn} from "./action";

let {TableCard} = Card;
const TrainingRequestList = (props) => {
    let dispatch = useDispatch();
    const navigate = useNavigate();
    let [total, setTotal] = useState(0);
    let tableRef = useRef();
    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            let obj = {
                sortField: "_id",
                sortOrder: "descend",
                ...params,
            };
            let data = await dispatch(
                trainingRequestListFxn({
                    ...obj,
                    regExFilters: ["name"],
                })
            );
            setTotal(data.total);
            console.log(data, "ddddddddddddatataaaaaaaaaaa");
            resolve(data);
        });
    };
    const events = {
        handleUpdateStatus: async (status, id) => {
            let valData = {};
            valData.status = status;
            valData.trainingRequestId = id;
            let x = await dispatch(updateTrainingRequestFxn(valData));
            if (x && x.success) {
                tableRef.current.reload();
            }
        },
    };
    const columns = [
        {
            title: "Sr .No",
            key: "_id",
            dataIndex: "_id",
            width: 80,
            render: (item, record, index) => {
                return <div style={{width: 50}}>{index + 1}</div>;
            },
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            searchTextName: "name",
            render: (val, record) => {
                return <p>{val}</p>;
            },
        },
        {
            title: "Company Name",
            dataIndex: "companyName",
            key: "companyName",
            searchTextName: "companyName",
        },
        // {
        //   title: 'Agent Name',
        //   dataIndex: 'agentName',
        //   key: 'agentName',
        //   searchTextName: 'agentName'
        // },
        {
            title: "Generated on",
            dataIndex: "date",
            key: "date",
            searchDateName: "date",
            render: (val) => {
                return (
                    <div
                        // style={{ width: 90 }}
                    >
                        {displayDate(val)}
                    </div>
                );
            },
        },
        {
            title: "Training Completion Date",
            dataIndex: "trainingCompletionDate",
            key: "trainingCompletionDate",

            render: (val) => {
                return (
                    <div
                        // style={{ width: 90 }}
                    >
                        {displayDate(val)}
                    </div>
                );
            },
        },

        {
            title: "Status",
            key: "status",
            dataIndex: "status",
            width: 150,
            render: (item, record) => {
                return (
                    <React.Fragment>
                        {item == "Pending" ? (
                            <span className="pending">Pending</span>
                        ) : null}
                        {item == "Done" ? <span className="active">Completed</span> : null}
                        {item == "Cancel" ? (
                            <span className="inactive">Cancelled</span>
                        ) : null}
                    </React.Fragment>
                );
            },
        },
        {
            title: "Action",
            dataIndex: "status",
            key: "status",
            render: (item, record) => {
                return (
                    <>
                        {record && record.status == "Pending" ? (
                            <div className={"col-md-4"}>
                                <div className={"mt-4-1"}>
                                    <Popconfirm
                                        title={"Are your sure, you want to approve Training?"}
                                        okText="Yes"
                                        cancelText="No"
                                        onConfirm={() => {
                                            events.handleUpdateStatus("Done", record._id);
                                        }}
                                    >
                                        <img
                                            src={imgPath.check}
                                            className="btn-action"
                                            style={{width: "30px", marginRight: "6px"}}
                                        />
                                    </Popconfirm>
                                    {/* <button
                                      className={"btn btn-danger ml10"}
                                      onClick={() => setShowAddStatusInput(false)}
                                  >
                                      Cancel
                                  </button> */}
                                    <Popconfirm
                                        title={"Are you sure, you want to Cancel this Training?"}
                                        onConfirm={() => {
                                            events.handleUpdateStatus("Cancel", record._id);
                                        }}
                                    >
                                        <button className={"btn"}>
                                            <img src={imgPath.close}/>
                                        </button>
                                    </Popconfirm>
                                </div>
                            </div>
                        ) : null}
                    </>
                );
            },
        },
    ];

    return (
        <>
            <TableCard {...props} title={`Total ${total} Request`}>
                <Table apiRequest={apiRequest} columns={columns} ref={tableRef}/>
            </TableCard>
        </>
    );
};

export default TrainingRequestList;
