import {apiUrl} from "../../../settings";

export const boardStreamUrl = () => {
    return `${apiUrl}/addBoardStream`;
};

export const boardStreamListUrl = () => {
    return `${apiUrl}/boardStreamList`;
};

export const deleteBoardUrl = () => {
    return `${apiUrl}/deleteBoard`;
};
