import { apiUrl } from "../../settings";

export const singleStudentUrl = (studentId) => {
  return apiUrl + "/getSingleStudent/" + studentId;
};
export const addCommissionUrl = () => {
  return `${apiUrl}/addCommission`;
};
export const singleStudentApplicationUrl = (studentId) => {
  return apiUrl + "/getsingleUserApplication/" + studentId;
};
export const singleStudentCommissionUrl = (studentId) => {
  return apiUrl + "/getsingleUserCommission/" + studentId;
};
export const postUpdateCommissionUrl = (id) => {
  return apiUrl + "/editCommission/" + id;
};
export const CommissionListUrl = () => {
  return `${apiUrl}/commissionList`;
};
