import { apiUrl } from "../../settings";

export const getRelatedUniverUrl = (countryId) => {
  return apiUrl + "/getRelatedMasterUniversity/" + countryId;
};

export const addRelatedCourseUrl = () => {
  return `${apiUrl}/addMasterCourse`;
};

export const CourseListUrl = () => {
  return `${apiUrl}/masterCourses`;
};
export const representativeCourseListUrl = () => {
  return `${apiUrl}/CourseList`;
};
export const previousCourseListUrl = () => {
  return `${apiUrl}/previousCourses`;
};

export const getCourseUrl = (courseId) => {
  return apiUrl + "/getSingleMasterCourse/" + courseId;
};

export const postCourseUrl = () => {
  return `${apiUrl}/updateMasterCourse`;
};

export const updateCourseActiveStatusUrl = () => {
  return apiUrl + "/updateCourseAvailability";
};
export const addRepresentativeCourseUrl = () => {
  return apiUrl + "/addCourse";
};

export const getRelatedUniversityForSopUrl = (countryId) => {
  return apiUrl + "/getRelatedUniversityForSop/" + countryId;
};
