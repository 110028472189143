import lodash from "lodash";
import CountryJson from "../../assets/jsonFiles/countries";
import StateJson from "../../assets/jsonFiles/states";
import CityJson from "../../assets/jsonFiles/indiaCities.json";
import UniversityJson from "../../assets/jsonFiles/universityJson.json";

const LoadAllCountry = () => {
  return CountryJson
}

const LoadState = (countryId) => {
  if (countryId) {
    let filterArr = lodash.filter(StateJson, (item) => {
      return item.country_id == countryId
    })
    return filterArr
  } else {
    return StateJson
  }
}

const LoadCity = (stateId) => {
  if (stateId) {
    let filterArr = lodash.filter(CityJson, (item) => {
      return item.state_id == stateId
    })
    return filterArr
  } else {
    return CityJson
  }
}
export {LoadAllCountry, LoadState, LoadCity, CountryJson, UniversityJson};
