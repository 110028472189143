import { hideLoader, showLoader } from "../../actions/loader";
import notification from "../../components/Elements/Notification";
import {customAxios as axios, getToken} from "../../request";
import {
  singleStudentUrl,
  addCommissionUrl,
  CommissionListUrl,
  postUpdateCommissionUrl,
  singleStudentApplicationUrl,
  singleStudentCommissionUrl,
  commissionListUrl
} from "./api";

export const singleStudentFxn = (id) => async (dispatch) => {
  dispatch(showLoader());
  let config = {
    ...(await getToken()),
  };
  let { data } = await axios.get(singleStudentUrl(id), config);
  console.log(data);
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  }

  return data;
};

export const addCommissionFxn = (valData) => async (dispatch) => {
  // dispatch(showLoader());
  let { data } = await axios.post(addCommissionUrl(), valData, getToken());
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  } else {
    notification.success({
      message: data.message || "Success",
    });
  }
  return data;
};
export const singleStudentApplicationFxn = (id) => async (dispatch) => {
  dispatch(showLoader());
  let config = {
    ...(await getToken()),
  };
  let { data } = await axios.get(singleStudentApplicationUrl(id), config);
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  }

  return data;
};

export const singleStudentCommissionFxn = (id) => async (dispatch) => {
  dispatch(showLoader());
  let config = {
    ...(await getToken()),
  };
  let { data } = await axios.get(singleStudentCommissionUrl(id), config);
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  }

  return data;
};

export const postUpdateCommission = (valData) => async (dispatch) => {
  dispatch(showLoader());
  let {id} = valData;
  let {data} = await axios.put(postUpdateCommissionUrl(id), valData, getToken());
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  } else {
    notification.success({
      message: data.message,
    });
  }
  return data;
};
export const commissionListFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  let config = {
    params: {...valData},
    ...(await getToken()),
  };
  let {data} = await axios.get(CommissionListUrl(), config);
  dispatch(hideLoader());


  return data;
};
