import { hideLoader, showLoader } from "../../actions/loader";
import notification from "../../components/Elements/Notification";
import { customAxios as axios, getToken } from "../../request";
import {
  CourseListUrl,
  addRelatedCourseUrl,
  addRepresentativeCourseUrl,
  getCourseUrl,
  getRelatedUniverUrl,
  postCourseUrl,
  previousCourseListUrl,
  representativeCourseListUrl,
  updateCourseActiveStatusUrl, getRelatedUniversityForSopUrl,
} from "./api";

export const getRelatedUniversityListFxn = async (id) => {
  let config = {
      ...(await getToken()),
  };
    let {data} = await axios.get(getRelatedUniverUrl(id), config);
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  }
  return data;
};

export const AddCourseFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
    let {data} = await axios.post(addRelatedCourseUrl(), valData, getToken());
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  } else {
    notification.success({
      message: data.message || "Success",
    });
  }
    return data;
};
export const AddRepresentingCourseFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  let { data } = await axios.post(
      addRepresentativeCourseUrl(),
      valData,
      getToken()
  );
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  } else {
    notification.success({
      message: data.message || "Success",
    });
  }
  return data;
};

export const courseCategoryList = async (valData) => {
  let config = {
    params: { ...valData },
    ...(await getToken()),
  };
  let { data } = await axios.get(CourseListUrl(), config);
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  }

  return data;
};
export const representativeCourseListFxn = async (valData) => {
  let config = {
    params: { ...valData },
    ...(await getToken()),
  };
  let { data } = await axios.get(representativeCourseListUrl(), config);
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  }

  return data;
};
export const previousCourseListFxn = async (valData) => {
    let config = {
        params: {...valData},
        ...(await getToken()),
    };
    let {data} = await axios.get(previousCourseListUrl(), config);
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  }

  return data;
};
export const GetRelatedCourseFxn = (id) => async (dispatch) => {
  dispatch(showLoader());
    let config = {
        ...(await getToken()),
    };
    let {data} = await axios.get(getCourseUrl(id), config);
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  }

  return data;
};

export const postRelatedCourseFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  let { data } = await axios.post(postCourseUrl(), valData, getToken());
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  } else {
    notification.success({
      message: data.message,
    });
  }
  return data;
};

export const updateCourseActiveStatusFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  let { data } = await axios.post(
    updateCourseActiveStatusUrl(),
    valData,
    getToken()
  );
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  } else {
    notification.success({
      message: data.message || "Success",
    });
  }
  return data;
};
export const getRelatedUniversityForSopFxn = async (id) => {
  let config = {
    ...(await getToken()),
  };
  let {data} = await axios.get(getRelatedUniversityForSopUrl(id), config);
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  }
  return data;
};
