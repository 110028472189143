import _ from "lodash";
import async from "async"

const englishFields = {
    examType: "englishExamType",
    examinationDate: "englishExamDate",
    overall: "englishOverallScore",
    listening: "englishListeningScore",
    writing: "englishWritingScore",
    reading: "englishReadingScore",
    speaking: "englishSpeakingScore",
    english12ThMarks: "english12ThMarksScore"
}
const qualificationFields = {
    "X": "10th",
    "XII": "12th",
    "Bachelor Degree": "bachelor",
    "Post Graduation": "postGraduation",
    "10th3YearDiploma": "10th3YearDiploma",
    "XII + Diploma": "12thDiploma",
}
const educationFields = {
    qualification: "Qualification",
    stream: "Stream",
    board: "Board",
    university: "University",
    passingYear: "PassingYear",
    percentage: "Percentage",
    math: "Math",
    english: "English"
}

export const getEducationData = (data) => {
    return new Promise((resolve) => {
        let {education, englishRequirement} = data;
        let obj = {}
        async.parallel([
            (cbMain) => {
                if (education && education.length) {
                    async.each(education, (item, cb) => {
                        let qualificationField = qualificationFields[item.qualification];
                        async.forEachOf(item, (value, key, cb1) => {
                            let fieldName = educationFields[key];
                            if (fieldName) {
                                obj[`${qualificationField}${fieldName}`] = value;
                                cb1()
                            } else {
                                cb1()
                            }
                        }, () => {
                            cb()
                        })
                    }, () => {
                        cbMain()
                    })
                } else {
                    cbMain()
                }
            },
            (cbMain) => {
                async.forEachOf(englishRequirement, (value, key, cb) => {
                    let fieldName = englishFields[key];
                    obj[fieldName] = value;
                    cb()
                }, () => {
                    cbMain()
                })
            }
        ], () => {
            resolve(obj)
        })
    })
}

