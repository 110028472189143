import {apiUrl} from "../../../../settings";

export const addCheckListCategoryUrl = () => {
    return `${apiUrl}/checkListCategory/add`;
};

export const updateCheckListCategoryUrl = () => {
    return `${apiUrl}/checkListCategory/update`;
};

export const checkListCategoryListUrl = () => {
    return `${apiUrl}/checkListCategory/list`;
};

export const singleCheckListCategoryUrl = () => {
    return `${apiUrl}/checkListCategory/single`;
};

export const addCheckListDocumentUrl = () => {
    return `${apiUrl}/checkListDocument/add`;
};

export const updateCheckListDocumentUrl = () => {
    return `${apiUrl}/checkListDocument/update`;
};

export const checkListDocumentListUrl = () => {
    return `${apiUrl}/checkListDocument/list`;
};

export const singleCheckListDocumentUrl = () => {
    return `${apiUrl}/checkListDocument/single`;
};

export const checkListGroupedDocumentUrl = () => {
    return `${apiUrl}/checkListDocument/groupDocuments`;
};
