import notification from "../../components/Elements/Notification";
import { hideLoader, showLoader } from "../../actions/loader";
import { customAxios as axios, getToken } from "../../request";
import {
  addTemplateSectionUrl,
  getSectionListUrl,
  getSingleTemplateUrl,
  getTemplatetCategoryListUrl,
  removeTemplateUrl
} from "./apis";

export const getSingleTemplatetFxn = async (id) => {
  let config = {
    ...(await getToken()),
  };
  let data = await axios.get(getSingleTemplateUrl(id), config);
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  }
  return data.data;
};
export const getTemplatetCategoryListFxn = async (params) => {
  let config = {
    params,
    ...(await getToken()),
  };
  let { data } = await axios.get(getTemplatetCategoryListUrl(), config);
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  }
  return data.data;
};
export const AddTemplateSectionFxn = async (valData) => {
  let { data } = await axios.post(addTemplateSectionUrl(), valData, getToken());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  }
  return data;
};
export const getSectionListFxn = async () => {
  let config = {
    ...(await getToken()),
  };
  let { data } = await axios.get(getSectionListUrl(), config);
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  }
  return data.data;
};
export const RemoveSectionFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  let {data} = await axios.post(removeTemplateUrl(), valData, getToken());
  dispatch(hideLoader());
  if (data.error) {
      notification.error({
          message: data.message || "Error",
      });
  } else {
      notification.success({
          message: data.message || "Success",
      });
  }
  return data;
};
