import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import S from "string";
import notification from "../../components/Elements/Notification";
import {
  Button,
  Card,
  Form,
  InputBox,
  Table,
} from "../../components/Elements/appUtils";
import {
  EnglishExamTypes,
  boardList,
  genderOption,
  maritalStatusOption,
  qualificationOptions,
  streamsList,
} from "../../components/_utils/_utils";
import { UniversityJson } from "../../components/_utils/countryUtil";
import { GetEachFormFields } from "../../components/_utils/formUtils";
import { getEducationData } from "../../components/_utils/sopUtil";
import {apiUrl} from "../../settings";
import {getRelatedUniversityForSopFxn, getRelatedUniversityListFxn} from "../Course/action";
import {loadCountryList} from "../Masters/state/action";
import {getRelatedCourseForSopFxn, getRelatedCourseListFxn} from "../template/actions";
import {GenerateSOPFxn, templateSopFxn} from "./action";
import "./style.css";
import {useNavigate} from "react-router-dom";
const initialState = {
  countryId: "",
  universityId: "",
  courseId: "",
  name: "",
  dateOfBirth: "",
  gender: "",
  maritalStatus: "",
  address: "",
  mobile: "",
  email: "",
  education: {},
  board: "",
  passingYear: "",
  percentage: "",
  math: "",
  english: "",
  examType: "",
  englishRequirement: {},
};

const eduStateInit = {
  qualification: "",
  math: "",
  english: "",
  percentage: "",
  board: "",
  stream: "",
  passingYear: "",
};

let boardFields = ["XII + Diploma", "XII", "X + 3 Year Diploma", "X"];

const EducationComponent = (props) => {
  let { data, index, eduEvents, education, deleteItem } = props;

  return (
      <>
        <div className={index > 0 ? "repeat-sec" : ""}>
          <div className={"row"} key={index}>
            <div className={"col-md-2"}>
              <InputBox type={"select"}>
                <select
                    className={"form-select form-control"}
                    value={data.qualification}
                    onChange={({target}) => {
                      eduEvents.updateState(
                          {qualification: target.value},
                          index
                      );
                    }}
                >
                  <option value={""}>Choose Qualification</option>
                  {qualificationOptions.map((item) => {
                    return (
                        <option key={item.option} value={item.option}>
                          {item.option}
                        </option>
                    );
                  })}
                </select>
              </InputBox>
            </div>

            {data.qualification && boardFields.includes(data.qualification) ? (
                <div className={"col-md-2"} name="board">
                  <InputBox type={"select"}>
                    <select
                        className={"form-control"}
                        placeholder="Board"
                        onChange={({target}) => {
                          eduEvents.updateState({board: target.value}, index);
                        }}
                        value={data.board}
                    >
                      <option value={""}>Choose Board</option>

                      {boardList.map((item) => {
                        return (
                            <option key={item.option} value={item.option}>
                              {item.option}
                            </option>
                        );
                      })}
                    </select>
                  </InputBox>
                </div>
            ) : null}

            {data.qualification == "Bachelor Degree" ||
            data.qualification == "Post Graduation" ? (
                <>
                  <div className={"col-md-2"} name="board">
                    <InputBox title={"University"}>
                      <input
                          className={"form-control"}
                          placeholder="University"
                          onChange={({target}) => {
                            eduEvents.updateState(
                                {university: target.value},
                                index
                            );
                          }}
                          value={data.university}
                      >
                        {/* <option value={""}>Choose</option>
                      {UniversityJson.map((item, index) => {
                        return (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        );
                      })} */}
                      </input>
                  </InputBox>
                </div>
                <div className={"col-md-2"}>
                  <InputBox title={"Degree Name"}>
                    <input
                        className={"form-control"}
                        placeholder="Degree Name"
                        onChange={({target}) => {
                          eduEvents.updateState(
                              {courseName: target.value},
                              index
                          );
                        }}
                        value={data.courseName}
                    />
                  </InputBox>
                </div>
                </>
            ) : null}

            <div className={"col-md-2"} name="passingYear">
              <InputBox title={"Passing Year"}>
                <input
                    className={"form-control"}
                    placeholder="Passing Year"
                    onChange={({target}) => {
                      eduEvents.updateState({passingYear: target.value}, index);
                    }}
                    value={data.passingYear}
                />
              </InputBox>
            </div>

            <div className={"col-md-2"} name="Percentage">
              <InputBox title={"Percentage"}>
                <input
                    placeholder="percentage"
                    className={"form-control"}
                    onChange={({target}) => {
                      eduEvents.updateState({percentage: target.value}, index);
                    }}
                    value={data.percentage}
                />
              </InputBox>
            </div>

            {data.qualification == "XII" ||
            data.qualification == "XII + Diploma" ? (
                <div className={"col-md-2 "} name="math">
                  <InputBox title={"Stream"}>
                    <input
                        className={"form-control"}
                        placeholder="Stream"
                        onChange={({target}) => {
                          eduEvents.updateState({stream: target.value}, index);
                        }}
                        value={data.stream}
                    >
                      {/* <option value={""}>Choose</option>

                    {streamsList.map((item) => {
                      return (
                        <option key={item.option} value={item.option}>
                          {item.option}
                        </option>
                      );
                    })} */}
                    </input>
                  </InputBox>
                </div>
            ) : null}

            {data.qualification == "XII" ? (
                <div className={"col-md-2 "} name="englishScore">
                  <InputBox title={"English Score"}>
                    <input
                        className={"form-control"}
                        placeholder="English Score"
                        onChange={({target}) => {
                          eduEvents.updateState({english: target.value}, index);
                        }}
                        value={data.english}
                    />
                  </InputBox>
                </div>
            ) : null}
            {data.qualification == "XII" ? (
                <div className={"col-md-2"} name="math">
                  <InputBox title={"Math Score"}>
                    <input
                        className={"form-control"}
                        placeholder="Math Score"
                        onChange={({target}) => {
                          eduEvents.updateState({math: target.value}, index);
                        }}
                        value={data.math}
                    />
                  </InputBox>
                </div>
            ) : null}
          </div>

          {index !== 0 ? (
              <button className="btn delete" onClick={() => deleteItem(index)}>
                <i className="fa fa-times-circle"></i>
              </button>
          ) : null}
        </div>
    </>
  );
};

const GenerateSOP = (props) => {
  let dispatch = useDispatch();
  const navigate = useNavigate();

  const {getFieldDecorator, getFieldValue} = props.form;
  const [countryList, setCountryList] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [fields, setFields] = useState([]);
  const [education, setEducation] = useState([eduStateInit]);
  const [universityList, setUniversityList] = useState([]);
  const [eduState, setEduState] = useState(eduStateInit);
  const [formState, setFormState] = useState(initialState);

  const [generateId, setGenerateId] = useState("");
  const [templateData, setTemplateData] = useState({});
  const [requiredFields, setRequiredFields] = useState([]);

  useEffect(() => {
    handleState();
  }, []);

  const AllData = () => {
    setEducation([...education, eduStateInit]);
  };
  const deleteItem = (itemId) => {
    const updatedItems = education.filter((item, key) => key !== itemId);
    setEducation(updatedItems);
  };

  const eduEvents = {
    updateState: (data, index) => {
      let cloneEdu = _.clone(education);
      cloneEdu[index] = { ...cloneEdu[index], ...data };
      setEducation(cloneEdu);
    },
    addEdu: () => {
      if (education) {
        if (education.percentage < 1) {
          notification.warning({
            message: "Enter greater then 0 Percentage",
          });
          return;
        }
        if (education.qualification == "XII") {
          if (education.math < 1) {
            notification.warning({
              message: "Enter greater then 0 Math score",
            });
            return;
          }
          if (education.english < 1) {
            notification.warning({
              message: "Enter greater then 0 English score",
            });
            return;
          }
        }
      }
      setEducation((preState) => {
        return [...preState, eduState];
      });
      eduEvents.updateState(eduStateInit);
    },
  };

  const handleState = async () => {
    let x = await loadCountryList({ active: true });
    setCountryList(x);
  };
  const getRelatedUniversities = async (id) => {
    if (id) {
      let data = await getRelatedUniversityForSopFxn(id);
      setUniversityList(data.data);
    }
  };
  const getRelatedCourse = async (id) => {
    if (id) {
      let {data} = await getRelatedCourseForSopFxn(id);

      setCourseList(data.data);
    }
  };

  const loadTemplateData = async (courseId) => {
    let obj = {};
    obj.countryId = getFieldValue("countryId");
    obj.universityId = getFieldValue("universityId");
    obj.courseId = courseId;
    let { data, success, requiredFields } = await templateSopFxn(obj);
    if (success) {
      setTemplateData(data);
      setRequiredFields(requiredFields);
    }
  };

  const ExamConditions =
    !getFieldValue("englishRequirement.examType") ||
    (getFieldValue("englishRequirement.examType") &&
      getFieldValue("englishRequirement.examType") == "I don't have this");

  const Exam12ThConditions =
    !getFieldValue("englishRequirement.examType") ||
    (getFieldValue("englishRequirement.examType") &&
      getFieldValue("englishRequirement.examType") !== "I don't have this");

  const handleSubmit = (e) => {
    const { form } = props;
    e.preventDefault();

    form.validateFields(async (err, valData) => {
      if (!err) {
        let refineFieldObj = await getEducationData({
          education,
          englishRequirement: valData.englishRequirement,
        });
        valData.templateId = templateData.templateId;
        valData = { ...valData, education, ...refineFieldObj };
        valData = { ...valData, ...templateData };
        let findRequire = _.find(requiredFields, (item) => {
          return !valData[item];
        });
        if (findRequire) {
          let fieldName = S(findRequire).humanize().s;
          notification.error({
            message: `${fieldName} is empty. Please enter.`,
          });
          return;
        }
        let x = await dispatch(GenerateSOPFxn(valData));
        if (x && x.success) {
          // form.reset()
          form.resetFields();
          setEducation([eduStateInit]);
          form.setFieldsValue({
            passportNumber: "",
            email: "",
            mobile: "",
          });
          /* if (x.fileName) {
             window.open(x.fileName, "_blank");
           }*/
          navigate("/sopList")
        }
      } else {
        notification.warning({
          message: "Fill All Required Fields",
        });
      }
    });
  };

  const testScore = {
    fields: [
      {
        key: "englishRequirement.examType",
        label: "English Exam Type",
        required: true,
        placeholder: "English Exam Type",
        type: "select",
        span: 6,
        showStar: true,
        keyAccessor: (x) => x.option,
        valueAccessor: (x) => `${x.option}`,
        options: EnglishExamTypes,
        onChange: (x) => {
          let obj = {
            examType: x,
            overall: "",
            listening: "",
            reading: "",
            writing: "",
            speaking: "",
            english12ThMarks: "",
          };
          props.form.setFieldsValue({
            englishRequirement: obj,
          });
        },
      },
      {
        key: "englishRequirement.english12ThMarks",
        label: "English 12th Marks",
        type: "number",
        placeholder: "English 12th Marks",
        span: 6,
        hidden: Exam12ThConditions,
        required: !Exam12ThConditions,
        showStar: true,

      },
      {
        key: "englishRequirement.examinationDate",
        label: "Examination Date",
        type: "date",
        span: 6,
        placeholder: "Examination Date",
        hidden: ExamConditions,
        required: !ExamConditions,
        onChange: (x) => {
          props.form.setFieldsValue({
            "englishRequirement.examinationDate": x,
          });
        },
        showStar: true,

      },
      {
        key: "englishRequirement.overall",
        label: "Overall",
        type: "number",
        span: 6,

        placeholder: "Overall",
        hidden: ExamConditions,
        required: !ExamConditions,
        showStar: true,

      },
      {
        key: "englishRequirement.listening",
        label: "Listening",
        type: "number",
        span: 3,
        placeholder: "Listening",
        hidden: ExamConditions,
        required: !ExamConditions,
        showStar: true,

      },
      {
        key: "englishRequirement.reading",
        label: "Reading",
        type: "number",
        span: 3,
        placeholder: "Reading",
        hidden: ExamConditions,
        required: !ExamConditions,
        showStar: true,

      },
      {
        key: "englishRequirement.writing",
        label: "Writing",
        type: "number",
        span: 3,
        placeholder: "Writing",
        hidden: ExamConditions,
        required: !ExamConditions,
        showStar: true,

      },
      {
        key: "englishRequirement.speaking",
        label: "Speaking",
        type: "number",
        span: 3,
        placeholder: "Speaking",
        hidden: ExamConditions,
        required: !ExamConditions,
        showStar: true,

      },
    ],
  };
  let studentInfo = {
    fields: [
      {
        key: "name",
        label: "Name",
        required: true,
        placeholder: "Name",
        type: "text",
        showStar: true,

      },
      {
        key: "dateOfBirth",
        label: "Date of Birth",
        required: true,
        placeholder: "Date of Birth",
        type: "date",
        keyAccessor: (x) => x.option,
        valueAccessor: (x) => `${x.option}`,
        options: genderOption,
        onChange: (x) => {
          props.form.setFieldsValue({
            dateOfBirth: x,
          });
        },
        showStar: true,

      },
      {
        key: "passportNumber",
        label: "Passport Number",
        required: true,
        placeholder: "Passport Number",
        showStar: true,

      },
      {
        key: "gender",
        label: "Gender",
        required: true,
        placeholder: "Gender",
        type: "select",
        keyAccessor: (x) => x.option,
        valueAccessor: (x) => `${x.option}`,
        options: genderOption,
        onChange: (x) => {
          props.form.setFieldsValue({
            gender: x,
          });
        },
        showStar: true,

      },
      {
        key: "maritalStatus",
        label: "Marital Status",
        required: true,
        placeholder: "Marital Status",
        type: "select",
        keyAccessor: (x) => x.option,
        valueAccessor: (x) => `${x.option}`,
        options: maritalStatusOption,
        onChange: (x) => {
          props.form.setFieldsValue({
            maritalStatus: x,
          });
        },
        showStar: true,

      },
      {
        key: "dateOfMarriage",
        label: "Marriage Date",
        required: true,
        placeholder: "Marriage Date",
        type: "date",
        hidden: !(
            getFieldValue("maritalStatus") &&
            getFieldValue("maritalStatus") == "Married"
        ),
        keyAccessor: (x) => x.option,
        valueAccessor: (x) => `${x.option}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            dateOfMarriage: x,
          });
        },
        showStar: true,

      },
      {
        key: "address",
        label: "Address",
        required: true,
        placeholder: "Address",
        type: "text",
        showStar: true,

      },

      {
        key: "mobile",
        label: "Mobile Number",
        required: true,
        placeholder: "Mobile Number",
        type: "text",
        showStar: true,

      },
      {
        key: "email",
        label: "Email",
        required: true,
        placeholder: "Email",
        type: "text",
        showStar: true,

      },
    ],
  };

  let inputTypes = {
    fields: [
      {
        key: "countryId",
        label: "Country",
        required: true,
        placeholder: "Country Name",
        type: "select",
        options: countryList,
        keyAccessor: (x) => x._id,
        valueAccessor: (x) => `${x.countryName}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            countryId: x,
            universityId: undefined,
            courseId: undefined,
          });
          getRelatedUniversities(x);
        },
        span: 8,
        showStar: true,

      },

      {
        key: "universityId",
        label: "University",
        required: true,
        placeholder: "University",
        type: "select",
        options: universityList,
        keyAccessor: (x) => x._id,
        valueAccessor: (x) => `${x.universityName}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            universityId: x,
            courseId: undefined,
          });
          getRelatedCourse(x);
        },
        showStar: true,

      },
      {
        key: "courseId",
        label: "Course",
        required: true,
        placeholder: "Course Name",
        type: "select",
        options: courseList,
        keyAccessor: (x) => x._id,
        valueAccessor: (x) => `${x.courseName}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            courseId: x,
          });
          loadTemplateData(x);
        },
        showStar: true,

      },
    ],
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Card title={"Generate SOP"}>
          <div className={"row"}>
            {inputTypes.fields.map((item, key) => {
              return (
                <div className={"col-md-4 pt-3"} key={key}>
                  <GetEachFormFields {...props.form} item={item} />
                </div>
              );
            })}
          </div>
        </Card>

        <Card title={"Student Basic Information"}>
          <div className={"row"}>
            {studentInfo.fields.map((item, key) => {
              return (
                  <div className={"col-md-4 pt-3"} key={key}>
                    <GetEachFormFields {...props.form} item={item}/>
                  </div>
              );
            })}
          </div>
        </Card>

        <Card title={"Education"}
              extraBtn={[
                {
                  name: "Add Multiple Education",
                  action: AllData,
                },
              ]}
        >
          {education.map((data, index) => {
            return (
                <EducationComponent
                    data={data}
                    key={index}
                    index={index}
                    education={education}
                    eduEvents={eduEvents}
                    deleteItem={deleteItem}
                />
            );
          })}
        </Card>

        <Card title={"Test Score"}>
          <div className={"row"}>
            {testScore.fields.map((item, key) => {
              return !item.hidden ? (
                <div
                  className={`pt-3 ${item.span == 3 ? "col-md-3" : "col-md-4"}`}
                  key={key}
                >
                  <GetEachFormFields {...props.form} item={item} />
                </div>
              ) : null;
            })}
          </div>

          <Button type={"primary"} htmlType="submit" className={"mt-4"}>
            submit
          </Button>
        </Card>
      </Form>
    </>
  );
};

export default Form.create()(GenerateSOP);
