import React, { useEffect, useState } from "react";
import { studentCountryWiseFxn } from "../actions";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

const $ = window.$;

let filterList = ["today", "last 7 days", "last 30 days", "all"];

const Report = (props) => {
  let [state, setState] = useState({
    dateFilter: "all",
  });

  let _updateState = (data) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...data,
      };
    });
  };

  let [studentData, setStudentData] = useState([]);

  useEffect(() => {
    loadData(state.dateFilter); // Load data initially with the default date filter
  }, [state.dateFilter]);

  let loadData = async (dateFilter) => {
    let { data } = await studentCountryWiseFxn({}, dateFilter);
    setStudentData(data);
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      let finalPayload = payload && payload.length ? payload[0].payload : {};
      let total = finalPayload && finalPayload.total ? finalPayload.total : 0;
      let visa = finalPayload && finalPayload["visa"] ? finalPayload["visa"] : 0;
      let name = finalPayload && finalPayload["countryName"] ? finalPayload["countryName"] : 0;
      return (
        <div className="custom-tooltip">
          <p className="label">
            <strong>{name}</strong>
          </p>
          <p className="count">{`Total : ${total}`}</p>
          <p className="count">{`Approved : ${visa}`}</p>
        </div>
      );
    }

    return null;
  };

  const getWidth = () => {
    if ($("#barChart")) {
      setTimeout(() => {
        return $("#barChart").width();
      }, 500);
    }
  };

  return (
    <>
      <div className="col-sm-12 col-xl-7">
        <div className="card-box text-center p-4  border-dark">
          <div className="d-flex align-items-center justify-content-between mb-4 gap-3 flex-wrap">
            <h6 className="mb-0">Reports</h6>
            <ul key={state.dateFilter}>
              {filterList.map((item) => {
                return (
                  <li key={item}>
                    <input
                      type="radio"
                      onChange={({ target }) => {
                        _updateState({ dateFilter: target.value });
                        loadData(item);
                      }}
                      checked={state.dateFilter == item}
                      name="radio"
                      value={item}
                    />
                    <label>{item}</label>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="country-content reports">
            <div className="country-box">
              {studentData && studentData.length
                ? studentData.map((eachDoc) => {
                    let { flag } = eachDoc;
                    return (
                      <div>
                        <div className="box">
                          <span>
                            <img src={flag && flag.path ? flag.path : ""} alt="" />
                          </span>
                          <h6>{eachDoc.total} count</h6>
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>

          {studentData && studentData.length ? (
            <div className={"barChart"}>
              <ResponsiveContainer width={getWidth()} height={350}>
                <BarChart width={500} height={300} data={studentData}>
                  <CartesianGrid strokeDasharray="1 1" />
                  <XAxis dataKey="countryName" />
                  <YAxis />
                  <Tooltip content={<CustomTooltip />} />
                  <Legend layout="horizontal" />

                  <Bar dataKey="total" fill="#5BA5DF" barSize={5} />
                  <Bar dataKey="visa" fill="#50BF56" barSize={5} />
                </BarChart>
              </ResponsiveContainer>
              <ul className={"barLabel_ul"}>
                <li>
                  <div className={"colorBox"} style={{ background: "#5BA5DF" }}></div>
                  Total
                </li>
                <li>
                  <div className={"colorBox"} style={{ background: "#50BF56" }}></div>
                  Visa Approved
                </li>
              </ul>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Report;