import React, {useRef} from "react";
import {useDispatch} from "react-redux";
import {AddDocumentFxn, documentList} from "../../../Document/action";
import {Button, Card, Drawer, Form, notification, Table, Tooltip} from "../../../../components/Elements/appUtils";
import {GetEachFormFields} from "../../../../components/_utils/formUtils";
import {addCheckListCategoryFxn, checkListCategoryListFxn} from "../actions";

const CheckListCategory = (props) => {
    let {visible, onClose, refreshCategory} = props;
    let dispatch = useDispatch();
    let tableRef = useRef();
    const handleSubmit = (e) => {
        const {form} = props;
        e.preventDefault();
        form.validateFields(async (err, valData) => {
            if (!err) {
                let x = await dispatch(addCheckListCategoryFxn(valData));
                if (x && x.success) {
                    tableRef.current.reload();
                    refreshCategory()
                }
                props.form.setFieldsValue({
                    name: ""
                });
            }
            ;
        });
    };
    let documentInfo = {
        fields: [
            {
                key: "name",
                label: "Category Name",
                required: true,
                placeholder: "Category Name",
                type: "text",
            }
        ],
    };
    const columns = [
        {
            title: "Category Name",
            dataIndex: "name",
            key: "name",
            searchTextName: "name",
        },

        /* {
             title: "Action",
             dataIndex: "action",
             width: 130,
             render: (val, record) => {
                 return (
                     <>
                     </>
                 );
             },
         },*/
    ];
    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            let data = await dispatch(checkListCategoryListFxn({...params, regExFilters: ["name"],}));
            resolve(data);
        });
    };
    return (
        <>
            <Drawer visible={visible} onClose={onClose} title={'Checklist Category'}>
                <Card>
                    <Form onSubmit={handleSubmit}>
                        <div className={"row"}>
                            {documentInfo.fields.map((item, key) => {
                                return !item.hidden ? (
                                    <div className={"col-md-8 pb-3"} key={key}>
                                        <GetEachFormFields
                                            {...props.form}
                                            item={item}
                                        />
                                    </div>
                                ) : null;
                            })}
                            <div className={'col-md-4'} style={{marginTop: 30}}>
                                <Button type={"primary"} htmlType="submit">
                                    submit
                                </Button>
                            </div>
                        </div>

                    </Form>

                    <div className={'mt-4'}>
                        <Table
                            columns={columns}
                            apiRequest={apiRequest}
                            ref={tableRef}
                        />
                    </div>
                </Card>

            </Drawer>
        </>
    );
}
export default Form.create()(CheckListCategory);
