import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Card,
  Form,
  notification,
} from "../../../components/Elements/appUtils";
import { CurrencyJson } from "../../../components/_utils/appUtils";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import {
    ActivateCountryFxn,
    AddCountryFxn,
    loadPreviousCountryList,
} from "../action";
import StatusComponent from "../components/statusComponent";

const statusInIt = { status: "", index: null };
const AddCountry = (props) => {
  const { getFieldDecorator, setFieldsValue } = props.form;

  let dispatch = useDispatch();
  const [oldFlag, setOldFlag] = useState({});

  let [countryData, setCountryData] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [countryName, setCountryName] = useState("");
  const [flag, setFlag] = useState({});

  const loadCountryList = async () => {
    let x = await loadPreviousCountryList();
    setCountryList(x.data);
  };

  // const [status, setStatus] = useState([statusInIt]);
  // const events = {
  //     chooseCountry: (_id) => {
  //         let countryName = _.find(countryList, (item) => {
  //             return item._id == _id;
  //         });
  //         if (countryName) {
  //             props.form.setFieldsValue({
  //                 currencySymbol: countryName.currencySymbol,
  //                 currency: countryName.currency,
  //             });
  //             if (countryName.flag && countryName.flag.path) {
  //                 setOldFlag(countryName.flag);
  //             }
  //             setCountryName(countryName.countryName ? countryName.countryName : "");
  //         }
  //     },
  //     addMore: () => {
  //         setStatus([...status, statusInIt]);
  //     },
  //     deleteItem: (itemId) => {
  //         const updatedItems = status.filter((item, key) => key !== itemId);
  //         setStatus(updatedItems);
  //     },
  //     updateState: (data, index) => {
  //         let cloneStatus = _.clone(status);
  //         cloneStatus[index] = {...cloneStatus[index], ...data};
  //         setStatus(cloneStatus);
  //     },
  // };

  const [status, setStatus] = useState([{ status: "", index: 1 }]);

  const events = {
    chooseCountry: (_id) => {
      let countryName = _.find(countryList, (item) => {
        return item._id == _id;
      });
        console.log(countryName, "cocuucucucucucuu");
      if (countryName) {
        props.form.setFieldsValue({
          currencySymbol: countryName.currencySymbol,
          currency: countryName.currency,
        });
        if (countryName.flag && countryName.flag.path) {
          setOldFlag(countryName.flag);
        }
        setCountryName(countryName.countryName ? countryName.countryName : "");
      }
    },
    addMore: () => {
      const newIndex = status.length + 1;
      setStatus([...status, { status: "", index: newIndex }]);
    },

    deleteItem: (indexToDelete) => {
      const updatedItems = status
        .filter((item) => item.index !== indexToDelete)
        .map((item, index) => ({ ...item, index: index + 1 }));
      setStatus(updatedItems);
    },
    updateState: (data, index) => {
      setStatus((prevStatus) => {
        const cloneStatus = _.clone(prevStatus);
        cloneStatus[index] = { ...cloneStatus[index], ...data };
        return cloneStatus;
      });
    },
  };

  let user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;

  const getCurrencySymbol = (name) => {
    let symbol = _.find(CurrencyJson, (item) => {
      return item.name == name;
    });
    if (symbol) {
      setFieldsValue({ currencySymbol: symbol.value });
    }
  };

    const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFlag(file);
  };

  let inputTypes = {
    fields: [
      {
        key: "masterCountryId",
        label: "Country",
        required: true,
        placeholder: "Country",
        type: "select",
        options: countryList,
        keyAccessor: (x) => x._id,
        valueAccessor: (x) => `${x.countryName}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            masterCountryId: x,
          });
          events.chooseCountry(x);
        },
      },
      {
        key: "currencySymbol",
        label: "Currency Symbol",
        disable: true,
        disabled: true,
        span: "col-md-3",

        placeholder: "Currency Symbol",
      },
      {
        key: "currency",
        label: "Currency",
        span: "col-md-3",
        type: "select",
        required: true,
        options: CurrencyJson,
        showSearch: true,
        keyAccessor: (x) => x.name,
        valueAccessor: (x) => x.name,
        onChange: (x) => {
          props.form.setFieldsValue({
            currency: x,
          });
          getCurrencySymbol(x);
        },
      },

      {
        key: "monthlyLivingCost",
        label: "Monthly Living Cost",
        type: "number",
        placeholder: "Enter Monthly Living Cost",
      },

      {
        key: "visaRequirements",
        label: "Visa Requirement",
        placeholder: "Enter Visa Requirement",
        type: "text",
      },
      {
        key: "countryBenefits",
        label: "Country Benefits",
        placeholder: "Country Benefits",
        type: "text",
      },
      {
        key: "flag",
        label: "Flag",
        type: "file",
        onChange: handleFileChange,
        urlPath: oldFlag.path,
      },
    ],
  };

  useEffect(() => {
    loadCountryList();
  }, []);

  const handleSubmit = async (e) => {
    const { form } = props;
    e.preventDefault();
    form.validateFields(async (err, valData) => {
      if (!err) {
        let emptyStatus = _.find(status, (item) => {
          return !item.status;
        });
        if (emptyStatus) {
          notification.warning({
            message: "Enter status",
          });
          return;
        }
        valData.statusList = status;
        valData.countryName = countryName;
        let fd = new FormData();
        if (flag && flag.name) {
          fd.append("flag", flag);
        } else {
          valData.flag = oldFlag;
        }
        fd.append("obj", JSON.stringify(valData));
          let x = await dispatch(AddCountryFxn(fd));
        if (!x.error) {
          props.form.setFieldsValue({
            masterCountryId: "",
            monthlyLivingCost: "",
            visaRequirements: "",
            countryBenefits: "",
            currencySymbol: "",
            currency: "",
          });
          setStatus([statusInIt]);
        }
      } else {
        notification.warning({
          message: "Fill All Required Fields",
        });
      }
    });
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Card title={"Basic Information"}>
          <div className={"row"}>
            {inputTypes.fields.map((item, key) => {
              return !item.hidden ? (
                <div
                  className={`pt-3 ${item.span ? item.span : "col-md-6"}`}
                  key={key}
                >
                  <GetEachFormFields {...props.form} item={item} />
                </div>
              ) : null;
            })}
          </div>
        </Card>
        <Card title={"Country Status"}>
          <div className={"row"}>
            {status.map((data, index) => {
              return (
                <StatusComponent
                  data={data}
                  key={index}
                  index={index}
                  status={status}
                  updateState={events.updateState}
                  deleteItem={events.deleteItem}
                />
              );
            })}
          </div>
          <a className={"btn btn-link floatRight"} onClick={events.addMore}>
            Add More Status
          </a>
        </Card>
        <div style={{ marginTop: "20px" }}>
          <Button type={"primary"} htmlType="submit">
            submit
          </Button>
        </div>
      </Form>
    </>
  );
};

export default Form.create()(AddCountry);
