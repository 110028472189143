import React, {useEffect, useState, useRef} from "react";
import {useDispatch} from "react-redux";
import {
    Button,
    Card,
    Form,
    notification,
    Popconfirm,
    Modal,
    displayDate,
    DatePicker, Table,
} from "../../components/Elements/appUtils";
import {GetEachFormFields} from "../../components/_utils/formUtils";
import {
    addCommissionFxn,
    singleStudentFxn,
    singleStudentApplicationFxn,
    postUpdateCommission,
    singleStudentCommissionFxn, commissionListFxn,
} from "./action";
import {imgPath} from "../../components/_utils/_utils";

const AddCommission = (props) => {
    let tableRef = useRef();

    let dispatch = useDispatch();
    const [commissionRecord, setCommissionRecord] = useState();
    const [studentData, setStudentData] = useState();
    const [userId, setUserId] = useState();
    const [studentId, setStudentId] = useState();
    const [applicationData, setApplicationData] = useState({});
    const [currency, setCurrency] = useState("");
    const [total, setTotal] = useState(0);
    const [ids, setIds] = useState({
        studentId: "",
        applicationId: ""
    })
    const [data, setData] = useState({
        semesterName: "",
        tuitionFees: "",
        commission: "",
        invoiceDate: "",
    });
    useEffect(() => {
        singleStudentData();
    }, [window.location]);


    const singleStudentData = async () => {
        let {pathname} = window.location;
        let studentId = pathname.split('/').pop()
        let searchParams = new URLSearchParams(window.location.search);
        let id = searchParams.get("id");
        if (id) {
            loadStudent(studentId);
            loadApplication(id);
            setIds({
                studentId: studentId,
                applicationId: id
            })
        }
    };
    let loadApplication = async (id) => {
        let {data} = await dispatch(singleStudentApplicationFxn(id));
        setApplicationData(data)
        if (data && data.countryId && data.countryId.currency) {
            setCurrency(data.countryId.currency)
        }
    };

    let loadStudent = async (id) => {
        return new Promise(async () => {
            let {data} = await dispatch(singleStudentFxn(id));
            setStudentData(data);
        })
    };

  useEffect(() => {
    if (ids.studentId) {
            tableRef.current.reload()
        }
    }, [ids])

    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            let obj = {
                ...params,
                studentId: ids.studentId,
                applicationId: ids.applicationId,
                regExFilters: ["name"],
            }
            let data = await dispatch(commissionListFxn(obj))
            setTotal(data.total)
            resolve(data);
        });
    };


    const handleSubmit = (e) => {
        const {form} = props;
        e.preventDefault();

        form.validateFields(async (err, valData) => {
            if (!err) {
                const updatedData = {
                    studentId: ids.studentId,
                    countryId: applicationData.countryId && applicationData.countryId._id ? applicationData.countryId._id : "",
                    courseId: applicationData.courseId,
                    universityId: applicationData.universityId,
                    applicationId: applicationData._id,
                    tuitionFees: data.tuitionFees,
                    commission: data.commission,
                    invoiceDate: data.invoiceDate,
                    semesterName: data.semesterName,
                };

                let x = await dispatch(addCommissionFxn(updatedData));

                if (x && x.success) {
                    setData({
                        semesterName: "",
                        tuitionFees: "",
                        commission: "",
                        invoiceDate: "",
                    });

                    tableRef.current.reload()

                } else {
                    notification.warning({
                        message: `Something went wrong`,
                    });
                }

                // }
            }
        });
    };
    const [open, setOpen] = useState(false);

    const showModal = (e, id) => {
        e.preventDefault();
        setOpen(true);
        setUserId(id);
    };
    const onClose = () => {
        setOpen(false);
    };


    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setData((prevData) => ({...prevData, [name]: value}));
    };

    const handleSubmitCommission = (e) => {
        const {form} = props;
        e.preventDefault();
        form.validateFields(async (err, valData) => {
            if (!err) {
                valData.id = userId;

                let x = await dispatch(postUpdateCommission(valData));
                if (x && x.success) {
                    setOpen(false);
                    form.resetFields();
                    props.form.setFieldsValue({
                        semesterName: "",
                        tuitionFees: "",
                        commission: "",
                        invoiceDate: "",
                    });
                    singleStudentData();

                }
            } else {
                notification.warning({
                    message: `Something went wrong`,
                });
            }
        });
    };


    const handleSubmitCommissionApproved = async (id) => {
        let resp = await dispatch(postUpdateCommission({id, status: "Approved"}));
        if (resp) {
            tableRef.current.reload()
        }
    };
    let inputTypes = {
        fields: [
            {
                key: "semesterName",
                label: "Semester Name",
                required: true,

                type: "text",
            },
            {
                key: "tuitionFees",
                label: "Tuition Fees",
                required: true,

                type: "text",
            },
            {
                key: "commission",
                label: "Commission",
                required: true,

                type: "text",
            },
            {
                key: "invoiceDate",
                label: "Invoice Date",
                required: true,
                placeholder: "Invoice Date",
                type: "date",
                onChange: (x) => {
                    props.form.setFieldsValue({
                        invoiceDate: x,
                    });
                },
            },
        ],
    };

    let columns = [
        {
            title: "#",
            key: "index",
            dataIndex: "#",
            render: (item, record, index) => {
                return index + 1;
            }
        },
        {
            title: "Semester",
            key: "semesterName",
            dataIndex: "semesterName"
        },
        {
            title: "Tuition Fees",
            key: "tuitionFees",
            dataIndex: "tuitionFees"
        },
        {
            title: "Commission",
            key: "commission",
            dataIndex: "commission"
        },
        {
            title: "Invoice Date",
            key: "invoiceDate",
            dataIndex: "invoiceDate",
            render: (item) => {
                return item ? displayDate(item) : null
            }
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (item, record) => {
                return (
                    <>
                        {item == 'Pending' ? <span className="pending">Pending</span> : null}
                        {item == 'Approved' ? <span className="active">Approved</span> : null}
                    </>
                );
            },
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (item, record) => {
                let {status} = record;
                return (
                    <>
                        {status == 'Pending' ? <>
                            <button className="btn" type="button" title="Edit Commission"
                                    onClick={(e) => showModal(e, record._id)}>
                                <img
                                    src={imgPath.edit}
                                    className="btn-action"
                                    style={{width: "25px"}}
                                />
                            </button>
                            <Popconfirm
                                title={"Are your sure, you want to approve commission?"}
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() => {
                                    handleSubmitCommissionApproved(record._id);
                                }}>
                                <img src={imgPath.check}
                                     className="btn-action"
                                     style={{width: "25px"}}
                                />
                            </Popconfirm>
                        </> : null}

                    </>
                )
            }
        }
    ]


    return (
        <>
            <Card>
                <div className="profile-box student-single-profile card-box border-grey mb-4 p-4">
                    <div className="pic">
                        <div className="upload-img">
              <span>
                <img src="../app/img/user.svg" alt="" className="w-100 h-100"/>
              </span>
                            <div className="upload-btn">
                                <input type="file" name="" id=""/>
                                <i className="fa fa-pencil-alt"></i>
                            </div>
                        </div>
                        <div className="content-box">
                            <h4>
                                {studentData && studentData.name ? studentData.name : null}
                            </h4>
                            <p>
                                DOB :{" "}
                                {studentData && studentData.dateOfBirth
                                    ? displayDate(studentData.dateOfBirth)
                                    : null}
                            </p>
                            <span className="active">active</span>
                        </div>
                    </div>
                    <div className="detail">
                        <ul>
                            <li>
                                <span>Name:</span>{" "}
                                {studentData && studentData.name ? studentData.name : null}
                            </li>
                            <li>
                                <span>City:</span>{" "}
                                {studentData && studentData.cityName
                                    ? studentData.cityName
                                    : null}
                            </li>
                            <li>
                                <span>Mobile:</span>
                                {studentData && studentData.countryCode
                                    ? studentData.countryCode
                                    : null}
                                {studentData && studentData.mobile ? studentData.mobile : null}
                            </li>
                            <li>
                                <span>Pincode:</span>
                                {studentData && studentData.pincode
                                    ? studentData.pincode
                                    : null}
                            </li>
                            <li>
                                <span>Email:</span>
                                {studentData && studentData.email ? studentData.email : null}
                            </li>
                            <li>
                                <span>Address:</span>
                                {studentData && studentData.address
                                    ? studentData.address
                                    : null}
                            </li>
                            <li>
                                <span>Country:</span>
                                {studentData && studentData.countryName
                                    ? studentData.countryName
                                    : null}
                            </li>
                            <li>
                                <span>State:</span>
                                {studentData && studentData.stateName
                                    ? studentData.stateName
                                    : null}
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="card-box border-grey text-center py-4 table-data-box">
                    <div className="filter px-4 mb-4">
                        <Form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <input
                                    type="text"
                                    placeholder="Semester Name"
                                    name="semesterName"
                                    className="form-control"
                                    value={data.semesterName}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="form-group cad-box">
                                <span>{currency}</span>
                                <input
                                    type="text"
                                    placeholder="Tuition Fees"
                                    name="tuitionFees"
                                    className="form-control"
                                    value={data.tuitionFees}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="form-group cad-box">
                                <span>{currency}</span>

                                <input
                                    type="text"
                                    placeholder="Commission"
                                    name="commission"
                                    className="form-control"
                                    value={data.commission}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="form-group calendar-box">
                                <DatePicker value={data.invoiceDate} onChange={(date) => {
                                    setData((prevData) => {
                                        return {
                                            ...prevData,
                                            invoiceDate: date
                                        }
                                    })
                                }}/>
                            </div>

                            <button className="btn blue-btn" type="submit">
                                Submit
                            </button>
                        </Form>
                    </div>

                    <div className="table-responsive">
                        <Table apiRequest={apiRequest} columns={columns} ref={tableRef} pagination={false}/>


                        {/* <table className="table text-start align-middle table-hover mb-0 data-table ">
              <thead>
                <tr className="text-dark">
                  <th scope="col">S.No.</th>
                  <th scope="col">Semester</th>
                  <th scope="col">Semester Fees</th>
                  <th scope="col">Commission</th>
                  <th scope="col">invoice Date</th>
                  <th scope="col">Status</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {commissionRecord?.map((item, i) => {

                  return (
                    <>
                      <tr>
                        <td>{i + 1}</td>
                        <td>{item.semesterName}</td>
                        <td>{item.commission}</td>
                        <td>{item.tuitionFees}</td>
                        <td>{displayDate(item.invoiceDate)}</td>
                                            <td>{item.status}</td>
                                            <td>
                                                {item.status == 'Pending' ? <>
                                                    <button className="btn" type="button" title="Edit Commission"
                                                            onClick={(e) => showModal(e, item._id)}>
                                                        <img
                                                            src={imgPath.edit}
                                                            className="btn-action"
                                                            style={{width: "25px"}}
                                                        />
                                                    </button>
                                                    <Popconfirm
                                                        title={"Are your sure, you want to approve commission?"}
                                                        okText="Yes"
                                                        cancelText="No"
                                                        onConfirm={() => {
                                                            handleSubmitCommissionApproved(item._id);
                                                        }}>
                                                        <img src={imgPath.check}
                                                             className="btn-action"
                                                             style={{width: "25px"}}
                                                        />
                                                    </Popconfirm>
                                                </> : null}
                        </td>
                      </tr>
                    </>
                  );

                            })}
                            </tbody>
                        </table>*/}
                    </div>
                </div>
            </Card>

            <Modal visible={open}
                   onClose={onClose}
                   open={open}
                   width={"30%"}
                   title={"Edit Commission"}>
                <div>
                    <Form onSubmit={handleSubmitCommission}>
                        <div className={"row"}>
                            {inputTypes.fields.map((item, key) => {
                                return (
                                    <div className={`pt-3 ${item.span ? item.span : "col-md-6"}`}
                                         key={key}>
                                        <GetEachFormFields {...props.form} item={item}/>
                                    </div>
                                );
                            })}
                        </div>

                        <div className={'mt-4'}>
                            <Button type={"primary"} htmlType="submit">
                                submit
                            </Button>
                        </div>
                    </Form>
                </div>
            </Modal>
        </>
    );
};
export default Form.create()(AddCommission);
