import React, {useEffect, useRef, useState} from "react"
import {Button, Card, Drawer, Form, notification} from "../../../../components/Elements/appUtils";
import {addCheckListDocumentFxn, checkListCategoryListFxn} from "../actions";
import {useDispatch} from "react-redux";
import {loadCountryList} from "../../state/action";
import {GetEachFormFields} from "../../../../components/_utils/formUtils";
import CheckListCategory from "./checkListCategory";

let documentTypeList = ['Document', 'Video']
const UploadCheckListDocument = (props) => {
    let {visible, onClose, onSubmit} = props;
    let dispatch = useDispatch();
    let [countryList, setCountryList] = useState([])
    let [categoryList, setCategoryList] = useState([])
    let [document, setDocument] = useState({})
    let [visibleAddCategory, setVisibleAddCategory] = useState(false)

    useEffect(() => {
        loadCountry()
        loadCategory()
    }, [])
    const loadCountry = async () => {
        let x = await loadCountryList();
        setCountryList(x.data);
    }
    const loadCategory = async () => {
        let obj = {
            results: 1000,
            count: 1000
        }
        let x = await dispatch(checkListCategoryListFxn(obj));
        setCategoryList(x.data);
    }
    const handleChange = (e) => {
        const file = e.target.files[0];
        setDocument(file);
    };
    const handleSubmit = (e) => {
        const {form} = props;
        e.preventDefault();
        form.validateFields(async (err, valData) => {
            if (!err) {
                let fd = new FormData();
                fd.append("obj", JSON.stringify(valData));
                if (document && document.name) {
                    fd.append("document", document);
                }
                let x = await dispatch(addCheckListDocumentFxn(fd));
                if (x && x.success) {
                    props.form.setFieldsValue({
                        name: ""
                    })
                    onSubmit()
                }
            }
        });
    };
    let cateEvents = {
        showModal: () => {
            setVisibleAddCategory(true)
        },
        hideModal: () => {
            setVisibleAddCategory(false)
        },
        refreshCategory: () => {
            loadCategory()
        },
    }
    let documentInfo = {
        fields: [
            {
                key: "categoryId",
                label: "Category",
                required: true,
                placeholder: "Category",
                type: "select",
                options: categoryList,
                keyAccessor: (x) => x._id,
                valueAccessor: (x) => x.name,
                customBtn: (
                    <>
                        <a className={'action_btn_white'} onClick={cateEvents.showModal}>
                            <i className={'fa fa-plus'}></i>
                        </a>
                    </>
                ),
                onChange: (x) => {
                    props.form.setFieldsValue({
                        categoryId: x
                    });
                },
            },
            {
                key: "masterCountryId",
                label: "Country",
                required: true,
                placeholder: "Country",
                type: "select",
                options: countryList,
                keyAccessor: (x) => x._id,
                valueAccessor: (x) => `${x.countryName}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        masterCountryId: x
                    });
                },
            },
            {
                key: "name",
                label: "Name",
                required: true,
                placeholder: "Name",
                type: "text",
            },
            {
                key: "documentType",
                label: "Document Type",
                required: true,
                placeholder: "Document Type",
                type: "select",
                options: documentTypeList,
                span: "col-md-6",
                keyAccessor: (x) => x,
                valueAccessor: (x) => `${x}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        documentType: x
                    });
                },
            },
            {
                key: "document",
                label: "Document",
                placeholder: "Document",
                type: "file",
                span: "col-md-6",
                onChange: handleChange,
            },
        ],
    };


    return (
        <>
            <Drawer title={'Upload Document'} visible={visible} onClose={onClose} width={"40%"}>
                <Card>
                    <Form onSubmit={handleSubmit}>
                        <div className={"row"}>
                            {documentInfo.fields.map((item, key) => {
                                return !item.hidden ? (
                                    <div className={`pb-3 ${item.span ? item.span : "col-md-12"}`} key={key}>
                                        <GetEachFormFields
                                            {...props.form}
                                            item={item}
                                        />
                                    </div>
                                ) : null;
                            })}
                        </div>
                        <div className={'mt-3'}>
                            <Button type={"primary"} htmlType="submit">
                                submit
                            </Button>
                        </div>
                    </Form>
                </Card>
                {visibleAddCategory ?
                    <CheckListCategory title={'Category'}
                                       visible={visibleAddCategory}
                                       onClose={cateEvents.hideModal}
                                       refreshCategory={cateEvents.refreshCategory}/>
                    : null}
            </Drawer>


        </>
    )
}
export default Form.create()(UploadCheckListDocument);
