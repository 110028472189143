import { hideLoader, showLoader } from "../../actions/loader";
import notification from "../../components/Elements/Notification";
import {customAxios as axios, getToken} from "../../request";

import {changepasswordUrl} from './api'

export const changepasswordFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let { data } = await axios.post(changepasswordUrl(), valData, getToken());
    dispatch(hideLoader());
    if (data.error) {
      notification.error({
        message: data.message || "Error",
      });
    } else {
      notification.success({
        message: data.message || "Success",
      });
    }
    return data;
  };