import {hideLoader, showLoader} from "../../actions/loader";
import notification from "../../components/Elements/Notification";
import {customAxios as axios, getToken} from "../../request";
import {
    addCampusUrl,
    addPartnerUrl, deletePartnerUrl, partnerListUrl,
    representingUniversityListUrl,
} from "./api";

// export const representingUniversityList = async (valData) => {
//   let config = {
//     params: { ...valData },
//     ...(await getToken()),
//   };
//   let { data } = await axios.get(representingUniversityListUrl(), config);
//   if (data.error) {
//     notification.error({
//       message: data.message || "Error",
//     });
//   }

//   return data;
// };

export const AddPartnerFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());

    let {data} = await axios.post(addPartnerUrl(), valData, getToken());
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};

export const partnerListFxn = (filter) => async (dispatch) => {
    dispatch(showLoader());
    let config = {
        params: filter,
        ...await getToken()
    }
    let {data} = await axios.get(partnerListUrl(), config);
    dispatch(hideLoader());
    return data;
};

export const deletePartnerFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(deletePartnerUrl(), valData, getToken());
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};
