import React, {Suspense, useEffect, useState} from "react"
import GicCommissionMonthWise from "../../GicCommission/GicCommissionMonthWise";
import AddGicDrawer from "../../GicCommission/addGicDrawer";
import TransactionListGic from "../../GicCommission/transactionList";
import {GetRelatedUserFxn} from "../../Users/action";
import {gicCommissionCountFxn} from "../../GicCommission/action";
import {useDispatch} from "react-redux";
import {gicUrl} from "../../../settings";

const GicComponent = (props) => {
    let {currentUser} = props;
    let [gicVisible, setGicVisible] = useState(false);
    let [transactionListVisible, setTransactionListVisible] = useState(false);
    let [commissionAmount, setCommissionAmount] = useState(0);
    let [count, setCount] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        getGicCommissionCount();
        getRelatedUser();
    }, []);
    const getRelatedUser = async (id) => {
        let {data} = await dispatch(GetRelatedUserFxn(currentUser._id));
        if (data) {
            setTimeout(() => {
                setCommissionAmount(data.amount ? data.amount : 0);
            }, 200);
        }
    };
    const getGicCommissionCount = async () => {
        let data = await dispatch(gicCommissionCountFxn({}));
        setTimeout(() => {
            setCount(data);
        }, 200);
    };
    let events = {
        showGic: () => {
            setGicVisible(true);
        },
        hideGic: () => {
            setGicVisible(false);
        },
        showTransactionList: () => {
            setTransactionListVisible(true);
        },
        hideTransactionList: () => {
            setTransactionListVisible(false);
        },
    };
    return (
        <>
            <section className="gic-revenue border-dark shadow mt-4">
                <div className="card-box text-center p-4">
                    <div className="d-flex align-items-center justify-content-between mb-4 gap-3 flex-wrap">
                        <h6 className="mb-0">GIC Revenue</h6>
                        <div className={"d-flex"}>
                            {/* <a href={gicUrl} target={'_blank'} className="main-btn" style={{marginRight: 5}}>
                                open gic account
                            </a>*/}
                            <a className="main-btn" onClick={events.showGic}>
                                Add Gic Request
                            </a>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-7 col-12">
                            <Suspense fallback={<></>}>
                                <GicCommissionMonthWise/>
                            </Suspense>
                        </div>
                        <div className="col-lg-5 col-12">
                            <div className="list">
                                <div className="box">
                                    <span>
                                      <img src="../app/img/total-transaction.svg" alt=""/>
                                    </span>
                                    <h4>
                                        {count && count.totalEntryCount
                                            ? count.totalEntryCount
                                            : 0}
                                    </h4>
                                    <p className="m-0">GIC requested</p>
                                </div>
                                <div className="box">
                                                <span>
                                                  <img src="../app/img/total-invoices.svg" alt=""/>
                                                </span>
                                    <h4>
                                        {count && count.approvedEntryCount
                                            ? count.approvedEntryCount
                                            : 0}
                                    </h4>
                                    <p className="m-0">GIC approved</p>
                                </div>
                                {currentUser.userType == 'headBranch' ?
                                    <button
                                        type="button"
                                        className="btn"
                                        onClick={events.showTransactionList}>
                                        <span>$ {commissionAmount} CAD</span>
                                        Withdraw Commission
                                    </button> : null}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {gicVisible ? (
                <AddGicDrawer
                    visible={gicVisible} onClose={events.hideGic}
                    onSubmit={() => {
                        getGicCommissionCount();
                        events.hideGic()
                    }}></AddGicDrawer>
            ) : null}
            {transactionListVisible ? (
                <TransactionListGic
                    currentBalance={commissionAmount}
                    visible={transactionListVisible}
                    onClose={() => {
                        events.hideTransactionList()
                        getGicCommissionCount();
                        getRelatedUser();
                    }}
                    onSubmit={() => {

                    }}
                ></TransactionListGic>
            ) : null}
        </>
    )
}
export default GicComponent
