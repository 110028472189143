import React, {useEffect, useState} from "react";
import {InputBox} from "../../../components/Elements/appUtils";
import {qualificationOptions} from "../../../components/_utils/_utils";
import {
  addStudentFxn,
  boardStreamListFxn,
  getCampusListFxn,
  getCourseCampusListFxn,
  getIntakeListFxn,
  getRelatedRepresentativeUniversityListFxn,
} from "../actions";

let boardFields = ["XII", "X"];
// const EducationComponent = (props) => {
//   let {data, index, eduEvents, education, deleteItem} = props;
//   const [streamsList, setStreamList] = useState([]);
//   const [boardList, setBoardList] = useState([]);
//   const loadBoardList = async (params = {}) => {
//     params.type = "Board";

//     let x = await boardStreamListFxn(params);
//     if (x.data) {
//       setBoardList(x.data);
//     }
//   };

//   const loadStreamList = async (params = {}) => {
//     params.type = "Stream";

//     let x = await boardStreamListFxn(params);
//     if (x.data) {
//       setStreamList(x.data);
//     }
//   };

//   useEffect(() => {
//     loadBoardList();
//     loadStreamList();
//   }, []);
//   return (
//       <>
//         <div className="row new-border">
//           <div className="col-md-11">
//             <div className={"row"} key={index}>
//               <div className={"col-md-2"}>
//                 <InputBox title={"Qualification"}>
//                   <select
//                       className={"form-control"}
//                       value={data.qualification}
//                       onChange={({target}) => {
//                         eduEvents.updateState(
//                             {qualification: target.value},
//                             index
//                         );
//                       }}
//                   >
//                     <option value={""}>Choose</option>
//                     {qualificationOptions.map((item) => {
//                       return (
//                           <option key={item.option} value={item.option}>
//                             {item.option}
//                           </option>
//                       );
//                     })}
//                   </select>
//                 </InputBox>
//               </div>

//               {data.qualification == "Bachelor Degree" ||
//               data.qualification == "Post Graduation" ||
//               data.qualification == "Diploma" ? (
//                   <div className={"col-md-2"} name="universityName">
//                     <InputBox title={"University"}>
//                       <input
//                           placeholder=" Enter University"
//                           className={"form-control"}
//                           onChange={({target}) => {
//                             eduEvents.updateState(
//                                 {universityName: target.value},
//                                 index
//                             );
//                           }}
//                           value={data.universityName}
//                       />
//                     </InputBox>
//                   </div>
//               ) : null}

//               {data.qualification == "Bachelor Degree" ||
//               data.qualification == "Post Graduation" ||
//               data.qualification == "Diploma" ? (
//                   <div className={"col-md-2"} name="courseName">
//                     <InputBox title={"Course"}>
//                       <input
//                           placeholder="Please enter your Course"
//                           className={"form-control"}
//                           onChange={({target}) => {
//                             eduEvents.updateState(
//                                 {courseName: target.value},
//                                 index
//                             );
//                           }}
//                           value={data.courseName}
//                       />
//                     </InputBox>
//                   </div>
//               ) : null}

//               {data.qualification && boardFields.includes(data.qualification) ? (
//                   <div className={"col-md-2"} name="board">
//                     <InputBox title={"Board"}>
//                       <select
//                           className={"form-control"}
//                           placeholder="Board"
//                           onChange={({target}) => {
//                             eduEvents.updateState({board: target.value}, index);
//                           }}
//                           value={data.board}
//                       >
//                         <option value={""}>Choose</option>

//                         {boardList.map((item) => {
//                           return (
//                               <option key={item.name} value={item.name}>
//                                 {item.name}
//                               </option>
//                           );
//                         })}
//                       </select>
//                     </InputBox>
//                   </div>
//               ) : null}

//               {data.qualification == "X" || data.qualification == "XII" ? (
//                   <div className={"col-md-2"} name="schoolName">
//                     <InputBox title={"School Name"}>
//                       <input
//                           className={"form-control"}
//                           placeholder="School Name"
//                           onChange={({target}) => {
//                             eduEvents.updateState(
//                                 {schoolName: target.value},
//                                 index
//                             );
//                           }}
//                           value={data.schoolName}
//                       />
//                     </InputBox>
//                   </div>
//               ) : null}

//               <div className={"col-md-2"} name="passingYear">
//                 <InputBox title={"Passing Year"}>
//                   <input
//                       className={"form-control"}
//                       placeholder="Passing Year"
//                       onChange={({target}) => {
//                         eduEvents.updateState({passingYear: target.value}, index);
//                       }}
//                       value={data.passingYear}
//                   />
//                 </InputBox>
//               </div>

//               <div className={"col-md-2"} name="Percentage">
//                 <InputBox title={"Percentage"}>
//                   <input
//                       placeholder="Please enter your percentage"
//                       className={"form-control"}
//                       onChange={({target}) => {
//                         eduEvents.updateState({percentage: target.value}, index);
//                       }}
//                       value={data.percentage}
//                   />
//                 </InputBox>
//               </div>

//               {data.qualification == "XII" ? (
//                   <div className={"col-md-2 "} name="math">
//                     <InputBox title={"Stream"}>
//                       <select
//                           className={"form-control"}
//                           placeholder="Stream"
//                           onChange={({target}) => {
//                             eduEvents.updateState({stream: target.value}, index);
//                           }}
//                           value={data.stream}
//                       >
//                         <option value={""}>Choose</option>

//                         {streamsList.map((item) => {
//                           return (
//                               <option key={item.name} value={item.name}>
//                                 {item.name}
//                               </option>
//                           );
//                         })}
//                       </select>
//                     </InputBox>
//                   </div>
//               ) : null}
//             </div>
//           </div>
//           <div className="col-md-1 div-icon">
//             {index !== 0 ? (
//                 <i
//                     class="fa fa-trash new-icon"
//                     aria-hidden="true"
//                     onClick={() => deleteItem(index)}
//                 ></i>
//             ) : null}
//           </div>
//         </div>
//       </>
//   );
// };
const EducationComponent = (props) => {
  let {data, index, eduEvents, education, deleteItem} = props;
  let {qualification} = data;
  const [streamsList, setStreamList] = useState([]);
  const [boardList, setBoardList] = useState([]);
  const loadBoardList = async (params = {}) => {
      params.type = "Board";

      let x = await boardStreamListFxn(params);
      if (x.data) {
          setBoardList(x.data);
      }
  };

  const loadStreamList = async (params = {}) => {
      params.type = "Stream";
      let x = await boardStreamListFxn(params);
      if (x.data) {
          setStreamList(x.data);
      }
  };

  useEffect(() => {
      loadBoardList();
      loadStreamList();
  }, []);
  return (
      <>
          <div className={index > 0 ? "repeat-sec" : ""}>
              <div className="row">
                  <div className="col-lg-4 col-12">
                      <InputBox type={"select"}>
                          <select
                              className={"form-select form-control"}
                              value={data.qualification}
                              onChange={({target}) => {
                                  eduEvents.updateState({qualification: target.value}, index);
                              }}
                          >
                              <option value={""}>Choose Qualification</option>
                              {qualificationOptions.map((item) => {
                                  return (
                                      <option key={item.option} value={item.option}>
                                          {item.option}
                                      </option>
                                  );
                              })}
                          </select>
                      </InputBox>
                  </div>
                  {qualification == "Bachelor Degree" ||
                  qualification == "Post Graduation" ||
                  qualification == "Diploma" ? (
                      <>
                          <div className="col-lg-4 col-12">
                              <InputBox title={"University"}>
                                  <input
                                      placeholder=" Enter University"
                                      className={"form-control"}
                                      onChange={({target}) => {
                                          eduEvents.updateState(
                                              {university: target.value},
                                              index
                                          );
                                      }}
                                      value={data.university}
                                  />
                              </InputBox>
                          </div>
                          <div className="col-lg-4 col-12">
                              <InputBox title={"Course"}>
                                  <input
                                      placeholder="Please enter your Course"
                                      className={"form-control"}
                                      onChange={({target}) => {
                                          eduEvents.updateState(
                                              {courseName: target.value},
                                              index
                                          );
                                      }}
                                      value={data.courseName}
                                  />
                              </InputBox>
                          </div>
                      </>
                  ) : null}

                  {data.qualification && boardFields.includes(data.qualification) ? (
                      <>
                          <div className="col-lg-4 col-12">
                              {/*title={"Board"}*/}
                              <InputBox type={"select"}>
                                  <select
                                      className={"form-control"}
                                      placeholder="Board"
                                      onChange={({target}) => {
                                          eduEvents.updateState({board: target.value}, index);
                                      }}
                                      value={data.board}
                                  >
                                      <option value={""}>Choose Board</option>

                                      {boardList.map((item) => {
                                          return (
                                              <option key={item.name} value={item.name}>
                                                  {item.name}
                                              </option>
                                          );
                                      })}
                                  </select>
                              </InputBox>
                          </div>
                      </>
                  ) : null}

                  {data.qualification == "X" || data.qualification == "XII" ? (
                      <div className="col-lg-4 col-12">
                          <InputBox title={"School Name"}>
                              <input
                                  className={"form-control"}
                                  placeholder="School Name"
                                  onChange={({target}) => {
                                      eduEvents.updateState({schoolName: target.value}, index);
                                  }}
                                  value={data.schoolName}
                              />
                          </InputBox>
                      </div>
                  ) : null}

                  <div className="col-lg-4 col-12">
                      <InputBox title={"Passing Year"}>
                          <input
                              className={"form-control"}
                              placeholder="Passing Year"
                              onChange={({target}) => {
                                  eduEvents.updateState({passingYear: target.value}, index);
                              }}
                              value={data.passingYear}
                          />
                      </InputBox>
                  </div>

                  <div className={"col-lg-4 col-12"}>
                      <InputBox title={"Percentage"}>
                          <input
                              placeholder="Please enter your percentage"
                              className={"form-control"}
                              onChange={({target}) => {
                                  eduEvents.updateState({percentage: target.value}, index);
                              }}
                              value={data.percentage}
                          />
                      </InputBox>
                  </div>

                  {data.qualification == "XII" ? (
                      <div className={"col-lg-4 col-12"}>
                          {/*title={"Stream"}*/}
                          <InputBox type={"select"}>
                              <select
                                  className={"form-control"}
                                  placeholder="Stream"
                                  onChange={({target}) => {
                                      eduEvents.updateState({stream: target.value}, index);
                                  }}
                                  value={data.stream}
                              >
                                  <option value={""}>Choose Stream</option>

                                  {streamsList.map((item) => {
                                      return (
                                          <option key={item.name} value={item.name}>
                                              {item.name}
                                          </option>
                                      );
                                  })}
                              </select>
                          </InputBox>
                      </div>
                  ) : null}
              </div>

              {index !== 0 ? (
                  <button className="btn delete" onClick={() => deleteItem(index)}>
                      <i className="fa fa-times-circle"></i>
                  </button>
              ) : null}
          </div>
      </>
  );
};
export default EducationComponent;
