import {hideLoader, showLoader} from "../../actions/loader";
import notification from "../../components/Elements/Notification";
import {customAxios as axios, getToken} from "../../request";
import {
    addCommentUrl,
    addNoteUrl,
    applicationListUrl,
    getSingleApplicationUrl,
} from "./api";

export const getSingleApplicationFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let config = {
        params: valData,

        ...(await getToken()),
    };
    let {data} = await axios.get(getSingleApplicationUrl(), config);
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    }

    return data;
};

export const applicationListFxn = async (valData) => {
  let config = {
      params: {...valData},
      ...(await getToken()),
  };
  let data = await axios.get(applicationListUrl(), config);
  return data.data;
};

export const addNoteFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(addNoteUrl(), valData, getToken());
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};

export const addCommentFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(addCommentUrl(), valData, getToken());
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};
