import _ from "lodash";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    Legend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import {gicCommissionListMonthWiseFxn} from "./action";

const $ = window.$;
export const MonthByIndexBackup = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
};
export const MonthByIndex = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
};

const colors = [
    "#49457B",
    "#7DB3FF",
    "#FFC107",
    "#FF7C78",
    "#FED3D0",
    "#6F76D9",
    "#9ADFB4",
    "#2E7987",
    "#138D75",
    "#2E4053",
    "#148F77",
    "#3498DB",
    "#239B56",
    "#212F3C",
    "#21618C",
    "#5B2C6F",
    "#512E5F",
];
const GicCommissionMonthWise = (props) => {
    let [dataList, setDataList] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        loadDataFxn();
    }, []);

    let loadDataFxn = async () => {
        let {data} = await dispatch(gicCommissionListMonthWiseFxn({}));
        let arrList = [];
        if (data && data.length) {
            _.each(data, (item) => {
                let {month, year, counts} = item;
                arrList.push({
                    name: `${MonthByIndex[month]}, ${year}`,
                    ...item
                });
            });
        }
        setDataList(arrList.reverse());
    };

    const getWidth = () => {
        if ($("#studentCountChart")) {
            setTimeout(() => {
                return $("#studentCountChart").width();
            }, 500);
        }
    };
    const CustomTooltip = ({active, payload, label}) => {
        if (active) {
            let finalPayload = payload && payload.length ? payload[0].payload : {}
            let total = finalPayload && finalPayload.total ? finalPayload.total : 0
            let approved = finalPayload && finalPayload['Approved'] ? finalPayload['Approved'] : 0
            let name = finalPayload && finalPayload['name'] ? finalPayload['name'] : 0
            return (
                <div className="custom-tooltip">
                    <p className="label"><strong>{name}</strong></p>
                    <p className="count">{`Total : ${total}`}</p>
                    <p className="count">{`Approved : ${approved}`}</p>
                </div>
            );
        }

        return null;
    };

    return dataList && dataList.length ? (
        <div className={'barChart'}>
            <ResponsiveContainer width={getWidth()} height={350}>
                <BarChart
                    width={500}
                    height={300}
                    data={dataList}
                >
                    <CartesianGrid strokeDasharray='1 1'/>
                    <XAxis dataKey="label"/>
                    <YAxis/>
                    <Tooltip content={<CustomTooltip/>}/>
                    <Legend layout='horizontal'/>

                    <Bar dataKey="total" fill="#4C47A5" barSize={5}/>
                    <Bar dataKey="Approved" fill="#50BF56" barSize={5}/>
                </BarChart>
            </ResponsiveContainer>
            <ul className={'barLabel_ul'}>
                <li>
                    <div className={'colorBox'} style={{background: "#4C47A5"}}></div>
                    Total
                </li>
                <li>
                    <div className={'colorBox'} style={{background: "#50BF56"}}></div>
                    Approved
                </li>
            </ul>
        </div>
    ) : null;
};
export default GicCommissionMonthWise;
