import axios from "axios";
import React, {useEffect, useRef, useState} from "react";
import ReactDragListView from "react-drag-listview";
import {useDispatch} from "react-redux";
import {
    Button,
    Modal,
    notification,
} from "../../../components/Elements/appUtils";

import {moveStatusToCountryFxn, singleCountryStatusListFxn} from "../action";

const RearrangeList = (props) => {
    let {visible, countryId, onClose} = props;
    let dispatch = useDispatch();
    const [statusList, setStatusList] = useState([]);

    useEffect(() => {
        getStatusList(countryId);
    }, [countryId]);
    const getStatusList = async (id) => {
        if (id) {
            let {data} = await singleCountryStatusListFxn({countryId: id});
            setStatusList(data);
        }
    };

    const [isDragEnabled, setIsDragEnabled] = useState(false);
    const [fromIndex, setFromIndex] = useState(null);
    const [toIndex, setToIndex] = useState(null);

    const onDragEnd = (fromIndex, toIndex) => {
        console.log(fromIndex, toIndex, "frormmrmrmrmrmrm");
        setFromIndex(fromIndex);
        setToIndex(toIndex);
        const updatedList = [...statusList];
        const [draggedItem] = updatedList.splice(fromIndex, 1);
        updatedList.splice(toIndex, 0, draggedItem);
        setStatusList(updatedList);
    };

    const handleRearrangeSubmit = async () => {
        setIsDragEnabled(true);

        if (fromIndex === null || toIndex === null) {
            notification.warning({
                message: "Please select value to rearrange",
            });
            return;
        }
        let obj = {
            countryId: countryId,
            fromIndex: fromIndex,
            toIndex: toIndex,
        };
        let {success, data} = await dispatch(moveStatusToCountryFxn(obj));
        if (success) {
            setStatusList(data.statusList);
            setIsDragEnabled(false);
            setFromIndex(null);
        }
    };

    return (
        <Modal
            visible={visible}
            onClose={onClose}
            width={"80%"}
            // height={"100%"}
            title={"Re-arrange Status"}
        >
            <p>You can drag and drop to rearrange them</p>
            <div>
                <ReactDragListView
                    nodeSelector=".draggable"
                    onDragEnd={onDragEnd}
                    enabled={isDragEnabled}
                >
                    <table className={"table table-bordered"}>
                        <thead>
                        <tr>
                            <th>Serial No</th>
                            <th>Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        {statusList?.map((item, index) => {
                            let {statusList} = item;
                            return statusList && statusList.active ? (
                                <tr key={index} className="draggable">
                                    <td>{index + 1}</td>
                                    <td style={{width: "60%"}}>
                                        {statusList ? statusList.status : item.status}
                                    </td>
                                </tr>
                            ) : (
                                ""
                            );
                        })}
                        </tbody>
                        {/* <table border="1">
           <tbody>
            {statusList.map((item, index) => (
              <tr key={index} className="draggable">
                <td>{item.statusList?.status}</td>
                <td>
                  <button>x</button>
                </td>
              </tr>
            ))}
          </tbody> */}
                    </table>
                </ReactDragListView>

                <></>

                <div style={{textAlign: "right"}}>
                    <Button
                        className={"btn btn-sm"}
                        onClick={handleRearrangeSubmit}
                        disabled={isDragEnabled}
                    >
                        Submit
                    </Button>
                </div>
            </div>
        </Modal>
    );
};
export default RearrangeList;
