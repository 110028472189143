import React, {useRef} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Card, HyperLink, Table} from "../../../components/Elements/appUtils";
import {userListFxn} from "../action";
import {imgPath} from "../../../components/_utils/_utils";

let {TableCard} = Card;
const ManagerList = () => {
    let navigate = useNavigate();
    let dispatch = useDispatch();
    let tableRef = useRef();
    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            let data = await dispatch(
                userListFxn({
                    ...params,
                    userType: "manager",
                    regExFilters: ["name"],
                })
            );
            resolve(data);
        });
    };

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "Mobile No",
            dataIndex: "mobile",
            key: "mobile",
        },

        {
            title: "Address",
            dataIndex: "address",
            key: "address",
        },
        {
            title: "Action",
            dataIndex: "action",
            width: 190,
            render: (val, record) => {
                return (
                    <>
                        <button className={'btn'} onClick={() => {
                            navigate(`/edit-manager?_id=${record._id}`)
                        }}>
                            <img src={imgPath.edit}/>
                        </button>
                    </>
                );
            },
        },
    ];

    return (
        <>
            {/* <Card title={"Counsellor List"} rightContent={(
                <HyperLink className={'btn btn-primary btn-sm'} link={'/add-counsellor'}>Add Counsellor</HyperLink>
            )}> */}
            <TableCard
                title={"Manager List"}
                extraBtn={[
                    {
                        name: "Add Manager",
                        action: () => {
                            navigate("/add-manager");
                        },
                    },
                ]}
            >
                {" "}
                <Table apiRequest={apiRequest} columns={columns} ref={tableRef}/>
            </TableCard>
            {/* </Card> */}
        </>
    );
};

export default ManagerList;
