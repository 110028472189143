import {apiUrl} from "../../../../settings";

export const getRelatedStatesUrl = (countryId) => {
  return apiUrl + "/getRelatedMasterStates/" + countryId;
};

export const addRelatedCityUrl = () => {
  return `${apiUrl}/addMasterCity`;
};
export const CityListUrl = () => {
  return `${apiUrl}/masterCitiesList`;
};

export const getCityUrl = (cityId) => {
  console.log(cityId);
  return apiUrl + "/getSingleMasterCity/" + cityId;
};

export const postCityUrl = () => {
  return `${apiUrl}/updateMasterCity`;
};
