import React, {useState, useEffect} from "react";
import {useDispatch} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {
  Button,
  Form,
  notification,
} from "../../../components/Elements/appUtils";
import {loginFxn, otpFxn, otpResendFxn} from "../actions";
import { useLocation } from "react-router-dom";
import Layout from "../layout";


const Login = (props) => {
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [branchId, setBranchId] = useState('');
  const [showOTP, setShowOTP] = useState(false);
  const [otp, setOtp] = useState();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [otpValues, setOtpValues] = useState(["", "", "", "", ""]);

  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const handleInputChange = (e) => {
    const {name, value} = e.target;
    setData((prevData) => ({...prevData, [name]: value}));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const {form} = props;
    const {email, password} = data;
    if (!email || !password) {
      notification.warning({
        message: "Please enter both email and password.",
      });
      return;
    }
    let x = await dispatch(
        otpFxn({
          email: data.email,
          password: data.password,
        })
    );
    if (x && x.success) {
      setData({
        email: "",
        password: "",
      });
      setEmail(x.email);
      setPassword(x.password);
      setOtp(x.otp);
      setShowOTP(true);
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const branchIdParam = searchParams.get('branchId');
    console.log(branchIdParam, "branchIdParam");
    if (branchIdParam) {
      setBranchId(branchIdParam);
    }
  }, [location.search]);

  const handleOtpChange = (index, value) => {
    if (!isNaN(value) && value.length < 2) {
      const updatedOtp = [...otpValues];
      updatedOtp[index] = value;
      setOtpValues(updatedOtp);

      if (value && index < otpValues.length - 1) {
        const nextInput = document.getElementById(`otp-input-${index + 1}`);
        if (nextInput) {
          nextInput.focus();
        }
      } else if (!value && index > 0) {
        const prevInput = document.getElementById(`otp-input-${index - 1}`);
        if (prevInput) {
          prevInput.focus();
        }
      }
    }
  };

  const resendCode = async (e) => {
    e.preventDefault();

    let x = await dispatch(
        otpResendFxn({
          email: email,
        })
    );
    if (x && x.success) {
      console.log(x);
    } else {
      notification.warning({
        message: `Otp  not sent`,
      });
    }
  };

  const handleOtpSubmit = (e) => {
    const {form} = props;

    e.preventDefault();
    form.validateFields(async (err, values) => {
      const otp = otpValues.join("");
      if (otp.trim() === "") {
        notification.error({
          message: "Please enter the OTP.",
        });
        return;
      }
      values.otp = otp;
      values.email = email;
      let x = await dispatch(loginFxn(values));
      console.log(x, "dddddddddddddd");

      if (!x.error) {
        console.log(x, "dddddddddddddd");
        localStorage.setItem("token", x.token);
        localStorage.setItem("user", JSON.stringify(x.user));
        dispatch({
          type: "SET_CURRENT_USER",
          user: x.user,
        });

        setTimeout(() => {
          window.location.href = "/dashboard";
        }, 300);
      } else {
        notification.error({
          message: x.message,
        });
        // window.location.href = '/login';
      }
    });
  };

  const goToRegister = () => {
    navigate("/signup");
  };
  const goToForgotPassword = () => {
    navigate("/forgot-password");
  };

  return (
      <>
        <Layout formName={"login"}>
          {!showOTP ? (
              <>
                <div className="content-box">
                  <img src="../app/img/logo.svg" className="logo" alt="logo here"/>

                  <div className="head">
                    <h4>welcome back!</h4>
                    <p>
                      Don't have an account?{" "}
                      <a onClick={goToRegister}>Create a new account</a> now, It
                      takes less than a minute.
                    </p>
                  </div>

                  <div className="field">
                    <Form onSubmit={handleSubmit}>
                      <div className="form-floating mb-4">
                      <input
                      type="email"
                      className="form-control"
                      name="email"
                      value={data.email}
                      onChange={handleInputChange}
                      id="floatingInput"
                      placeholder="name@example.com"
                      autoComplete="off" 
                  />

                        <label htmlFor="floatingInput">Email address</label>
                      </div>
                      <div className="form-floating mb-5">
                        <input
                            type="password"
                            className="form-control"
                            name="password"
                            value={data.password}
                            onChange={handleInputChange}
                            id="floatingPassword"
                            placeholder="Password"
                            autoComplete="off" 

                        />
                        <label htmlFor="floatingPassword">Password</label>
                      </div>
                      <Button className="btn black-btn mt-3" htmlType="submit">
                        login now
                      </Button>
                      <a className="btn main-btn" onClick={goToRegister}>
                        CREATE NEW ACCOUNT
                      </a>
                      <div className="forgot">
                        forgot password{" "}
                        <a href="/forget-password" onClick={goToForgotPassword}>
                          click here
                        </a>
                      </div>
                    </Form>
                  </div>
                </div>
              </>
          ) : (
              <>
                <div className="content-box">
                  <img src="../app/img/logo.svg" className="logo" alt="logo here"/>

                  <div className="head">
                    <h4>OTP Verification</h4>
                    <p>
                      Enter OTP Code sent to <span>{data.email}</span>
                    </p>
                  </div>

                  <div className="field">
                    <Form onSubmit={handleOtpSubmit}>
                      <div id="inputs" className="inputs">
                        {/* <input
                                                            type="text"
                                                            value={otpValues.join('')}
                                                            onChange={handleOtpChange}
                                                            placeholder="Enter OTP"
                                                            maxLength={5}
                                                        /> */}
                        {otpValues.map((digit, index) => (
                            <input
                                key={index}
                                type="text"
                                maxLength="1"
                                value={digit}
                                onChange={(e) => handleOtpChange(index, e.target.value)}
                                id={`otp-input-${index}`}
                                className="input"
                            />
                        ))}
                      </div>
                      <div className="otp">
                        Didn't receive OTP code?
                        {/* <a href="#">Resend Code</a> */}
                        <a onClick={resendCode}>Resend Code</a>
                      </div>

                      <button type="submit" className="btn main-btn m-0">
                        Verify & Proceed
                      </button>
                    </Form>
                  </div>
                </div>
              </>
          )}
        </Layout>
      </>
  );
};
export default Form.create()(Login);
