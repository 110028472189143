import React, { useRef, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";
import {
  Card,
  HyperLink,
  Popconfirm,
  Table,
  Modal,
  InputBox,
  notification,
  Form,
  Button,
  displayDate
} from "../../components/Elements/appUtils";
import { imgPath } from "../../components/_utils/_utils";

import { useNavigate } from "react-router-dom";

import { UserListFxn,EmployeeListFxn } from "../Users/action";
const EmployeeList = (props) => {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const { getFieldDecorator, getFieldValue, setFieldsValue } = props.form;
  const [openUserModalVisible, setOpenUserModalVisible] = useState(false);
  const [openUserData, setOpenUserData] = useState(null);

  const [AllData, setAllData] = useState([]);
  let {TableCard} = Card;
  let tableRef = useRef();
  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      
      let data = await dispatch(
        EmployeeListFxn({
          ...params,
          //   userType: "branchUser",
          regExFilters: ["name","mobile"],
        })
      );
      resolve(data);
      setAllData(data.data)
      console.log(data.data);
    });
  };
  let user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;
  useEffect(() => {
    // events.setCountryCode(user.countryName);
    setFieldsValue({ countryCode: user.countryCode });
    apiRequest()
  }, []);

  let openLink = (link) => {
    navigate(link);
  };

  const handleOpenUser = (userData) => {
    console.log(userData, "userData");
    setOpenUserData(userData);
    setOpenUserModalVisible(true);
  };

  const handleModalCancel = () => {
    setOpenUserModalVisible(false);
    setOpenUserData(null);
  };

  const columns = [
 
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record, index) => {
        return <div>{record.name}</div>;
      },
     
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text, record, index) => {
        return <div>{record.email}</div>;
      },
      
    },
    {
      title: "Designation",
      dataIndex: "designation",
      key: "designation",
      render: (text, record, index) => {
        return <div>{record.designation}</div>;
      },
    
    },
   
 
    {
      title: "Date Of Birth",
      dataIndex: "dateOfBirth",
      key: "dateOfBirth",
      searchTextName: "dateOfBirth",
      render: (text, record, index) => {
        return <div>{displayDate(record.dateOfBirth)}</div>;
      },
      filterRegex: true,
      
    },
    {
      title: "Mobile No",
      dataIndex: "mobile",
      key: "mobile",
      searchTextName: "mobile",
      render: (text, record, index) => {
        return <div>{record.mobile}</div>;
      },
      
      filterRegex: true,
    },
   
    {
      title: "Assigned User",
      dataIndex: "assignUser",
      key: "assignUser",
      render: (text, record, index) => {
       
        if (Array.isArray(record.assignUser) && record.assignUser.length > 0) {
          const assignedUsers = record.assignUser.map((user, idx) => (
            <div key={idx}>{user.userType}</div>
          ));
          return <div>{assignedUsers}</div>;
        }
        return <div>No Assigned Users</div>; 
      },
      
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    
      render: (v, item) => {
        
          return (
              <>
                 

                  <button
                      className="btn"
                      type="button"
                      title="View Profile Details"
                      onClick={() => {
                          openLink(`/single-emp-profile?_id=${item._id}`);
                      }}>
                      <img src={imgPath.view} alt=""/>
                  </button>


            
                      <button
                          className="btn"
                          type="button"
                          title="Edit Profile"
                          onClick={() => {
                              openLink(`/edit-single-emp?_id=${item._id}`);
                          }}>
                          <img src={imgPath.edit} alt=""/>
                      </button>

                     

                    


                  
              </>
          );
      },
  },
    
  ];

  return (
    <>
    <TableCard
                 title={`Employee List`}
                extraBtn={[
                    {
                        name: "Add Employee",
                        action: () => {
                            navigate("/add-employee");
                        },
                    },
                ]}
            >
    
        <Table apiRequest={apiRequest} columns={columns} ref={tableRef} />
   
    
      </TableCard>
    
    </>
  );
};

export default Form.create()(EmployeeList);