import { hideLoader, showLoader } from "../../actions/loader";
import notification from "../../components/Elements/Notification";
import Request, { customAxios as axios, getToken } from "../../request.js";
import {getSingleUserUrl} from "../Users/views/api/index.js";
import {
    AllLeaveUrl,
    addDocumentsEmpUrl,
    addEmployeeUrl,
    addLeaveUrl,
    boardStreamListUrl,
    deleteEmpDocumentUrl,
    deleteSingleEmpDocumentUrl,
    getSingleEmpDocumentsUrl,
    getSingleEmployeeUrl,
    getSingleUrl,
    postUpdateLeaveUrl,
    updateSingleUrl,
    userUrl,
} from "./apis";
export const AddHrFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  let { data } = await axios.post(userUrl(), valData, getToken());
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  } else {
    notification.success({
      message: data.message || "Success",
    });
  }
  return data;
};

export const branchListFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  let config = {
    params: { ...valData },
    ...(await getToken()),
  };
  let { data } = await axios.get(userUrl(), config);
  dispatch(hideLoader());

  return data;
};

export const addEmployeeFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
  let { data } = await axios.post(addEmployeeUrl(), valData, getToken());
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  } else {
    notification.success({
      message: data.message || "Success",
    });
  }
  return data;
};

export const boardStreamListFxn = async (valData) => {
  let config = {
    params: { ...valData },
    ...(await getToken()),
  };
  let { data } = await axios.get(boardStreamListUrl(), config);
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  }
  return data;
};
export const GetRelatedUserFxn = (id) => async (dispatch) => {
  dispatch(showLoader());
    let config = {
        ...(await getToken()),
    };
    let {data} = await axios.get(getSingleUserUrl(id), config);
  console.log(data);
  dispatch(hideLoader());
  if (data.error) {
      notification.error({
          message: data.message || "Error",
      });
  }

  return data;
};
export const getSingleEmpFxn = (id) => async (dispatch) => {
  dispatch(showLoader());
    let config = {
        ...(await getToken()),
    };
    let {data} = await axios.get(getSingleEmployeeUrl(id), config);
  dispatch(hideLoader());
  if (data.error) {
      notification.error({
          message: data.message || "Error",
      });
  }

  return data;
};

export const updateSingleFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
    let {data} = await axios.post(updateSingleUrl(), valData, getToken());
  dispatch(hideLoader());
  if (data.error) {
      notification.error({
          message: data.message || "Error",
      });
  } else {
      notification.success({
          message: data.message || "Success",
      });
  }
  return data;
};
export const addLeave = (valData) => async (dispatch) => {
  dispatch(showLoader());

  let { data } = await axios.post(addLeaveUrl(), valData, getToken());
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  } else {
    // notification.success({
    //   message: data.message || "Success",
    // });
  }
  return data;
};
export const SingleUserLeave = (userId) => async (dispatch) => {
  dispatch(showLoader());
    let config = {
        ...(await getToken()),
    };
    let {data} = await axios.get(AllLeaveUrl(userId), config);
  console.log(data);
  dispatch(hideLoader());
  // if (data.error) {
  //     notification.error({
  //         message: data.message || "Error",
  //     });
  // }
  // else {
  //   notification.success({
  //     message: data.message || "Success",
  //   });
  // }

  return data;
};

export const postUpdateLeave = (valData) => async (dispatch) => {
  dispatch(showLoader());

    let {data} = await axios.put(postUpdateLeaveUrl(), valData, getToken());
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  } else {
    notification.success({
      message: data.message,
    });
  }
  return data;
};

export const addDocumentToEmpFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
    let {data} = await axios.post(addDocumentsEmpUrl(), valData, getToken());
  dispatch(hideLoader());
  if (data.error) {
      notification.error({
          message: data.message || "Error",
      });
  } else {
      notification.success({
          message: data.message || "Success",
      });
  }
  return data;
};

export const getSingleEmpDocumentsFxn = (id) => async (dispatch) => {
  dispatch(showLoader());
    let config = {
        ...(await getToken()),
    };
    let {data} = await axios.get(getSingleEmpDocumentsUrl(id), config);

  dispatch(hideLoader());
  // if (data.error) {
  //     console.log("errrrrrrrr");
  //     // notification.error({
  //     //     message: data.message || "Error",
  //     // });
  // }

  return data;
};

export const deleteSingleDocumentFxn = (id) => async (dispatch) => {
  dispatch(showLoader());
    let config = {
        ...(await getToken()),
    };
    let {data} = await axios.delete(deleteSingleEmpDocumentUrl(id), config);
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    }
};

export const deleteEmpDocumentFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());

    let {data} = await axios.post(deleteEmpDocumentUrl(), valData, getToken());
  dispatch(hideLoader());
  if (data.error) {
      notification.error({
          message: data.message || "Error",
      });
  } else {
      notification.success({
          message: data.message || "Success",
      });
  }

  return data;
};
