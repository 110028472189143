import {apiUrl} from "../../settings";

export const GenerateSOP = () => {
    return `${apiUrl}/generateSOP`;
};

export const templateSOPUrl = () => {
    return `${apiUrl}/templateForSop`;
};

export const studentListUrl = () => {
    return `${apiUrl}/studentList`;
};
export const sopListUrl = () => {
    return `${apiUrl}/sopList`;
};

export const applicationListUrl = () => {
    return `${apiUrl}/applicationList`;
};

export const reGenerateSopUrl = () => {
    return `${apiUrl}/re-generateSop`;
};

export const reGenerateSopDocUrl = () => {
    return `${apiUrl}/re-generateSop-doc`;
};
