import { hideLoader, showLoader } from "../../actions/loader";
import notification from "../../components/Elements/Notification";
import { customAxios as axios, getToken } from "../../request";
import { addUserUrl, branchListUrl, userListUrl } from "./api";

export const addUserFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  let { data } = await axios.post(addUserUrl(), valData, getToken());
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  } else {
    notification.success({
      message: data.message || "Success",
    });
  }
  return data;
};

export const userListFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  let config = {
    params: { ...valData },
    ...(await getToken()),
  };
  let { data }= await axios.get(userListUrl(), config);
  console.log(data, "hjds");
  dispatch(hideLoader());

  return data;
};
export const branchListFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  let config = {
    params: { ...valData },
    ...(await getToken()),
  };
  let { data } = await axios.get(branchListUrl(), config);
  dispatch(hideLoader());

  return data;
};
