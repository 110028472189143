import moment from "moment";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {
    Drawer,
    Tooltip,
    displayDate,
} from "../../components/Elements/appUtils";
import {applicationNoteFxn} from "../Student/actions";
import AddComment from "./addComment";

const CommentsComponent = (props) => {
    let {data} = props;
    return (
        <>
            <ul>
                {data && data.length
                    ? data.map((item, key) => {
                        return (
                            <li className="active">
                                <div className="head">
                                    <h6 className="m-0">Added on</h6>
                                    <span>
                                        <i className="fa fa-calendar-alt"></i>{displayDate(item.time)}
                                    </span>
                                </div>
                                <p className="m-0">{item.text}</p>
                                <span className="date">
                                <i className="fa fa-user"></i>
                                    {item.addedByUserId && item.addedByUserId.name
                                        ? item.addedByUserId.name
                                        : null}{" "}
                              </span>
                            </li>
                        );
                    })
                    : null}

            </ul>

            <br/>
            <br/>
        </>
    )
}


const SingleNote = (props) => {
    let {data, events} = props;
    const [noteId, setNoteId] = useState("");
    const [commentOpen, setCommentOpen] = useState(false);
    const showComment = (id) => {
        setNoteId(id);
        setCommentOpen(true);
    };

    const onCloseComment = () => {
        setCommentOpen(false);
    };

    return (
        <>
            <div className="box" key={data._id}>
                {data && data.items && data.items.length
                    ? data.items.map((item, key) => {
                        return (
                            <li className="active">
                                <div className="head">
                                    <h5 className="m-0">Added on</h5>
                                    <span>
                                          <i className="fa fa-calendar-alt"></i>{" "}
                                        {displayDate(item.createdAt)}
                                      </span>
                                </div>
                                <p className="m-0">{item.note}</p>
                                <span className="date">
                                      <i className="fa fa-user"></i>
                                    {item.assignTo && item.assignTo.name
                                        ? item.assignTo.name
                                        : null}
                                 </span>
                                <div className={'comment_list'}>
                                    {item.comments && item.comments.length ?
                                        <CommentsComponent data={item.comments}/> : null}

                                    <div className={'add_comment'}>
                                        <a onClick={() => {
                                            showComment(item._id)
                                        }}>
                                            Add Comment
                                        </a>
                                    </div>
                                </div>
                            </li>
                        );
                    })
                    : null}
            </div>
            {commentOpen ? <AddComment
                visible={commentOpen}
                onClose={onCloseComment}
                onSubmit={() => {
                    onCloseComment()
                    events.reload()
                }}
                noteId={noteId}
            /> : null}
        </>
    );
};
const NoteList = (props) => {
    let dispatch = useDispatch();
    let {visible, studentId, applicationId, onClose, onSubmit} = props;
    let [noteList, setNoteList] = useState([]);
    useEffect(() => {
        loadNoteList();
    }, [applicationId._id]);
    let loadNoteList = async () => {
        let params = {
            results: 100,
            count: 100,
            studentId,
            applicationId: applicationId._id,
        };
        let {data} = await dispatch(applicationNoteFxn(params));
        setNoteList(data);
    };
    let events = {
        reload: () => {
            loadNoteList();
        }
    }
    return (
        <>
            <Drawer title={"Notes"} visible={visible} onClose={onClose} width={"60%"}>
                <div className={'followup_list'}>
                    <div className="added-on">
                        <ul>
                            {noteList && noteList.length
                                ? noteList.map((item, index) => {
                                    return (
                                        <SingleNote data={item} key={item._id} index={index} events={events}/>
                                    );
                                })
                                : null}
                        </ul>
                    </div>
                </div>

            </Drawer>
        </>
    );
};
export default NoteList;
