import {hideLoader, showLoader} from "../../../actions/loader";
import notification from "../../../components/Elements/Notification";
import {customAxios as axios, getToken} from "../../../request";
import {
    addStudentUrlTest,
    LeadListUrl,
    addCloseUrl,
    addDeleteUrl,
    addFollowListUrl,
    addFollowUrl,

    addTransferToAnotherUrl,
    counsellorUrl,
    getLeadFieldListUrl,
    getLeadUrl,
    getRelatedCounsellorUrl,
    singleFollowUpUrl,
    updateFollowupUrl,
    updateLeadUrl,
    addStudentUrl,
    boardStreamListUrl,
    geCampusListUrl,
    getCampusListUrl,
    getCourseCampusListUrl,
    getIntakeListUrl,
    getRelatedUniversityUrl,

} from "../api";

export const addStudentFxnTest = (valData) => async (dispatch) => {
    valData.leadType = "test";
    // dispatch(showLoader());
    let {data} = await axios.post(addStudentUrlTest(), valData, getToken());
    // dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};


export const LeadListFxn = () => async (dispatch) => {
    dispatch(showLoader());
    let config = {
        params: {
            leadType: 'test',
        },
        ...(await getToken()),
    };
    let {data} = await axios.get(LeadListUrl(), config);
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    }
    return data;
};

export const AddFollowUpFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(addFollowUrl(), valData, getToken());
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};

export const FollowUpList = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let config = {
        params: {...valData},
        ...(await getToken()),
    };
    let {data} = await axios.get(addFollowListUrl(), config);
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    }

    return data;
};

export const AddCloseFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(addCloseUrl(), valData, getToken());
    console.log(data);
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};

export const AddDeleteFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(addDeleteUrl(), valData, getToken());
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};

export const getRelatedCounsellorFxn = async (valData) => {
    let config = {
        params: {...valData},
        ...(await getToken()),
    };
    let {data} = await axios.get(getRelatedCounsellorUrl(), config);
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    }
    return data.data;
};

export const AddTransferToAnotherFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(
        addTransferToAnotherUrl(),
        valData,
        getToken()
    );
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};

export const getSingleLeadFxn = (id) => async (dispatch) => {
    dispatch(showLoader());
    let config = {
        ...(await getToken()),
    };
    let {data} = await axios.get(getLeadUrl(id), config);
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    }

    return data;
};

export const updateLeadFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(updateLeadUrl(), valData, getToken());
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};

export const singleFollowUpFxn = (id) => async (dispatch) => {
    dispatch(showLoader());
    let config = {
        params: {},
        ...(await getToken()),
    };
    let {data} = await axios.get(singleFollowUpUrl(id), config);
    dispatch(hideLoader());
    return data;
};
export const updateFollowUpFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(updateFollowupUrl(), valData, getToken());
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};

export const getLeadFieldListFxn = async (valData) => {
    let config = {
        params: {...valData},
        ...(await getToken()),
    };
    let {data} = await axios.get(getLeadFieldListUrl(), config);

    return data.data;
};


export const getRelatedRepresentativeUniversityListFxn = async (id) => {
    let config = {
        ...(await getToken()),
    };
    let {data} = await axios.get(getRelatedUniversityUrl(id), config);
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    }
    return data;
};

export const getCampusListFxn = async (valData) => {
    let config = {
        params: {...valData},
        ...(await getToken()),
    };
    let {data} = await axios.get(getCampusListUrl(), config);
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    }
    return data;
};
export const getCourseCampusListFxn = async (valData) => {
    let config = {
        params: {...valData},
        ...(await getToken()),
    };
    let {data} = await axios.get(getCourseCampusListUrl(), config);
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    }
    return data;
};
export const getIntakeListFxn = async (valData) => {
    let config = {
        params: {...valData},
        ...(await getToken()),
    };
    let {data} = await axios.get(getIntakeListUrl(), config);
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    }
    return data;
};
export const boardStreamListFxn = async (valData) => {
    let config = {
        params: {...valData},
        ...(await getToken()),
    };
    let {data} = await axios.get(boardStreamListUrl(), config);
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    }
    return data;
};
