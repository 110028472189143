import React, {useState} from "react"
import {
    Button,
    Card,
    englishTestList,
    InputBox,
    Modal,
    notification,
    Select
} from "../../../components/Elements/appUtils";

const {Option} = Select
const ConvertToEnglishTest = (props) => {
    let {visible, onClose, onSubmit} = props;
    let [englishTest, setEnglishTest] = useState("");
    let submitQuery = () => {
        if (!englishTest) {
            notification.warning({message: "Choose Test"})
            return
        }
    }
    return (
        <>
            <Modal
                title={'Process to ILETS/PTE'}
                visible={visible}
                onClose={onClose}>
                <InputBox title={'Choose English Test'}>
                    <select className={'form-control'} onChange={({target}) => {
                        setEnglishTest(target.value)
                    }}>
                        <option value={""}>Choose Test</option>
                        {englishTestList.map((item) => {
                            return (
                                <option key={item} value={item}>{item}</option>
                            )
                        })}
                    </select>
                </InputBox>

                <div className={'mt-4 alignRight'}>
                    <Button type={"primary"} onClick={submitQuery}>
                        Submit
                    </Button>
                </div>
            </Modal>
        </>
    )
}
export default ConvertToEnglishTest
