import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import Modal from "../../components/Elements/Modal";
import {addTrainingRequest} from "./action";
import {Card} from "../../components/Elements/appUtils";

const {confirm} = Modal;

const TrainingRequestComponent = (props) => {
    const dispatch = useDispatch();
    let [message, setMessage] = useState("");

    let user = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : null;

    const handleSubmit = async () => {
        let resp = await dispatch(addTrainingRequest({agentId: user._id}));
        if (resp && resp.success) {
            setMessage(resp.message);
        } else {
            setMessage(resp.message);
        }
    };

    const events = {
        confirmMeetingFxn: () => {
            confirm({
                title: "Are you sure, you want to confirm your Online Portal Training ?",
                onSubmit: () => {
                    handleSubmit()
                }
            })
        }
    };

    return (
        <React.Fragment>
            <Card>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-xs-12">
                            <div className={"trainingBox"} style={{alignItems: "center"}}>
                                {message ? (
                                    <div className={"textBox normal"}>
                                        <h5>{message}</h5>
                                    </div>
                                ) : (
                                    <div className={"training_card"}>
                                        <img src={'../app/img/zoom-logo.jpg'} style={{height: 80}}/>
                                        <h6 className="mt-4">Confirm your online portal training</h6>
                                        <br/>
                                        <br/>
                                        <button
                                            onClick={() => {
                                                events.confirmMeetingFxn();
                                            }}
                                            className="btn main-btn">
                                            Click to confirm
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

            </Card>
        </React.Fragment>
    );
};
export default TrainingRequestComponent;
