import React, {useRef, useState} from 'react'
import {useDispatch} from "react-redux";
import {checkListDocumentListFxn} from "../actions";
import {Card, Table, Drawer, Tabs} from "../../../../components/Elements/appUtils";

let {TabPane} = Tabs
const DocumentCheckList = (props) => {
    let {visible, onClose, masterCountry, category} = props;
    let tableRef = useRef();
    let dispatch = useDispatch();
    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            params.masterCountryId = masterCountry._id;
            params.categoryId = category._id;
            let data = await dispatch(checkListDocumentListFxn({
                ...params,
                regExFilters: ["countryName", "interview", "documents"],
            }));
            resolve(data);
        });
    };

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            width: 150
        },
        {
            title: "document",
            dataIndex: "document",
            key: "document",
            width: 150,
            render: (data, record) => {
                return (
                    <>
                        {data && data.url ? <a href={data.url} download>{data.name}</a> : null}
                    </>
                )
            },
        },
        {
            title: "Action",
            dataIndex: "action",
            width: 150,
            render: (val, record) => {
                return (
                    <>

                    </>
                );
            },
        },
    ];
    return (
        <Drawer visible={visible} onClose={onClose} title={'Document List'} width={"45%"}>
            <Card>
                <Tabs
                    onChange={(index) => {
                        console.log(index)
                    }}>
                    <TabPane tab={'Documents'} key={"1"}>
                        <Table apiRequest={apiRequest} columns={columns} ref={tableRef}/>
                    </TabPane>
                    <TabPane tab={'Videos'} key={"2"}>

                    </TabPane>
                </Tabs>
            </Card>
        </Drawer>
    );
}
export default DocumentCheckList
