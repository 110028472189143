import React, {useRef} from "react";
import {useNavigate} from "react-router-dom";
import {Card, HyperLink, Table} from "../../components/Elements/appUtils";
import {branchListFxn} from "../Reception/actions";
import {useDispatch} from "react-redux";

let {TableCard} = Card;

const BranchList = () => {
    const navigate = useNavigate();
    let dispatch = useDispatch()
    let tableRef = useRef();
    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            let data = await dispatch(branchListFxn({
                ...params,
                userType: "branch",
                regExFilters: ["name"],
            }))
            resolve(data);
        });
    };

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            searchTextName: "name",
            filterRegex: true,
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "Mobile No",
            dataIndex: "mobile",
            key: "mobile",
            render: (item, record) => {
                let {countryCode} = record;
                return (
                    <>
                        {countryCode} {item}
                    </>
                )
            }
        },
        {
            title: "Country",
            dataIndex: "countryName",
            key: "countryName",
            // render: (item, record) => {
            //     return item && item.countryName ? item.countryName : null
            // }
        },
        /* {
             title: "Action",
             dataIndex: "action",
             width: 190,
             render: (val, record) => {
                 return (
                     <>
                         <button className={'btn'} onClick={() => {
                             navigate(`/edit-branch?_id=${record._id}`)
                         }}>
                             <img src={'../app/img/icons/edit.svg'}/>
                         </button>
                     </>
                 );
             },
         },*/
    ];

    return (
        <>
            <TableCard
                title={"Branch List"}
                extraBtn={[
                    {
                        name: "Add Branch",
                        action: () => {
                            navigate("/add-branch");
                        },
                    },
                ]}
            >
                <Table apiRequest={apiRequest} columns={columns} ref={tableRef}/>
            </TableCard>
        </>
    );
};

export default BranchList;
