import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {
    Card,
    Popconfirm,
    Table,
    Tooltip,
    displayDate,
} from "../../../components/Elements/appUtils";
import {companyCountryList} from "../../University/action";
import RearrangeList from "../Modals/rearrangeList";
import StatusList from "../Modals/statusList";
import ActivateCountry from "./ActivateCountry";

let {TableCard} = Card
const SingleCountry = (props) => {
    let {item, events} = props;
    let {masterCountryId} = item;
    return (
        <>
            <div className={"lead_card"} key={item._id}>
                <div className={"row"}>
                    <div className={"col-md-9"}>
                        <div className={"flex1"}>
                            <div className={"avatar_lead"}>
                                {
                                    <img
                                        src={
                                            masterCountryId &&
                                            masterCountryId.flag &&
                                            masterCountryId.flag.path
                                                ? masterCountryId.flag.path
                                                : null
                                        }
                                        style={{height: 25, marginRight: 10}}
                                    />
                                }
                            </div>
                            <div className="basic-info">
                                <div className={"name"}>
                                    <Tooltip title={"Edit Country"}>
                                        <a
                                            // onClick={() => events.showEditLead(masterCountryId._id)}
                                        >
                                            {masterCountryId && masterCountryId.countryName
                                                ? masterCountryId.countryName
                                                : null}{" "}
                                            {masterCountryId && masterCountryId.lastName
                                                ? masterCountryId.lastName
                                                : null}
                                        </a>
                                    </Tooltip>
                                </div>

                                <div className={"lead_info"}>
                                    <i className="fa fa-calendar">
                                        <a style={{height: 25, marginLeft: 10}}>
                                            {" "}
                                            {masterCountryId && masterCountryId.createdAt
                                                ? displayDate(masterCountryId.createdAt)
                                                : null}
                                        </a>
                                    </i>
                                </div>
                                <div className={"lead_info"}>
                                    <div className={"single_box"}>
                                        <a
                                            className={"btn btn-link p-0 mt-1"}
                                            onClick={() =>
                                                events.showStatuses(
                                                    item._id
                                                    // item.countryId
                                                )
                                            }
                                        >
                                            Total Active Status -
                                            {item && item.statusList && item.statusList.length
                                                ? item.statusList.length
                                                : 0}
                                        </a>
                                        {/* <a
                      style={{ marginLeft: "20px" }}
                      className={"btn btn-link p-0 mt-1"}
                      onClick={() =>
                        events.showRearrange(
                          item._id
                          // item.countryId
                        )
                      }
                    >
                      Rearrange
                    </a> */}
                                    </div>
                                </div>

                                <div className={"lead_info"}>
                                    <div className={"single_box"}></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"col-md-3 align_center"}>
                        <div className={"lead_label"}>Actions</div>
                        <div className={"btn_group"}>
                            <div>
                                <a>
                                    <i className="fa fa-eye"></i>
                                </a>

                                <Popconfirm
                                    title="Are you sure to delete this country?"
                                    onConfirm={() => {
                                    }}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <a>
                                        <i className="fa fa-trash"></i>
                                    </a>
                                </Popconfirm>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
const CountryList = () => {
    let dispatch = useDispatch();
    let tableRef = useRef()
    const [appState, setAppState] = useState({
        visible: false,
        countryId: "",
    });
    const [rearrangeState, setRearrangeState] = useState({
        visible: false,
        countryId: "",
    });
    const [addVisible, setAddVisible] = useState({
        visible: false,
        countryId: "",
    });
    useEffect(() => {
    }, []);
    const apiRequest = async () => {
        return new Promise(async (resolve) => {
            let params = {
                results: 100,
                count: 100,
            };
            let resp = await companyCountryList({
                ...params,
                regExFilters: ["countryName", "interview", "documents"],
            });
            resolve(resp)
        })
    };

    const events = {
        reloadTable: () => {
            tableRef.current.reload()
        },
        showStatuses: (countryId) => {
            setAppState({
                visible: true,
                countryId: countryId,
            });
        },

        hideStatuses: () => {
            events.reloadTable()
            setAppState({
                visible: false,
                countryId: "",
            });
        },
        showAddCountry: () => {
            setAddVisible({
                visible: true,
                countryId: "",
            });
        },

        hideAddCountry: () => {
            events.reloadTable()
            setAddVisible({
                visible: false,
                universityId: "",
                countryId: "",
            });
        },
        hideRearrange: () => {
            setRearrangeState({
                visible: false,
                universityId: "",
                countryId: "",
            });
        },
        showRearrange: (countryId) => {
            setRearrangeState({
                visible: true,
                universityId: "",
                countryId: countryId,
            });
        },
    };
    let columns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            render: (item, record, index) => {
                return index + 1;
            }
        },
        {
            title: "Country",
            key: "countryName",
            dataIndex: "countryName",
            render: (item, record) => {
                let {flag} = record;
                return (
                    <>
                        {flag && flag.path ? <img src={flag.path} style={{height: 25, marginRight: 10}}/> : null}
                        {item}
                    </>
                )
            }
        },
        {
            title: "Currency",
            key: "currency",
            dataIndex: "currency"
        },

        {
            title: "Action",
            key: "action",
            dataIndex: "action",
            render: (item, record) => {
                return (
                    <>
                        <button
                            className="btn" type="button"
                            onClick={() =>
                                events.showStatuses(record._id)
                            }>
                            <img src="../app/img/action7.svg" alt=""/>
                        </button>
                    </>
                )
            }
        },

    ]
    return (
        <>
            <TableCard
                title={"Country List"}
                extraBtn={[{
                    name: "Add Country",
                    action: () => {
                        events.showAddCountry()
                    }
                }]}>
                <div>
                    <Table columns={columns} apiRequest={apiRequest} ref={tableRef}/>
                </div>
            </TableCard>
            {appState.visible ? (
                <StatusList {...appState} onClose={events.hideStatuses}/>
            ) : null}
            {rearrangeState.visible ? (
                <RearrangeList {...rearrangeState} onClose={events.hideRearrange}/>
            ) : null}{" "}
            {addVisible.visible ? (
                <ActivateCountry
                    {...addVisible}
                    onClose={events.hideAddCountry}
                    onSubmit={events.reloadTable}
                />
            ) : null}
        </>
    );
};

export default CountryList;
