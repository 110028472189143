import React from "react";
import { InputBox } from "../../../components/Elements/appUtils";

const CampusComponent = (props) => {
  let { data, index, updateState, deleteItem } = props;
  return (
    <>
      <div className={"col-md-6"} name="campus" key={index}>
        <div className={"new-border"}>
          <InputBox title={`${index + 1}. Campus`} className={"rowFlex"}>
            <input
              required={true}
              placeholder="Please enter Campus Name"
              className={"form-control"}
              onChange={({ target }) => {
                updateState({ campus: target.value, index: index + 1 }, index);
              }}
              value={data.campus}
            />
            {index !== 0 ? (
              <a>
                <i
                  className="fa fa-trash new-icon"
                  aria-hidden="true"
                  onClick={() => deleteItem(index)}
                ></i>
              </a>
            ) : null}
          </InputBox>
        </div>
      </div>
    </>
  );
};

export default CampusComponent;
