import {apiUrl} from "../../../settings";

export const leadCountByTypeUrl = () => {
    return `${apiUrl}/dashboard/leadCountByType`;
};
export const englishTestLeadCountUrl = () => {
    return `${apiUrl}/dashboard/englishTestLeadCount`;
};
export const applicationCountCountryWiseUrl = () => {
    return `${apiUrl}/dashboard/applicationCountCountryWise`;
};

export const studentCountryWiseUrl = () => {
    return `${apiUrl}/dashboard/studentCountryWise`;
};
export const commissionCountryWiseUrl = () => {
    return `${apiUrl}/dashboard/commissionCountryWise`;
};
export const englishTestRevenueUrl = () => {
    return `${apiUrl}/dashboard/englishTestRevenue`;
};

export const getDataByBranchIdUrl = () => {
    return `${apiUrl}/dashboard/getDataByBranchId`;
};
