import _ from "lodash";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {
    Button,
    Card,
    Form,
    notification,
    Modal
} from "../../../components/Elements/appUtils";
import {LoadState} from "../../../components/_utils/countryUtil";
import {Countries} from "../../../components/_utils/appUtils";
import {genderOption} from "../../../components/_utils/_utils";
import {EnglishExamOptions} from "../../../components/_utils/_utils";
import {Flags} from "../../../components/_utils/appUtils";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import {companyCountryList} from "../../University/action";
import {GetRelatedUserFxn} from "../../Users/action";
import {postUpdateUserFxn} from "../../Users/views/action";
import EducationComponent from "../../AddStudent/Components/educationComponent";

const {InnerCard} = Card;
const eduStateInit = {
    qualification: "",
    math: "",
    english: "",
    percentage: "",
    board: "",
    university: "",
    stream: "",
    passingYear: "",
    courseName: "",
    schoolName: "",
};

const EditMyProfile = (props) => {

    const initialState = {
        name: "",
        passportNumber: "",
        email: "",
        address: "",
        companyContactNo: "",
        companyAddress: "",
        companyEmail: "",
        companyLogo: {},
        country: undefined,
        gstRegistration: true,
        gstInNo: "",
        pincode: "",
        countryCode: "",
        cityName: undefined,
        countryName: undefined,
        stateName: undefined,
        postalCode: "",
        agentId: "",
        certificate: {},
        profilePhoto: {},
        referBy: "",
    };

    const [countryList, setCountryList] = useState([]);
    let [interestedFields, setInterestedFields] = useState([]);
    const [stateArray, setStateArray] = useState([]);
    const [userId, setUserId] = useState("");
    let [extraFields, setExtraFields] = useState({});
    let [formState, setState] = useState(initialState);
    const [education, setEducation] = useState([eduStateInit]);
    const [enableForEnglish, setEnableForEnglish] = useState(false);
    const [eduState, setEduState] = useState(eduStateInit);
    const dispatch = useDispatch();
    let [userType, setUserType] = useState("counsellor");
    let user = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : null;
    const AllData = () => {
        setEducation([...education, eduStateInit]);
    };
    const deleteItem = (itemId) => {
        const updatedItems = education.filter((item, key) => key !== itemId);
        setEducation(updatedItems);
    };

    const eduEvents = {
        updateState: (data, index) => {
            let cloneEdu = _.clone(education);
            cloneEdu[index] = {...cloneEdu[index], ...data};
            setEducation(cloneEdu);
        },
        addEdu: () => {
            if (education) {
                if (education.percentage < 1) {
                    notification.warning({
                        message: "Enter greater then 0 Percentage",
                    });
                    return;
                }
                if (education.qualification == "XII") {
                    if (education.math < 1) {
                        notification.warning({
                            message: "Enter greater then 0 Math score",
                        });
                        return;
                    }
                    if (education.english < 1) {
                        notification.warning({
                            message: "Enter greater then 0 English score",
                        });
                        return;
                    }
                }
            }
            setEducation((preState) => {
                return [...preState, eduState];
            });
            eduEvents.updateState(eduStateInit);
        },
    };


    useEffect(() => {
        handleState();

    }, []);
    useEffect(() => {
        let id = user._id;
        if (id) {
            setUserId(id);
            getRelatedUser(id);
        }
    }, []);

    const getRelatedUser = async (id) => {
        let {data} = await dispatch(GetRelatedUserFxn(id));

        setTimeout(() => {
            if (data && data.englishExamType && data.englishExamType.length > 0) {
                setEnableForEnglish(!enableForEnglish);
            }
            props.form.setFieldsValue({
                contactPerson: data.contactPerson,
                address: data.address,
                email: data.email,
                countryCode: data.countryISOCode,
                mobile: data.mobile,
                dateOfBirth: data.dateOfBirth,
                gender: data.gender,
                countries: data.countries,
                countryName: data.countryName,
                stateName: data.stateName,

                cityName: data.cityName,

            });
            setUserType(data.userType)
            setEducation(data && data.education && data.education.length ? data.education : [eduStateInit]);
        }, 500);

    };

    const handleState = async () => {
        let x = await companyCountryList();
        setCountryList(x.data);
    };

    const handleSubmit = (e) => {
        const {form} = props;
        e.preventDefault();
        form.validateFields(async (err, valData) => {
            if (!err) {
                if (!valData.contactPerson) {
                    notification.warning({message: "Enter name"});
                    return;
                }
                if (!valData.email) {
                    notification.warning({message: "Enter email"});
                    return;
                }

                if (!valData.countryCode) {
                    notification.warning({message: "Choose country code"});
                    return;
                }
                if (!valData.mobile) {
                    notification.warning({message: "Enter mobile no"});
                    return;
                }


                valData.id = userId;
                valData = {...valData, education};
                let x = await dispatch(
                    postUpdateUserFxn({
                        ...valData,


                    })
                );
                if (x.success) {
                }
            } else {
                notification.warning({
                    message: "Please Fill All Required Fields",
                });
            }
        });
    };
    const events = {

        chooseCountry: (name) => {
            let countryName = _.find(Countries, (item) => {
                return item.name == name;
            });
            if (countryName) {
                setState({
                    ...formState,
                    countryName: countryName.name ? countryName.name : "",
                });
                setStateArray(countryName.id ? LoadState(countryName.id) : []);
            }
        },
        chooseState: (name) => {
            let stateName = _.find(stateArray, (item) => {
                return item.name == name;
            });
            if (stateName) {
                setState({
                    ...formState,
                    stateName: stateName.name ? stateName.name : "",
                });
            }
        },
        updateExtraState: (data) => {
            setExtraFields((prevState) => {
                return {
                    ...prevState,
                    ...data,
                };
            });
        },
        chooseCourseCategory: (category) => {
            let cloneD = _.clone(interestedFields);
            let findV = _.find(cloneD, (item) => {
                return item == category;
            });
            if (findV) {
                cloneD = _.reject(cloneD, (item) => {
                    return item == category;
                });
                setInterestedFields(cloneD);
            } else {
                cloneD.push(category);
                setInterestedFields(cloneD);
            }
        },
    };

    let inputTypes = {
        fields: [
            {
                key: `Name (${userType})`,
                label: "contact Person",
                required: false,
                placeholder: "Enter Name",
                type: "text",
            },
            {
                key: "email",
                label: "Email",

                placeholder: "Email",
                type: "text",
            },

            {
                key: "address",
                label: "Address",
                required: false,
                placeholder: "Address",
                type: "text",
            },
            {
                key: "countryCode",
                label: "Country Code",
                required: false,
                placeholder: "Country Code",
                type: "select",
                options: Flags,
                showSearch: true,
                keyAccessor: (x) => x.countryCode,
                valueAccessor: (x) => `${x.name} (${x.dialCode})`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        countryCode: x,
                    });
                },
                span: "col-md-2",
            },
            {
                key: "mobile",
                label: "Mobile No",
                required: false,
                placeholder: "Mobile No",
                type: "text",
                span: "col-md-4",
            },
            {
                key: "gender",
                label: "Gender",

                placeholder: "Gender ",
                type: "select",
                keyAccessor: (x) => x.option,
                valueAccessor: (x) => `${x.option}`,
                options: genderOption,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        gender: x,
                    });
                },
                span: "col-md-4",
            },
            {
                key: "countryName",
                label: "Country",
                showStar: true,
                showSearch: true,
                placeholder: "Country Name",
                type: "select",
                options: Countries,
                keyAccessor: (x) => x.name,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        countryName: x,
                        stateName: "",
                        cityName: "",
                    });
                    events.chooseCountry(x);
                },
                span: "col-md-4",
            },
            {
                key: "stateName",
                label: "State",
                showStar: true,
                showSearch: true,
                placeholder: "State Name",
                type: "text",
                span: "col-md-4",
            },
            {
                key: "cityName",
                label: "City",
                showStar: true,
                placeholder: "City Name",
                type: "text",
                span: "col-md-4",
            },

        ],


    };
    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Card title={"Edit My Profile"}>
                    <div className={"row"}>
                        {inputTypes.fields.map((item, key) => {
                            return (
                                <div
                                    className={`pt-3 ${item.span ? item.span : "col-md-6"}`}
                                    key={key}
                                >
                                    <GetEachFormFields {...props.form} item={item}/>
                                </div>
                            );
                        })}
                    </div>
                    <Button
                        type={"primary"}
                        htmlType="submit"
                        className={"btn main-btn mt-4"}
                    >
                        submit
                    </Button>
                </Card>

            </Form>

        </>
    );
};

export default Form.create()(EditMyProfile);
