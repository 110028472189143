import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Form,
  Button,
  Card,
  notification,
} from "../../components/Elements/appUtils";
import {
  EnglishExamOptions,
  genderOption,
  maritalStatusOption,
  EnglishExamTypes,
} from "../../components/_utils/_utils";
import { Countries, Flags } from "../../components/_utils/appUtils";
import { LoadState } from "../../components/_utils/countryUtil";
import { GetEachFormFields } from "../../components/_utils/formUtils";
import WorkExperienceComp from "../AddStudent/Components/WorkExperienceComp";
import EducationComponent from "../AddStudent/Components/educationComponent";
import { courseCategoryList } from "../Masters/courseCategory/action";
import { loadCountryList } from "../Masters/state/action";
import { companyCountryList } from "../University/action";
import { getRelatedCounsellorFxn } from "../Leads/action";
import { getSingleEmpFxn, updateSingleFxn } from "./actions";
// import { singleStudentFxn,updateStudentFxn } from "../action"
const { InnerCard } = Card;
const initialState = {
  name: "",
  passportNumber: "",
  email: "",
  address: "",
  companyContactNo: "",
  companyAddress: "",
  companyEmail: "",
  companyLogo: {},
  country: undefined,
  gstRegistration: true,
  gstInNo: "",
  pincode: "",
  countryCode: "",
  cityName: undefined,
  countryName: undefined,
  stateName: undefined,
  postalCode: "",
  agentId: "",
  certificate: {},
  profilePhoto: {},
  referBy: "",
};

const eduStateInit = {
  qualification: "",
  math: "",
  english: "",
  percentage: "",
  board: "",
  university: "",
  stream: "",
  passingYear: "",
  courseName: "",
  schoolName: "",
};

const workStateInit = {
  companyName: "",
  position: "",
  time: "",
  // experienceCertificate: "",
};
const EmployeeEdit = (props) => {
  const { getFieldDecorator, getFieldValue, setFieldsValue } = props.form;
  const [countryList, setCountryList] = useState([]);
  const [counselorList, setCounselorList] = useState([]);
  const [stateArray, setStateArray] = useState([]);
  const [leadFieldsList, setLeadFieldsList] = useState([]);
  const [categoriesList, setCategoryList] = useState([]);
  const [EmpId, setEmpId] = useState("");
  const [eduState, setEduState] = useState(eduStateInit);
  const [studentId, setStudentId] = useState("");
  let [formState, setState] = useState(initialState);
  let [extraFields, setExtraFields] = useState({});
  let [interestedFields, setInterestedFields] = useState([]);
  const [education, setEducation] = useState([eduStateInit]);
  const [experience, setExperience] = useState([workStateInit]);
  const dispatch = useDispatch();

  useEffect(() => {
    handleLead();
    handleCourseCategory();

    checkLeadId();
  }, []);
  const checkLeadId = async () => {
    let searchParams = new URLSearchParams(window.location.search);
    let id = searchParams.get("_id");
    if (id) {
      getSingleStudent(id);
      setStudentId(id);
    }
  };

  const handleCounselor = async (countryId) => {
    if (countryId) {
      let x = await getRelatedCounsellorFxn({
        countryId,
        userType: "counsellor",
      });
      setCounselorList(x);
    }
  };
  const getSingleStudent = async (id) => {
    let { data, success } = await dispatch(getSingleEmpFxn(id));
    if (success) {
      setTimeout(() => {
        props.form.setFieldsValue({
          name: data.name,
          gender: data.gender,
          mobile: data.mobile,
          address: data.address,
          email: data.email,
          passportNumber: data.passportNumber,
          leadSource: data.leadSource,

         
          interestedCountry:
            data.interestedCountry && data.interestedCountry.countryName
              ? data.interestedCountry.countryName
              : null,
          countryCode: data.countryCode,
          maritalStatus: data.maritalStatus,
          countryName: data.countryName,
          stateName: data.stateName,
          cityName: data.cityName,
          pincode: data.pincode,
          message: data.message,
          dateOfBirth: data.dateOfBirth ? moment(data.dateOfBirth) : "",
        
        });
        setEducation(
          data && data.education && data.education.length
            ? data.education
            : [eduStateInit]
        );
        setExtraFields(data.extraFields);
        setInterestedFields(data.interestedFields);
        setEmpId(data._id)
        setExperience(
          data && data.workExperience && data.workExperience.length
            ? data.workExperience
            : [workStateInit]
        );

    
      }, 300);
      
    }
  };
  let user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;
  useEffect(() => {
    setFieldsValue({ countryCode: user.countryCode });
  }, []);

  const handleCourseCategory = async () => {
    let x = await courseCategoryList();
    setCategoryList(x.data.data);
  };

  const events = {
    addMore: () => {
      setExperience([...experience, workStateInit]);
    },
    deleteItem: (itemId) => {
      const updatedItems = experience.filter((item, key) => key !== itemId);
      setExperience(updatedItems);
    },
    updateState: (data, index) => {
      let cloneStatus = _.clone(experience);
      cloneStatus[index] = { ...cloneStatus[index], ...data };
      setExperience(cloneStatus);
    },
    chooseCountry: (name) => {
      let countryName = _.find(Countries, (item) => {
        return item.name == name;
      });
      if (countryName) {
        setState({
          ...formState,
          countryName: countryName.name ? countryName.name : "",
        });
        setStateArray(countryName.id ? LoadState(countryName.id) : []);
      }
    },
    chooseState: (name) => {
      let stateName = _.find(stateArray, (item) => {
        return item.name == name;
      });
      if (stateName) {
        setState({
          ...formState,
          stateName: stateName.name ? stateName.name : "",
        });
      }
    },
    updateExtraState: (data) => {
      setExtraFields((prevState) => {
        return {
          ...prevState,
          ...data,
        };
      });
    },
    chooseCourseCategory: (category) => {
      let cloneD = _.clone(interestedFields);
      let findV = _.find(cloneD, (item) => {
        return item == category;
      });
      if (findV) {
        cloneD = _.reject(cloneD, (item) => {
          return item == category;
        });
        setInterestedFields(cloneD);
      } else {
        cloneD.push(category);
        setInterestedFields(cloneD);
      }
    },
  };
  const handleLead = async () => {
    let x = await companyCountryList();
    setCountryList(x.data);
  };

 

  const AllData = () => {
    setEducation([...education, eduStateInit]);
  };
  const deleteItem = (itemId) => {
    const updatedItems = education.filter((item, key) => key !== itemId);
    setEducation(updatedItems);
  };

  const eduEvents = {
    updateState: (data, index) => {
      let cloneEdu = _.clone(education);
      cloneEdu[index] = { ...cloneEdu[index], ...data };
      setEducation(cloneEdu);
    },
    addEdu: () => {
      if (education) {
        if (education.percentage < 1) {
          notification.warning({
            message: "Enter greater then 0 Percentage",
          });
          return;
        }
        if (education.qualification == "XII") {
          if (education.math < 1) {
            notification.warning({
              message: "Enter greater then 0 Math score",
            });
            return;
          }
          if (education.english < 1) {
            notification.warning({
              message: "Enter greater then 0 English score",
            });
            return;
          }
        }
      }
      setEducation((preState) => {
        return [...preState, eduState];
      });
      eduEvents.updateState(eduStateInit);
    },
  };
 
  const handleSubmit = (e) => {
    const { form } = props;
    e.preventDefault();

    form.validateFields(async (err, valData) => {
      if (!err) {
        valData.extraFields = extraFields;
        valData.interestedFields = interestedFields;

        valData = { ...valData, education };
        valData.workExperience = experience;
        valData.id = EmpId;
        let x = await dispatch(updateSingleFxn(valData));
        if (x && x.success) {
        }
      } else {
        if (err.name) {
          notification.warning({
            message: `Please enter Name`,
          });
          return;
        }
        if (err.countryName) {
          notification.warning({
            message: `Please select Country`,
          });
          return;
        }

        if (err.countryCode) {
          notification.warning({
            message: `Please select Country Code`,
          });
          return;
        }

        if (err.mobile) {
          notification.warning({
            message: `Please enter Mobile No`,
          });
          return;
        }

        if (err.dateOfBirth) {
          notification.warning({
            message: `Please enter Date of Birth`,
          });
          return;
        }

        if (err.gender) {
          notification.warning({
            message: `Please select Gender`,
          });
          return;
        }

        if (err.maritalStatus) {
          notification.warning({
            message: `Please select Marital Status`,
          });
          return;
        }
        if (err.countryName) {
          notification.warning({
            message: `Please select Country`,
          });
          return;
        }

        if (err.stateName) {
          notification.warning({
            message: `Please enter State`,
          });
          return;
        }

        if (err.cityName) {
          notification.warning({
            message: `Please enter City`,
          });
          return;
        }

        if (err.pinCode) {
          notification.warning({
            message: `Please enter Pin Code`,
          });
          return;
        }

        if (err.address) {
          notification.warning({
            message: `Please enter Address`,
          });
          return;
        }

        notification.warning({
          message: "Fill All Required Fields",
        });
      }
    });
  };

  let inputTypes = {
    fields: [
      {
        key: "name",
        label: "Name",
        showStar: true,
        placeholder: "Name",
        type: "text",
        span: "col-md-4",
      },
      {
        key: "countryName",
        label: "Country",
        showStar: true,
        placeholder: "Country",
        type: "select",
        options: Countries,
        showSearch: true,
        keyAccessor: (x) => x.name,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            countryName: x,
          });
        },
        span: "col-md-4",
      },
      {
        key: "email",
        label: "Email",
        // showStar: true,
        placeholder: "Email",
        type: "text",
        span: "col-md-4",
      },

      {
        key: "countryCode",
        label: "Country Code",
        showStar: true,
        placeholder: "Country Code",
        type: "select",
        options: Flags,
        showSearch: true,
        keyAccessor: (x) => x.countryCode,
        valueAccessor: (x) => `${x.name} (${x.dialCode})`,
        onChange: (x) => {
          props.form.setFieldsValue({
            countryCode: x,
          });
        },
        span: "col-md-4",
      },

      {
        key: "mobile",
        label: "Mobile No",
        showStar: true,
        placeholder: "Mobile No ",
        type: "text",
        span: "col-md-4",
      },
      {
        key: "dateOfBirth",
        label: "Date Of Birth ",
        showStar: true,
        placeholder: "Date Birth ",
        type: "date",
        span: "col-md-4",
        keyAccessor: (x) => x.option,
        valueAccessor: (x) => `${x.option}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            dateOfBirth: x,
          });
        },
      },
      {
        key: "gender",
        label: "Gender",
        showStar: true,
        placeholder: "Gender ",
        type: "select",
        keyAccessor: (x) => x.option,
        valueAccessor: (x) => `${x.option}`,
        options: genderOption,
        onChange: (x) => {
          props.form.setFieldsValue({
            gender: x,
          });
        },
        span: "col-md-4",
      },
      {
        key: "maritalStatus",
        label: "Marital Status",
        showStar: true,
        placeholder: "Marital Status",
        type: "select",
        keyAccessor: (x) => x.option,
        valueAccessor: (x) => `${x.option}`,
        options: maritalStatusOption,
        onChange: (x) => {
          props.form.setFieldsValue({
            maritalStatus: x,
          });
        },
        span: "col-md-4",
      },
      {
        key: "address",
        label: "Address",
        showStar: true,
        placeholder: "Address",
        type: "text",
        span: "col-md-4",
      },
    ],
  };
  let AddressInfo = {
    fields: [
      {
        key: "countryName",
        label: "Country",
        showStar: true,
        showSearch: true,
        placeholder: "Country Name",
        type: "select",
        options: Countries,
        keyAccessor: (x) => x.name,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            countryName: x,
            stateName: "",
            cityName: "",
          });
          events.chooseCountry(x);
        },
        span: "col-md-4",
      },
      {
        key: "stateName",
        label: "State",
        showStar: true,
        showSearch: true,
        placeholder: "State Name",
        type: "text",
      },
      {
        key: "cityName",
        label: "City",
        showStar: true,
        placeholder: "City Name",
        type: "text",
        span: "col-md-4",
      },
      {
        key: "pincode",
        label: "Pin Code",
        required: true,
        placeholder: "Pin Code",
        type: "text",
        span: "col-md-4",
      },
    ],
  };

 
  
  return (
    <>
     
      <Card title={"Basic Information"}>
        <Form>
          <div className={"row"}>
            {inputTypes.fields.map((item, key) => {
              return (
                <div
                  className={`pt-3 ${item.span ? item.span : "col-md-4"}`}
                  key={key}
                >
                  <GetEachFormFields {...props.form} item={item} />
                </div>
              );
            })}
          </div>
        </Form>
      </Card>
      {/* AddressInfo */}
      <Card title={"Address Information"}>
        <Form>
          <div className={"row"}>
            {AddressInfo.fields.map((item, key) => {
              return (
                <div className={"col-md-4 pt-3"} key={key}>
                  <GetEachFormFields {...props.form} item={item} />
                </div>
              );
            })}
          </div>
        </Form>
      </Card>

      <Card
        title={"Education"}
        bordered
        extraBtn={[
          {
            name: "Add Multiple Education",
            action: AllData,
          },
        ]}
      >
        {education.map((data, index) => {
          return (
            <InnerCard key={index}>
              <EducationComponent
                data={data}
                index={index}
                education={education}
                eduEvents={eduEvents}
                deleteItem={deleteItem}
              />
            </InnerCard>
          );
        })}
      </Card>

      <Card
        title={"Work Experience"}
        extraBtn={[
          {
            name: "Add Work Experience",
            action: events.addMore,
          },
        ]}
      >
        {experience.map((data, index) => {
          return (
            <InnerCard key={index}>
              <WorkExperienceComp
                data={data}
                index={index}
                experience={experience}
                updateState={events.updateState}
                deleteItem={events.deleteItem}
                handleFileChange={events.handleFileChange}
              />
            </InnerCard>
          );
        })}
        <Form onSubmit={handleSubmit}>
          <div style={{ marginTop: "20px" }}>
            <Button type={"primary"} htmlType="submit">
              submit
            </Button>
          </div>
        </Form>
      </Card>
    </>
  );
};

export default Form.create()(EmployeeEdit);
