import React, {useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {
    Card,
    Popconfirm,
    Table,
    displayDate,
} from "../../components/Elements/appUtils";
import {imgPath} from "../../components/_utils/_utils";
import {
    DeleteFeedbackFxn,
    FeedbackListFxn,
    trainingRequestListFxn,
    updateTrainingRequestFxn,
} from "./action";
import ResolveFeedback from "./resolveFeedback";

let {TableCard} = Card;
const FeedBackList = (props) => {
    let dispatch = useDispatch();
    const navigate = useNavigate();
    let tableRef = useRef();
    const [feedbackState, setFeedbackState] = useState({
        visible: false,
        feedbackId: "",
    });
    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            let obj = {
                sortField: "_id",
                sortOrder: "descend",
                ...params,
            };
            let {data} = await dispatch(
                FeedbackListFxn({
                    ...obj,
                    regExFilters: ["partnerName"],
                })
            );
            resolve(data);
        });
  };
  const events = {
    deleteFeedback: async (id) => {
        let x = await dispatch(DeleteFeedbackFxn({_id: id}));
      if (x && x.success) {
        tableRef.current.reload();
      }
    },
    showResolve: (id) => {
      setFeedbackState({
        visible: true,
        feedbackId: id,
      });
    },
    hideResolve: () => {
      setFeedbackState({
        visible: false,
        feedbackId: "",
      });
      tableRef.current.reload();
    },
  };
  const columns = [
    {
      title: "Serial No.",
      dataIndex: "index",
      key: "index",
      width: 120,
      render: (val, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Company Name",
      dataIndex: "companyId",
      key: "companyId",
      searchTextName: "companyId",
      filterRegex: true,
      render: (text, record) => {
        return <>{text && text.companyName ? text.companyName : null} </>;
      },
    },
    {
      title: "Branch Name",
      dataIndex: "branchId",
      key: "branchId",
      searchTextName: "branchId",
      filterRegex: true,
      render: (text, record) => {
        return <>{text && text.name ? text.name : null} </>;
      },
    },

    {
      title: "Feedback",
      dataIndex: "feedback",
      key: "feedback",
      searchTextName: "feedback",
      filterRegex: true,
    },
      {
          title: "Date",
          dataIndex: "updatedAt",
          key: "updatedAt",
          render: (item, record) => {
              return displayDate(record.updatedAt);
          },
      },

    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      width: 150,
      render: (item, record) => {
        return (
            <React.Fragment>
                {item == "Pending" ? (
                    <span className="pending">Pending</span>
                ) : null}
                {item == "Resolved" ? (
                    <span className="active">Resolved</span>
                ) : null}
            </React.Fragment>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "status",
      key: "status",
      render: (item, record) => {
        return (
            <>
                {record && record.status == "Pending" ? (
                    <div className={"col-md-4"}>
                        <div className={"mt-4-1"}>
                            <Popconfirm
                                title={"Are your sure, you want to Resolve Feedback?"}
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() => {
                                    events.showResolve(record._id);
                                }}
                            >
                                <img
                                    src={imgPath.check}
                                    className="btn-action"
                                    style={{width: "30px", marginRight: "6px"}}
                                />
                            </Popconfirm>
                            {/* <button
                                        className={"btn btn-danger ml10"}
                                        onClick={() => setShowAddStatusInput(false)}
                                    >
                                        Cancel
                                    </button> */}
                            <Popconfirm
                                title={"Are you sure, you want to Delete this Feedback?"}
                                onConfirm={() => {
                                    events.deleteFeedback(record._id);
                                }}
                            >
                                <button className={"btn"}>
                                    <img src={imgPath.close}/>
                                </button>
                            </Popconfirm>
                        </div>
                    </div>
                ) : null}{" "}
            </>
        );
      },
    },
  ];

  return (
      <>
          <TableCard {...props} title={`FeedBack List`}>
              <Table apiRequest={apiRequest} columns={columns} ref={tableRef}/>
          </TableCard>
          {feedbackState.visible ? (
              <ResolveFeedback
                  onClose={events.hideResolve}
                  visible={feedbackState.visible}
                  feedbackId={feedbackState.feedbackId}
              />
          ) : null}
      </>
  );
};

export default FeedBackList;
