import _ from "lodash";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import Card from "../../components/Elements/Card";
import {
    Button,
    Checkbox,
    Drawer,
    Form,
    notification,
} from "../../components/Elements/appUtils";
import {GetEachFormFields} from "../../components/_utils/formUtils";
import {
    getUserCountryStatusWiseNewFxn,
} from "../Users/action";
import {updateApplicationFxn} from "./action";
import {singleCountryStatusListFxn} from "../Country/action";

const UpdateStatus = (props) => {
    let {
        visible,
        studentId,
        applicationId,
        onClose,
        onSubmit,

        form: {getFieldDecorator},
    } = props;
    let {countryId, status, _id, assignedUserId, courseId, universityId} =
        applicationId;

    useEffect(() => {
        setSelectedOption("moveToNextStep");
        handleApplicationStatus();
    }, []);

    const [attachment, setAttachment] = useState({});
    const [applicationStatus, setApplicationStatus] = useState([]);
    const [applicationStatusToCheck, setApplicationStatusToCheck] = useState([]);

    const [userList, setUserList] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [openStatusList, setOpenStatusList] = useState(true);
    const [toShowRollBack, setToShowRollBack] = useState(true);
    useEffect(() => {
        events.chooseStatus(selectedOption);
    }, [applicationStatusToCheck]);
    let dispatch = useDispatch();

    const loadUserForAssignApp = async (newStatus) => {
        let obj = {
            countryId: countryId._id,
            status: newStatus,
        };
        let {data, success} = await dispatch(getUserCountryStatusWiseNewFxn(obj));
        if (success) {
            setUserList(data);
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setAttachment(file);
    };

    const events = {
        handleRadioChange: (e) => {
            if (e == "rollBack") {
                props.form.setFieldsValue({
                    status: undefined,
                    assignedUserId: undefined,
                    note: undefined,
                });
                setUserList([]);
                setSelectedOption(e);
                events.chooseStatus(e);
            } else if (e == "moveToNextStep") {
                props.form.setFieldsValue({
                    assignedUserId: undefined,
                    note: undefined,
                });

                setOpenStatusList(true);
                setToShowRollBack(true);
                setSelectedOption(e);
                events.chooseStatus(e);
            }
        },
        chooseStatus: (option) => {
            const regexPattern = new RegExp(`^${status}$`, "i");
            const statusIndex = applicationStatusToCheck.findIndex((item) =>
                regexPattern.test(item.status)
            );
            if (statusIndex !== -1 && statusIndex < applicationStatusToCheck.length - 1 && option == "moveToNextStep") {
                let nextStatusIndex;

                if (applicationStatusToCheck[statusIndex].active) {
                    nextStatusIndex = applicationStatusToCheck.findIndex(
                        (item, index) =>
                            index > statusIndex &&
                            item.active &&
                            item.statusType === "Offer Process"
                    );

                    if (nextStatusIndex === -1) {
                        const newApplicationStatus = applicationStatusToCheck.filter(
                            (item, index) =>
                                index > statusIndex &&
                                item.active &&
                                item.statusType == "Visa Process"
                        );
                        setOpenStatusList(false);

                        setApplicationStatus(newApplicationStatus);
                    }
                } else {
                    nextStatusIndex = applicationStatusToCheck.findIndex(
                        (item, index) =>
                            index > statusIndex &&
                            !item.active &&
                            item.statusType === "Offer Process"
                    );
                }

                if (nextStatusIndex !== -1) {
                    setTimeout(() => {
                        props.form.setFieldsValue({
                            status: applicationStatusToCheck[nextStatusIndex].status,
                        });
                    }, 100);
                }
            } else if (option == "rollBack" && statusIndex !== -1) {
                if (
                    applicationStatusToCheck &&
                    applicationStatusToCheck[statusIndex] &&
                    applicationStatusToCheck[statusIndex].statusType
                        ? applicationStatusToCheck[statusIndex].statusType == "Visa Process"
                        : null
                ) {
                    setToShowRollBack(false);
                } else {
                    const newApplicationStatus = applicationStatusToCheck
                        .slice(0, statusIndex)
                        .filter(
                            (item) => item.active && item.statusType == "Offer Process"
                        );
                    setApplicationStatus(newApplicationStatus);
                }

            }
        },

    };

    let inputTypes = {
        fields: [
            {
                key: "status",
                label: "Status ",
                // required: true,
                type: "select",
                span: "col-md-3",
                showSearch: true,
                allowClear: true,
                disabled: openStatusList == true && selectedOption == "moveToNextStep",
                options: applicationStatus,
                placeholder: "Change Status",
                keyAccessor: (x) => x.status,
                valueAccessor: (x) => `${x.status}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        status: x,
                    });
                    // loadUserForAssignApp(x);
                },
            },
            // {
            //   key: "assignedUserId",
            //   label: "Assign To",
            //   required: true,
            //   placeholder: "Assign to",
            //   type: "select",
            //   options: userList,
            //   keyAccessor: (x) => x._id,
            //   valueAccessor: (x) => `${x.name}`,
            //   onChange: (x) => {
            //     props.form.setFieldsValue({
            //       assignedUserId: x,
            //     });
            //   },
            // },

            {
                key: "attachment",
                label: "Attachment",
                type: "file",
                onChange: handleFileChange,
            },
            {
                key: "note",
                label: "Details",
                placeholder: "Details",
                type: "textarea",
                span: "col-md-12",
            },
        ],
    };

    const handleSubmit = (e) => {
        const {form} = props;
        e.preventDefault();
        form.validateFields(async (err, valData) => {
            if (!err) {
                if (!valData.status) {
                    notification.warning({
                        message: "Please choose status"
                    })
                    return
                }
                valData.studentId = studentId;
                valData.oldStatus = status;
                valData.applicationId = _id;
                valData.courseId = courseId._id;
                valData.countryId = countryId._id;
                valData.universityId = universityId._id;
                // valData.assignedUserId = assignedUserId._id;
                valData.actionToBeTaken = selectedOption;
                let fd = new FormData();

                fd.append("obj", JSON.stringify(valData));
                if (attachment && attachment.name) {
                    fd.append("documents", attachment);
                }
                let x = await dispatch(updateApplicationFxn(fd));
                if (!x.error) {
                    onSubmit();
                }
            } else {
                if (err.assignedUserId) {
                    notification.warning({
                        message: `Please Select Agent`,
                    });
                    return;
                }
            }
        });
    };
    const handleApplicationStatus = async () => {
        const {data} = await singleCountryStatusListFxn({countryId: countryId._id});
        let statusListArr = []
        _.each(data, (item) => {
            statusListArr.push(item.statusList);
        })

        if (statusListArr && statusListArr.length) {
            setApplicationStatus(statusListArr);
            setApplicationStatusToCheck(statusListArr);
        }
    };

    return (
        <Drawer
            visible={visible}
            onClose={onClose}
            width={"51%"}
            title={"Update Status"}
            placement="right"
        >
            <div className={'mb-3'}>
                <label className={"me-3"}>
                    <Checkbox
                        value="moveToNextStep"
                        name={"moveToNextStep"}
                        className={"me-1"}
                        onChange={(e, name) => {
                            events.handleRadioChange(name);
                        }}
                        checked={selectedOption == "moveToNextStep"}
                    />
                    Move To Next Step
                </label>

                <label>
                    <Checkbox
                        value="rollBack"
                        name={"rollBack"}
                        className={"me-1"}
                        onChange={(e, name) => {
                            events.handleRadioChange(name);
                        }}
                        checked={selectedOption == "rollBack"}
                    />
                    Roll Back
                </label>
            </div>
            {selectedOption && toShowRollBack ? (
                <Form onSubmit={handleSubmit}>
                    <div className={"row"}>
                        {inputTypes.fields.map((item, key) => {
                            return (
                                <div className={"col-md-12 pt-3"} key={key}>
                                    <GetEachFormFields {...props.form} item={item}/>
                                </div>
                            );
                        })}
                    </div>
                    <div style={{marginTop: "20px"}}>
                        <Button type={"primary"} htmlType="submit">
                            submit
                        </Button>
                    </div>
                </Form>
            ) : (
                "Please Contact Admin To Roll-Back"
            )}

        </Drawer>
    );
};
export default Form.create()(UpdateStatus);
