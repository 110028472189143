import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {
    Button,
    Card,
    DefaultTablePagination,
    Form,
    Table,
    displayDate,
    notification,
} from "../../components/Elements/appUtils";
import {GetEachFormFields} from "../../components/_utils/formUtils";
import {AddFeedbackFxn, FeedbackListFxn} from "./action";

let {TableCard} = Card;
const Feedback = (props) => {
    let tableRef = useRef();
    let dispatch = useDispatch();
    const handleSubmit = (e) => {
        const {form} = props;
        e.preventDefault();
        form.validateFields(async (err, valData) => {
            if (!err) {
                let x = await dispatch(AddFeedbackFxn(valData));
                if (!x.error) {
                    tableRef.current.reload();
                    props.form.setFieldsValue({
                        feedback: "",
                    });
                }
            } else {
                if (err.feedback) {
                    notification.warning({message: "Please Enter Feedback"});
                    return;
                }
            }
        });
    };
    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            let obj = {
                sortField: "_id",
                sortOrder: "descend",
                ...params,
            };
            let {data} = await dispatch(
                FeedbackListFxn({
                    ...obj,
                    regExFilters: ["partnerName"],
                })
            );
            console.log(data, "-----------------------");
            resolve(data);
        });
    };

    const columns = [
        {
            title: "Serial No.",
            dataIndex: "index",
            key: "index",
            width: 120,
            render: (val, record, index) => {
                return index + 1;
            },
        },

        {
            title: "Feedback",
            dataIndex: "feedback",
            key: "feedback",
            // searchTextName: "feedback",
            // filterRegex: true,
        },
        {
            title: "Date",
            dataIndex: "updatedAt",
            key: "updatedAt",
            render: (item, record) => {
                return displayDate(record.updatedAt);
            },
        },
    ];

    let inputTypes = {
        fields: [
            {
                key: "feedback",
                label: "FeedBack",
                required: true,
                showStar: true,
                placeholder: "Please Enter Feedback",
                type: "textarea",
            },
        ],
    };
    return (
        <>
            <Card title={"Add Feedback"}>
                <Form onSubmit={handleSubmit}>
                    <div className={"row"}>
                        {inputTypes.fields.map((item, key) => {
                            return (
                                <div className={"col-md-12 pt-3"} key={key}>
                                    <GetEachFormFields {...props.form} item={item}/>
                                </div>
                            );
                        })}
                    </div>

                    <Button
                        type={"primary"}
                        htmlType="submit"
                        className="btn main-btn mt-4"
                    >
                        submit
                    </Button>
                </Form>
            </Card>
            <TableCard title={"Feedback List"}>
                <Table
                    apiRequest={apiRequest}
                    columns={columns}
                    pagination={DefaultTablePagination()}
                    ref={tableRef}
                />
            </TableCard>
        </>
    );
};

export default Form.create()(Feedback);
