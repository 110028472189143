import React, {useEffect, useState} from 'react'
import {Button, Card, Form} from "../../../components/Elements/appUtils";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import {getRelatedStateListFxn} from "../city/action";
import {loadCountryList} from "../state/action";
import {GetRelatedCities, GetRelatedUniversityFxn, postRelatedUniversityFxn} from "../../University/action";
import {useDispatch} from 'react-redux';


const initialState = {
    universityCountry: "",
    universityState: "",
    universityCity: "",
    universityName: "",
    universityWebsite: "",
    contactAddress: "",
    contactName: "",
    contactPhone: "",
    contactEmail: "",
    smallLogo: {
        //   path: "",
        //   fileName: "",
    },
    logo: {},
    interview: "",
};

const EditUniversity = (props) => {
    const {getFieldDecorator} = props.form;
    let dispatch = useDispatch()
    // let[formState, setState] = useState(initialState);
    const [smallLogo, setSmallLogo] = useState("");
    const [logo, setLogo] = useState("");
    const [universityLogo, setUniversityLogo] = useState({});
    const [universitySmallLogo, setUniversitySmallLogo] = useState({})
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [countryId, setCountryData] = useState("");
    const [universityId, setUniversityId] = useState("");
    const [stateId, setStateId] = useState("");


    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setLogo(file)
    }

    const handleLogoChange = (e) => {
        const file = e.target.files[0];
        setSmallLogo(file);
    }

    const handleSubmit = (e) => {
        const {form} = props;
        e.preventDefault();
        form.validateFields(async (err, valData) => {
            if (!err) {
                valData.universityId = universityId
                let fd = new FormData();
                fd.append("obj", JSON.stringify(valData));
                if (logo && logo.name) {
                    fd.append("logo", logo);
                }
                if (smallLogo && smallLogo.name) {

                    fd.append("smallLogo", smallLogo)
                }
                let x = await dispatch(postRelatedUniversityFxn(fd));
                console.log(x, 'cccccccccccccccccccc');
            }
        });
    }

    useEffect(() => {
        loadCountryFxn();
        getCampusData();
    }, []);

    const loadCountryFxn = async () => {
        let x = await loadCountryList();
        setCountryList(x);
    };

    const getRelatedStates = async (id) => {
        console.log(id, 'idiidididi');
        if (id) {
            setCountryData(id);
            let {data} = await getRelatedStateListFxn(id);
            setStateList(data);
        }
    };

    const getRelatedCity = async (stateId, countryId) => {
        let data = await GetRelatedCities({stateId, countryId});
        console.log(stateId, countryId, 'iddddddddddddddd');

        setCityList(data.data);
    };


    const getCampusData = async () => {
        let searchParams = new URLSearchParams(window.location.search)
        let id = searchParams.get('_id')
        if (id) {
            setUniversityId(id)
            GetRelatedUniversity(id)
        }
    }

    const GetRelatedUniversity = async (id) => {

        let {data} = await dispatch(GetRelatedUniversityFxn(id));
        console.log(data)
        getRelatedStates(data.universityCountry._id)
        setStateId(data.universityState._id)
        getRelatedCity(data.universityState._id, data.universityCountry._id)
        // console.log(data,'datatattatatatat');
        setTimeout(() => {
            props.form.setFieldsValue({
                contactAddress: data.contactAddress,
                contactEmail: data.contactEmail,
                contactName: data.contactName,
                contactPhone: data.contactPhone,
                interview: data.interview,
                universityCity: data.universityCity._id,
                universityCountry: data.universityCountry._id,
                universityState: data.universityState._id,
                universityWebsite: data.universityWebsite,
                universityName: data.universityName,
            });
        }, 500)
        setUniversityLogo(data.logo);
        setUniversitySmallLogo(data.smallLogo);
    }
    let inputTypes = {
        fields: [
            {
                key: "universityCountry",
                label: "University Country",
                required: true,
                placeholder: "Country Name",
                type: "select",
                options: countryList,
                keyAccessor: (x) => x._id,
                valueAccessor: (x) => `${x.countryName}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        universityCountry: x,
                    });
                    getRelatedStates(x);
                },
            },

            {
                key: "universityState",
                label: "University State",
                required: true,
                placeholder: "State Name",
                type: "select",
                options: stateList,
                keyAccessor: (x) => x._id,
                valueAccessor: (x) => `${x.stateName}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        universityState: x,
                    });
                    getRelatedCity(x, countryId);
                },
                span: 8,
            },

            {
                key: "universityCity",
                label: "University City",
                required: true,
                placeholder: "City Name",
                type: "select",
                options: cityList,
                keyAccessor: (x) => x._id,
                valueAccessor: (x) => `${x.cityName}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        universityCity: x,
                    });
                },
                span: 8,
            },
            {
                key: "universityName",
                label: "University Name",
                required: true,
                placeholder: "University  Name",
                type: "text",
            },
            {
                key: "universityWebsite",
                label: "University Website",
                required: true,
                placeholder: "Website",
                type: "text",
            },
            {
                key: "contactAddress",
                label: "Address",
                required: true,
                placeholder: "Address",
                type: "text",
            },
            {
                key: "contactName",
                label: "Contact Name",
                required: true,
                placeholder: "Contact-Name",
                type: "text",
            },
            {
                key: "contactPhone",
                label: "Contact Phone",
                required: true,
                placeholder: "Contact-Phone",
                type: "text",
            },
            {
                key: "contactEmail",
                label: "Contact Email",
                required: true,
                placeholder: "Contact-Email",
                type: "text",
            },
            {
                key: "smallLogo",
                label: " Small Logo",
                type: "file",
                onChange: handleLogoChange,
                urlPath: universitySmallLogo && universitySmallLogo.path ? universitySmallLogo.path : ""
            },
            {
                key: "logo",
                label: " Logo",
                placeholder: "logo",
                type: "file",
                onChange: handleFileChange,
                urlPath: universityLogo && universityLogo.path ? universityLogo.path : ""
            },
            {
                key: "interview",
                label: " Interview",
                required: true,
                placeholder: "interview",
                type: "select",
                options: [
                    {name: "Yes"},
                    {name: "No"},
                    {name: "Maybe"},
                ],
                keyAccessor: (x) => x.name,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        interview: x,
                    });
                },
                span: 8,
            },
        ],
    };
    return (
        <>
            <Card title={"Edit University"}>
                <Form onSubmit={handleSubmit}>
                    <div className={"row"}>
                        {inputTypes.fields.map((item, key) => {
                            return (
                                <div className={"col-md-6 pt-3"} key={key}>
                                    <GetEachFormFields
                                        {...props.form}
                                        item={item}
                                    />
                                </div>
                            );
                        })}
                    </div>

                    <div style={{marginTop: "20px"}}>
                        <Button type={"primary"} htmlType="submit">
                            submit
                        </Button>
                    </div>
                </Form>
            </Card>
        </>
    )
}

export default Form.create()(EditUniversity);
