import React, {useState} from "react";
import {useDispatch} from "react-redux";
import Card from "../../../components/Elements/Card";
import {Button, Drawer, Form} from "../../../components/Elements/appUtils";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import {addNoteFxn} from "../../Student/actions";

const AddNote = (props) => {
    let {
        visible,
        studentId,
        applicationId,
        onClose,
        onSubmit,
        form: {getFieldDecorator},
    } = props;
    const [attachment, setAttachment] = useState({});
    let dispatch = useDispatch();

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setAttachment(file);
    };
    let inputTypes = {
        fields: [
            {
                key: "note",
                label: "Note",
                required: true,
                placeholder: "Note",
                type: "textarea",
                span: "col-md-12",
            },
            {
                key: "attachment",
                label: "Attachment",
                type: "file",
                onChange: handleFileChange,
            },
        ],
    };

    const handleSubmit = (e) => {
        const {form} = props;
        e.preventDefault();
        form.validateFields(async (err, valData) => {
            if (!err) {
                let fd = new FormData();
                valData.studentId = studentId;
                valData.applicationId = applicationId._id;
                fd.append("obj", JSON.stringify(valData));
                if (attachment && attachment.name) {
                    fd.append("attachment", attachment);
                }
                let x = await dispatch(addNoteFxn(fd));
                if (x && x.success) {
                    onSubmit();
                }
            }
        });
    };
    return (
        <Drawer
            visible={visible}
            onClose={onClose}
            width={"45%"}
            title={"Add Note"}
            placement="right"
        >
            <Form onSubmit={handleSubmit}>
                <div className={"row"}>
                    {inputTypes.fields.map((item, key) => {
                        return (
                            <div className={"col-md-12 pt-3"} key={key}>
                                <GetEachFormFields {...props.form} item={item}/>
                            </div>
                        );
                    })}
                </div>
                <div style={{marginTop: "20px"}}>
                    <Button type={"primary"} htmlType="submit">
                        submit
                    </Button>
                </div>
            </Form>
        </Drawer>
    );
};
export default Form.create()(AddNote);
