import _ from "lodash";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {
    Button,
    Card,
    Drawer,
    Form,
    Popconfirm,
    notification, Modal,
} from "../../../components/Elements/appUtils";
import {CurrencyJson} from "../../../components/_utils/appUtils";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import {
  ActivateCountryFxn,
  AddCountryFxn,
  loadPreviousCountryList,
} from "../action";
import StatusComponent from "../components/statusComponent";

const statusInIt = {status: "", index: null};
const ActivateCountry = (props) => {
  const {visible, onClose, onSubmit} = props;
    const {setFieldsValue} = props.form;
  let dispatch = useDispatch();
  const [oldFlag, setOldFlag] = useState({});

  let [countryData, setCountryData] = useState({});
  const [countryList, setCountryList] = useState([]);
  const [countryName, setCountryName] = useState("");
  const [flag, setFlag] = useState({});

  const loadCountryList = async () => {
    let x = await loadPreviousCountryList();
    setCountryList(x);
  };

  // const [status, setStatus] = useState([statusInIt]);
  // const events = {
  //     chooseCountry: (_id) => {
  //         let countryName = _.find(countryList, (item) => {
  //             return item._id == _id;
  //         });
  //         if (countryName) {
  //             props.form.setFieldsValue({
  //                 currencySymbol: countryName.currencySymbol,
  //                 currency: countryName.currency,
  //             });
  //             if (countryName.flag && countryName.flag.path) {
  //                 setOldFlag(countryName.flag);
  //             }
  //             setCountryName(countryName.countryName ? countryName.countryName : "");
  //         }
  //     },
  //     addMore: () => {
  //         setStatus([...status, statusInIt]);
  //     },
  //     deleteItem: (itemId) => {
  //         const updatedItems = status.filter((item, key) => key !== itemId);
  //         setStatus(updatedItems);
  //     },
  //     updateState: (data, index) => {
  //         let cloneStatus = _.clone(status);
  //         cloneStatus[index] = {...cloneStatus[index], ...data};
  //         setStatus(cloneStatus);
  //     },
  // };

  const [status, setStatus] = useState([{status: "", index: 1}]);

  const events = {
    chooseCountry: (_id) => {
      let countryName = _.find(countryList, (item) => {
        return item._id == _id;
      });
      if (countryName) {
        props.form.setFieldsValue({
          currencySymbol: countryName.currencySymbol,
          currency: countryName.currency,
        });
        if (countryName.flag && countryName.flag.path) {
          setOldFlag(countryName.flag);
        }
        setCountryData(countryName);
        setCountryName(countryName.countryName ? countryName.countryName : "");
        setStatus(countryName.statusList);
      }
    },
    addMore: () => {
      const newIndex = status.length + 1;
      setStatus([...status, {status: "", index: newIndex}]);
    },

    deleteItem: (indexToDelete) => {
      const updatedItems = status
          .filter((item) => item.index !== indexToDelete)
          .map((item, index) => ({...item, index: index + 1}));
      setStatus(updatedItems);
    },
    updateState: (data, index) => {
      setStatus((prevStatus) => {
        const cloneStatus = _.clone(prevStatus);
        cloneStatus[index] = {...cloneStatus[index], ...data};
        return cloneStatus;
      });
    },
  };

  let user = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null;

  const getCurrencySymbol = (name) => {
    let symbol = _.find(CurrencyJson, (item) => {
      return item.name == name;
    });
    if (symbol) {
      setFieldsValue({currencySymbol: symbol.value});
    }
  };

  const handleConfirmation = async (fd) => {
    let x = await dispatch(ActivateCountryFxn(fd));
    if (!x.error) {
      props.form.setFieldsValue({
        masterCountryId: "",
        monthlyLivingCost: "",
        visaRequirements: "",
        countryBenefits: "",
        currencySymbol: "",
        currency: "",
      });
      setStatus([statusInIt]);
    }
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFlag(file);
  };

  useEffect(() => {
    loadCountryList();
  }, []);

  const handleSubmit = async (e) => {
    const {form} = props;
    e.preventDefault();
    form.validateFields(async (err, valData) => {
      if (!err) {
        if (!valData.masterCountryId) {
          notification.warning({
            message: "Please Choose Country",
          });
        }
        let masterCountryId = valData.masterCountryId;
        valData = countryData;
        valData.masterCountryId = masterCountryId;
        // valData.statusList = status;
        // valData.countryName = countryName;
        let fd = new FormData();
        if (flag && flag.name) {
          fd.append("flag", flag);
        } else {
          valData.flag = oldFlag;
        }
        fd.append("obj", JSON.stringify(valData));
        let x = await dispatch(ActivateCountryFxn(fd));
        if (!x.error) {
          props.form.setFieldsValue({
              masterCountryId: undefined,
              monthlyLivingCost: "",
              visaRequirements: "",
              countryBenefits: "",
              currencySymbol: "",
              currency: "",
          });
            setStatus([statusInIt]);
            onSubmit()
        }
      } else {
        notification.warning({
          message: "Fill All Required Fields",
        });
      }
    });
  };
  const showPopconfirm = (fd) => {
    return (
        <>
          <Popconfirm
              title={"Are you sure you want to activate the country?"}
              okText="Yes"
              cancelText="No"
              onConfirm={() => handleConfirmation(fd)}
          >
            FF
          </Popconfirm>
        </>
    );
  };
  let inputTypes = {
    fields: [
      {
        key: "masterCountryId",
        label: "Country",
        placeholder: "Country",
        type: "select",
        options: countryList,
        keyAccessor: (x) => x._id,
        valueAccessor: (x) => `${x.countryName}`,
        span: "col-md-12",
        onChange: (x) => {
          props.form.setFieldsValue({
            masterCountryId: x,
          });
          events.chooseCountry(x);
        },
      },
    ],
  };
  return (
      <Modal
          placement="right"
          onClose={onClose}
          visible={visible}
          width="35%"
          title={"Activate Country"}>
          <Form onSubmit={handleSubmit}>
              <div className={"row"}>
                  {inputTypes.fields.map((item, key) => {
                      return !item.hidden ? (
                          <div
                              className={`${item.span ? item.span : "col-md-6"}`}
                              key={key}
                          >
                              <GetEachFormFields {...props.form} item={item}/>
                          </div>
                      ) : null;
                  })}
              </div>

              <Button type={"primary"} htmlType="submit" className="btn main-btn mt-4">
                  submit
              </Button>
          </Form>
      </Modal>
  );
};

export default Form.create()(ActivateCountry);
