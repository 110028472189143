import _, { add } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { RowTable } from "../../../components/Elements/RowTable";

import { useNavigate } from "react-router-dom";
import S from "string";
import {
  Avatar,
  Button,
  Card,
  DropDown,
  Form,
  InputBox,
  Modal,
  Popconfirm,
  Tabs,
  displayDate,
  displayTime,
  documentArray,
  getStatusColor,
} from "../../../components/Elements/appUtils";
import { imgPath } from "../../../components/_utils/_utils";
import { FollowUpList } from "../../Leads/action";
import AddFollowup from "../../Leads/drawers/AddFollowup";
import {
  deleteSingleDocumentFxn,
  getSingleStudentDocumentsFxn,
} from "../../StudentProfile/action";
import UploadNewDocument from "../../StudentProfile/uploadNewDocument";
import {
  getSingleLeadDocumentsFxn,
  singleLeadFxn,
  deleteLeadDocumentFxn,
} from "../action";
import UploadLeadDocument from "../uploadLeadDocument";

const menuOptions = [
  {
    name: "Add Note",
    icon: "fa-plus",
  },
  {
    name: "Notes",
    icon: "fa-list",
  },
];
let { TabPane } = Tabs;

const SingleLead = (props) => {
  const [leadData, setLeadData] = useState();
  const [leadId, setLeadId] = useState("");
  const [followList, setFollowList] = useState([]);
  const [openFollowUp, setOpenFollowUp] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [showModal, setShowModal] = useState(false);
  let [singleDocState, setSingleDocState] = useState({
    visible: false,
    studentId: "",
    item: "",
    documentType: "",
    type: "",
  });
  let [newsingleDocState, setNewSingleDocState] = useState({
    visible: false,
    studentId: "",
    item: "",
  });
  const events = {
    showNewSingleDoc: (studentId) => {
      setNewSingleDocState({
        visible: true,
        studentId,
      });
    },
    hideNewSingleDoc: () => {
      setNewSingleDocState({
        visible: false,
        studentId: "",
      });
      singleStudentData();
    },
    visibleFollowUp: () => {
      setOpenFollowUp(true);
    },
    hideFollowUp: async () => {
      setOpenFollowUp(false);
      // Reload lead data after adding follow-up
      //   await apiRequest();

      // Reload follow-up list
      await loadFollowUpList(leadId);
    },
    showSingleDoc: (documentType, type, studentId) => {
      setSingleDocState({
        visible: true,
        studentId,
        documentType,
        type,
      });
    },
    hideSingleDoc: () => {
      setSingleDocState({
        visible: false,
        studentId: "",
        item: "",
        documentType: "",
        type: "",
      });
      singleStudentData();
    },
    deleteSingleDocument: async (id) => {
      let data = await dispatch(
        deleteLeadDocumentFxn({ documentId: id, leadId })
      );

      console.log(data, "dataaaaa");

      if (data && data.success) {
        setDocuments((prevDocuments) =>
          prevDocuments.map((category) => ({
            ...category,
            documentTypes: category.documentTypes.map((docType) => ({
              ...docType,
              documents: docType.documents.filter((doc) => doc._id !== id),
            })),
          }))
        );
      }
    },
  };
  let dispatch = useDispatch();

  useEffect(() => {
    singleStudentData();
  }, []);

  const getMatchingDocuments = (docType, docCategory) => {
    const matchingDocuments = [];

    if (documents && documents.length) {
      for (const category of documents) {
        // console.log(category, "ddddddddddddd");
        if (category.documentCategory === docCategory) {
          for (const docTypeObj of category.documentTypes) {
            if (docTypeObj.documentType === docType) {
              matchingDocuments.push(...docTypeObj.documents);
            }
          }
        }
      }
    }

    return matchingDocuments;
  };

  const navigate = useNavigate();

  let openLink = (link) => {
    navigate(link);
  };
  const singleStudentData = async () => {
    let searchParams = new URLSearchParams(window.location.search);
    let id = searchParams.get("_id");
    if (id) {
      setLeadId(id);
      apiRequest(id);
      loadFollowUpList(id);
      loadDocuments(id);
    }
  };
  let loadDocuments = async (id) => {
    let { data, success } = await dispatch(getSingleLeadDocumentsFxn(id));

    if (success && data) {
      const otherCategoryIndex = _.findIndex(documentArray, { name: "Other" });

      const otherCategory = _.find(data, { documentCategory: "Other" });
      if (otherCategory) {
        _.each(otherCategory.documentTypes, (docTypeObj) => {
          const documentTypeToAdd = docTypeObj.documentType;
          if (
            !documentArray[otherCategoryIndex].documents.includes(
              documentTypeToAdd
            )
          ) {
            documentArray[otherCategoryIndex].documents.push(documentTypeToAdd);
          }
        });
      }
      setDocuments(data);
    }
  };
  const loadFollowUpList = async (leadId) => {
    let obj = {
      leadId,
      sortField: "_id",
      sortOrder: "descend",
    };
    let { data } = await dispatch(FollowUpList(obj));
    setFollowList(data);
  };

  let apiRequest = async (id) => {
    let { data } = await dispatch(singleLeadFxn(id));
    console.log(data, "single lead profile");
    setLeadData(data);
  };

  return (
    <>
      <div className="container-fluid pt-4 px-4">
        <div className="profile-box student-single-profile card-box border-grey mb-4 p-4">
          <div className="pic">
            <div className="upload-img">
              <span>
                <img src="../app/img/user.svg" alt="" className="w-100 h-100" />
              </span>
              <div className="upload-btn">
                <input type="file" name="" id="" />

                <i className="fa fa-pencil-alt"></i>
              </div>
            </div>
            <div className="content-box">
              <h4>{leadData && leadData.name ? leadData.name : null}</h4>
              <p>Marketing Manager</p>

              {leadData && leadData.status ? (
                <>
                  {leadData.status === "Pending" ? (
                    <span className="pending">Pending</span>
                  ) : leadData.status === "Done" ? (
                    <span className="active">Done</span>
                  ) : leadData.status === "Closed" ? (
                    <span className="inactive">Closed</span>
                  ) : null}
                </>
              ) : null}
            </div>
          </div>
          <div className="detail">
            <ul>
              <li>
                <span>Name:</span>
                {leadData && leadData.name ? leadData.name : null}
              </li>
              <li>
                <span>City:</span>
                {leadData && leadData.cityName ? leadData.cityName : null}
              </li>
              <li>
                <span>Mobile:</span>
                {leadData && leadData.mobileNo
                  ? leadData.countryCode.substring(3) + " " + leadData.mobileNo
                  : null}
              </li>
              <li>
                <span>Pincode:</span>
                {leadData && leadData.pincode ? leadData.pincode : null}
              </li>
              <li>
                <span>Email:</span>
                {leadData && leadData.email ? leadData.email : null}
              </li>
              <li>
                <span>Address:</span>
                {leadData && leadData.address ? leadData.address : null}
              </li>
              <li>
                <span>Country:</span>
                {leadData && leadData.countryName ? leadData.countryName : null}
              </li>
              <li>
                <span>State:</span>
                {leadData && leadData.stateName ? leadData.stateName : null}
              </li>
            </ul>
          </div>
        </div>

        <div className="information-box card-box border-grey p-4 mb-4">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="Profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#Profile"
                type="button"
                role="tab"
                aria-controls="Profile"
                aria-selected="true"
              >
                Profile
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="FollowsUp-tab"
                data-bs-toggle="tab"
                data-bs-target="#FollowsUp"
                type="button"
                role="tab"
                aria-controls="FollowsUp"
                aria-selected="false"
              >
                FollowsUp
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="Documents-tab"
                data-bs-toggle="tab"
                data-bs-target="#Documents"
                type="button"
                role="tab"
                aria-controls="Documents"
                aria-selected="false"
              >
                Documents
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="Education-tab"
                data-bs-toggle="tab"
                data-bs-target="#Education"
                type="button"
                role="tab"
                aria-controls="Education"
                aria-selected="false"
              >
                Education
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="Experience-tab"
                data-bs-toggle="tab"
                data-bs-target="#Experience"
                type="button"
                role="tab"
                aria-controls="Experience"
                aria-selected="false"
              >
                Experience
              </button>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="Profile"
              role="tabpanel"
              aria-labelledby="Profile-tab"
            >
              {" "}
              <div className="detail-box">
                <ul>
                  <li>
                    <span>Name:</span>
                    {leadData && leadData.name ? leadData.name : null}
                  </li>
                  <li>
                    <span>Date:</span>
                    {leadData && leadData.dateOfBirth
                      ? displayDate(leadData.dateOfBirth)
                      : null}
                  </li>

                  <li>
                    <span>Gender:</span>
                    {leadData && leadData.gender ? leadData.gender : null}
                  </li>
                  <li>
                    <span>Marital Status:</span>
                    {leadData && leadData.maritalStatus
                      ? leadData.maritalStatus
                      : null}
                  </li>
                  <li>
                    <span>Address:</span>
                    {leadData && leadData.address ? leadData.address : null}
                  </li>
                  <li>
                    <span>Country:</span>
                    {leadData && leadData.countryName
                      ? leadData.countryName
                      : null}
                  </li>
                  <li>
                    <span>State:</span>
                    {leadData && leadData.stateName ? leadData.stateName : null}
                  </li>
                  <li>
                    <span>City:</span>
                    {leadData && leadData.cityName ? leadData.cityName : null}
                  </li>
                </ul>
              </div>
              <div className="detail-box">
                <h3>Education</h3>

                {leadData?.education?.map((item) => {
                  return (
                    <>
                      <ul>
                        <li>
                          <span>Qualification:</span>
                          {item.qualification}{" "}
                        </li>
                        <li>
                          <span>Board:</span>
                          {item.board}{" "}
                        </li>
                        <li>
                          <span>School Name:</span>
                          {item.schoolName}
                        </li>
                        <li>
                          <span>Passing Year:</span>
                          {item.passingYear}{" "}
                        </li>
                        <li>
                          <span>Percentage:</span>
                          {item.percentage}
                        </li>
                        <li>
                          <span>Stream:</span>
                          {item.stream}{" "}
                        </li>
                      </ul>
                    </>
                  );
                })}
              </div>
              <div className="detail-box">
                <h3>English Test Score</h3>
                <ul>
                  <li>
                    <span>English Exam Type:</span>{" "}
                    {leadData && leadData.englishRequirement?.examType
                      ? leadData.englishRequirement?.examType
                      : null}
                  </li>
                  <li>
                    <span>Examination Date:</span>{" "}
                    {leadData && leadData.englishRequirement?.examinationDate
                      ? displayDate(
                          leadData.englishRequirement?.examinationDate
                        )
                      : null}
                  </li>
                  <li>
                    <span>Overall Score:</span>{" "}
                    {leadData && leadData.englishRequirement?.overall
                      ? leadData.englishRequirement?.overall
                      : null}
                  </li>
                  <li>
                    <span>Speaking:</span>{" "}
                    {leadData && leadData.englishRequirement?.speaking
                      ? leadData.englishRequirement?.speaking
                      : null}
                  </li>
                  <li>
                    <span>Reading:</span>{" "}
                    {leadData && leadData.englishRequirement?.reading
                      ? leadData.englishRequirement?.reading
                      : null}
                  </li>
                  <li>
                    <span>Writing:</span>{" "}
                    {leadData && leadData.englishRequirement?.writing
                      ? leadData.englishRequirement?.writing
                      : null}
                  </li>
                  <li>
                    <span>Listening:</span>{" "}
                    {leadData && leadData.englishRequirement?.listening
                      ? leadData.englishRequirement?.listening
                      : null}
                  </li>
                  <li></li>
                </ul>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="FollowsUp"
              role="tabpanel"
              aria-labelledby="FollowsUp-tab"
            >
              {" "}
              {leadData && leadData.status == "Closed" ? (
                ""
              ) : (
                <button
                  className="btn blue-btn ms-auto mb-3"
                  onClick={() => events.visibleFollowUp()}
                >
                  Add FollowUp
                </button>
              )}
              <div className="information-box follow_ups">
                <div className={"followup_list"}>
                  <div className="added-on">
                    <ul style={{ flexDirection: "column" }}>
                      {followList && followList.length
                        ? followList.map((item, index) => {
                            return (
                              <>
                                <li className="active">
                                  <div className="head">
                                    <h5 className="m-0">Added on</h5>
                                    <span>
                                      <i className="fa fa-calendar-alt"></i>{" "}
                                      {displayDate(item.createdAt)}
                                    </span>
                                  </div>
                                  <p className="m-0">{item.notes}</p>
                                  <span className="date">
                                    <i className="fa fa-calendar-alt"></i> Next
                                    Followup: {displayDate(item.date)}
                                  </span>
                                </li>
                              </>
                            );
                          })
                        : null}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="Documents"
              role="tabpanel"
              aria-labelledby="Documents-tab"
            >
              <a
                href="#"
                style={{
                  marginLeft: "82%",
                  color: "#3E6AB4",
                  textDecoration: "underline",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  events.showNewSingleDoc(leadData._id);
                }}
              >
                Add Other-Document
              </a>
              {documentArray.map((eachDoc) => {
                return (
                  <>
                    <div className="detail-box">
                      <div className="flex box">
                        <h3>{eachDoc.name}</h3>
                      </div>
                      <div className={"upload_form"}>
                        <div className="col-lg-12 col-12 mb-4">
                          <div className="form-group">
                            <div>
                              {eachDoc.documents && eachDoc.documents.length
                                ? eachDoc.documents.map((docType, index) => {
                                    const matchingDocuments =
                                      getMatchingDocuments(
                                        docType,
                                        eachDoc.name
                                      );
                                    return (
                                      <div className="card-box border-grey p-3 mb-4">
                                        <div
                                          className="flex-box"
                                          style={{
                                            flexWrap: "nowrap",
                                            margin: 0,
                                          }}
                                        >
                                          <label className="form-control">
                                            {docType}{" "}
                                            {matchingDocuments.map(
                                              (document) => (
                                                <span>
                                                  {document.name.length > 6
                                                    ? document.name.substring(
                                                        0,
                                                        6
                                                      ) +
                                                      "[...]" +
                                                      document.name.slice(-4)
                                                    : document.name}
                                                  <button
                                                    type="button"
                                                    className="p-0"
                                                  >
                                                    <Popconfirm
                                                      title="Are you sure, you want to delete this Document?"
                                                      onConfirm={() => {
                                                        events.deleteSingleDocument(
                                                          document._id
                                                        );
                                                      }}
                                                      okText="Yes"
                                                      cancelText="No"
                                                    >
                                                      <span>
                                                        <i
                                                          className="fa fa-times"
                                                          // onClick={() =>
                                                          //   events.deleteSingleDocument(
                                                          //     document._id
                                                          //   )
                                                          // }
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                        ></i>
                                                      </span>
                                                    </Popconfirm>
                                                  </button>
                                                </span>
                                              )
                                            )}
                                          </label>
                                          <div className="upload">
                                            <a
                                              className="btn blue-btn"
                                              onClick={(e) => {
                                                events.showSingleDoc(
                                                  docType,
                                                  eachDoc.name,
                                                  leadId._id
                                                );
                                              }}
                                            >
                                              <img
                                                src={imgPath.upload}
                                                alt=""
                                              />
                                              Upload
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
            <div
              className="tab-pane fade"
              id="Education"
              role="tabpanel"
              aria-labelledby="Education-tab"
            >
              <div className="detail-box">
                <h3>Education</h3>

                {leadData?.education?.map((item) => {
                  return (
                    <>
                      <ul>
                        <li>
                          <span>Qualification:</span>
                          {item.qualification}{" "}
                        </li>
                        <li>
                          <span>Percentage:</span>
                          {item.percentage}{" "}
                        </li>
                        <li>
                          <span>University:</span>
                          {item.university}
                        </li>
                        <li>
                          <span>Passing Year:</span>
                          {item.passingYear}{" "}
                        </li>
                        <li>
                          <span>CourseName:</span>
                          {item.courseName}
                        </li>
                        {/*<li><span>Stream:</span>{item.stream} </li>*/}
                      </ul>
                    </>
                  );
                })}
                {/*leadData &&
                  leadData?.education.map((item, index) => {
                    return (
                      <ul key={index}>
                        {Object.entries(item).map(
                          ([key, value]) =>
                            key !== "id" &&
                            key !== "_id" &&
                            value && (
                              <li key={key}>
                                <span> {key}:</span> {value}
                              </li>
                            )
                        )}
                      </ul>
                    );
                  })*/}
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="Experience"
              role="tabpanel"
              aria-labelledby="Experience-Tab"
            >
              <div className="detail-box">
                <h3>Experience</h3>

                {leadData &&
                  leadData?.workExperience.map((item) => {
                    return (
                      <>
                        <ul>
                          <li>
                            <span>Company Name:</span>
                            {item.companyName}
                          </li>
                          <li>
                            <span>Position:</span>
                            {item.position}
                          </li>
                          <li>
                            <span>Time:</span>
                            {item.time}
                          </li>
                        </ul>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
      {newsingleDocState.visible ? (
        <UploadNewDocument
          uploadType={"Lead"}
          {...newsingleDocState}
          onClose={events.hideNewSingleDoc}
          leadId={leadId}
          onSubmit={() => {
            events.hideNewSingleDoc();
          }}
        />
      ) : null}
      {openFollowUp && leadId ? (
        <AddFollowup
          onClose={events.hideFollowUp}
          onSubmit={events.hideFollowUp}
          visible={openFollowUp}
          lead={leadData}
          leadId={leadId}
        />
      ) : null}
      {singleDocState.visible ? (
        <UploadLeadDocument
          {...singleDocState}
          onClose={events.hideSingleDoc}
          leadId={leadId}
          onSubmit={() => {
            events.hideSingleDoc();
          }}
        />
      ) : null}
    </>
  );
};
export default SingleLead;
