import React, {useEffect, useState} from "react";
import {InputBox, Select, Card} from "../../../components/Elements/appUtils";
import {inputFieldsOptions} from "../../../components/_utils/_utils";
import {getLeadFieldListFxn} from "../../Leads/action";

const LeadComponent = (props) => {
    let {data, index, updateState, deleteItem} = props;


    return (
        <>
            <Card key={index} padding={10}>
                <div className={'row'}>

                    <div name="status" className={"col-md-5"}>
                        <InputBox title={`${index + 1}. Field Name`} className={"rowFlex"}>
                            <input
                                required={true}
                                placeholder="Please enter field name"
                                className={"form-control"}
                                onChange={({target}) => {
                                    updateState({name: target.value}, index);
                                }}
                                value={data.name}
                            />

                        </InputBox>
                    </div>

                    <div className={"col-md-5"}>
                        <InputBox title={"Inputs"}>
                            <select
                                className={"form-control"}
                                value={data.inputType}
                                onChange={({target}) => {
                                    updateState({inputType: target.value}, index);
                                }}
                            >
                                <option value={""}>Choose</option>
                                {inputFieldsOptions.map((item) => {
                                    return (
                                        <option key={item.option} value={item.option}>
                                            {item.label}
                                        </option>
                                    );
                                })}
                            </select>


                        </InputBox>

                    </div>

                    <div className="col-md-2" style={{paddingTop: "45px"}}>
                        {index !== 0 ? (
                            <a>
                                <i
                                    className="fa fa-trash new-icon"
                                    style={{marginTop: "25x"}}
                                    aria-hidden="true"
                                    onClick={() => deleteItem(index)}
                                ></i>
                            </a>
                        ) : null}
                    </div>

                    <div className={"col-md-12"}>
                        {data.inputType == "select" ? (
                            <InputBox title={"Options: Value Seperated By Comma"}>
                                <input
                                    required={true}
                                    placeholder="Please options"
                                    className={"form-control"}
                                    onChange={({target}) => {
                                        updateState({option: target.value}, index);
                                    }}
                                    value={data.option}
                                />
                            </InputBox>
                        ) : null}
                    </div>

                </div>
            </Card>
        </>
    );
};

export default LeadComponent;
