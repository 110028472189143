import _ from "lodash";
import moment from "moment";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {
    Button,
    Card,
    DocumentTypes,
    Form,
    InputBox,
    Modal,
    documentArray,
    notification,
} from "../../components/Elements/appUtils";
import {GetEachFormFields} from "../../components/_utils/formUtils";
import {documentList} from "../Document/action";
import {addDocumentToLeadFxn} from "../SingleLead/action";
import {addDocumentToStudentFxn} from "./action";

let initialState = {
    fileList: [],
    uploadKey: moment(),
    fileName: "",
};
const UploadNewDocument = (props) => {
    let tableRef = useRef();
    let {
        visible,
        studentId,
        leadId,
        uploadType,
        onClose,
        documentType,
        type,
        form: {getFieldValue},
    } = props;
    let dispatch = useDispatch();
    const [state, setState] = useState(initialState);
    const [note, setNote] = useState("");

    const [documentTypes, setDocumentTypes] = useState([]);

    const events = {
        getRelatedDocumentTypes: (name) => {
            let document = _.find(documentArray, (item) => {
                return item.name == name;
      });
      if (document) {
        setDocumentTypes(document.documents);
      }
    },
    chooseDocument: (e) => {
        let {name, files} = e.target;
      if (getFieldValue("documentType") == undefined) {
        notification.warning({
          message: "Please choose Document-Type",
        });
        return;
      }

      if (files && files.length) {
        setState({
          ...state,
          [getFieldValue("documentType")]: files[0],
          fileName: files[0].name,
        });
      }
    },
    handleSubmit: async (e) => {
        const {form} = props;
      e.preventDefault();
      form.validateFields(async (err, valData) => {
          if (
              !(state[valData.documentType] && state[valData.documentType].name)
          ) {
              notification.warning({
                  message: "Please Choose Document",
              });
              return;
          }
          // if (!valData.note) {
          //     notification.warning({
          //         message: "Please Enter Note",
          //     });
          //     return;
          // }

          if (uploadType == "Student") {
              valData.studentId = studentId;
          } else {
              valData.leadId = leadId;
          }
          valData.documentCategory = valData.type;

          let fd = new FormData();
          fd.append("obj", JSON.stringify(valData));
          if (state[valData.documentType] && state[valData.documentType].name) {
              fd.append("documents", state[valData.documentType]);
          }
          if (uploadType == "Student") {
              console.log("Student");
              let x = await dispatch(addDocumentToStudentFxn(fd));
              if (!x.error) {
                  onClose();
              }
          } else {
              console.log("Leadddddddddddd");

              let x = await dispatch(addDocumentToLeadFxn(fd));
              if (!x.error) {
                  onClose();
              }
          }
      });
    },
  };

  let inputTypes = {
    fields: [
      {
        key: "type",
        label: "Type",
        required: false,
        type: "select",

        options: documentArray,
        keyAccessor: (x) => x.name,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            type: x,
            documentType: undefined,
          });
          events.getRelatedDocumentTypes(x);
        },
        span: 12,
        placeholder: "Type",
      },
      {
        key: "documentType",
        label: "Document Type",
        required: true,
        type: "select",
        hidden: getFieldValue("type") === "Other",
        options: documentTypes,
        keyAccessor: (x) => x,
        valueAccessor: (x) => `${x}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            documentType: x,
          });
        },
        span: 12,
        placeholder: "Document Type",
      },
        {
            key: "documentType",
            label: "Document Type",
            required: true,
            hidden: !(getFieldValue("type") === "Other"),
            span: 12,
            placeholder: "Document Type",
            type: "text",
        },
        {
            key: `documentFile`,
            label: "Document File",
            required: false,
            span: 12,

            placeholder: "Document File",
            type: "file",
            fileName:
                state && state[getFieldValue("documentType")]
                    ? state[getFieldValue("documentType")]
                    : "",
            // fileName: "ddddddd",
            onChange: (target) => {
                events.chooseDocument(target);
            },
        },
        // {
        //   key: "note",
        //   label: "Note",
        //   required: false,
        //   span: 12,
        //   placeholder: "Note",
        //   type: "textArea",
        // },
    ],
  };
  _.each(DocumentTypes, (documentType) => {
    initialState[documentType] = "";
  });
  return (
      <Modal
          visible={visible}
          onClose={onClose}
          width={"40%"}
          // height={"100%"}
          title={
              <>
                  <div className="d-flex">Upload Document</div>
              </>
          }
          placement="right"
      >
          <Card shadow={false} padding={false}>
              <Form onSubmit={events.handleSubmit}>
                  <div className={"row"}>
                      {inputTypes.fields.map((item, key) => {
                          return (
                              <div
                                  className={`${item.span ? item.span : "col-md-6"}`}
                                  key={key}
                              >
                                  <GetEachFormFields {...props.form} item={item}/>
                              </div>
                          );
                      })}
                  </div>
                  <Button
                      type={"primary"}
                      htmlType="submit"
                      className="btn main-btn mt-4"
                  >
                      Submit
                  </Button>
              </Form>
          </Card>
      </Modal>
  );
};
export default Form.create()(UploadNewDocument);
