import React, {useEffect, useState} from "react";
import {DropDown} from "../../components/Elements/appUtils";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {GetEachFormFields} from "../../components/_utils/formUtils";
import {Modal, Button, Card, Form} from "../../components/Elements/appUtils";
import {notification} from "../../components/Elements/appUtils";

const $ = window.$;
const TopMenu = (props) => {
    let dispatch = useDispatch()
    const navigate = useNavigate();

    let {currentUser} = props;
    const [open, setOpen] = useState(false);
    const showModal = (id) => {

        setOpen(true);

    };
    const onClose = () => {
        setOpen(false);
    };
    useEffect(() => {
        loadScript();
    }, []);

    const loadScript = () => {
        $(window).scroll(function () {
            if ($(this).scrollTop() > 300) {
                $(".back-to-top").fadeIn("slow");
            } else {
                $(".back-to-top").fadeOut("slow");
            }
        });
        $(".back-to-top").click(function () {
            $("html, body").animate({scrollTop: 0}, 1500, "easeInOutExpo");
            return false;
        });

        // Sidebar Toggler
        $(".sidebar-toggler").click(function () {
            $(".sidebar, .content").toggleClass("open");
            return false;
        });
    };


    const menuItems = [
        {
            name: "Edit Profile",
            icon: "fa-user",
        },
        {
            name: "Change Password",
            icon: "fa-key",
        },
        {
            name: "Feedback",
            icon: "fa-user",
        },
        {
            name: "Request for Training",
            icon: "fa-user",
        },
        {
            name: "Logout",
            icon: "fa-sign-out-alt",
        },
    ];
    const logoutFxn = () => {
        localStorage.clear();
        setTimeout(() => {
            dispatch({
                type: "SET_CURRENT_USER",
                user: {},
            });
            window.location.href = "/login";
        }, 100);
    };

    const menuEvents = (eventName) => {
        if (eventName == "Logout") {
            logoutFxn()
        } else if (eventName == 'Edit Profile') {
            navigate(`/edit-my-profile`);

        } else if (eventName == 'Change Password') {
            navigate('/change-password')
        } else if (eventName == 'Feedback') {
            navigate('/add-feedback')
        } else if (eventName == 'Request for Training') {
            navigate('/training-request')
        }
    }

    const handleSubmitPasswordChange = (e) => {
        const {form} = props;
        e.preventDefault();
        form.validateFields(async (err, valData) => {
            if (!err) {
                if (!valData.currentPassword) {
                    notification.warning({message: "Enter your current password"});
                    return;
                }
                if (!valData.newPassword) {
                    notification.warning({message: "Enter your new password"});
                    return;
                }
                if (valData.newPassword !== valData.confirmPassword) {
                    notification.warning({message: "New password and confirm password do not match"});
                    return;
                }


                onClose();
            } else {
                notification.warning({message: "Please Fill All Required Fields"});
            }
        });
    };

    return (
        <>
            <nav className="navbar navbar-expand navbar-light sticky-top p-4 justify-content-between">
                <div className="main-head d-flex align-items-center">
                    <a href="#" className="sidebar-toggler flex-shrink-0">
                        <i className="fa fa-bars"></i>
                    </a>
                    <h1 className="main-heading px-3">Dashboard</h1>
                </div>

                {/* <form className="d-none d-md-flex">
                    <img src="../app/img/search.svg" alt=""/>
                    <input className="form-control border-0" type="search"
                           placeholder="Search Something..."/>
                </form>*/}
                <div className="navbar-nav align-items-center">
                    <ul>
                        <li className="notification">
                            <a href="#">
                                <img src="../app/img/bell.svg" alt=""/>
                            </a>
                        </li>
                        {/*  <li className="user-profile">
                            <h5>{currentUser.name}</h5>
                            <p className="m-0">
                                {currentUser.email}
                                {currentUser && currentUser.cityName
                                    ? `(${currentUser.cityName})`
                                    : null}
                            </p>
                            <span>
                                <img src="../app/img/user.svg" alt=""/>
                              </span>
                        </li>*/}
                        <li className="user-profile">
                            <h5>{currentUser.name}({currentUser.userType})</h5>
                            <DropDown
                            className={'right_menu'}
                            options={menuItems}
                            onClick={(action) => {
                                menuEvents(action);
                            }}>
                            <p className="m-0">
                                {currentUser.email}
                               
                            </p>
                            </DropDown>

                            <DropDown
                                className={'right_menu'}
                                options={menuItems}
                                onClick={(action) => {
                                    menuEvents(action);
                                }}>
                                <span>
                                    <img src="../app/img/user.svg" alt=""/>
                              </span>
                            </DropDown>
                        </li>
                    </ul>

                </div>

            </nav>
        </>
    );
};
export default TopMenu;
