import _ from "lodash";
import React, { useEffect, useState } from "react";
import {useDispatch} from "react-redux";
import {
    Button,
    Card,
    Form,
    InputBox,
    notification,
} from "../../components/Elements/appUtils";
import {GetEachFormFields} from "../../components/_utils/formUtils";
import {getLeadFieldListFxn} from "../Leads/action";
import {ActivateCountryFxn, addLeadFieldsFxn} from "./action";
import LeadComponent from "./components/leadComponent";

const leadInIt = {name: "", inputType: "", option: ""};
const LeadExtraFields = (props) => {
    const {getFieldDecorator} = props.form;
    let dispatch = useDispatch();
    const [leadFieldsList, setLeadFieldsList] = useState([]);
    const [leadId, setLeadId] = useState("");

    useEffect(() => {
        handleLeadList();
    }, []);

    const handleLeadList = async () => {
        let data = await getLeadFieldListFxn();
        if (data && data._id) {
            setLead(data.leadFields);
            setLeadId(data._id);
    }
  };
  const [lead, setLead] = useState([leadInIt]);
  const events = {
    addMore: () => {
      setLead([...lead, leadInIt]);
    },
    deleteItem: (itemId) => {
      const updatedItems = lead.filter((item, key) => key !== itemId);
      setLead(updatedItems);
    },
    updateState: (data, index) => {
      let cloneStatus = _.clone(lead);
      cloneStatus[index] = { ...cloneStatus[index], ...data };
      setLead(cloneStatus);
    },
  };

  let user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;

  const handleSubmit = async (e) => {
    const { form } = props;
    e.preventDefault();
    form.validateFields(async (err, valData) => {
      if (!err) {
        let emptyStatus = _.find(lead, (item) => {
          return !(
              item.name
              // &&
              // item.inputType
          );
        });
        if (emptyStatus) {
          notification.warning({
            message: "Enter Both Fields",
          });
          return;
        }
        if (leadId) {
            valData.extraFieldId = leadId;
        }
        valData.leadFields = lead;
        let x = await dispatch(addLeadFieldsFxn(valData));
        if (!x.error) {
          /*if(leadId){
            valData.extraFieldId=leadId
          }else{
            setLead([leadInIt]);
          }*/
            handleLeadList();
        }
      } else {
        notification.warning({
          message: "Fill All Required Fields",
        });
      }
    });
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
          <Card title={" Walking Extra Fields"}>
              {lead.map((data, index) => {
                  return (
                      <LeadComponent
                          data={data}
                          key={index}
                          index={index}
                          lead={lead}
                          updateState={events.updateState}
                          deleteItem={events.deleteItem}
                      />
                  );
              })}
              <a className={"btn btn-link floatRight"} onClick={events.addMore}>
                  Add More
              </a>
          </Card>
          <div style={{marginTop: "20px"}}>
              <Button type={"primary"} htmlType="submit">
                  submit
              </Button>
          </div>
      </Form>
    </>
  );
};

export default Form.create()(LeadExtraFields);
