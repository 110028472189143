import React, {useRef} from "react";
import {useNavigate} from "react-router-dom";
import {Card, HyperLink, Table} from "../../components/Elements/appUtils";
import {ReceptionListFxn} from "./actions";

let {TableCard} = Card;
const ReceptionList = () => {
    const navigate = useNavigate();
    let tableRef = useRef();
    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            let data = await ReceptionListFxn({
                ...params,
                userType: "reception",
                regExFilters: ["name"],
            })
            resolve(data)
        });
    }

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "Mobile No",
            dataIndex: "mobile",
            key: "mobile",
        },
        {
            title: "Action",
            dataIndex: "action",
            width: 190,
            render: (val, record) => {
                return (
                    <>
                        <button className={'btn'} onClick={() => {
                            navigate(`/edit-reception?_id=${record._id}`)
                        }}>
                            <img src={"../app/img/icons/edit.svg"}/>

                        </button>


                    </>
                );
            },
        },

    ];


    return (
        <>
            {/* <Card
        title={""}
        rightContent={
          <HyperLink className={"btn btn-primary btn-sm"} link={"/add-reception"}>
            Add Reception
          </HyperLink>
        }
      >
        <Table apiRequest={apiRequest} columns={columns} ref={tableRef} />
      </Card> */}
            <TableCard
                title={"Reception List"}
                extraBtn={[
                    {
                        name: "Add Reception",
                        action: () => {
                            navigate("/add-reception");
                        },
                    },
                ]}
            >
                <Table apiRequest={apiRequest} columns={columns} ref={tableRef}/>
            </TableCard>
        </>
    );
};

export default ReceptionList;
