import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { branchListFxn } from "../../Reception/actions";
import { leadCountByTypeFxn, getDataByBranchId } from "../actions";
import { getAllLeadsFxn } from "../../Counsellor/action";



let filterList = ["today", "last week", "last month", "all"];

const LeadSlider = (props) => {
    let { callback, currentUser } = props;
    let [state, setState] = useState({
        dateFilter: "all",
    });
    const navigate = useNavigate();

    let _updateState = (data) => {
        setState((prevState) => {
            return {
                ...prevState,
                ...data,
            };
        });
    };

    let dispatch = useDispatch();
    let [leadData, setLeadData] = useState([]);
    let [scriptLoaded, setScriptLoaded] = useState(false);
    const [branchList, setBranchList] = useState([]);
    const [initialBranchSet, setInitialBranchSet] = useState(false); // New state
    const [defaultBranchId, setDefaultBranchId] = useState("")


    let [branchId, setBranchId] = useState("");
    let [addedBy, setAddedBy] = useState("");

    let loadLeadData = async (dateFilter, selectedBranchId) => {
        console.log(dateFilter, selectedBranchId, "date----");
        let { data } = await leadCountByTypeFxn({}, dateFilter, selectedBranchId);

        console.log("data------" ,data);
        setLeadData(data);
        loadScript();
    };



    useEffect(() => {
        loadBranches();
    }, []);

    useEffect(() => {
        // Check if currentUser is available and _id exists
        if (currentUser && currentUser._id && !initialBranchSet && branchList.length > 0) {
            // Set the default branch only once
            setBranchId(currentUser._id);
            callback(currentUser._id);
            setInitialBranchSet(true);
        }
    }, [currentUser, branchList, initialBranchSet, callback]);


    useEffect(() => {

        loadLeadData(state.dateFilter, branchId);

    }, [branchId, state.dateFilter]);

    let addCompanyBtn = () => {
        navigate(`/master/add-company`);
    };


    const loadBranches = async () => {
        let { data } = await dispatch(
            branchListFxn({
                userType: ["branch", "headBranch"],
            })
        );
        console.log(data, "loadbranchesdata----")
        setBranchList(data);
    };


    const loadScript = () => {
        const script = document.createElement("script");
        script.src = "../app/js/customScript.js";
        script.async = true;
        setTimeout(() => {
            document.body.appendChild(script);
            setScriptLoaded(true);
        }, 200);
        return () => {
            document.body.removeChild(script);
        };
    };

    let chooseBranch = async (selectedBranchId) => {
        console.log(selectedBranchId, "branchid--------");
        setBranchId(selectedBranchId);
        callback(selectedBranchId);

        // Fetch data for the selected branch
        if (state.dateFilter) {
            await loadLeadData(state.dateFilter, selectedBranchId);
        }

    };


    return (
        <>
            {scriptLoaded ? (
                <>
                    <div className="activity-filter">
                        <ul>
                            {filterList.map((item) => {
                                return (
                                    <li
                                        key={item}
                                        onClick={() => {
                                            _updateState({ dateFilter: item });
                                            loadLeadData(item, branchId);
                                        }}
                                    >
                                        <a
                                            className={
                                                state.dateFilter === item ? "active" : ""
                                            }
                                        >
                                            {item}
                                        </a>
                                    </li>
                                );
                            })}
                        </ul>

                        {currentUser.userType === "marketingUser" ? (
                            <div className="ms-auto">
                                {" "}
                                <a className="main-btn" onClick={addCompanyBtn}>
                                    Add Company
                                </a>
                            </div>
                        ) : null}
                        {currentUser.userType === "headBranch" ? (
                            <div className="ms-auto">
                                <div className="select-box">
                                    <select
                                        name="filter"
                                        value={branchId}
                                        className="form-control branch_dropdown"
                                        onChange={({ target }) => {
                                            chooseBranch(target.value);
                                        }}
                                    >
                                        {branchList && branchList.length
                                            ? branchList.map((item) => {
                                                return (
                                                    <option value={item._id} key={item._id}>
                                                        {item.userType}{" "}
                                                        {item.cityName ? `(${item.cityName})` : null}
                                                    </option>
                                                );
                                            })
                                            : null}
                                    </select>
                                </div>
                            </div>
                        ) : null}
                    </div>
                    <div className="country-box">
                        {leadData && leadData.length
                            ? leadData.map((item, index) => {
                                let { flag, _id } = item;
                                return (
                                    <div key={index}>
                                        {_id ? (
                                            <div className="box">
                                                <span>
                                                    <img
                                                        src={flag && flag.path ? flag.path : ""}
                                                        alt=""
                                                    />
                                                </span>
                                                <h4>{item.countryName}</h4>
                                                <p>{item.count} Students</p>
                                            </div>
                                        ) : (
                                            <div className="box">
                                                <span>
                                                    <img src="../app/img/ILETS.svg" alt="" />
                                                </span>
                                                <h4>IELTS/PTE</h4>
                                                <p>{item.count} Students</p>
                                            </div>
                                        )}
                                    </div>
                                );
                            })
                            : null}
                    </div>
                </>
            ) : null}
        </>
    );
};

export default LeadSlider;
