import {apiUrl} from "../../settings";

export const AddPlanUrl = () => {
    return `${apiUrl}/addPlan`;
};
export const PlansListUrl = () => {
    return `${apiUrl}/plansList`;
};

export const EditPlanUrl = () => {
    return `${apiUrl}/editPlan`;
};

export const getPlanUrl = (planId) => {
    return apiUrl + '/getSinglePlan/' + planId
};


export const DeletePlanUrl = () => {
    return `${apiUrl}/deletePlan`;
};
