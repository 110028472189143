import React from "react";
import {Card, Modal} from "../../components/Elements/appUtils";
import AddPartner from "../Partner/addPartner";

const AddPartnerDrawer = (props) => {
    let {visible, onClose} = props;

    return (
        <Modal
            visible={visible}
            onClose={onClose}
            width={"50%"}
            title={"Add Partner"}
            placement="right"
        >
            <Card shadow={false} padding={false}>
                <AddPartner {...props} />
            </Card>
        </Modal>
    );
};
export default AddPartnerDrawer;
