import _ from "lodash";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {
    Button,
    Card,
    Form,
    englishTestList,
    notification,
} from "../../../components/Elements/appUtils";
import {Flags} from "../../../components/_utils/appUtils";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import {addUserFxn} from "../../Counsellor/action";
import {branchListFxn} from "../../Reception/actions";

const RegistrationOptions = [
    {option: "Yes", value: true},
    {option: "No", value: false},
];
const AddEnglishTeacherComponent = (props) => {
    const [branchList, setBranchList] = useState([]);
    const [enableForIp, setEnableForIp] = useState(false);

    const dispatch = useDispatch();
    let [userType, setUserType] = useState("englishTeacher");
    let user = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : null;

    useEffect(() => {
        loadBranches();
    }, []);
    useEffect(() => {
        props.form.setFieldsValue({
            countryCode: "IN_+91",
            enableForIp: false, 
        });
        setEnableForIp(false); 
    }, []);

    const loadBranches = async () => {
        let {data} = await dispatch(
            branchListFxn({
                userType: ["branch", "headBranch"],
            })
        );
        setBranchList(data);
        setTimeout(() => {
            let headBranch = _.find(data, (item) => {
                return item.userType == "headBranch";
            });
            console.log(headBranch, "headdddddddddddd");
            props.form.setFieldsValue({
                branchId: headBranch._id,
            });
        }, 700);
    };
    const handleSubmit = (e) => {
        const {form} = props;
        e.preventDefault();
        form.validateFields(async (err, valData) => {
            if (!err) {
                if (!valData.name) {
                    notification.warning({message: "Enter name"});
                    return;
                }
                if (!valData.email) {
                    notification.warning({message: "Enter email"});
                    return;
                }
                if (!valData.password) {
                    notification.warning({message: "Enter password"});
                    return;
                }
                if (valData.password.length < 6) {
                    notification.warning({message: "Enter atleast 6 digit password."});
                    return;
                }
                if (!valData.countryCode) {
                    notification.warning({message: "Choose country code"});
                    return;
                }
                if (!valData.mobile) {
                    notification.warning({message: "Enter mobile no"});
                    return;
                }
                if (user.userType == "headBranch") {
                    if (!valData.branchId) {
                        notification.warning({message: "Choose branch"});
                        return;
                    }
                }
                if (
                    !valData.englishExamType ||
                    (valData.englishExamType && !valData.englishExamType.length)
                ) {
                    notification.warning({message: "Choose english exam"});
                    return;
                }

                let x = await dispatch(addUserFxn({...valData, userType: userType}));
                if (x.success) {
                    props.form.setFieldsValue({
                        name: "",
                        email: "",
                        mobile: "",
                        password: "",
                        address: "",
                        countryCode: "",
                        branchId: "",
                        countries: [],
                        englishExamType: [],
                        enableForIp: "",
                    });
                }
            } else {
                notification.warning({
                    message: "Please Fill All Required Fields",
                });
            }
        });
    };

    let inputTypes = {
        fields: [
            {
                key: "name",
                label: " Full Name",
                required: false,
                showStar: true,
                placeholder: "Name",
                type: "text",
            },
            {
                key: "email",
                label: "Email",
                required: false,
                showStar: true,
                placeholder: "Email",
                type: "text",
            },
            {
                key: "password",
                label: "Password",
                required: false,
                showStar: true,
                placeholder: "Password",
                type: "text",
            },
            {
                key: "address",
                label: "Address",
                required: false,
                placeholder: "Address",
                type: "text",
            },
            {
                key: "countryCode",
                label: "Country Code",
                required: false,
                showStar: true,
                placeholder: "Country Code",
                type: "select",
                options: Flags,
                showSearch: true,
                keyAccessor: (x) => x.countryCode,
                valueAccessor: (x) => `${x.name} (${x.dialCode})`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        countryCode: x,
                    });
                },
                span: "col-md-2",
            },
            {
                key: "mobile",
                label: "Mobile No",
                required: false,
                showStar: true,
                placeholder: "Mobile No",
                type: "text",
                span: "col-md-4",
            },

            {
                key: "branchId",
                label: "Branch",
                hidden: !(user.userType == "headBranch"),
                placeholder: "Please Choose Branch",
                type: "select",
                options: branchList,
                showStar: true,
                keyAccessor: (x) => x._id,
                valueAccessor: (x) =>
                    `${
                        x && x.userType && x.userType === "branch"
                            ? `${x.cityName ? x.cityName : ""}(${x.contactPerson})`
                            : `${x.cityName ? x.cityName : ""}(${x.name})`
                    }`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        branchId: x,
                    });
                },
            },
            // {
            //     key: "enableForIp",
            //     label: "Enable For Ip Address",
            //     placeholder: "Enable For Ip Address",
            //     options: RegistrationOptions,
            //     type: "select",
            //     keyAccessor: (x) => x.value,
            //     valueAccessor: (x) => `${x.option}`,
            //     onChange: (x) => {
            //         props.form.setFieldsValue({
            //             enableForIp: x,
            //         });
            //     },
            // },
        ],
        counsellorField: [
            {
                key: "englishExamType",
                label: "English Test",
                placeholder: "Please Choose English Test",
                type: "select",
                options: englishTestList,
                multiple: true,
                showStar: true,
                keyAccessor: (x) => x.name,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        englishExamType: x,
                    });
                },
            },
        ],
    };
    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Card title={"Add English Teacher"}>
                    <div className={"row"}>
                        {inputTypes.fields.map((item, key) => {
                            return (
                                <div
                                    className={`pt-3 ${item.span ? item.span : "col-md-6"}`}
                                    key={key}
                                >
                                    <GetEachFormFields {...props.form} item={item}/>
                                </div>
                            );
                        })}
                    </div>
                </Card>
                <Card title={"Choose User Type"}>
                    <div className="row">
                        <div className="col-lg-6 col-12">
                            <div className="form-group">
                                <div className="radio-check-box">
                                    <input
                                        type="checkbox"
                                        value={"englishTeacher"}
                                        name="english"
                                        id="english"
                                        checked={true}
                                    />
                                    <label className="form-control">
                                        Enable user for English Test <span>Click to Select</span>
                                    </label>
                                    <img src="../app/img/check-green.svg" alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"row"}>
                        {inputTypes.counsellorField.map((item, key) => {
                            return (
                                <div className={`pt-3 col-md-6`} key={key}>
                                    <GetEachFormFields {...props.form} item={item}/>
                                </div>
                            );
                        })}
                    </div>

                    <Button
                        type={"primary"}
                        htmlType="submit"
                        className={"btn main-btn mt-4"}
                    >
                        submit
                    </Button>
                </Card>
            </Form>
        </>
    );
};

export default Form.create()(AddEnglishTeacherComponent);
