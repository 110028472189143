//<editor-fold desc="Masters">
import AddMasterCity from "../containers/Masters/city/AddCity";
import MasterCityList from "../containers/Masters/city/CityList";
import EditMasterCity from "../containers/Masters/city/EditCity";
import EditCompany from "../containers/Masters/company/editCompany";
import AddMasterCountry from "../containers/Masters/country/AddCountry";
import MasterCountryList from "../containers/Masters/country/CountryList";
import EditMasterCountry from "../containers/Masters/country/EditCountry";
import AddMasterCourse from "../containers/Masters/course/AddCourse";
import MasterCourseList from "../containers/Masters/course/CourseList";
import EditMasterCourse from "../containers/Masters/course/EditCourse";
import MasterCourseCategory from "../containers/Masters/courseCategory/CourseCategory";
import MasterCourseCategoryEdit from "../containers/Masters/courseCategory/CourseCategoryEdit";
import AddMasterState from "../containers/Masters/state/AddState";
import EditMasterState from "../containers/Masters/state/EditState";
import MasterStateList from "../containers/Masters/state/StateList";
import AddMasterUniversity from "../containers/Masters/university/AddUniversity";
import EditMasterUniversity from "../containers/Masters/university/EditUniversity";
import MasterUniversityList from "../containers/Masters/university/UniversityList";
import AddTemplate from "../containers/template/view/AddTemplate";
import EditTemplate from "../containers/template/view/EditTemplate";
import TemplateList from "../containers/template/view/TemplateList";

import TemplateCategory from "../containers/templateCategory/drawers/templateCategory";
import TemplateCategoryList from "../containers/templateCategory/views/TemplateCategoryList";
import TemplateView from "../containers/templateView/templateView";

import AddCompany from "../containers/Masters/company/AddCompany";
import CompanyList from "../containers/Masters/company/CompanyList";

//</editor-fold>

//<editor-fold desc="Head branch">

import AddCountry from "../containers/Country/view/AddCountry";
import CountryList from "../containers/Country/view/CountryList";
import AddCourse from "../containers/Course/view/AddCourse";
import CourseList from "../containers/Course/view/CourseList";
import SelfDocument from "../containers/Document/selfDocument";
import AddUniversity from "../containers/University/view/AddUniversity";
import UniversityList from "../containers/University/view/UniversityList";
//</editor-fold>

import AddStudent from "../containers/AddStudent/AddStudent";
import StudentListTest from "../containers/AddStudent_test/views/StudentListTest";
import ApplicationProfile from "../containers/ApplicationProfile";
import ApplicationsList from "../containers/Applications/list";
import Login from "../containers/Auths/login";
import BranchList from "../containers/Branch/branchList";
import AddCounsellor from "../containers/Counsellor/views/AddCounsellor";
import CounsellorList from "../containers/Counsellor/views/CounsellorList";
import Dashboard from "../containers/Dashboard";
import Home from "../containers/Home";
import AddLead from "../containers/Leads/views/AddLead";
import LeadList from "../containers/Leads/views/LeadList";
import AddPartner from "../containers/Partner/addPartner";
import Profile from "../containers/Profile/views/profile";
import AddReception from "../containers/Reception/addReception";
import ReceptionList from "../containers/Reception/receptionList";
import GenerateSop from "../containers/SOP/generateSop";
import SopList from "../containers/SOP/sopList";
import StudentList from "../containers/Student/views/StudentList";
import EditUser from "../containers/Users/views/editUser";
import LeadExtraFields from "../containers/leadExtraFields";

import AddPaymentDetails from "../containers/AddPayments/AddPaymentDetails";
import AgencyFees from "../containers/AddPayments/agencyFees";
import AddBoardStream from "../containers/BoardStream/addBoardStream";
import AddHr from "../containers/HR/AddHr";
import AddEmployee from "../containers/HR/addEmployee";
import EmployeeList from "../containers/HR/employeeList";
import EditLead from "../containers/Leads/views/editLead";
import DocumentCheckList from "../containers/Masters/documentCheckList/groupedDocuments";
import EditPlans from "../containers/Plans/views/EditPlans";
import Plans from "../containers/Plans/views/plans";
import SingleLead from "../containers/SingleLead/view";
import StudentProfile from "../containers/StudentProfile/view";
import UserList from "../containers/Users/userGrouped";

import {history} from "../reducers/store";

import AddCommission from "../containers/Commission/addCommission";
import CommissionList from "../containers/Commission/commissionList";
import EditCounsellor from "../containers/Counsellor/views/EditCounsellor";
import CanadaCourseStatus from "../containers/CourseStatus/canadaCourseStatus";
import UkCourseStatus from "../containers/CourseStatus/ukCourseStatus";
import UsaCourseStatus from "../containers/CourseStatus/usaCourseStatus";
import MyProfile from "../containers/Dashboard/components/myProfile";
import selfDocument from "../containers/Document/selfDocument";
import FeedBackList from "../containers/Feedback/feedBackLIst";
import feedback from "../containers/Feedback/feedback";
import AddGicPage from "../containers/GicCommission/addGicPage";
import GicApprovedList from "../containers/GicCommission/gicApprovedList";
import GicList from "../containers/GicCommission/gicList";
import EmployeeEdit from "../containers/HR/employeeEdit";
import HrList from "../containers/HR/list";
import SingleEmpProfile from "../containers/HR/singleEmpProfile";
import IntroductoryVideos from "../containers/IntroductoryVideos";
import addIpAddress from "../containers/IpAddress/addIpAddress";
import ManagerList from "../containers/Manager/views/ManagerList";
import AddManager from "../containers/Manager/views/addManager";
import EditManager from "../containers/Manager/views/editManager";
import RequestedCompanyList from "../containers/Masters/company/requestedCompanyList";
import EditMyProfile from "../containers/Profile/editMyProfile";
import EditReception from "../containers/Reception/EditReception";
import generateSop from "../containers/SOP/generateSop";
import EditStudent from "../containers/StudentProfile/view/editStudent";
import TrainingRequestComponent from "../containers/TrainingRequest";
import TrainingRequestList from "../containers/TrainingRequest/trainingRequestList";
import AddBranch from "../containers/Users/branch/addBranch";
import AddEnglishTeacher from "../containers/Users/views/addEnglishTeacher";
import EditEnglishTeacher from "../containers/Users/views/editEnglishTeacher";
import EnglishTeacherList from "../containers/Users/views/englsihTeacherList";
import WithdrawalList from "../containers/Withdrawals/views/list";
import ChangePassword from "../containers/changepassword/changePassword";
import {gicUrl} from "../settings";

export let menuData = [
    {
        name: "Dashboard",
        path: "/dashboard",
        key: "dashboard",
        icon: "monitor",
        // authority: ["admin", "company"],
        component: Dashboard,
        // authority: ["headBranch", "branch", "counsellor", "englishTeacher", "hr", "manager", 'admin']
    },
    /*{
                name: "My Profile",
                path: "/my-profile",
                key: "myprofile",
                icon: "monitor",
                // authority: ["admin", "company"],
                component: MyProfile
            },*/

    {
        name: "Companies",
        key: "company",
        icon: "book",
        authority: ["admin", "marketingUser"],
        subMenu: [
            {
                name: "Company List",
                path: "/master/company-list",
                component: CompanyList,
                key: "companyList",
                authority: ["admin", "marketingUser"],
            },
            {
                name: "Requested Company",
                path: "/master/requested-company-list",
                component: RequestedCompanyList,
                key: "requestedCompanyList",
                authority: ["admin", "marketingUser"],
            },
            {
                name: "Add Company",
                path: "/master/add-company",
                component: AddCompany,
                key: "addCompany",
                dontShowOnMenu: true,
                authority: ["admin", "marketingUser"],
            },
        ],
    },
    {
        name: "Employee List",
        key: "employeelist",
        path: "/employee-list",
        icon: "book",
        component: EmployeeList,
        authority: ["headBranch",
            "branch",
            "manager",
            "hr"
        ],
    },
    {
        name: "Walk-in List",
        key: "leadList",
        path: "/lead-list",
        icon: "book",
        component: LeadList,
        authority: [
            "headBranch",
            "counsellor",
            "reception",
            "branchUser",
            "branch",
            "englishTeacher",
            "manager",
            "hr",
        ],
    },
    {
        name: "Add Walk-in",
        key: "addLead",
        path: "/add-lead",
        icon: "book",
        component: AddLead,
        dontShowOnMenu: true,
        authority: [
            "headBranch",
            "counsellor",
            "reception",
            "branchUser",
            "branch",
            "englishTeacher",
            "manager",
        ],
    },
    {
        name: "Edit Walk-in",
        key: "editLead",
        path: "/edit-Lead",
        icon: "book",
        component: EditLead,
        dontShowOnMenu: true,
        authority: [
            "headBranch",
            "counsellor",
            "reception",
            "branchUser",
            "branch",
            "englishTeacher",
            "manager",
        ],
    },

    {
        name: "Student",
        icon: "trello",
        key: "student",
        authority: [
            "headBranch",
            "branch",
            "manager",
            "counsellor",
            "englishTeacher",
        ],
        subMenu: [
            {
                name: "Add Student",
                path: "/add-student",

                component: AddStudent,
                key: "addStudent",
                authority: [
                    "headBranch",
                    "counsellor",
                    "reception",
                    "branchUser",
                    "branch",
                    "englishTeacher",
                    "manager",
                ],
            },
            {
                name: "Student List",
                path: "/student-list",
                component: StudentList,
                key: "studentList",
                authority: [
                    "headBranch",
                    "counsellor",
                    "reception",
                    "branchUser",

                    "branch",
                    "manager",
                ],
            },
            {
                name: "Student Profile",
                key: "studentProfile",
                path: "/student-profile",
                component: StudentProfile,
                dontShowOnMenu: true,
                authority: [
                    "headBranch",
                    "counsellor",
                    "reception",
                    "branchUser",
                    "branch",
                    "englishTeacher",
                    "manager",
                ],
            },
            {
                name: "Edit Student",
                key: "editStudent",
                path: "/edit-student",
                icon: "book",
                component: EditStudent,
                dontShowOnMenu: true,
                authority: [
                    "headBranch",
                    "counsellor",
                    "reception",
                    "branchUser",
                    "branch",
                    "englishTeacher",
                    "manager",
                ],
            },
        ],
    },

    {
        name: "Edit Plan",
        key: "editPlan",
        path: "/edit-plan",
        icon: "book",
        component: EditPlans,
        dontShowOnMenu: true,
        authority: ["headBranch", "manager"],
    },
    {
        name: "Agency Fees",
        key: "agencyFees",
        path: "/agency-fees-details/:id",
        icon: "book",
        component: AgencyFees,
        dontShowOnMenu: true,
        authority: [
            "headBranch",
            "branch",
            "counsellor",
            "englishTeacher",
            "manager",
        ],
    },

    {
        name: "Add Payment Details",
        key: "addPaymentDetails",
        path: "/add-payment-details",
        icon: "book",
        component: AddPaymentDetails,
        dontShowOnMenu: true,
        authority: [
            "headBranch",
            "branch",
            "counsellor",
            "englishTeacher",
            "manager",
        ],
    },

    {
        name: "Lead Profile",
        key: "Lead Profile",
        path: "/lead-Profile",
        icon: "book",
        component: SingleLead,
        dontShowOnMenu: true,
        authority: [
            "headBranch",
            "branch",
            "counsellor",
            "englishTeacher",
            "manager",
        ],
    },

    {
        name: "GIC Request List",
        title: "GIC Request List",
        key: "leadList",
        path: "/gic-Request-list",
        icon: "book",
        component: GicList,
        authority: ["admin", "marketingUser"],
    },
    {
        name: "Template",
        icon: "trello",
        key: "template",
        authority: ["admin", "manager"],
        subMenu: [
            {
                name: "Template List",
                path: "/master/template-list",
                component: TemplateList,
                key: "templateList",
            },
            {
                name: "Add Template",
                path: "/master/add-template",
                component: AddTemplate,
                key: "templateList",
            },
            {
                name: "Edit Template",
                path: "/master/edit-template",
                component: TemplateList,
                key: "templateList",
                dontShowOnMenu: true,
            },
            {
                name: "View Template",
                path: "/template-view",
                component: TemplateView,
                key: "templateView",
                dontShowOnMenu: true,
            },
            {
                name: "Category List",
                title: "Template Category List",
                path: "/master/template-category-list",
                key: "templateCategoryList",
                component: TemplateCategoryList,
            },
            {
                name: "Add Category",
                title: "Add Template Category",
                path: "/master/add-template-category",
                key: "addTemplateCategory",
                component: TemplateCategory,
            },
            {
                name: "SOP List",
                title: "SOP List",
                path: "/sopList",
                key: "sopList",
                component: SopList,
            },
            {
                name: "Generate SOP",
                title: "Generate SOP",
                path: "/generateSOP",
                key: "generateSOP",
                component: generateSop,
            },
        ],
    },
    {
        name: "Master",
        icon: "folder",
        key: "master",
        authority: ["admin", "manager"],
        subMenu: [
            {
                name: "Country",
                path: "/master/country-list",
                component: MasterCountryList,
                key: "masterCountryList",
            },
            {
                name: "Add Country",
                path: "/master/add-country",
                key: "addMasterCountry",
                component: AddMasterCountry,
                dontShowOnMenu: true,
            },
            {
                name: "Edit Country",
                path: "/master/country-edit",
                key: "editMasterCountry",
                component: EditMasterCountry,
                dontShowOnMenu: true,
            },
            {
                name: "Edit Company",
                path: "/master/edit-company",
                key: "editMasterCompany",
                component: EditCompany,
            },
            {
                name: "State",
                path: "/master/state-list",
                component: MasterStateList,
            },
            {
                name: "Add State",
                path: "/master/add-state",
                key: "addMasterState",
                component: AddMasterState,
                dontShowOnMenu: true,
            },
            {
                name: "Edit State",
                path: "/master/state-edit",
                key: "editMasterState",
                component: EditMasterState,
                dontShowOnMenu: true,
            },
            {
                name: "City",
                path: "/master/city-list",
                component: MasterCityList,
            },
            {
                name: "Add City",
                path: "/master/add-city",
                key: "addMasterCity",
                component: AddMasterCity,
                dontShowOnMenu: true,
            },
            {
                name: "Edit City",
                path: "/master/city-edit",
                key: "editMasterCity",
                component: EditMasterCity,
                dontShowOnMenu: true,
            },
            {
                name: "University",
                path: "/master/university-list",
                component: MasterUniversityList,
            },
            {
                name: "Add University",
                path: "/master/add-university",
                key: "addMasterUniversity",
                component: AddMasterUniversity,
                dontShowOnMenu: true,
            },
            {
                name: "Edit University",
                path: "/master/university-edit",
                key: "editMasterUniversity",
                component: EditMasterUniversity,
                dontShowOnMenu: true,
            },
            {
                name: "Course",
                path: "/master/course-list",
                component: MasterCourseList,
            },
            {
                name: "Add Course",
                path: "/master/add-course",
                key: "addMasterCourse",
                component: AddMasterCourse,
                dontShowOnMenu: true,
            },
            {
                name: "Edit Course",
                path: "/master/course-edit",
                key: "editMasterCourse",
                component: EditMasterCourse,
                dontShowOnMenu: true,
            },
            {
                name: "Course Category",
                path: "/master/course-category",
                component: MasterCourseCategory,
            },
            {
                name: "Edit Course Category",
                path: "/master/course-category-edit",
                key: "editMasterCourseCategory",
                component: MasterCourseCategoryEdit,
                dontShowOnMenu: true,
            },
        ],
    },
    {
        name: "Document Checklist",
        key: "documentCheckList",
        path: "/document-check-list",
        icon: "book",
        authority: ["admin", "manager"],
        component: DocumentCheckList,
    },

    {
        name: "Training Request List",
        path: "/training-request-list",
        key: "trainingRequestList",
        component: TrainingRequestList,
        authority: ["admin"],
    },
    {
        name: "Feedback List",
        path: "/feedback-list",
        key: "feedbackList",
        component: FeedBackList,
        authority: ["admin"],
    },
    {
        name: "GIC",
        icon: "book",
        key: "gic",
        authority: ["headBranch", "admin", "counsellor", "englishTeacher"],

        subMenu: [
            {
                name: "Add GIC Request",
                path: "/add-gic-request",
                key: "addGic",
                component: AddGicPage,
            },
            {
                name: "GIC Requested",
                path: "/gic-Request-list",
                key: "gicRequested",
                component: GicList,
            },
            {
                name: "GIC Approved",
                path: "/gic-approved-list",
                key: "gicApproved",
                component: GicApprovedList,
            },
            {
                name: "GIC Withdrawal",
                path: "/withdrawal-list",
                key: "withdrawalList",
                component: WithdrawalList,
                authority: ["headBranch"],
            },
        ],
    },
    {
        name: "Branch",
        icon: "book",
        key: "branch",
        authority: ["headBranch", "manager"],
        subMenu: [
            {
                name: "Add Branch",
                path: "/add-branch",
                key: "addBranch",
                component: AddBranch,
            },
            {
                name: "Branch List",
                path: "/branch-list",
                key: "branchList",
                component: BranchList,
            },
        ],
    },

    {
        name: "Expand Your Team",
        title: "Expand Your Team",
        key: "expandYourTeams",
        path: "/user-list",
        icon: "book",
        component: UserList,
        authority: ["headBranch", "branch", "manager"],
    },
    {
        name: "Commission List",
        title: "Commission List",
        key: "commissionList",
        path: "/commission-list",
        icon: "book",
        component: CommissionList,
        authority: ["headBranch", "branch"],
    },
    {
        name: "Add Commission",
        title: "Add Commission",
        key: "addCommission",
        path: "/add-Commission/:id",
        icon: "book",
        component: AddCommission,
        dontShowOnMenu: true,
        authority: [
            "headBranch",
            "branch",
            "counsellor",
            "englishTeacher",
            "hr",
            "manager",
        ],
    },
    {
        name: "Self Document",
        title: "Self Document",
        key: "selfDocument",
        path: "/self-document",
        icon: "book",
        component: selfDocument,
        authority: [
            "headBranch",
            "branch",
            "counsellor",
            "englishTeacher",
            "hr",
            "manager",
        ],
    },
    {
        name: "University List",
        path: "/university-list",
        key: "universityList",
        component: MasterUniversityList,
        icon: "book",
        authority: [
            "headBranch",
            "branch",
            "counsellor",
            "englishTeacher",
            "manager",
        ],
    },
    /*  {
          name: "Employee List",
          key: "employeeList",
          path: "/employee-list",
          icon: "book",
          component: EmployeeList,
          authority: [
              "headBranch",
              "branch",
              "manager",
              "hr"
          ],
      },
      {
          name: "Employees",
          key: "employee",
          icon: "book",
          authority: ["hr"],
          subMenu: [
              {
                  name: "Add Employee",
                  path: "/add-employee",
                  key: "addEmployee",
                  component: AddEmployee,
              },
              {
                  name: "Employee List",
                  path: "/employee-list",
                  key: "employeeList",
                  component: EmployeeList,
              },
          ],
      },*/
    {
        name: "Setting",
        icon: "setting",
        key: "setting",
        authority: ["headBranch"],
        subMenu: [
            {
                name: "Countries",
                path: "/country-list",
                key: "countryList",
                component: CountryList,
            },

            /*{
                                      name: "Lead Extra Fields",
                                      path: "/lead-extra-fields",
                                      key: "leadExtraFields",
                                      component: LeadExtraFields,
                                    },*/
            {
                name: "Partners",
                path: "/add-partner",
                key: "partners",
                component: AddPartner,
            },
            {
                name: "Ip Address",
                path: "/add-ipAddress",
                key: "IpAddress",
                component: addIpAddress,
            },
            {
                name: "Board Stream",
                path: "/board-and-stream",
                key: "addBoardStream",
                component: AddBoardStream,
            },
            {
                name: "FeedBack",
                path: "/add-feedback",
                key: "feedback",
                component: feedback,
            },
            {
                name: "Request For Training",
                path: "/training-request",
                key: "trainingRequest",
                component: TrainingRequestComponent,
            },
            {
                name: "Introductory Video",
                path: "/introductory-video",
                key: "introductoryVideo",
                component: IntroductoryVideos,
            },
            {
                name: "Edit Profile",
                path: `/edit-my-profile`,
                key: "myProfile",
                component: EditMyProfile,
            },
            {
                name: "Change Password",
                path: "/change-password",
                key: "changePassword",
                component: ChangePassword,
            },
            {
                name: "Plans",
                key: "addPlan",
                path: "/add-plan",
                icon: "book",
                component: Plans,
                authority: ["headBranch", "manager"],
            },
        ],
    },
    {
        name: "Canada Courses Current Status",
        title: "Canada Courses Current Status",
        key: "canadaCoursesCurrentStatus",
        path: "/canada-courses-current-status",
        icon: "book",
        component: CanadaCourseStatus,
        authority: [
            "headBranch",
            "branch",
            "counsellor",
            "englishTeacher",
            "manager",
            "marketingUser",
        ],
    },

    {
        name: "USA Courses Current Status",
        title: "USA Courses Current Status",
        key: "usaCoursesCurrentStatus",
        path: "/usa-courses-current-status",
        icon: "book",
        component: UsaCourseStatus,
        authority: [
            "headBranch",
            "branch",
            "counsellor",
            "englishTeacher",
            "manager",
            "marketingUser",
        ],
    },

    {
        name: "UK courses current status",
        title: "UK courses current status",
        key: "ukCoursesCurrentStatus",
        path: "/uk-courses-current-status",
        icon: "book",
        component: UkCourseStatus,
        authority: [
            "headBranch",
            "branch",
            "counsellor",
            "englishTeacher",
            "manager",
            "marketingUser",
        ],
    },

    {
        name: "Users",
        icon: "users",
        key: "users",
        authority: ["headBranch", "branch", "manager"],
        dontShowOnMenu: true,
        subMenu: [
            {
                name: "Add Reception",
                path: "/add-reception",
                key: "addReception",
                component: AddReception,
            },
            {
                name: "Reception",
                path: "/reception-list",
                key: "reception",
                component: ReceptionList,
            },
            {
                name: "Reception",
                path: "/edit-reception",
                key: "editReception",
                component: EditReception,
            },
            {
                name: "Add Counsellor",
                path: "/add-counsellor",
                key: "addCounsellor",
                component: AddCounsellor,
            },
            {
                name: "Counsellor",
                path: "/counsellor-list",
                key: "counsellorList",
                component: CounsellorList,
            },
            {
                name: "Edit Counsellor",
                path: "/edit-counsellor",
                key: "editCounsellor",
                component: EditCounsellor,
            },
            {
                name: "Add English Teacher",
                path: "/add-english-teacher",
                key: "addEnglishTeacher",
                component: AddEnglishTeacher,
            },
            {
                name: "English Teacher",
                path: "/englishTeacher-list",
                key: "englishTeacherList",
                component: EnglishTeacherList,
            },
            {
                name: "Edit English Teacher",
                path: "/edit-englishTeacher",
                key: "editEnglishTeacher",
                component: EditEnglishTeacher,
            },
            {
                name: "Add HR",
                path: "/add-hr",
                key: "addHr",
                component: AddHr,
            },
            {
                name: "HR List",
                path: "/hr-list",
                key: "hrList",
                component: HrList,
            },
            {
                name: "Add Manager",
                path: "/add-manager",
                key: "addManager",
                component: AddManager,
            },
            {
                name: "Manager List",
                path: "/manager-list",
                key: "managerList",
                component: ManagerList,
            },
            {
                name: "Edit Manager",
                path: "/edit-manager",
                key: "editManager",
                component: EditManager,
            },
            {
                name: "Add Employee",
                path: "/add-employee",
                key: "addEmployee",
                component: AddEmployee,
            },

            {
                name: "Employee List",
                path: "/employee-list",
                key: "employeeList",
                component: EmployeeList,
                // authority: ["hr"],
            },
            {
                name: "Employee Profile",
                path: "/single-emp-profile",
                key: "SingleEmpProfile",
                component: SingleEmpProfile,
            },
            {
                name: "Employee Edit",
                path: "/edit-single-emp",
                key: "EmployeeEdit",
                component: EmployeeEdit,
            },
        ],
    },
];
