import { hideLoader, showLoader } from "../../actions/loader";
import notification from "../../components/Elements/Notification";
import { customAxios as axios, getToken } from "../../request";
import {
    UniversityListUrl,
    addCampusUrl,
    addRepresentingUniversityUrl,
    addUniversityDataUrl,
    companyCountryListUrl,
    getRelatedCityUrl,
    getUniversityUrl,
    partnerListUrl,
    postUniversityUrl,
    representingUniversityListUrl,
    updateCampusUrl,
    updateUniversityActiveStatusUrl,
} from "./api";

export const GetRelatedCities = async (valData) => {
  let config = {
    params: { ...valData },
    ...(await getToken()),
  };
  let data = await axios.get(getRelatedCityUrl(), config);

  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  }
  return data.data;
};

export const AddUniversityFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  let { data } = await axios.post(addUniversityDataUrl(), valData, getToken());
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  } else {
    notification.success({
      message: data.message || "Success",
    });
  }
  return data;
};
export const AddRepresentingUniversityFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  let { data } = await axios.post(
    addRepresentingUniversityUrl(),
    valData,
    getToken()
  );
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  } else {
    notification.success({
      message: data.message || "Success",
    });
  }
  return data;
};

export const universityList = async (valData) => {
  let config = {
    params: { ...valData },
    ...(await getToken()),
  };
  let { data } = await axios.get(UniversityListUrl(), config);
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  }

  return data;
};
export const representingUniversityList = async (valData) => {
  let config = {
    params: { ...valData },
    ...(await getToken()),
  };
  let { data } = await axios.get(representingUniversityListUrl(), config);
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  }

  return data;
};
export const companyCountryList = async (valData) => {
  let config = {
    params: { ...valData },
    ...(await getToken()),
  };
  let { data } = await axios.get(companyCountryListUrl(), config);
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  }

  return data;
};
export const partnerListFxn = async (valData) => {
  let config = {
    params: { ...valData },
    ...(await getToken()),
  };
  let { data } = await axios.get(partnerListUrl(), config);
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  }

  return data;
};
export const GetRelatedUniversityFxn = (id) => async (dispatch) => {
  dispatch(showLoader());
  let config = {
    ...(await getToken()),
  };
  let { data } = await axios.get(getUniversityUrl(id), config);
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  }

  return data;
};

export const postRelatedUniversityFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  let { data } = await axios.post(postUniversityUrl(), valData, getToken());
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  } else {
    notification.success({
      message: data.messages,
    });
  }
  return data.data;
};

export const updateUniversityActiveStatusFxn =
  (valData) => async (dispatch) => {
    dispatch(showLoader());
    let { data } = await axios.post(
      updateUniversityActiveStatusUrl(),
      valData,
      getToken()
    );
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
      return data;
  };
export const updateCampusFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(updateCampusUrl(), valData, getToken());
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};
export const addCampusFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(addCampusUrl(), valData, getToken());
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};
