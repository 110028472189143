import React from "react"

const Layout = (props) => {
    let {children, formName = ''} = props;
    return (
        <>
            <div className="position-relative p-0 overflow-hidden">
                <section className={`login-module ${formName}`}>
                    <div className="row align-items-start align-items-xl-center h-100">
                        <div className="col-xl-7 col-lg-12 col-12 h-100 p-0 d-none d-lg-none d-xl-block">
                            <div className="lft-content">
                                <img src="../app/img/login-img.png" alt="" className="img-responsive"/>
                            </div>
                        </div>
                        <div className="col-lg-5 col-12 p-0">
                            {children}
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
export default Layout;
