import _ from "lodash";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {
    Button,
    Card,
    Form,
    notification,
} from "../../components/Elements/appUtils";
import {EnglishExamOptions} from "../../components/_utils/_utils";
import {Flags} from "../../components/_utils/appUtils";
import {GetEachFormFields} from "../../components/_utils/formUtils";
import {companyCountryList} from "../University/action";
import {GetRelatedUserFxn} from "../Users/action";
import {postUpdateUserFxn} from "../Users/views/action";
import {branchListFxn} from "./actions";

const RegistrationOptions = [
    {option: "Yes", value: true},
    {option: "No", value: false},
];

const EditReception = (props) => {
    const [countryList, setCountryList] = useState([]);
    const [branchList, setBranchList] = useState([]);
    const [userId, setUserId] = useState("");
    const [enableForEnglish, setEnableForEnglish] = useState(false);
    const dispatch = useDispatch();
    let [userType, setUserType] = useState("reception");
    let user = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : null;
    useEffect(() => {
        handleState();
        loadBranches();
    }, []);
    useEffect(() => {
        let searchParams = new URLSearchParams(window.location.search);
        let id = searchParams.get("_id");
        if (id) {
            setUserId(id);
            getRelatedUser(id);
    }
  }, [branchList]);
  const events = {
    setBranch: (id) => {
      let branchUser = _.find(branchList, (item) => {
        return item._id == id;
      });
      if (branchUser) {
        props.form.setFieldsValue({
          branchId: branchUser._id,
        });
      }
    },
  };
  const getRelatedUser = async (id) => {
      let {data} = await dispatch(GetRelatedUserFxn(id));

    setTimeout(() => {
      props.form.setFieldsValue({
          name: data.name,
          address: data.address,
          email: data.email,
          countryCode: data.countryISOCode,
          mobile: data.mobile,
          countries: data.countries,
          enableForIp: data.enableForIp,
      });
    }, 500);
    events.setBranch(data.branchId);
  };

  const handleState = async () => {
    let x = await companyCountryList();
    setCountryList(x.data);
  };
  const loadBranches = async () => {
      let {data} = await dispatch(
          branchListFxn({
              userType: ["branch", "headBranch"],
          })
      );
      setBranchList(data);
  };
  const handleSubmit = (e) => {
      const {form} = props;
    e.preventDefault();
    form.validateFields(async (err, valData) => {
      if (!err) {
        if (!valData.name) {
            notification.warning({message: "Enter name"});
          return;
        }
        if (!valData.email) {
            notification.warning({message: "Enter email"});
          return;
        }

        if (!valData.countryCode) {
            notification.warning({message: "Choose country code"});
          return;
        }
        if (!valData.mobile) {
            notification.warning({message: "Enter mobile no"});
          return;
        }

        valData.id = userId;

        let x = await dispatch(
            postUpdateUserFxn({
                ...valData,
                userType: userType,
                enableForEnglish,
            })
        );
        if (x.success) {
        }
      } else {
        notification.warning({
          message: "Please Fill All Required Fields",
        });
      }
    });
  };

  let inputTypes = {
    fields: [
      {
        key: "name",
        label: "Name",
        required: false,
        placeholder: "Name",
        type: "text",
      },
      {
        key: "email",
        label: "Email",
        required: false,
        placeholder: "Email",
        type: "text",
      },

      {
        key: "countryCode",
        label: "Country Code",
        required: false,
        placeholder: "Country Code",
        type: "select",
        options: Flags,
        showSearch: true,
        keyAccessor: (x) => x.countryCode,
        valueAccessor: (x) => `${x.name} (${x.dialCode})`,
        onChange: (x) => {
          props.form.setFieldsValue({
            countryCode: x,
          });
        },
        span: "col-md-2",
      },
      {
        key: "mobile",
        label: "Mobile No",
        required: false,
        placeholder: "Mobile No",
        type: "text",
        span: "col-md-4",
      },
      {
        key: "branchId",
        label: "Branch",
        hidden: !(user.userType == "headBranch"),
        placeholder: "Please Choose Branch",
        type: "select",
        options: branchList,
        keyAccessor: (x) => x._id,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            branchId: x,
          });
        },
      },
        {
            key: "address",
            label: "Address",
            required: false,
            placeholder: "Address",
            type: "text",
        },
        {
            key: "enableForIp",
            label: "Enable For Ip Address",
            placeholder: "Enable For Ip Address",
            options: RegistrationOptions,
            type: "select",
            keyAccessor: (x) => x.value,
            valueAccessor: (x) => `${x.option}`,
            onChange: (x) => {
                props.form.setFieldsValue({
                    enableForIp: x,
                });
            },
        },
    ],
  };
  return (
      <>
          <Form onSubmit={handleSubmit}>
              <Card title={"Edit Reception"}>
                  <div className={"row"}>
                      {inputTypes.fields.map((item, key) => {
                          return (
                              <div
                                  className={`pt-3 ${item.span ? item.span : "col-md-6"}`}
                                  key={key}
                              >
                                  <GetEachFormFields {...props.form} item={item}/>
                              </div>
                          );
                      })}
                  </div>
                  <Button
                      type={"primary"}
                      htmlType="submit"
                      className={"btn main-btn mt-4"}
                  >
                      submit
                  </Button>
              </Card>
          </Form>
      </>
  );
};

export default Form.create()(EditReception);
