import React from "react";
import { useDispatch } from "react-redux";
import { Button, Card, Drawer, Form } from "../../components/Elements/appUtils";
import { GetEachFormFields } from "../../components/_utils/formUtils";
import { addCommentFxn } from "../ApplicationProfile/action";
const AddComment = (props) => {
  let {visible, onClose, noteId, onSubmit} = props;
    const {getFieldDecorator} = props.form;
  const dispatch = useDispatch();
  console.log(noteId, "noooteeee");

  const handleSubmit = (e) => {
    const { form } = props;
    e.preventDefault();
    form.validateFields(async (err, valData) => {
      console.log(err, valData);
      valData.noteId = noteId;
      if (!err) {
        let x = await dispatch(addCommentFxn(valData));
          props.form.setFieldsValue({
              text: "",
          });
          onSubmit()
      }
    });
  };
  let inputTypes = {
    fields: [
      {
        key: "text",
        label: "Comment",
        required: true,
        placeholder: "Comment",
        type: "textarea",
        span: "col-md-12",
      },
    ],
  };
  return (
      <Drawer
          visible={visible}
          onClose={onClose}
          width={"45%"}
          title={"Add Comment"}
          placement="right"
      >
          <Form onSubmit={handleSubmit}>
              <div className={"row"}>
                  {inputTypes.fields.map((item, key) => {
                      return (
                          <div className={"col-md-12 pt-3"} key={key}>
                              <GetEachFormFields {...props.form} item={item}/>
                          </div>
                      );
                  })}
              </div>
              <div style={{marginTop: "20px"}}>
                  <Button type={"primary"} htmlType="submit">
                      submit
                  </Button>
              </div>
          </Form>
    </Drawer>
  );
};

export default Form.create()(AddComment);
