import {apiUrl} from "../../settings";

export const representingUniversityListUrl = () => {
  return `${apiUrl}/representingUniversityList`;
};

export const addIpAddressUrl = () => {
  return `${apiUrl}/addIpAddress`;
};
export const IpAddressListUrl = () => {
  return `${apiUrl}/IpAddressList`;
};

export const deleteIpAddressUrl = () => {
  return `${apiUrl}/deleteIpAddress`;
};
