import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {
  Button,
  Card,
  Form,
  notification,
} from "../../../components/Elements/appUtils";
import {gicQuestionList} from "../../../components/_utils/_utils";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import {AddGicCommissionFxn} from "../action";

const gicInIt = {
  name: "",
  phoneNo: "",
  country: "Canada",
  gicId: "",
  gicPassword: "",
  status: "Pending",
};
const AddGicComponent = (props) => {
  let {
    onSubmit,
    form: {getFieldValue},
  } = props;
  let [whichToShow, setWhichToShow] = useState("");
  const [gicAccountFile, setGicAccountFile] = useState("");
  const [gicCertificate, setGicCertificate] = useState("");
  const [passportCopy, setPassportCopy] = useState("");
  let dispatch = useDispatch();

  let inputTypes = {
    fields: [
      {
        key: "firstName",
        label: "First Name",
        required: false,
        placeholder: "First Name",
        type: "text",
        showStar: true,
      },
      {
        key: "lastName",
        label: "Last Name",
        required: false,
        placeholder: "Last Name",
        type: "text",
        showStar: true,
      },
      {
        key: "userName",
        label: "Username",
        required: false,
        placeholder: "Username",
        type: "text",
        showStar: true,
      },
      {
        key: "email",
        label: "Email",
        required: false,
        placeholder: "Email",
        type: "email",
        showStar: true,
      },

      {
        key: "password",
        label: "Password",
        required: false,
        placeholder: "Password",
        type: "text",
        showStar: true,
      },

      {
        key: "confirmationPassword",
        label: "Confirmation Password",
        required: false,
        placeholder: "Confirmation Password",
        type: "text",
        showStar: true,
      },

      {
        key: "securityQuestion",
        label: "Security Question",
        required: false,
        placeholder: "Security Question",
        type: "select",
        options: gicQuestionList,
        showStar: true,
        keyAccessor: (x) => x.name,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            securityQuestion: x,
          });
        },
      },

      {
        key: "securityAnswer",
        label: "Answer to Security Question",
        required: false,
        placeholder: "Answer to Security Question",
        type: "text",
        disabled: !getFieldValue("securityQuestion"),
        showStar: true,
      },

      {
        key: "phoneNo",
        label: "Contact No",
        required: false,
        placeholder: "Contact No",
        type: "text",
        showStar: true,
      },

      {
        key: "collegeName",
        label: "College Name",
        required: false,
        placeholder: "College Name",
        type: "text",
        showStar: true,
      },

      {
        key: "city",
        label: "City",
        required: false,
        placeholder: "City",
        type: "text",
        showStar: true,
      },

      {
        key: "expectedDateOfArrival",
        label: "Expected Date of Arrival",
        required: false,
        placeholder: "Expected Date of Arrival",
        type: "date",
        showStar: true,
        onChange: (x) => {
          props.form.setFieldsValue({
            expectedDateOfArrival: x,
          });
        },
      },

      // {
      //     key: "gicAccountFile",
      //     label: "GIC Account File",
      //     required: false,
      //     placeholder: "GIC Account File",
      //     type: "file",
      //     fileName: gicAccountFile,
      //     onChange: ({target}) => {
      //         handleFileChange(target)
      //     }
      // },

      // {
      //     key: "gicCertificate",
      //     label: "GIC Certificate",
      //     required: false,
      //     placeholder: "GIC Certificate",
      //     type: "file",
      //     fileName: gicCertificate,
      //     onChange: ({target}) => {
      //         handleFileChange(target)
      //     }
      //   },
      {
        key: "passportCopy",
        label: "Passport Copy",
        required: false,
        placeholder: "Passport Copy",
        type: "file",
        fileName: passportCopy,
        onChange: ({target}) => {
          handleFileChange(target);
        },
      },
    ],
  };

  const handleFileChange = (e) => {
    let {name, files} = e;
    if (name == "gicCertificate") {
      setGicCertificate(files[0]);
    } else if (name == "gicAccountFile") {
      setGicAccountFile(files[0]);
    } else {
      setPassportCopy(files[0]);
    }
  };

  const [gicCommission, setGicCommission] = useState(gicInIt);

  const handleSubmit = async (e) => {
    const {form} = props;
    e.preventDefault();
    form.validateFields(async (err, valData) => {
      if (!valData.firstName) {
        notification.warning({
          message: "Please enter first name",
        });
        return;
      }
      if (!valData.lastName) {
        notification.warning({
          message: "Please enter last name",
        });
        return;
      }
      if (!valData.userName) {
        notification.warning({
          message: "Please enter username",
        });
        return;
      }
      if (!valData.email) {
        notification.warning({
          message: "Please enter email address",
        });
        return;
      }
      if (!valData.password) {
        notification.warning({
          message: "Please enter password",
        });
        return;
      }
      if (!valData.confirmationPassword) {
        notification.warning({
          message: "Please enter confirmation password",
        });
        return;
      }
      if (valData.password !== valData.confirmationPassword) {
        notification.warning({
          message: "Password not match",
        });
        return;
      }

      if (!valData.securityQuestion) {
        notification.warning({
          message: "Choose security question",
        });
        return;
      }
      if (!valData.securityAnswer) {
        notification.warning({
          message: "Enter security answer",
        });
        return;
      }
      if (!valData.phoneNo) {
        notification.warning({
          message: "Enter contact no",
        });
        return;
      }
      if (!valData.collegeName) {
        notification.warning({
          message: "Enter college name",
        });
        return;
      }
      if (!valData.city) {
        notification.warning({
          message: "Enter city",
        });
        return;
      }
      if (!valData.expectedDateOfArrival) {
        notification.warning({
          message: "Choose expected date of arrival",
        });
        return;
      }
      if (!(passportCopy && passportCopy.name)) {
        notification.warning({
          message: "Choose select passport Copy",
        });
        return;
      }

      let fd = new FormData();
      fd.append("obj", JSON.stringify(valData));
      if (gicAccountFile && gicAccountFile.name) {
        fd.append("gicAccountFile", gicAccountFile);
      }
      if (passportCopy && passportCopy.name) {
        fd.append("passportCopy", passportCopy);
      }
      if (gicCertificate && gicCertificate.name) {
        fd.append("gicCertificate", gicCertificate);
      }
      let x = await dispatch(AddGicCommissionFxn(fd));
      if (x && x.success) {
        setGicCommission(gicInIt);
        form.setFieldsValue({
          firstName: "",
          lastName: "",
          email: "",
          userName: "",
          phoneNo: "",
          securityQuestion: "",
          securityAnswer: "",
          password: "",
          confirmationPassword: "",
          collegeName: "",
          city: "",
          expectedDateOfArrival: "",
        });
        onSubmit();
      }
    });
  };
  useEffect(() => {
    setWhichToShow("History");
    props.form.setFieldsValue({country: "Canada"});
  }, []);

  return (
      <Form onSubmit={handleSubmit}>
        <div className={"row"}>
          {inputTypes.fields.map((item, key) => {
            return (
                <div className={`${item.span ? item.span : "col-md-6"}`} key={key}>
                  <GetEachFormFields {...props.form} item={item}/>
                </div>
            );
          })}
        </div>
        <Button type={"primary"} htmlType="submit" className="btn main-btn mt-4">
          Submit
        </Button>
      </Form>
  );
};
export default Form.create()(AddGicComponent);
