import {hideLoader, showLoader} from "../../../actions/loader";
import notification from "../../../components/Elements/Notification";
import {customAxios as axios, getToken} from "../../../request";
import {
    addTemplateUrl,
    getCompanyCourseListUrl,
    getRelatedCourseForSopUrl,
    getRelatedCourseUrl,
    templateListUrl,
    updateTemplateActiveStatusUrl,
    updateTemplateUrl,
} from "../apis";

export const AddTemplateFxn = (valData) => async (dispatch) => {
  console.log(valData);
  dispatch(showLoader());

    let {data} = await axios.post(addTemplateUrl(), valData, getToken());
  console.log(data);
  dispatch(hideLoader());

  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  }
  return data;
};

export const getRelatedCourseListFxn = async (valData) => {
    let {universityId} = valData;
    let config = {
        params: {...valData},

        ...(await getToken()),
    };
    let data = await axios.get(getRelatedCourseUrl(universityId), config);
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
  }
  return data;
};

export const templateListFxn = async (valData) => {
  let config = {
      params: {...valData},
      ...(await getToken()),
  };
  let data = await axios.get(templateListUrl(), config);
  return data.data;
};
export const updateTemplateFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
    let {data} = await axios.post(updateTemplateUrl(), valData, getToken());
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  } else {
    notification.success({
      message: data.message || "Success",
    });
  }
  return data;
};

export const updateTemplateActiveStatusFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(
        updateTemplateActiveStatusUrl(),
        valData,
        getToken()
    );
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
  }
  return data;
};
export const getCompanyCourseListFxn = async (id) => {
  let config = {
    ...(await getToken()),
  };
  let data = await axios.get(getCompanyCourseListUrl(id), config);
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  }
  return data;
};
export const getRelatedCourseForSopFxn = async (id) => {
  let config = {
    ...(await getToken()),
  };
  let data = await axios.get(getRelatedCourseForSopUrl(id), config);
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  }
  return data;
};
