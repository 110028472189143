import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {
    Button,
    Card,
    Drawer,
    Form,
} from "../../../components/Elements/appUtils";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import {addNoteFxn} from "../action";

const CreateDrawer = (props) => {
    let {visible, onClose, studentId, applicationId} = props;
    const {getFieldDecorator} = props.form;
    const dispatch = useDispatch();
    const [attachment, setAttachment] = useState({});
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setAttachment(file);
    };

    const handleSubmit = (e) => {
        const {form} = props;
        e.preventDefault();
        form.validateFields(async (err, valData) => {
            if (!err) {
                let fd = new FormData();
                valData.studentId = studentId;
                valData.applicationId = applicationId._id;
                fd.append("obj", JSON.stringify(valData));
                if (attachment && attachment.name) {
                    fd.append("attachment", attachment);
                }
                let data = await dispatch(addNoteFxn(fd));

                props.form.setFieldsValue({
                    note: "",
                    attachment: "",
                });
            }
        });
    };
    let inputTypes = {
        fields: [
            {
                key: "note",
                label: "Note",
                required: true,
                placeholder: "Note",
                type: "textarea",
                span: "col-md-12",
            },
            {
                key: "attachment",
                label: "Attachment",
                type: "file",
                onChange: handleFileChange,
            },
        ],
    };

    return (
        <>
            <Drawer
                visible={visible}
                onClose={onClose}
                width={"45%"}
                title={"Add Note"}
                placement="right"
            > <Form onSubmit={handleSubmit}>
                <div className={"row"}>
                    {inputTypes.fields.map((item, key) => {
                        return (
                            <div className={"col-md-12 pt-3"} key={key}>
                                <GetEachFormFields {...props.form} item={item}/>
                            </div>
                        );
                    })}
                </div>
                <div style={{marginTop: "20px"}}>
                    <Button type={"primary"} htmlType="submit">
                        submit
                    </Button>
                </div>
            </Form>
            </Drawer>
        </>
    );
};

export default Form.create()(CreateDrawer);
