import React, {useState, useRef} from "react";
import {
    Button,
    Form,
    Card,
    Table,
    Drawer,
    displayDate, Popconfirm, TableButton, Tooltip, DefaultTablePagination,
} from "../../components/Elements/appUtils";
import {reGenerateSopDocFxn, reGenerateSopFxn, sopListFxn} from "./action";
import {apiUrl} from "../../settings";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

let {TableCard} = Card
const SopList = (props) => {
    let tableRef = useRef();
    const navigate = useNavigate();

    let dispatch = useDispatch()
    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            let data = await sopListFxn({
                ...params,
                sortField: '_id',
                sortOrder: 'descend',
                regExFilters: [
                    "country",
                    "university",
                    "course",
                    "name",
                    "gender",
                    "maritalStatus",
                    "address",
                    "mobile",
                    "email",
                    "examType",
                ],
            });
            resolve(data);
        });
    };

    const reCreateSop = async (sopId) => {
        let resp = await dispatch(reGenerateSopFxn({sopId}));
        if (resp && resp.success) {
            tableRef.current.reload()
        }
    }

    const reGenerateSopDoc = async (sopId) => {
        let resp = await dispatch(reGenerateSopDocFxn({sopId}));
        if (resp && resp.success) {
            tableRef.current.reload()
        }
    }

    const columns = [
        {
            title: "Sr. no",
            key: "index",
            dataIndex: "index",
            width: 50,
            render: (item, record, index) => {
                return index + 1;
            }
        },
        {
            title: "University",
            dataIndex: "universityId",
            key: "universityId",
            width: "200",
            render: (item, record) => {
                return (
                    <>
                        <p>{item && item.universityName ? item.universityName : null}</p>

                        {/* <div>
                            {record._id}
                        </div>*/}

                    </>
                );
            },
        },
        {
            title: "Country",
            dataIndex: "countryId",
            key: "countryId",
            render: (item, record) => {
                return <p>{item && item.countryName ? item.countryName : null}</p>;
            },
        },
        {
            title: "Course",
            dataIndex: "courseId",
            key: "courseId",
            render: (item, record) => {
                return <p>{item && item.courseName ? item.courseName : null}</p>;
            },
        },
        {
            title: "Name",
            dataIndex: "studentId.name",
            key: "studentId.name",
            render: (item, record) => {
                return <p>{record && record.studentId && record.studentId.name}</p>;
            },
        },
        {
            title: "Added Date",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (item) => {
                return (
                    displayDate(item)
                )
            }
        },

        {
            title: "Template",
            dataIndex: "templateId",
            key: "templateId",
            render: (item) => {
                return <p>
                    {item && item.templateName ? item.templateName : ""}
                </p>;
            },
        },

        {
            title: "Action",
            dataIndex: "action",
            render: (val, record) => {
                let {generatedSop, generatedSopDoc} = record;
                return (
                    <div className={'btn_group'}>
                        {/*   {generatedSop && generatedSop.path ?
                            <>
                                <Tooltip title={'Download SOP'}>
                                    <a className={'empty_btn'}
                                       href={generatedSop.path} target={'_blank'}
                                       download={generatedSop.fileName}>
                                        <i className={'fa fa-download far'}></i>
                                    </a>
                                </Tooltip>
                            </> : null}*/}
                        {generatedSopDoc && generatedSopDoc.path ?
                            <>
                                <Tooltip title={'Download SOP'}>
                                    <a className={'empty_btn'}
                                       href={generatedSopDoc.path} target={'_blank'}
                                       download={generatedSopDoc.fileName}>
                                        <i className={'fa fa-download far'}></i>
                                    </a>
                                </Tooltip>
                            </> : null}


                        <TableButton type={'view'} onClick={() => {
                            window.open(`${apiUrl}/scholarCrmView/${record._id}`, '_blank')
                        }}/>


                        {/* <Popconfirm
                            title={'Are you sure, you want to Re-Generate SOP?'}
                            onConfirm={() => {
                                reCreateSop(record._id)

                            }}>
                            <Tooltip title={'Re-Generate SOP'}>
                                <button className={'empty_btn'}>
                                    <i className={'fa fa-sync far'}></i>
                                </button>
                            </Tooltip>
                        </Popconfirm>*/}


                        <Popconfirm
                            title={'Are you sure, you want to Re-Generate SOP?'}
                            onConfirm={() => {
                                reGenerateSopDoc(record._id)
                            }}>
                            <button className={'empty_btn'} name={'Re-generate SOP'}>
                                <i className={'fa fa-sync far'}></i>
                            </button>
                        </Popconfirm>


                    </div>
                );
            },
        },
    ];
    return (
        <>
            <TableCard title={"SOP List"}
                       extraBtn={[
                           {
                               name: "Ad SOP",
                               action: () => {
                                   navigate("/generateSOP")
                               }
                           }
                       ]}>
                <Table columns={columns}
                       pagination={DefaultTablePagination()}
                       apiRequest={apiRequest} ref={tableRef}/>
            </TableCard>
        </>
    );
};

export default Form.create()(SopList);
