import _, {add} from "lodash";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {RowTable} from "../../../components/Elements/RowTable";
import {
  Avatar,
  Button,
  Card,
  DocumentTypes,
  DropDown,
  Form,
  InputBox,
  Modal,
  Popconfirm,
  Tabs,
  Tooltip,
  displayDate,
  documentArray,
  getStatusColor,
  longDisplayDate,
} from "../../../components/Elements/appUtils";

import {applicationListFxn} from "../../SOP/action";
import AddNote from "../../Student/drawers/addNote";
import StudentDocuments from "../../Student/drawers/studentDocuments";
import {
  deleteStudentDocumentFxn,
  getSingleStudentDocumentsFxn,
  singleStudentFxn,
} from "../action";
import ApplicationActivityList from "../activityList";
import UpdateStatus from "../moveToNextStep";
// import MoveToNextStep from "."
import {imgPath, statusColor} from "../../../components/_utils/_utils";
import AddMoreApplication from "../../Student/drawers/AddMoreApplication";
import NoteList from "../noteList";
import StatusList from "../statusList";
import UploadSingleDocument from "../uploadDocument";
import UploadNewDocument from "../uploadNewDocument";

const menuOptions = [
  {
    name: "Add Note",
    icon: "fa-plus",
  },
  {name: "Update Status", icon: "fa fa-exchange-alt"},
  {
    name: "Notes",
    icon: "fa-list",
  },
  {
    name: "Documents",
    icon: "fa-list",
  },
  {
    name: "Activity List",
    icon: "fa-list",
  },
  {
    name: "Status List",
    icon: "fa-list",
  },
  {
    name: "Tution Fees",
    icon: "fa-list",
  },
];
let {TabPane} = Tabs;
const BasicInfoComponent = (props) => {
  let {index, studentData, visible} = props;
  const [open, setOpen] = useState(false);
  let [document, setDocument] = useState("");
  const [verify, setVerify] = useState(false);
  const [unverify, setUnverify] = useState(false);
  const [addNote, setAddNote] = useState(false);

  const Note = () => {
    setAddNote(true);
  };
  const showModal = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setDocument(file);
  };
  const popUp = () => {
  };
  return (
      <div className={"smallTab"}>
        <Tabs defaultActiveKey="1" onChange={() => {
        }}>
          <TabPane tab={"Basic"} key="1" width={150}>
            <div className={"row"}>
              <div className={"col-md-8"}>
                <div className={"tableBox"}>
                  <RowTable
                      title={"Name (ID)"}
                      value={
                        studentData && studentData.name ? studentData.name : null
                      }
                  />
                  <RowTable
                      title={"Mobile"}
                      value={
                        studentData && studentData.mobile
                            ? studentData.countryCode.substring(3) +
                            " " +
                            studentData.mobile
                            : null
                      }
                  />
                  <RowTable
                      title={"Email"}
                      value={
                        studentData && studentData.email ? studentData.email : null
                      }
                  />
                  <RowTable
                      title={"Gender"}
                      value={
                        studentData && studentData.gender
                            ? studentData.gender
                            : null
                      }
                  />
                  <RowTable
                      title={"Passport"}
                      value={
                        studentData && studentData.passportNumber
                            ? studentData.passportNumber
                            : null
                      }
                  />

                  <RowTable
                      title={"Country"}
                      value={
                        studentData && studentData.countryName
                            ? studentData.countryName
                            : null
                      }
                  />
                  <RowTable
                      title={"State"}
                      value={
                        studentData && studentData.stateName
                            ? studentData.stateName
                            : null
                      }
                  />
                  <RowTable
                      title={"City"}
                      value={
                        studentData && studentData.cityName
                            ? studentData.cityName
                            : null
                      }
                  />
                  <RowTable
                      title={"Pincode"}
                      value={
                        studentData && studentData.pincode
                            ? studentData.pincode
                            : null
                      }
                  />
                  <RowTable
                      title={"Address"}
                      value={
                        studentData && studentData.address
                            ? studentData.address
                            : null
                      }
                  />
                </div>
              </div>
            </div>
          </TabPane>
          <TabPane tab={"Academy"} key="2">
            <Card title={"Education"} type={"primary"}>
              <table className={"table table-bordered"}>
                <tr>
                  <th>Qualification</th>
                  <th>School/University</th>
                  <th>Stream</th>
                  <th>Passing Year</th>
                  <th>Percentage</th>
                </tr>
                {studentData &&
                studentData.education.map((item) => {
                  return (
                      <>
                        <tr>
                          <td>{item.qualification}</td>
                          <td>{item.board}</td>
                          <td>{item.stream}</td>
                          <td>{item.passingYear}</td>
                          <td>{item.percentage}</td>
                        </tr>
                      </>
                  );
                })}
              </table>
            </Card>
            <Card title={"English Score"} type={"primary"}>
              <table className={"table table-bordered"}>
                <tr>
                  <th>English Exam Type</th>
                  <th>Exam Date</th>
                  <th>Overall</th>
                  <th>Listening</th>
                  <th>Reading</th>
                  <th>Writing</th>
                  <th>Speaking</th>
                </tr>
                <tr>
                  <td>
                    {studentData &&
                    studentData.englishRequirement &&
                    studentData.englishRequirement.examType
                        ? studentData.englishRequirement.examType
                        : null}
                  </td>
                  <td>
                    {studentData &&
                    studentData.englishRequirement &&
                    studentData.englishRequirement.examinationDate
                        ? studentData.englishRequirement.examinationDate
                        : null}
                  </td>
                  <td>
                    {studentData &&
                    studentData.englishRequirement &&
                    studentData.englishRequirement.overall
                        ? studentData.englishRequirement.overall
                        : null}
                  </td>
                  <td>
                    {studentData &&
                    studentData.englishRequirement &&
                    studentData.englishRequirement.listening
                        ? studentData.englishRequirement.listening
                        : null}
                  </td>
                  <td>
                    {studentData &&
                    studentData.englishRequirement &&
                    studentData.englishRequirement.reading
                        ? studentData.englishRequirement.reading
                        : null}
                  </td>
                  <td>
                    {studentData &&
                    studentData.englishRequirement &&
                    studentData.englishRequirement.writing
                        ? studentData.englishRequirement.writing
                        : null}
                  </td>
                  <td>
                    {studentData &&
                    studentData.englishRequirement &&
                    studentData.englishRequirement.speaking
                        ? studentData.englishRequirement.speaking
                        : null}
                  </td>
                </tr>
              </table>
            </Card>
          </TabPane>
          <TabPane tab={"Experience"} key="3">
            <table className={"table table-bordered"}>
              {studentData &&
              studentData.workExperience.map((item) => {
                return (
                    <>
                      <tr>
                        <th>Company</th>
                        <th>Position</th>
                        <th>Time Period</th>
                        <th>Document</th>
                        <th>Document Verification</th>
                      </tr>
                      <tr>
                        <td>{item.companyName}</td>
                        <td>{item.position}</td>
                        <td>{item.time}</td>
                        <td>
                          <input
                              className="form-control sm"
                              type="file"
                              title="Choose File"
                              onChange={handleFileChange}
                          />
                        </td>
                        <td>
                          <input
                              className="form-control sm"
                              type="text"
                              placeholder="Pending"
                              onClick={showModal}
                          />
                        </td>
                      </tr>
                    </>
                );
              })}
            </table>
            <Modal
                visible={open}
                onClose={onClose}
                open={open}
                width={"30%"}
                title={"Document Verification"}
            >
              <Card>
                <Form>
                  <div>
                    <input
                        type="radio"
                        value="radio"
                        name="verify"
                        onChange={(e) => {
                          setVerify(verify);
                        }}
                    />
                    <label style={{marginLeft: "10px"}}>Verify</label>
                  </div>
                  <div>
                    <input
                        type="radio"
                        value="unverify"
                        name="unverify"
                        onChange={(e) => {
                          setUnverify(!unverify);
                        }}
                    />
                    <label style={{marginLeft: "10px"}}>Unverify</label>
                  </div>
                  <div>
                    <label style={{marginTop: "10px"}}>Add Note</label>
                    <input
                        style={{marginLeft: "10px"}}
                        type="textarea"
                        rows="4"
                        cols="50"
                    />
                  </div>
                  <div>
                    <Button
                        onClick={() => {
                          Note();
                        }}
                        type={"primary"}
                    >
                      Submit
                    </Button>
                  </div>
                </Form>
              </Card>
            </Modal>
          </TabPane>
        </Tabs>
      </div>
  );
};

let initialState = {
  currentStep: 0,
  fileList: [],
  uploadKey: moment(),
  allStates: [],
  allCities: [],
  countryName: "",
  stateName: "",
  cityName: "",
};
const StudentProfile = () => {
  const navigate = useNavigate();
  const [studentData, setStudentData] = useState();
  const [applicationList, setApplicationList] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [studentId, setStudentId] = useState("");
  const [state, setState] = useState(initialState);
  let [singleDocState, setSingleDocState] = useState({
    visible: false,
    studentId: "",
    item: "",
    documentType: "",
    type: "",
  });
  let [newsingleDocState, setNewSingleDocState] = useState({
    visible: false,
    studentId: "",
    item: "",
  });
  let events = {
    showSingleDoc: (documentType, type, studentId) => {
      setSingleDocState({
        visible: true,
        studentId,
        documentType,
        type,
      });
    },

    hideSingleDoc: () => {
      setSingleDocState({
        visible: false,
        studentId: "",
        item: "",
        documentType: "",
        type: "",
      });
      singleStudentData();
    },
    showNewSingleDoc: (studentId) => {
      setNewSingleDocState({
        visible: true,
        studentId,
      });
    },
    hideNewSingleDoc: () => {
      setNewSingleDocState({
        visible: false,
        studentId: "",
      });
      singleStudentData();
    },
    deleteSingleDocument: async (id) => {
      let data = await dispatch(
          deleteStudentDocumentFxn({documentId: id, studentId})
      );

      if (data && data.success) {
        singleStudentData();
      }
    },
  };
  let [addNoteState, setAddNoteState] = useState({
    visible: false,
    studentId: "",
    applicationId: "",
  });
  let [nextStepState, setNextStepState] = useState({
    visible: false,
    studentId: "",
    applicationId: "",
  });
  let [noteListState, setNoteListState] = useState({
    visible: false,
    studentId: "",
    applicationId: "",
  });
  let [statusListState, setStatusListState] = useState({
    visible: false,
    studentId: "",
    applicationId: "",
  });
  let [activityListState, setActivityListState] = useState({
    visible: false,
    studentId: "",
    applicationId: "",
  });
  let [applicationOpen, setApplicationOpen] = useState(false);
  let [newDocumentArray, setNewDocumentArray] = useState([]);
  const showApplicationDrawer = () => {
    setApplicationOpen(true);
  };

  const onApplicationDrawer = () => {
    apiApplication();
    setApplicationOpen(false);
  };

  const actionEvents = {
    showAddNote: (applicationId) => {
      setAddNoteState({
        visible: true,
        studentId,
        applicationId,
      });
    },
    hideAddNote: () => {
      setAddNoteState({
        visible: false,
        studentId,
        applicationId: "",
      });
    },
    showNoteList: (applicationId) => {
      setNoteListState({
        visible: true,
        studentId,
        applicationId,
      });
    },
    hideNoteList: () => {
      setNoteListState({
        visible: false,
        studentId: "",
        applicationId: "",
      });
    },
    showStatusList: (applicationId) => {
      setStatusListState({
        visible: true,
        studentId,
        applicationId,
      });
    },
    hideStatusList: () => {
      setStatusListState({
        visible: false,
        studentId: "",
        applicationId: "",
      });
    },
    showActivityList: (applicationId) => {
      setActivityListState({
        visible: true,
        studentId,
        applicationId,
      });
    },
    hideActivityList: () => {
      setActivityListState({
        visible: false,
        studentId: "",
        applicationId: "",
      });
    },
    // showDocumentList: (applicationId) => {
    //   setDocumentListState({
    //     visible: true,
    //     studentId,
    //     applicationId,
    //   });
    // },

    // hideDocumentList: () => {
    //   setDocumentListState({
    //     visible: false,
    //     studentId: "",
    //     applicationId: "",
    //   });
    // },
    moveToNextStep: (applicationId) => {
      setNextStepState({
        visible: true,
        studentId,
        applicationId,
      });
    },
    hideMoveToStep: () => {
      setNextStepState({
        visible: false,
        studentId: "",
        applicationId: "",
      });
    },
  };

  let menuEvents = (eventName, appId, id) => {
    if (eventName == "Add Note") {
      actionEvents.showAddNote(appId);
    } else if (eventName == "Update Status") {
      actionEvents.moveToNextStep(appId);
    } else if (eventName == "Add Commission") {
      navigate(`/add-commission/${id}?id=${appId._id}`);
    } else if (eventName == "Notes") {
      actionEvents.showNoteList(appId);
    } else if (eventName == "Activity List") {
      actionEvents.showActivityList(appId);
    } else if (eventName == "Status List") {
      actionEvents.showStatusList(appId);
    } else {
    }
  };

  let dispatch = useDispatch();
  _.each(DocumentTypes, (item) => {
    initialState[item] = "";
  });
  useEffect(() => {
    singleStudentData();
  }, []);

  const singleStudentData = async () => {
    let searchParams = new URLSearchParams(window.location.search);
    let id = searchParams.get("_id");
    if (id) {
      setStudentId(id);
      apiRequest(id);
      loadDocuments(id);
    }
  };

  let menuEvent = (id) => {
    navigate(`/add-commission?_id=${id}`);
  };

  const getMatchingDocuments = (docType, docCategory) => {
    const matchingDocuments = [];

    for (const category of documents) {
      if (category.documentCategory === docCategory) {
        for (const docTypeObj of category.documentTypes) {
          if (docTypeObj.documentType === docType) {
            matchingDocuments.push(...docTypeObj.documents);
          }
        }
      }
    }

    return matchingDocuments;
  };

  // const getMatchingDocuments = (docType, docCategory) => {
  //   const matchingDocuments = [];

  //   for (const category of documents) {
  //     if (category.documentCategory === docCategory) {
  //       let typeFound = false;

  //       for (const docTypeObj of category.documentTypes) {
  //         if (docTypeObj.documentType === docType) {
  //           matchingDocuments.push(...docTypeObj.documents);
  //           typeFound = true;
  //           break;
  //         }
  //       }

  //       // If the document type is not found, add documents to "Other" category
  //       if (!typeFound) {
  //         const otherCategory = documents.find(
  //           (cat) =>console.log(cat,),
  //           cat.documentCategory === "Other"
  //         );

  //         if (otherCategory) {
  //           const otherDocType = otherCategory.documentTypes[0].documentType;

  //           if (docCategory !== "Other" || docType !== otherDocType) {
  //             matchingDocuments.push(
  //               ...otherCategory.documentTypes[0].documents
  //             );
  //           }
  //         }
  //       }
  //     }
  //   }

  //   return matchingDocuments;
  // };
  // const getMatchingDocuments = (docType, docCategory) => {
  //   const matchingDocuments = [];

  //   for (const category of documents) {
  //     if (category.documentCategory === docCategory) {
  //       for (const docTypeObj of category.documentTypes) {
  //         if (docTypeObj.documentType === docType) {
  //           if (docCategory === "Other") {
  //             matchingDocuments.push(...category.documentTypes);
  //             return matchingDocuments;
  //           } else {
  //             matchingDocuments.push(...docTypeObj.documents);
  //           }
  //         }
  //       }

  //       // If the document type is not found, and the category is "Other", add documents to "Other" category
  //     }
  //   }

  //   return matchingDocuments;
  // };

  let apiRequest = async (id) => {
    let {data} = await dispatch(singleStudentFxn(id));
    setStudentData(data);
  };
  let loadDocuments = async (id) => {
    console.log(id, "loadDocumentsloadDocuments");
    let {data, success} = await dispatch(getSingleStudentDocumentsFxn(id));
    if (success && data) {
      const otherCategoryIndex = _.findIndex(documentArray, {name: "Other"});

      const otherCategory = _.find(data, {documentCategory: "Other"});
      console.log(otherCategory, "otehrrrrrrrrrrrrr");
      if (otherCategory) {
        _.each(otherCategory.documentTypes, (docTypeObj) => {
          const documentTypeToAdd = docTypeObj.documentType;
          if (
              !documentArray[otherCategoryIndex].documents.includes(
                  documentTypeToAdd
              )
          ) {
            documentArray[otherCategoryIndex].documents.push(documentTypeToAdd);
          }
        });
      }
    }
    setDocuments(data);
  };

  const menuitems = [
    {
      name: "Add Commission",
      icon: "fa-plus",
    },
    {
      name: "Add Note",
      icon: "fa-plus",
    },
    {name: "Update Status", icon: "fa fa-exchange-alt"},
    {
      name: "Notes",
      icon: "fa-list",
    },

    {
      name: "Activity List",
      icon: "fa-list",
    },
    {
      name: "Status List",
      icon: "fa-list",
    },
  ];

  const loadMenu = (data) => {
    let cloneMenu = _.clone(menuitems);
    if (data.status !== "Visa Approved") {
      cloneMenu = _.reject(cloneMenu, (item) => {
        return item.name == "Add Commission";
      });
    }
    return cloneMenu;
  };

  useEffect(() => {
    if (studentId) {
      apiApplication();
    }
  }, [studentId]);
  const apiApplication = async () => {
    let params = {
      studentId,
      results: 50,
      count: 50,
    };
    let {data} = await applicationListFxn({
      ...params,
    });
    setApplicationList(data);
  };

  return (
      <>
        <div className="container-fluid pt-4 px-4">
          <div className="profile-box student-single-profile card-box border-grey mb-4 p-4">
            <div className="pic">
              <div className="upload-img">
              <span>
                <img src="../app/img/user.svg" alt="" className="w-100 h-100"/>
              </span>
                <div className="upload-btn">
                  <input type="file" name="" id=""/>
                  <i className="fa fa-pencil-alt"></i>
                </div>
              </div>
              <div className="content-box">
                <h4>
                  {studentData && studentData.name ? studentData.name : null}
                </h4>
                <p>
                  {" "}
                  DOB:{" "}
                  {studentData && studentData.dateOfBirth
                      ? displayDate(studentData.dateOfBirth)
                      : null}
                </p>
                <span className="active">active</span>
              </div>
            </div>
            <div className="detail">
              <ul>
                <li>
                  <span>Name:</span>{" "}
                  {studentData && studentData.name ? studentData.name : null}
                </li>
                <li>
                  <span>City:</span>
                  {studentData && studentData.cityName
                      ? studentData.cityName
                      : null}
                </li>
                <li>
                  <span>Mobile:</span>{" "}
                  {studentData && studentData.mobile
                      ? studentData.countryCode.substring(3) +
                      " " +
                      studentData.mobile
                      : null}
                </li>
                <li>
                  <span>Pincode:</span>{" "}
                  {studentData && studentData.pincode
                      ? studentData.pincode
                      : null}
                </li>
                <li>
                  <span>Email:</span>
                  {studentData && studentData.email ? studentData.email : null}
                </li>
                <li>
                  <span>Address:</span>{" "}
                  {studentData && studentData.address
                      ? studentData.address
                      : null}
                </li>
                <li>
                  <span>Country:</span>{" "}
                  {studentData && studentData.countryName
                      ? studentData.countryName
                      : null}
                </li>
                <li>
                  <span>State:</span>{" "}
                  {studentData && studentData.stateName
                      ? studentData.stateName
                      : null}
                </li>
              </ul>
            </div>
          </div>

          <div className="information-box card-box border-grey p-4 mb-4">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                    className="nav-link active"
                    id="Profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#Profile"
                    type="button"
                    role="tab"
                    aria-controls="Profile"
                    aria-selected="true"
                >
                  Profile
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                    className="nav-link"
                    id="Applications-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#Applications"
                    type="button"
                    role="tab"
                    aria-controls="Applications"
                    aria-selected="false"
                >
                  Applications
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                    className="nav-link"
                    id="Documents-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#Documents"
                    type="button"
                    role="tab"
                    aria-controls="Documents"
                    aria-selected="false"
                >
                  Documents
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                    className="nav-link"
                    id="Education-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#Education"
                    type="button"
                    role="tab"
                    aria-controls="Education"
                    aria-selected="false"
                >
                  Education
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                    className="nav-link"
                    id="Experience-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#Experience"
                    type="button"
                    role="tab"
                    aria-controls="Experience"
                    aria-selected="false"
                >
                  Experience
                </button>
              </li>
              {/* <li className="nav-item" role="presentation">
                            <button className="nav-link" id="Attendance-tab" data-bs-toggle="tab" data-bs-target="#Attendance" type="button" role="tab" aria-controls="Attendance" aria-selected="false">Attendance</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="Leave_Request-tab" data-bs-toggle="tab" data-bs-target="#Leave_Request" type="button" role="tab" aria-controls="Leave_Request" aria-selected="false">Leave Request</button>
                        </li> */}
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                  className="tab-pane fade show active"
                  id="Profile"
                  role="tabpanel"
                  aria-labelledby="Profile-tab"
              >
                <div className="detail-box">
                  <ul>
                    <li>
                      <span>Name:</span>
                      {studentData && studentData.name ? studentData.name : null}
                    </li>
                    <li>
                      <span>Date:</span>{" "}
                      {studentData && studentData.dateOfBirth
                          ? displayDate(studentData.dateOfBirth)
                          : null}{" "}
                    </li>
                    <li>
                      <span>Passport:</span>{" "}
                      {studentData && studentData.passportNumber
                          ? studentData.passportNumber
                          : null}
                    </li>
                    <li>
                      <span>Gender:</span>{" "}
                      {studentData && studentData.gender
                          ? studentData.gender
                          : null}
                    </li>
                    <li>
                      <span>Marital Status:</span>{" "}
                      {studentData && studentData.maritalStatus
                          ? studentData.maritalStatus
                          : null}
                    </li>
                    <li>
                      <span>Address:</span>{" "}
                      {studentData && studentData.address
                          ? studentData.address
                          : null}
                    </li>
                    <li>
                      <span>Country:</span>
                      {studentData && studentData.countryName
                          ? studentData.countryName
                          : null}
                    </li>
                    <li>
                      <span>State:</span>{" "}
                      {studentData && studentData.stateName
                          ? studentData.stateName
                          : null}
                    </li>
                    <li>
                      <span>City:</span>
                      {studentData && studentData.cityName
                          ? studentData.cityName
                          : null}
                    </li>
                    <li></li>
                  </ul>
                </div>
                {/* <div className="detail-box">
                <h3>Education</h3>

                {studentData &&
                  studentData.education.map((item) => {
                    return (
                      <>
                        <ul>
                          <li>
                          { item.qualification ?  
                            <span>Qualification:</span> {item.qualification} : " "
                          }
                           
                          </li>
                        
                          {
                            item.board ? 
                            <span>Board:</span> {item.board} : " "
                          }
                          </li>
                          <li>
                            <span>School Name:</span>
                            {item.schoolName}
                          </li>
                          <li>
                            <span>Passing Year:</span> {item.passingYear}
                          </li>
                          <li>
                            <span>Percentage:</span> {item.percentage}
                          </li>
                          <li>
                            <span>Stream:</span> {item.stream}
                          </li>
                        </ul>
                      </>
                    );
                  })}
              </div> */}
                <div className="detail-box">
                  <h3>English Test Score</h3>
                  <ul>
                    <li>
                      <span>English Exam Type:</span>{" "}
                      {studentData &&
                      studentData.englishRequirement &&
                      studentData.englishRequirement.examType
                          ? studentData.englishRequirement.examType
                          : null}
                    </li>
                    <li>
                      <span>Examination Date:</span>{" "}
                      {studentData &&
                      studentData.englishRequirement &&
                      studentData.englishRequirement.examinationDate
                          ? displayDate(
                              studentData.englishRequirement.examinationDate
                          )
                          : null}
                    </li>
                    <li>
                      <span>Overall Score:</span>{" "}
                      {studentData &&
                      studentData.englishRequirement &&
                      studentData.englishRequirement.overall
                          ? studentData.englishRequirement.overall
                          : null}
                    </li>
                    <li>
                      <span>Speaking:</span>{" "}
                      {studentData &&
                      studentData.englishRequirement &&
                      studentData.englishRequirement.speaking
                          ? studentData.englishRequirement.speaking
                          : null}
                    </li>
                    <li>
                      <span>Reading:</span>{" "}
                      {studentData &&
                      studentData.englishRequirement &&
                      studentData.englishRequirement.reading
                          ? studentData.englishRequirement.reading
                          : null}
                    </li>
                    <li>
                      <span>Writing:</span>{" "}
                      {studentData &&
                      studentData.englishRequirement &&
                      studentData.englishRequirement.writing
                          ? studentData.englishRequirement.writing
                          : null}
                    </li>
                    <li>
                      <span>Listening:</span>{" "}
                      {studentData &&
                      studentData.englishRequirement &&
                      studentData.englishRequirement.listening
                          ? studentData.englishRequirement.listening
                          : null}
                    </li>
                    <li></li>
                  </ul>
                </div>
              </div>
              <div
                  className="tab-pane fade"
                  id="Applications"
                  role="tabpanel"
                  aria-labelledby="Applications-tab"
              >
                <button
                    className="btn blue-btn ms-auto mb-3"
                    onClick={() => showApplicationDrawer()}
                >
                  Add Application
                </button>

                <div className="detail-box application-status">
                  {applicationList &&
                  applicationList.map((item) => {
                    let {courseId, universityId} = item;
                    return (
                        <>
                          <div className="flex box">
                            <h3>
                              {universityId && universityId.universityName
                                  ? universityId.universityName
                                  : ""}{" "}
                              <div
                                  className={`badge ${statusColor[item.status]}`}
                              >
                                {item.status}
                              </div>
                            </h3>

                            <DropDown
                                options={loadMenu(item)}
                                onClick={(action) => {
                                  menuEvents(action, item, studentData._id);
                                }}
                            >
                              <img
                                  src="./app/img/action6.svg"
                                  alt=""
                                  style={{marginLeft: "auto", width: "30px"}}
                              />
                            </DropDown>
                            {/* ) : null} */}
                          </div>

                          <ul>
                            <li>
                              <span>Fees:</span> {item.tuitionFee}
                            </li>
                            <li>
                              <span>Campus:</span>{" "}
                              {item.campusId && item.campusId.campus}
                            </li>
                            <li>
                              <span>Duration:</span>{" "}
                              {item.courseDetails &&
                              item.courseDetails.courseDuration}{" "}
                              Years
                            </li>
                            <li>
                              <span>Added on:</span> {displayDate(item.addedOn)}
                            </li>
                            <li>
                              <span>Program:</span>
                              {courseId && courseId.courseName
                                  ? courseId.courseName
                                  : ""}
                            </li>
                            <li>
                              <span>Last Activity:</span>
                              {displayDate(item.updatedAt)}
                            </li>
                            <li>
                              <span>User:</span> {item.addByUserId.name}
                            </li>
                            <li></li>
                          </ul>
                        </>
                    );
                  })}
                </div>
              </div>
              <div
                  className="tab-pane fade"
                  id="Documents"
                  role="tabpanel"
                  aria-labelledby="Documents-tab"
              >
                {" "}
                <a
                    href="#"
                    // className=" ms-auto mb-3"
                    style={{
                      // display: "inline-block",
                      // marginLeft: "auto !important",
                      marginLeft: "82%",
                      textDecoration: "underline",
                      color: "#3E6AB4",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      events.showNewSingleDoc(studentData._id);
                    }}
                >
                  Add Other-Document
                </a>
                {documentArray.map((eachDoc) => {
                  return (
                      <>
                        <div className="detail-box">
                          <div className="flex box">
                            <h3>{eachDoc.name}</h3>
                          </div>
                          <div className={"upload_form"}>
                            <div className="col-lg-12 col-12 mb-4">
                              <div className="form-group">
                                <div>
                                  {eachDoc.documents && eachDoc.documents.length
                                      ? eachDoc.documents.map((docType, index) => {
                                        const matchingDocuments =
                                            getMatchingDocuments(
                                                docType,
                                                eachDoc.name
                                            );
                                        return (
                                            <div className="card-box border-grey p-3 mb-4">
                                              <div
                                                  className="flex-box"
                                                  style={{
                                                    flexWrap: "nowrap",
                                                    margin: 0,
                                                  }}
                                              >
                                                <label className="form-control">
                                                  {docType}{" "}
                                                  {matchingDocuments.map(
                                                      (document) => (
                                                          <span>
                                                  {document.name.length > 6
                                                      ? document.name.substring(
                                                          0,
                                                          6
                                                      ) +
                                                      "[...]" +
                                                      document.name.slice(-4)
                                                      : document.name}
                                                            <button
                                                                type="button"
                                                                className="p-0"
                                                            >
                                                    <Popconfirm
                                                        title="Are you sure, you want to delete this Document?"
                                                        onConfirm={() => {
                                                          events.deleteSingleDocument(
                                                              document._id
                                                          );
                                                        }}
                                                        okText="Yes"
                                                        cancelText="No"
                                                    >
                                                      <span>
                                                        <i
                                                            className="fa fa-times"
                                                            // onClick={() =>
                                                            //   events.deleteSingleDocument(
                                                            //     document._id
                                                            //   )
                                                            // }
                                                            style={{
                                                              cursor: "pointer",
                                                            }}
                                                        ></i>
                                                      </span>
                                                    </Popconfirm>
                                                  </button>
                                                </span>
                                                      )
                                                  )}
                                                </label>
                                                <div className="upload">
                                                  <a
                                                      className="btn blue-btn"
                                                      onClick={(e) => {
                                                        events.showSingleDoc(
                                                            docType,
                                                            eachDoc.name,
                                                            studentId._id
                                                        );
                                                      }}
                                                  >
                                                    <img
                                                        src={imgPath.upload}
                                                        alt=""
                                                    />
                                                    Upload
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                        );
                                      })
                                      : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                  );
                })}
              </div>

              <div
                  className="tab-pane fade"
                  id="Education"
                  role="tabpanel"
                  aria-labelledby="Education-tab"
              >
                <div className="detail-box">
                  <h3>Education</h3>
                  {studentData &&
                  studentData.education.map((item) => {
                    return (
                        <>
                          <ul>
                            <li>
                              <span>Qualification:</span> {item.qualification}
                            </li>
                            {item.university ? (
                                <li>
                                  <span>University:</span> {item.university}
                                </li>
                            ) : (
                                <li>
                                  <span>Board:</span> {item.board}
                                </li>
                            )}
                            {item.courseName ? (
                                <li>
                                  <span>Course:</span> {item.courseName}
                                </li>
                            ) : (
                                item.schoolName && (
                                    <li>
                                      <span>School Name:</span> {item.schoolName}
                                    </li>
                                )
                            )}
                            <li>
                              <span>Passing Year:</span> {item.passingYear}
                            </li>
                            <li>
                              <span>Percentage:</span> {item.percentage}
                            </li>
                            {item.stream ? (
                                <li>
                                  <span>Stream:</span> {item.stream}
                                </li>
                            ) : (
                                ""
                            )}
                          </ul>
                        </>
                    );
                  })}
                </div>
              </div>
              <div
                  className="tab-pane fade"
                  id="Experience"
                  role="tabpanel"
                  aria-labelledby="Experience-Tab"
              >
                <div className="detail-box">
                  <h3>Experience</h3>
                  {studentData &&
                  studentData.workExperience.map((item) => {
                    return (
                        <>
                          <ul>
                            <li>
                              <span>Company Name:</span>
                              {item.companyName}
                            </li>
                            <li>
                              <span>Position:</span> {item.position}
                            </li>
                            <li>
                              <span>Period:</span> {item.time}
                            </li>
                            <li></li>
                          </ul>
                        </>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        {singleDocState.visible ? (
            <UploadSingleDocument
                {...singleDocState}
                onClose={events.hideSingleDoc}
                studentId={studentId}
                onSubmit={() => {
                  events.hideSingleDoc();
                }}
            />
        ) : null}
        {newsingleDocState.visible ? (
            <UploadNewDocument
                uploadType={"Student"}
                {...newsingleDocState}
                onClose={events.hideNewSingleDoc}
                studentId={studentId}
                onSubmit={() => {
                  events.hideNewSingleDoc();
                }}
            />
        ) : null}
        {addNoteState.visible ? (
            <AddNote
                studentId={studentId}
                {...addNoteState}
                onClose={actionEvents.hideAddNote}
                onSubmit={() => {
                  actionEvents.hideAddNote();
                  apiApplication();
                }}
            />
        ) : null}
        {nextStepState.visible ? (
            <UpdateStatus
                //   applicationId={applicationId}
                //   countryId={countryId}
                {...nextStepState}
                onClose={actionEvents.hideMoveToStep}
                studentId={studentId}
                onSubmit={() => {
                  actionEvents.hideMoveToStep();
                  apiApplication();
                }}
            />
        ) : null}
        {noteListState.visible ? (
            <NoteList
                applicationId={noteListState.applicationId}
                {...noteListState}
                onClose={actionEvents.hideNoteList}
                onSubmit={() => {
                  actionEvents.hideNoteList();
                  apiApplication();
                }}
            />
        ) : null}{" "}
        {statusListState.visible ? (
            <StatusList
                applicationId={statusListState.applicationId}
                {...statusListState}
                onClose={actionEvents.hideStatusList}
                onSubmit={() => {
                  actionEvents.hideStatusList();
                  apiApplication();
                }}
            />
        ) : null}
        {activityListState.visible ? (
            <ApplicationActivityList
                applicationId={activityListState.applicationId}
                {...activityListState}
                onClose={actionEvents.hideActivityList}
                onSubmit={() => {
                  actionEvents.hideActivityList();
                  apiApplication();
                }}
            />
        ) : null}
        {applicationOpen ? (
            <AddMoreApplication
                visible={applicationOpen}
                onClose={onApplicationDrawer}
                studentId={studentId}
            />
        ) : null}
      </>
  );
};
export default StudentProfile;
