import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Card, Form } from "../../../components/Elements/appUtils";
import { GetEachFormFields } from "../../../components/_utils/formUtils";
import { loadCountryList } from "../state/action";
import { AddCityFxn, getRelatedStateListFxn } from "./action";

const AddCity = (props) => {
  const { getFieldDecorator, getFieldValue } = props.form;
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  let dispatch = useDispatch();

  useEffect(() => {
    loadCountryFxn();
  }, []);

  const loadCountryFxn = async () => {
    let x = await loadCountryList();
    setCountryList(x);
  };

  const getRelatedStates = async (id) => {
    if (id) {
      let { data } = await getRelatedStateListFxn(id);
      setStateList(data);
    }
  };

  const handleSubmit = (e) => {
    const { form } = props;
    e.preventDefault();
    form.validateFields(async (err, valData) => {
      if (!err) {
        let x = await dispatch(AddCityFxn(valData));
        props.form.setFieldsValue({
          country: "",
          state: "",
          cityName: "",
        });
      }
    });
  };

  let inputTypes = {
    fields: [
      {
        key: "country",
        label: "Country",
        required: true,
        placeholder: "Country Name",
        type: "select",
        options: countryList,
        keyAccessor: (x) => x._id,
        valueAccessor: (x) => `${x.countryName}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            country: x,
            state: undefined,
          });
          getRelatedStates(x);
        },
        span: 8,
      },

      {
        key: "state",
        label: "State",
        required: true,
        placeholder: "State Name",
        type: "select",
        options: stateList,
        keyAccessor: (x) => x._id,
        valueAccessor: (x) => `${x.stateName}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            state: x,
          });
        },
        span: 8,
      },

      {
        key: "cityName",
        label: "City",
        required: true,
        placeholder: "City Name",
        type: "text",
      },
    ],
  };
  return (
    <>
      <Card title={"Add City"}>
        <Form onSubmit={handleSubmit}>
          <div className={"row"}>
            {inputTypes.fields.map((item, key) => {
              return (
                <div className={"col-md-6 pt-3"} key={key}>
                  <GetEachFormFields {...props.form} item={item} />
                </div>
              );
            })}
          </div>

          <div style={{ marginTop: "20px" }}>
            <Button type={"primary"} htmlType="submit">
              submit
            </Button>
          </div>
        </Form>
      </Card>
    </>
  );
};

export default Form.create()(AddCity);
