import _ from "lodash";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {
    Button,
    Card,
    Form,
    Modal,
    notification,
} from "../../../components/Elements/appUtils";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import {
    getCampusListFxn,
    getCourseCampusListFxn,
    getIntakeListFxn,
    getRelatedRepresentativeUniversityListFxn,
} from "../../AddStudent/actions";
import {getRelatedUniversityListFxn} from "../../Course/action";
import {companyCountryList} from "../../University/action";
import {getUserCountryStatusWiseFxn} from "../../Users/action";
import {
    getCompanyCourseListFxn,
    getRelatedCourseListFxn,
} from "../../template/actions";
import {addAppliactionFxn} from "../actions";

const AddMoreApplication = (props) => {
    const {getFieldDecorator} = props.form;
    let {visible, onClose, studentId} = props;
    const dispatch = useDispatch();
    const [countryList, setCountryList] = useState([]);
    const [intakesList, setIntakesList] = useState([]);
    const [courseList, setCourseList] = useState([]);
    const [campusList, setCampusList] = useState([]);
    const [universityList, setUniversityList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [intake, setIntake] = useState({});

    const handleSubmit = (e) => {
        const {form} = props;
        e.preventDefault();
        form.validateFields(async (err, valData) => {
            if (!err) {
                valData.studentId = studentId;

                valData.intake = intake;
                let x = await dispatch(addAppliactionFxn(valData));
                if (x.success) {
                    props.form.setFieldsValue({
                        countryId: "",
                        universityId: "",
                        courseId: "",
                        campus: "",
                        intake: "",
                        // assignedUserId: undefined,
                    });
                    onClose();
                }
            } else {
                if (err.countryId) {
                    notification.warning({
                        message: "Please Select Country",
                    });
                    return;
                }
                if (err.universityId) {
                    notification.warning({
                        message: "Please Select Univesity",
                    });
                    return;
                }
                if (err.courseId) {
                    notification.warning({
                        message: "Please Select Course",
                    });
                    return;
                }
                /*if (err.campus) {
                                    notification.warning({
                                        message: "Please Select Campus",
                                    });
                                    return;
                                }
                                if (err.intake) {
                                    notification.warning({
                                        message: "Please Select Intake",
                                    });
                                    return;
                                }*/
                // if (err.assignedUserId) {
                //     notification.warning({
                //         message: "Please Select AssignTo User",
                //     });
                //     return;
                // }
            }
        });
    };

    const selectIntakes = (_id) => {
        let intake = _.find(intakesList, (item) => {
            return item._id === _id;
        });
        if (intake) {
            let {month, year} = intake;
            setIntake({month, year});
        }
    };

    useEffect(() => {
        handleState();
    }, []);

    const loadUserForAssignApp = async (countryId) => {
        let obj = {
            countryId,
            status: "Waiting to Apply",
        };
        let {data, success} = await dispatch(getUserCountryStatusWiseFxn(obj));
        if (success) {
            setUserList(data);
        }
    };

    const handleState = async () => {
        let x = await companyCountryList();
        setCountryList(x.data);
    };

    const getRelatedUniversities = async (id) => {
        if (id) {
            let {data} = await getRelatedUniversityListFxn(id);
            setUniversityList(data);
        }
    };
    const getRelatedCourse = async (id) => {
        if (id) {
            let obj = {
                universityId: id,
                sortField: "courseName",
                sortOrder: "descend",
            };
            let {data} = await getRelatedCourseListFxn(obj);
            setCourseList(data.data);
        }
    };

    const getRelatedCampus = async (id) => {
        if (id) {
            let data = await getCourseCampusListFxn({courseId: id});
            if (data && data.data && data.data.length) {
                setCampusList(data.data);
            }
        }
    };

    const getRelatedIntakes = async (id) => {
        if (id) {
            let {data} = await getIntakeListFxn({courseId: id});
            if (data && data.intakeList && data.intakeList.length) {
                setIntakesList(data.intakeList);
            }
        }
    };

    let inputTypes = {
        fields: [
            {
                key: "countryId",
                label: "Country",
                required: true,
                showStar: true,
                placeholder: "Country Name",
                type: "select",
                options: countryList,
                keyAccessor: (x) => x._id,
                valueAccessor: (x) => `${x.countryName}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        countryId: x,
                        universityId: undefined,
                        courseId: undefined,
                        campus: undefined,
                        intake: undefined,
                        assignedUserId: undefined,
                    });
                    getRelatedUniversities(x);
                    loadUserForAssignApp(x);
                },
                span: "col-md-12",
            },

            {
                key: "universityId",
                label: "University",
                required: true,
                placeholder: "University",
                type: "select",
                showStar: true,

                span: "col-md-12",
                options: universityList,
                keyAccessor: (x) => x._id,
                valueAccessor: (x) => `${x.universityName}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        universityId: x,
                        courseId: undefined,
                        campus: undefined,
                        intake: undefined,
                        assignedUserId: undefined,
                    });
                    getRelatedCourse(x);
                },
            },

            {
                key: "courseId",
                label: "Course",
                required: true,
                span: "col-md-12",
                placeholder: "Course Name",
                showStar: true,

                type: "select",
                options: courseList,
                keyAccessor: (x) => x._id,
                valueAccessor: (x) => `${x.courseName}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        courseId: x,
                        campus: undefined,
                        intake: undefined,
                        assignedUserId: undefined,
                    });
                    getRelatedIntakes(x);
                    getRelatedCampus(x);
                },
            },
            {
                key: "campus",
                label: "Campus",
                // required: true,
                placeholder: "Campus",
                // showStar: true,

                span: "col-md-6",
                type: "select",
                options: campusList,
                keyAccessor: (x) => x.campus,
                valueAccessor: (x) => `${x.campus}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        campus: x,
                        intake: undefined,
                        assignedUserId: undefined,
                    });
                },
            },
            {
                key: "intake",
                label: "Intakes",
                // showStar: true,

                // required: true,
                placeholder: "Intake",
                type: "select",
                options: intakesList,
                keyAccessor: (x) => x.month,
                valueAccessor: (x) => `${x.month} (${x.year})`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        intake: x,
                        assignedUserId: undefined,
                    });
                    selectIntakes(x);
                },
            },

            {
                key: "assignedUserId",
                label: "Assign To",
                placeholder: "Assign to",
                type: "select",
                options: userList,
                keyAccessor: (x) => x._id,
                valueAccessor: (x) => `${x.name}`,
                showStar: true,

                onChange: (x) => {
                    props.form.setFieldsValue({
                        assignedUserId: x,
                    });
                },
            },
        ],
    };

    return (
        <>
            <Modal
                visible={visible}
                onClose={onClose}
                width={"50%"}
                title={"Add Application"}
            >
                <Form onSubmit={handleSubmit}>
                    <div className={"row"}>
                        {inputTypes.fields.map((item, key) => {
                            return (
                                <div className={"col-md-6 pt-3"} key={key}>
                                    <GetEachFormFields {...props.form} item={item}/>
                                </div>
                            );
                        })}
                    </div>
                    <Button
                        type={"primary"}
                        htmlType="submit"
                        className={"btn main-btn mt-4"}
                    >
                        submit
                    </Button>
                </Form>
            </Modal>
        </>
    );
};
export default Form.create()(AddMoreApplication);
