import React, {useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Popconfirm, Table, Card, HyperLink} from "../../../components/Elements/appUtils";
import {templateListFxn, updateTemplateActiveStatusFxn} from "../actions";
import {useDispatch} from "react-redux";
import {apiUrl} from "../../../settings";

let {TableCard} = Card;
const TemplateList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    let tableRef = useRef();

    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            let {data} = await templateListFxn({
                ...params,
                regExFilters: ["categoryName"],
            });
            console.log(data)
            resolve(data);
        });
    };

    let editTemplate = (id) => {
        navigate(`/template-view?id=${id}`);
    };

    const updateActiveStatus = async (templateId) => {
        let resp = await dispatch(updateTemplateActiveStatusFxn({templateId}));
        if (resp) {
            tableRef.current.reload()
        }
    }


    const columns = [
        {
            title: "Country",
            dataIndex: "countryId",
            key: "countryId",
            searchText: "countryId",
            render: (item, record) => {
                return item && item.countryName ? item.countryName : null;
            },
        },
        {
            title: "University Name",
            dataIndex: "universityId",
            key: "universityId",
            render: (item, record) => {
                return item && item.universityName ? item.universityName : null;
            },
        },
        {
            title: "Course Name",
            dataIndex: "courseId",
            key: "courseId",
            render: (item, record) => {
                return item && item.courseName ? item.courseName : null;
            },
        },
        {
            title: "Template Name",
            dataIndex: "templateName",
            key: "templateName",
            searchTextName: "templateName",
            filterRegex: true,
        },
        {
            title: "Sections",
            dataIndex: "sections",
            key: "sections",
            render: (item) => {
                return item && item.length ? (
                    <a className={"btn btn-info btn-xs"}>{item.length}</a>
                ) : null;
            },
        },
        {
            title: "Status",
            dataIndex: "active",
            key: "active",
            render: (item, record) => {
                return (
                    <>
                        <Popconfirm
                            title={'Are your sure, you want to update status?'}
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => {
                                updateActiveStatus(record._id)
                            }}>
                            {item ?
                                <span className="active">Active</span>
                                : <span className="inactive">Un-Active</span>
                            }
                        </Popconfirm>
                    </>
                )
            },
        },

        {
            title: "Action",
            dataIndex: "action",
            width: 200,
            render: (val, record) => {
                let {originalDocument} = record;
                return (
                    <div className={'btn_group'}>
                        <a className={"btn btn-primary btn-xs"} onClick={() => {
                            editTemplate(record._id)
                        }}>
                            View Info
                        </a>
                        <a className={"btn btn-default btn-xs"}
                           href={`${apiUrl}/template/${record._id}`}>
                            Preview
                        </a>
                        {originalDocument && originalDocument.path ?
                            <a className={"btn btn-default btn-xs"} href={originalDocument.path} target={'_blank'}>
                                Docs
                            </a> : null}
                    </div>
                );
            },
        },
    ];
    return (
        <>
            <TableCard
                title={"Template List"}
                extraBtn={[
                    {
                        name: "Add Template",
                        action: () => {
                            navigate(`/master/add-template`);
                        }
                    }
                ]}>
                <Table apiRequest={apiRequest} columns={columns} ref={tableRef} extraProps={{scroll: 2000}}/>
            </TableCard>
        </>
    );
};

export default TemplateList;
