import _ from "lodash";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import Card from "../../components/Elements/Card";
import {
    Button,
    Drawer,
    Form,
    InputBox,
    Modal,
    notification,
} from "../../components/Elements/appUtils";
// import AddBankAccount from "./addBankAccounts";
// import {addWithdrawalFxn, addWithdrawalSendOtpFxn} from "../../withdrawal/actions/withdrawalAction";
import {GetEachFormFields} from "../../components/_utils/formUtils";
import {addAppliactionFxn} from "../Student/actions";
import {GetRelatedUserFxn} from "../Users/action";
import {
    AddTransactionFxn,
    BankAcountListFxn,
    addWithdrawalFxn,
    sendWithdrawalOtpFxn,
} from "./action";
import AddBankAcount from "./addBankAcount";

let initState = {
    bankId: "",
  balance: 0,
  amount: 0,
};
const WithdrawalComponent = (props) => {
  const {
      onClose,
      visible,
      onSubmit,
      form: {setFieldsValue},
  } = props;
  let [state, setState] = useState(initState);
  let [bankList, setBankList] = useState([]);
  let [visibleBankDrawer, setVisibleBankDrawer] = useState(false);
  let [visibleOtpScreen, setVisibleOtpScreen] = useState(false);
  let [otp, setOtp] = useState("");

  let dispatch = useDispatch();
  const loadBankList = async () => {
      let {data} = await dispatch(BankAcountListFxn({}));
    setBankList(data);
  };
  useEffect(() => {
    loadBankList();
  }, []);
  useEffect(() => {
      let user = localStorage.getItem("user")
          ? JSON.parse(localStorage.getItem("user"))
          : null;
    if (user._id) {
      loadCurrentCommission(user._id);
    }
  }, []);

  const updateState = (data) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...data,
      };
    });
  };

  const events = {
    showBankDrawer: () => {
      setVisibleBankDrawer(true);
    },
    hideBankDrawer: () => {
      setVisibleBankDrawer(false);
      loadBankList();
    },
  };

  let loadCurrentCommission = async (id) => {
      let {data} = await dispatch(GetRelatedUserFxn(id));
    if (data) {
        setFieldsValue({balance: data.amount});
    }
  };

  const handleChange = (data) => {
    setState((prevData) => {
      return {
        ...prevData,
        ...data,
      };
    });
  };

  const handleSubmit = (e) => {
      const {form} = props;
    e.preventDefault();
    form.validateFields(async (err, valData) => {
      if (!err) {
        if (!valData.bankId) {
            notification.warning({message: "Please choose bank"});
          return;
        }
        if (!valData.amount || valData.amount < 0) {
            notification.warning({message: "Please enter amount"});
          return;
        }
        if (valData.amount > valData.balance) {
            notification.warning({message: "Please enter valid amount"});
          return;
        }
        let findBank = _.find(bankList, (item) => {
          return item._id == valData.bankId;
        });
        valData = {
          ...valData,
          ...findBank,
        };
        delete valData["_id"];
        let x = await dispatch(sendWithdrawalOtpFxn(valData));
        if (x.success) {
          setState(valData);
          setVisibleOtpScreen(true);
        }
      } else {
      }
    });
  };

  const submitHandleOtp = async (e) => {
    if (!otp) {
      notification.warning({
        message: "Please enter Otp",
      });
      return;
    }
      let x = await dispatch(addWithdrawalFxn({...state, otp}));
    if (x.success) {
      setState({});
      setVisibleOtpScreen(false);
      onSubmit();
    }
  };

  let inputTypes = {
    fields: [
      {
        key: "bankId",
        label: "Bank",
        required: false,
        placeholder: "Bank",
        type: "select",
        options: bankList,
        keyAccessor: (x) => x._id,
        valueAccessor: (x) => `${x.bankName} (${x.accountNo})`,
        onChange: (x) => {
          props.form.setFieldsValue({
            bankId: x,
          });
        },
        span: "col-md-12",
        customBtn: (
            <a
                className="btn add"
                onClick={() => {
                    events.showBankDrawer();
                }}
            >
                <i className="fa fa-plus-circle"></i>
            </a>
        ),
      },
      {
        key: "balance",
        label: "Balance",
        required: true,
        placeholder: "balance",
        type: "text",
        disabled: true,
      },

      {
        key: "amount",
        label: "Amount",
        required: false,
        placeholder: "Amount",
        type: "number",
      },
    ],
  };

  return (
      <>
          <Drawer
              visible={visible}
              title={"Withdrawal"}
              onClose={onClose}
              width={"45%"}
          >
              <div>
                  <Form onSubmit={handleSubmit}>
                      <div className={"repeat-sec"}>
                          <div className={"row"}>
                              {inputTypes.fields.map((item, key) => {
                                  return (
                                      <div className={"col-md-12"} key={key}>
                                          <GetEachFormFields {...props.form} item={item}/>
                                      </div>
                                  );
                              })}
                          </div>
                      </div>
                      <Button
                          type={"primary"}
                          htmlType="submit"
                          className={"btn main-btn mt-4"}
                      >
                          submit
                      </Button>
                  </Form>
              </div>

              {visibleBankDrawer ? (
                  <AddBankAcount
                      onClose={events.hideBankDrawer}
                      visible={visibleBankDrawer}
                  />
              ) : null}

              {visibleOtpScreen ? (
                  <Modal
                      visible={visibleOtpScreen}
                      closable={false}
                      title={"Enter OTP"}
                      footer={false}
                  >
                      <div style={{textAlign: "center", marginBottom: 10}}>
                          <h5>
                              A text message with a 5-digit verification code has been sent to
                              your phone number.
                          </h5>
                          <InputBox title={"Enter OTP"}>
                              <input
                                  className={"form-control mt10"}
                                  type={"password"}
                                  minLength={5}
                                  maxLength={5}
                                  value={otp}
                                  onChange={({target}) => {
                                      setOtp(target.value);
                                  }}
                              />
                          </InputBox>
                          <Button
                              className={"btn btn-success mt10"}
                              onClick={submitHandleOtp}
                          >
                              Submit OTP
                          </Button>
                      </div>
                  </Modal>
              ) : null}
          </Drawer>
      </>
  );
};

export default Form.create()(WithdrawalComponent);
