import React, {useState} from 'react'
import {AddFollowUpFxn} from '../actions/index';
import {useDispatch} from 'react-redux';
import {Button, Card, Drawer, Form, notification} from '../../../components/Elements/appUtils';
import {GetEachFormFields} from '../../../components/_utils/formUtils';
import {followUpOptions, reminderMeOptions} from '../../../components/_utils/_utils';

const AddFollowup = (props) => {
    let {visible, onClose, onSubmit, leadId, apiRequest} = props;
    const {getFieldDecorator} = props.form;
    let dispatch = useDispatch()
    const handleSubmit = (e) => {
        const {form} = props;
        e.preventDefault();
        form.validateFields(async (err, valData) => {
            if (!err) {
                valData.leadId = leadId
                let x = await dispatch(AddFollowUpFxn(valData));
                if (!x.error) {
                    props.form.setFieldsValue({
                        type: "",
                        subject: "",
                        date: "",
                        time: '',
                        reminderTime: "",
                        notes: ""
                    })
                    onSubmit()
                }
            } else {
                notification.warning({
                    message: 'Fill All Required Fields'
                })
            }
        });
    }

    let inputTypes = {
        fields: [
            {
                key: "type",
                label: "FollowUp Types",
                required: true,
                placeholder: "FollowUp Types",
                type: "select",
                keyAccessor: (x) => x.option,
                valueAccessor: (x) => `${x.option}`,
                options: followUpOptions,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        type: x,
                    });
                },
            },

            {
                key: "subject",
                label: "FollowUp Subject",
                required: true,
                placeholder: "FollowUp Subject",
                type: "text",

            },
            {
                key: "date",
                label: "Next FollowUp Date",
                required: true,
                placeholder: "Next FollowUp Date",
                type: "date",
                onChange: (x) => {
                    console.log(x)
                    props.form.setFieldsValue({
                        date: x,
                    });
                }

            },
            {
                key: "time",
                label: "Start Time",
                required: true,
                placeholder: "Select Time",
                type: "time",
                onChange: (x) => {
                    props.form.setFieldsValue({
                        time: x,
                    });
                }
            },
            {
                key: "reminder",
                label: "Reminder In",
                required: true,
                placeholder: "Reminder In",
                type: "select",
                keyAccessor: (x) => x.option,
                valueAccessor: (x) => `${x.option}`,
                options: reminderMeOptions,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        reminder: x,
                    });

                },
                span: 6
            },
            {
                key: "reminderTime",
                label: " Time",
                required: true,
                placeholder: "Time",
                type: "number",
                span: 6
            },

            {
                key: "notes",
                label: " FollowUp Notes",
                required: true,
                placeholder: "FollowUp Notes",
                type: "textarea",
            },
        ]
    }
    return (
        <>
            <Drawer placement="right" onClose={onClose} visible={visible} width="45%" title={"Add Follow Up"}>
                <Card>
                    <Form onSubmit={handleSubmit}>
                        <div className={"row"}>
                            {inputTypes.fields.map((item, key) => {
                                return (
                                    <div className={`pt-3 ${item.span == 6 ? "col-md-6" : "col-md-12"}`} key={key}>
                                        <GetEachFormFields
                                            {...props.form}
                                            item={item}
                                        />
                                    </div>
                                );
                            })}
                        </div>

                        <div style={{marginTop: "20px"}}>
                            <Button type={"primary"} htmlType="submit">
                                submit
                            </Button>
                        </div>
                    </Form>
                </Card>
            </Drawer>
        </>
    )
}

export default Form.create()(AddFollowup)
