import _ from "lodash";
import moment from "moment";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {
    Button,
    Card,
    DocumentTypes,
    Form,
    InputBox,
    Modal,
    notification,
} from "../../components/Elements/appUtils";
import {GetEachFormFields} from "../../components/_utils/formUtils";
import {addDocumentToLeadFxn} from "./action";

let initialState = {
    fileList: [],
    uploadKey: moment(),
    fileName: "",
};
const UploadSingleLeadDocument = (props) => {
    let tableRef = useRef();
    let {
        visible,
        leadId,
        onClose,
        documentType,
        type,
        form: {getFieldValue},
    } = props;
  let dispatch = useDispatch();
  const [state, setState] = useState(initialState);
  const [note, setNote] = useState("");
  useEffect(() => {
      props.form.setFieldsValue({documentType: documentType, type: type});
  }, []);
  const events = {
    chooseDocument: (e) => {
        let {name, files} = e.target;
      console.log(name, files, "fffffffffffffff");
      if (files && files.length) {
        setState({
          ...state,
          [name]: files[0],
          fileName: files[0].name,
        });
      }
    },
    handleSubmit: async (e) => {
        const {form} = props;
      e.preventDefault();
      form.validateFields(async (err, valData) => {
        if (!(state[documentType] && state[documentType].name)) {
          notification.warning({
            message: "Please Choose Document",
          });
          return;
        }
        // if (!valData.note) {
        //   notification.warning({
        //     message: "Please Enter Note",
        //   });
        //   return;
        // }

        valData.leadId = leadId;
        valData.documentType = documentType;
        valData.documentCategory = type;
        let fd = new FormData();
        fd.append("obj", JSON.stringify(valData));
        if (state[documentType] && state[documentType].name) {
          fd.append("documents", state[documentType]);
        }
        let x = await dispatch(addDocumentToLeadFxn(fd));
        if (!x.error) {
          onClose();
        }
      });
    },
  };

  let inputTypes = {
    fields: [
      {
        key: "documentType",
        label: "Document Type",
        required: false,
        disabled: true,
        span: 12,
        placeholder: "Document Type",
        type: "text",
      },
      {
        key: "type",
        label: "Type",
        required: false,
        disabled: true,

        span: 12,
        placeholder: "Type",
        type: "text",
      },
      {
        key: `${documentType}`,
        label: "Document File",
        required: false,
        span: 12,

        placeholder: "Document File",
        type: "file",
        fileName: state && state[documentType] ? state[documentType] : "",
        // fileName: "ddddddd",
        onChange: (target) => {
          events.chooseDocument(target);
        },
      },
      // {
      //   key: "note",
      //   label: "Note",
      //   required: false,
      //   span: 12,
      //   placeholder: "Note",
      //   type: "textArea",
      // },
    ],
  };
  _.each(DocumentTypes, (documentType) => {
    initialState[documentType] = "";
  });
  return (
      <Modal
          visible={visible}
          onClose={onClose}
          width={"40%"}
          // height={"100%"}
          title={
              <>
                  <div className="d-flex">Upload {type}</div>
              </>
          }
          placement="right"
      >
          <Card shadow={false} padding={false}>
              <Form onSubmit={events.handleSubmit}>
                  <div className={"row"}>
                      {inputTypes.fields.map((item, key) => {
                          return (
                              <div
                                  className={`${item.span ? item.span : "col-md-6"}`}
                                  key={key}
                              >
                                  <GetEachFormFields {...props.form} item={item}/>
                              </div>
                          );
                      })}
                  </div>
                  <Button
                      type={"primary"}
                      htmlType="submit"
                      className="btn main-btn mt-4"
                  >
                      Submit
                  </Button>
              </Form>
              {/* <div className={"ml10"}>
        <InputBox title={`${documentType} Document`}>
          <input
            type={"file"}
            name={documentType}
            id={documentType}
            className={"form-control"}
            onChange={(e) => {
              props.chooseDocument(e);
            }}
          />
        </InputBox>
      </div> */}
              {/* <div>
          <div className={"row"}>
            <InputBox title={"Type"}>
              <select className={"form-control"} value={type} disabled={true}>
                <option value={type}>{type}</option>
              </select>
            </InputBox>
            <InputBox title={"Document Type"}>
              <select
                  className={"form-control"}
                  value={documentType}
                  disabled={true}
              >
                <option value={documentType}>{documentType}</option>
              </select>
            </InputBox>

            <InputBox title={"Choose Document"}>
              <input
                  type={"file"}
                  name={documentType}
                  id={documentType}
                  className={"form-control"}
                  onChange={(e) => {
                    events.chooseDocument(e);
                  }}
              />
            </InputBox>
            <InputBox title={"Note"}>
            <textarea
                placeholder="Enter Note"
                className={"form-control"}
                onChange={({target}) => {
                  setNote(target.value);
                }}
                required={true}
                value={note}
            />
            </InputBox>
          </div>
          <div style={{marginTop: "20px"}}>
            <button className={"btn main-btn"}
                    onClick={() => events.handleSubmit()}>
              Upload
            </button>
          </div>
        </div> */}
              {/* <button className="btn" type="submit" onClick={events.handleSubmit}>
        save
      </button> */}
          </Card>
      </Modal>
  );
};
export default Form.create()(UploadSingleLeadDocument);
