import React from "react";
import {useDispatch} from "react-redux";
import {Button, Card, Drawer, Form} from "../../components/Elements/appUtils";
import {GetEachFormFields} from "../../components/_utils/formUtils";
import {ResolveFeedbackFxn} from "./action";

const ResolveLead = (props) => {
  let {visible, onClose, feedbackId} = props;
  const {getFieldDecorator} = props.form;
  let dispatch = useDispatch();
  let inputTypes = {
    fields: [
      {
        key: "details",
        label: "Details",
        required: true,
        placeholder: "Details",
        type: "textarea",
      },
    ],
  };
  const handleClose = (e) => {
    const {form} = props;
    e.preventDefault();
    form.validateFields(async (err, valData) => {
      if (!err) {
        valData.feedbackId = feedbackId;
        valData.status = "Resolved";
        let x = await dispatch(ResolveFeedbackFxn(valData));
        props.form.setFieldsValue({
          reason: "",
        });
        onClose();
      }
    });
  };
  return (
      <>
        <Drawer
            placement="right"
            title={"Resolve Feedback"}
            onClose={onClose}
            visible={visible}
            width="40%"
        >
          <Card>
            <Form onSubmit={handleClose}>
              <div className={"row"}>
                {inputTypes.fields.map((item, key) => {
                  return (
                      <div
                          className={`pt-3 ${
                              item.span == 6 ? "col-md-6" : "col-md-12"
                          }`}
                          key={key}
                      >
                        <GetEachFormFields {...props.form} item={item}/>
                      </div>
                  );
                })}
              </div>

              <div style={{marginTop: "20px"}}>
                <Button type={"primary"} htmlType="submit">
                  submit
                </Button>
              </div>
            </Form>
          </Card>
        </Drawer>
      </>
  );
};
export default Form.create()(ResolveLead);
