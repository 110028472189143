import React, {useEffect, useState} from "react"
import {applicationCountCountryWiseFxn} from "../actions";
import _ from "lodash"
let $ = window.$;
const ApplicationStatusWise = () => {
    let [countryData, setCountryData] = useState([])
    let [statusList, setStatusList] = useState([])
    let [activeCountryId, setActiveCountryId] = useState("")
    let loadData = async () => {
        let {data} = await applicationCountCountryWiseFxn({});
        setCountryData(data)

        if (data && data.length) {
            let currentIndex = data[0];
            setActiveCountryId(currentIndex._id);
            setStatusList(currentIndex.statusList)
        }
    }
    useEffect(() => {
        loadData()
    }, [])

    const chooseCountry = (data) => {
        setActiveCountryId(data._id);
        setStatusList(data.statusList)
    }


    return (
        <>

            <section className="country-record mt-4">
                <div className="country-box m-0" style={{padding: "0 50px"}} id={'country-box-status'}>
                    {countryData && countryData.length ? countryData.map((eachCountry, index) => {
                        let {flag} = eachCountry;
                        return (
                            <div key={eachCountry._id} onClick={() => {
                                chooseCountry(eachCountry)
                            }}>
                                <div className={`outer-box ${activeCountryId == eachCountry._id ? "active" : ""}`}>
                                    <div className="box">
                                          <span>
                                       {flag && flag.path ? <img src={flag.path} alt=""/> : null}
                                          </span>
                                        <h4>{eachCountry.countryName}</h4>
                                    </div>
                                </div>

                            </div>
                        )
                    }) : null}

                </div>
                <div className="count-content">
                    <ul className="">
                        {statusList && statusList.length ? statusList.map((eachStatus) => {
                            return (
                                <li key={eachStatus.status}>
                                    <h2>{eachStatus.count}</h2>
                                    <p className="m-0">{eachStatus.status}</p>
                                </li>
                            )
                        }) : null}


                    </ul>
                </div>
            </section>
        </>
    )
}
export default ApplicationStatusWise
