import _ from "lodash";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import notification from "../../../components/Elements/Notification";
import {Button, Card, Form} from "../../../components/Elements/appUtils";
import {dateFormats, timeFormats} from "../../../components/_utils/_utils";
import {
    Countries,
    CurrencyJson,
    Flags,
    TimeZones,
} from "../../../components/_utils/appUtils";
import {LoadState} from "../../../components/_utils/countryUtil";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import SubscriptionPackage from "../../../components/subscriptionPackage";
import {AddAgentFxn} from "./action";

const initialState = {
    companyName: "",
    contactPerson: "",
    countryCode: "",
    companyContactNo: "",
    companyAddress: "",
    companyEmail: "",
    companyLogo: {},
    country: undefined,
    gstRegistration: true,
    gstInNo: "",
    cityName: undefined,
    countryName: undefined,
    stateName: undefined,
    postalCode: "",
    agentId: "",
    certificate: {},
    profilePhoto: {},
    referBy: "",
};

const RegistrationOptions = [
    {option: "Yes", value: true},
    {option: "No", value: false},
];

const AddCompany = (props) => {
    let dispatch = useDispatch();
    const {getFieldDecorator, getFieldValue} = props.form;
    let [formState, setState] = useState(initialState);
    let [activePlan, setActivePlan] = useState(null);
    const [stateArray, setStateArray] = useState([]);
    let [plan, setPlan] = useState({});

    const setLocalState = (data) => {
        setState({
            ...formState,
            ...data,
        });
    };

    const events = {
        chooseCountry: (name) => {
            let countryName = _.find(Countries, (item) => {
                return item.name == name;
            });
            if (countryName) {
                setState({
                    ...formState,
                    countryName: countryName.name ? countryName.name : "",
                });
                setStateArray(countryName.id ? LoadState(countryName.id) : []);
            }
        },
        chooseState: (name) => {
            let stateName = _.find(stateArray, (item) => {
                return item.name == name;
            });
            if (stateName) {
                setState({
                    ...formState,
                    stateName: stateName.name ? stateName.name : "",
                });
            }
        },
    };

    const handleSubmit = (e) => {
        const {form} = props;
        e.preventDefault();

        form.validateFields(async (err, valData) => {
            let {certificate, companyLogo, profilePhoto} = formState;
            if (!err) {
                if (!valData.contactPerson) {
                    notification.warning({
                        message: `Please enter contact person name`,
                    });
                    return;
                }
        if (!valData.companyName) {
          notification.warning({
            message: `Please enter  Company Name`,
          });
          return;
        }
        if (!valData.email) {
          notification.warning({
            message: `Please enter company Email`,
          });
          return;
        }
        // if (!valData.password) {
        //     notification.warning({
        //         message: `Please enter password`,
        //     });
        //     return;
        // }

        if (!valData.currency) {
          notification.warning({
            message: `Please choose currency.`,
          });
          return;
        }
        if (!valData.countryCode) {
          notification.warning({
            message: `Choose country code`,
          });
          return;
        }
        if (!valData.mobile) {
          notification.warning({
            message: `Please enter contact no`,
          });
          return;
        }
        /*   if (!valData.timezone) {
                               notification.warning({
                                   message: `Please choose timezone`,
                               });
                               return;
                           }


                           if (!valData.dateFormat) {
                               notification.warning({
                                   message: `Please choose date format`,
                               });
                               return;
                           }

                           if (!valData.timeFormat) {
                               notification.warning({
                                   message: `Please choose time format`,
                               });
                               return;
                           }


                           if (!valData.countryName) {
                               notification.warning({
                                   message: `Choose country`,
                               });
                               return;
                           }

                           if (!valData.stateName) {
                               notification.warning({
                                   message: `Choose state`,
                               });
                               return;
                           }*/
        /*  if (!valData.cityName) {
                              notification.warning({
                                  message: `Please Enter city`,
                              });
                              return;
                          }*/
        /* if (!valData.postalCode) {
                             notification.warning({
                                 message: `Please enter postal code`,
                             });
                             return;
                         }*/
        /*if (!valData.companyAddress) {
                            notification.warning({
                                message: `Please enter company address`,
                            });
                            return;
                        }*/
        /* if (!valData.monthlyPlan) {
                             notification.warning({
                                 message: `Please Choose Monthly Plan`,
                             });
                             return;
                         }*/

        if (!plan || (plan && !plan.name)) {
            notification.warning({message: "Please choose plan."});
          return;
        }

        // if (valData.password.length < 6) {
        //     notification.warning({
        //         message: `Please enter atleast 6 digit password`,
        //     });
        //     return;
        // }
        if (plan && plan.price) {
          notification.warning({
            message: `Please enter Card Holder Name`,
          });
          return;
        }
        let fd = new FormData();
        valData.userType = "headBranch";

                fd.append("obj", JSON.stringify({...valData, plan}));
        if (certificate && certificate.name) {
          fd.append("certificate", certificate);
        }
        if (companyLogo && companyLogo.name) {
          fd.append("companyLogo", companyLogo);
        }

        let x = await dispatch(AddAgentFxn(fd));
        if (!x.error) {
          props.form.setFieldsValue({
            companyName: "",
            contactPerson: "",
            countryCode: undefined,
              mobile: "",
              companyAddress: "",
              email: "",
              country: "",
              gstInNo: "",
              cityName: "",
              countryName: undefined,
              password: "",
              website: "",
              gstRegistration: "",
              stateName: undefined,
              postalCode: "",
              agentId: "",
              referBy: "",
              timezone: undefined,
              timeFormat: undefined,
              dateFormat: undefined,
              monthlyPlan: [],
              currency: undefined,
          });
            setPlan({});
            setState(initialState);
        }
      } else {
      }
    });
  };

  const handleFileChange = (e) => {
      const file = e.target.files[0];
      const name = e.target.name;
      const updatedFormData = {...formState};

      updatedFormData[name] = file;
      setState(updatedFormData);
  };

  const gstRegistrationOptions = [
      {option: "Yes", value: false},
      {option: "No", value: true},
  ];
  const monthlyPlanOptions = [
      {option: "Free-0$/year"},
      {option: "Premium-50$/year"},
      {option: "Business-129$/year"},
  ];

  const choosePlanFxn = (data) => {
      setPlan(data);
      setActivePlan(data);
  };

  let inputTypes = {
    fields: [
      {
        key: "contactPerson",
        label: "Contact Person",
        required: false,
        placeholder: "Contact Person",
        type: "text",
        showStar: true,
      },

      {
        key: "companyName",
        label: "Company Name",
        required: false,
        placeholder: "Company Name",
        type: "text",
        showStar: true,
      },

      {
        key: "email",
        label: "Contact Email",
        required: true,
        placeholder: "Contact Email",
        type: "text",
        showStar: true,
      },

      {
        key: "currency",
        label: "Currency",
        placeholder: "Choose Currency",
        type: "select",
        required: false,
        options: CurrencyJson,
        showSearch: true,
        keyAccessor: (x) => x.name,
        valueAccessor: (x) => x.name,
        onChange: (x) => {
          props.form.setFieldsValue({
            currency: x,
          });
        },
        showStar: true,
      },
      {
        key: "countryCode",
        placeholder: "Country Code",
        span: "col-md-3",
        type: "select",
        required: false,
        options: Flags,
        showSearch: true,
        keyAccessor: (x) => x.countryCode,
        valueAccessor: (x) => `${x.name} (${x.dialCode})`,
        onChange: (x) => {
          props.form.setFieldsValue({
            countryCode: x,
          });
        },
        showStar: true,
      },
      {
        key: "mobile",
        span: "col-md-3",
        label: "Contact Number",
        required: false,
        placeholder: "Contact Number",
        type: "text",
        showStar: true,
      },
      // {
      //     key: "timezone",
      //     label: "Timezone",
      //     type: "select",
      //     required: false,
      //     options: TimeZones,
        //     showSearch: true,
        //     keyAccessor: (x) => x.value,
        //     valueAccessor: (x) => `${x.name}`,
        //     onChange: (x) => {
        //         props.form.setFieldsValue({
        //             timezone: x,
        //         });
        //     },
        // },
        // {
        //     key: "dateFormat",
        //     label: "Date Format",
        //     type: "select",
        //     required: false,
        //     options: dateFormats,
        //     showSearch: true,
        //     keyAccessor: (x) => x,
        //     valueAccessor: (x) => `${x}`,
        //     onChange: (x) => {
        //         props.form.setFieldsValue({
        //             dateFormat: x,
        //         });
        //     },
        // },

        // {
        //     key: "timeFormat",
        //     label: "Time Format",
        //     type: "select",
        //     required: false,
        //     options: timeFormats,
        //     showSearch: true,
        //     keyAccessor: (x) => x.value,
        //     valueAccessor: (x) => x.name,
        //     onChange: (x) => {
        //         props.form.setFieldsValue({
        //             timeFormat: x,
        //         });
        //     },
        // },
        {
            key: "website",
            label: "Website(Optional)",
            placeholder: "Website(Optional)",
            type: "text",
        },
        {
            key: "countryName",
            label: "Country",
            required: false,
            type: "select",
            showSearch: true,
            allowClear: true,
            options: Countries,
            placeholder: "Country ",
            keyAccessor: (x) => x.name,
            valueAccessor: (x) => `${x.name}`,
            onChange: (x) => {
                props.form.setFieldsValue({
                    countryName: x,
                    stateName: "",
                    cityName: "",
                });
                events.chooseCountry(x);
            },
        },

        {
            key: "stateName",
            label: "State",
            required: false,
            type: "select",
            span: "col-md-2",
            showSearch: true,
            allowClear: true,
            placeholder: "State Name",
            options: stateArray,
            keyAccessor: (x) => x.name,
            valueAccessor: (x) => `${x.name}`,
            onChange: (x) => {
                props.form.setFieldsValue({
                    stateName: x,
                    cityName: "",
                });
                events.chooseState(x);
            },
        },
        {
            key: "postalCode",
            label: "Postal Code (Optional)",
            span: "col-md-4",
            required: false,
            placeholder: "Postal Code",
            type: "text",
        },
        {
            key: "cityName",
            label: "City Name",
            required: false,
            placeholder: "City Name",
            type: "text",
        },
        {
            key: "companyAddress",
            label: "Company Address (Optional)",
            required: false,
            placeholder: "Company Address",
            type: "text",
        },
        {
            key: "enableForIp",
            label: "Enable For Ip Address",
            placeholder: "Enable For Ip Address",
            options: RegistrationOptions,

            type: "select",
            keyAccessor: (x) => x.value,
            valueAccessor: (x) => `${x.option}`,
            onChange: (x) => {
                props.form.setFieldsValue({
                    enableForIp: x,
                });
            },
        },
        // {
        //   key: "referBy",
        //   label: "Refer By",
        //   placeholder: "Refer By",
        //   type: "number",
        // },

        // {
        //   key: "cityName",
        //   label: "City",
        //   required: true,
        //   placeholder: "City",
        //   type: "text",
        // },

        // {
        //   key: "gstRegistration",
        //   label: "GST Registration",
        //   options: gstRegistrationOptions,
        //   type: "select",
        //   keyAccessor: (x) => x.value,
        //   valueAccessor: (x) => `${x.option}`,
        //   onChange: (x) => {
        //     setLocalState({ gstRegistration: x });
        //   },
        // },
        /* {
                         key: "monthlyPlan",
                         label: "Monthly Plan",
                         placeholder: "Monthly Plan",
                         options: monthlyPlanOptions,
                         type: "select",
                         required: true,
                         keyAccessor: (x) => x.option,
                         valueAccessor: (x) => `${x.option}`,
                         onChange: (x) => {
                             props.form.setFieldsValue({monthlyPlan: x});
                         },
                     },
         */
      // {
      //   key: "gstInNo",
      //   label: "GSTIN Number",
      //   hidden: formState.gstRegistration,
      //   required: true,
      //   placeholder: "GSTIN Number",
      //   type: "text",
      // },

      // {
      //   label: "Company Logo",
      //   key: "companyLogo",
      //   type: "file",
      //   onChange: handleFileChange,
      // },
      // {
      //   label: "Certificate",
      //   key: "certificate",
      //   type: "file",
      //   onChange: handleFileChange,
      // },
      // {
      //   label: "Profile Photo",
      //   key: "profilePhoto",
      //   type: "file",
      //   onChange: handleFileChange,
      // },
    ],
    cardField: [
      {
        key: "cardHolderName",
        label: "Card Holder Name",
        hidden: !plan || (plan && !plan.price),
        placeholder: "Card Holder Name",
        type: "text",
        span: "col-md-12",
      },
      /*   {
                         label: "Card Number",
                         key: "cardNumber",
                         hidden: !plan || (plan && !plan.price),
                         placeholder: "Company Address",
                         type: "text",
                     },*/
      {
        label: "Valid Date",
        key: "validDate",
        hidden: !plan || (plan && !plan.price),
        placeholder: "Valid Date",
        type: "text",
        span: "col-md-6",
      },
      {
        label: "CVV",
        key: "cvv",
        hidden: !plan || (plan && !plan.price),
        placeholder: "CVV",
        type: "text",
        span: "col-md-6",
      },
    ],
  };

  useEffect(() => {
      props.form.setFieldsValue({
          currency: "INR",
          countryCode: "IN_+91",
          countryName: "India",
          enableForIp: false,
      });
      events.chooseCountry("India");
  }, []);

  return (
      <>
          <Card title={"Add Company"}>
              <Form onSubmit={handleSubmit}>
                  <div className={"row"}>
                      {inputTypes.fields.map((item, key) => {
                          return !item.hidden ? (
                              <div
                                  className={`${item.span ? item.span : "col-md-6"}`}
                                  key={key}
                              >
                                  <GetEachFormFields {...props.form} item={item}/>
                              </div>
                          ) : null;
                      })}
                  </div>
                  <div className={"row"}>
                      <div className="col-lg-6 col-12">
                          <div className="row mt-3">
                              <div className="col-lg-12 col-12">
                                  <div className="form-group">
                                      <div
                                          className="plan"
                                          data-bs-toggle="modal"
                                          data-bs-target="#staticBackdrop"
                                      >
                                          {plan && plan.planType ? (
                                              <>
                                                  {plan.planType} USD ${plan.price}({plan.periodName})
                                                  <span>
                            <img src="../app/img/edit.png" alt=""/>
                          </span>
                                              </>
                                          ) : (
                                              <>
                                                  Choose Plan
                                                  <span>
                            <img src="../app/img/edit.png" alt=""/>
                          </span>
                                              </>
                                          )}
                                      </div>
                                      {/*  <p className="m-0 pt-1" style={{fontSize: 16, fontWeight: 500}}>Choose Yearly
                                            Plan & Save <span style={{color: "green"}}>$300</span></p>*/}
                                  </div>
                              </div>
                              {inputTypes.cardField.map((item, key) => {
                                  return !item.hidden ? (
                                      <div
                                          key={key}
                                          className={`${item.span ? item.span : "col-md-6"}`}
                                      >
                                          <GetEachFormFields {...props.form} item={item}/>
                                      </div>
                                  ) : null;
                              })}
                          </div>
                      </div>
                  </div>

                  <Button
                      type={"primary"}
                      htmlType="submit"
                      className="btn main-btn mt-4"
                  >
                      Submit & Pay <img src="../app/img/angle.png" alt=""/>
                  </Button>
              </Form>
          </Card>

          <SubscriptionPackage choosePlan={choosePlanFxn} activePlan={activePlan}/>
      </>
  );
};
export default Form.create()(AddCompany);
