import _ from "lodash";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import currencyList from "../../../src/assets/jsonFiles/currency_list.json";
import {
  Button,
  Card,
  DatePicker,
  Form,
  InputBox,
  Select2,
  notification,
} from "../../components/Elements/appUtils";
import {
  InstallmentOptions,
  RoundOf,
  taxObj,
} from "../../components/_utils/_utils";
import {getSingleLeadFxn} from "../Leads/action";
import {PlansListFxn} from "../Plans/action";
import AddPlanDrawer from "../Plans/drawers/addPlanDrawer";
import {addPurchasePlanFxn} from "./actions/index";

let {TableCard} = Card;
const initState = {
  isServiceTax: false,
  isGst: false,
  discount: 0,
  totalAmount: 0,
  restAmount: 0,
  balance: 0,
  paidAmount: 0,
  netTotal: 0,
};

const planInit = {
  planId: undefined,
  amount: undefined,
  name: "",
};

const TableComponent = (props) => {
  let {title, children} = props;
  return (
      <tr>
        <td className={"label_col"}>{title}</td>
        <td className={"value_col"}>{children}</td>
      </tr>
  );
};

const DropDownComponent = (props) => {
  let {index, events, data, planList} = props;
  const [currencySymbol, setCurrencySymbol] = useState(null);

  useEffect(() => {
    const userString = localStorage.getItem("user");
    const user = JSON.parse(userString);
    const currency = user.currency;
    Symbol(currency);
  }, []);

  const Symbol = (name) => {
    let symbol = _.find(currencyList, (item) => {
      return item.name === name;
    });

    if (symbol) {
      setCurrencySymbol(symbol.value);
    }
  };

  return (
      <>
        <div className={index > 0 ? "repeat-sec" : ""}>
          <div className={"row"} key={index}>
            <div className={"col-md-6"}>
              <InputBox title={"Select Service"} type={"select"}>
                <Select2
                    title={"Select Service"}
                    label={"Select Service"}
                    options={planList}
                    value={data.planId}
                    keyAccessor={(x) => x._id}
                    valueAccessor={(x) => x.name}
                    onChange={(e) => {
                      let obj = {
                        planId: e,
                      };
                      let findName = _.find(planList, (item) => {
                        return item._id == obj.planId;
                      });
                      if (findName) {
                        obj.name = findName.name;
                        obj.amount = findName.amount;
                      }
                      events._updatePlan({...obj}, index);
                    }}
                />
              </InputBox>
            </div>

            <div className={"col-md-6"}>
              <InputBox title={"Price"}>
                <input
                    style={{paddingLeft: 20}}
                    className={"form-control"}
                    placeholder="Price"
                    value={data.amount}
                    readOnly
                />
              </InputBox>
            </div>
          </div>
          {index !== 0 ? (
              <button
                  className="btn delete"
                  onClick={() => events.deleteItem(data.planId)}
              >
                <i className="fa fa-times-circle"></i>
              </button>
          ) : null}
        </div>
      </>
  );
};

const AddPaymentDetails = (props) => {
  const navigate = useNavigate();

  let dispatch = useDispatch();
  let [planList, setPlanList] = useState([]);
  let [plans, setPlans] = useState([planInit]);
  let [state, setState] = useState(initState);
  let [studentData, setStudentData] = useState({});
  let [installments, setInstallments] = useState([]);
  let [visiblePlanDrawer, setVisiblePlanDrawer] = useState(false);
  let user = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null;

  const handleSubmit = async (e) => {
    // e.preventDefault();

    let obj = {
      ...state,
      installments,
      plans,
      leadId: studentData._id,
    };
    let totalAmountToBePaid = 0;

    _.each(installments, (installment) => {
      // Convert amount to a number if it's a string
      const amount =
          typeof installment.amount === "string"
              ? parseFloat(installment.amount)
              : installment.amount;

      totalAmountToBePaid += amount;
    });
    console.log(totalAmountToBePaid, obj.balance, "amountPaidamountPaid");
    if (obj.balance > totalAmountToBePaid) {
      notification.warning({
        message: "Installments amount is Less then Balance",
      });
      return;
    }
    if (!obj.totalAmount) {
      notification.warning({message: "Please choose services"});
      return;
    }
    if (obj.balance > 0 && !obj.noOfInstallments) {
      notification.warning({message: "Please choose installments."});
      return;
    }
    let findEmp = _.find(obj.installments, (item) => {
      return !(item.amount && item.dueDate);
    });
    if (findEmp) {
      notification.warning({
        message: "Please enter installment amount & Due Date",
      });
      return;
    }

    let {success} = await dispatch(addPurchasePlanFxn(obj));
    if (success) {
      setState((prevState) => {
        return {
          ...prevState,
          ...initState,
        };
      });
      setPlans([planInit]);
      setInstallments([]);
      setTimeout(() => {
        navigate(`/agency-fees-details/${obj.leadId}`);
      }, 500);
    }
  };

  useEffect(() => {
    planEvents.loadPlans();
    loadStudentData();
  }, []);

  const loadStudentData = async () => {
    let searchParams = new URLSearchParams(window.location.search);
    let id = searchParams.get("_id");
    if (id) {
      let {data, success} = await dispatch(getSingleLeadFxn(id));
      setStudentData(data);
    }
  };

  const events = {
    _updateState: (data) => {
      setState((prevState) => {
        return {
          ...prevState,
          ...data,
        };
      });
    },
    calculateNetAmount: () => {
      let {totalAmount} = state;
      let cloneState = {};
      cloneState.netAmount = 0;
      cloneState.serviceTaxAmount = 0;
      cloneState.gstAmount = 0;
      cloneState.noOfInstallments = 0;
      if (state.discount) {
        cloneState.discountAmount =
            (parseFloat(totalAmount) * parseFloat(state.discount)) / 100;
        cloneState.discountAmount = RoundOf(cloneState.discountAmount);
        cloneState.subTotal =
            parseFloat(totalAmount) - parseFloat(cloneState.discountAmount);
      } else {
        cloneState.subTotal = parseFloat(totalAmount);
      }
      cloneState.subTotal = RoundOf(cloneState.subTotal);

      if (state.isServiceTax) {
        cloneState.serviceTax = taxObj.serviceTax;
        cloneState.serviceTaxAmount =
            (parseFloat(cloneState.subTotal) *
                parseFloat(cloneState.serviceTax)) /
            100;
        cloneState.serviceTaxAmount = RoundOf(cloneState.serviceTaxAmount);

        // cloneState.netAmount = parseFloat(cloneState.subTotal) - parseFloat(cloneState.serviceTaxAmount)
      }
      if (state.isGst) {
        cloneState.gst = taxObj.gst;
        let amount =
            parseFloat(cloneState.subTotal) +
            parseFloat(cloneState.serviceTaxAmount);
        cloneState.gstAmount = (amount * parseFloat(cloneState.gst)) / 100;
        cloneState.gstAmount = RoundOf(cloneState.gstAmount);
      }

      cloneState.netAmount =
          parseFloat(cloneState.subTotal) +
          parseFloat(cloneState.serviceTaxAmount) +
          parseFloat(cloneState.gstAmount);
      if (state.paidAmount) {
        cloneState.balance =
            parseFloat(cloneState.netAmount) - parseFloat(state.paidAmount);
      } else {
        cloneState.balance = cloneState.netAmount;
      }
      cloneState.balance = RoundOf(cloneState.balance);

      events._updateState({...cloneState});
    },
    chooseInstallment: () => {
      let {noOfInstallments, balance} = state;
      let installments = [];
      if (noOfInstallments) {
        let amount = parseFloat(balance) / parseFloat(noOfInstallments);
        amount = RoundOf(amount);
        _.times(noOfInstallments, () => {
          installments.push({
            amount,
            dueDate: null,
          });
        });
      }
      setInstallments(installments);
    },
    _updateInstallment: (data, index) => {
      let clonePlan = _.clone(installments);
      clonePlan[index] = {...clonePlan[index], ...data};
      setInstallments(clonePlan);
    },
    showAddPlanDrawer: () => {
      setVisiblePlanDrawer(true);
    },
    hideAddPlanDrawer: () => {
      setVisiblePlanDrawer(false);
    },
  };

  useEffect(() => {
    planEvents.calculateAmount();
  }, [plans]);
  useEffect(() => {
    events.calculateNetAmount();
  }, [
    state.discount,
    state.isGst,
    state.isServiceTax,
    state.paidAmount,
    state.totalAmount,
  ]);

  useEffect(() => {
    events.chooseInstallment();
  }, [state.noOfInstallments]);

  const planEvents = {
    loadPlans: async () => {
      let {data} = await dispatch(
          PlansListFxn({
            results: 1000,
            count: 1000,
            regExFilters: ["name", "amount", "currency"],
          })
      );
      setPlanList(data.data);
    },
    addPlan: () => {
      let clonePlan = _.clone(plans);
      clonePlan.push(planInit);
      setPlans(clonePlan);
    },
    _updatePlan: (data, index) => {
      let clonePlan = _.clone(plans);
      clonePlan[index] = {...clonePlan[index], ...data};
      setPlans(clonePlan);
    },
    calculateAmount: () => {
      let totalAmount = 0;
      _.each(plans, (item) => {
        if (item && item.amount) {
          totalAmount = parseFloat(totalAmount) + parseFloat(item.amount);
        }
      });
      totalAmount = RoundOf(totalAmount);
      events._updateState({totalAmount});
    },
    deleteItem: (planId) => {
      let clonePlan = _.clone(plans);
      clonePlan = _.reject(clonePlan, (item) => {
        return item.planId == planId;
      });
      setPlans(clonePlan);
    },
  };

  return (
      <>
        <Card title={"Purchase Services"} shadow={false}>
          <div className="detail-box">
            <ul>
              <li>
                <span>Name:</span>
                {studentData.name}
              </li>
              <li>
                <span>Email:</span>
                {studentData.email}
              </li>
              <li>
                <span>Country:</span>
                {studentData.countryName}
              </li>
              <li></li>
            </ul>
          </div>
          <Card
              padding={false}
              shadow={false}
              extraBtn={[
                {
                  name: "Choose Multiple Plans",
                  action: planEvents.addPlan,
                },
                {
                  name: "Add Plans",
                  action: events.showAddPlanDrawer,
                },
              ]}
          >
            {plans.map((data, index) => {
              return (
                  <DropDownComponent
                      key={index}
                      index={index}
                      data={data}
                      planList={planList}
                      events={planEvents}
                  />
              );
            })}
          </Card>
        </Card>

        <Card shadow={false}>
          <div>
            <div className={"row"}>
              <div className={"col-md-12"}>
                <table className={"table payment_table"}>
                  <TableComponent title={"Total Amount"}>
                    {state.totalAmount}
                  </TableComponent>
                  <TableComponent title={"Discount"}>
                    <input
                        type="number"
                        className={"form-control"}
                        name="discount"
                        placeholder={"Discount"}
                        value={state.discount}
                        onChange={({target}) => {
                          events._updateState({discount: target.value});
                        }}
                    />
                    <span>{state.discount ? `(${state.discount}%)` : null}</span>
                  </TableComponent>
                  <TableComponent title={"Rest Amount"}>
                    {state.subTotal}
                  </TableComponent>
                  <TableComponent
                      title={
                        <label>
                          <input
                              style={{marginRight: "7px"}}
                              type="checkbox"
                              onChange={({target}) => {
                                events._updateState({isServiceTax: target.checked});
                              }}
                              checked={state.isServiceTax}
                          />
                          Show Service Tax
                        </label>
                      }
                  >
                    {state.isServiceTax
                        ? `${state.serviceTaxAmount} (${taxObj.serviceTax}%)`
                        : ""}
                  </TableComponent>
                  <TableComponent
                      title={
                        <label>
                          <input
                              style={{marginRight: "7px"}}
                              type="checkbox"
                              onChange={({target}) => {
                                events._updateState({isGst: target.checked});
                              }}
                              checked={state.isGst}
                          />
                          GST
                        </label>
                      }
                  >
                    {state.isGst ? `${state.gstAmount} (${taxObj.gst}%)` : ""}
                  </TableComponent>
                  <TableComponent title={"Grand Total"}>
                    {state.netAmount || 0}
                  </TableComponent>
                  <TableComponent title={"Pay Amount"}>
                    <input
                        type="text"
                        className={"form-control"}
                        value={state.paidAmount}
                        onChange={({target}) => {
                          events._updateState({paidAmount: target.value});
                        }}
                    />
                  </TableComponent>

                  <TableComponent title={"Rest of Amount"}>
                    {state.balance}
                  </TableComponent>
                  <TableComponent title={"Select Installment"}>
                    <select
                        className={"form-control"}
                        disabled={!state.balance}
                        value={state.noOfInstallments}
                        onChange={({target}) => {
                          events._updateState({noOfInstallments: target.value});
                        }}
                    >
                      <option value="">Select an Installment</option>
                      {InstallmentOptions.map((option) => (
                          <option key={option.option} value={option.option}>
                            {option.option}
                          </option>
                      ))}
                    </select>
                  </TableComponent>
                </table>
              </div>
            </div>

            {installments && installments.length ? (
                <table className="table table-bordered installment_table">
                  <tbody>
                  {installments.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <InputBox title={`Installment ${index + 1}`}>
                            <input
                                type="number"
                                style={{paddingLeft: 20}}
                                className={"form-control"}
                                placeholder={`Installment ${index + 1}`}
                                value={item.amount}
                                onChange={({target}) =>
                                    events._updateInstallment(
                                        {amount: target.value},
                                        index
                                    )
                                }
                            />
                          </InputBox>
                        </td>
                        <td>
                          <InputBox title={`Due Date ${index + 1}`}>
                            <DatePicker
                                type="date"
                                value={item.dueDate}
                                onChange={(value) =>
                                    events._updateInstallment({dueDate: value}, index)
                                }
                            />
                          </InputBox>
                        </td>
                      </tr>
                  ))}
                  </tbody>
                </table>
            ) : null}
          </div>
          <div className={"mt-4"} style={{textAlign: "center"}}>
            <Button type={"primary"} onClick={handleSubmit}>
              Submit
            </Button>
          </div>
        </Card>

        {visiblePlanDrawer ? (
            <AddPlanDrawer
                visible={visiblePlanDrawer}
                onClose={events.hideAddPlanDrawer}
                onSubmit={() => {
                  events.hideAddPlanDrawer();
                  planEvents.loadPlans();
                }}
            />
        ) : null}
      </>
  );
};

export default Form.create()(AddPaymentDetails);
