import React, {useRef} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {
    Card, DefaultTablePagination,
    HyperLink,
    Popconfirm,
    Table, TableButton,
} from "../../../components/Elements/appUtils";
import {
    universityList,
    updateUniversityActiveStatusFxn,
} from "../../University/action";
import _ from "lodash";

let {TableCard} = Card;
const UniversityList = (props) => {
    let {currentUser} = props;
    let navigate = useNavigate();
    let tableRef = useRef();
    const dispatch = useDispatch();
    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            let {data} = await universityList({
                ...params,
                regExFilters: ["universityName"],
            });
            resolve(data);
        });
    };

    const updateActiveStatus = async (universityId) => {
        let resp = await dispatch(
            updateUniversityActiveStatusFxn({universityId})
        );
        if (resp) {
            tableRef.current.reload();
        }
    };
    let user = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : null;
    const columns = [
        {
            title: "Sr. No.",
            dataIndex: "index",
            key: "index",
            width: 50,
            render: (val, record, index) => {
                return index + 1;
            },
        },
        {
            title: "Country",
            dataIndex: "universityCountry",
            key: "universityCountry",
            width: 160,
            render: (item, record) => {
                return item.countryName;
            },
        },
        {
            title: "University State",
            dataIndex: "universityState",
            key: "universityState",
            width: 160,
            render: (item, record) => {
                return item.stateName;
            },
        },
        {
            title: "University City",
            dataIndex: "universityCity",
            key: "universityCity",
            width: 160,
            render: (item, record) => {
                return item.cityName;
            },
        },
        {
            title: "University Name",
            dataIndex: "universityName",
            key: "universityName",
            width: 160,
            searchTextName: "universityName",
            filterRegex: true,
        },
        {
            title: "University Website",
            dataIndex: "universityWebsite",
            key: "universityWebsite",
            width: 160,
            searchTextName: "universityWebsite",
            filterRegex: true,
        },
        {
            title: "Address",
            dataIndex: "contactAddress",
            key: "contactAddress",
            width: 130,
        },

        {
            title: "Interview",
            dataIndex: "interview",
            key: "interview",
            width: 130,
        },
        {
            title: "Active",
            dataIndex: "active",
            key: "active",
            width: 230,
            hidden: user.userType !== "admin",
            render: (item, record) => {
                return (
                    <>
                        <Popconfirm
                            title={"Are your sure, you want to update status?"}
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => {
                                updateActiveStatus(record._id);
                            }}>
                            {item ? <span className="active">Active</span> : <span className="inactive">Inactive</span>}
                        </Popconfirm>
                    </>
                );
            },
        },
        {
            title: "Action",
            dataIndex: "action",
            width: 130,
            hidden: user.userType !== "admin",
            render: (val, record) => {
                return (
                    <>
                        <TableButton type={'edit'} path={`/master/university-edit?_id=${record._id}`}/>
                    </>
                );
            },
        },
    ];

    let loadColumn = () => {
        let columnList = []
        _.each(columns, (item) => {
            if (!item.hidden) {
                columnList.push(item)
            }
        })
        return columnList
    }


    return (
        <>
            <TableCard
                title={"University List"}
                /*extraBtn={[{
                    name: "Add University",
                    action: () => {
                        navigate("/add-University");
                    }
                }]}*/

            >
                <Table
                    apiRequest={apiRequest}
                    columns={loadColumn()}
                    pagination={DefaultTablePagination()}

                    extraProps={{scroll: {x: 2000}}}
                    ref={tableRef}/>
            </TableCard>
        </>
    );
};

export default UniversityList;
