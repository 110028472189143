import _ from "lodash";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {
    Button,
    Card,
    Form,
    InputBox,
    notification,
} from "../../../components/Elements/appUtils";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import {AddUniversityFxn, GetRelatedCities} from "../../University/action";
import CampusComponent from "../../University/components/campusComponent";
import ContactDetails from "../../University/components/contactComponent";
import {getRelatedStateListFxn} from "../city/action";
import {loadCountryList} from "../state/action";

const EligibilityCriteria = (props) => {
    let {data, index, updateState, deleteItem} = props;
    return (
        <>
            <div name="status" key={index} className={"col-md-6"}>
                <InputBox title={"Name"} className={"rowFlex"}>
                    <input
                        required={true}
                        placeholder="Please enter Name"
                        className={"form-control"}
                        onChange={({target}) => {
                            console.log(target.value);
                            updateState({name: target.value}, index);
                        }}
                        value={data.name}
                    />
                </InputBox>
            </div>

            <div className={"col-md-5"}>
                <InputBox title={`Value`} className={"rowFlex"}>
                    <input
                        required={true}
                        placeholder="Please enter value"
                        className={"form-control"}
                        onChange={({target}) => {
                            console.log(target.value);
              updateState({ value: target.value }, index);
            }}
            value={data.value}
          />
        </InputBox>
      </div>

      <div className="col-md-1">
        {index !== 0 ? (
          <a>
            <i
              className="fa fa-trash new-icon mt-5"
              aria-hidden="true"
              onClick={() => deleteItem(index)}
            ></i>
          </a>
        ) : null}
      </div>
    </>
  );
};
const cirteriaInt = { name: "", value: "" };
const initialState = {
    universityCountry: "",
    universityState: "",
    universityCity: "",
    universityName: "",
    universityWebsite: "",
    contactAddress: "",
    contactName: "",
    contactPhone: "",
    contactEmail: "",
    smallLogo: {
        //   path: "",
        //   fileName: "",
    },
    logo: {},
    interview: "",
};
const stateInIt = {
    contactPerson: "",
    email: "",
    mobile: "",
    designation: "",
};
const campusInIt = {
    campus: "",

    // , index: null
};
const AddUniversity = (props) => {
    const {getFieldDecorator} = props.form;
    let [formState, setState] = useState(initialState);
    const [countryList, setCountryList] = useState([]);
    const [smalllogo, setsmallLogo] = useState("");
    const [logo, setLogo] = useState("");
    const [applicationForm, setApplicationForm] = useState("");
    const [refundForm, setRefundForm] = useState("");
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [countryId, setCountryData] = useState("");
    const [criteria, setCriteria] = useState([cirteriaInt]);
    let dispatch = useDispatch();
    const [campus, setCampus] = useState([campusInIt]);
    const [contactDetails, setContactDetails] = useState([stateInIt]);
    const handleFileChange = (e) => {
        const {name, files} = e.target;
        if (name == "logo") {
            setLogo(files[0]);
        } else if (name == "refundForm") {
            setRefundForm(files[0]);
        } else {
            setApplicationForm(files[0]);
        }
    };

    const handleLogoChange = (e) => {
        const file = e.target.files[0];
        setsmallLogo(file);
    };
    const handleSubmit = (e) => {
        const {form} = props;
        e.preventDefault();
        form.validateFields(async (err, valData) => {
            if (!err) {
                let emptyContact = _.find(contactDetails, (item) => {
                    return !(item.contactPerson && item.designation);
                });

                if (emptyContact) {
                    notification.warning({
                        message: "Enter Fill All Contact-Details",
                    });
                    return;
                }
                let emptyCampus = _.find(campus, (item) => {
                    return !item.campus;
                });
                if (emptyCampus) {
                    notification.warning({
                        message: "Enter Campus",
                    });
                    return;
                }
                valData.campusList = campus;

                valData.contactDetails = contactDetails;
                let fd = new FormData();
                fd.append("obj", JSON.stringify(valData));
                if (logo && logo.name) {
                    fd.append("logo", logo);
                }
                if (smalllogo && smalllogo.name) {
                    fd.append("smallLogo", smalllogo);
                }
                if (refundForm && refundForm.name) {
                    fd.append("refundForm", refundForm);
                }
                if (applicationForm && applicationForm.name) {
                    fd.append("applicationForm", applicationForm);
                }

                let x = await dispatch(AddUniversityFxn(fd));
                console.log(x, "neww");
                props.form.setFieldsValue({
                    universityCountry: "",
                    universityState: "",
                    universityCity: "",
                    eligibilityCriteria: "",
                    universityName: "",
                    universityWebsite: "",
                    contactAddress: "",
                    contactName: "",
                    contactPhone: "",
                    contactEmail: "",
                    smallLogo: "",
                    logo: "",
                    interview: "",
                    addNote: "",
                });
                setCampus([campusInIt]);
                setContactDetails([stateInIt]);
                setCriteria([cirteriaInt]);
            } else {
                notification.warning({
                    message: `Please Enter All Required Fields`,
                });
                return;
            }
    });
  };

  const events = {
      addMore: () => {
          setCriteria([...criteria, cirteriaInt]);
      },
      deleteItem: (itemId) => {
          const updatedItems = criteria.filter((item, key) => key !== itemId);
          setCriteria(updatedItems);
      },
      updateState: (data, index) => {
          let cloneStatus = _.clone(criteria);
          cloneStatus[index] = {...cloneStatus[index], ...data};
          setCriteria(cloneStatus);
      },
      addMoreContact: () => {
          setContactDetails([...contactDetails, stateInIt]);
      },
      deleteItemContact: (itemId) => {
          const updatedItems = contactDetails.filter((item, key) => key !== itemId);
          setContactDetails(updatedItems);
      },
      updateStateContact: (data, index) => {
          let cloneStatus = _.clone(contactDetails);
          cloneStatus[index] = {...cloneStatus[index], ...data};
          setContactDetails(cloneStatus);
      },
      addMoreCampus: () => {
          setCampus([...campus, campusInIt]);
      },
      deleteItemCampus: (itemId) => {
          const updatedItems = campus.filter((item, key) => key !== itemId);
          setCampus(updatedItems);
      },
      updateStateCampus: (data, index) => {
          let cloneCampus = _.clone(campus);
          cloneCampus[index] = {...cloneCampus[index], ...data};
          setCampus(cloneCampus);
      },
  };

  useEffect(() => {
    loadCountryFxn();
  }, []);

  const loadCountryFxn = async () => {
      let x = await loadCountryList();
      console.log(x, "xxxxxxxxxxxxxxx");
      setCountryList(x);
  };

  const getRelatedStates = async (id) => {
    if (id) {
      setCountryData(id);
      let { data } = await getRelatedStateListFxn(id);
        console.log(data, "ddddddddddddddddddd");
        setStateList(data);
    }
  };

  const getRelatedCity = async (stateId) => {
    let data = await GetRelatedCities({ stateId, countryId });
    setCityList(data.data);
  };

  let inputTypes = {
    fields: [
        {
            key: "universityCountry",
            label: "University ",
            required: true,
            placeholder: "Country Name",
            type: "select",
            options: countryList,
            keyAccessor: (x) => x._id,
            valueAccessor: (x) => `${x.countryName}`,
            onChange: (x) => {
                props.form.setFieldsValue({
                    universityCountry: x,
                    universityState: undefined,
            universityCity: undefined,
          });
          getRelatedStates(x);
        },
      },

      {
        key: "universityState",
        label: "University State",
        required: true,
        placeholder: "State Name",
        type: "select",
        options: stateList,
        keyAccessor: (x) => x._id,
        valueAccessor: (x) => `${x.stateName}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            universityState: x,
            universityCity: undefined,
          });
          getRelatedCity(x);
        },
        span: 8,
      },

      {
        key: "universityCity",
        label: "University City",
        required: true,
        placeholder: "City Name",
        type: "select",
        options: cityList,
        keyAccessor: (x) => x._id,
        valueAccessor: (x) => `${x.cityName}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            universityCity: x,
          });
        },
        span: 8,
      },
      {
        key: "universityName",
        label: "University Name",
        required: true,
        placeholder: "University  Name",
        type: "text",
      },
      {
        key: "universityWebsite",
        label: "University Website",
        required: true,
        placeholder: "Website",
        type: "text",
      },
      {
        key: "contactAddress",
        label: "Address",
        required: true,
        placeholder: "Address",
        type: "text",
      },
      {
        key: "contactName",
        label: "Contact Name",
        required: true,
        placeholder: "Contact-Name",
        type: "text",
      },
      {
        key: "contactPhone",
        label: "Contact Phone",
        required: true,
        placeholder: "Contact-Phone",
        type: "text",
      },
      {
        key: "contactEmail",
        label: "Contact Email",
        required: true,
        placeholder: "Contact-Email",
        type: "text",
      },
      {
        key: "interview",
        label: " Interview",
        required: true,
        placeholder: "interview",
        type: "select",
        options: [{ name: "Yes" }, { name: "No" }, { name: "Maybe" }],
        keyAccessor: (x) => x.name,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            interview: x,
          });
        },
        span: 8,
      },
      {
        key: "addNote",
        label: "Add Note",
        required: true,
        placeholder: "Add Note",
        type: "textarea",
        span: "col-md-12",
      },

      {
        key: "smallLogo",
        label: " Small Logo",
        type: "file",
        onChange: handleLogoChange,
      },
      {
        key: "logo",
        label: " Logo",
        placeholder: "logo",
        type: "file",
        onChange: handleFileChange,
      },
      {
        key: "applicationForm",
        label: "Application Form",
        placeholder: "Application Form",
        type: "file",
        onChange: handleFileChange,
      },
      {
        key: "refundForm",
        label: "Refund Form",
        placeholder: "Refund Form",
        type: "file",
        onChange: handleFileChange,
      },
    ],
  };
  return (
      <>
          <Form onSubmit={handleSubmit}>
              <Card title={"Add University"}>
                  <div className={"row"}>
                      {inputTypes.fields.map((item, key) => {
                          return (
                              <div
                                  className={`pt-3 ${
                                      item.span == "col-md-12" ? "col-md-12" : "col-md-6"
                                  }`}
                                  key={key}
                              >
                                  <GetEachFormFields {...props.form} item={item}/>
                              </div>
                          );
                      })}
                  </div>
                  <div className={"row "}>
                      {criteria.map((data, index) => {
                          return (
                              <EligibilityCriteria
                                  data={data}
                                  key={index}
                                  index={index}
                                  criteria={criteria}
                                  updateState={events.updateState}
                                  deleteItem={events.deleteItem}
                              />
                          );
                      })}
                  </div>
                  <a className={"btn btn-link floatRight"} onClick={events.addMore}>
                      Add More
                  </a>
              </Card>
              <Card title={"Add Multiple Campuses"}>
                  <div className={"row"}>
                      {campus.map((data, index) => {
                          return (
                              <CampusComponent
                                  data={data}
                                  key={index}
                                  index={index}
                                  campus={campus}
                                  updateState={events.updateStateCampus}
                                  deleteItem={events.deleteItemCampus}
                              />
                          );
                      })}
                  </div>
                  <a
                      className={"btn btn-link floatRight"}
                      onClick={events.addMoreCampus}
                  >
                      Add More Campus
                  </a>
              </Card>
              <Card title={"Contact Details"}>
                  <div className={"row"}>
                      {contactDetails.map((data, index) => {
                          return (
                              <ContactDetails
                                  data={data}
                                  key={index}
                                  index={index}
                                  contactDetails={contactDetails}
                                  updateState={events.updateStateContact}
                                  deleteItem={events.deleteItemContact}
                              />
                          );
                      })}
                  </div>
                  <a
                      className={"btn btn-link floatRight"}
                      onClick={events.addMoreContact}
                  >
                      Add More
                  </a>
              </Card>
              <div style={{marginTop: "20px"}}>
                  <Button type={"primary"} htmlType="submit">
                      submit
                  </Button>
              </div>
          </Form>
      </>
  );
};

export default Form.create()(AddUniversity);
