import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Card,
  Form,
  notification,
} from "../../../components/Elements/appUtils";
import { GetEachFormFields } from "../../../components/_utils/formUtils";
import { getRelatedUniversityListFxn } from "../../Course/action";
import { getRelatedStateListFxn } from "../../Masters/city/action";
import { loadCountryList } from "../../Masters/state/action";
import {
    AddRepresentingUniversityFxn,
    AddUniversityFxn,
    companyCountryList,
    partnerListFxn,
} from "../action";
import CampusComponent from "../components/campusComponent";
import ContactDetails from "../components/contactComponent";

const stateInIt = {
  contactPerson: "",
  email: "",
  mobile: "",
  designation: "",
};
const campusInIt = {
  campus: "",

  // , index: null
};
const AddUniversity = (props) => {
  const { getFieldDecorator } = props.form;
    const [countryList, setCountryList] = useState([]);
    const [partnerList, setPartnerList] = useState([]);
    const [instituteLogo, seInstituteLogo] = useState("");
    const [masterCountryId, setMasterCountryId] = useState("");
    const [universityList, setUniversityList] = useState([]);
    const [contractCopy, setContractCopy] = useState("");
    const [universityLogo, setUniversityLogo] = useState("");
    const [universityIcon, setUniversityIcon] = useState("");
    const [universityName, setUniversityName] = useState("");
    const [currencySymbol, setCurrencySymbol] = useState("");
    const [campus, setCampus] = useState([campusInIt]);
    const [contactDetails, setContactDetails] = useState([stateInIt]);
    let dispatch = useDispatch();

    const handleFileChange = (e) => {
        const {name, files} = e.target;
        if (name == "contractCopy") {
            setContractCopy(files[0]);
        } else if (name == "universityIcon") {
            setUniversityIcon(files[0]);
        } else if (name == "universityLogo") {
            setUniversityLogo(files[0]);
        } else {
      seInstituteLogo(files[0]);
    }
  };
  const getRelatedUniversities = async (id) => {
    let country = _.find(countryList, (item) => {
      return item._id === id;
    });
    if (country) {
      let { masterCountryId } = country;
      setCurrencySymbol(masterCountryId.currency);
      setMasterCountryId(country.masterCountryId._id);
      let { data } = await getRelatedUniversityListFxn(
        country.masterCountryId._id
      );
      setUniversityList(() => [...data, { universityName: "Other", _id: 1 }]);
      // props.form.setFieldsValue({
      //   countryId: "",})
    }
  };

  const handleSubmit = (e) => {
    const { form } = props;
    e.preventDefault();
    form.validateFields(async (err, valData) => {
      if (!err) {
        let fd = new FormData();
        let emptyContact = _.find(contactDetails, (item) => {
          return !(item.contactPerson && item.designation);
        });

        if (emptyContact) {
          notification.warning({
            message: "Enter Fill All Contact-Details",
          });
          return;
        }
        let emptyCampus = _.find(campus, (item) => {
          return !item.campus;
        });
        if (emptyCampus) {
          notification.warning({
            message: "Enter Campus",
          });
          return;
        }
        valData.campusList = campus;
        if (universityName) {
          valData.universityName = universityName;
          valData.masterCountryId = masterCountryId;
        }

        valData.contactDetails = contactDetails;
        fd.append("obj", JSON.stringify(valData));
        if (instituteLogo && instituteLogo.name) {
          fd.append("instituteLogo", instituteLogo);
        }
        if (contractCopy && contractCopy.name) {
          fd.append("contractCopy", contractCopy);
        }
        if (universityIcon && universityIcon.name) {
          fd.append("universityIcon", universityIcon);
        }
        if (universityLogo && universityLogo.name) {
          fd.append("universityLogo", universityLogo);
        }

          let x = await dispatch(AddUniversityFxn(fd));
        if (!x.error) {
          props.form.setFieldsValue({
            countryId: "",
            masterUniversityId: "",
            universityWebsite: "",
            applicationFee: "",
            fundsRequirementForVisa: "",
            contractTerms: "",
            contractExpiryDate: "",
            instituteType: "",
            fundsRequirtment: "",
            // campus: "",
            languageRequirements: "",
            institutionalBenefits: "",
            partTimeWorkDetails: "",
            scholarshipPolicy: "",
            instituteLogo: "",
          });
          setContactDetails([stateInIt]);
          setCampus([campusInIt]);
        }
      } else {
        if (err.countryId) {
          notification.warning({
            message: "Please Choose Country",
          });
          return;
        }

        if (err.masterUniversityId) {
          notification.warning({
            message: "Please Choose University",
          });
          return;
        }

        if (err.instituteType) {
          notification.warning({
            message: "Please Choose Institute Type",
          });
          return;
        }

        if (err.applicationFee) {
          notification.warning({
            message: "Please Enter Application Fee",
          });
          return;
        }
      }
    });
  };

  useEffect(() => {
    loadCountryFxn();
    loadPartnerList();
  }, []);

  const loadCountryFxn = async () => {
    let x = await companyCountryList();
    setCountryList(x.data);
  };
  const loadPartnerList = async () => {
    let x = await partnerListFxn();
    setPartnerList(x.data);
  };

  const events = {
    chooseUniversity: (_id) => {
      if (_id !== 1) {
        let universityName = _.find(universityList, (item) => {
          return item._id == _id;
        });
        if (universityName) {
          setUniversityName(
            universityName.universityName ? universityName.universityName : ""
          );
        }
      } else {
        setUniversityName("");
        // props.form.setFieldsValue({
        //   masterUniversityId: "",
        // });
      }
    },
    addMore: () => {
      setContactDetails([...contactDetails, stateInIt]);
    },
    deleteItem: (itemId) => {
      const updatedItems = contactDetails.filter((item, key) => key !== itemId);
      setContactDetails(updatedItems);
    },
    updateState: (data, index) => {
      let cloneStatus = _.clone(contactDetails);
      cloneStatus[index] = { ...cloneStatus[index], ...data };
      setContactDetails(cloneStatus);
    },
    addMoreCampus: () => {
      setCampus([...campus, campusInIt]);
    },
    deleteItemCampus: (itemId) => {
      const updatedItems = campus.filter((item, key) => key !== itemId);
      setCampus(updatedItems);
    },
    updateStateCampus: (data, index) => {
      let cloneCampus = _.clone(campus);
      cloneCampus[index] = { ...cloneCampus[index], ...data };
      setCampus(cloneCampus);
    },
  };

  let inputTypes = {
    fields: [
      {
        key: "countryId",
        label: "University Country",
        required: true,
        placeholder: "University Country ",
        type: "select",
        options: countryList,
        keyAccessor: (x) => x._id,
        valueAccessor: (x) => `${x.masterCountryId.countryName}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            countryId: x,
            masterUniversityId: undefined,
          });
          getRelatedUniversities(x);
        },
      },
      {
        key: "masterUniversityId",
        label: "University",
        required: true,
        placeholder: "University",
        type: "select",
        options: universityList,
        keyAccessor: (x) => x._id,
        valueAccessor: (x) => `${x.universityName}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            masterUniversityId: x,
          });
          events.chooseUniversity(x);
        },
      },

      {
        key: "instituteType",
        label: " Institute Type",
        required: true,
        placeholder: "Institute Type",
        type: "select",
        options: [{ name: "Direct" }, { name: "Indirect" }],
        keyAccessor: (x) => x.name,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            instituteType: x,
          });
        },
      },
      {
        key: "universityName",
        label: "University Name",
        hidden: !(props.form.getFieldValue("masterUniversityId") === 1),

        placeholder: "University Name",
        type: "text",
      },

      {
        key: "universityLogo",
        label: "University Logo",
        type: "file",
        hidden: !(props.form.getFieldValue("masterUniversityId") === 1),

        onChange: handleFileChange,
      },
      {
        key: "universityIcon",
        label: "University Icon",
        type: "file",
        hidden: !(props.form.getFieldValue("masterUniversityId") === 1),

        onChange: handleFileChange,
      },
      {
        key: "partners",
        label: "Partners",
        required: true,
        placeholder: "Select Partners",
        multiple: true,
        hidden: !(props.form.getFieldValue("instituteType") === "Indirect"),
        type: "select",
        showSearch: true,
        options: partnerList,
        keyAccessor: (x) => x._id,
        valueAccessor: (x) => `${x.partnerName}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            partners: x,
          });
        },
      },
      // {
      //   key: "campus",
      //   label: "Campus (Enter Multiple Campuses Separated by ,)",
      //   placeholder: "Enter Multiple Campuses Separated by , ",
      //   type: "text",
      // },
      {
        key: "universityWebsite",
        label: "University Website",
        placeholder: "Website",
        type: "text",
      },
      {
        key: "applicationFee",
        label: "Application Fee",
        required: true,
        placeholder: "Application Fee",
        type: "number",
        customText: currencySymbol,
      },
      {
        key: "fundsRequirtment",
        label: "Funds Requirtment",
        placeholder: "Funds Requirtment",
        type: "number",
      },
      {
        key: "fundsRequirementForVisa",
        label: "Funds Requirement for Visa",
        placeholder: "Funds Requirement for Visa",
        type: "number",
      },
      {
        key: "contractTerms",
        label: "Contract Terms",
        placeholder: "Contract Terms",
        type: "text",
      },

      {
        key: "contractExpiryDate",
        label: "Contract Expiry Date",
        placeholder: "Contract Expiry Date",
        type: "date",
        keyAccessor: (x) => x.option,
        valueAccessor: (x) => `${x.option}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            contractExpiryDate: x,
          });
        },
      },

      {
        key: "institutionalBenefits",
        label: "Institutional Benefits",
        placeholder: "Institutional Benefits",
        type: "textarea",
        span: "col-md-12",
      },

      {
        key: "partTimeWorkDetails",
        label: "Part-Time Work Details",

        placeholder: "Part-Time Work Details",
        type: "textarea",
        span: "col-md-12",
      },
      {
        key: "scholarshipPolicy",
        label: "Scholarship Policy",

        placeholder: "Scholarship Policy",
        type: "textarea",
        span: "col-md-12",
      },
      {
        key: "instituteLogo",
        label: "Institute Logo",
        type: "file",
        onChange: handleFileChange,
      },
      {
        key: "contractCopy",
        label: "Contract Copy",
        type: "file",
        onChange: handleFileChange,
      },
    ],
  };
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Card title={"Add Representing University"}>
          <div className={"row"}>
            {inputTypes.fields.map((item, key) => {
              return !item.hidden ? (
                <div
                  className={`pt-3 ${item.span ? item.span : "col-md-6"}`}
                  key={key}
                >
                  <GetEachFormFields {...props.form} item={item} />
                </div>
              ) : null;
            })}
          </div>
        </Card>
        <Card title={"Add Multiple Campuses"}>
          <div className={"row"}>
            {campus.map((data, index) => {
              return (
                <CampusComponent
                  data={data}
                  key={index}
                  index={index}
                  campus={campus}
                  updateState={events.updateStateCampus}
                  deleteItem={events.deleteItemCampus}
                />
              );
            })}
          </div>
          <a
            className={"btn btn-link floatRight"}
            onClick={events.addMoreCampus}
          >
            Add More Campus
          </a>
        </Card>
        <Card title={"Contact Details"}>
          <div className={"row"}>
            {contactDetails.map((data, index) => {
              return (
                <ContactDetails
                  data={data}
                  key={index}
                  index={index}
                  contactDetails={contactDetails}
                  updateState={events.updateState}
                  deleteItem={events.deleteItem}
                />
              );
            })}
          </div>
          <a className={"btn btn-link floatRight"} onClick={events.addMore}>
            Add More
          </a>
        </Card>
        <div style={{ marginTop: "20px" }}>
          <Button type={"primary"} htmlType="submit">
            submit
          </Button>
        </div>
      </Form>
    </>
  );
};

export default Form.create()(AddUniversity);
