import { hideLoader, showLoader } from "../../actions/loader";
import notification from "../../components/Elements/Notification";
import {customAxios as axios, getToken} from "../../request";
import {AddPlanUrl, PlansListUrl, EditPlanUrl, DeletePlanUrl, getPlanUrl} from "./api";

export const AddPlanFxn =(valData)=> async (dispatch) => {
    dispatch(showLoader())

    let {data} = await axios.post(AddPlanUrl(), valData, getToken());

   dispatch(hideLoader())
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};

export const EditPlanFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(EditPlanUrl(), valData, getToken());
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};

export const PlansListFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let config = {
        params: {...valData},
        ...await getToken()
    }
    let data = await axios.get(PlansListUrl(), config)
    dispatch(hideLoader())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }

    return data;
};

export const DeletePlanFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(DeletePlanUrl(), valData, getToken());
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};

export const getSinglePlanFxn = (id) => async (dispatch) => {
    dispatch(showLoader());
    let config = {
        ...(await getToken()),
    };
    let {data} = await axios.get(getPlanUrl(id), config);
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    }

    return data;
};
