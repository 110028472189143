import moment from "moment";
import momentTimezone from "moment-timezone";

export {default as Avatar} from "./Avatar";
export {default as Button} from "./Button";
export {default as TableButton} from "./Button/TableButton";
export {default as Card} from "./Card";
export {default as Checkbox} from "./CheckBox";
export {default as DatePicker} from "./DatePicker";
export {default as Drawer} from "./Drawer";
export {default as DropDown} from "./DropDown";
export {default as Form} from "./Form";
export {default as HyperLink} from "./HyperLink";
export {default as Icon} from "./Icon";
export {default as Modal} from "./Modal";
export {default as notification} from "./Notification";
export {default as Pagination} from "./Pagination";
export {default as Popconfirm} from "./Popconfirm";
export {SelectRc as Select, SelectComponent2 as Select2} from "./Select";
export {default as Table} from "./Table";
export {default as Tabs} from "./Tabs";
export {default as TimePicker} from "./TimePicker";
export {default as Tooltip} from "./Tooltip";
export {default as CkEditor} from "./ckEditorComponent";
export {titleComponent as Title} from "./component";
export {default as InputBox} from "./inputComponent";
export {default as StyleEditor} from "./styleEditorComponent";

export const displayDate = (date) => {
  if (date) {
    // return moment(date).format("DD MMMM YYYY");
    return moment(date).format("DD-MM-YYYY");
  } else {
    return null;
  }
};
export const appDisplayDate = (date) => {
  if (date) {
    return moment(date).format("DD/MM/YYYY");
  } else {
    return null;
  }
};
export const displayTime = (date) => {
  if (date) {
    return moment(date).format("hh:mm a");
  } else {
    return null;
  }
};
export const longDisplayDate = (date) => {
  if (date) {
    return moment(date).format("DD-MM-YYYY | h:mm A");
  } else {
    return null;
  }
};

export const DefaultTablePagination = (
    newParams,
    defaultPageSize = 20,
    pageSizeOptions = ["20", "50", "75", "100"]
) => {
  let params = {
    defaultPageSize,
    pageSizeOptions,
    ...newParams,
    position: "top",
  };
  return params;
};

export const MonthList = [
  {name: "January"},
  {name: "February"},
  {name: "March"},
  {name: "April"},
  {name: "May"},
  {name: "June"},
  {name: "July"},
  {name: "August"},
  {name: "September"},
  {name: "October"},
  {name: "November"},
  {name: "December"},
];

export const momentDateZone = (date, timeZone) => {
  let formattedDate = momentTimezone(date);
  return formattedDate.tz(timeZone);
};

export const getStatusColor = (status) => {
  let colorObj = [
    "#337ab7",
    "#5cb85c",
    "#5bc0de",
    "#f0ad4e",
    "#d9534f",
    "#0000FF",
    "#FF1493",
    "#F4A460",
    "#708090",
    "#808080",
    "#5F9EA0",
    "#1890ff",
    "#0872BC",
  ];
  return (
      <span className={"statusLabel"} style={{borderColor: "#4B49AC"}}>
      {status}
    </span>
  );
};

export const DocumentTypes = [
  "Passport",
  "IELTS",
  "10th",
  "12th",
  "Bachelor DMC",
  "Backlog",
  "Degree",
  "Experience",
  "Medium of Instruction",
  "Letter of Recommendation",
  "CV",
  "Diploma",
  "Study Permit",
  "Other",
];

export const englishTestList = ["IELTS", "PTE", "TOEFL", "DUOLINGO"];

export const documentArray = [
  {
    name: "Identity Proof",
    documents: ["Passport", "Pan Card", "Aadhaar"],
  },
  {
    name: "Academic Qualification",
    documents: [
      "10th",
      "12th",
      "BackLog",
      "BachelorDmc",
      "Diploma",
      "Degree",
      "PostGraduate",
    ],
  },
  {
    name: "Test Score",
    documents: englishTestList,
  },
  {
    name: "Special",
    documents: ["SOP", "LOR", "Medium Of Instruction", "Study Permit"],
  },
  {
    name: "Other",
    documents: ["Other"],
  },
  {
    name: "Financial",
    documents: ["Bank Account Statement"],
  },
  {
    name: "WorkExperience",
    documents: ["WorkExperience", "SalarySlip", "Internship", "OfferLetter"],
  },
];

export const Intakes = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const countryIds = {
  canada: "5dea0a52e304ca03881dd208",
  uk: "5dea0a52e304ca03881dd22e",
  australia: "601e424bd7e547555f7925c1",
  usa: "612c8bc76efc5360647230ba",
};
