import {getToken, customAxios as axios} from '../../../request'
import {hideLoader, showLoader} from "../../../actions/loader";
import {
    campusIntakesListUrl
} from '../apis'

export const campusIntakesListFxn = async (filters) => {
    let config = {
        params: {...filters},
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
    }
    let {data} = await axios.get(campusIntakesListUrl(), config)
    return data.data
}
