import React, {useEffect, useState} from "react"
import {applicationNoteFxn} from "../actions";
import {useDispatch} from "react-redux";
import {displayDate, Drawer, Tooltip} from "../../../components/Elements/appUtils";
import moment from "moment";

const SingleNote = (props) => {
    let {data, index, events} = props;
    return (
        <>
            <div className="box" key={data._id}>
                <div className="head d-flex align-items-center">
                    <h5>{moment().month(data._id.month - 1).format('MMMM')}, {data._id.year}</h5>
                    {/*  {index == 0 ?
                        <button className="btn" type="button" >+ create
                            note</button> : null}*/}

                </div>

                {data && data.items && data.items.length ? data.items.map((item, key) => {
                    return (
                        <div className="inner card" key={key}>
                            <img src="../images/icons/editing.png" alt="" className="edit"/>
                            <div className={'noteTitle'}>
                                <h5>note</h5>
                                <div style={{textAlign: 'right'}}>
                                                                <span className={'date'}>
                                                                  {displayDate(item.createdAt)}<br/>
                                                                  </span>

                                </div>
                            </div>
                            <p>
                                {item.assignedUserId && item.assignedUserId.name ?
                                    <strong>{item.assignedUserId.name} : </strong>
                                    : null}
                                <div className={'display-linebreak'}>
                                    {item.note}
                                </div>
                            </p>
                            <div className={'paddingHz'}>
                                {item.attachment && item.attachment.url ?
                                    <Tooltip title={item.attachment.name}>
                                        <a download={item.attachment.name}
                                           href={item.attachment.url}>
                                            <label>{item.attachment.name}</label>
                                        </a>
                                    </Tooltip>
                                    : null}
                            </div>
                            <div className={'paddingHz'}>

                            </div>
                            <span><img src='../images/icons/user.png'
                                       alt=""/> {item.createdByUser.name} left a note.</span>

                            <div>
                                {item.comments && item.comments.length ? item.comments.map((eacCom, key1) => {
                                    return (
                                        <React.Fragment key={key}>
                                            <div className={'commentBox'}>
                                                <div className={'row'}>
                                                    <div className={'col-md-2'}>
                                                        <div className={'avatar'}>
                                                            <i className={'fa fa-user'}></i>
                                                        </div>
                                                    </div>
                                                    <div className={'col-md-10'}>
                                                        <div className={'noteTitle'}>
                                                            <div>
                                                                {eacCom.addedByUserId && eacCom.addedByUserId.name ? eacCom.addedByUserId.name : null}
                                                            </div>
                                                            <span className={'date'}>
                                                                <i className={'fa fa-calender'}></i>
                                                                {displayDate(eacCom.time)}</span>
                                                        </div>
                                                        <div className={'commentText'}>
                                                            {eacCom.text}
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </React.Fragment>
                                    )
                                }) : null}
                            </div>
                            <br/>
                            <button type="button">+ add comment
                            </button>
                        </div>
                    )
                }) : null}


            </div>
        </>
    )
}
const NoteList = (props) => {
    let dispatch = useDispatch()
    let {visible, studentId, applicationId, onClose, onSubmit} = props;
    let [noteList, setNoteList] = useState([])
    useEffect(() => {
        loadNoteList()
    }, [applicationId])
    let loadNoteList = async () => {
        let params = {
            results: 100,
            count: 100,
            studentId,
            applicationId
        }
        let {data} = await dispatch(applicationNoteFxn(params))
        console.log(data)
        setNoteList(data)
    }
    return (
        <>
            <Drawer title={'Notes'} visible={visible} onClose={onClose} width={"60%"}>
                <div className={'student-tab'}>
                    <div className={'noteBlock'}>
                        {noteList && noteList.length ? noteList.map((item, index) => {
                            return (
                                <SingleNote data={item} key={item._id} index={index}/>
                            )
                        }) : null}
                    </div>
                </div>
            </Drawer>
        </>
    )
}
export default NoteList
