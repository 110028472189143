import React from "react"
import {useNavigate} from "react-router-dom";
import {menuData} from "../../routes/menu";
import {useDispatch} from "react-redux";

const SingleMenu = (props) => {
    let {menu, events} = props;
    return !menu.dontShowOnMenu ? (
        <>
            <a onClick={() => events.openLink(menu.path)} className="nav-item nav-link" key={menu.key}>
                <img src="../app/img/dashboard-ico.svg" className="me-2" alt=""/>{menu.name}</a>
        </>
    ) : null;
};
const NestedMenu = (props) => {
    let {menu, events} = props;
    let menuKey = `ui-${menu.key}`;
    return (
        !menu.dontShowOnMenu ? <>
            <div className="nav-item dropdown" key={menuKey}>
                <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">
                    <img src="../app/img/setting-ico.svg" className="me-2" alt=""/>{menu.name}</a>
                <div className="dropdown-menu border-0">
                    {menu.subMenu.map((child) => {
                        return !child.dontShowOnMenu ? (
                            events.checkAuth(child) ? <div key={child.key}>
                                {child.type == 'link' ?
                                    <a key={child.key}
                                       href={child.path}
                                       target={'_blank'}
                                       className="nav-item nav-link dropdown-item">
                                        {child.name}
                                    </a> :
                                    <a key={child.key}
                                       onClick={() => events.openLink(child.path)}
                                       className="nav-item nav-link dropdown-item">
                                        {child.name}
                                    </a>}
                            </div> : null
                        ) : null;
                    })}

                </div>
            </div>
        </> : null
    );
};


const SideMenu = () => {
    let dispatch = useDispatch()
    const navigate = useNavigate();
    let user = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : null;

    let events = {
        openLink: (path) => {
            navigate(path);
        },
        checkAuth: (data) => {
            return data && data.authority && data.authority.length
                ? data.authority.includes(user.userType)
                    ? true
                    : false
                : true;
        },
    };

    const logoutFxn = () => {
        localStorage.clear();
        setTimeout(() => {
            dispatch({
                type: "SET_CURRENT_USER",
                user: {},
            });
            window.location.href = "/login";
        }, 100);
    };
    return (
        <>
            <div className="sidebar pb-3">
                <a href="#" className="sidebar-toggler flex-shrink-0 d-lg-block d-xl-none">
                    <i className="fa fa-times"></i>
                </a>
                <nav className="navbar">
                    <a href="/" className="navbar-brand mx-4">
                        <img src="../app/img/logo-white.png" alt="logo" className="logo-white"/>
                    </a>
                    <div className="navbar-nav w-100">
                        {menuData.map((item) => {
                            let {subMenu = []} = item;
                            let checkM = ((subMenu && subMenu.length ? (
                                <NestedMenu menu={item} events={events} key={item.key}/>
                            ) : (
                                <SingleMenu menu={item} events={events} key={item.key}/>
                            )));
                            return events.checkAuth(item) ? checkM : null;
                        })}

                        <div className="bottom mt-auto">
                            {/* <a href="index.html" className="nav-item nav-link">
                                <img src="../app/img/setting-ico.svg" className="me-2" alt=""/>Settings</a>*/}
                            <a className="nav-item nav-link" onClick={logoutFxn}>
                                <img src="../app/img/logout-ico.svg" className="me-2" alt=""/>Logout</a>
                        </div>

                    </div>
                </nav>
            </div>
        </>
    )
}
export default SideMenu
