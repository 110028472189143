import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import Card from "../../components/Elements/Card";
import {commissionListFxn, postUpdateCommission} from "./action";
import {displayDate, Popconfirm, Table} from "../../components/Elements/appUtils";
import {imgPath} from "../../components/_utils/_utils";

let {TableCard} = Card;
const CommissionList = (props) => {
  let dispatch = useDispatch();
  let tableRef = useRef();

  const [total, setTotal] = useState(0);
  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      let obj = {
        ...params,

        regExFilters: ["name"],
      }
      let data = await dispatch(commissionListFxn(obj))
      setTotal(data.total)
      resolve(data);
    });
  };
  const handleSubmitCommissionApproved = async (id) => {
    let resp = await dispatch(postUpdateCommission({id, status: "Approved"}));
    if (resp) {
      tableRef.current.reload()
    }
  };
  let columns = [
    {
      title: "#",
      key: "index",
      dataIndex: "#",
      render: (item, record, index) => {
        return index + 1;
      }
    },
    {
      title: "Semester",
      key: "semesterName",
      dataIndex: "semesterName"
    },
    {
      title: "Tuition Fees",
      key: "tuitionFees",
      dataIndex: "tuitionFees"
    },
    {
      title: "Commission",
      key: "commission",
      dataIndex: "commission"
    },
    {
      title: "Invoice Date",
      key: "invoiceDate",
      dataIndex: "invoiceDate",
      render: (item) => {
        return item ? displayDate(item) : null
      }
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (item, record) => {
        return (
            <>
              {item == 'Pending' ? <span className="pending">Pending</span> : null}
              {item == 'Approved' ? <span className="active">Approved</span> : null}
            </>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (item, record) => {
        let {status} = record;
        return (
            <>
              {status == 'Pending' ? <>

                <Popconfirm
                    title={"Are your sure, you want to approve commission?"}
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => {
                      handleSubmitCommissionApproved(record._id);
                    }}>
                  <img src={imgPath.check}
                       className="btn-action"
                       style={{width: "25px"}}
                  />
                </Popconfirm>
              </> : null}

            </>
        )
      }
    }
  ]
  return (
      <>
        <TableCard title={'Commission List'}>
          <Table apiRequest={apiRequest} columns={columns} ref={tableRef} pagination={false}/>

        </TableCard>
      </>
  );
};
export default CommissionList;
