import React, {Suspense, useEffect, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import AdminDashboard from "./InnerDashboard/adminDashboard";
import CompanyDashboard from "./InnerDashboard/companyDashboard";

const Dashboard = (props) => {
    let {currentUser} = props;

    const setScriptFxn = () => {
        const script = document.createElement('script');
        script.src = "../app/js/customScript.js";
        script.async = true;
        setTimeout(() => {
            document.body.appendChild(script);
        }, 500)
        return () => {
            document.body.removeChild(script);
        }
    }


    return (
        <>
            <div>
                {currentUser.userType == 'admin' ? <AdminDashboard {...props}/> : <CompanyDashboard {...props}/>}
            </div>
        </>
    );
};
const mapStateToProps = ({global, router}) => ({
    currentUser: global.currentUser,
});
export default connect(mapStateToProps, null)(Dashboard);
