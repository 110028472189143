import {apiUrl} from "../../settings";

export const addDocumentUrl = () => {
    return `${apiUrl}/selfDocument/add`;
};
export const DocumentListUrl = () => {
    return `${apiUrl}/selfDocument/list`;
};

export const getSingleDocumentUrl = (documentId) => {
    console.log(documentId);
    return apiUrl + '/singleSelfDocument/' + documentId
};

export const postDocumentUrl = () => {
    return `${apiUrl}/selfDocument/update` ;
};