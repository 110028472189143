import _ from "lodash";
import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Form, notification} from "../../../components/Elements/appUtils";
import {Countries, Flags} from "../../../components/_utils/appUtils";
import {LoadState} from "../../../components/_utils/countryUtil";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import SubscriptionPackage from "../../../components/subscriptionPackage";
import {registerCompanyFxn} from "../actions";
import Layout from "../layout";

const Signup = (props) => {
  const [selectedCountry, setSelectedCountry] = useState({});
  const [activePlan, setActivePlan] = useState(null);
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const [stateArray, setStateArray] = useState([]);

  let events = {
    chooseCountry: (name) => {
      let countryName = _.find(Countries, (item) => {
        return item.name == name;
      });
      if (countryName) {
        setStateArray(countryName.id ? LoadState(countryName.id) : []);
      }
    },
  };
  let [plan, setPlan] = useState({});
  const handleCountryCodeChange = (selectedCountryCode) => {
    const selectedCountry = Flags.find(
        (item) => item.countryCode === selectedCountryCode
    );
    if (selectedCountry) {
      props.form.setFieldsValue({
        countryName: selectedCountry.name,
      });
      events.chooseCountry(selectedCountry.name);
    }
  };

  const handleSubmit = (e) => {
    const {form} = props;
    e.preventDefault();
    form.validateFields(async (err, valData) => {
      if (!err) {
        if (!valData.contactPerson) {
          notification.warning({
            message: "Please enter contact person name.",
          });
          return;
        }
        if (!valData.companyName) {
          notification.warning({message: "Please enter company name."});
          return;
        }
        if (!valData.email) {
          notification.warning({message: "Please enter company email."});
          return;
        }
        if (!valData.countryCode && !valData.countryName) {
          notification.warning({message: "Please enter country code."});
          return;
        }
        if (!valData.mobile) {
          notification.warning({message: "Please enter contact number."});
          return;
        }
        if (!valData.stateName) {
          notification.warning({message: "Please enter state."});
          return;
        }
        if (!valData.cityName) {
          notification.warning({message: "Please enter City Name."});
          return;
        }
        if (!valData.password) {
          notification.warning({message: "Please enter password."});
          return;
        }
        if (!valData.confirmPassword) {
          notification.warning({message: "Please enter confirm password."});
          return;
        }
        if (valData.password !== valData.confirmPassword) {
          notification.warning({message: "Password not match."});
          return;
        }
        if (!plan || (plan && !plan.name)) {
          notification.warning({message: "Please choose plan."});
          return;
        }
        console.log(plan, "plammmmmmmmmmmmmmm");
        let x = await dispatch(registerCompanyFxn({...valData, plan}));
        if (x && x.success) {
          form.setFieldsValue({
            name: "",
            companyName: "",
            address: "",
            stateName: "",
            countryName: "",
            cityName: "",
            email: "",
            countryCode: "",
            contactPerson: "",
            postalCode: "",
            website: "",
            referralBy: "",
            password: "",
            confirmPassword: "",
            mobile: "",
          });
          setPlan({});
        }
      } else {
        notification.warning({
          message: `Please fill all required field.`,
        });
        return;
      }
    });
  };

  const choosePlanFxn = (data) => {
    setPlan(data);
    setActivePlan(data);
  };

  let inputTypes = {
    fields: [
      {
        key: "contactPerson",
        label: "Contact Person Name",
        showStar: true,
        placeholder: "University",
        span: "col-md-12",
      },
      {
        key: "companyName",
        label: "Company Name",
        showStar: true,
        placeholder: "companyName",
        span: "col-md-12",
      },
      {
        key: "email",
        label: "Company Email",
        showStar: true,
        placeholder: "Email",
        span: "col-md-12",
      },
      {
        key: "countryCode",
        label: "Country Code",
        showStar: true,
        placeholder: "Country Code",
        type: "select",
        options: Flags,
        showSearch: true,
        keyAccessor: (x) => x.countryCode,
        valueAccessor: (x) => `${x.name} (${x.dialCode})`,
        onChange: (selectedCountryCode) =>
            handleCountryCodeChange(selectedCountryCode),
        span: "col-md-6",
      },

      {
        key: "mobile",
        label: "Contact Number",
        showStar: true,
        placeholder: "Contact Number",
        span: "col-md-6",
      },
      {
        key: "countryName",
        label: "Country",
        showStar: true,
        placeholder: "Country",
        type: "select",
        options: Countries,
        showSearch: true,
        keyAccessor: (x) => x.name,
        valueAccessor: (x) => `${x.name}`,
        onChange: (selectedCountry) => {
          props.form.setFieldsValue({
            countryName: selectedCountry,
          });
          events.chooseCountry(selectedCountry);
        },
      },

      {
        key: "stateName",
        label: "State",
        required: false,
        type: "select",
        showSearch: true,
        placeholder: "State Name",
        options: stateArray,
        keyAccessor: (x) => x.name,
        showStar: true,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            stateName: x,
            cityName: "",
          });
        },
        span: "col-md-6",
      },
      {
        key: "cityName",
        label: "City",
        showStar: true,
        placeholder: "City",
        span: "col-md-6",
      },
      {
        key: "postalCode",
        label: "Postal Code (Optional)",
        required: false,
        placeholder: "Postal Code (Optional)",
        span: "col-md-12",
      },
      {
        key: "address",
        label: "Address (Optional)",
        required: false,
        placeholder: "Address",
        span: "col-md-12",
      },
      {
        key: "website",
        label: "Website(Optional)",
        required: false,
        placeholder: "Website",
        span: "col-md-12",
      },
      {
        key: "referralBy",
        label: "Referral Code (if any)",
        required: false,
        placeholder: "Referral Code",
        span: "col-md-12",
      },
      {
        key: "password",
        label: "Password",
        showStar: true,
        placeholder: "Password",
        span: "col-md-12",
        type: "password",
      },
      {
        key: "confirmPassword",
        label: "Confirm Password",
        showStar: true,
        placeholder: "Confirm Password",
        span: "col-md-12",
        type: "password",
      },
    ],
  };

  const goToLogin = () => {
    navigate("/login");
  };
  return (
      <Layout formName={"signup"}>
        <div className="content-box">
          <div className="head">
            <h5>Registration</h5>
          </div>

          <div className="field">
            <Form onSubmit={handleSubmit}>
              <div className={"row"}>
                {inputTypes.fields.map((item, key) => {
                  return !item.hidden ? (
                      <div className={`${item.span}  mb-3`} key={key}>
                        <GetEachFormFields {...props.form} item={item}/>
                      </div>
                  ) : null;
                })}
              </div>

              <div className="form-floating mb-3">
                <div
                    className="plan"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                >
                  {plan && plan.planType ? (
                      <>
                        {plan.planType} USD ${plan.price} ({plan.periodName})
                        <span>
                      <img src="../app/img/edit.png" alt=""/>
                    </span>
                      </>
                  ) : (
                      <>
                        Choose Plan
                        <span>
                      <img src="../app/img/edit.png" alt=""/>
                    </span>
                      </>
                  )}
                </div>
              </div>

              <button type="submit" className="btn main-btn">
                Submit
              </button>
            </Form>

            <div className="forgot">
              Already have a account? <a onClick={goToLogin}>login</a>
            </div>
          </div>
        </div>

        <SubscriptionPackage choosePlan={choosePlanFxn} activePlan={activePlan}/>
      </Layout>
  );
};
export default Form.create()(Signup);
