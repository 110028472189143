import React from "react"
import {Button, Card, Drawer, Form} from "../../../components/Elements/appUtils";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import {AddCloseFxn} from "../actions/index";
import {useDispatch} from "react-redux";

const CloseLead = (props) => {
    let {visible, onClose, leadId} = props;
    const {getFieldDecorator} = props.form;
    let dispatch = useDispatch()
    let inputTypes = {
        fields: [
            {
                key: "reason",
                label: "Notes",
                required: true,
                placeholder: "Notes",
                type: 'textarea'

            },

        ]
    }
    const handleClose = (e) => {
        const {form} = props;
        e.preventDefault();
        form.validateFields(async (err, valData) => {
            if (!err) {
                valData.leadId = leadId
                let x = await dispatch(AddCloseFxn(valData));
                props.form.setFieldsValue({
                    reason: ""
                })
            }
        });
    }
    return (
        <>
            <Drawer placement="right"
                    title={"Close Lead"}
                    onClose={onClose} visible={visible} width="40%">
                <Card>
                    <Form onSubmit={handleClose}>
                        <div className={"row"}>
                            {inputTypes.fields.map((item, key) => {

                                return (
                                    <div className={`pt-3 ${item.span == 6 ? "col-md-6" : "col-md-12"}`}
                                         key={key}>
                                        <GetEachFormFields
                                            {...props.form}
                                            item={item}
                                        />
                                    </div>
                                );
                            })}
                        </div>

                        <div style={{marginTop: "20px"}}>
                            <Button type={"primary"} htmlType="submit">
                                submit
                            </Button>
                        </div>
                    </Form>
                </Card>
            </Drawer>
        </>
    )
}
export default Form.create()(CloseLead)
