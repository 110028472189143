import React, {useEffect, useState} from "react"
import {applicationListFxn} from "../../SOP/action";
import {Modal, appDisplayDate} from "../../../components/Elements/appUtils";
import AddNote from "../drawers/addNote";
import NoteList from "../drawers/noteList";
import AddMoreApplication from "../drawers/AddMoreApplication";
import {statusColor} from "../../../components/_utils/_utils";


let SingleApplication = (props) => {
    let {application} = props;
    let {courseId, countryId, universityId, addedOn, campusId, intake} = application
    let {flag} = countryId
    return (
        <>
            <div className="student-applications">
                <ul>
                    <li className="card-box border-grey mb-3">
                        <div className="head">
                            <h5><span>
                                {flag && flag.path ?
                                    <img src={flag.path} alt=""/> : null}
                            </span> {countryId && countryId.countryName ? countryId.countryName : ""}</h5>
                            <div className={`badge ${statusColor[application.status]}`}>{application.status}</div>
                        </div>
                        <span
                            className="university-name">{universityId && universityId.universityName ? universityId.universityName : ""}</span>
                        <div className="row">
                            <div className="col-lg-10 col-12">
                                <div className="data">
                                    <p><b>Course:</b> {courseId && courseId.courseName ? courseId.courseName : ""}</p>

                                    <p>

                                        {intake && intake.month ?
                                            <span><b>Intake:</b> {intake.month}, {intake.year} | </span> : null}

                                        <span> <b>Added Date:</b> {addedOn ? appDisplayDate(addedOn) : ""}</span>
                                    </p>

                                    <p><b>Campus:</b>
                                        {campusId && campusId.campusName ? campusId.campusName : ""}
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-2 col-12">
                                {/* <button className="btn" type="button"><img src="../app/img/icons/menu.svg" alt=""/>
                                </button>*/}
                            </div>
                        </div>
                    </li>
                </ul>
            </div>


        </>
    )
}

const ApplicationList = (props) => {
    let {studentId, onClose, visible, studentName} = props;
    let [applicationList, setApplicationList] = useState([]);
    let [addAppState, setAddAppState] = useState({
        visible: false
    })

    let [addNoteState, setAddNoteState] = useState({
        visible: false,
        studentId: "",
        applicationId: "",
    });
    let [noteListState, setNoteListState] = useState({
        visible: false,
        studentId: "",
        applicationId: "",
    });


    useEffect(() => {
        events.apiApplication();
    }, [studentId]);

    const events = {
        apiApplication: async () => {
            let params = {
                results: 50,
                count: 50,
                studentId
            };
            let {data} = await applicationListFxn({
                ...params,
                // regExFilters: [],
            });
            setApplicationList(data);
        },
        showAddNote: (applicationId, studentId) => {
            setAddNoteState({
                visible: true,
                studentId,
                applicationId,
            });
        },
        hideAddNote: () => {
            setAddNoteState({
                visible: false,
                studentId: "",
                applicationId: "",
            });
        },
        showNoteList: (applicationId, studentId) => {
            setNoteListState({
                visible: true,
                studentId,
                applicationId,
            });
        },
        hideNoteList: () => {
            setNoteListState({
                visible: false,
                studentId: "",
                applicationId: "",
            });
        },
        showAddMoreApp: () => {
            setAddAppState({
                visible: true
            })
        },
        hideAddMoreApp: () => {
            setAddAppState({
                visible: false
            })
        }
    };

    return (
        <>
            <Modal visible={visible} onClose={onClose} width={'35vw'}>
                <div className="modal-header">
                    <h1 className="modal-title">{studentName}</h1>
                    <button
                        type="button"
                        className="btn blue-btn"
                        onClick={() => {
                            events.showAddMoreApp()
                        }}>Add Application
                    </button>
                </div>
                {applicationList && applicationList.length ? applicationList.map((singleApp) => {
                    return (
                        <SingleApplication application={singleApp}/>
                    )
                }) : null}
            </Modal>


            {addNoteState.visible ? (
                <AddNote
                    {...addNoteState}
                    onClose={events.hideAddNote}
                    onSubmit={() => {
                        events.hideAddNote();
                        events.apiApplication();
                    }}
                />
            ) : null}
            {noteListState.visible ? (
                <NoteList
                    {...noteListState}
                    onClose={events.hideNoteList}
                    onSubmit={() => {
                        events.hideNoteList();
                        events.apiApplication();
                    }}
                />
            ) : null}

            {addAppState.visible ?
                <AddMoreApplication {...addAppState} onClose={events.hideAddMoreApp} studentId={studentId}/> : null}

        </>
    )
}
export default ApplicationList
