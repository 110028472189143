import {hideLoader, showLoader} from "../../../actions/loader";
import notification from "../../../components/Elements/Notification";
import {customAxios as axios, getToken} from "../../../request";
import {boardStreamListUrl, boardStreamUrl, deleteBoardUrl} from "../api";

export const AddBoardStreamFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(boardStreamUrl(), valData, getToken());
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};

// export const stateCategoryList = (valData) => async (dispatch) => {
//     dispatch(showLoader())
//     let config = {
//         params: {...valData},
//         ...await getToken()
//     }
//     let {data} = await axios.get(StateListUrl(), config)
//     dispatch(hideLoader())
//     if (data.error) {
//         notification.error({
//             message: data.message || 'Error'
//         })
//     }
//     return data
// }

export const boardStreamListFxn = (filters) => async (dispatch) => {
    dispatch(showLoader());
    let config = {
        params: filters,
        ...await getToken()
    }
    let {data} = await axios.get(boardStreamListUrl(), config);
    dispatch(hideLoader());
    return data;
};

export const deleteBoardFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(deleteBoardUrl(), valData, getToken());
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};
