import { hideLoader, showLoader } from "../../actions/loader";
import notification from "../../components/Elements/Notification";
import Request, {customAxios as axios, getToken} from "../../request.js";
import {getUserByTypeUrl, userUrl} from "./apis";

export const AddReceptionFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  let { data } = await axios.post(userUrl(), valData, getToken());
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  } else {
    notification.success({
      message: data.message || "Success",
    });
  }
  return data;
};

export const ReceptionListFxn = async (valData) => {
  let config = {
    params: { ...valData },
    ...(await getToken()),
  };
  let { data } = await axios.get(userUrl(), config);
  return data;
};
export const branchListFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
    let config = {
        params: {...valData},
        ...(await getToken()),
    };
    let {data} = await axios.get(getUserByTypeUrl(), config);
  dispatch(hideLoader());

  return data;
};
