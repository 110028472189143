import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Card} from "../../../components/Elements/appUtils";
import {getCompanyUserGroupedFxn} from "../views/action";

const UserComponent = (props) => {
    const navigate = useNavigate();
    let {data, events} = props;
    let openPage = (type) => {
        if (type == "branch") {
            navigate("/add-branch");
        }
        if (type == "branchUser") {
            navigate("/add-branch-user");
        }
        if (type == "reception") {
            navigate("/add-reception");
        }
        if (type == "counsellor") {
            navigate("/add-counsellor");
        }
        if (type == "hr") {
            navigate("/add-hr");
        }
        if (type == "manager") {
            navigate("/add-manager");
        }
        if (type == "englishTeacher") {
            navigate("/add-english-teacher");
        }
    };
    let openEmployeePage = (type) => {
        if (type == "hr") {
            navigate("/add-employee");
        }
    };
    let openList = (type) => {
        if (type == "branch") {
            navigate("/branch-list");
        }
        if (type == "branchUser") {
            navigate("/branch-user-list");
        }
        if (type == "reception") {
            navigate("/reception-list");
        }
        if (type == "counsellor") {
            navigate("/counsellor-list");
        }
        if (type == "hr") {
            navigate("/hr-list");
        }
        if (type == "englishTeacher") {
            navigate("/englishTeacher-list");
        }
        if (type == "manager") {
            navigate("/manager-list");
        }
    };
    return (
        <>
            <div className="col-lg-6 col-12" key={data.userType}>
                <div className="card-box p-4">
                    <div className="flex-box">
                        <h3>{data.name}</h3>
                        <button
                            className="btn blue-btn"
                            onClick={() => {
                                openPage(data.type);
                            }}
                        >
                            Add New {data.name}
                        </button>
                    </div>
                    <a onClick={() => {
                        openList(data.type);
                    }}>
                        <span className="count">Total Users: {data.count}</span>
                    </a>
                </div>
            </div>


        </>
    );
};

const UserGrouped = (props) => {
    let [userList, setUserList] = useState([]);
    let dispatch = useDispatch();
    useEffect(() => {
        loadUserList();
    }, []);
    const loadUserList = async () => {
        let resp = await dispatch(getCompanyUserGroupedFxn());
        setUserList(resp);
    };
    return (
        <>
            <div title={"Our Teams"}>


                <section className="our-teams">
                    <div className="row gy-4">
                        {userList && userList.length
                            ? userList.map((data) => {
                                return <UserComponent data={data} key={data._id}/>;
                            })
                            : null}


                    </div>
                </section>
            </div>
        </>
    );
};
export default UserGrouped;
