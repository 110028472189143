import React, {useEffect, useState} from 'react';
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import {Button, Card, Form} from "../../../components/Elements/appUtils";
import {GetCourseCategoryFxn, postCourseCategoryFxn} from './action';
import {useDispatch} from 'react-redux';

const CourseCategoryEdit = (props) => {
    const {getFieldDecorator} = props.form;
    const [categoryId, setCategoryId] = useState("");
    const [oldLogo, setOldLogo] = useState({})
    const [logo, setLogo] = useState({})
    let [formState, setState] = useState({
        categoryName: "",
        logo: {}
    });
    let dispatch = useDispatch();

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setLogo(file)
    }
    useEffect(() => {
        getCampusData()
    }, [])

    const getCampusData = async () => {
        let searchParams = new URLSearchParams(window.location.search);
        let id = searchParams.get("_id");

        if (id) {
            setCategoryId(id);
            getRelatedCategory(id);
        }
    };

    const getRelatedCategory = async (id) => {

        let {data} = await dispatch(GetCourseCategoryFxn(id));
        console.log(data, 'category');

        setTimeout(() => {
            props.form.setFieldsValue({
                categoryName: data.categoryName,
            });

        }, 500)
        setOldLogo(data.logo)
    }

    const handleSubmit = (e) => {
        const { form } = props;
        e.preventDefault();
        form.validateFields(async (err, valData) => {
            console.log(err, valData);
            if (!err) {
                valData.courseCategoryId = categoryId
                let fd = new FormData();
                fd.append("obj", JSON.stringify(valData));
                if (logo && logo.name) {
                    fd.append("logo", logo);
                }
                let x = await dispatch(postCourseCategoryFxn(fd));
            }
        });
    };

    let inputTypes = {
        fields: [
            {
                key: "categoryName",
                label: "Category Name",
                required: true,
                placeholder: "Category Name"

            },
            {
                key: "logo",
                label: " Logo",
                placeholder: "logo",
                type: "file",
                onChange: handleFileChange,
                urlPath:oldLogo
            },
        ]
    };
    return (
        <>
            <Card title={"Edit Course Category"}>
                <Form onSubmit={handleSubmit}>
                    <div className={"row"}>
                        {inputTypes.fields.map((item, key) => {
                            return (
                                <div className={"col-md-6 pt-3"} key={key}>
                                    <GetEachFormFields
                                        {...props.form}
                                        item={item}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    <div style={{ marginTop: "20px" }}>
                        <Button type={"primary"} htmlType="submit">
                            submit
                        </Button>
                    </div>
                </Form>
            </Card>
        </>
    )
}

export default Form.create()(CourseCategoryEdit);
