import {addCourseCategoryUrl, courseCategoryUrl, getCourseCategoryUrl, postCourseCategoryUrl} from "../api";
import notification from "../../../../components/Elements/Notification";
import {customAxios as axios, getToken} from "../../../../request";
import {hideLoader, showLoader} from "../../../../actions/loader";

export const AddCourseCategoryFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let data = await axios.post(addCourseCategoryUrl(), valData, getToken());
    dispatch(hideLoader())
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    }
    return data.data;
};


export const courseCategoryList = async (valData) => {
    let config = {
        params: {...valData},
        ...await getToken()
    }
    let {data} = await axios.get(courseCategoryUrl(), config)
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }

    return data
}


export const GetCourseCategoryFxn = (id) => async (dispatch) => {
    dispatch(showLoader())
    let config = {
        ...await getToken()
    }
    let {data} = await axios.get(getCourseCategoryUrl(id), config)
    dispatch(hideLoader())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }

    return data
}

export const postCourseCategoryFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(postCourseCategoryUrl(), valData, getToken());
    dispatch(hideLoader())
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message
        });
    }
    return data;
};
