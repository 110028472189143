import {hideLoader, showLoader} from "../../../actions/loader";
import notification from "../../../components/Elements/Notification";
import {customAxios as axios, getToken} from "../../../request";
import {
    addPurchasePlanUrl, getSinglePurchasePlanByUserUrl, purchasePlansListUrl,
    singlePurchasePlanUrl
} from "../api";

export const addPurchasePlanFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(addPurchasePlanUrl(), valData, getToken());
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};


export const singlePurchasePlanFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let config = {
        params: {...valData},
        ...await getToken()
    }
    let {data} = await axios.get(singlePurchasePlanUrl(), config);
    dispatch(hideLoader());
    return data;
};

export const purchasePlansListFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let config = {
        params: {...valData},
        ...await getToken()
    }
    let {data} = await axios.get(purchasePlansListUrl(), config);
    dispatch(hideLoader());
    return data;
};


export const getSinglePurchasePlanByUserFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let config = {
        params: {...valData},
        ...await getToken()
    }
    let {data} = await axios.get(getSinglePurchasePlanByUserUrl(), config);
    dispatch(hideLoader());
    return data;
};

