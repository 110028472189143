import {hideLoader, showLoader} from "../../actions/loader";
import notification from "../../components/Elements/Notification";
import {customAxios as axios, getToken} from "../../request";
import {addDocumentUrl, DocumentListUrl, postDocumentUrl, getSingleDocumentUrl} from "./api";

export const AddDocumentFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(addDocumentUrl(), valData, getToken());
    dispatch(hideLoader())
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};

export const documentList = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let config = {
        params: {...valData},
        ...await getToken()
    }
    let {data} = await axios.get(DocumentListUrl(), config)
    dispatch(hideLoader())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }

    return data;
};
export const getSingleDocumentFxn = (id) => async (dispatch) => {
    dispatch(showLoader())
    let config = {
        ...await getToken()
    }
    let {data} = await axios.get(getSingleDocumentUrl(id), config)
    dispatch(hideLoader())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }

    return data
}

export const postDocumentFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(postDocumentUrl(), valData, getToken());
    dispatch(hideLoader())
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message
        });
    }
    return data;
};
