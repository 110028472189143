import { apiUrl } from "../../settings";

export const addCountryUrl = () => {
    return `${apiUrl}/addMasterCountry`;
};
export const activateCountryUrl = () => {
    return `${apiUrl}/addCountry`;
};

export const CountryListUrl = () => {
  return `${apiUrl}/masterCountriesList`;
};

export const getCountryUrl = (countryId) => {
  return apiUrl + "/getSingleMasterCountry/" + countryId;
};

export const updateCountryUrl = () => {
  return `${apiUrl}/updateMasterCountry`;
};

export const singleCountryStatusUrl = () => {
  return `${apiUrl}/singleCountryStatus`;
};

export const loadPreviousCountryListUrl = () => {
  return `${apiUrl}/allPreviousCountries`;
};
export const listCountryStatusUrl = () => {
  return `${apiUrl}/listCountryStatus`;
};
export const addCountryStatusUrl = () => {
  return `${apiUrl}/listCountryStatus`;
};
export const singleCountryStatusListUrl = () => {
  return `${apiUrl}/singleCountryStatusList`;
};
export const addStatusToCountryUrl = () => {
    return `${apiUrl}/addStatusToCountry`;
};

export const moveStatusToCountryUrl = () => {
    return `${apiUrl}/updateStatusListIndex`;
};
export const updateStatusInCountryUrl = () => {
    return `${apiUrl}/updateStatusInCountry`;
};

export const updateStatusToggleInCountryUrl = () => {
    return `${apiUrl}/updateStatusToggleInCountry`;
};
