import React, {useEffect, useState} from "react";
import {Button, Drawer, Form, Card} from "../../../components/Elements/appUtils";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import {updateTemplateSectionFxn} from "../actions";
import moment from "moment";
import {useDispatch} from "react-redux";

const TemplateSectionEdit = (props) => {
    let dispatch = useDispatch()
    let {visible, onClose, sectionData} = props;
    const {getFieldDecorator} = props.form;
    let [editorKey, setEditorKey] = useState(moment())


    const handleSubmit = (e) => {
        const {form} = props;
        e.preventDefault();
        form.validateFields(async (err, valData) => {
            if (!err) {
                valData.templateSectionId = sectionData._id;
                let x = await dispatch(updateTemplateSectionFxn(valData));
                console.log(x);
                form.resetFields();
                onClose()
            }
        });
    };


    const getRelatedState = () => {
        setTimeout(() => {
            props.form.setFieldsValue({
                title: sectionData.title,
                content: sectionData.content
            });
            setEditorKey(moment())

        }, 500)
    }
    useEffect(() => {
        getRelatedState()
    }, [sectionData]);

    let inputTypes = {
        fields: [
            {
                key: "title",
                label: "Section Title",
                // required: true,
                placeholder: "Section Title",
                type: "text",
                onChange: (x) => {
                    props.form.setFieldsValue({
                        title: x,
                    });
                },
                span: 8,
            },

            {
                key: "content",
                label: "Section Content",
                type: "ckeditor",
                required: true,
                editorKey: editorKey,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        content: x,
                    });
                },
            },
        ],
    };

    return (
        <Drawer placement="right" onClose={onClose} visible={visible} width="65%" title={'Edit Section'}>
            <Form onSubmit={handleSubmit}>
                <div className={"row"}>
                    {inputTypes.fields.map((item, key) => {
                        return (
                            <div className={"col-md-12 pt-3"} key={key}>
                                <GetEachFormFields
                                    {...props.form}
                                    item={item}
                                />
                            </div>
                        );
                    })}
                </div>
                <Button type={"primary"} htmlType="submit" className={"mt-4"}>
                    submit
                </Button>
            </Form>
        </Drawer>
    );
};

export default Form.create()(TemplateSectionEdit);
