import React, {useEffect, useState} from 'react'
import {Card, Drawer, displayDate, displayTime} from '../../../components/Elements/appUtils';
import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import '../style.css'
import {FollowUpList} from '../actions/index';
import {useDispatch} from 'react-redux';

const FollowUpListComponent = (props) => {
    let {visible, onClose, leadId} = props;
    const [followList, setFollowList] = useState([])
    let dispatch = useDispatch()

    const apiRequest = async () => {
        let obj = {
            leadId,
            sortField: '_id',
            sortOrder: 'descend',
        }
        let data = await dispatch(FollowUpList(obj))
        setFollowList(data.data)
    }
    useEffect(() => {
        apiRequest()
    }, [])
    return (
        <>
            <Drawer placement="right" onClose={onClose} visible={visible} width="40%" title={"Follow Ups"}>
                <Card>
                    <ul className="ant-timeline ant-timeline-pending">
                        {followList && followList.length ? followList.map((item, index) => {
                            return (
                                <li className="ant-timeline-item" key={item._id}>
                                    <div className="ant-timeline-item-tail"></div>
                                    <div
                                        className={`ant-timeline-item-head ant-timeline-item-head-${index == 0 ? 'green' : "blue"}`}></div>
                                    <div className="ant-timeline-item-content">
                                        <div className={'dateBox'}>
                                            <label
                                                className={`label ${index == 0 ? 'label-green' : "label-blue"}`}>{item.type}</label>
                                            <div className={'date'}>
                                                <i class="fa fa-calendar" aria-hidden="true"></i> &nbsp;
                                                {displayDate(item.createdAt)}
                                            </div>
                                        </div>
                                        <div className={'ant-timeline-body'}>
                                            <div>
                                                <strong>Subject :- </strong>
                                                {item.subject}</div>
                                            <div className={'mt-3'}>{item.notes}</div>
                                            <div className={'followupDate'}>
                                                Next Followup : {displayDate(item.date)} at {displayTime(item.time)}
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            )
                        }) : null}

                    </ul>

                </Card>
            </Drawer>
        </>
    )
}

export default FollowUpListComponent
