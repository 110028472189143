import {hideLoader, showLoader} from "../../../../actions/loader";
import notification from "../../../../components/Elements/Notification";
import {customAxios as axios, getToken} from "../../../../request";
import {
    countryStatusUrl,
    getCompanyUserGroupedUrl,
    getSingleUserUrl,
    getUserCountryStatusWiseUrl,
    postUpdateUserUrl,
    postUpdateUserEducationUrl,
    addLeaveUrl,
    addLeaveByUserUrl,
    SingleUserCheckInUrl,
    AllLeaveUrl,
    checkInUrl,
    checkOutUrl
} from "../api";

export const CountryStatusFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let config = {
        params: valData,
        ...(await getToken()),
    };
    let {data} = await axios.get(countryStatusUrl(), config);
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    }

    return data.data;
};

export const getUserCountryStatusWiseFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(
        getUserCountryStatusWiseUrl(),
        valData,
        getToken()
    );
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    }

    return data.data;
};

export const GetRelatedUserFxn = (id) => async (dispatch) => {
    dispatch(showLoader());
    let config = {
        ...(await getToken()),
    };
    let {data} = await axios.get(getSingleUserUrl(id), config);
    console.log(data);
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    }

    return data;
};

export const postUpdateUserFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {id} = valData;
    let {data} = await axios.put(postUpdateUserUrl(id), valData, getToken());
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message,
        });
    }
    return data;
};

export const getCompanyUserGroupedFxn = () => async (dispatch) => {
    dispatch(showLoader());
    let data = await axios.post(getCompanyUserGroupedUrl(), {}, getToken());
    dispatch(hideLoader());
    return data.data;
};

export const postUpdateUserEducationFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {id} = valData;
    let data = await axios.post(postUpdateUserEducationUrl(id), valData, getToken());

    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.data.message || "Error",
        });
    } else {
        notification.success({
            message: data.data.message,
        });
    }
    return data.data;
};

export const addLeave = (valData) => async (dispatch) => {
  dispatch(showLoader());
  let {id} = valData;
  console.log(id, "ddddddddddddddddddddddddddddd")
  let { data } = await axios.post(addLeaveUrl(), id, getToken());
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  } else {
    notification.success({
      message: data.message || "Success",
    });
  }
  return data;
};



export const addLeaveByUser = (valData) => async (dispatch) => {
  dispatch(showLoader());

  let { data } = await axios.post(addLeaveByUserUrl(), valData, getToken());
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  } else {
    notification.success({
      message: data.message || "Success",
    });
  }
  return data;
};

export const SingleUserLeave = (id) => async (dispatch) => {
  dispatch(showLoader());
  let config = {
      ...(await getToken()),
  };
  let {data} = await axios.get(AllLeaveUrl(id), config);
  console.log(data);
  dispatch(hideLoader());
  if (data.error) {
      notification.error({
          message: data.message || "Error",
      });
  }
  else {
    notification.success({
      message: data.message || "Success",
    });
  }

  return data;
};



export const SingleUserCheckIn = (id) => async (dispatch) => {
  dispatch(showLoader());
  let config = {
      ...(await getToken()),
  };
  let {data} = await axios.get(SingleUserCheckInUrl(id), config);
  console.log(data);
  dispatch(hideLoader());
  if (data.error) {
      notification.error({
          message: data.message || "Error",
      });
  }
  else {
    notification.success({
      message: data.message || "Success",
    });
  }

  return data;
};

export const checkInFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  let { data } = await axios.post(checkInUrl(), valData, getToken());
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  } else {
    notification.success({
      message: data.message || "Success",
    });
  }
  return data;
};

export const checkOutFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  let { data } = await axios.post(checkOutUrl(), valData, getToken());
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  } else {
    notification.success({
      message: data.message || "Success",
    });
  }
  return data;
};
