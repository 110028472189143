import React from "react";
import {useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {
    Table,
    Card,
    Popconfirm,
    HyperLink, TableButton, DefaultTablePagination, Intakes
} from "../../../components/Elements/appUtils";
import {
    courseCategoryList,
    updateCourseActiveStatusFxn,
} from "../../Course/action";
import {useDispatch} from "react-redux";

const {TableCard} = Card;

const CourseList = (props) => {
    const navigate = useNavigate();
    let tableRef = useRef();
    const dispatch = useDispatch();

    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            let {data} = await courseCategoryList({
                ...params,
                regExFilters: [
                    "universityCountry",
                    "universityName",
                    "courseName",
                    "courseDuration",
                    "courseLevel",
                    "tuitionFee",
                    "categoryId",
                ],
            });
            resolve(data);
        });
    };

    const updateActiveStatus = async (courseId) => {
        let resp = await dispatch(updateCourseActiveStatusFxn({courseId}));
        if (resp) {
            tableRef.current.reload();
        }
    };


    let editCourse = (id) => {
        navigate(`/course-view?id=${id}`);
    };

    const columns = [
        {
            title: "Sr. No.",
            dataIndex: "index",
            key: "index",
            render: (val, record, index) => {
                return index + 1;
            },
        },
        {
            title: "Country",
            dataIndex: "universityCountry",
            key: "universityCountry",
            searchText: "universityCountry",
            width: 100,
            // searchTextName: 'universityCountry',
            //  filterRegex: true,

            render: (item, record) => {
                return item.countryName
            }
        },
        {
            title: 'University Name',
            dataIndex: 'universityId',
            key: 'universityId',
            render: (item, record) => {
                return item && item.universityName ? item.universityName : ""
            }
        },
        {
            title: 'Course Name',
            dataIndex: 'courseName',
            key: 'courseName',
            searchTextName: 'courseName',
            filterRegex: true,
            render: (val, record) => {
                return (
                    <>
                        {val}
                        <br/>
                        {/*{record._id}*/}
                    </>
                )
            }
        },
        {
            title: 'Course Duration',
            dataIndex: 'courseDuration',
            key: 'courseDuration',
            width: 150,
        },
        {
            title: 'Campus',
            dataIndex: 'campus',
            width: 150,
            key: 'campus',
            render: (value, record) => {
                console.log(record, '---')
                return (
                    <React.Fragment>

                        {value && value.length ? <ul className={'countList'}>
                            {
                                value.map((item, key) => {
                                    return (
                                        <li key={key}>{item.campus}</li>
                                    )
                                })
                            }
                        </ul> : ''}
                    </React.Fragment>
                )
            }
        },
        {
            title: 'Duration',
            key: 'courseDuration',
            // width: 150,
            width: 150,
            dataIndex: 'courseDuration',
            searchTextName: 'courseDuration'
        },
        {
            title: 'Level',
            key: 'courseLevel',
            // width: 150,
            width: 150,
            dataIndex: 'courseLevel',
            searchTextName: 'courseLevel'
        },
        {
            title: 'Tuition Fee',
            dataIndex: 'tuitionFee',
            // width: 100,
            width: 100,
            key: 'tuitionFee',
            searchTextName: 'fundAcceptable'
        },
        {
            title: 'Intakes',
            dataIndex: 'intakes',
            width: 100,
            key: 'intakes',
            filterMultiple: false,
            filters: Intakes.map(x => ({value: x, text: x})),
            render: (value, record) => {
                return (
                    <React.Fragment>
                        {value && value.length ? value.map((item, key) => {
                            return (
                                <div key={key}>{item}</div>
                            )
                        }) : ''}
                    </React.Fragment>
                )
            }
        },
        {
            title: 'Course Intakes',
            dataIndex: 'courseIntakes',
            width: 150,
            key: 'campusIntakes',
            filterMultiple: false,
            // filters: Intakes.map(x => ({ value: x, text: x })),
            render: (value, record) => {
                return (
                    <React.Fragment>
                        {value && value.length ? value.map((item, key) => {
                            return (
                                <div key={key}>{item.month}, {item.year}</div>
                            )
                        }) : ''}
                    </React.Fragment>
                )
            }
        },

        {
            title: 'Fees',
            dataIndex: 'tuitionFee',
            key: 'tuitionFee',
            width: 80,
        },
        {
            title: 'Category',
            dataIndex: 'categoryId',
            key: 'categoryId',
            render: (item, record) => {
                return item && item.categoryName ? item.categoryName : ""
            }
        },
        {
            title: "Status",
            dataIndex: "active",
            key: "active",
            width: 100,
            render: (item, record) => {
                return (
                    <>
                        <Popconfirm
                            title={'Are your sure, you want to update status?'}
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => {
                                updateActiveStatus(record._id)
                            }}>
                            {item ? <span className="active">Active</span> : <span className="inactive">Inactive</span>}

                        </Popconfirm>
                    </>
                )
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            width: 130,
            render: (val, record) => {
                return (
                    <>
                        <TableButton type={'edit'} path={`/master/course-edit?_id=${record._id}`}/>
                    </>
                )
            }
        },
    ];

    return (
        <>
            <TableCard
                title={"Course List"}
                extraBtn={[
                    {
                        name: "Add Course",
                        action: () => {
                            navigate("/master/add-course")
                        }
                    }
                ]}>
                <Table apiRequest={apiRequest}
                       pagination={DefaultTablePagination()}
                       columns={columns} ref={tableRef} extraProps={{scroll: {x: 2200}}}/>
            </TableCard>

        </>
    );
};

export default CourseList;
