import React, {useEffect, useRef, useState} from "react";
import {
    Button,
    Card,
    Form,
    Table, TableButton,
} from "../../../components/Elements/appUtils";
import {templateCategoryList} from "../actions";
import TemplateSectionsList from "../drawers/templateSectionsList";
import TemplateCategoryEdit from "./TemplateCategoryEdit";

let {TableCard} = Card
const TemplateCategoryList = (props) => {
    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false)
    const [categoryEditId, setCategoryEditId] = useState("");
    const [categoryId, setCategoryId] = useState("");

    const showDrawer = (id) => {
        setOpen(true);
        setCategoryId(id);
    };
    const onClose = () => {
        setOpen(false);
    };

    const showEdit = (id) => {
        setOpenEdit(true);
        setCategoryEditId(id)
    };
    const hideEdit = () => {
        setOpenEdit(false);
    };
    let tableRef = useRef();

    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            // params.results = 100;
            // params.count = 100;
            let {data} = await templateCategoryList({
                ...params,
                regExFilters: ["categoryName"],
            });
            resolve(data);
            console.log(data)
        });
    };

    const columns = [
        {
            title: "Sr. No",
            dataIndex: "_id",
            key: "_id",
            width: 100,
            render: (val, record, index) => {
                return index + 1;
            }
        },
        {
            title: "Category Name",
            dataIndex: "categoryName",
            key: "categoryName",
            width: 130,
        },

        {
            title: "Action",
            dataIndex: "action",
            width: 130,
            render: (val, record) => {
                return (
                    <div className={'btn_group'}>
                        <TableButton type={'view'} onClick={() => {
                            showDrawer(record._id)
                        }}/>
                        <TableButton type={'edit'} onClick={() => {
                            showEdit(record._id)
                        }}/>

                    </div>
                );
            },
        },
    ];

    return (
        <>
            <TableCard title={"Template Category List"}>
                <Table
                    apiRequest={apiRequest}
                    columns={columns}
                    ref={tableRef}
                    //   pagination={DefaultTablePagination({}, 50)}
                />
                {open && categoryId ? (
                    <TemplateSectionsList
                        visible={open}
                        onClose={onClose}
                        categoryId={categoryId}
                    />
                ) : null}
                {openEdit &&
                <TemplateCategoryEdit visible={openEdit} hideEdit={hideEdit}
                                      categoryEditId={categoryEditId}/>
                }
            </TableCard>
        </>
    );
};

export default Form.create()(TemplateCategoryList);
