import _ from "lodash";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {
    Button,
    Card,
    DatePicker,
    Form,
    InputBox,
    englishTestList,
    notification,
} from "../../../components/Elements/appUtils";
import {leadSourceOption} from "../../../components/_utils/_utils";
import {Countries, Flags} from "../../../components/_utils/appUtils";
import {LoadState} from "../../../components/_utils/countryUtil";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import {courseCategoryList} from "../../Masters/courseCategory/action";
import {companyCountryList} from "../../University/action";
import {
    AddLeadFxn,
    getLeadFieldListFxn,
    getRelatedCounsellorFxn,
} from "../action";

const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    companyContactNo: "",
    companyAddress: "",
    companyEmail: "",
    companyLogo: {},
    country: undefined,
    gstRegistration: true,
    gstInNo: "",
    cityName: undefined,
    countryName: undefined,
    stateName: undefined,
    postalCode: "",
    agentId: "",
    certificate: {},
    profilePhoto: {},
    referBy: "",
};

const counselInIt = {
  interestedCountry: "",
  cousellor: "",
  comments: "",
  leadSource: "",
  // experienceCertificate: "",
};
const englishInIt = {
    englishTestType: "",
    user: "",
    comments: "",
    leadSource: "",
    // experienceCertificate: "",
};
const {InnerCard} = Card;

const LeadInformation = (props) => {
    let {data, extraFields, updateExtraState} = props;
    let [optionsList, setOptionsList] = useState([]);

  useEffect(() => {
    setOptionsListFxn();
  }, []);
  let setOptionsListFxn = () => {
    if (data.option) {
      let optionList = data.option.split(",");
      setOptionsList(optionList);
    }
  };



  return (
      <>
          {data.inputType == "select" ? (
              <div className={"col-md-4"} key={data.key}>
                  <InputBox title={data.name}>
                      <select
                          className={"form-control"}
                          value={extraFields[data.name]}
                          onChange={(e) => {
                              updateExtraState({[data.name]: e.target.value});
                          }}
                      >
                          <option value={""}>Choose</option>
                          {optionsList && optionsList.length
                              ? optionsList.map((item) => {
                                  return (
                                      <option key={item} value={item}>
                                          {item}
                                      </option>
                                  );
                              })
                              : null}
                      </select>
                  </InputBox>
              </div>
          ) : null}

            {data.inputType == "text" ? (
                <div className={"col-md-4"} key={data.key}>
                    <InputBox title={data.name}>
                        <input
                            type="text"
                            className="form-control"
                            placeholder={data.name}
                            value={extraFields[data.name] ? extraFields[data.name] : null}
                            onChange={(e) => {
                                updateExtraState({[data.name]: e.target.value});
                            }}
                        />
                    </InputBox>
                </div>
            ) : null}

            {data.inputType == "date" ? (
                <div className={"col-md-4"} key={data.key}>
                    <InputBox title={data.name}>
                        <DatePicker
                            value={extraFields[data.name] ? extraFields[data.name] : null}
                            onChange={(e) => {
                                updateExtraState({[data.name]: e});
                            }}
                        />
                    </InputBox>
                </div>
            ) : null}
        </>
  );
};
const AddLead = (props) => {
    const {getFieldDecorator, setFieldsValue, getFieldValue} = props.form;
    const [countryList, setCountryList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [selectedCountryCode, setSelectedCountryCode] = useState("");

    const [stateArray, setStateArray] = useState([]);
    const [leadFieldsList, setLeadFieldsList] = useState([]);
    const [categoriesList, setCategoryList] = useState([]);
    let [formState, setState] = useState(initialState);
    let [extraFields, setExtraFields] = useState({});
    let [interestedFields, setInterestedFields] = useState([]);
    let [leadType, setLeadType] = useState("");

    const dispatch = useDispatch();

    useEffect(() => {
        handleLead();
        handleCourseCategory();
        handleLeadList();
    }, []);

    useEffect(() => {
        props.form.setFieldsValue({
            countryCode: "IN_+91",
        });
    }, []);

  const handleCourseCategory = async () => {
    let x = await courseCategoryList();
    setCategoryList(x.data.data);
  };
  const handleSpanClick = (type) => {
    if (type === 'counselling') {
        setLeadType('lead');
        setFieldsValue({
            interestedCountry: undefined,
            assignTo: undefined,
        });
    } else if (type === 'english') {
        setLeadType('englishTest');
        setFieldsValue({
            interestedCountry: undefined,
            assignTo: undefined,
        });
    }
};

    const events = {
        updateExtraState: (data) => {
            setExtraFields((prevState) => {
                return {
                    ...prevState,
                    ...data,
                };
            });
        },
    };
    const handleLead = async () => {
        let x = await companyCountryList();
        setCountryList(x.data);
    };
    let user = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : null;
    const handleLeadList = async () => {
        let data = await getLeadFieldListFxn();
        let fieldList = {};
        let leadFields = [];
        if (data && data.leadFields && data.leadFields.length) {
            _.each(data.leadFields, (item, index) => {
                fieldList[item.name] = "";
                leadFields.push({...item, key: moment() + 1});
            });
            setExtraFields(fieldList);
            setLeadFieldsList(leadFields);
        }
    };

    useEffect(() => {
        loadCounsellor();
    }, [
        getFieldValue("interestedCountry"),
        getFieldValue("interestedEnglishTest"),
        leadType,
  ]);

  const loadCounsellor = async () => {
      let obj = {};
      if (leadType == "lead") {
          obj.countryId = getFieldValue("interestedCountry");
      } else {
          obj.englishExamType = getFieldValue("interestedEnglishTest");
      }
      console.log();
      let x = await getRelatedCounsellorFxn({...obj, leadType});

      setUserList(x);
      if (
          (user &&
              user.userType == "counsellor" &&
              getFieldValue("interestedCountry")) ||
          (user.userType == "englishTeacher" &&
              getFieldValue("interestedEnglishTest"))
      ) {
          let counsellor = _.find(x, (item) => {
              return user._id == item._id;
          });

          props.form.setFieldsValue({
              assignTo: counsellor._id,
          });
      }
  };

  const handleSubmit = (e) => {
      const {form} = props;
    e.preventDefault();
    form.validateFields(async (err, valData) => {
      if (!err) {
          if (!valData.name) {
              notification.warning({
                  message: `Please enter Name`,
              });
              return;
          }
          if (!valData.countryName) {
              notification.warning({
                  message: `Please choose country`,
              });
              return;
          }

          /*if (!valData.email) {
                          notification.warning({
                              message: `Please enter Email`,
                          });
                          return;
                      }*/

          if (!valData.stateName) {
              notification.warning({
                  message: `Please enter state`,
              });
              return;
          }

          if (!valData.dateOfBirth) {
              notification.warning({
                  message: `Please choose date of birth`,
              });
              return;
          }
          if (!valData.cityName) {
              notification.warning({
                  message: `Please enter city`,
              });
              return;
          }

          if (!valData.countryCode) {
              notification.warning({
            message: `Please select Country Code`,
          });
          return;
        }

        if (!valData.mobileNo) {
          notification.warning({
            message: `Please enter Mobile No`,
          });
          return;
        }

        if (!leadType) {
          notification.warning({
            message: `Choose lead type`,
          });
          return;
        }

        if (leadType == "lead" && !valData.interestedCountry) {
          notification.warning({
            message: `Choose Interested Country`,
          });
          return;
        }
        if (leadType == "englishTest" && !valData.interestedEnglishTest) {
          notification.warning({
            message: `Choose Interested Test`,
          });
          return;
        }

        valData.leadType = leadType;

        // valData.extraFields = extraFields;
        // valData.interestedFields = interestedFields;
        let x = await dispatch(AddLeadFxn(valData));
        if (x && x.success) {
          props.form.setFieldsValue({
              name: "",
              email: "",
              mobileNo: "",
              dateOfBirth: "",
              gender: "",
              countryCode: undefined,
              countryName: "",
              stateName: "",
              cityName: "",
              address: "",
              comments: "",
              interestedCountry: undefined,
              interestedEnglishTest: undefined,
              leadSource: undefined,
              assignTo: undefined,
          });
            setInterestedFields([]);
            setLeadType("");
            // setExtraFields({});
            // handleLeadList();
        }
      } else {
          notification.warning({
              message: "Fill All Required Fields",
          });
      }
    });
  };

    let inputTypes = {
        fields: [
            {
                key: "name",
                label: "Name",
                showStar: true,
                placeholder: "Name",
                autoComplete: "new-name",
                type: "text",
                span: "col-md-6",
            },
            {
              key: "countryName",
              label: "Country",
              showStar: true,
              placeholder: "Country",
              type: "select",
              options: Countries,
              showSearch: true,
              keyAccessor: (x) => x.name,
              valueAccessor: (x) => `${x.name}`,
              onChange: (selectedCountry) => {
                  const countryCode = Flags.find((flag) => flag.name === selectedCountry)?.countryCode;
                  setSelectedCountryCode(countryCode);
                  props.form.setFieldsValue({
                      countryName: selectedCountry,
                  });
                  if(countryCode){props.form.setFieldsValue({
                    countryCode: countryCode,
                  });
                    }
              },
              span: "col-md-6",
          },

            {
                key: "stateName",
                label: "State",
                showStar: true,
                showSearch: true,
                placeholder: "State Name",
                type: "text",
                span: "col-md-6",
            },
            {
                key: "dateOfBirth",
                label: "Date Of Birth ",
                showStar: true,
                placeholder: "Date Birth ",
                type: "date",
                span: "col-md-6",
                keyAccessor: (x) => x.option,
                valueAccessor: (x) => `${x.option}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        dateOfBirth: x,
                    });
                },
            },
            {
                key: "cityName",
                label: "City",
                showStar: true,
                placeholder: "City Name",
                type: "text",
                span: "col-md-6",
            },
            // {
            //     key: "email",
            //     label: "Email",
            //     // required: true,
            //     placeholder: "Email",
            //     type: "text",
            //     span: "col-md-4",
            // },
            {
                key: "countryCode",
                label: "Country Code",
                showStar: true,
                placeholder: "Country Code",
                type: "select",
                options: Flags,
                showSearch: true,
                keyAccessor: (x) => x.countryCode,
                valueAccessor: (x) => `${x.name} (${x.dialCode})`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        countryCode: x,
                    });
                },
                span: "col-md-3",
            },

            {
                key: "mobileNo",
                label: "Mobile No",
                showStar: true,
                placeholder: "Mobile No",
                type: "text",
                span: "col-md-3",
            },
        ],
        counsellingField: [
            {
                key: "interestedCountry",
                label: "Country",
                showStar: true,
                placeholder: "Please Choose Country",
                type: "select",
                options: countryList,
                keyAccessor: (x) => x._id,
                valueAccessor: (x) => `${x.countryName}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        interestedCountry: x,
                        assignTo: undefined,
                    });
                },
            },
            {
                key: "assignTo",
                label: "Select Counsellor",
                placeholder: "Select Counsellor",
                type: "select",
                options: userList,
                keyAccessor: (x) => x._id,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        assignTo: x,
                    });
                },
            },
        ],
        englishTestField: [
            {
                key: "interestedEnglishTest",
                label: "Select English Test",
                placeholder: "Select English Test",
                type: "select",
                showStar: true,
                options: englishTestList,
                keyAccessor: (x) => x,
                valueAccessor: (x) => `${x}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        interestedEnglishTest: x,
                        assignTo: undefined,
                    });
                },
            },
            {
                key: "assignTo",
                label: "Select User",
                placeholder: "Select User",
                type: "select",
                options: userList,
                keyAccessor: (x) => x._id,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        assignTo: x,
                    });
                },
            },
        ],
        commonFields: [
            {
                key: "comments",
                label: "Comments",
                placeholder: "Comments",
                type: "text",
                span: "col-md-6",
            },
            {
                key: "leadSource",
                label: "Lead Source",
                placeholder: "Please Choose Lead Source",
        type: "select",
        options: leadSourceOption,
        keyAccessor: (x) => x.option,
        valueAccessor: (x) => `${x.option}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            leadSource: x,
          });
        },
      },
    ],
  };

  return (
      <>
          <Form onSubmit={handleSubmit} autoComplete="off">
              <Card title={"Basic Information"}>
                  <div className={"row"}>
                      {inputTypes.fields.map((item, key) => {
                          return (
                              <div
                                  className={`pt-3 ${item.span ? item.span : "col-md-4"}`}
                                  autoComplete="off"
                        key={key}
                    >
                      <GetEachFormFields {...props.form} item={item} autoComplete="off" />
                    </div>
                );
              })}
            </div>
            <div className="row mt-3">
              <div className="col-lg-6 col-12">
                <div className="form-group">
                  <div className="radio-check-box">
                    <input
                        type="checkbox"
                        value={"lead"}
                        name="Counselling"
                        id="Counselling"
                        onClick={({target}) => {
                          setLeadType(target.value);
                                               setFieldsValue({
                                              interestedCountry: undefined,
                                              assignTo: undefined,
                                          });
                                      }}
                                      checked={leadType == "lead"}
                                  />
                                  <label className="form-control">
                                  Counselling <span onClick={() => handleSpanClick('counselling')}>Click to Select</span>
                                  </label>
                                  <img src="../app/img/check-green.svg" alt=""/>
                              </div>
                          </div>
                      </div>
                      <div className="col-lg-6 col-12">
                          <div className="form-group">
                              <div className="radio-check-box">
                                  <input
                                      type="checkbox"
                                      value={"englishTest"}
                                      name="english"
                                      id="english"
                                      onClick={({target}) => {
                                          setLeadType(target.value);
                                          setFieldsValue({
                                              interestedCountry: undefined,
                                              assignTo: undefined,
                                          });
                                      }}
                                      checked={leadType == "englishTest"}
                                  />
                                  <label className="form-control">
                                      Register for English Eligibility
                                      <span onClick={() => handleSpanClick('english')}>Click to Select</span>
                                  </label>
                                  <img src="../app/img/check-green.svg" alt=""/>
                              </div>
                          </div>
                      </div>
                  </div>

                  {leadType == "lead" ? (
                      <div className={"row"}>
                          {inputTypes.counsellingField.map((item, key) => {
                              return (
                                  <div className={`pt-3 col-md-6`} key={key}>
                                      <GetEachFormFields {...props.form} item={item}/>
                                  </div>
                              );
                          })}
                      </div>
                  ) : null}
                  {leadType == "englishTest" ? (
                      <div className={"row"}>
                          {inputTypes.englishTestField.map((item, key) => {
                              return (
                                  <div className={`pt-3 col-md-6`} key={key}>
                                      <GetEachFormFields {...props.form} item={item}/>
                                  </div>
                              );
                          })}
                      </div>
                  ) : null}

                  <div className={"row"}>
                      {inputTypes.commonFields.map((item, key) => {
                          return (
                              <div className={`pt-3 col-md-6`} key={key}>
                                  <GetEachFormFields {...props.form} item={item}/>
                              </div>
                          );
                      })}
                  </div>

                  <Button
                      type={"primary"}
                      htmlType="submit"
                      className="btn main-btn mt-4"
                  >
                      Submit
                  </Button>
              </Card>
          </Form>
      </>
  );
};

export default Form.create()(AddLead);
