import React, {useRef} from "react"
import {Card, Table} from "../../../components/Elements/appUtils";
import {useNavigate} from "react-router-dom";
import {withdrawalFxn} from "../actions";
import {useDispatch} from "react-redux";

let {TableCard} = Card;
const WithdrawalList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    let tableRef = useRef();
    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            let {data} = await dispatch(withdrawalFxn({
                ...params,
                regExFilters: ["name"],
            }))
            resolve(data);
        });
    };

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            render: (item, record) => {
                let {userId} = record
                return userId && userId.name ? userId.name : ""
            }
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            render: (item, record) => {
                let {userId} = record
                return userId && userId.email ? userId.email : ""
            }
        },
        {
            title: "Company Name",
            dataIndex: "companyName",
            key: "companyName",
            render: (item, record) => {
                let {companyId} = record
                return companyId && companyId.companyName ? companyId.companyName : ""
            }
        },


        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (item, record) => {
                return (
                    <>
                        {item == 'Pending' ? <span className="pending">Pending</span> : null}
                        {item == 'Approved' ? <span className="active">Approved</span> : null}
                        {item == 'Cancel' ? <span className="inactive">Cancelled</span> : null}
                    </>
                );
            },
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (item, record) => {
                return (
                    <>

                    </>
                );
            },
        },

    ];

    return (
        <>
            <TableCard title={"Withdrawal List"}>
                <Table apiRequest={apiRequest} columns={columns} ref={tableRef}/>
            </TableCard>
        </>
    );
}
export default WithdrawalList
