import React, {useRef, useState} from "react";
import {
    Button,
    Card,
    Form,
    Table,
    Tooltip,
    notification,
} from "../../components/Elements/appUtils";
import {GetEachFormFields} from "../../components/_utils/formUtils";
import {AddDocumentFxn, documentList} from "./action";
import {useDispatch} from "react-redux";

const SelfDocument = (props) => {
    let [document, setDocument] = useState("");
    const {getFieldDecorator, getFieldValue} = props.form;
    let dispatch = useDispatch();
    let tableRef = useRef();
    let {TableCard} = Card
    const handleChange = (e) => {
        const file = e.target.files[0];
        setDocument(file);
    };

    const handleSubmit = (e) => {
        const {form} = props;
        e.preventDefault();
        form.validateFields(async (err, valData) => {
            if (!err) {
                let fd = new FormData();
                if (!valData.name) {
                    notification.warning({message: "Please enter name."})
                    return
                }
                if (!document || (document && !document.name)) {
                    notification.warning({message: "Please choose document."})
                    return
                }
                fd.append("obj", JSON.stringify(valData));
                if (document && document.name) {
                    fd.append("document", document);
                }
                let x = await dispatch(AddDocumentFxn(fd));
                if (x && x.success) {
                    tableRef.current.reload();
                }
                props.form.setFieldsValue({
                    name: "",
                    document: "",
                });
                setDocument("")

            }
            ;
        });
    };
    let documentInfo = {
        fields: [
            {
                key: "name",
                label: "Name",
                showStar: true,
                placeholder: "Name",
                type: "text",
            },
            {
                key: "document",
                label: "Document",
                placeholder: "Document",
                type: "file",
                fileName: document,
                showStar: true,
                onChange: handleChange,
            },
        ],
    };
    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            searchTextName: "name",
        },
        {
            title: "Document",
            dataIndex: "document",
            key: "document",
            render: (item, record) => {
                return (
                    <Tooltip title={item.name}>
                        <a download={item.name} href={item.url} target={"_blank"}>
                            <label>{item.name}</label>
                        </a>
                    </Tooltip>
                );
            },
        },
        /* {
             title: "Action",
             dataIndex: "action",
             width: 130,
             render: (val, record) => {
                 return (
                     <a
                         className={"btn btn-primary btn-sm"}
                         href={`/selfDocument-edit?documentId=${record._id}`}
                     >
                         Edit
                     </a>
                 );
             },
         },*/
    ];
    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            let data = await dispatch(
                documentList({
                    ...params,
                    regExFilters: ["name", "document"],
                })
            );
            resolve(data.data);
        });
    };
    return (
        <>
            <Card title={"Documents"}>
                <Form onSubmit={handleSubmit}>
                    <div className={"row"}>
                        {documentInfo.fields.map((item, key) => {
                            return !item.hidden ? (
                                <div className={"col-md-6 pt-3"} key={key}>
                                    <GetEachFormFields
                                        {...props.form}
                                        item={item}
                                    />
                                </div>
                            ) : null;
                        })}
                    </div>
                    <div style={{marginTop: "20px"}}>
                        <Button type={"primary"} htmlType="submit">
                            submit
                        </Button>
                    </div>
                </Form>
            </Card>
            <TableCard title={" Document List"} className={"marginTop:50px"}>
                <Table
                    columns={columns}
                    apiRequest={apiRequest}
                    ref={tableRef}
                    marginTop="8"
                />
            </TableCard>
        </>
    );
};

export default Form.create()(SelfDocument);
