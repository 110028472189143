import {hideLoader, showLoader} from "../../../actions/loader";
import {customAxios as axios, getToken} from "../../../request";
import {withdrawalUrl} from "../apis";

export const withdrawalFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let config = {
        params: {...valData},
        ...(await getToken()),
    };
    let {data} = await axios.get(withdrawalUrl(), config);
    dispatch(hideLoader());
    return data;
};
