import {apiUrl} from "../../../../settings";

export const addAllCountry = () => {
  return `${apiUrl}/masterCountriesList`;
};

export const addStateUrl = () => {
  return `${apiUrl}/addMasterState`;
};

export const StateListUrl = () => {
  return `${apiUrl}/masterStateList`;
};

export const getStateUrl = (stateId) => {
  return apiUrl + "/getSingleMasterState/" + stateId;
};

export const postStateUrl = () => {
  return `${apiUrl}/updateState`;
};
