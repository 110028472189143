import React from "react"
import CompanyList from "../../Masters/company/CompanyList";

const AdminDashboard = (props) => {
    return (
        <>
            <div>
                <div className="countings">
                    <div className="row gy-4">
                        <div className="col-lg-3 col-12">
                            <div className="card-box">
                                <span className="lightblue-clr">15</span>
                                <h4>live users</h4>
                            </div>
                        </div>
                        <div className="col-lg-3 col-12">
                            <div className="card-box">
                                <span className="blue-clr">15</span>
                                <h4>active users</h4>
                            </div>
                        </div>
                        <div className="col-lg-3 col-12">
                            <div className="card-box">
                                <span className="pink-clr">15</span>
                                <h4>trial active agents</h4>
                            </div>
                        </div>
                        <div className="col-lg-3 col-12">
                            <div className="card-box">
                                <span className="skyblue-clr">15</span>
                                <h4>upcomming payment pending</h4>
                            </div>
                        </div>
                        <div className="col-lg-3 col-12">
                            <div className="card-box">
                                <span className="lightgreen-clr">15</span>
                                <h4>pending approved users</h4>
                            </div>
                        </div>
                        <div className="col-lg-3 col-12">
                            <div className="card-box">
                                <span className="orange-clr">15</span>
                                <h4>total revenue $</h4>
                            </div>
                        </div>
                        <div className="col-lg-3 col-12">
                            <div className="card-box">
                                <span className="purple-clr">15</span>
                                <h4>total active users</h4>
                            </div>
                        </div>
                        <div className="col-lg-3 col-12">
                            <div className="card-box">
                                <span className="green-clr">15</span>
                                <h4>diversity</h4>
                            </div>
                        </div>
                        <div className="col-lg-3 col-12">
                            <div className="card-box">
                                <span className="orange-clr">15</span>
                                <h4>pending trial users</h4>
                            </div>
                        </div>
                        <div className="col-lg-3 col-12">
                            <div className="card-box">
                                <span className="green-clr">15</span>
                                <h4>15 days extended users</h4>
                            </div>
                        </div>
                        <div className="col-lg-3 col-12">
                            <div className="card-box">
                                <span className="skyblue-clr">15</span>
                                <h4>total agents</h4>
                            </div>
                        </div>
                        <div className="col-lg-3 col-12">
                            <div className="card-box">
                                <span className="mehroon-clr">15</span>
                                <h4>unizportal users</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={'mt-4'}>
                <CompanyList borderDark={true}/>
            </div>

        </>
    )
}
export default AdminDashboard
