import React, {useState} from "react"
import {Button, Card, InputBox, Modal} from "../../../components/Elements/appUtils";
import {paymentOptions} from "../../../components/_utils/_utils";

const PayModal = (props) => {
    let {visible, onClose, onSubmit, data} = props;
    let [paymentMethod, setPaymentMethod] = useState("")
    return (
        <>
            <Modal title={'Pay Installment'} visible={visible} onClose={onClose}>
                <InputBox title={'Amount'}>
                    <div className={"rowFlex"}>
                        <input value={data.amount} className={'form-control'} disabled={true}/>
                        <a className={'action_btn'}>
                            $
                        </a>
                    </div>
                </InputBox>
                <InputBox title={'Payment Method'}>
                    <select
                        value={data.amount} className={'form-control'}
                        onChange={({target}) => {

                        }}>
                        <option value={""}>Choose Method</option>
                        {paymentOptions.map((item) => {
                            return (
                                <option value={item.value} key={item.label}>{item.label}</option>
                            )
                        })}
                    </select>
                </InputBox>
                <div className={"mt-4 alignRight"}>
                    <Button type={"primary"}>
                        Submit
                    </Button>
                </div>
            </Modal>
        </>
    )
}
export default PayModal
