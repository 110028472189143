import { apiUrl } from "../../settings";

export const singleStudentUrl = (studentId) => {
  return apiUrl + "/getSingleStudent/" + studentId;
};
export const updateApplicationUrl = () => {
  return apiUrl + "/updateApplication";
};
export const addDocumentsStudentUrl = () => {
  return apiUrl + "/addDocuments";
};
export const getSingleStudentDocumentsUrl = (studentId) => {
  return apiUrl + "/getSingleStudentDocuments/" + studentId;
};

export const updateStudentUrl = () => {
  return apiUrl + '/updateSingleStudent'
};

