import _ from "lodash";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {
    Button,
    Card,
    Form,
    notification,
} from "../../components/Elements/appUtils";
import {Flags} from "../../components/_utils/appUtils";
import {GetEachFormFields} from "../../components/_utils/formUtils";
import {loadCountryList} from "../Masters/state/action";
import {AddReceptionFxn, branchListFxn} from "./actions";

const RegistrationOptions = [
    {option: "Yes", value: true},
    {option: "No", value: false},
];
const AddReception = (props) => {
    const [countryList, setCountryList] = useState([]);
    const [branchList, setBranchList] = useState([]);
    const [enableForIp, setEnableForIp] = useState(false);

    const dispatch = useDispatch();
    let user = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : null;
    useEffect(() => {
        handleState();
        loadBranches();
    }, []);
    useEffect(() => {
        props.form.setFieldsValue({
            countryCode: "IN_+91",
            enableForIp: false, 
        });
        setEnableForIp(false); 
    }, []);
    const handleState = async () => {
        let x = await loadCountryList();
        setCountryList(x.data);
    };
    const loadBranches = async () => {
        let {data} = await dispatch(
            branchListFxn({
                userType: ["branch", "headBranch"],
            })
        );
        setBranchList(data);
        setTimeout(() => {
            let headBranch = _.find(data, (item) => {
                return item.userType == "headBranch";
            });
            console.log(headBranch, "headdddddddddddd");
            props.form.setFieldsValue({
                branchId: headBranch._id,
            });
        }, 700);
    };
    const handleSubmit = (e) => {
        const {form} = props;
        e.preventDefault();
        form.validateFields(async (err, valData) => {
            if (!err) {
                if (!valData.name) {
                    notification.warning({message: "Enter name"});
                    return;
                }
                if (!valData.email) {
                    notification.warning({message: "Enter email"});
                    return;
                }
                if (!valData.password) {
                    notification.warning({message: "Enter password"});
                    return;
                }
                if (valData.password.length < 6) {
                    notification.warning({message: "Enter atleast 6 digit password."});
                    return;
                }
                if (!valData.countryCode) {
                    notification.warning({message: "Choose country code"});
                    return;
                }
                if (!valData.mobile) {
                    notification.warning({message: "Enter mobile no"});
                    return;
                }
                if (user.userType == "headBranch") {
                    if (!valData.branchId) {
                        notification.warning({message: "Choose branch"});
                        return;
                    }
                }
                let x = await dispatch(
                    AddReceptionFxn({...valData, userType: "reception"})
                );
                if (x.success) {
                    props.form.setFieldsValue({
                        name: "",
                        email: "",
                        mobile: "",
                        password: "",
                        address: "",
                        countryCode: "",
                        countryId: "",
                        branchId: "",
                        enableForIp: "",
                    });
                }
            } else {
                notification.warning({
                    message: "Fill All Required Fields",
                });
            }
        });
    };

    let inputTypes = {
        fields: [
            {
                key: "name",
                label: "Name",
                required: false,
                placeholder: "Name",
                type: "text",
                showStar: true,
            },
            {
                key: "email",
                label: "Email",
                required: false,
                placeholder: "Email",
                type: "email",
                showStar: true,
            },
            {
                key: "countryCode",
                label: "Country Code",
                required: false,
                placeholder: "Country Code",
                type: "select",
                options: Flags,
                showSearch: true,
                keyAccessor: (x) => x.countryCode,
                valueAccessor: (x) => `${x.name} (${x.dialCode})`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        countryCode: x,
                    });
                },
                span: "col-md-2",
                showStar: true,
            },
            {
                key: "mobile",
                label: "Mobile No",
                required: false,
                placeholder: "Mobile No",
                type: "text",
                span: "col-md-4",
                showStar: true,
            },
            {
                key: "password",
                label: "Password",
                required: false,
                placeholder: "Password",
                type: "password",
                showStar: true,
            },
            {
                key: "address",
                label: "Address",
                required: false,
                placeholder: "Address",
                type: "text",
            },
            {
                key: "branchId",
                label: "Branch",
                required: false,
                placeholder: "Please Choose Branch",
                type: "select",
                showStar: true,

                hidden: !(user.userType == "headBranch"),
                options: branchList,
                keyAccessor: (x) => x._id,

                valueAccessor: (x) =>
                    `${
                        x && x.userType && x.userType === "branch"
                            ? `${x.cityName ? x.cityName : ""}(${x.contactPerson})`
                            : `${x.cityName ? x.cityName : ""}(${x.name})`
                    }`,

                onChange: (x) => {
                    props.form.setFieldsValue({
                        branchId: x,
                    });
                },
            },
            // {
            //     key: "enableForIp",
            //     label: "Enable For Ip Address",
            //     placeholder: "Enable For Ip Address",
            //     options: RegistrationOptions,
            //     type: "select",
            //     keyAccessor: (x) => x.value,
            //     valueAccessor: (x) => `${x.option}`,
            //     onChange: (x) => {
            //         props.form.setFieldsValue({
            //             enableForIp: x,
            //         });
            //     },
            // },
        ],
    };
    return (
        <>
            <Card title={"Add Reception"}>
                <Form onSubmit={handleSubmit}>
                    <div className={"row"}>
                        {inputTypes.fields.map((item, key) => {
                            return (
                                <div
                                    className={`pt-3 ${item.span ? item.span : "col-md-6"}`}
                                    key={key}
                                >
                                    <GetEachFormFields {...props.form} item={item}/>
                                </div>
                            );
                        })}
                    </div>

                    <div style={{marginTop: "20px"}}>
                        <Button type={"primary"} htmlType="submit">
                            submit
                        </Button>
                    </div>
                </Form>
            </Card>
        </>
    );
};

export default Form.create()(AddReception);
