import _ from "lodash";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {
    Button,
    Card,
    Form,
    Modal,
    notification,
} from "../../../components/Elements/appUtils";
import {
    EnglishExamOptions,
    dateFormats,
    genderOption,
    timeFormats,
} from "../../../components/_utils/_utils";
import {
    Countries,
    CurrencyJson,
    Flags,
    TimeZones,
} from "../../../components/_utils/appUtils";
import {LoadState} from "../../../components/_utils/countryUtil";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import EducationComponent from "../../AddStudent/Components/educationComponent";
import {companyCountryList} from "../../University/action";
import {postUpdateUserFxn} from "../../Users/views/action";
import {GetRelatedCompanyFxn, updateCompanyFxn} from "./action";

const {InnerCard} = Card;
const eduStateInit = {
    qualification: "",
    math: "",
    english: "",
    percentage: "",
    board: "",
    university: "",
    stream: "",
    passingYear: "",
    courseName: "",
    schoolName: "",
};

const EditCompany = (props) => {
    const initialState = {
        companyName: "",
        contactPerson: "",
        countryCode: "",
        mobile: "",
        address: "",
        cityName: undefined,
        countryName: undefined,
        stateName: undefined,
        postalCode: "",
        website: "",
    };

    const [countryList, setCountryList] = useState([]);
    let [interestedFields, setInterestedFields] = useState([]);
    const [stateArray, setStateArray] = useState([]);
    const [userId, setUserId] = useState("");
    let [extraFields, setExtraFields] = useState({});
    let [formState, setState] = useState(initialState);
    const [education, setEducation] = useState([eduStateInit]);
    const [enableForEnglish, setEnableForEnglish] = useState(false);
    const [eduState, setEduState] = useState(eduStateInit);
    const dispatch = useDispatch();
    let [userType, setUserType] = useState("counsellor");
    let user = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : null;
    const AllData = () => {
        setEducation([...education, eduStateInit]);
    };
    const deleteItem = (itemId) => {
        const updatedItems = education.filter((item, key) => key !== itemId);
        setEducation(updatedItems);
    };

    const eduEvents = {
        updateState: (data, index) => {
            let cloneEdu = _.clone(education);
            cloneEdu[index] = {...cloneEdu[index], ...data};
            setEducation(cloneEdu);
        },
        addEdu: () => {
            if (education) {
                if (education.percentage < 1) {
                    notification.warning({
                        message: "Enter greater then 0 Percentage",
                    });
                    return;
                }
                if (education.qualification == "XII") {
                    if (education.math < 1) {
                        notification.warning({
                            message: "Enter greater then 0 Math score",
                        });
                        return;
                    }
                    if (education.english < 1) {
                        notification.warning({
                            message: "Enter greater then 0 English score",
                        });
                        return;
                    }
                }
            }
            setEducation((preState) => {
                return [...preState, eduState];
            });
            eduEvents.updateState(eduStateInit);
        },
    };

    useEffect(() => {
        handleState();
    }, []);
    useEffect(() => {
        let searchParams = new URLSearchParams(window.location.search);
        let id = searchParams.get("_id");
        if (id) {
            setUserId(id);
            getRelatedUser(id);
        }
    }, []);

    const getRelatedUser = async (id) => {
        let {data} = await dispatch(GetRelatedCompanyFxn(id));
        console.log("companyyyyyyyyyyyyyyyy", data);
        setTimeout(() => {
            props.form.setFieldsValue({
                contactPerson: data.contactPerson,
                companyName: data.companyName,
                companyAddress: data.companyAddress,
                email: data.email,
                countryCode: data.countryISOCode,
                mobile: data.mobile,
                dateOfBirth: data.dateOfBirth,
                gender: data.gender,
                countries: data.countries,
                countryName: data.countryName,
                postalCode: data.postalCode,
                stateName: data.stateName,
                website: data.website,
                cityName: data.cityName,
                timezone: data.timezone,
                dateFormat: data.dateFormat,
                timeFormat: data.timeFormat,
                casualLeave: data.casualLeave,
                earnedLeave: data.earnedLeave,
            });
        }, 500);
    };

    const handleState = async () => {
        let x = await companyCountryList();
        setCountryList(x.data);
    };

    const handleSubmit = (e) => {
        const {form} = props;
        e.preventDefault();
        form.validateFields(async (err, valData) => {
            if (!err) {
                if (!valData.contactPerson) {
                    notification.warning({message: "Enter name"});
                    return;
                }
                if (!valData.email) {
                    notification.warning({message: "Enter email"});
                    return;
                }

                if (!valData.countryCode) {
                    notification.warning({message: "Choose country code"});
                    return;
                }
                if (!valData.mobile) {
                    notification.warning({message: "Enter mobile no"});
                    return;
                }

                valData.companyId = userId;
                valData = {...valData, education};
                let x = await dispatch(
                    updateCompanyFxn({
                        ...valData,
                    })
                );
                if (x.success) {
                }
            } else {
                notification.warning({
                    message: "Please Fill All Required Fields",
                });
            }
        });
    };
    const events = {
        chooseCountry: (name) => {
            let countryName = _.find(Countries, (item) => {
                return item.name == name;
            });
            if (countryName) {
                setState({
                    ...formState,
                    countryName: countryName.name ? countryName.name : "",
                });
                setStateArray(countryName.id ? LoadState(countryName.id) : []);
            }
        },
        chooseState: (name) => {
            let stateName = _.find(stateArray, (item) => {
                return item.name == name;
            });
            if (stateName) {
                setState({
                    ...formState,
                    stateName: stateName.name ? stateName.name : "",
                });
            }
        },
        updateExtraState: (data) => {
            setExtraFields((prevState) => {
                return {
                    ...prevState,
                    ...data,
                };
            });
        },
        chooseCourseCategory: (category) => {
            let cloneD = _.clone(interestedFields);
            let findV = _.find(cloneD, (item) => {
                return item == category;
            });
            if (findV) {
                cloneD = _.reject(cloneD, (item) => {
                    return item == category;
                });
                setInterestedFields(cloneD);
            } else {
                cloneD.push(category);
                setInterestedFields(cloneD);
            }
        },
    };

    let inputTypes = {
        fields: [
            {
                key: "contactPerson",
                label: "Contact Person",
                required: false,
                placeholder: "Enter Name",
                type: "text",
            },
            {
                key: "companyName",
                label: "Company Name",
                required: false,
                placeholder: "Enter Company Name",
                type: "text",
            },
            {
                key: "email",
                label: "Email",
                placeholder: "Email",
                type: "text",
            },

            {
                key: "address",
                label: "Address",
                required: false,
                placeholder: "Address",
                type: "text",
            },
            {
                key: "countryCode",
                label: "Country Code",
                required: false,
                placeholder: "Country Code",
                type: "select",
                options: Flags,
                showSearch: true,
                keyAccessor: (x) => x.countryCode,
                valueAccessor: (x) => `${x.name} (${x.dialCode})`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        countryCode: x,
                    });
                },
                span: "col-md-2",
            },

            {
                key: "mobile",
                label: "Contact No",
                required: false,
                placeholder: "Mobile No",
                type: "text",
                span: "col-md-6",
            },
            {
                key: "gender",
                label: "Gender",

                placeholder: "Gender ",
                type: "select",
                keyAccessor: (x) => x.option,
                valueAccessor: (x) => `${x.option}`,
                options: genderOption,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        gender: x,
                    });
                },
                span: "col-md-4",
            },
            {
                key: "timezone",
                label: "Timezone",
                type: "select",
                required: false,
                options: TimeZones,
                showSearch: true,
                keyAccessor: (x) => x.value,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        timezone: x,
                    });
                },
                span: "col-md-4",
            },
            {
                key: "dateFormat",
                label: "Date Format",
                type: "select",
                required: false,
                options: dateFormats,
                showSearch: true,
                keyAccessor: (x) => x,
                valueAccessor: (x) => `${x}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        dateFormat: x,
                    });
                },
                span: "col-md-4",
            },

            {
                key: "timeFormat",
                label: "Time Format",
                type: "select",
                required: false,
                options: timeFormats,
                showSearch: true,
                keyAccessor: (x) => x.value,
                valueAccessor: (x) => x.name,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        timeFormat: x,
                    });
                },
                span: "col-md-4",
            },
            {
                key: "website",
                label: "Website",
                placeholder: "Website(Optional)",
                type: "text",
                span: "col-md-4",
            },
            {
                key: "countryName",
                label: "Country",
                showStar: true,
                showSearch: true,
                placeholder: "Country Name",
                type: "select",
                options: Countries,
                keyAccessor: (x) => x.name,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        countryName: x,
                        stateName: "",
                        cityName: "",
                    });
                    events.chooseCountry(x);
                },
                span: "col-md-4",
            },
            {
                key: "stateName",
                label: "State",
                showStar: true,
                showSearch: true,
                placeholder: "State Name",
                type: "text",
                span: "col-md-4",
            },
            {
                key: "cityName",
                label: "City",
                showStar: true,
                placeholder: "City Name",
                type: "text",
                span: "col-md-4",
            },

            {
                key: "postalCode",
                label: "Postal Code (Optional)",
                span: "col-md-4",
                required: false,
                placeholder: "Postal Code",
                type: "text",
            },
            {
                key: "casualLeave",
                label: "Casual Leave",
                span: "col-md-4",
                required: false,
                placeholder: "Casual Leave",
                type: "text",
            },
            {
                key: "earnedLeave",
                label: "Earned Leave",
                span: "col-md-4",
                required: false,
                placeholder: "Earned Leave",
                type: "text",
            },
        ],
    };
    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Card title={"Edit My Profile"}>
                    <div className={"row"}>
                        {inputTypes.fields.map((item, key) => {
                            return (
                                <div
                                    className={`pt-3 ${item.span ? item.span : "col-md-6"}`}
                                    key={key}
                                >
                                    <GetEachFormFields {...props.form} item={item}/>
                                </div>
                            );
                        })}
                    </div>
                    <Button
                        type={"primary"}
                        htmlType="submit"
                        className={"btn main-btn mt-4"}
                    >
                        submit
                    </Button>
                </Card>
            </Form>
        </>
    );
};

export default Form.create()(EditCompany);
