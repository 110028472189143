import React from "react";
import {
    Button,
    Card,
    Drawer,
    Form,
} from "../../../components/Elements/appUtils";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import {addCommentFxn} from "../action";
import {useDispatch} from "react-redux";

const CommentDrawer = (props) => {
    let {visible, onCloseComment, noteId} = props;
    const {getFieldDecorator} = props.form;
    const dispatch = useDispatch()
    console.log(noteId, "noooteeee");

    const handleSubmit = (e) => {
        const {form} = props;
        e.preventDefault();
        form.validateFields(async (err, valData) => {
            console.log(err, valData);
            valData.noteId = noteId
            if (!err) {
                let x = await dispatch(addCommentFxn(valData));
                console.log(x);
                props.form.setFieldsValue({
                    text:"",

                })
            }
        });
    }
    let inputTypes = {
        fields: [
            {
                key: "text",
                label: "Comment",
                required: true,
                placeholder: "Comment",
                type: "textarea",
                span: "col-md-12",
            },
    ],
  };
  return (
    <Drawer
      visible={visible}
      onClose={onCloseComment}
      width={"45%"}
      title={"Add Comment"}
      placement="right"
    >
      <Card>
        <Form onSubmit={handleSubmit}>
          <div className={"row"}>
            {inputTypes.fields.map((item, key) => {
              return (
                <div className={"col-md-12 pt-3"} key={key}>
                  <GetEachFormFields
                      {...props.form}
                      item={item}
                  />
                </div>
              );
            })}
          </div>
          <div style={{ marginTop: "20px" }}>
            <Button type={"primary"} htmlType="submit">
              submit
            </Button>
          </div>
        </Form>
      </Card>
    </Drawer>
  );
};

export default Form.create()(CommentDrawer);
