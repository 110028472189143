import {apiUrl} from "../../../../settings";

export const addAgentUrl = () => {
    return `${apiUrl}/addCompany`;
};

export const agentListUrl = () => {
    return `${apiUrl}/agentList`;
};

export const companyListUrl = () => {
    return `${apiUrl}/allCompanies`;
};

export const approvedTrialManualUrl = () => {
    return `${apiUrl}/api/approvedTrialManual`;
};

export const companyByIdUrl = () => {
    return `${apiUrl}/api/companyById`;
};
export const updateCompanyUrl = (id) => {
    return `${apiUrl}/updateCompany/${id}`;
};
export const getSingleCompanyUrl = (id) => {
    return apiUrl + "/getSingleCompany/" + id;
};
export const sendOptToDeleteCompanyUrl = () => {
    return apiUrl + "/sendOtpToDeleteCompany";
};
export const deleteCompanyUrl = () => {
    return apiUrl + "/removeCompany";
};
